import React, { useState } from "react";
import {
	Grid,
	Select,
	MenuItem,
	Typography,
	IconButton,
} from "@material-ui/core";
import useStyles from "../../styles";
import Pagination from "@material-ui/lab/Pagination";
import CloudOffTwoToneIcon from "@material-ui/icons/CloudOffTwoTone";
import RestoreIcon from "@material-ui/icons/Restore";
import StorageIcon from "@material-ui/icons/Storage";
import Skeleton from "@material-ui/lab/Skeleton";

const Qualitative = ({ data, errmsg, getApprove, statecode, spinner }) => {
	const [value, setValue] = React.useState(1);
	const classes = useStyles();
	// console.log(spinner);
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(4);
	// Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	//question 9
	const q9 = data.filter((e) => e.question_9 !== "");
	const q9total = q9.slice(indexOfFirstPost, indexOfLastPost);
	// console.log(q9);
	const handleq9 = (e, value) => {
		setCurrentPage(value);
		//console.log(value);
	};

	//question 13 a
	const q13a = data.filter((e) => e.question_13_a !== "");
	const q13atotal = q13a.slice(indexOfFirstPost, indexOfLastPost);
	//  console.log(q13atotal);
	const handleq13a = (e, value) => {
		setCurrentPage(value);
		//console.log(value);
	};

	//question 15
	const q15 = data.filter((e) => e.question_15_oui_autre !== "");
	const q15atotal = q15.slice(indexOfFirstPost, indexOfLastPost);
	//  console.log(q13atotal);
	const handleq15 = (e, value) => {
		setCurrentPage(value);
		//console.log(value);
	};

	//question 18
	const q18a = data.filter((e) => e.question_18_a_autre !== "");
	const q18atotal = q18a.slice(indexOfFirstPost, indexOfLastPost);
	//  console.log(q13atotal);
	const handleq18a = (e, value) => {
		setCurrentPage(value);
		//console.log(value);
	};

	//question 18b
	const q18b = data.filter((e) => e.question_18_b !== "");
	const q18btotal = q18b.slice(indexOfFirstPost, indexOfLastPost);
	//  console.log(q13atotal);
	const handleq18b = (e, value) => {
		setCurrentPage(value);
		//console.log(value);
	};

	//question 18c
	const q18c = data.filter((e) => e.question_18_c !== "");
	const q18ctotal = q18c.slice(indexOfFirstPost, indexOfLastPost);
	//  console.log(q13atotal);
	const handleq18c = (e, value) => {
		setCurrentPage(value);
		//console.log(value);
	};

	//question 19
	const q19 = data.filter((e) => e.question_19_autre !== "");
	const q19total = q19.slice(indexOfFirstPost, indexOfLastPost);
	//  console.log(q13atotal);
	const handleq19 = (e, value) => {
		setCurrentPage(value);
		//console.log(value);
	};

	//question 20
	const q20 = data.filter((e) => e.question_20_autre !== "");
	const q20total = q20.slice(indexOfFirstPost, indexOfLastPost);
	//  console.log(q13atotal);
	const handleq20 = (e, value) => {
		setCurrentPage(value);
		//console.log(value);
	};

	//question 21
	const q21 = data.filter((e) => e.question_21_autre !== "");
	const q21total = q21.slice(indexOfFirstPost, indexOfLastPost);
	//  console.log(q13atotal);
	const handleq21 = (e, value) => {
		setCurrentPage(value);
		//console.log(value);
	};
	//question 22
	const q22 = data.filter((e) => e.question_22_autre !== "");
	const q22total = q22.slice(indexOfFirstPost, indexOfLastPost);
	//  console.log(q13atotal);
	const handleq22 = (e, value) => {
		setCurrentPage(value);
		//console.log(value);
	};
	//question 26
	const q26 = data.filter((e) => e.question_26 !== "");
	const q26total = q26.slice(indexOfFirstPost, indexOfLastPost);
	//  console.log(q13atotal);
	const handleq26 = (e, value) => {
		setCurrentPage(value);
		//console.log(value);
	};

	const handletoal = (num) => {
		const value = Math.ceil(num.length / postsPerPage);
		//(num.length%postsPerPage)-1
		return value;
	};
	const handletoal2 = (num) => {
		const value = Math.ceil(num.length / postsPerPage);
		//(num.length%postsPerPage)-1
		return value;
	};
	const handletoal3 = (num) => {
		const value = Math.ceil(num.length / postsPerPage);
		//(num.length%postsPerPage)-1
		return value;
	};
	const handletoal4 = (num) => {
		const value = Math.ceil(num.length / postsPerPage);
		//(num.length%postsPerPage)-1
		return value;
	};
	const handletoal5 = (num) => {
		const value = Math.ceil(num.length / postsPerPage);
		//(num.length%postsPerPage)-1
		return value;
	};
	const handletoal6 = (num) => {
		const value = Math.ceil(num.length / postsPerPage);
		//(num.length%postsPerPage)-1
		return value;
	};
	const handletoal7 = (num) => {
		const value = Math.ceil(num.length / postsPerPage);
		//(num.length%postsPerPage)-1
		return value;
	};
	const handletoal8 = (num) => {
		const value = Math.ceil(num.length / postsPerPage);
		//(num.length%postsPerPage)-1
		return value;
	};
	const handletoala = (num) => {
		const value = Math.ceil(num.length / postsPerPage);
		//(num.length%postsPerPage)-1
		return value;
	};
	const handletoalb = (num) => {
		const value = Math.ceil(num.length / postsPerPage);
		//(num.length%postsPerPage)-1
		return value;
	};
	const handletoalc = (num) => {
		const value = Math.ceil(num.length / postsPerPage);
		//(num.length%postsPerPage)-1
		return value;
	};

	const handleChange = (event) => {
		setValue(event.target.value);
	};

	const displayContent = () => {
		if (value === 1) {
			return (
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12}>
						<Typography className={classes.detailTypo}>
							9. Description de l'acte de VD – Que s'est-il passé ?
						</Typography>
					</Grid>

					{q9total.map((e) => {
						return (
							<Grid item xs={12} sm={6} key={e.id}>
								{
									<Grid
										container
										style={{
											backgroundColor: "#E0F3F5",
											borderRadius: 4,
											padding: 5,

										}}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.titleanswer}>
												{e.caseid} 
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer_view}>
												{e.question_9}
											</Typography>
										</Grid>
										<Grid>
											<Grid
												container
												alignItems="flex-end"
												className={classes.margin_Bottom_20}
											>
												<Grid>
													<Typography className={classes.footerDate}>
														Created: {e.date_created}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								}
							</Grid>
						);
					})}
					<Grid container justifyContent="flex-end" alignItems="flex-end">
						<Grid>
							<Pagination
								count={handletoal(q9)}
								page={currentPage}
								onChange={handleq9}
								defaultPage={currentPage}
								variant="outlined"
								color="secondary"
							/>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 2) {
			return (
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12}>
						<Typography className={classes.detailTypo}>
							13. Impact de l'acte de VD – Quelles ont été les conséquences de
							l'acte de violence dans la vie de la victime ?
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography className={classes.detailTypo}>
							a. Demandez la victime ce qui a changé dans sa vie à cause des
							violences domestiques subies, donc les conséquences causées par
							l'acte de violence dans sa vie et ses relation.
						</Typography>
					</Grid>

					{q13atotal.map((e) => {
						return (
							<Grid item xs={12} sm={6}>
								{
									<Grid
										container
										style={{
											backgroundColor: "#E0F3F5",
											borderRadius: 4,
											padding: 5,
										}}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.titleanswer}>
												{e.caseid}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer_view}>
												{e.question_13_a}
											</Typography>
										</Grid>
										<Grid>
											<Grid Container alignItems="flex-end">
												<Grid>
													<Typography className={classes.footerDate}>
														Created: {e.date_created}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								}
							</Grid>
						);
					})}
					<Grid container justifyContent="flex-end" alignItems="flex-end">
						<Grid>
							<Pagination
								count={handletoal2(q13a)}
								page={currentPage}
								onChange={handleq13a}
								defaultPage={currentPage}
								variant="outlined"
								color="secondary"
							/>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 3) {
			return (
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12}>
						<Typography className={classes.detailTypo}>
							15. Est-ce que l'agresseur et la victime habitent ensemble ?
						</Typography>
						<Typography className={classes.detailTypo}>
							<small>
								« Autre situation de vie en proximité, notamment : »
							</small>
						</Typography>
					</Grid>

					{q15atotal.map((e) => {
						return (
							<Grid item xs={12} sm={6}>
								{
									<Grid
										container
										style={{
											backgroundColor: "#E0F3F5",
											borderRadius: 4,
											padding: 5,
										}}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.titleanswer}>
												{e.caseid}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer_view}>
												{e.question_15_oui_autre}
											</Typography>
										</Grid>
										<Grid>
											<Grid Container alignItems="flex-end">
												<Grid>
													<Typography className={classes.footerDate}>
														Created: {e.date_created}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								}
							</Grid>
						);
					})}
					<Grid container justifyContent="flex-end" alignItems="flex-end">
						<Grid>
							<Pagination
								count={handletoal3(q15)}
								page={currentPage}
								onChange={handleq15}
								defaultPage={currentPage}
								variant="outlined"
								color="secondary"
							/>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 4) {
			return (
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12}>
						<Typography className={classes.detailTypo}>
							18. Est-ce que la victime a déjà bénéficié de services de prise en
							charge comme réponse à l'acte de violence domestique documenté ici
							?
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography className={classes.detailTypo}>
							a. De quel(s) volet(s) de prise en charge la victime a-t-elle
							bénéficié ?
						</Typography>
						<Typography className={classes.detailTypo}>
							<small> « Autre, notamment : »</small>
						</Typography>
					</Grid>

					{q18atotal.map((e) => {
						return (
							<Grid item xs={12} sm={6}>
								{
									<Grid
										container
										style={{
											backgroundColor: "#E0F3F5",
											borderRadius: 4,
											padding: 5,
										}}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.titleanswer}>
												{e.caseid}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer_view}>
												{e.question_18_a_autre}
											</Typography>
										</Grid>
										<Grid>
											<Grid Container alignItems="flex-end">
												<Grid>
													<Typography className={classes.footerDate}>
														Created: {e.date_created}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								}
							</Grid>
						);
					})}
					<Grid
						container
						justifyContent="flex-end"
						alignItems="flex-end"
						className={classes.margin_Bottom_20}
					>
						<Grid>
							<Pagination
								count={handletoala(q18a)}
								page={currentPage}
								onChange={handleq18a}
								defaultPage={currentPage}
								variant="outlined"
								color="secondary"
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography className={classes.detailTypo}>
							b. Quand est-ce que la victime a bénéficié des formes de prise en
							charge mentionnés ci-dessus ?
						</Typography>
					</Grid>
					{q18btotal.map((e) => {
						return (
							<Grid item xs={12} sm={6}>
								{
									<Grid
										container
										style={{
											backgroundColor: "#E0F3F5",
											borderRadius: 4,
											padding: 5,
										}}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.titleanswer}>
												{e.caseid}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer_view}>
												{e.question_18_b}
											</Typography>
										</Grid>
										<Grid>
											<Grid Container alignItems="flex-end">
												<Grid>
													<Typography className={classes.footerDate}>
														Created: {e.date_created}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								}
							</Grid>
						);
					})}
					<Grid
						container
						justifyContent="flex-end"
						alignItems="flex-end"
						className={classes.margin_Bottom_20}
					>
						<Grid>
							<Pagination
								count={handletoalb(q18b)}
								page={currentPage}
								onChange={handleq18b}
								defaultPage={currentPage}
								variant="outlined"
								color="secondary"
							/>
						</Grid>
					</Grid>

					<Grid item xs={12} sm={12}>
						<Typography className={classes.detailTypo}>
							c. Quelle(s) structure(s), organisation(s) ou institution(s) ont
							appuyé la victime en assurant une prise en charge ?
						</Typography>
					</Grid>
					{q18ctotal.map((e) => {
						return (
							<Grid item xs={12} sm={6}>
								{
									<Grid
										container
										style={{
											backgroundColor: "#E0F3F5",
											borderRadius: 4,
											padding: 5,
										}}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.titleanswer}>
												{e.caseid}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer_view}>
												{e.question_18_c}
											</Typography>
										</Grid>
										<Grid>
											<Grid Container alignItems="flex-end">
												<Grid>
													<Typography className={classes.footerDate}>
														Created: {e.date_created}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								}
							</Grid>
						);
					})}
					<Grid
						container
						justifyContent="flex-end"
						alignItems="flex-end"
						className={classes.margin_Bottom_20}
					>
						<Grid>
							<Pagination
								count={handletoalc(q18c)}
								page={currentPage}
								onChange={handleq18c}
								defaultPage={currentPage}
								variant="outlined"
								color="secondary"
							/>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 5) {
			return (
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12}>
						<Typography className={classes.detailTypo}>
							19. Est-ce que la victime pense avoir encore besoin de certains
							volets de prise en charge pour répondre à l'acte de violence
							domestique documenté ici ?
						</Typography>
						<Typography className={classes.detailTypo}>
							<small> « Autre, notamment : »</small>
						</Typography>
					</Grid>

					{q19total.map((e) => {
						return (
							<Grid item xs={12} sm={6}>
								<Grid
									container
									style={{
										backgroundColor: "#E0F3F5",
										borderRadius: 4,
										padding: 5,
									}}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.titleanswer}>
											{e.caseid}
										</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.answer_view}>
											{e.question_19_autre}
										</Typography>
									</Grid>
									<Grid>
										<Grid Container alignItems="flex-end">
											<Grid>
												<Typography className={classes.footerDate}>
													Created: {e.date_created}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						);
					})}
					<Grid
						container
						justifyContent="flex-end"
						alignItems="flex-end"
						className={classes.margin_Bottom_20}
					>
						<Grid>
							<Pagination
								count={handletoal4(q19)}
								page={currentPage}
								onChange={handleq19}
								defaultPage={currentPage}
								variant="outlined"
								color="secondary"
							/>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 6) {
			return (
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12}>
						<Typography className={classes.detailTypo}>
							20. En vue des services de prise en charge dont la victime a
							encore besoin (cf. question 19), pour quel(s) volet(s)
							l'organisation qui documente ce cas est-elle capable de fournir
							ces services elle-même ?{" "}
						</Typography>
						<Typography className={classes.detailTypo}>
							<small> « Autre, notamment : »</small>
						</Typography>
					</Grid>

					{q20total.map((e) => {
						return (
							<Grid item xs={12} sm={6}>
								{
									<Grid
										container
										style={{
											backgroundColor: "#E0F3F5",
											borderRadius: 4,
											padding: 5,
										}}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.titleanswer}>
												{e.caseid}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer_view}>
												{e.question_20_autre}
											</Typography>
										</Grid>
										<Grid>
											<Grid Container alignItems="flex-end">
												<Grid>
													<Typography className={classes.footerDate}>
														Created: {e.date_created}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								}
							</Grid>
						);
					})}
					<Grid
						container
						justifyContent="flex-end"
						alignItems="flex-end"
						className={classes.margin_Bottom_20}
					>
						<Grid>
							<Pagination
								count={handletoal5(q20)}
								page={currentPage}
								onChange={handleq20}
								defaultPage={currentPage}
								variant="outlined"
								color="secondary"
							/>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 7) {
			return (
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12}>
						<Typography className={classes.detailTypo}>
							21. En vue des services de prise en charge dont la victime a
							encore besoin (cf. question 19), pour quel(s) volet(s)
							l’organisation qui documente ce cas est-elle capable de référer la
							victime ?
						</Typography>
						<Typography className={classes.detailTypo}>
							<small> « Autre, notamment : »</small>
						</Typography>
					</Grid>

					{q21total.map((e) => {
						return (
							<Grid item xs={12} sm={6}>
								{
									<Grid
										container
										style={{
											backgroundColor: "#E0F3F5",
											borderRadius: 4,
											padding: 5,
										}}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.titleanswer}>
												{e.caseid}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer_view}>
												{e.question_21_autre}
											</Typography>
										</Grid>
										<Grid>
											<Grid Container alignItems="flex-end">
												<Grid>
													<Typography className={classes.footerDate}>
														Created: {e.date_created}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								}
							</Grid>
						);
					})}
					<Grid
						container
						justifyContent="flex-end"
						alignItems="flex-end"
						className={classes.margin_Bottom_20}
					>
						<Grid>
							<Pagination
								count={handletoal6(q21)}
								page={currentPage}
								onChange={handleq21}
								defaultPage={currentPage}
								variant="outlined"
								color="secondary"
							/>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 8) {
			return (
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12}>
						<Typography className={classes.detailTypo}>
							22. En vue des services de prise en charge dont la victime a
							encore besoin (cf. question 19) pour quels volets est-il
							impossible de trouver une prise en charge dans l'immédiat ?
						</Typography>
						<Typography className={classes.detailTypo}>
							<small> « Autre, notamment : »</small>
						</Typography>
					</Grid>

					{q22total.map((e) => {
						return (
							<Grid item xs={12} sm={6}>
								{
									<Grid
										container
										style={{
											backgroundColor: "#E0F3F5",
											borderRadius: 4,
											padding: 5,
										}}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.titleanswer}>
												{e.caseid}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer_view}>
												{e.question_22_autre}
											</Typography>
										</Grid>
										<Grid>
											<Grid Container alignItems="flex-end">
												<Grid>
													<Typography className={classes.footerDate}>
														Created: {e.date_created}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								}
							</Grid>
						);
					})}
					<Grid
						container
						justifyContent="flex-end"
						alignItems="flex-end"
						className={classes.margin_Bottom_20}
					>
						<Grid>
							<Pagination
								count={handletoal7(q22)}
								page={currentPage}
								onChange={handleq22}
								defaultPage={currentPage}
								variant="outlined"
								color="secondary"
							/>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 9) {
			return (
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12}>
						<Typography className={classes.detailTypo}>
							26. Quel est le message direct que la victime souhaite de partager
							?
						</Typography>
					</Grid>

					{q26total.map((e) => {
						return (
							<Grid item xs={12} sm={6}>
								{
									<Grid
										container
										style={{
											backgroundColor: "#E0F3F5",
											borderRadius: 4,
											padding: 5,
										}}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.titleanswer}>
												{e.caseid}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer_view}>
												{e.question_26}
											</Typography>
										</Grid>
										<Grid>
											<Grid Container alignItems="flex-end">
												<Grid>
													<Typography className={classes.footerDate}>
														Created: {e.date_created}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								}
							</Grid>
						);
					})}
					<Grid
						container
						justifyContent="flex-end"
						alignItems="flex-end"
						className={classes.margin_Bottom_20}
					>
						<Grid>
							<Pagination
								count={handletoal8(q26)}
								page={currentPage}
								onChange={handleq26}
								defaultPage={currentPage}
								variant="outlined"
								color="secondary"
							/>
						</Grid>
					</Grid>
				</Grid>
			);
		}
	};

	const displayData = () => {
		if (statecode === false) {
			if (spinner === true) {
				return (
					<Grid container spacing={4}>
						<Grid item xs={12} sm={6}>
							<Skeleton variant="rect" height={40} />
						</Grid>
						<Grid item xs={12} sm={12}>
							<Skeleton variant="text" />
						</Grid>
						<Grid item xs={12} sm={12}>
							<Grid container direction="row" spacing={4}>
								<Grid item xs={12} sm={3}>
									{" "}
									<Skeleton variant="rect" height={100} />
								</Grid>
								<Grid item xs={12} sm={3}>
									{" "}
									<Skeleton variant="rect" height={100} />
								</Grid>
								<Grid item xs={12} sm={3}>
									{" "}
									<Skeleton variant="rect" height={100} />
								</Grid>
								<Grid item xs={12} sm={3}>
									{" "}
									<Skeleton variant="rect" height={100} />
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={4}>
								<Grid item xs={12} sm={3}>
									{" "}
									<Skeleton variant="rect" height={100} />
								</Grid>
								<Grid item xs={12} sm={3}>
									{" "}
									<Skeleton variant="rect" height={100} />
								</Grid>
								<Grid item xs={12} sm={3}>
									{" "}
									<Skeleton variant="rect" height={100} />
								</Grid>
								<Grid item xs={12} sm={3}>
									{" "}
									<Skeleton variant="rect" height={100} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				);
			} else {
				return (
					<Grid container direction="row" justifyContent="center">
						<Grid item xs={12} sm={4}></Grid>
						<Grid item xs={12} sm={4}>
							<Grid container justifyContent="center">
								<Grid item>
									<CloudOffTwoToneIcon size={40} />
								</Grid>
								<Grid item>
									<Typography className={classes.detailTypo}>
										{errmsg}
									</Typography>
								</Grid>
							</Grid>
							<Grid container justifyContent="center">
								<Grid item>
									<IconButton
										color="primary"
										aria-label="Restart"
										component="span"
										onClick={() => getApprove()}
									>
										<RestoreIcon />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={4}></Grid>
					</Grid>
				);
			}
		} else if (statecode === true && data === "") {
			return (
				<Grid container direction="row" justifyContent="center">
					<Grid xs={12} sm={4}></Grid>
					<Grid xs={12} sm={4}>
						<Grid container direction="row">
							<Grid item xs={12} sm={4}>
								<StorageIcon size={60} />
							</Grid>
							<Grid item xs={12} sm={8}>
								<Typography className={classes.detailTypo}>
									No data found
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid xs={12} sm={4}></Grid>
				</Grid>
			);
		} else if (statecode === true && data !== "") {
			return (
				<div>
					<Grid container>
						<Grid item xs={12} sm={12}>
							{/* selector for question */}
							<Grid container className={classes.margin_Bottom_30}>
								<Grid item xs={12} sm={6}>
									<Select
										value={value}
										onChange={handleChange}
										variant="outlined"
										style={{ height: 60 }}
										fullWidth
										autoWidth
										className={classes.inputSelector}
										size="small"
									>
										<MenuItem className={classes.detailTypo} value={1}>
											9. Description de l'acte de VD – Que s'est-il passé ?
										</MenuItem>
										<MenuItem className={classes.detailTypo} value={2}>
											13. Impact de l'acte de VD – Quelles ont été les
											conséquences <br /> de l'acte de violence dans la vie de
											la victime ?
										</MenuItem>
										<MenuItem className={classes.detailTypo} value={3}>
											15. Est-ce que l'agresseur et la victime habitent ensemble
											?
										</MenuItem>
										<MenuItem className={classes.detailTypo} value={4}>
											18. Est-ce que la victime a déjà bénéficié de services de{" "}
											<br /> prise en charge comme réponse à l'acte de violence
											domestique documenté ici ?
										</MenuItem>
										<MenuItem className={classes.detailTypo} value={5}>
											19. Est-ce que la victime pense avoir encore besoin de
											certains <br /> volets de prise en charge pour répondre à
											l'acte de violence domestique documenté ici ?
										</MenuItem>
										<MenuItem className={classes.detailTypo} value={6}>
											20. En vue des services de prise en charge dont la victime
											a <br /> encore besoin (cf. question 19), pour quel(s)
											volet(s) <br />l 'organisation qui documente ce cas
											est-elle capable de fournir ces services elle-même ?{" "}
										</MenuItem>
										<MenuItem className={classes.detailTypo} value={7}>
											21. En vue des services de prise en charge dont la victime
											a <br /> encore besoin (cf. question 19), pour quel(s)
											volet(s) <br /> l’organisation qui documente ce cas
											est-elle capable de référer la victime ?
										</MenuItem>
										<MenuItem className={classes.detailTypo} value={8}>
											22. En vue des services de prise en charge dont la victime
											a <br /> encore besoin (cf. question 19) pour quels volets
											est-il impossible de trouver une prise en charge dans
											l'immédiat ?{" "}
										</MenuItem>
										<MenuItem className={classes.detailTypo} value={9}>
											26. Quel est le message direct que la victime souhaite{" "}
											<br /> de partager ?
										</MenuItem>
									</Select>
								</Grid>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography className={classes.answer}>Answers</Typography>
							</Grid>

							{displayContent()}
						</Grid>
					</Grid>
				</div>
			);
		}
	};
	return <div>{displayData()}</div>;
};

export default Qualitative;

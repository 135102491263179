import React, { useState } from "react"; //useEffect
import { makeStyles } from "@material-ui/core/styles";
import {
	Grid,
	Typography,
	Box,
	FormGroup,
	Checkbox,
	List,
	Divider,
	TextField,
	RadioGroup,
	Radio,
	FormControlLabel,
	FormControl,
	// TextareaAutosize,
	Button,
	// Dialog,
	// DialogTitle,
	// DialogContent,
	// DialogContentText,
	// DialogActions,
	// Paper,
	IconButton,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { DataGrid } from "@material-ui/data-grid";
// import {
// 	q1,
// 	q2,
// 	q3,
// 	q4,
// 	q5,
// 	q6,
// 	q6a,
// 	q6b,
// 	q7,
// 	q8,
// 	q8a,
// 	q8b,
// 	q8c,
// 	q8d,
// 	q8e,
// 	q8f,
// 	q9,
// 	q10,
// 	q11,
// 	q11a,
// 	q11b,
// 	q12,
// 	q12a,
// 	q12b,
// 	q13,
// 	q13a,
// 	q13b,
// 	q14,
// 	q14a,
// 	q14b,
// 	q14c,
// 	q15,
// 	q16,
// 	q17,
// 	q18,
// 	q18a,
// 	q18b,
// 	q18c,
// 	q19,
// 	q20,
// 	q21,
// 	q22,
// 	q23,
// 	q24,
// 	q24a,
// 	q25,
// 	q26,
// 	q27,
// } from "../../../widget/i1e";
//import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CancelIcon from "@material-ui/icons/Cancel";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	table: {
		height: 400,
	},
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		//border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(1, 4, 8),
		width: 500,
		height: 650,
		[theme.breakpoints.down("xs")]: {
			marginTop: 20,
			width: 350,
		},
	},
	label: {
		fontSize: 15,
		color: "#2D2C2F",
		fontWeight: 300,
		// marginBottom: "10px",
	},
	// label:{
	//     color: '#4F2970',
	//     fontSize: '14px',
	//     fontWeight:200,
	//     marginBottom:"10px"
	// },
	// label:{
	//     color: '#4F2970',
	//     fontSize: '14px',
	//     fontWeight:200
	// },
	radiolabel: {
		"& .MuiInputBase-root": {
			padding: 10,
		},
	},
	margin_Bottom_30: {
		marginBottom: 30,
	},
	answer: {
		color: "#00A1AF",
		fontSize: 12,
		fontWeight: 300,
	},
	answerB: {
		color: "#00A1AF",
		fontSize: 14,
		fontWeight: 400,
	},
	answer_view: {
		marginTop: 10,
		marginRight: 5,
		marginLeft: 5,
		color: "#00A1AF",
		fontWeight: 300,
	},
	titleanswer: {
		marginTop: 10,
		marginRight: 5,
		marginLeft: 5,
		fontWeight: 400,
	},
	button: {
		textTransform: "unset",
		fontSize: 15,
		color: "#2D2C2F",
		fontWeight: 300,
		textAlign: "left",
	},
	checkbox: {
		fontSize: 50,
	},
}));

export default function Casedatabank({ casemap, active }) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [rowData, setrowData] = useState([]);
	const { t } = useTranslation();

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onRowClick = (row) => {
		setrowData(row.row);

		handleOpen();
	};

	const columns = [
		{ field: "caseid", headerName: `${t("case-databank-caseid")}`, width: 170 },
		{
			field: "question_6_b",
			headerName: `${t("case-databank-age")}`,
			width: 170,
		},
		{
			field: "question_7",
			headerName: `${t("case-databank-gender")}`,
			width: 100,
		},
		{
			field: "question_8_a",
			headerName: `${t("case-databank-civil")}`,
			width: 220,
		},
		{
			field: "question_8_c",
			headerName: `${t("case-databank-living-hand")}`,
			width: 220,
		},

		{
			field: "question_11_a_oui",
			headerName: `${t("case-databank-begin")}`,
			width: 220,
		},
		{
			field: "question_11_b_oui",
			headerName: `${t("case-databank-occured")}`,
			width: 220,
		},
		{
			field: "province",
			headerName: `${t("case-databank-prov")}`,
			width: 180,
		},
		{
			field: "city",
			headerName: `${t("case-databank-city")}`,
			width: 180,
		},
	];

	//console.log(rowData);

	const displayData = () => {
		return (
			<List style={{ maxHeight: 450, overflow: "auto" }}>
				{/* QUESTION 4 */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-detail-q1")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.answerB}>
							{rowData.question_4}
						</Typography>
					</Grid>
				</Grid>

				{/* QUESTION 6 B */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-statistic-box3")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.answerB}>
							{rowData.question_6_b}
						</Typography>
					</Grid>
				</Grid>

				{/* QUESTION 7 */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-statistic-box4")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.answerB}>
							{rowData.question_7}
						</Typography>
					</Grid>
				</Grid>

				{/* QUESTION 8A */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-statistic-box5")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.answerB}>
							{rowData.question_8_a}
						</Typography>
					</Grid>
				</Grid>

				{/* QUESTION 8B */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-statistic-box6")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<FormControl component="fieldset">
							<RadioGroup name="question_8_b" className={classes.answer}>
								<FormControlLabel
									value={rowData.question_8_b}
									style={{cursor: "text"}}
									checked
									control={<Radio size="small" />}
									label={
										<Box component="div" fontSize={13}>
											{rowData.question_8_b}
										</Box>
									}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
				</Grid>

				{/* QUESTION 8C */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-statistic-box7")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<FormControl component="fieldset">
							<RadioGroup name="question_8_c" className={classes.answer}>
								<FormControlLabel
									value={rowData.question_8_c}
									style={{cursor: "text"}}
									checked
									control={<Radio size="small" />}
									label={
										<Box component="div" fontSize={13}>
											{rowData.question_8_c}
										</Box>
									}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
				</Grid>

				{/* QUESTION 8D */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-databank-victim-with-occupation")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<FormControl component="fieldset">
							<RadioGroup name="question_8_d" className={classes.answer}>
								<FormControlLabel
									value={rowData.question_8_d}
									style={{cursor: "text"}}
									checked
									control={<Radio size="small" />}
									label={
										<Box component="div" fontSize={13}>
											{rowData.question_8_d}
										</Box>
									}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
				</Grid>

				{/* QUESTION 8E */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-statistic-box11")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<FormControl component="fieldset">
							<RadioGroup name="question_8_e" className={classes.answer}>
								<FormControlLabel
									value={rowData.question_8_e}
									style={{cursor: "text"}}
									checked
									control={<Radio size="small" />}
									label={
										<Box component="div" fontSize={13}>
											{rowData.question_8_e}
										</Box>
									}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
				</Grid>

				{/* QUESTION 8F */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-statistic-box12")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<FormControl component="fieldset">
							<RadioGroup name="question_8_f" className={classes.answer}>
								<FormControlLabel
									value={rowData.question_8_f}
									style={{cursor: "text"}}
									checked
									control={<Radio size="small" />}
									label={
										<Box component="div" fontSize={13}>
											{rowData.question_8_f}
										</Box>
									}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
				</Grid>

				{/* QUESTION 10A */}
				<Typography
					className={classes.label}
					style={{
						fontWeight: 500,
						marginBottom: 10,
						textTransform: "uppercase",
					}}
				>
					{t("case-statistic-type-violence")}
				</Typography>
				{rowData.question_10_a === null ||
				rowData.question_10_a === undefined ? (
					""
				) : rowData.question_10_a.filter(Boolean).length === 0 ? (
					""
				) : (
					<Grid container>
						<Grid item xs={12} sm={12}>
							<Button
								variant="text"
								color="default"
								className={classes.label}
								style={{ padding: 0, textAlign: "left", cursor: "text" }}
								startIcon={<ArrowDropDownIcon style={{ fontSize: 30 }} />}
							>
								{t("case-statistic-violence-categorie1")}
							</Button>
						</Grid>

						<Grid
							item
							xs={12}
							sm={12}
							style={{ paddingLeft: 20, marginBottom: 30 }}
						>
							<FormGroup className={classes.answerB}>
								{rowData.question_10_a
									.filter((e) => e)
									.map((e) => (
										<FormControlLabel
											control={<Checkbox name="" size="small" checked />}
											key={e}
											style={{cursor: "text"}}
											label={
												<Typography className={classes.detailTypo}>
													{/* {e} */}
													{e.indexOf(
														"Viol conjugal entre couples mariés et non mariés"
													) > -1
														? t("case-statistic-incident-of-sex-mar")
														: e.indexOf("Viol") > -1
														? t("case-statistic-incident-of-sex-rape")
														: e.indexOf("Agression sexuelle sur mineur") > -1
														? t("case-statistic-incident-of-sex-sexual")
														: e.indexOf("Coercition sexuelle") > -1
														? t("case-statistic-incident-of-sex-coe")
														: e.indexOf("Harcèlement sexuel") > -1
														? t("case-statistic-incident-of-sex-har")
														: e.indexOf("Attouchements inappropriés") > -1
														? t("case-statistic-incident-of-sex-inappropriete")
														: e.indexOf(
																"Forcer la victime à avoir des relations sexuelles sans préservatif"
														  ) > -1
														? t("case-statistic-incident-of-sex-forcing")
														: e.indexOf(
																"Empêcher la victime de faire ses propres choix quant à la décision d'avoir ou non un bébé"
														  ) > -1
														? t("case-statistic-incident-of-sex-preventing")
														: e.indexOf("Autres") > -1
														? t("case-statistic-incident-of-ecom-other")
														: null}
												</Typography>
											}
										/>
									))}
							</FormGroup>
						</Grid>
					</Grid>
				)}

				{/* QUESTION 10B */}
				{rowData.question_10_b === null ||
				rowData.question_10_b === undefined ? (
					""
				) : rowData.question_10_b.filter(Boolean).length === 0 ? (
					""
				) : (
					<Grid container>
						<Grid item xs={12} sm={12}>
							<Button
								variant="text"
								color="default"
								className={classes.label}
								style={{ padding: 0, textAlign: "left", cursor: "text" }}
								startIcon={<ArrowDropDownIcon style={{ fontSize: 30 }} />}
							>
								{t("case-statistic-violence-categorie2")}
							</Button>
						</Grid>

						<Grid
							item
							xs={12}
							sm={12}
							style={{ paddingLeft: 20, marginBottom: 30 }}
						>
							<FormGroup className={classes.answer}>
								{rowData.question_10_b
									.filter((e) => e)
									.map((e) => (
										<FormControlLabel
											control={<Checkbox name="" size="small" checked />}
											key={e}
											style={{cursor: "text"}}
											label={
												<Typography className={classes.detailTypo}>
													{/* {e} */}
													{e.indexOf("Meurtre / féminicide") > -1
														? t("case-statistic-incident-of-phys-mur")
														: e.indexOf("Coups de poing et/ou coups de pieds") >
														  -1
														? t("case-statistic-incident-of-phys-punch")
														: e.indexOf("Coups portés avec des objets") > -1
														? t("case-statistic-incident-of-phys-punchwithobj")
														: e.indexOf("Attaque à l’acide") > -1
														? t("case-statistic-incident-of-phys-acidattack")
														: e.indexOf("Attaque au couteau ou à la machette") >
														  -1
														? t(
																"case-statistic-incident-of-phys-attackwithknife"
														  )
														: e.indexOf(
																"Attaque à l’eau chaude, à l’huile chaude ou autre objet chaud"
														  ) > -1
														? t(
																"case-statistic-incident-of-phys-attackwithhotwater"
														  )
														: e.indexOf(
																"Privation de la liberté physique de la victime"
														  ) > -1
														? t("case-statistic-incident-of-phys-deprivation")
														: e.indexOf(
																"Privation de nourriture de la victime"
														  ) > -1
														? t(
																"case-statistic-incident-of-phys-fooddeprivatio"
														  )
														: e.indexOf(
																"Négligence physique d’enfant (si la victime est un enfant)"
														  ) > -1
														? t(
																"case-statistic-incident-of-phys-physneglofchild"
														  )
														: e.indexOf(
																"Négligence physique de personnes âgées (si la victime est une personne âgée)"
														  ) > -1
														? t(
																"case-statistic-incident-of-phys-physneglofelder"
														  )
														: e.indexOf(
																"Destruction d’objets dans la maison de la victime"
														  ) > -1
														? t("case-statistic-incident-of-phys-destr")
														: e.indexOf(
																"Rites de veuvage affectant la santé physique et mentale de la victime"
														  ) > -1
														? t("case-statistic-incident-of-phys-risk")
														: e.indexOf("Autres") > -1
														? t("case-statistic-incident-of-ecom-other")
														: null}
												</Typography>
											}
										/>
									))}
							</FormGroup>
						</Grid>
					</Grid>
				)}

				{/* QUESTION 10C */}
				{rowData.question_10_c === null ||
				rowData.question_10_c === undefined ? (
					""
				) : rowData.question_10_c.filter(Boolean).length === 0 ? (
					""
				) : (
					<Grid container>
						<Grid item xs={12} sm={12}>
							<Button
								variant="text"
								color="default"
								className={classes.label}
								style={{ padding: 0, textAlign: "left", cursor: "text" }}
								startIcon={<ArrowDropDownIcon style={{ fontSize: 30 }} />}
							>
								{t("case-statistic-violence-categorie3")}
							</Button>
						</Grid>

						<Grid
							item
							xs={12}
							sm={12}
							style={{ paddingLeft: 20, marginBottom: 30 }}
						>
							<FormGroup className={classes.answer}>
								{rowData.question_10_c
									.filter((e) => e)
									.map((e) => (
										<FormControlLabel
											control={<Checkbox name="" size="small" checked />}
											key={e}
											style={{cursor: "text"}}
											label={
												<Typography className={classes.detailTypo}>
													{/* {e}  */}
													{e.indexOf("Menaces") > -1
														? t("case-statistic-incident-of-emot-threat")
														: e.indexOf("Injures") > -1
														? t("case-statistic-incident-of-emot-insult")
														: e.indexOf("Négligence émotionnelle") > -1
														? t("case-statistic-incident-of-emot-emot")
														: e.indexOf(
																"Humiliation de la victime face aux autres"
														  ) > -1
														? t("case-statistic-incident-of-emot-hum")
														: e.indexOf(
																"Attaques visant à nuire à la réputation de la victime"
														  ) > -1
														? t(
																"case-statistic-incident-of-emot-victimreputatio"
														  )
														: e.indexOf(
																"Isolement de la victime de sa famille d’origine ou ses amis"
														  ) > -1
														? t("case-statistic-incident-of-emot-isol")
														: e.indexOf(
																"Contrôle des mouvements de la victime"
														  ) > -1
														? t("case-statistic-incident-of-emot-ctrmov")
														: e.indexOf(
																"Contrôle de la communication de la victime"
														  ) > -1
														? t("case-statistic-incident-of-emot-ctrmov")
														: e.indexOf(
																"Refus de divorce malgré le souhait de la victime de se divorcer"
														  ) > -1
														? t("case-statistic-incident-of-emot-refus")
														: e.indexOf(
																"Abus spirituel / abus religieux de la victime"
														  ) > -1
														? t("case-statistic-incident-of-emot-spiritual")
														: e.indexOf("Autres") > -1
														? t("case-statistic-incident-of-ecom-other")
														: null}
												</Typography>
											}
										/>
									))}
							</FormGroup>
						</Grid>
					</Grid>
				)}

				{/* QUESTION 10D */}
				{rowData.question_10_d === null ||
				rowData.question_10_d === undefined ? (
					""
				) : rowData.question_10_d.filter(Boolean).length === 0 ? (
					""
				) : (
					<Grid container>
						<Grid item xs={12} sm={12}>
							<Button
								variant="text"
								color="default"
								className={classes.label}
								style={{ padding: 0, textAlign: "left", cursor: "text" }}
								startIcon={<ArrowDropDownIcon style={{ fontSize: 30 }} />}
							>
								{t("case-statistic-violence-categorie4")}
							</Button>
						</Grid>

						<Grid
							item
							xs={12}
							sm={12}
							style={{ paddingLeft: 20, marginBottom: 30 }}
						>
							<FormGroup className={classes.answer}>
								{rowData.question_10_d
									.filter((e) => e)
									.map((e) => (
										<FormControlLabel
											control={<Checkbox name="" size="small" checked />}
											key={e}
											style={{cursor: "text"}}
											label={
												<Typography className={classes.detailTypo}>
													{e.indexOf(
														"L’auteur abandonne ou chasse la victime"
													) > -1
														? t("case-statistic-incident-of-ecom-perp")
														: e.indexOf(
																"Confiscation des revenus de la victime"
														  ) > -1
														? t("case-statistic-incident-of-ecom-forf")
														: e.indexOf(
																"Interdiction de participation à la gestion des revenus du ménage"
														  ) > -1
														? t("case-statistic-incident-of-ecom-prohibitio")
														: e.indexOf(
																"Restriction de l’accès à l'éducation"
														  ) > -1
														? t("case-statistic-incident-of-ecom-restreducatio")
														: e.indexOf("Restriction de l’accès à l'emploi") >
														  -1
														? t("case-statistic-incident-of-ecom-restremploy")
														: e.indexOf(
																"Restriction de l’accès aux ressources économiques"
														  ) > -1
														? t("case-statistic-incident-of-ecom-restrecom")
														: e.indexOf(
																"Privation d’une prise en charge médicale, de médicaments ou d'appareils d’assistance"
														  ) > -1
														? t("case-statistic-incident-of-ecom-depriv")
														: e.indexOf("Lévirat après la mort du conjoint") >
														  -1
														? t("case-statistic-incident-of-ecom-levirat")
														: e.indexOf(
																"Perte des propriétés et des biens / non accès à l’héritage après la mort du conjoint ou d’un parent"
														  ) > -1
														? t(
																"case-statistic-incident-of-ecom-lossofproperty"
														  )
														: e.indexOf(
																"Perte des enfants après la mort du conjoint"
														  ) > -1
														? t(
																"case-statistic-incident-of-ecom-lossofchildren"
														  )
														: e.indexOf("Autres") > -1
														? t("case-statistic-incident-of-ecom-other")
														: null}
													{/* {t("case-statistic-incident-of-ecom-forf")} */}
													{/* {"Confiscation of the victim's income"}
													: {null} */}
													{/* {e} {console.log(e.indexOf("Confiscation des revenus de la victime") > -1 ? "true" : "false")} */}
													{/* {console.log(rowData.question_10_a.filter(item => item.indexOf('Viol conjugal entre couples mariés et non mariés') > -1) ? 'true' : 'false')} */}
												</Typography>
											}
										/>
									))}
							</FormGroup>
						</Grid>
					</Grid>
				)}

				{/* //TODO: refactor this 11a */}
				{/* QUESTION 11A */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Grid container spacing={1}>
							<Grid item sm={12} xs={12}>
								<Typography className={classes.label}>
									{t("case-detail-situation")}
								</Typography>
							</Grid>
							<Grid item sm={12} xs={12}>
								<FormControl component="fieldset">
									<RadioGroup name="question_11_a" className={classes.answer}>
										{rowData.question_11_a === "Oui" ||
										rowData.question_11_a === "Yes" ? (
											<div>
												<FormControlLabel
													value="Oui"
													style={{cursor: "text"}}
													checked
													control={<Radio size="small" />}
													label={
														<Box component="div" fontSize={13}>
															{rowData.question_11_a}
														</Box>
													}
												/>
												<Typography className={classes.label}>
													{t("case-detail-situation-take-place")}
												</Typography>
												<Typography className={classes.answerB}>
													{t("case-detail-month")}{" "}
													{rowData.question_11_a_oui_month}
												</Typography>

												<Typography className={classes.answerB}>
													{t("case-detail-year")} {rowData.question_11_a_oui}
												</Typography>
											</div>
										) : (
											<FormControlLabel
												value="Non"
												style={{cursor: "text"}}
												checked
												control={<Radio size="small" />}
												label={
													<Box component="div" fontSize={13}>
														{rowData.question_11_a}
													</Box>
												}
											/>
										)}
									</RadioGroup>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				{/* //TODO: refactor this 11b */}
				{/* QUESTION 11B */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-detail-on-time")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<FormControl component="fieldset">
							<RadioGroup name="question_11_b" className={classes.answer}>
								{rowData.question_11_b === "Oui" ||
								rowData.question_11_b === "Yes" ? (
									<div>
										<FormControlLabel
											checked
											value="Oui"
											style={{cursor: "text"}}
											control={<Radio size="small" />}
											label={
												<Box component="div" fontSize={13}>
													{rowData.question_11_b}
												</Box>
											}
										/>
										<Typography className={classes.label}>
											{t("case-detail-situation-start")}
										</Typography>
										{rowData.question_11_a_oui_month === "" ? null : (
											<Typography className={classes.answerB}>
												{t("case-detail-month")}{" "}
												{rowData.question_11_b_oui_month}
											</Typography>
										)}
										<Typography className={classes.answerB}>
											{t("case-detail-year")} {rowData.question_11_b_oui}
										</Typography>
									</div>
								) : (
									<FormControlLabel
										checked
										value="Non"
										style={{cursor: "text"}}
										control={<Radio size="small" />}
										label={
											<Box component="div" fontSize={13}>
												{rowData.question_11_b}
											</Box>
										}
									/>
								)}
							</RadioGroup>
						</FormControl>
					</Grid>
				</Grid>

				{/* QUESTION 12A */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-detail-prov-city")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.answerB}>
							{rowData.question_12_a}
						</Typography>
					</Grid>
				</Grid>

				{/* QUESTION 12B */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-detail-type-area")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.answerB}>
							{rowData.question_12_b}
						</Typography>
					</Grid>
				</Grid>

				{/* QUESTION 13 B */}
				<Typography
					className={classes.label}
					style={{
						fontWeight: 500,
						marginBottom: 10,
						textTransform: "uppercase",
					}}
				>
					{t("case-detail-category-of-harm")}
				</Typography>

				{/* QUESTION 13B A */}
				{rowData.question_13_b_a === null ||
				rowData.question_13_b_a === undefined ? (
					""
				) : rowData.question_13_b_a.filter(Boolean).length === 0 ? (
					""
				) : (
					<Grid container>
						<Grid item xs={12} sm={12}>
							<Button
								variant="text"
								color="default"
								className={classes.label}
								style={{ padding: 0, textAlign: "left", cursor: "text" }}
								startIcon={<ArrowDropDownIcon style={{ fontSize: 30 }} />}
							>
								{t("case-statistic-categ-of-harm-phys")}
							</Button>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							style={{ paddingLeft: 20, marginBottom: 30 }}
						>
							<FormGroup className={classes.answerB}>
								{rowData.question_13_b_a
									.filter((e) => e)
									.map((e) => (
										<FormControlLabel
											control={<Checkbox name="" size="small" checked />}
											key={e}
											style={{cursor: "text"}}
											label={
												<Typography className={classes.detailTypo}>
													{/* {e} */}
													{e.indexOf("Décès") > -1
														? t("case-statistic-categ-of-harm-phys-death")
														: e.indexOf("Maladies (par exemple hypertension)") >
														  -1
														? t("case-statistic-categ-of-harm-phys-ill")
														: e.indexOf("Blessures") > -1
														? t("case-statistic-categ-of-harm-phys-inj")
														: e.indexOf("Douleurs") > -1
														? t("case-statistic-categ-of-harm-phys-pain")
														: e.indexOf(
																"Troubles gynécologiques et reproductifs"
														  ) > -1
														? t("case-statistic-categ-of-harm-phys-gyn")
														: e.indexOf("Invalidité / Handicap") > -1
														? t("case-statistic-categ-of-harm-phys-disa")
														: e.indexOf("Grossesse non voulue") > -1
														? t("case-statistic-categ-of-harm-phys-uni")
														: e.indexOf("Perte de l'enfant à naître") > -1
														? t("case-statistic-categ-of-harm-phys-loss")
														: e.indexOf("Abus d'alcool") > -1
														? t("case-statistic-categ-of-harm-phys-alcool")
														: e.indexOf("Abus de drogues") > -1
														? t("case-statistic-categ-of-harm-phys-drug")
														: e.indexOf("Abus de médicaments") > -1
														? t("case-statistic-categ-of-harm-phys-med")
														: e.indexOf("Autres") > -1
														? t("case-statistic-incident-of-ecom-other")
														: null}
												</Typography>
											}
										/>
									))}
							</FormGroup>
						</Grid>
					</Grid>
				)}

				{/* QUESTION 13B B */}
				{rowData.question_13_b_b === null ||
				rowData.question_13_b_b === undefined ? (
					""
				) : rowData.question_13_b_b.filter(Boolean).length === 0 ? (
					""
				) : (
					<Grid container>
						<Grid item xs={12} sm={12}>
							<Button
								variant="text"
								color="default"
								className={classes.label}
								style={{ padding: 0, textAlign: "left", cursor: "text" }}
								startIcon={<ArrowDropDownIcon style={{ fontSize: 30 }} />}
							>
								{t("case-statistic-categ-of-harm-em")}
							</Button>
						</Grid>

						<Grid
							item
							xs={12}
							sm={12}
							style={{ paddingLeft: 20, marginBottom: 30 }}
						>
							<FormGroup className={classes.answerB}>
								{rowData.question_13_b_b
									.filter((e) => e)
									.map((e) => (
										<FormControlLabel
											control={<Checkbox name="" size="small" checked />}
											key={e}
											style={{cursor: "text"}}
											label={
												<Typography className={classes.detailTypo}>
													{/* {e} */}
													{e.indexOf("Risque de suicide") > -1
														? t("case-statistic-categ-of-harm-em-risk")
														: e.indexOf("Peur et anxiété") > -1
														? t("case-statistic-categ-of-harm-em-fear")
														: e.indexOf("Dépression") > -1
														? t("case-statistic-categ-of-harm-em-depr")
														: e.indexOf("Détresse émotionnelle") > -1
														? t("case-statistic-categ-of-harm-em-em")
														: e.indexOf("Sentiment de honte") > -1
														? t(
																"case-statistic-categ-of-harm-em-feelings-shame"
														  )
														: e.indexOf("Sentiment de colère") > -1
														? t(
																"case-statistic-categ-of-harm-em-feelings-anger"
														  )
														: e.indexOf("Troubles de sommeil") > -1
														? t("case-statistic-categ-of-harm-em-sleep-dis")
														: e.indexOf("Troubles alimentaires") > -1
														? t("case-statistic-categ-of-harm-em-eat")
														: e.indexOf("Perte d’espoir") > -1
														? t("case-statistic-categ-of-harm-em-loss")
														: e.indexOf("Manque de confiance en soi") > -1
														? t("case-statistic-categ-of-harm-phys-drug")
														: e.indexOf("Abus de médicaments") > -1
														? t("case-statistic-categ-of-harm-em-lack")
														: e.indexOf("Autres") > -1
														? t("case-statistic-incident-of-ecom-other")
														: null}
												</Typography>
											}
										/>
									))}
							</FormGroup>
						</Grid>
					</Grid>
				)}

				{/* QUESTION 13B C */}
				{rowData.question_13_b_c === null ||
				rowData.question_13_b_c === undefined ? (
					""
				) : rowData.question_13_b_c.filter(Boolean).length === 0 ? (
					""
				) : (
					<Grid container>
						<Grid item xs={12} sm={12}>
							<Button
								variant="text"
								color="default"
								className={classes.label}
								style={{ padding: 0, textAlign: "left", cursor: "text" }}
								startIcon={<ArrowDropDownIcon style={{ fontSize: 30 }} />}
							>
								{t("case-statistic-categ-of-harm-econ")}
							</Button>
						</Grid>

						<Grid
							item
							xs={12}
							sm={12}
							style={{ paddingLeft: 20, marginBottom: 30 }}
						>
							<FormGroup className={classes.answerB}>
								{rowData.question_13_b_c
									.filter((e) => e)
									.map((e) => (
										<FormControlLabel
											control={<Checkbox name="" size="small" checked />}
											key={e}
											style={{cursor: "text"}}
											label={
												<Typography className={classes.detailTypo}>
													{/* {e} */} {console.log(e)}
													{e.indexOf(
														"Perte ou manque des moyens de subsistance"
													) > -1
														? t("case-statistic-categ-of-harm-econ-loss")
														: e.indexOf("Perte ou manque de revenus") > -1
														? t("case-statistic-categ-of-harm-econ-incom")
														: e.indexOf("Sexe de survie") > -1
														? t("case-statistic-categ-of-harm-econ-surv")
														: e.indexOf("Perte de maison") > -1
														? t("case-statistic-categ-of-harm-econ-home")
														: e.indexOf("Déménagements fréquents") > -1
														? t("case-statistic-categ-of-harm-econ-freq")
														: e.indexOf("Situation de sans-abri") > -1
														? t("case-statistic-categ-of-harm-econ-homeless")
														: e.indexOf(
																"Pas ou peu d'éducation ou de formation"
														  ) > -1
														? t("case-statistic-categ-of-harm-econ-no")
														: e.indexOf("Autres") > -1
														? t("case-statistic-incident-of-ecom-other")
														: null}
												</Typography>
											}
										/>
									))}
							</FormGroup>
						</Grid>
					</Grid>
				)}

				{/* QUESTION 13B D */}
				{rowData.question_13_b_d === null ||
				rowData.question_13_b_d === undefined ? (
					""
				) : rowData.question_13_b_d.filter(Boolean).length === 0 ? (
					""
				) : (
					<Grid container>
						<Grid item xs={12} sm={12}>
							<Button
								variant="text"
								color="default"
								className={classes.label}
								style={{ padding: 0, textAlign: "left", cursor: "text" }}
								startIcon={<ArrowDropDownIcon style={{ fontSize: 30 }} />}
							>
								{t("case-statistic-categ-of-harm-impact")}
							</Button>
						</Grid>

						<Grid
							item
							xs={12}
							sm={12}
							style={{ paddingLeft: 20, marginBottom: 30 }}
						>
							<FormGroup className={classes.answerB}>
								{rowData.question_13_b_d
									.filter((e) => e)
									.map((e) => (
										<FormControlLabel
											control={<Checkbox name="" size="small" checked />}
											key={e}
											style={{cursor: "text"}}
											label={
												<Typography className={classes.detailTypo}>
													{/* {e} */}
													{e.indexOf(
														"Rupture de la famille (famille créée par la victime et l’auteur)"
													) > -1
														? t("case-statistic-categ-of-harm-impact-fam")
														: e.indexOf("Conflits domestiques réguliers") > -1
														? t("case-statistic-categ-of-harm-impact-regul")
														: e.indexOf(
																"Abandon de la victime par sa famille d’origine (parents)"
														  ) > -1
														? t("case-statistic-categ-of-harm-impact-abn")
														: e.indexOf(
																"Stigmatisation de la victime par la communauté"
														  ) > -1
														? t("case-statistic-categ-of-harm-impact-stig")
														: e.indexOf(
																"Perte de la garde ou de contact avec propres enfants"
														  ) > -1
														? t("case-statistic-categ-of-harm-impact-loss")
														: e.indexOf("Isolement social de la victime") > -1
														? t("case-statistic-categ-of-harm-impact-soc")
														: e.indexOf("Autres") > -1
														? t("case-statistic-incident-of-ecom-other")
														: null}
												</Typography>
											}
										/>
									))}
							</FormGroup>
						</Grid>
					</Grid>
				)}

				{/* QUESTION 14 */}

				<Typography
					className={classes.label}
					style={{
						fontWeight: 500,
						marginBottom: 10,
						textTransform: "uppercase",
					}}
				>
					{t("case-statistic-perpetrator-categ")}
				</Typography>

				{/* QUESTION 14 A */}
				{rowData.question_14_a_oui === null ||
				rowData.question_14_a_oui === undefined ? (
					""
				) : rowData.question_14_a_oui.filter(Boolean).length === 0 ? (
					""
				) : (
					<Grid container>
						<Grid item xs={12} sm={12}>
							<Button
								variant="text"
								color="default"
								className={classes.label}
								style={{ padding: 0, textAlign: "left", cursor: "text" }}
								startIcon={<ArrowDropDownIcon style={{ fontSize: 30 }} />}
							>
								{t("case-statistic-perpetrator-categ-fam")}
							</Button>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							style={{ paddingLeft: 20, marginBottom: 30 }}
						>
							<FormGroup className={classes.answerB}>
								{rowData.question_14_a_oui
									.filter((e) => e)
									.map((e) => (
										<FormControlLabel
											control={<Checkbox name="" size="small" checked />}
											key={e}
											style={{cursor: "text"}}
											label={
												<Typography className={classes.detailTypo}>
													{/* {e} */}
													{e.indexOf("Mère") > -1
														? t("case-statistic-perpetrator-categ-fam-mo")
														: e.indexOf("Père") > -1
														? t("case-statistic-perpetrator-categ-fam-fa")
														: e.indexOf("Tuteur / gardien (homme)") > -1
														? t("case-statistic-perpetrator-categ-fam-gua-male")
														: e.indexOf("Tuteur / gardienne (femme)") > -1
														? t("case-statistic-perpetrator-categ-fam-gual-fem")
														: e.indexOf("Propres frères") > -1
														? t("case-statistic-perpetrator-categ-fam-bro")
														: e.indexOf("Propres sœurs") > -1
														? t("case-statistic-perpetrator-categ-fam-sist")
														: e.indexOf("Propres enfants adultes") > -1
														? t("case-statistic-perpetrator-categ-fam-adult")
														: e.indexOf("Grand-mère") > -1
														? t("case-statistic-perpetrator-categ-fam-grdma")
														: e.indexOf("Grand-père") > -1
														? t("case-statistic-perpetrator-categ-fam-grdfa")
														: e.indexOf("Belle-mère") > -1
														? t("case-statistic-perpetrator-categ-fam-mo-law")
														: e.indexOf("Beau-père") > -1
														? t("case-statistic-perpetrator-categ-fam-fa-law")
														: e.indexOf("Beaux-frères") > -1
														? t("case-statistic-perpetrator-categ-fam-bro-law")
														: e.indexOf("Belles-sœurs") > -1
														? t("case-statistic-perpetrator-categ-fam-sist-law")
														: e.indexOf("Oncles") > -1
														? t("case-statistic-perpetrator-categ-fam-uncle")
														: e.indexOf("Tantes") > -1
														? t("case-statistic-perpetrator-categ-fam-aunt")
														: e.indexOf(
																"Autre(s) membre(s) de la famille de la victime"
														  ) > -1
														? t("case-statistic-perpetrator-categ-fam-other")
														: null}
												</Typography>
											}
										/>
									))}
							</FormGroup>
						</Grid>
					</Grid>
				)}

				{/* QUESTION 14 B */}
				{rowData.question_14_b_oui === null ||
				rowData.question_14_b_oui === undefined ? (
					""
				) : rowData.question_14_b_oui.filter(Boolean).length === 0 ? (
					""
				) : (
					<Grid container>
						<Grid item xs={12} sm={12}>
							<Button
								variant="text"
								color="default"
								className={classes.label}
								style={{ padding: 0, textAlign: "left", cursor: "text" }}
								startIcon={<ArrowDropDownIcon style={{ fontSize: 30 }} />}
							>
								{t("case-statistic-perpetrator-categ-spouse")}
							</Button>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							style={{ paddingLeft: 20, marginBottom: 30 }}
						>
							<FormGroup className={classes.answerB}>
								{rowData.question_14_b_oui
									.filter((e) => e)
									.map((e) => (
										<FormControlLabel
											control={<Checkbox name="" size="small" checked />}
											key={e}
											style={{cursor: "text"}}
											label={
												<Typography className={classes.detailTypo}>
													{/* {e} */}
													{e.indexOf("Mari") > -1
														? t("case-statistic-perpetrator-categ-spouse-husb")
														: e.indexOf("Ex-mari") > -1
														? t(
																"case-statistic-perpetrator-categ-spouse-exhusb"
														  )
														: e.indexOf("Epouse") > -1
														? t("case-statistic-perpetrator-categ-spouse-wife")
														: e.indexOf("Ex-épouse") > -1
														? t(
																"case-statistic-perpetrator-categ-spouse-exwife"
														  )
														: e.indexOf(
																"Partenaire intime actuel qui est homme"
														  ) > -1
														? t(
																"case-statistic-perpetrator-categ-spouse-current-male"
														  )
														: e.indexOf(
																"Partenaire intime actuel qui est femme"
														  ) > -1
														? t(
																"case-statistic-perpetrator-categ-spouse-current-female"
														  )
														: e.indexOf("Ex-partenaire intime qui est homme") >
														  -1
														? t(
																"case-statistic-perpetrator-categ-spouse-ex-intim-male"
														  )
														: e.indexOf("Ex-partenaire intime qui est femme") >
														  -1
														? t(
																"case-statistic-perpetrator-categ-spouse-ex-intim-female"
														  )
														: null}
												</Typography>
											}
										/>
									))}
							</FormGroup>
						</Grid>
					</Grid>
				)}

				{/* QUESTION 14 C */}
				{rowData.question_14_c === null ||
				rowData.question_14_c === undefined ? (
					""
				) : rowData.question_14_c.filter(Boolean).length === 0 ? (
					""
				) : (
					<Grid container>
						<Grid item xs={12} sm={12}>
							<Button
								variant="text"
								color="default"
								className={classes.label}
								style={{ padding: 0, textAlign: "left", cursor: "text" }}
								startIcon={<ArrowDropDownIcon style={{ fontSize: 30 }} />}
							>
								{t("case-statistic-perpetrator-categ-other")}
							</Button>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							style={{ paddingLeft: 20, marginBottom: 30 }}
						>
							<FormGroup className={classes.answerB}>
								{rowData.question_14_c
									.filter((e) => e)
									.map((e) => (
										<FormControlLabel
											control={<Checkbox name="" size="small" checked />}
											key={e}
											style={{cursor: "text"}}
											label={
												<Typography className={classes.detailTypo}>
													{/* {e} */}
													{e.indexOf("Patron (homme) de personnel domestique") >
													-1
														? t(
																"case-statistic-perpetrator-categ-other-boss-male"
														  )
														: e.indexOf(
																"Patronne (femme) de personnel domestique"
														  ) > -1
														? t(
																"case-statistic-perpetrator-categ-other-boss-female"
														  )
														: e.indexOf("Personnel domestique (homme)") > -1
														? t(
																"case-statistic-perpetrator-categ-other-dom-male"
														  )
														: e.indexOf("Personnel domestique (femme)") > -1
														? t(
																"case-statistic-perpetrator-categ-other-dom-female"
														  )
														: e.indexOf(
																"Bailleur / propriétaire de la maison (homme)"
														  ) > -1
														? t(
																"case-statistic-perpetrator-categ-other-landlord"
														  )
														: e.indexOf(
																"Bailleresse / propriétaire de la maison (femme)"
														  ) > -1
														? t(
																"case-statistic-perpetrator-categ-other-landlady"
														  )
														: e.indexOf(
																"Autre(s) membre(s) du cercle restreint de la victime"
														  ) > -1
														? t(
																"case-statistic-perpetrator-categ-other-othermember"
														  )
														: null}
												</Typography>
											}
										/>
									))}
							</FormGroup>
						</Grid>
					</Grid>
				)}

				{/* QUESTION 15 */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-detail-victim-relation-answ")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<FormControl component="fieldset">
							<RadioGroup name="question_15" className={classes.answer}>
								<div>
									<FormControlLabel
										checked
										value={rowData.question_15}
										style={{cursor: "text"}}
										control={<Radio size="small" />}
										label={
											<Box component="div" fontSize={13}>
												{rowData.question_15}
											</Box>
										}
									/>
								</div>
							</RadioGroup>
						</FormControl>

						{/* QUESTION 15  OUI */}
						{rowData.question_15_oui === null ||
						rowData.question_15_oui === undefined ? (
							""
						) : rowData.question_15_oui.filter(Boolean).length === 0 ? (
							""
						) : (
							<div>
								<Typography className={classes.label}>
									{t("case-detail-if-so")}
								</Typography>
								<FormGroup className={classes.answer}>
									{rowData.question_15_oui
										.filter((e) => e)
										.map((e) => (
											<FormControlLabel
												key={e}
												style={{cursor: "text"}}
												control={<Checkbox name="" size="small" checked />}
												label={
													<Typography className={classes.detailTypo}>
														{/* {e} */}
														{e.indexOf("Même ménage") > -1
															? t(
																	"case-statistic-victim-cohabitate-sameHouseHold"
															  )
															: e.indexOf("Même parcelle") > -1
															? t("case-statistic-victim-cohabitate-sameParcel")
															: e.indexOf("Maison d’enfants") > -1
															? t(
																	"case-statistic-victim-cohabitate-ChildrenHome"
															  )
															: e.indexOf("Camp de réfugiés") > -1
															? t(
																	"case-statistic-victim-cohabitate-RefugeeCamp"
															  )
															: null}
													</Typography>
												}
											/>
										))}

									{/* QUESTION 15 OUI AUTRE */}
									{rowData.question_15_oui_autre ? (
										<FormControlLabel
											control={<Checkbox name=" " checked />}
											style={{cursor: "text"}}
											label={
												<TextField
													disabled
													label={rowData.question_15_oui_autre}
													variant="outlined"
													style={{ width: "100%" }}
													size="small"
													fullWidth
												/>
											}
										/>
									) : (
										""
									)}
								</FormGroup>
							</div>
						)}
					</Grid>
				</Grid>

				{/* QUESTION 16 */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-detail-victim-judiciaire")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<FormControl component="fieldset">
							<RadioGroup name="question_16" className={classes.answer}>
								<FormControlLabel
									value={rowData.question_16}
									style={{cursor: "text"}}
									checked
									control={<Radio size="small" />}
									label={
										<Box component="div" fontSize={13}>
											{rowData.question_16}
										</Box>
									}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>

					{/* QUESTION 16 OUI */}
					{rowData.question_16_oui ? (
						<Grid item sm={12} xs={12}>
							<Typography className={classes.label}>
								{t("case-detail-victim-judiciaire-oui")}{" "}
							</Typography>
							<RadioGroup name="question_16_oui" className={classes.answer}>
								<FormControlLabel
									value={rowData.question_16_oui}
									style={{cursor: "text"}}
									checked
									control={<Radio size="small" />}
									label={
										<Box component="div" fontSize={13}>
											{rowData.question_16_oui}
										</Box>
									}
								/>
							</RadioGroup>
						</Grid>
					) : (
						""
					)}
				</Grid>

				{/* QUESTION 17 */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-detail-victim-support")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<FormControl component="fieldset">
							<RadioGroup name="question_17" className={classes.answer}>
								<FormControlLabel
									value={rowData.question_17}
									style={{cursor: "text"}}
									checked
									control={<Radio size="small" />}
									label={
										<Box component="div" fontSize={13}>
											{rowData.question_17}
										</Box>
									}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
				</Grid>

				{/* QUESTION 18 */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-detail-victim-even-receiv-care")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<FormControl component="fieldset">
							<RadioGroup name="question_18" className={classes.answer}>
								<FormControlLabel
									value={rowData.question_18}
									style={{cursor: "text"}}
									checked
									control={<Radio size="small" />}
									label={
										<Box component="div" fontSize={13}>
											{rowData.question_18}
										</Box>
									}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
				</Grid>
				{/* QUESTION 18 A */}
				{rowData.question_18_a === null ||
				rowData.question_18_a === undefined ? (
					""
				) : rowData.question_18_a.filter(Boolean).length === 0 ? (
					""
				) : (
					<Grid container spacing={1} className={classes.margin_Bottom_30}>
						<Grid item sm={12} xs={12}>
							<Typography className={classes.label}>
								{t("case-detail-victim-wish-receiv")}
							</Typography>
						</Grid>
						<Grid item sm={12} xs={12}>
							<FormGroup className={classes.answer}>
								{rowData.question_18_a
									.filter((e) => e)
									.map((e) => (
										<FormControlLabel
											control={<Checkbox name="" size="small" checked />}
											key={e}
											style={{cursor: "text"}}
											label={
												<Typography className={classes.detailTypo}>
													{/* {e} */}
													{e.indexOf("Soins médicaux") > -1
														? t("case-statistic-victim-assistance-MedicalCare")
														: e.indexOf("Assistance psychosociale") > -1
														? t(
																"case-statistic-victim-assistance-PsychoAssistance"
														  )
														: e.indexOf("Assistance en médiation familiale") >
														  -1
														? t(
																"case-statistic-victim-assistance-FamilyMediation"
														  )
														: e.indexOf("Assistance juridique") > -1
														? t(
																"case-statistic-victim-assistance-LegalAssistance"
														  )
														: e.indexOf(
																"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale"
														  ) > -1
														? t(
																"case-statistic-victim-assistance-CriminalJudicial"
														  )
														: e.indexOf(
																"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile"
														  ) > -1
														? t(
																"case-statistic-victim-assistance-CivilJudicial"
														  )
														: e.indexOf("Assistance socio-économique") > -1
														? t(
																"case-statistic-victim-assistance-Socioeconomic"
														  )
														: e.indexOf(
																"Développement d’un plan de sécurité pour la victime de VD"
														  ) > -1
														? t(
																"case-statistic-victim-assistance-DevSecurityPlan"
														  )
														: null}
												</Typography>
											}
										/>
									))}

								{/* QUESTION 18 A OUI AUTRE */}
								{rowData.question_18_a_autre ? (
									<FormControlLabel
										control={<Checkbox name=" " checked />}
										style={{cursor: "text"}}
										label={
											<TextField
												disabled
												label={rowData.question_18_a_autre}
												variant="outlined"
												style={{ width: "100%" }}
												size="small"
												fullWidth
											/>
										}
									/>
								) : (
									""
								)}
							</FormGroup>
						</Grid>
					</Grid>
				)}

				{/* QUESTION 19 */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-detail-victim-still-need-assistance")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<FormControl component="fieldset">
							<RadioGroup name="question_19" className={classes.answer}>
								<FormControlLabel
									value={rowData.question_19}
									style={{cursor: "text"}}
									checked
									control={<Radio size="small" />}
									label={
										<Box component="div" fontSize={13}>
											{rowData.question_19}
										</Box>
									}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>

					{/* QUESTION 19 OUI */}
					{rowData.question_19_oui === null ||
					rowData.question_19_oui === undefined ? (
						""
					) : rowData.question_19_oui.filter(Boolean).length === 0 ? (
						""
					) : (
						<Grid item sm={12} xs={12}>
							<Typography className={classes.label}>
								{t("case-detail-if-so")}
							</Typography>
							<FormGroup className={classes.answer}>
								{rowData.question_19_oui
									.filter((e) => e)
									.map((e) => (
										<FormControlLabel
											control={<Checkbox name="" size="small" checked />}
											style={{cursor: "text"}}
											key={e}
											label={
												<Typography className={classes.detailTypo}>
													{/* {e} */}
													{e.indexOf(
														"Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)"
													) > -1
														? t(
																"case-statistic-victim-assistance-MedCare72Hours"
														  )
														: e.indexOf("Soins médicaux") > -1
														? t("case-statistic-victim-assistance-MedicalCare")
														: e.indexOf("Assistance psychosociale") > -1
														? t(
																"case-statistic-victim-assistance-PsychoAssistance"
														  )
														: e.indexOf("Assistance en médiation familiale") >
														  -1
														? t(
																"case-statistic-victim-assistance-FamilyMediation"
														  )
														: e.indexOf("Assistance juridique") > -1
														? t(
																"case-statistic-victim-assistance-LegalAssistance"
														  )
														: e.indexOf(
																"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale"
														  ) > -1
														? t(
																"case-statistic-victim-assistance-CriminalJudicial"
														  )
														: e.indexOf(
																"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile"
														  ) > -1
														? t(
																"case-statistic-victim-assistance-CivilJudicial"
														  )
														: e.indexOf("Assistance socio-économique") > -1
														? t(
																"case-statistic-victim-assistance-Socioeconomic"
														  )
														: e.indexOf(
																"Développement d’un plan de sécurité pour la victime de VD"
														  ) > -1
														? t(
																"case-statistic-victim-assistance-DevSecurityPlan"
														  )
														: null}
												</Typography>
											}
										/>
									))}

								{/* QUESTION 19 AUTRE */}
								{rowData.question_19_autre ? (
									<FormControlLabel
										control={<Checkbox name=" " checked />}
										style={{cursor: "text"}}
										label={
											<TextField
												disabled
												label={rowData.question_19_autre}
												variant="outlined"
												style={{ width: "100%" }}
												size="small"
												fullWidth
											/>
										}
									/>
								) : (
									""
								)}
							</FormGroup>
						</Grid>
					)}
				</Grid>

				{/* QUESTION 23 */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-detail-victim-feel-safe")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<FormControl component="fieldset">
							<RadioGroup name="question_23" className={classes.answer}>
								<FormControlLabel
									value={rowData.question_23}
									style={{cursor: "text"}}
									checked
									control={<Radio size="small" />}
									label={
										<Box component="div" fontSize={13}>
											{rowData.question_23}
										</Box>
									}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
				</Grid>

				{/* QUESTION 24 */}
				<Grid container spacing={1} className={classes.margin_Bottom_30}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes.label}>
							{t("case-detail-victim-seek-to-leave")}
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<FormControl
							component="fieldset"
							className={classes.marginBottom_30}
						>
							<RadioGroup name="question_24" className={classes.answer}>
								<FormControlLabel
									checked
									control={<Radio size="small" />}
									style={{cursor: "text"}}
									label={
										<Box component="div" fontSize={13}>
											{rowData.question_24}
										</Box>
									}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
				</Grid>

				{/* QUESTION 24 A */}
				{rowData.question_24_a ? (
					<Grid container spacing={1} className={classes.margin_Bottom_30}>
						<Grid item sm={12} xs={12}>
							<Typography className={classes.label}>
								{t("case-detail-victim-knows-where-to-go")}
							</Typography>
						</Grid>
						<Grid item sm={12} xs={12}>
							<FormControl component="fieldset">
								<RadioGroup name="question_24_a" className={classes.answer}>
									<FormControlLabel
										value={rowData.question_24_a}
										style={{cursor: "text"}}
										checked
										control={<Radio size="small" />}
										label={
											<Box component="div" fontSize={13}>
												{rowData.question_24_a}
											</Box>
										}
									/>
								</RadioGroup>
							</FormControl>
						</Grid>
					</Grid>
				) : (
					""
				)}
			</List>
		);
	};

	return (
		<Grid container direction="row">
			<Grid item xs={12} sm={12} className={classes.table}>
				<DataGrid
					rows={casemap}
					columns={columns}
					pageSize={15}
					loading={active === true ? false : true}
					onRowClick={onRowClick}
					className={classes.answer}
				/>
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					className={classes.modal}
					open={open}
					onClose={handleClose}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={open}>
						<Grid
							container
							justifyContent="center"
							alignItems="center"
							className={classes.paper}
						>
							<Grid item xs={12} sm={12}>
								<Grid container direction="row">
									<Grid item xs={6} sm={6}>
										<Grid
											container
											justifyContent="flex-start"
											alignItems="flex-start"
										>
											<Grid className={classes.answer}>
												<Typography style={{ marginTop: 5, marginBottom: 10 }}>
													{rowData.caseid}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={6} sm={6}>
										<Grid
											container
											justifyContent="flex-end"
											alignItems="flex-end"
										>
											<Grid item>
												<IconButton onClick={handleClose}>
													<CancelIcon />
												</IconButton>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Divider />
								<Grid item xs={12} sm={12} style={{ marginTop: 10 }}>
									{displayData()}
								</Grid>
							</Grid>
						</Grid>
					</Fade>
				</Modal>
			</Grid>
		</Grid>
	);
}

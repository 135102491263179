import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";

import {
	AppBar,
	Toolbar,
	IconButton,
	Grid,
	Typography,
	TextField,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	Select,
	MenuItem,
	DialogTitle,
} from "@material-ui/core";
import useStyles from "./style";
import { q2, q3, q4, annule, suivant, grp1 } from "../../widget/i1e";
import {
	BtnContained,
	BtnOutline,
	BtnText1,
	BtnLogout2,
	//TextForm,
} from "../../widget/util";
import { Link, useHistory, useLocation } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useForm } from "react-hook-form"; // Controller
import regions from "../../../assets/regions.json";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { format } from "date-fns";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Identify_organisation = () => {
	const classes = useStyles();
	const [selectedDate, setSelectedDate] = React.useState(new Date());
	const [open, setOpen] = useState(false);
	const theme = useTheme();
	const history = useHistory();
	const location = useLocation();
	const [orgData, setorgData] = useState([]);
	const [regionMap, setregionMap] = useState([]);
	const [provinceName, setprovinceName] = useState();
	const [cities, setcities] = useState([]);
	const [selectedCity, setselectedCity] = useState("");
	const [organization, setorganization] = useState("");
	const [erDate, seterDate] = useState("");
	const [getOrg, setgetOrg] = useState([]);
	const [prov, setprov] = useState("");
	const [cty, setcty] = useState("");
	const [date, setdate] = useState("");
	const [openSnackBar, setOpenSnackbar] = useState(false);

	// snackbar
	const handleSnackBar = () => {
		setOpenSnackbar(true);
	};
	const handleCloseSnackBar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackbar(false);
	};

	//controler for the navbar
	const [select, setSelect] = useState(2);
	const handleChange = (event) => {
		setSelect(event.target.value);
	};
	//

	// const getCities = (event) => {
	//     setcities(event.target.value);
	//   };

	const getProvince = (event) => {
		setprovinceName(event.target.value);
		setcities(regionMap.filter((e) => e.name === event.target.value));

		// setprovinceName(getOrg.map(e=>e.q3a))
	};

	// setting date
	const [datebool, setdatebool] = useState(false);
	const handleDateChange = (date) => {
		// console.log(date);
		var a = format(new Date(date), "dd/MM/yyyy");
		// console.log(a);
		setSelectedDate(a);

		setdatebool(true);
	};

	const { register, handleSubmit, reset } = useForm({
		mode: "onBlur",
	});
	const onSubmit = (data, e) => {
		e.preventDefault();
		//console.log(data);

		if (data !== null && datebool !== false) {
			const orgList = [
				{
					q2: data.name,
					q3a: provinceName,
					q3ab: selectedCity,
					datebool: datebool,
					q4: selectedDate.toLocaleString().split(",")[0],
				},
			];
			//console.log("orgList",orgList);
			sessionStorage.setItem("organization", JSON.stringify(orgList));

			if (orgData !== null) {
				handleSauvegarde();
			} else {
				handleSuivant();
			}
		} else {
			// //console.log("choisir la date")
			seterDate("Choisissez la date");
			handleSnackBar();
		}
	};

	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleSuivant = () => {
		history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/form/profile`);
	};

	const handleRetour = () => {
		history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/main/changes`);
	};

	const handleSauvegarde = () => {
		history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/main/changes`);
	};

	const getOrganization = () => {
		const tkn = sessionStorage.getItem("org");
		return setorganization(tkn);
	};

	const newCity = (e) => {
		const value = e.target.value;

		//console.log(value);

		return setselectedCity(value);
	};

	const getOrgInfo = () => {
		const data = sessionStorage.getItem("organization");
		//  //console.log('data from local storage',JSON.parse(data));
		return setgetOrg(JSON.parse(data));
	};

	const annuler = () => {
		sessionStorage.removeItem("organization");
		sessionStorage.removeItem("profile");
		sessionStorage.removeItem("info");
		sessionStorage.removeItem("perpetor");
		sessionStorage.removeItem("need");
		history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/`);
	};

	useEffect(() => {
		getOrgInfo();
		setorgData(location.data);
		setregionMap(regions.province);
		getOrganization();
		// setnom(getOrg.map(e=>e.q2))
		if (getOrg !== null) {
			setprov(getOrg.map((e) => e.q3a));
			setcty(getOrg.map((e) => e.q3ab));
			setdate(getOrg.map((e) => e.q4));
			setprovinceName(getOrg.map((e) => e.q3a));
			setselectedCity(getOrg.map((e) => e.q3ab));
			setSelectedDate(getOrg.map((e) => e.q4));
			setdatebool(getOrg.map((e) => e.datebool));
			reset({
				name: getOrg.map((e) => e.q2),
				provs: getOrg.map((e) => e.q3a),
				ctys: getOrg.map((e) => e.q3ab),
				cty: getOrg.map((e) => e.q3ab),
				dte: getOrg.map((e) => e.q4),
			});
		}
	}, [
		orgData,
		regionMap,
		setcities.name,
		organization,
		getOrg,
		location.data,
		reset,
		// provinceName
	]); //Just added getOrg, location.data, reset and removed regions

	return (
		<div>
			<div className={classes.root}>
				<AppBar position="absolute" className={classes.appbar}>
					<Toolbar>
						{orgData !== null ? null : (
							<IconButton
								edge="start"
								className={classes.menuButton}
								color="inherit"
								aria-label="open drawer"
								component={Link}
								to={"/" + process.env.REACT_APP_COLLECT_LINK + "/collect"}
							>
								{/* <ArrowBack/> */}
							</IconButton>
						)}
						<Typography
							className={classes.titleAppBar}
							variant="h6"
							noWrap
						></Typography>
						<div className={classes.search}>
							<div className={classes.searchIcon}></div>
							{orgData !== null ? (
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={select}
									fullWidth
									className={classes.select}
									onChange={handleChange}
								>
									<MenuItem value={2}>
										IDENTIFICATION DE L'ORGANISATION
									</MenuItem>
								</Select>
							) : (
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={select}
									fullWidth
									className={classes.select}
									onChange={handleChange}
								>
									{/* <MenuItem value={1}>CHOISIR PARMIS LES OPTIONS SUIVANTES</MenuItem> */}
									<MenuItem
										value={2}
										component={Link}
										to={
											"/" + process.env.REACT_APP_COLLECT_LINK + "/collect/form"
										}
									>
										IDENTIFICATION DE L'ORGANISATION
									</MenuItem>
									{/* <MenuItem value={3} component={Link} to='/collect/form/profile'>PROFILE DE LA / DU SURVIVANT(E)</MenuItem>
            <MenuItem value={4} component={Link} to='/collect/form/info'>INFORMATION SUR L`INCIDENT DE VIOLENCE DOMESTIQUE DOCUMENTÉ</MenuItem>
            <MenuItem value={5} component={Link} to='/collect/form/perpretor'>PROFIL DE L`AUTEUR DE L`ACTE DE VIOLENCE DOMESTIQUE</MenuItem>
            <MenuItem value={6} component={Link} to='/collect/form/besion'>SITUATION ET BESIONS DE PRISE EN CHARGE DE LA / DU SURVIVANT(E) </MenuItem> */}
								</Select>
							)}
						</div>
					</Toolbar>
				</AppBar>
			</div>
			<div>
				<Grid container justifyContent="center" spacing={1}>
					<Grid item xs={11} sm={8} className={classes.container}>
						<Typography align="center" className={classes.title}>
							{organization}
						</Typography>
						{/* <Typography align='center'  className={classes.subtitle}>
                            Location
                    </Typography>        */}
					</Grid>
				</Grid>
				<Grid container justifyContent="center" spacing={3}>
					<Grid item xs={11} sm={8}>
						<Typography
							xs={11}
							sm={8}
							className={classes.bigtitles}
							align="left"
						>
							{grp1.toUpperCase()}
						</Typography>
					</Grid>
				</Grid>
				<Grid container justifyContent="center" spacing={3}>
					<Grid
						item
						justifyContent="center"
						className={classes.content}
						xs={11}
						sm={8}
					>
						<Grid container direction="row">
							<Grid item sm={1} xs={0}></Grid>
							<Grid item sm={10} xs="12" className={classes.marginTop_40}>
								<form onSubmit={handleSubmit(onSubmit)}>
									<Grid
										container
										spacing={1}
										direction="row"
										className={classes.marginBottom_30}
									>
										<Grid item xs={12} sm={12}>
											<Grid container spacing={3}>
												<Grid item xs={12} sm={12}>
													<Grid container>
														<Grid item xs={12} sm={12}>
															<Typography className={classes.label}>
																{q2}
															</Typography>
														</Grid>
													</Grid>
													<Grid container spacing={2}>
														<Grid item xs={12} sm={6}>
															<TextField
																required
																name="name"
																inputRef={register}
																label="Nom"
																variant="outlined"
																size="small"
																fullWidth
															/>
														</Grid>
														<Grid item sm={6}></Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										direction="row"
										className={classes.marginBottom_30}
									>
										<Grid item xs={12} sm={12}>
											<Grid container spacing={1}>
												<Grid item xs={12} sm={12}>
													<Grid container>
														<Grid item xs={12} sm={12}>
															<Typography className={classes.label}>
																{q3}
															</Typography>
														</Grid>
													</Grid>
													<Grid container spacing={2}>
														<Grid item xs={12} sm={6}>
															<Typography className={classes.sublabel}>
																Province*
															</Typography>

															{prov.length > 0 ? (
																<TextField
																	required
																	onClick={() => {
																		setprov("");
																		setcty("");
																		setprovinceName("");
																	}}
																	name="provs"
																	inputRef={register}
																	// label="Nom"
																	variant="outlined"
																	size="small"
																	fullWidth
																/>
															) : (
																<Select
																	//renderValue="hello"
																	required
																	variant="outlined"
																	size="small" //
																	fullWidth //
																	value={provinceName}
																	style={{ height: 40 }}
																	onChange={getProvince}
																>
																	{regionMap !== null
																		? regionMap.map((e) => (
																				<MenuItem value={e.name}>
																					{e.name}
																				</MenuItem>
																		  ))
																		: null}
																</Select>
															)}
														</Grid>

														<Grid item sm={6} xs={12}>
															<Typography className={classes.sublabel}>
																Ville/Territoire*
															</Typography>
															{cty.length > 0 ? (
																<TextField
																	onClick={() => {
																		setprov("");
																		setcty("");
																		setprovinceName("");
																	}}
																	name="ctys"
																	inputRef={register}
																	// label="Nom"
																	variant="outlined"
																	size="small"
																	fullWidth
																/>
															) : (
																<Select
																	required
																	variant="outlined"
																	size="small"
																	fullWidth
																	style={{ height: 40 }}
																	value={selectedCity}
																	onChange={newCity}
																>
																	{cities !== null
																		? cities.map((e) =>
																				e.cities.map((e) => (
																					<MenuItem value={e.name}>
																						{e.name}
																					</MenuItem>
																				))
																		  )
																		: null}
																</Select>
															)}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>

									<Grid container spacing={1} direction="row">
										<Grid item xs={12} sm={12}>
											<Grid container spacing={1}>
												<Grid item xs={12} sm={12}>
													<Grid container>
														<Grid item xs={12} sm={12}>
															<Typography className={classes.label}>
																{q4}
															</Typography>
														</Grid>
													</Grid>
													<Grid container spacing={3}>
														<Grid item xs={12} sm={6}>
															{/* {
                                           date.length>0?
                                            <TextField
                                            //disabled
                                            onClick={()=>{
                                                setdate("");
                                            }}
                                            name='dte'
                                            inputRef={register}
                                           // label="Nom"
                                            variant='outlined'
                                            size='small'
                                            fullWidth
                                        />: */}

															<MuiPickersUtilsProvider utils={DateFnsUtils}>
																<div>
																	<KeyboardDatePicker
																		helperText={""}
																		required
																		fullWidth
																		size="small"
																		autoOk={true}
																		disableToolbar
																		//variant="inline"
																		inputVariant="outlined"
																		format={"dd/MM/yyyy"}
																		//emptyLabel="mm / dd / yyyy"
																		margin="normal"
																		//id="date-picker-inline"
																		label="Date"
																		value={selectedDate}
																		onChange={handleDateChange}
																		// KeyboardButtonProps={{
																		//     'aria-label': 'change date',
																		// }}
																	/>
																</div>
															</MuiPickersUtilsProvider>

															{/* //  }                                                                     */}
														</Grid>

														<Grid item sm={6}>
															<Snackbar
																open={openSnackBar}
																autoHideDuration={10}
																onClose={handleClose}
															>
																<Alert
																	onClose={handleCloseSnackBar}
																	severity="error"
																>
																	<Typography>{erDate}</Typography>
																</Alert>
															</Snackbar>
															{/* <TextField
                                        required
                                        label="Territoire/Ville"
                                        variant='outlined'
                                        
                                        size='small'
                                        fullWidth
                                        /> */}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										direction="row"
										justifyContent="flex-end"
									>
										<Grid item className={classes.marginButton}>
											{orgData !== null ? (
												<div>
													<BtnOutline
														// component={Link}
														// to='/collect'
														content="RETOUR"
														onClick={() => {
															handleRetour();
															//console.log('hello elijah');
														}}
													/>
													<BtnContained
														// onClick={handleSauvegarde}
														type="submit"
														content="SAUVEGARDER"
													/>
												</div>
											) : (
												<div>
													<BtnOutline
														// component={Link}
														// to='/collect'
														content={annule}
														onClick={() => {
															handleClickOpen();
															//console.log('hello elijah');
														}}
													/>

													<Dialog
														fullScreen={fullScreen}
														open={open}
														onClose={handleClose}
														aria-labelledby="responsive-dialog-title"
													>
														<DialogTitle id="responsive-dialog-title">
															{"Vous êtes sûr de vouloir annuler ?"}
														</DialogTitle>
														<DialogContent>
															<DialogContentText>
																Une fois que vous avez annulé, il n'y a plus de
																retour en arrière. Toutes les données de ce
																formulaire seront supprimées. Veuillez en être
																certain.
															</DialogContentText>
														</DialogContent>
														<DialogActions>
															<BtnText1 content="NON" onClick={handleClose} />
															{/* <Button autoFocus onClick={handleClose} color="primary">
                                ANNULER
                            </Button> */}
															<BtnLogout2 content="OUI" onClick={annuler} />
														</DialogActions>
													</Dialog>
													<BtnContained
														//    onClick={handleSuivant}
														type="submit"
														content={suivant}
													/>
												</div>
											)}
										</Grid>
									</Grid>
								</form>
							</Grid>

							<Grid item sm={1} xs={0}></Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default Identify_organisation;

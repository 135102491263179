// import React,{useState,useEffect} from 'react';
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "../../website/index/style";
import { useTranslation } from "react-i18next";

const Oxide = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Grid container direction="row" spacing={2}>
			<Grid item xs={12} sm={6}>
				<Grid container className={classes.copyright1}>
					<Grid item>
						<Typography className={classes.oxide_text}>
							&copy; {new Date().getFullYear()}{" "}
							<a
								style={{ textDecoration: "none" }}
								href="https://www.madre.org"
								target="_blank"
								rel="noopener noreferrer"
							>
								<span className={classes.oxide_color1}>
									{t("partner-madre")}
								</span>
							</a>{" "}
							{t("copyright2")}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Grid container className={classes.copyright2}>
					<Grid item>
						<Typography className={classes.oxide_text}>
							{t("copyright")}{" "}
							<a
								style={{ textDecoration: "none" }}
								href="https://www.oxide-digital.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								<span className={classes.oxide_color}>{t("compane")}</span>
							</a>
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Oxide;

import { Grid, Typography, Button } from "@material-ui/core";
import React from "react";
import FaqTable from "./widgets/FaqTable";
import useStyles from "./style.js";
import { NavLink } from "react-router-dom";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useTranslation } from "react-i18next";

const Section7 = () => {
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	return (
		<Grid className={classes.margin_section}>
			<Typography className={classes.big_dark_txt}>{t("faq")}</Typography>
			<FaqTable />
			<br />
			<Grid container direction="row" justifyContent="center">
				<Grid item justifyContent="center">
					<Typography variant="h6" className={classes.about_link}>
						<NavLink
							activeClassName="is-active"
							style={{
								textDecoration: "none",
								color: "white",
								marginTop: "8px",
							}}
							to={"/" + i18n.language + t("link-faq")}
						>
							<Button
								endIcon={<KeyboardArrowRightIcon />}
								size="Large"
								color="secondary"
								className={classes.btn_txt}
							>
								{t("all")}
							</Button>
						</NavLink>
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Section7;

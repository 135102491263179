import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../src/ui/page/dashboard/home";
import Cases from "../src/ui/page/datacollector/cases";
import Website from "../src/ui/page/website/home";
// import Default from "../src/ui/page/default";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


const App = () => {
	const theme = createTheme({
		palette: {
			primary: {
				main: "#4F2970",
			},
			secondary: {
				main: "#00A1AF",
			},
			background: {
				default: "#F0F0F0",
			},
		},
	});
	const { t } = useTranslation();

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_TRACKID);
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<>
				<Helmet>
					<title>{t("site-title")}</title>
					<meta name="description" content={t("site-description")} />
				</Helmet>
				<React.Fragment>
					<Router>
						<Switch>
							<Route
								path={"/" + process.env.REACT_APP_DASHBOAD_LINK + "/dashboard"}
								component={Home}
							/>
							<Route
								path={"/" + process.env.REACT_APP_COLLECT_LINK + "/collect"}
								component={Cases}
							/>
							<Route path="/" component={Website} />
						</Switch>
					</Router>
				</React.Fragment>
			</>
		</ThemeProvider>
	);
};

export default App;

import React,{useEffect}  from 'react';
import {Switch,Route,} from'react-router-dom'
import Login from './login'
import MainRoutes from './mainRoutes'
import ProtectedRoute from './protectedRoute'

const Home=()=>{ 

    // Just commented this 
    //   useEffect(() => { 
        
    //   }, [])
      

     // console.log("my token \t\t\t"+token);

    return(
        <div>

            
            <Switch>
                <Route exact path={'/'+process.env.REACT_APP_DASHBOAD_LINK+'/dashboard/login'} component={Login} />
                <ProtectedRoute path={'/'+process.env.REACT_APP_DASHBOAD_LINK+'/dashboard'} component={MainRoutes} /> 
                {/* <Route component={Default}/>*/}
            </Switch>
        </div> 
    )
}

export default Home;
import React from "react";
import { Grid } from "@material-ui/core"; //Typography
import useStyles from "../../../styles";
import {
	PieChart,
	Pie,
	// Legend,
	// Tooltip,
	// Cell,
	Label,
	ResponsiveContainer,
} from "recharts";

// const COLORS = ['#FF8042'];

const DoughnutCharts = ({
	data,
	value,
	width,
	height,
	fill,
	alignItems,
	justify,
	filledColor,
	innerRadius,
	outerRadius,
}) => {
	const classes = useStyles();
	return (
		<div>
			<Grid
				container
				direction="column"
				justifyContent={justify}
				alignItems={alignItems}
			>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<ResponsiveContainer width={width} height={height}>
						<PieChart>
							<Pie
								data={data}
								//    cx={300}
								//    cy={150}
								innerRadius={innerRadius}
								outerRadius={outerRadius}
								fill={fill}
								paddingAngle={2}
							>
								<Label
									value={value}
									//fill={'#707070'}
									fontFamily="Arial"
									style={{ fontSize: 26 }}
									// fontWeight={120}
									position="center"
									className={classes.dougth}
								/>
								{filledColor}
							</Pie>
							{/* <Tooltip/> */}
						</PieChart>
					</ResponsiveContainer>
				</Grid>
			</Grid>
		</div>
	);
};
export default DoughnutCharts;

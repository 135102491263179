import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import ApptopBar from "../../component/website/widgets/appBar";
import home from ".";
import Campain from "./Campain";
import CampainNews from "./CampNews";
import CaseBank from "./CaseBank";
import CaseStat from "./CaseStat";
import Faq from "./Faq";
import ContactUs from "./ContactUs";
import AboutObs from "./AboutObs";
import Overview from "./Overview";
import Partners from "./Partners";
import Footer from "../../component/website/widgets/footer";
import Oxide from "./../../component/website/widgets/oxide";
import { Grid } from "@material-ui/core";
import useStyles from "./style";
import Article from "./Article";
import Where_to_find from "./where_to_find";
import What_is_dv from "./what_is_dv";
import Access_to from "./access_to";
import Sitemap from "./sitemap";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import Default from "./default";

export const history = createBrowserHistory();
const Home = () => {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	history.listen((location) => {
		ReactGA.pageview(location);
		// console.log(location);
	});

	//console.log(i18n.language);

	return (
		<div>
			<Router history={history}>
				<Grid>
					<ApptopBar />
					<Switch>
						<Route exact path="/" component={home} />
						<Route
							exact
							path={"/" + i18n.language + t("link-news-and-report")}
							component={CampainNews}
						/>
						<Route
							exact
							path={"/" + i18n.language + t("link-campain")}
							component={Campain}
						/>
						<Route
							exact
							path={"/" + i18n.language + t("link-case-database")}
							component={CaseBank}
						/>
						<Route
							exact
							path={"/" + i18n.language + t("link-case-statistic")}
							component={CaseStat}
						/>
						<Route
							exact
							path={"/" + i18n.language + t("link-faq")}
							component={Faq}
						/>
						<Route
							exact
							path={"/" + i18n.language + t("link-contact-us")}
							component={ContactUs}
						/>
						<Route
							exact
							path={"/" + i18n.language + t("link-about")}
							component={AboutObs}
						/>
						<Route
							exact
							path={"/" + i18n.language + t("link-post-detail")}
							component={Article}
						/>
						<Route
							exact
							path={"/" + i18n.language + t("link-overview")}
							component={Overview}
						/>
						<Route
							exact
							path={"/" + i18n.language + t("link-partners")}
							component={Partners}
						/>
						<Route
							exact
							path={"/" + i18n.language + t("link-What-is-domestic-violence")}
							component={What_is_dv}
						/>
						<Route
							exact
							path={"/" + i18n.language + t("link-Where-to-find-help")}
							component={Where_to_find}
						/>
						<Route
							exact
							path={
								"/" +
								i18n.language +
								t("link-Access-to-justice-and-reparations")
							}
							component={Access_to}
						/>
						<Route
							exact
							path={"/" + i18n.language + t("link-Sitemap")}
							component={Sitemap}
						/>
						<Route exact path="*" component={Default} />
					</Switch>
					<Grid>
						<Grid
							container
							direction="row"
							justifyContent="center"
							alignItems="flex-start"
							className={classes.footer}
						>
							<Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
							<Grid item xs={10} sm={10} md={10} lg={10} xl={8}>
								<Grid container>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<Footer />
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<Grid
											container
											direction="row"
											// justifyContent="center"
											// alignItems=""
											className={classes.oxide}
										>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Oxide />
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
						</Grid>
					</Grid>
				</Grid>
			</Router>
		</div>
	);
};

export default Home;

import { Grid } from "@material-ui/core";
import useStyles from "../../component/website/campain/style";
import React,{useEffect} from "react";
import Section1 from "../../component/website/CampainNews/section1";
import AllPost from "../../component/website/CampainNews/all";
import {Typography,Tab,Tabs} from '@material-ui/core'
import PropTypes from "prop-types";
import CampainPost from "../../component/website/CampainNews/campain";
import ReportPost from "../../component/website/CampainNews/report_article";
import {useHistory} from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import {Helmet} from "react-helmet";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        
          <Typography component={"span"}>{children}</Typography>
       
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
export default function CampainNews() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const history = useHistory()
  const { t ,i18n} = useTranslation();

  const handleChange = ( newValue) => {
    setValue(newValue);
  };

  const handleReadmore = ()=>{
    history.push(`/${i18n.language}${t('link-post-detail')}`)
  }
  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])
  return (
    <div>
       <Helmet>
              <title>{t('campaign-title')}</title>
              {/* <meta name='description' content={t('site-description')}/> */}
            </Helmet>
      <Grid container className={classes.section_1} alignItems="center">
        <Grid md={1} lg={1} xl={2}></Grid>
        <Grid md={10} lg={10} xl={8}>
          <Section1 />
        </Grid>
        <Grid  md={1} lg={1} xl={2}></Grid>
      </Grid>
      <Grid container alignItems="center" className={classes.marginTop_20}>
        <Grid item xs={1} sm={1} md={1} lg={2} xl={2}></Grid>
        <Grid item xs={10} sm={10} md={10} lg={8} xl={8}>
          {/* <TabBar /> */}
          <Tabs
            className={classes.marginTop_5}
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            centered
          >
            <Tab label={t('campaign-alltab')}  style={{fontWeight:300}}/>
            <Tab label={t('campaigntab')} style={{fontWeight:300}} />
            <Tab label={t('reporttab')} style={{fontWeight:300}} />
      </Tabs>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2} xl={2}></Grid>
      </Grid>
      <Grid container alignItems="center" style={{marginTop:40}} spacing={2}>
        <Grid item xs={1} sm={1}  md={1} lg={2} xl={3}></Grid>
        <Grid item xs={10} sm={10}  md={10} lg={8} xl={6}>
          <TabPanel value={value} index={0}>
             <AllPost handleReadmore={handleReadmore} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <CampainPost handleReadmore={handleReadmore} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ReportPost handleReadmore={handleReadmore} />
      </TabPanel>
        </Grid>
        <Grid item xs={1} sm={1}  md={1} lg={1} xl={3}></Grid>
      </Grid>
    </div>
  );
}

import { Grid } from "@material-ui/core";
import useStyles from "../../component/website/campain/style";
import React, { useState, useEffect } from "react";
import Partner from "../../component/website/widgets/Partner";
import Section1 from "../../component/website/partener/section";
import { useTranslation } from "react-i18next";
import Pagination from "@material-ui/lab/Pagination";
import madre from "../../component/website/assets/images/madre.png";
import sofepadi from "../../component/website/assets/images/SOFEPADI.png";
import AIDPROFEN from "../../component/website/assets/images/AIDPROFEN.png";
import BEIF from "../../component/website/assets/images/BEIF.png";
import CEFIDE from "../../component/website/assets/images/CEFIDE.png";
import CFPD from "../../component/website/assets/images/CFPD.png";
import FFPI from "../../component/website/assets/images/FFPI.png";
import LCVF from "../../component/website/assets/images/LCVF.jpeg";
import SEPPAF from "../../component/website/assets/images/SEPPAF.png";
//import Image from "../../component/website/assets/images/Logo.png";
import { Helmet } from "react-helmet";

export default function Overview() {
	const classes = useStyles();
	const { t } = useTranslation();

	const partner = [
		{
			name: `${t("partner-sofepadi")}`,
			desc: `${t("partner-sofepadi-short-desc")}`,
			content: `${t("partner-sofepadi-long-desc")}`,
			link: `${t("partner-sofepadi-link")}`,
			content2: "",
			org: "",
			org2: "",
			org3: "",
			org4: "",
			org5: "",
			org6: "",
			org7: "",
			org8: "",
			img: `${sofepadi}`,
		},
		{
			name: `${t("partner-madre")}`,
			desc: `${t("partner-madre-short-desc")}`,
			content: `${t("partner-madre-long-desc")}`,
			link: `${t("partner-madre-link")}`,
			content2: `${t("partner-madre-long-desc3")}`,
			org: `${t("partner-madre-org")}`,
			org2: `${t("partner-madre-org2")}`,
			org3: `${t("partner-madre-org3")}`,
			org4: `${t("partner-madre-org4")}`,
			org5: `${t("partner-madre-org5")}`,
			org6: `${t("partner-madre-org6")}`,
			org7: `${t("partner-madre-org7")}`,
			org8: `${t("partner-madre-org8")}`,
			img: `${madre}`,
		},
		{
			name: `${t("partner-AIDPRODEN")}`,
			desc: `${t("partner-AIDPRODEN-short-desc")}`,
			content: `${t("partner-AIDPRODEN-long-desc")}`,
			link: `${t("partner-AIDPRODEN-link")}`,
			content2: "",
			org: "",
			org2: "",
			org3: "",
			org4: "",
			org5: "",
			org6: "",
			org7: "",
			org8: "",
			img: `${AIDPROFEN}`,
		},
		{
			name: `${t("partner-SAFDF")}`,
			desc: `${t("partner-SAFDF-short-desc")}`,
			content: `${t("partner-SAFDF-long-desc")}`,
			link: `${t("partner-SAFDF-link")}`,
			content2: "",
			org: "",
			org2: "",
			org3: "",
			org4: "",
			org5: "",
			org6: "",
			org7: "",
			org8: "",
			img: null,
		},

		{
			name: `${t("partner-FFPI")}`,
			desc: `${t("partner-FFPI-short-desc")}`,
			content: `${t("partner-FFPI-long-desc")}`,
			link: `${t("partner-FFPI-link")}`,
			content2: "",
			org: "",
			org2: "",
			org3: "",
			org4: "",
			org5: "",
			org6: "",
			org7: "",
			org8: "",
			img: `${FFPI}`,
		},
		{
			name: `${t("partner-CDJP")}`,
			desc: `${t("partner-CDJP-short-desc")}`,
			content: `${t("partner-CDJP-long-desc")}`,
			link: `${t("partner-CDJP-link")}`,
			content2: "",
			org: "",
			org2: "",
			org3: "",
			org4: "",
			org5: "",
			org6: "",
			org7: "",
			org8: "",
			img: null,
		},
		{
			name: `${t("partner-SEPPAF")}`,
			desc: `${t("partner-SEPPAF-short-desc")}`,
			content: `${t("partner-sofepadi-long-desc")}`,
			link: `${t('partner-SEPPAF-link"')}`,
			content2: "",
			org: "",
			org2: "",
			org3: "",
			org4: "",
			org5: "",
			org6: "",
			org7: "",
			org8: "",
			img: `${SEPPAF}`,
		},
		{
			name: `${t("partner-SOFDI")}`,
			desc: `${t("partner-SOFDI-short-desc")}`,
			content: `${t("partner-SOFDI-long-desc")}`,
			link: `${t("partner-SOFDI-link")}`,
			content2: "",
			org: "",
			org2: "",
			org3: "",
			org4: "",
			org5: "",
			org6: "",
			org7: "",
			org8: "",
			img: null,
		},
		{
			name: `${t("partner-RJELVG")}`,
			desc: `${t("partner-RJELVG-short-desc")}`,
			content: `${t("partner-RJELVG-long-desc")}`,
			link: `${t("partner-RJELVG-link")}`,
			content2: "",
			org: "",
			org2: "",
			org3: "",
			org4: "",
			org5: "",
			org6: "",
			org7: "",
			org8: "",
			img: null,
		},
		{
			name: `${t("partner-BEIF-ODD")}`,
			desc: `${t("partner-BEIF-ODD-short-desc")}`,
			content: `${t("partner-BEIF-ODD-long-desc")}`,
			link: `${t("partner-BEIF-ODD-link")}`,
			content2: "",
			org: "",
			org2: "",
			org3: "",
			org4: "",
			org5: "",
			org6: "",
			org7: "",
			org8: "",
			img: `${BEIF}`,
		},
		{
			name: `${t("partner-LCVF/ONGDH")}`,
			desc: `${t("partner-LCVF/ONGDH-short-desc")}`,
			content: `${t("partner-LCVF/ONGDH-long-desc")}`,
			link: `${t("partner-LCVF/ONGDH-link")}`,
			content2: "",
			org: "",
			org2: "",
			org3: "",
			org4: "",
			org5: "",
			org6: "",
			org7: "",
			org8: "",
			img: `${LCVF}`,
		},
		{
			name: `${t("partner-CEFIDE")}`,
			desc: `${t("partner-CEFIDE-short-desc")}`,
			content: `${t("partner-CEFIDE-long-desc")}`,
			link: `${t("partner-CEFIDE-link")}`,
			content2: "",
			org: "",
			org2: "",
			org3: "",
			org4: "",
			org5: "",
			org6: "",
			org7: "",
			org8: "",
			img: `${CEFIDE}`,
		},
		{
			name: `${t("partner-FOFEDDI-ONG")}`,
			desc: `${t("partner-FOFEDDI-ONG-short-desc")}`,
			content: `${t("partner-FOFEDDI-ONG-long-desc")}`,
			link: `${t("partner-FOFEDDI-ONG-link")}`,
			content2: "",
			org: "",
			org2: "",
			org3: "",
			org4: "",
			org5: "",
			org6: "",
			org7: "",
			org8: "",
			img: null,
		},
		{
			name: `${t("partner-AFEJUCO/MMA")}`,
			desc: `${t("partner-AFEJUCO/MMA-short-desc")}`,
			content: `${t("partner-AFEJUCO/MMA-long-desc")}`,
			link: `${t("partner-AFEJUCO/MMA-link")}`,
			content2: "",
			org: "",
			org2: "",
			org3: "",
			org4: "",
			org5: "",
			org6: "",
			org7: "",
			org8: "",
			img: null,
		},
		{
			name: `${t("partner-CFPD")}`,
			desc: `${t("partner-CFPD-short-desc")}`,
			content: `${t("partner-CFPD-long-desc")}`,
			link: `${t("partner-CFPD-link")}`,
			content2: "",
			org: "",
			org2: "",
			org3: "",
			org4: "",
			org5: "",
			org6: "",
			org7: "",
			org8: "",
			img: `${CFPD}`,
		},
	];
	const [currentPage, setCurrentPage] = useState(1);
	const [partnerPerPage] = useState(6);
	// Get current posts
	const indexOfLastPost = currentPage * partnerPerPage;
	const indexOfFirstPost = indexOfLastPost - partnerPerPage;
	const all = partner.filter((e) => e.name !== "");
	// console.log(all.length);
	const alltotal = all.slice(indexOfFirstPost, indexOfLastPost);
	//  console.log(alltotal);
	const handleAll = (e, value) => {
		setCurrentPage(value);
		//console.log(value);
	};
	const handletotal = (num) => {
		const value = Math.ceil(num.length / partnerPerPage);
		return value;
	};
	//console.log(partner);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div>
			<Helmet>
				<title>{t("footer-partners")}</title>
				<meta name="description" content={t("footer-partners-text")} />
			</Helmet>
			<Grid container className={classes.section_1} alignItems="center">
				<Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
				<Grid item xs={10} sm={10} md={10} lg={10}>
					<Section1 />
				</Grid>
				<Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
			</Grid>
			<Grid container direction="row" style={{ marginTop: 40 }}>
				<Grid item xs={1} sm={1} md={1} lg={2} xl={2}>
					{" "}
				</Grid>
				<Grid item xs={10} sm={10} md={10} lg={8} xl={8}>
					<Grid container direction="row" spacing={2}>
						{alltotal.map((e) => (
							<Grid item xs={12} sm={4} md={4} lg={4}>
								<Partner
									name={e.name}
									desc={e.desc}
									content={e.content}
									content2={e.content2}
									org={e.org}
									org2={e.org2}
									org3={e.org3}
									org4={e.org4}
									org5={e.org5}
									org6={e.org6}
									org7={e.org7}
									org8={e.org8}
									link={e.link}
									img={e.img}
								/>
							</Grid>
						))}
					</Grid>
					<Grid
						container
						justifyContent="center"
						alignItems="center"
						style={{ marginTop: 40, marginBottom: 40 }}
					>
						<Grid item>
							<Pagination
								count={handletotal(all)}
								page={currentPage}
								onChange={handleAll}
								defaultPage={currentPage}
								variant="outlined"
								color="secondary"
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={1} sm={1} md={1} lg={2} xl={2}>
					{" "}
				</Grid>
			</Grid>
		</div>
	);
}

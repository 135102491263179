import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import useStyles from "../../../styles";
const useRowStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},
});

function createData(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8,
	tpn9,
	vl9
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
			{ contentID: `${tpn9}`, amount: `${vl9}` },
		],
	};
}

function createData2(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8,
	tpn9,
	vl9,
	tpn10,
	vl10,
	tpn11,
	vl11,
	tpn12,
	vl12,
	tpn13,
	vl13
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
			{ contentID: `${tpn9}`, amount: `${vl9}` },
			{ contentID: `${tpn10}`, amount: `${vl10}` },
			{ contentID: `${tpn11}`, amount: `${vl11}` },
			{ contentID: `${tpn12}`, amount: `${vl12}` },
			{ contentID: `${tpn13}`, amount: `${vl13}` },
		],
	};
}

function createData3(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8,
	tpn9,
	vl9,
	tpn10,
	vl10,
	tpn11,
	vl11
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
			{ contentID: `${tpn9}`, amount: `${vl9}` },
			{ contentID: `${tpn10}`, amount: `${vl10}` },
			{ contentID: `${tpn11}`, amount: `${vl11}` },
		],
	};
}

function createData4(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8,
	tpn9,
	vl9,
	tpn10,
	vl10,
	tpn11,
	vl11
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
			{ contentID: `${tpn9}`, amount: `${vl9}` },
			{ contentID: `${tpn10}`, amount: `${vl10}` },
			{ contentID: `${tpn11}`, amount: `${vl11}` },
		],
	};
}
function Row(props) {
	const { row } = props;
	const [open, setOpen] = React.useState(false);
	const classes = useRowStyles();

	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row">
					<Typography
						variant="button"
						style={{
							fontWeight: 330,
							textTransform: "unset",
							textAlign: "left",
							cursor: "pointer",
						}}
						onClick={() => setOpen(!open)}
					>
						{row.name}
					</Typography>
				</TableCell>
				<TableCell align="right" style={{ fontWeight: 330 }}>
					{row.total}
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							{/* <Typography variant="h6" gutterBottom component="div">
                Type
              </Typography> */}
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell style={{ fontWeight: 300, fontSize: 18 }}>
											Incidents
										</TableCell>
										<TableCell
											align="right"
											style={{ fontWeight: 300, fontSize: 18 }}
										>
											Cas
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{row.history.map((historyRow) => (
										<TableRow key={historyRow.contentID}>
											<TableCell style={{ fontWeight: 300 }}>
												{historyRow.contentID}
											</TableCell>
											<TableCell align="right" style={{ fontWeight: 300 }}>
												{historyRow.amount}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

Row.propTypes = {
	row: PropTypes.shape({
		// calories: PropTypes.number.isRequired,
		// carbs: PropTypes.number.isRequired,
		// fat: PropTypes.number.isRequired,
		history: PropTypes.arrayOf(
			PropTypes.shape({
				amount: PropTypes.number.isRequired,
				contentID: PropTypes.any.isRequired,
				// contentID: PropTypes.string.isRequired,
			})
		).isRequired,
		name: PropTypes.string.isRequired,
		total: PropTypes.number.isRequired,
	}).isRequired,
};

const Tabls = ({
	title1,
	title2,
	incidentTotal,
	viol,
	conj,
	agr,
	coer,
	har,
	attou,
	etr,
	empl,
	autr,
	physiqueTotal,
	meu,
	coupDepoing,
	coupDobj,
	att,
	attAcouto,
	attqAleau,
	priv,
	privDeNoutr,
	neglDEnfa,
	neglDpers,
	destr,
	rite,
	autr2,
	psychoTotal,
	men,
	inj,
	neglEm,
	humil,
	attVisant,
	isol,
	contr,
	cntrDecom,
	refus,
	abus,
	autr3,
	auteur,
	econTotal,
	conf,
	inter,
	restdeLAccessDeducatio,
	restdeLAccessDemploie,
	restdeLAccessDeconomique,
	privDepriseEncharge,
	levirat,
	pertepropriete,
	perteDenfa,
	autr4,
}) => {
	const rowsx = [
		createData(
			"Incidents de violence sexuelle",
			`${incidentTotal}`,
			"Viol",
			`${viol}`,
			"Viol conjugal entre couples mariés et non mariés",
			`${conj}`,
			"Agression sexuelle sur mineur",
			`${agr}`,
			"Coercition sexuelle",
			`${coer}`,
			"Harcèlement sexuel",
			`${har}`,
			"Attouchements inappropriés",
			`${attou}`,
			"Etre forcé(e) à avoir des relations sexuelles sans préservatif",
			`${etr}`,
			"Etre empêché(e) de faire ses propres choix quant à la décision d'avoir ou non un bébé",
			`${empl}`,
			"Autres",
			`${autr}`
		),
		createData2(
			"Incidents de violence physique (autre que violence sexuelle)",
			`${physiqueTotal}`,
			"Meurtre / féminicide",
			`${meu}`,
			"Coups de poing et/ou coups de pieds",
			`${coupDepoing}`,
			"Coups portés avec des objets",
			`${coupDobj}`,
			"Attaque à l’acide",
			`${att}`,
			"Attaque au couteau ou à la machette",
			`${attAcouto}`,
			"Attaque à l'eau chaude, à l'huile chaude ou autre objet chaud",
			`${attqAleau}`,
			"Privation de la liberté physique de la victime",
			`${priv}`,
			"Privation de nourriture de la victime",
			`${privDeNoutr}`,
			"Négligence physique d'enfant (si la victime est un enfant)",
			`${neglDEnfa}`,
			"Négligence physique de personnes âgées (si la victime est une personne âgée)",
			`${neglDpers}`,
			"Destruction d'objets dans la maison de la victime",
			`${destr}`,
			"Rites de veuvage affectant la santé physique et mentale de la victime",
			`${rite}`,
			"Autres",
			`${autr2}`
		),
		createData3(
			"Incidents de violence psychologique",
			`${psychoTotal}`,
			"Menaces",
			`${men}`,
			"Injures",
			`${inj}`,
			"Négligence émotionnelle",
			`${neglEm}`,
			"Humiliation de la victime face aux autres",
			`${humil}`,
			"Attaques visant à nuire à la réputation de la victime",
			`${attVisant}`,
			"Isolement de la victime de sa famille d'origine ou ses amis",
			`${isol}`,
			"Contrôle des mouvements de la victime",
			`${contr}`,
			"Contrôle de la communication de la victime",
			`${cntrDecom}`,
			"Refus de divorce malgré le souhait de la victime de se divorcer",
			`${refus}`,
			"Abus spirituel / abus religieux de la victime",
			`${abus}`,
			"Autres",
			`${autr3}`
		),
		createData4(
			"Incidents de violence économique et de déni d’opportunités",
			`${econTotal}`,
			"L'auteur abandonne ou chasse la victime",
			`${auteur}`,
			"Confiscation des revenus de la victime",
			`${conf}`,
			"Interdiction de participation à la gestion des revenus du ménage",
			`${inter}`,
			"Restriction de l'accès à l'éducation",
			`${restdeLAccessDeducatio}`,
			"Restriction de l'accès à l'emploi",
			`${restdeLAccessDemploie}`,
			"Restriction de l'accès aux ressources économiques",
			`${restdeLAccessDeconomique}`,
			"Privation d'une prise en charge médicale, de médicaments ou d'appareils d'assistance",
			`${privDepriseEncharge}`,
			"Lévirat après la mort du conjoint",
			`${levirat}`,
			"Perte des propriétés et des biens / non accès à l'héritage après la mort du conjoint ou d'un parent",
			`${pertepropriete}`,
			"Perte des enfants après la mort du conjoint",
			`${perteDenfa}`,
			"Autres",
			`${autr4}`
		),
	];
	const classes = useStyles();
	return (
		<TableContainer component={Paper}>
			<Table aria-label="collapsible table">
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell className={classes.typochart}>{title1}</TableCell>
						<TableCell align="right" className={classes.typochart}>
							{title2}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rowsx.map((row) => (
						<Row key={row.name} row={row} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
export { Tabls };

import {alpha,makeStyles } from '@material-ui/core/styles'



export default  makeStyles((theme)=>({
    root:{
        background: '#F1F2F5',
        width: '101vv',
        
       
    },

    card:{
        marginTop: '100px',
        height: '539px',
        widht: '400px',
    },
    area:{
        "&:hover":{
            backgroundColor:'transparent'
        }

    },

   

    cardHome:{
        marginTop: '80px',
        height: '539px',
        widht: '431px'
        

    }, 
    notchedOutline: {
        borderWidth: '1px solid',
        borderColor: '#979797 !important'
      },
      cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
          borderColor: `#00A1AF !important`,
        }
      },
      cssFocused: {},
      
      menuButton: {
        marginRight: theme.spacing(2),
      },
      title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
        },
      },
      search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(1),
          width: 'auto',
        },
      },
      searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '12ch',
          '&:focus': {
            width: '20ch',
          },
        },
      },

      bigtitle:{
        marginTop:'140px',
        marginBottom:'60px'
      },
      disconnect:{
        color:'red',
        marginTop:'30px',
        textDecoration: 'underline'
        
        
      },
      continue:{
        color:'white',
        backgroundColor:"#00A1AF",
        fontSize:10,
        width:100,
        '&:hover':{
          backgroundColor:'#00A1AF'
        },
      },
      

      dialogTypo:{
        fontWeight:'bold'
      }






}))
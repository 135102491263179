import React from "react";
import {Button,Box,Collapse,IconButton,Table,TableBody,TableRow,TableCell,Typography} from '@material-ui/core'
// import Box from "@material-ui/core/Box";
// import Collapse from "@material-ui/core/Collapse";
// import IconButton from "@material-ui/core/IconButton";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableRow from "@material-ui/core/TableRow";
// import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import useStyles from "../style";
import { useTranslation } from 'react-i18next';

function createData(name,answer) {
  return {
    name,
    answer
  };
}
function Row(props) {
  const { row } = props;
 // console.log(row);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  return (
    <React.Fragment>
      <TableRow>
        <TableCell style={{ width: 40 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align={"left"} scope="row">
          <Button variant='text' style={{textTransform:'unset'}} onClick={() => setOpen(!open)}>
              <Typography className={classes.table_Color1}>{row.name}</Typography>
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={4}>
              <Typography
                variant="Body1"
                gutterBottom
                component="div"
                className={classes.table_Color2}
              >
                {row.answer}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}



export default function FaqTable() {
  const { t } = useTranslation();
  const rows = [
    createData(`${t("question1")}`,`${t("answer1")}`),
    createData(`${t("question2")}`,`${t("answer2")}`),
    createData(`${t("question3")}`,`${t("answer3")}`),
    
  ];
  return (
    <div>
      <Table aria-label="collapsible table">
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from 'react-i18next'
import languageEN from './english/en_translation.json'
import languageFR from './french/fr_translation.json'

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
    resources: {
        en: {
            translations:languageEN
        },
        fr: {
            translations:languageFR
        }
    },
    /* default language when load the website in browser */
   // lng: "fr",
    /* When react i18next not finding any language to as default in borwser */
    //fallbackLng: "en",
    load: 'languageOnly',
    whitelist: ['en', 'fr'],
  
    /* debugger For Development environment */
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
    detection: {
        order: ['path','navigator', 'htmlTag',  'subdomain'],
    },
    react: {
        wait: true,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    }
})

export default i18n;
import React from "react";
import { MapContainer, TileLayer,Popup ,Marker} from 'react-leaflet' //GeoJSON
import MarkerClusterGroup from 'react-leaflet-markercluster';
// import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
const Map =({casemap})=>{

  return (
    <MapContainer center={[-4.214943, 23.284580]} zoom={6} scrollWheelZoom={false} attributionControl={false}>
      <TileLayer  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
       <MarkerClusterGroup>
            {
              casemap.map(e=>{
             //   console.log(e.lat);
                return (
                  <Marker position={[`${e.lat}`,`${e.lon}`]} >
                    
                  <Popup>
                     <span>CASEID: {e.caseid}</span><br/>
                     <span>LOCALISATION: {e.question_12_a}, 
                     {/* {incident['city']} - {incident['zip_code']} */}
                     </span>
                   <br/>  
                     <span>GENRE: {e.question_7}</span><br/>
                  </Popup>
              </Marker>
                )
              })
            }
        </MarkerClusterGroup>

    </MapContainer>
  )
  
}



export default Map;


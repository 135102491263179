import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import useStyles from '../../../styles'
import { Typography } from '@material-ui/core';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function createData3(
  name,
   total,
   tpn,
   vl,
   tpn2,
   vl2,
   tpn3,
   vl3,
   tpn4,
   vl4,
   tpn5,
   vl5,
   tpn6,
   vl6,
   tpn7,
   vl7,
   ) {
  return {
    name,
    total,
    history: [
      { contentID: `${tpn}`, amount: `${vl}`},
      { contentID: `${tpn2}`, amount: `${vl2}`},
      { contentID: `${tpn3}`, amount: `${vl3}`},
      { contentID: `${tpn4}`, amount: `${vl4}`},
      { contentID: `${tpn5}`, amount: `${vl5}`},
      { contentID: `${tpn6}`, amount: `${vl6}`},
      { contentID: `${tpn7}`, amount: `${vl7}`},     
    ],
  };
}


function createData2(
  name,
   total,
   tpn,
   vl,
   tpn2,
   vl2,
   tpn3,
   vl3,
   tpn4,
   vl4,
   tpn5,
   vl5,
   tpn6,
   vl6,
   tpn7,
   vl7,
   tpn8,
   vl8,
   ) {
  return {
    name,
    total,
    history: [
      { contentID: `${tpn}`, amount: `${vl}`},
      { contentID: `${tpn2}`, amount: `${vl2}`},
      { contentID: `${tpn3}`, amount: `${vl3}`},
      { contentID: `${tpn4}`, amount: `${vl4}`},
      { contentID: `${tpn5}`, amount: `${vl5}`},
      { contentID: `${tpn6}`, amount: `${vl6}`},
      { contentID: `${tpn7}`, amount: `${vl7}`},
      { contentID: `${tpn8}`, amount: `${vl8}`},
    
     
      
    ],
  };
}

function createData(
  name,
   total,
   tpn,
   vl,
   tpn2,
   vl2,
   tpn3,
   vl3,
   tpn4,
   vl4,
   tpn5,
   vl5,
   tpn6,
   vl6,
   tpn7,
   vl7,
   tpn8,
   vl8,
   tpn9,
   vl9,
   tpn10,
   vl10,
   tpn11,
   vl11,
   tpn12,
   vl12,
   tpn13,
   vl13,
   tpn14,
   vl14,
   tpn15,
   vl15,
   tpn16,
   vl16,
   ) {
  return {
    name,
    total,
    history: [
      { contentID: `${tpn}`, amount: `${vl}`},
      { contentID: `${tpn2}`, amount: `${vl2}`},
      { contentID: `${tpn3}`, amount: `${vl3}`},
      { contentID: `${tpn4}`, amount: `${vl4}`},
      { contentID: `${tpn5}`, amount: `${vl5}`},
      { contentID: `${tpn6}`, amount: `${vl6}`},
      { contentID: `${tpn7}`, amount: `${vl7}`},
      { contentID: `${tpn8}`, amount: `${vl8}`},
      { contentID: `${tpn9}`, amount: `${vl9}`},
      { contentID: `${tpn10}`, amount: `${vl10}`},
      { contentID: `${tpn11}`, amount: `${vl11}`},
      { contentID: `${tpn12}`, amount: `${vl12}`},
      { contentID: `${tpn13}`, amount: `${vl13}`},
      { contentID: `${tpn14}`, amount: `${vl14}`},
      { contentID: `${tpn15}`, amount: `${vl15}`},
      { contentID: `${tpn16}`, amount: `${vl16}`},
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
        <Typography variant='button' style={{fontWeight:330,textTransform:'unset', textAlign: "left", cursor: "pointer"}} onClick={() => setOpen(!open)}>
          {row.name}
          </Typography>
        </TableCell>
        <TableCell align="right" style={{fontWeight:330,}}>{row.total}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {/* <Typography variant="h6" gutterBottom component="div">
                Type
              </Typography> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{fontWeight:300,fontSize:18}}>Auteur</TableCell>
                    <TableCell align="right" style={{fontWeight:300,fontSize:18}}>Cas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.contentID}>
                      <TableCell style={{fontWeight:300}}>{historyRow.contentID}</TableCell>
                      <TableCell align="right" style={{fontWeight:300}}>{historyRow.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}




Row.propTypes = {
  row: PropTypes.shape({
    // calories: PropTypes.number.isRequired,
    // carbs: PropTypes.number.isRequired,
    // fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        contentID: PropTypes.any.isRequired,
        // contentID: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
};



const Auteurtbl=({
  title1, 
  title2,
  mememberTotal,
  mere,
  pere,
  tuthom,
  tutfem,
  proFrer,
  propSoeur,
  propDenfa,
  gdmere,
  gdpere,
  belmere,
  beaupere,
  beauFere,
  belSoeur,
  oncle,
  tante,
  autrmembre,
  conjointeTotal,
  mari,
  exmari,
  epouse,
  exepouse,
  partHomme,
  partFemme,
  expartHomme,
  expartFemme,
  autreRelationTotal,
  patronHomDom,
  patronFemDom,
  personelHomDomestique,
  personelFemDomestique,
  bailDemezon,
  bailleDemezon,
  autremember2,
})=> {
  const rowsx = [
    createData(
      'Membre de famille', `${mememberTotal}`,
      "Mère",`${mere}`,
      "Père",`${pere}`,
      "Tuteur / gardien (homme)",`${tuthom}`,
      "Tuteur / gardienne (femme)",`${tutfem}`,
      "Propres frères",`${proFrer}`,
      "Propres sœurs",`${propSoeur}`,
      "Propres enfants adultes",`${propDenfa}`,
      "Grand-mere",`${gdmere}`,
      "Grand-père",`${gdpere}`,
      "Belle-mère",`${belmere}`,
      "Beau-père",`${beaupere}`,
      "Beaux-frères",`${beauFere}`,
      "Belles-sœurs",`${belSoeur}`,
      "Oncles",`${oncle}`,
      "Tantes",`${tante}`,
      "Autre(s) membre(s) de la famille de la victime",`${autrmembre}`,
    ),
    createData2(
    "Conjoint(e) ou partenaire intime",`${conjointeTotal}`,
    "Mari",`${mari}`,       
    "Ex-mari",`${exmari}`, 
    "Epouse",`${epouse}`,
    "Ex-épouse",`${exepouse}`,
    "Partenaire intime actuel qui est homme",`${partHomme}`,
    "Partenaire intime actuel qui est femme",`${partFemme}`,
    "Ex-partenaire intime qui est homme",`${expartHomme}`,
    "Ex-partenaire intime qui est femme",`${expartFemme}`,
    
    ),
    createData3(
      "Autres relations de proximité",`${autreRelationTotal}`,
      "Patron (homme) de personnel domestique",`${patronHomDom}`,
      "Patronne (femme) de personnel domestique",`${patronFemDom}`,
      "Personnel domestique (homme)",`${personelHomDomestique}`,
      "Personnel domestique (femme)",`${personelFemDomestique}`,
      "Bailleur / propriétaire de la maison (homme)",`${bailDemezon}`,
      "Bailleresse / propriétaire de la maison (femme)",`${bailleDemezon}`,
      "Autre(s) membre(s) du cercle restreint de la victime",`${autremember2}`,
    ),
  ];
  const classes =useStyles()

    return (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={classes.typochart}>{title1}</TableCell>
              <TableCell align="right" className={classes.typochart}>{title2}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsx.map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  export {
    Auteurtbl
  }
import { Grid, Typography,Divider } from "@material-ui/core";
import useStyles from "../../component/website/campain/style";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Section1 from '../../component/website/overview/section'
import {Helmet} from "react-helmet";


export default function Overview() {
  const classes = useStyles();
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])

  return (
    <div>
       <Helmet>
              <title>{t('overviewtitle')+t('footer-country')}</title>
              <meta name='description' content={t('overviewcontent')}/>
            </Helmet>
      <Grid container className={classes.section_1} alignItems="center">
        <Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={8}>
          <Section1 />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
      </Grid>
      <Grid container className={classes.marginTop_13} style={{ padding: "2vh" }}>
        <Grid item xs={1} sm={1} md={2} lg={2} xl={2}></Grid>
        <Grid item xs={10} sm={10} md={8} lg={8} xl={8} className={classes.marginBottom_5}>
            <Grid container spacing = {2}>
              <Grid item xs={12} sm={12} >
                <Typography className={classes.pg_header}>
                    {t("overviewpageheading1")}
                  </Typography>
              </Grid>
              <Grid item xs={12} sm={12} >
              <Typography className={classes.dark_txt}>
              {t("overviewpageheading1Text-1")}
              <sup>{t('overviewpageheading1Text-1-sup')}</sup>
              {t("overviewpageheading1Text-1-2")}
              <sup>{t('overviewpageheading1Text-1-sup2')}</sup>
              {t("overviewpageheading1Text-1-3")}
              <sup>{t('overviewpageheading1Text-1-sup3')}</sup>
              </Typography>
              </Grid>
              <Grid item xs={12} sm={12} >
                <Typography className={classes.dark_txt}>
                {t("overviewpageheading1Text-2")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing = {2} className={classes.marginTop_13}>
              <Grid item xs={12} sm={12} >
                <Typography className={classes.pg_header}>
                    {t("overviewpageheading2")}
                  </Typography>
              </Grid>
              <Grid item xs={12} sm={12} >
              <Typography className={classes.dark_txt}>
              {t("overviewpageheading2Text")}        
              </Typography>
              </Grid>
              <Grid item xs={12} sm={12} >
                <Typography className={classes.dark_txt}>
                {t("overviewpageheading1Text-2")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} >
                <Typography className={classes.dark_txt}>
                {t("overviewpageheading2Text-3")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing = {2} className={classes.marginTop_13}>
              <Grid item xs={12} sm={12} >
                <Typography className={classes.pg_header}>
                    {t("overviewpageheading3")}
                  </Typography>
              </Grid>
              <Grid item xs={12} sm={12} >
              <Typography className={classes.dark_txt}>
              {t("overviewpageheading3Text1")}
              <sup>{t('overviewpageheading3Text-sup')}</sup>
              {t("overviewpageheading3Text1-2")}
              </Typography>
              </Grid>
              <Grid item xs={12} sm={12} >
                <Typography className={classes.dark_txt}>
                {t("overviewpageheading3Text1-3")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing = {2}>
              <Grid item xs={12} sm={12} >
              <Divider style={{width:300,marginTop:30,marginBottom:10}}/>
              <div style={{marginTop:5}}><small ><sup>1</sup>{t("overviewpageheading3Textsub1")}</small></div>
              <div style={{marginTop:5}}><small ><sup>2</sup>{t("overviewpageheading3Textsub2")}</small></div>
              <div style={{marginTop:5}}><small ><sup>3</sup>{t("overviewpageheading3Textsub3")}</small></div>
              <div style={{marginTop:5}}>{t("overviewpageheading3Textsub4")===""?null: <small ><sup>4</sup>{t("overviewpageheading3Textsub4")}</small>}</div>
              </Grid>
             
            
            </Grid>
        </Grid>
        <Grid item xs={1} sm={1} md={2} lg={2} xl={2}></Grid>
      </Grid>
    </div>
  );
}





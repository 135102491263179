
import { Grid, Typography } from "@material-ui/core";
import React,{useEffect} from "react";
import { useTranslation } from 'react-i18next';
import Section from  '../../component/website/sitemap/section'
import useStyles from "../../component/website/campain/style";
import { NavLink } from "react-router-dom";
import {Helmet} from "react-helmet";


export default function Sitemap() {
  const classes = useStyles();
  const { t,i18n } = useTranslation();

useEffect(() => {
  window.scrollTo(0, 0)

}, [])
  return (
    <div>
      <Helmet>
              <title>{t('sitemap')}</title>
              {/* <meta name='description' content={t('site-description')}/> */}
            </Helmet>
        <Grid container className={classes.section_1} alignItems="center">
        <Grid md={1} lg={1} xl={2}></Grid>
        <Grid md={10} lg={10}>
          <Section />
        </Grid>
        <Grid md={1} lg={1} xl={2}></Grid>
      </Grid>
      <Grid className={classes.marginTop_13} style={{ padding: "2vh" }}>
        <Grid container alignItems="center">
          <Grid item xs={1} sm={1} md={2} lg={2} xl={2}></Grid>
          <Grid item xs={10} sm={10}  md={8} lg={8} xl={8} className={classes.marginBottom_5}>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={12} lg ={12} xl={12}><Typography className={classes.label}>{t('home')}</Typography></Grid>
                        <Grid item xs={12} sm={12} md={12} lg ={12} xl={12}>
                            <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} className={classes.marginBottom_1}>
                                <NavLink className={classes.Link_stl} 
                                to={'/'+i18n.language+t('link-What-is-domestic-violence')} >
                                <Typography className={classes.label1hover}>{t("sectionLeft")}</Typography>
                                </NavLink>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} className={classes.marginBottom_1}>
                                <NavLink className={classes.Link_stl} 
                                to={'/'+i18n.language+t('link-Where-to-find-help')} >
                                  <Typography className={classes.label1hover}> {t("serctioncenter")}</Typography>
                                </NavLink>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} className={classes.marginBottom_1}>
                                <NavLink className={classes.Link_stl} 
                                to={'/'+i18n.language+t('link-Access-to-justice-and-reparations')} >
                                <Typography className={classes.label1hover}>{t("sectionrigth")}</Typography>
                                </NavLink>
                            </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={12} lg ={12} xl={12} ><Typography className={classes.label}>Navigation</Typography></Grid>
                        <Grid item xs={12} sm={12} md={12} lg ={12} xl={12}>
                            <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} className={classes.marginBottom_1}>
                                <NavLink className={classes.Link_stl} 
                                to="/">
                                  <Typography className={classes.label1hover}> {t("home")}</Typography>
                                </NavLink>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} className={classes.marginBottom_1}>
                                <NavLink className={classes.Link_stl} 
                                to={'/'+i18n.language+t('link-about')}>
                                 <Typography className={classes.label1hover}>{t("about")}</Typography>
                                </NavLink>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} className={classes.marginBottom_1}>
                                <NavLink className={classes.Link_stl} 
                                to={'/'+i18n.language+t('link-campain')} >
                                 <Typography className={classes.label1hover}>{t("campain")}</Typography>
                                </NavLink>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} className={classes.marginBottom_1}>
                                <NavLink className={classes.Link_stl} 
                                to={'/'+i18n.language+t('link-news-and-report')} >
                                <Typography className={classes.label1hover}>{t("campaign-title")}</Typography> 
                                </NavLink>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} className={classes.marginBottom_1}>
                                <NavLink className={classes.Link_stl} 
                                to={'/'+i18n.language+t('link-case-statistic')} >
                                <Typography className={classes.label1hover}>{t("case-statistic")}</Typography> 
                                </NavLink>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} className={classes.marginBottom_1}>
                                <NavLink className={classes.Link_stl} 
                                to={'/'+i18n.language+t('link-case-database')}>
                                <Typography className={classes.label1hover}>{t("case-databank")}</Typography> 
                                </NavLink>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} className={classes.marginBottom_1}>
                                <NavLink className={classes.Link_stl} 
                                to={'/'+i18n.language+t('link-faq')}>
                                <Typography className={classes.label1hover}> {t("faq")}</Typography>
                                </NavLink>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} className={classes.marginBottom_1}>
                                <NavLink className={classes.Link_stl} 
                                to={'/'+i18n.language+t('link-contact-us')} >
                                <Typography className={classes.label1hover}>{t("contact")}</Typography> 
                                </NavLink>
                            </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={2} lg={2} xl={6}></Grid>
        </Grid>
      </Grid>
    </div>
  );
}

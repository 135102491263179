import React, { useState, useEffect } from "react";
import useStyles from "../styles";
import {
	Grid,
	Typography,
	TextField,
	Card,
	CardContent,
	Tab,
	Tabs, 
	Box,
	InputAdornment,
	Divider,
	CircularProgress,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { withRouter, useHistory } from "react-router-dom";
import axiosInstance from "../../../service/dashboardAxiosApi";
import SearchIcon from '@material-ui/icons/Search';


// TABLE COLUMNS ---------------------------------------------------------------------------------------
const columns = [
	{ field: "id", headerName: "No", width: 80 },
	{ field: "caseid", headerName: "CASEID", width: 180 },
	{ field: "organization", headerName: "ORGANISATION", width: 180 },
	{ field: "question_2", headerName: "STAFF", width: 180 },
	{ field: "question_3", headerName: "LOCATION OF CASE DOCUMENTATION ", width: 310 },
	{ field: "question_4", headerName: "DATE OF DOCUMENTATION ", width: 230 },
	{ field: "question_5", headerName: "CODE OF SURVIVOR", width: 180 },
	{ field: "question_6_a", headerName: "AGE", width: 100 },
	{ field: "question_7", headerName: "GENDER", width: 140 },
	{
		field: "question_12_a",
		headerName: "LOCATION WHERE VIOLENCE OCCURED",
		width: 340,
	},
];


// TAB PANEL SETTINGS -------------------------------------------------------------------------------------------
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
}

const Cases = () => {
	// CASES STATE VARIABLES ---------------------------------------------------------------------------------------
	const classes = useStyles();
	const history = useHistory();
	const roleLocal = sessionStorage.getItem("role");
	const [cases, setCases] = useState([]);
	const [value, setValue] = useState(0);
	const [loading, setLoading] = useState(false);
	const [networkError, setNetworkError] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const searchTermLowerCase = searchTerm.toLowerCase();


	
	
	// GETTING THE CASES ----------------------------------------------------------------

	useEffect(() => {
		const fetchCaseData = async () => {
		  setLoading(true);
		  try {
			const response = await axiosInstance.get('cases/');
			setCases(response.data.caseItem);
			setLoading(false);
			// console.log(response.data.caseItem)
			
		  } catch (error) {
			if (error.message === "Network Error") {
			  setNetworkError(true);
			}
			// console.error('Something went wrong!', error);
			setLoading(false);
		  }
		};
	
		fetchCaseData();
	  }, []);
	

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleSearchChange = (e) => {
		setSearchTerm(e.target.value);
	};

	const handleRowClick = (params) => {
		history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/case/detail/${params.row.id}`);
	  };

	
	const allCases = cases.filter(caseItem => 
		Object.values(caseItem).some(value => 
		  String(value).toLowerCase().includes(searchTermLowerCase)
		)
	  );
	  const approvedCases = allCases.filter(caseItem => caseItem.approve === true);
	const pendingApprovalCases = allCases.filter(caseItem => caseItem.approve === false);
	

	// DISPLAY DATA ----------------------------------------------------------------------------------
	return (
		<Card className={classes.fullWidthCard}>
			<CardContent className={classes.leftAndRightMiddle}>
		{/* TITLE */}
			<Grid container direction="row">
				<Grid item xs={12} sm={12}>
					<Typography variant="h4" className={classes.bigtitle}>
					Cases
					</Typography>
				</Grid>
			</Grid>
			<Divider className={classes.bottom_margin} />
			<Grid container spacing={2} className={classes.bottom_margin}>
				{/* SEARCH FIELD */}
				<Grid item>
					<TextField 
						label="Search.." 
						id="filled-size-small"
						size="small"
						// fullWidth 
						variant="outlined" 
						value={searchTerm} 
						onChange={handleSearchChange} 
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<SearchIcon />
								</InputAdornment>
							),
						}}/>
				</Grid>
			</Grid>

			{/* TABS */}
			<Grid container direction="row">
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Tabs 
					value={value} 
					onChange={handleChange} 
					variant="fullWidth"
					TabIndicatorProps={{
						className: classes.indicatedColor,
						}}>
						<Tab label={<div>
							All Cases
							<span className={classes.tabDataCount0}>
								{allCases.length}
							</span>
						</div>}  />
						<Tab label={<div>
							Approved
							<span className={classes.tabDataCount1}>
								{approvedCases.length}
							</span>
						</div>}  />
						<Tab label={<div>
							Pending Approval  
							<span className={classes.tabDataCount2}>
								{pendingApprovalCases.length}
							</span>
						</div>}  />
					</Tabs>
				</Grid>	
			</Grid>
			
			{networkError ? 
				<div>
				Network error occurred. Please reload the page.
				<button onClick={() => window.location.reload()}>Reload</button>
				</div> :

				// TAB PANNELS
				<Grid container justifyContent="flex-start" className={classes.table}>
						<Grid item xs={12} sm={12}>
							{/* value={value} */}
							<TabPanel value={value} index={0}>
								{loading ? <CircularProgress /> :
								<div style={{ height: 600, width: '100%' }}>
									<DataGrid 
										rows={allCases} 
										columns={columns} 
										pageSize={20} 
										onRowClick={(event) => {
											if(roleLocal <= 2) {
												handleRowClick(event)
											}
										}} />
								</div>
								}
							</TabPanel>

							<TabPanel value={value} index={1}>
								{loading ? <CircularProgress /> :
								<div style={{ height: 600, width: '100%' }}>
									<DataGrid 
										rows={approvedCases}  
										columns={columns} 
										pageSize={20} 
										onRowClick={(event) => {
											if(roleLocal <= 3) {
												handleRowClick(event)
											}
										}} 
									/>
								</div>
								}
							</TabPanel> 

							<TabPanel value={value} index={2}>
								{loading ? <CircularProgress /> :
								<div style={{ height: 600, width: '100%' }}>
									<DataGrid 
										rows={pendingApprovalCases}
										columns={columns} 
										pageSize={20} 
										onRowClick={(event) => {
											if(roleLocal <= 3) {
												handleRowClick(event)
											}
										}}
									/>
								</div>
								}
							</TabPanel>
						</Grid>
					</Grid>
			}
		  </CardContent>
		</Card>
	);
};

export default withRouter(Cases);


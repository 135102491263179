import { alpha, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({



  marginTopBottom:{
    marginTop:100,
    marginBottom:40,
    [theme.breakpoints.down('sm')]:{
      marginTop:50,
      marginBottom:20,
    }
  },
  imgs:{
    width:'300px',
    [theme.breakpoints.down('sm')]:{
      width:'150px',
    }
  },
    marginTopBottom404:{
     
    marginBottom:40,
    [theme.breakpoints.down('sm')]:{
      // marginTop:30,
      marginBottom:30,
    }
  },

  

  pg_header:{
    color: "#1D384C",
    fontSize:25,
    fontWeight:400,
   // marginBottom:25,
    [theme.breakpoints.down('xs')]:{
      fontSize:20,
      fontWeight:400,
      //marginBottom:20,
    }
  
  },
  Link_stl: {
    "&:hover": {
      color: "#00A1AF",
    },
    textDecoration: "none",
    color: "#1D384C",
    marginTop: "8px",
  },
  label:{
    color: "#1D384C",
    fontWeight:400,
    fontSize:25,
    [theme.breakpoints.down('sm')]:{
      fontSize:20
    }
  },
  labelTitle:{
    marginTop:40,
    color: "#1D384C",
    fontWeight:600,
    fontSize:40,
    [theme.breakpoints.down('sm')]:{
      fontSize:26
    }
  },
  label1:{
    color: "#1D384C",
    fontWeight:300,
    fontSize:15,
    [theme.breakpoints.down('sm')]:{
      fontSize:15
    }
  },
  label1hover:{
    "&:hover": {
      color: "#00A1AF",
    },
    color: "#1D384C",
    fontWeight:300,
    fontSize:15,
    [theme.breakpoints.down('sm')]:{
      fontSize:15
    }
  },
  label2:{
    color:'#00A1AF',
    fontWeight:300,
    textTransform:'capitalize',
    fontSize:15,
    [theme.breakpoints.down('sm')]:{
      fontSize:15
    }
  },
  dark_txt: {
    color: "#1D384C",
    fontWeight: 300,
    fontSize: 18,
    //textDecoration:"unset",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      
    },
  },

btn_txt:{
  textTransform:'capitalize',
  fontWeight:300,
  marginBottom:30,
  marginTop:30,
  [theme.breakpoints.down('xs')]:{
    marginTop:30
  }
},
poste_date:{
  color: "#9e9e9e",
  fontWeight:300,
  fontSize:12,
  marginTop:5
},



  section_1: {
    background: "#1D384C",
    height: "240px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      padding: "3vh",
      align: "center",
      paddingTop: "3vh",
      paddingBottom: "3vh",
    },
  },

  section_article: {
    background: "#1D384C",
    height: "60px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      padding: "3vh",
      align: "center",
      paddingTop: "3vh",
      paddingBottom: "3vh",
    },
  },

  section_4: {
    background: "#4BBDC7",
    height: "auto",
  },
  width_80: {
    width: "80vw",
  },
  width_70: {
    width: "70vw",
  },
  big_white_txt: {
    color: "white",
    
    [theme.breakpoints.down('xs')]: {
      marginTop:60,
      fontSize: 25,
      fontWeight: 400,
    },
    [theme.breakpoints.up('sm')]: {
      // marginLeft:"12vh",
      marginTop:50,
      fontSize: 40,
      fontWeight: 400,
    },

  },

  white_color: {
    color: "white",
    [theme.breakpoints.down("xs")]: {
      marginTop:10,
      fontSize:14,
      fontWeight:300
      
    },
    [theme.breakpoints.up("sm")]: {
      marginTop:10,
      fontSize:16,
      fontWeight:300
      
    },
  },
 
  sub_big_white_txt: {
    color: "white",
    fontWeight: 700,
    fontSize: "40px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "16px",
    },
  },

  white_txt: {
    color: "white",
    fontSize: "16px",
  },

 

  green_color: {
    color: "#00A1AF",
  },

  overview_ttl: {
    color: "#1D384C",
    fontWeight: 700,
    textAlign: "center",
    fontSize: "50px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "23px",
    },
  },

  about_ttl: {
    color: "#1D384C",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "40px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "23px",
    },
  },
  goal_ttl: {
    color: "#FFF",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "40px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "15px",
    },
  },

  abt_txt: {
    color: "#1D384C",
    textAlign: "left",
    fontWeight: 400,
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "15px",
    },
  },

  about_link: {
    color: "#00A1AF",
    fontWeight: 700,
    textAlign: "left",
    fontStyle: "italic",
    textDecoration: "underline",
    fontSize: "30px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "15px",
    },
  },

  // dark_txt: {
  //   color: "#1D384C",
  //   textAlign: "center",
  //   fontWeight: 400,
  //   fontSize: "25px",
  //   [theme.breakpoints.down("sm")]: {
  //     // marginLeft:"12vh",
  //     fontSize: "15px",
  //   },
  // },
  dark_color: {
    color: "#1D384C",
  },
  oxide_color: {
    color: "#EB9B29",
  },
  oxide_text: {
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "12px",
    },
  },

  overview_link: {
    color: "#00A1AF",
    fontWeight: 700,
    textAlign: "center",
    fontStyle: "italic",
    textDecoration: "underline",
    fontSize: "30px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "15px",
    },
  },

  card_circle: {
    height: "175px",
    width: "175px",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      display: "center",
    },
  },
  card_pic: {
    height: "480px",
    width: "480px",
    backgroundColor: "#00A1AF",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      height: "280px",
      width: "320px",
    },
  },
  card_stl: {
    backgroundColor: "#00A1AF",
  },
  marginLeft_2: {
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      marginLeft: "2.5vh",
    },
  },

  goals_stl: {},

  marginBottom_5: {
    marginBottom: "5vh",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      marginBottom: "2.5vh",
    },
  },
  marginBottom_15: {
    marginBottom: "15vh",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      marginBottom: "5vh",
    },
  },
  marginBottom_10: {
    marginBottom: "10vh",
  },

  overview_ttl2: {
    color: "#1D384C",
    fontWeight: 700,
    marginBottom: 35,
    textAlign: "center",
    fontSize: "1vw",
  },

  marginLeft_10: {
    marginLeft: "10vw",
  },

  marginTop_10: {
    marginTop: "10vh",
  },

  marginTop_13: {
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      // marginTop: "7vh",
    },
  },

  big_dark_tittle: {
    color: "#1D384C",
    fontWeight: 700,
    textAlign: "center",
  },

  card: {
    marginTop: "100px",
    height: "539px",
    widht: "400px",
  },

  divider_stl: {
    widht: "5px",
    color: "white",
  },

  area: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  cardHome: {
    marginTop: "80px",
    height: "539px",
    widht: "431px",
  },
  notchedOutline: {
    borderWidth: "1px solid",
    borderColor: "#979797 !important",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `#00A1AF !important`,
    },
  },
  cssFocused: {},

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },

  bigtitle: {
    marginTop: "140px",
    marginBottom: "60px",
  },
  disconnect: {
    color: "red",
    marginTop: "30px",
    textDecoration: "underline",
  },
  continue: {
    color: "white",
    backgroundColor: "#00A1AF",
    fontSize: 10,
    width: 100,
    "&:hover": {
      backgroundColor: "#00A1AF",
    },
  },

  dialogTypo: {
    fontWeight: "bold",
  },
  margin_Bottom_30: {
    marginBottom: 20,
  },
  margin_Bottom_10: {
    marginBottom: 10,
  },
  marginTop_20: {
    marginTop: 20,
  },
  img: {
    marginTop: 40,
    // borderBottom:30,
    height: 500,
    [theme.breakpoints.only("xs")]: {
      height: 320,
    },
    marginBottom: 30,
  },
  posts: {
    maxWidth: 345,
    padding: 10,
  },
  posts_media: {
    height: 500,
    [theme.breakpoints.only("xs")]: {
      height: 320,
    },
  },
  marginTop_and_marginBottom: {
    marginTop: 30,
    marginBottom: 30,
  },
  loading: {
    color: "white",
    height: "40vh",
  },
  progbar: {
    marginTop: "15vh",
  },
  loadingtext: {
    fontSize: 100,
  },
  marginTopbot_10: {
    marginTop: "7vh",
    marginBottom: "7vh",
  },
}));

import React from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "./style.js";
import { useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom'




const Section1 = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  
  return (
    <div>
      <Grid container className={classes.section}>
        <Grid container className={classes.marginBottom_5}>
          <Grid item>
            <Typography className={classes.big_white_txt}>
              {t('subtitle')}
            </Typography>
            <Typography className={classes.sub_big_white_txt}>
              {t('sub1')}
            </Typography>
            <Typography className={classes.sub_big_white_txt}>
              {t('sub2')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="row" spacing={2}>
          <Grid item>
          <Link to={'/'+i18n.language+t('link-What-is-domestic-violence')} className={classes.link}>
            <Typography variant="body1" className={classes.tile_txt1}>
             {t('sectionLeft')}
            </Typography>
           
            <Typography variant="body2" className={classes.tile_txt2}>
            {t('sectionLeft-text')}
            </Typography>
            </Link>
          </Grid>
          <Grid item>
           <Link to={'/'+i18n.language+t('link-Where-to-find-help')}  className={classes.link}>
           <Typography variant="body1" className={classes.tile_txt1}>
             {t('serctioncenter')}
            </Typography>
           
            <Typography variant="body2" className={classes.tile_txt2}>
            {t('sectioncenter-text')}
            </Typography>
            </Link>
          </Grid>
          <Grid item>
           <Link 
           to={'/'+i18n.language+t('link-Access-to-justice-and-reparations')} 
           className={classes.link}>
           <Typography variant="body1" className={classes.tile_txt1}>
             {t('sectionrigth')}
            </Typography>
           
            <Typography variant="body2" className={classes.tile_txt2}>
            {t('sectionright-text')}
            </Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Section1;

import React from 'react';
import {
     Grid,
     Typography,
     Button
    } from '@material-ui/core'
import useStyles from './style.js'
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const Section5=()=>{
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(!open);
    };
    const [open2, setOpen2] = React.useState(false);

    const handleClick2 = () => {
      setOpen2(!open2);
    };

  const { t} = useTranslation();

    return(

            <Grid container direction = 'row' spacing ={3}>
                <Grid item xs={12} sm={12} md={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} >
                        <Typography  className={classes.goal_ttl}>
                               {t("main-goal-title")}
                             </Typography>
                             </Grid>
                        <Grid item xs={12} sm={12} >
                        <Typography  className={classes.white_color}>
                            {t("main-goal-text")}
                             </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                   <Grid container direction='row'>
                       <Grid item xs={12} sm={1}>
                           <hr className={classes.hr}/>
                       </Grid>
                       <Grid item xs={12} sm={11}>
                           <Grid container spacing={2} >
                               <Grid item xs={12} sm={12}>
                               <Typography  className={classes.white_color_title}> {t("main-goal-sub1-title")}</Typography>
                               </Grid>
                               <Grid item xs={12} sm={12}>
                                    <Typography  className={classes.white_color}>
                                      {t("main-goal-sub1-content")}
                                        </Typography>
                               </Grid>
                           </Grid>
                       </Grid>
                       </Grid> 
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                <Grid container direction='row'>
                       <Grid item xs={12} sm={1}>
                           <hr className={classes.hr}/>
                       </Grid>
                       <Grid item xs={12} sm={11}>
                           <Grid container spacing={2} >
                               <Grid item xs={12} sm={12}>
                               <Typography className={classes.white_color_title}> {t("main-goal-sub2-title")}</Typography>
                               </Grid>
                               <Grid item xs={12} sm={12}>
                                    <Typography  className={classes.white_color}>
                                   
                                  
                                      {open?
                                       <Collapse in={open} timeout="auto" unmountOnExit>
                                           {t('main-goal-sub2-content-1')}
                                         </Collapse>:<> {t("main-goal-sub2-content")}</>}
                                     
                                        </Typography>
                                        <Button
                                            size="small"
                                            color="primary"
                                            variant='text'
                                            endIcon={open ? <ExpandLess /> : <ExpandMore />}
                                            onClick={handleClick}
                                            className={classes.readmore}
                                            >
                                            {open? `${t('read-less')}`:`${t('read-more')}`}
                                            </Button>
                                
                               </Grid>
                           </Grid>
                       </Grid>
                       </Grid> 
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                <Grid container direction='row'>
                       <Grid item xs={12} sm={1}>
                           <hr className={classes.hr}/>
                       </Grid>
                       <Grid item xs={12} sm={11}>
                           <Grid container spacing={2} >
                               <Grid item xs={12} sm={12}>
                               <Typography  className={classes.white_color_title}> {t("main-goal-sub3-title")}</Typography>
                               </Grid>
                               <Grid item xs={12} sm={12}>
                                    <Typography  className={classes.white_color}>
                                    
                                  
                                     {!open2?<>{t("main-goal-sub3-content")}</>:
                                        <Collapse in={open2} timeout="auto" unmountOnExit>
                                            {t('main-goal-sub3-content-1')}
                                            </Collapse>}
                                      
                                        </Typography>
                                        <Button
                                            size="small"
                                            color="primary"
                                            variant='text'
                                            endIcon={open2 ? <ExpandLess /> : <ExpandMore />}
                                            onClick={handleClick2}
                                            className={classes.readmore}
                                            >
                                            {open2? `${t('read-less')}`:`${t('read-more')}`}
                                            </Button>
                                       
                               </Grid>
                           </Grid>
                       </Grid>
                       </Grid> 
                </Grid>
            </Grid>

    )
}

export default Section5;
import React, { useState, useEffect } from "react";
import {
	Grid,
	Typography,
	Divider,
	Card,
	CardContent,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	TextField,
	InputAdornment, 
	IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import useStyles from "../styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import axiosInstance from "../../../service/dashboardAxiosApi";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useParams } from "react-router-dom";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { Visibility, VisibilityOff } from "@material-ui/icons";


 
const Detail = () => {
	const { id } = useParams();
  	const [userData, setUserData] = useState(null);
	const classes = useStyles();
	const role = sessionStorage.getItem("role");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [passOpen, setPassOpen] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [openDialogForActivateUser, setOpenDialogForActivateUser] = useState(false);
	const [internetError, setInternetError] = useState(false);
	const [responseMessage, setResponseMessage] = useState("");
	const [successResponse, setSuccessResponse] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);
	const history = useHistory();



	const fetchUser = async () => {

		// Return Internet Error
		if (!navigator.onLine) {
		//   console.log("No internet connection.");
		  setResponseMessage("No internet connection.")
		  setInternetError(true);
		  return;
		}
	  
		try {
		//   console.log(`Fetching user with id: ${id}`);
		  const response = await axiosInstance.get(`/users/${id}`, {timeout: 10000});
		//   console.log(`Fetched user:`, response.data);
		  setUserData(response.data);
		  setInternetError(false);
		  setSuccessResponse(true)
		} catch (err) {
		//   console.log('Error fetching user: ', err);
		  if (err.code === 'ECONNABORTED') {
			// console.log("The request took too long - please try again.");
			setResponseMessage("The request took too long - please try again.")
			setInternetError(true);
		  } 

		  setSuccessResponse(false)
		}
	  };
	  
	useEffect(() => {
		fetchUser();
	  }, [id]);
	
	// Display load icon if data is not fetched
	if (!userData) {
		return <CircularProgress />;
		
	}

	// If no internet, reload
	if (internetError) {
		return (
		<div>
			<p>An error occurred. Please check your internet connection and try again.</p>
			<Button variant="contained" color="primary"  onClick={fetchUser}>Retry</Button>
		</div>
		)
	}

	// Redirect to edit page
	const handleEdit = () => {
		history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/user/edit/${id}`);
	};

	//   DEACTIVATE USER
	const handleDeactivate = async () => {
		setIsUpdating(true);
		// send request to backend to deactivate the user
		try {
			await axiosInstance.patch(`/user/${id}/`, {
				activated: userData.users.activated === true ? false : userData.users.activated === false ? true : userData.users.activated,
				is_active: userData.users.activated === true ? false : userData.users.activated === false ? true : userData.users.activated,
				role: userData.users.role === "Super Admin" ? 1 : userData.users.role === "Admin" ? 2 : userData.users.role === "Author" ? 3 : userData.users.role === "Data Collector" ? 4 : userData.users.role,
			});
			setResponseMessage(userData.users.activated === false ? `${userData.users.first_name} has been activated` : `${userData.users.first_name} has been deactivated`)
			// Fetch the user to update the display
			fetchUser();
		} catch (err) {
			// console.error('Error deactivating user:', err);
			setResponseMessage("Error deactivating user. Check your internet or contact admin")
		}
		setIsUpdating(false);
		handleCloseDialogForActivateUser();
		handleClickSnack(); // CALLING THE SNACKBAR
	};

	// PASSWORD CHANGE
	const handleChangePassword = async () => {
		setIsUpdating(true);
		try {
			await axiosInstance.patch(`changepassword/${id}/`, {
				password: password,
				role: userData.users.role === "Super Admin" ? 1 : userData.users.role === "Admin" ? 2 : userData.users.role === "Author" ? 3 : userData.users.role === "Data Collector" ? 4 : userData.users.role,
			});
			// console.log('User Password has been changed');
			setResponseMessage(`${userData.users.first_name}'s password has been changed`)
			fetchUser();
		} catch (err) {
			// console.error('Error occured:', err);
			setResponseMessage("Error occured. Check your internet or contact admin")
		}
		setPassword('');
		setConfirmPassword('');
		setIsUpdating(false);
		handlePassClose();
		handleClickSnack();
	};


	// HANDLING SNACKBAR OPENING AND CLOSING
	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});

	const handleCloseScnackBar = (event, reason) => {
		if (reason === 'clickaway') {
		return;
		}

		setOpenSnackBar(false);
	};

	const handleClickSnack = () => {
		setOpenSnackBar(true);
	};


	//  Activate user case diologue
	const handleOpenDialogForActivateUser = () => {
		setOpenDialogForActivateUser(true);
	};

	const handleCloseDialogForActivateUser = () => {
		setOpenDialogForActivateUser(false);
	};
	

	// Password case diologue
	const handlePassClickOpen = () => {
		setPassOpen(true);
	};

	const handlePassClose = () => {
		setPassword('');
		setConfirmPassword('');
		setPassOpen(false);
	};
	

	return (
		
		<Grid container direction="row">
			<Grid item xs={12}>
				<Typography variant="h5" className={classes.bigtitle}>
				{userData.users.first_name} {userData.users.activated ? <CheckIcon style={{ color: 'green', fontSize: 50 }} /> : <CloseIcon style={{ color: 'red', fontSize: 50 }} />}
				</Typography>
			</Grid>

			{/* RESPONSE MESSAGE SNACKBAR */}
			<Grid item xs={12} sm={4}>
				<Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseScnackBar}>
					{ successResponse ? <Alert onClose={handleCloseScnackBar} severity="success" sx={{ width: '100%' }}>
						{responseMessage}
					</Alert>
					:
					<Alert onClose={handleCloseScnackBar} severity="error" sx={{ width: '100%' }}>
					{responseMessage}
					</Alert> }
				</Snackbar>
			</Grid>

			<Divider />

			<Grid item xs={12}>
				<Card className={classes.grid}>
					<CardContent>
						<Grid container justifyContent="center">
							{/* division of the section into part */}
							<Grid item sm={10}>
								<Grid container>
									<Grid item xs={12} sm={12}>
										<Grid container>
											{/* LEFT SECTION */}
											<Grid item xs={12} sm={6}>
												<Grid
													container
													className={classes.margin_Bottom_30}
													direction="row"
												>
													<Grid item xs={12} sm={12}>
														<Typography className={classes.detailTypo}>
															Role
														</Typography>{" "}
													</Grid>
													<Grid item xs={12} sm={12}>
														<Typography className={classes.detailTypoGrow}>
															<strong>{userData.users.role}</strong>
														</Typography>{" "}
													</Grid>
												</Grid>

												<Grid
													container
													className={classes.margin_Bottom_30}
												>
													<Grid item  xs={12} sm={12}>
														<Typography className={classes.detailTypo}>
															Organisation
														</Typography>{" "}
													</Grid>
													<Grid item xs={12} sm={12}>
														<Typography className={classes.detailTypoGrow}>
															<strong>{userData.users.organization}</strong>
														</Typography>{" "}
													</Grid>
												</Grid>

												<Grid
													container
													className={classes.margin_Bottom_30}
												>
													<Grid item xs={12} sm={12}>
														<Typography className={classes.detailTypo}>
															Abbreviation of the Organisation
														</Typography>{" "}
													</Grid>
													<Grid item xs={12} sm={12}>
														<Typography className={classes.detailTypoGrow}>
															<strong>{userData.users.abbreviation}</strong>
														</Typography>{" "}
													</Grid>
												</Grid>

												<Grid
													container
													className={classes.margin_Bottom_30}
												>
													<Grid item xs={12} sm={12}>
														<Typography className={classes.detailTypo}>
															Address
														</Typography>{" "}
													</Grid>
													<Grid item xs={12} sm={12}>
														<Typography className={classes.detailTypoGrow}>
															<strong>{userData.users.address}</strong>
														</Typography>{" "}
													</Grid>
												</Grid>
												<Grid
													container
													className={classes.margin_Bottom_30}
												>
													<Grid item sm={12}>
														<Typography className={classes.detailTypo}>
															Registered on:
														</Typography>{" "}
													</Grid>
													<Grid item sm={12}>
														<Typography className={classes.detailTypoGrow}>
															<strong>{userData.users.date_joined}</strong>
														</Typography>{" "}
													</Grid>
												</Grid>

											</Grid>

											{/* RIGHT SECTION */}
											<Grid item xs={12} sm={6}>
												<Grid
													container
													className={classes.margin_Bottom_30}
												>
													<Grid item sm={12}>
														<Typography className={classes.detailTypo}>
															First Name
														</Typography>{" "}
													</Grid>
													<Grid item sm={12}>
														<Typography className={classes.detailTypoGrow}>
															<strong>{userData.users.first_name}</strong>
														</Typography>{" "}
													</Grid>
												</Grid>

												<Grid
													container
													className={classes.margin_Bottom_30}
												>
													<Grid item sm={12}>
														<Typography className={classes.detailTypo}>
															Last Name
														</Typography>{" "}
													</Grid>
													<Grid item sm={12}>
														<Typography className={classes.detailTypoGrow}>
															<strong>{userData.users.last_name}</strong>
														</Typography>{" "}
													</Grid>
												</Grid>

												<Grid
													container
													className={classes.margin_Bottom_30}
												>
													<Grid item sm={12}>
														<Typography className={classes.detailTypo}>
															Phone
														</Typography>{" "}
													</Grid>
													<Grid item sm={12}>
														<Typography className={classes.detailTypoGrow}>
															<strong>{userData.users.phone_number}</strong>
														</Typography>{" "}
													</Grid>
												</Grid>

												<Grid
													container
													className={classes.margin_Bottom_30}
												>
													<Grid item sm={12}>
														<Typography className={classes.detailTypo}>
															E-mail
														</Typography>{" "}
													</Grid>
													<Grid item sm={12}>
														<Typography className={classes.detailTypoGrow}>
															<strong>{userData.users.email}</strong>
														</Typography>{" "}
													</Grid>
												</Grid>

												<Grid
													container
													className={classes.margin_Bottom_30}
												>
													<Grid item sm={12}>
														<Typography className={classes.detailTypo}>
															Registered by:
														</Typography>{" "}
													</Grid> 
													<Grid item sm={12}>
														<Typography className={classes.detailTypoGrow}>
															<strong>{userData.users.created_by}</strong>
														</Typography>{" "}
													</Grid>
												</Grid>
											</Grid>
											{role <= 1 ? (
											
											<Grid container spacing={2}>
												{/* EDIT BUTTON */}
												<Grid item>
													<Button startIcon={<EditIcon />} onClick={handleEdit} variant="contained" color="primary">
														Edit
													</Button>
												</Grid>

												{/* ACTIVATE / DEACTIVATE BUTTON*/}
												<Grid item>
													<Button startIcon={userData.users.activated ? <CloseIcon /> : <CheckIcon />}
															onClick={handleOpenDialogForActivateUser}
															variant="contained"
															color={userData.users.activated ? "inherit" : "secondary"}>
														{userData.users.activated ? "Deactivate" : "Activate"}
													</Button>

													<Dialog open={openDialogForActivateUser} onClose={handleCloseDialogForActivateUser}>
														<DialogTitle>Confirmation</DialogTitle>
														<DialogContent>
														<DialogContentText>
															Are you sure you want to {userData.users.activated ? "deactivate" : "activate"} <strong> {userData.users.first_name}? </strong> 
														</DialogContentText>
														</DialogContent>
														<DialogActions>
														<Button onClick={handleCloseDialogForActivateUser} color="primary">
															Cancel
														</Button>
														<Button onClick={handleDeactivate} color="secondary">
														{isUpdating ? <CircularProgress size={24} /> : `Yes, ${userData.users.activated ? "Deactivate" : "Activate"}`}
														</Button>
														</DialogActions>
													</Dialog>
												</Grid>

												{/* CHANGE PASSWORD BUTTON*/}
												<Grid item>
													<Button variant="outlined" color="primary" onClick={handlePassClickOpen}>
														Change Password
													</Button>
													<Dialog open={passOpen} onClose={handlePassClose} aria-labelledby="form-dialog-title">
														<DialogTitle id="form-dialog-title">Change Password</DialogTitle>
														<DialogContent>
														<TextField
															autoFocus
															margin="dense"
															id="password"
															label="New Password"
															type={showPassword ? "text" : "password"}
															fullWidth
															value={password}
															error={password !== confirmPassword && confirmPassword !== ''}
															helperText={password !== confirmPassword && confirmPassword !== '' ? 'Passwords do not match!' : ''}
															onChange={e => setPassword(e.target.value)}
															InputProps={{
																endAdornment: (
																<InputAdornment position="end">
																	<IconButton
																	onClick={() => setShowPassword(!showPassword)}
																	>
																	{showPassword ? <Visibility /> : <VisibilityOff />}
																	</IconButton>
																</InputAdornment>
																),
															}}
														/>
														<TextField
															margin="dense"
															id="confirmPassword"
															label="Confirm New Password"
															type={showPassword ? "text" : "password"}
															fullWidth
															value={confirmPassword}
															error={password !== confirmPassword && confirmPassword !== ''}
															helperText={password !== confirmPassword && confirmPassword !== '' ? 'Passwords do not match!' : ''}
															onChange={e => setConfirmPassword(e.target.value)}
															InputProps={{
																endAdornment: (
																<InputAdornment position="end">
																	<IconButton
																	onClick={() => setShowPassword(!showPassword)}
																	>
																	{showPassword ? <Visibility /> : <VisibilityOff />}
																	</IconButton>
																</InputAdornment>
																),
															}}
														/>
														</DialogContent>
														<DialogActions>
														<Button onClick={handlePassClose} color="primary">
															Cancel
														</Button>
														<Button onClick={handleChangePassword} color="secondary" disabled={isUpdating}>
															{isUpdating ? <CircularProgress size={24} /> : "Confirm"}
														</Button>	
														</DialogActions>
													</Dialog>
												</Grid>

											</Grid>
											) : null}
										</Grid>
									</Grid>
								</Grid>

							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);

}
  
export default Detail;
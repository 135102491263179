import React, { useState, useEffect } from "react";
import {
	AppBar,
	CssBaseline,
	Drawer,
	Hidden,
	List,
	ListItem,
	ListItemText,
	Toolbar,
	ListItemIcon,
	Grid,
	Avatar,
	Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import useStyles from "./style";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import MapIcon from "@material-ui/icons/Map";
import TimelineRoundedIcon from "@material-ui/icons/TimelineRounded";
import PeopleIcon from "@material-ui/icons/People";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import SendIcon from "@material-ui/icons/Send";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory, withRouter } from "react-router-dom";
import { BtnLogout } from "../../../ui/widget/util";
import axiosInstance from "../../service/dashboardAxiosApi";
// import { useTheme } from '@material-ui/core/styles';

const Main = (props) => {
	const { window } = props;
	const classes = useStyles();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [user, setuser] = useState("");
	const { history } = props;
	// const theme = useTheme();
	const histo = useHistory();
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	// const auth=()=>{
	//   const tokn =sessionStorage.getItem('tokens')
	//   settoken(tokn)
	//   //console.log("my token \t\t\t"+tokn);

	// }
	const getUser = () => {
		const user = sessionStorage.getItem("users");
		setuser(user);
		//console.log("my token \t\t\t"+user);
	};
	const removeAuth = async () => {
		try {
			const response = await axiosInstance.post("blacklist/", {
				refresh: sessionStorage.getItem("refreshs"),
			});
			sessionStorage.removeItem("tokens");
			sessionStorage.removeItem("refreshs");
			sessionStorage.removeItem("users");
			sessionStorage.removeItem("orgs");
			sessionStorage.removeItem("ids");
			axiosInstance.defaults.headers["Authorization"] = null;
			histo.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/login`);
			return response;
		} catch (e) {
			sessionStorage.removeItem("tokens");
			sessionStorage.removeItem("refreshs");
			sessionStorage.removeItem("users");
			sessionStorage.removeItem("orgs");
			sessionStorage.removeItem("ids");
			//console.log(e);
			histo.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/login`);
		}

		////console.log('remove token');
	};

	useEffect(() => {
		getUser();
	}, []); //axiosInstance

	const drawerList = [
		{
			text: "Cases",
			icon: <FiberManualRecordIcon className={classes.icon} />,
			onClick: () =>
				history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard`),
		},
		{
			text: "Case Statistics",
			icon: <TimelineRoundedIcon className={classes.icon} />,
			onClick: () =>
				history.push(
					`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/case/statistic`
				),
		},
		{
			text: "Case Databank & Case Map",
			icon: <MapIcon className={classes.icon} />,
			onClick: () =>
				history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/maps`),
		},
		{
			text: "Analysis",
			icon: <FindInPageIcon className={classes.icon} />,
			onClick: () =>
				history.push(
					`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/analysis`
				),
		},
		{
			text: "Campaign & Observatory News",
			icon: <SendIcon className={classes.icon} />,
			onClick: () =>
				history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/posts`),
		},
		{
			text: "Users",
			icon: <PeopleIcon className={classes.icon} />,
			onClick: () => {
				history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/users`);
			},
		},
	];

	const drawer = (
		<div>
			{/* Avatar */}
			<Grid
				container
				direction="column"
				justifyContent="center"
				alignItems="center"
				className={classes.avatar}
			>
				<Grid item sm={12} xs={12} className={classes.margin_Top_30}>
					<Avatar className={classes.avatarSize}>
						<b>{user.substr(0, 2).toUpperCase()}</b>
					</Avatar>
				</Grid>
				<Grid item sm={12} xs={12} className={classes.margin_Bottom_30}>
					<Typography className={classes.typography}>
						<b>{user.toUpperCase()}</b>
					</Typography>
				</Grid>
			</Grid>
			{/* <div className={classes.toolbar} />
			 */}
			<List>
				{drawerList.map((item, index) => {
					const { text, icon, onClick } = item;
					return (
						<ListItem button key={text} onClick={onClick}>
							{icon && <ListItemIcon>{icon}</ListItemIcon>}
							<ListItemText className={classes.title} primary={text} />
						</ListItem>
					);
				})}
			</List>
			<Grid
				container
				direction="row"
				justifyContent="center"
				className={classes.margin_100}
				spacing={1}
			>
				<Grid item>
					<BtnLogout
						content="Logout"
						endIcon={<ExitToAppIcon className={classes.icon} />}
						onClick={removeAuth}
					/>
				</Grid>
				{/* <Grid item><Typography>Logout</Typography></Grid> */}
			</Grid>
		</div>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;
	// console.log(container);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={classes.appBar}
				style={{ backgroundColor: "transparent", boxShadow: "0px 0px 0px 0px" }}
			>
				<Toolbar variant="dense">
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						className={classes.menuButton}
					>
						<MenuIcon style={{ color: "#4F2970" }} />
					</IconButton>
					{/* <Typography variant="h6" noWrap>
            Responsive drawer
          </Typography> */}
				</Toolbar>
			</AppBar>
			<nav className={classes.drawer} aria-label="DV_OBSERVATOR">
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Hidden mdUp implementation="css">
					<Drawer
						container={container}
						variant="temporary"
						// anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						anchor="left"
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{drawer}
					</Drawer>
				</Hidden>
				<Hidden smDown implementation="css">
					<Drawer
						classes={{
							paper: classes.drawerPaper,
						}}
						variant="permanent"
						open
					>
						{drawer}
					</Drawer>
				</Hidden>
			</nav>
			<main className={classes.content}>{props.children}</main>
		</div>
	);
};

export default withRouter(Main);

import axios from "axios";
import {config} from '../../config/config'


//const baseURL = 'http://127.0.0.1:8000/' ?? 'http://143.198.8.57';
const baseURL = config.url.API_URL;


const axiosInstance = axios.create({
	baseURL: baseURL,
	timeout: 60000,
	headers: {
		Authorization: sessionStorage.getItem('token')
			? 'Bearer ' + sessionStorage.getItem('token')
			: null,
		'Content-Type': 'application/json',
		accept: 'application/json',
	}, 
});


axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest =  await error.config; // Added await #Joe

		if (typeof error.response === 'undefined') {
			// alert(
			// 	'A server/network error occurred. ' +
			// 		'Sorry about this - we will get it fixed shortly.'
			// );
			return await Promise.reject(error); // Added await #Joe
		}

		if (
			error.response.status === 401 &&
			originalRequest.url === baseURL + 'token/refresh/'
		) {
			window.location.href = `/${process.env.REACT_APP_COLLECT_LINK}/collect/login`;
			
			return await Promise.reject(error); // Added await #Joe
		}

		if (
			error.response.data.code === 'token_not_valid' &&
			error.response.status === 401 &&
			error.response.statusText === 'Unauthorized'
		) {
			const refreshToken = sessionStorage.getItem('refresh');

			if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

				// exp date in token is expressed in seconds, while now() returns milliseconds:
				const now = Math.ceil(Date.now() / 1000);
				//console.log(tokenParts.exp);

				if (tokenParts.exp > now) {
					return await axiosInstance // Added await #Joe
						.post('/token/refresh/', { refresh: refreshToken })
						.then((response) => {
							sessionStorage.setItem('token', response.data.access);

							axiosInstance.defaults.headers['Authorization'] =
								'Bearer ' + response.data.access;
							originalRequest.headers['Authorization'] =
								'Bearer ' + response.data.access;

							return axiosInstance(originalRequest);
						})
						.catch((err) => {
							//console.log(err.response);
							//console.log(err.request);
						});
				} else {
					//console.log('Refresh token is expired', tokenParts.exp, now);
					sessionStorage.removeItem('token');
					sessionStorage.removeItem('refresh');
					window.location.href = `/${process.env.REACT_APP_COLLECT_LINK}/collect/login`;
				}
			} else {
				//console.log('Refresh token not available.');
				sessionStorage.removeItem('token');
				sessionStorage.removeItem('refresh');
				window.location.href = `/${process.env.REACT_APP_COLLECT_LINK}/collect/login`;
			}
		}

		// specific error handling done elsewhere
		return await Promise.reject(error); // Added await #Joe
	}
);

export default axiosInstance;


// const baseURL = "http://127.0.0.1:8000/";

// const axiosInstance = axios.create({
//   baseURL: baseURL,
//   timeout: 5000,
//   headers: {
//     Authorization: sessionStorage.getItem('token')
//       ? "Bearer " + sessionStorage.getItem('token')
//       : null,
//     "Content-Type": "application/json",
//     //"Access-Control-Allow-Origin": "http://127.0.0.1:8000/",
//     accept: "application/json",
//   },
// });

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const originalRequest = error.config;

//     // Prevent infinite loops early
//     if (
//       error.response.status === 401 &&
//       originalRequest.url === baseURL + "token/refresh/"
//     ) {
//       window.location.href = "/collect/login";
//       return Promise.reject(error);
//     }

//     if (
//       error.response.data.code === "token_not_valid" &&
//       error.response.status === 401 &&
//       error.response.statusText === "Unauthorized"
//     ) {
//       const refreshToken = sessionStorage.getItem("refresh");

//       if (refreshToken) {
//         const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

//         // exp date in token is expressed in seconds, while now() returns milliseconds:
//         const now = Math.ceil(Date.now() / 1000);
//         //console.log(tokenParts.exp);

//         if (tokenParts.exp > now) {
//           return axiosInstance
//             .post("/token/refresh/", {refresh: refreshToken})
//             .then((response) => {
//               sessionStorage.setItem("token", response.data.access);
//               //sessionStorage.setItem("refresh", response.data.refresh);

//               axiosInstance.defaults.headers["Authorization"] =
//                 "Bearer " + response.data.access;
//               originalRequest.headers["Authorization"] =
//                 "Bearer " + response.data.access;

//               return axiosInstance(originalRequest);
//             })
//             .catch((err) => {
//               //console.log(err);
//             });
//         } else {
//           //console.log("Refresh token is expired", tokenParts.exp, now);
//           window.location.href = "/collect/login";
//         }
//       } else {
//         //console.log("Refresh token not available.");
//         window.location.href = "/collect/login";
//       }
//     }

//     // specific error handling done elsewhere
//     return Promise.reject(error);
//   }
// );

// export default axiosInstance;

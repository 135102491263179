import React from "react";
// import  {Bar,defaults} from 'react-chartjs-2'
import { useState, useEffect } from "react";
import axiosInstance from "../../../../../service/dashboardAxiosApi";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "../../../styles";
import {
	BarChart,
	Bar,
	XAxis,
	// Cell,
	YAxis,
	// Tooltip,
	ResponsiveContainer,
} from "recharts";

//  defaults.global.legend.position='right'

const BarCharts = ({ data, name, filledcolor }) => {
	const classes = useStyles();
    // const dataCount = data.map(item=>item.total);
    // const dataCount2 = dataCount.reduce((a, b) => a + b);
    // console.log(dataCount);
    const [casesCount, setCasesCount] = useState([]);

    const getCase = async () => {
		await axiosInstance
			.get(`pubcases/`)
			.then((res) => {
				if (res.status === 200) {
					//setspinner(false)
					const data = res.data.approved;
					setCasesCount(data.length);
				}
			})
			.catch((err) => {
				if (err.request) {
					return null;
				} else {
					return null;
				}
			});
	};
    

    useEffect(() => {
        getCase();
    } , []);

	return (
		<Grid container direction="column">
			<Grid item xs={12} sm={12}>
				<Typography className={classes.typochart}>{name}</Typography>
			</Grid>
			<Grid item sm={12} xs={12}>
				<ResponsiveContainer width="100%" height={135}>
					{/* <ResponsiveContainer width="100%" height={135}> */}
					<BarChart
						data={data}
						margin={{ right: 0, left: -28, bottom: 2, top: 5 }}
					>
						<XAxis dataKey="name" stroke="#000000" />
						<YAxis type="number" fontWeight={5} domain={[0, casesCount]} allowDecimals={false} style={{ fontSize: 12, paddingLeft: 0 }} fontFamily="Arial" />
                        
						{/* <Tooltip
                                                    wrapperStyle={{ width: 80, backgroundColor: '#ccc' }}
                                                    formatter={function(Total) {return `${Total}`}}
                                                /> */}
						<Bar
							dataKey="total"
							// fill="#00a0fc"
							// stroke="#000000"
							strokeWidth={1}
						>
							{
								filledcolor
								// data.map((entry, index) => (
								//     <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
								// ))
							}
						</Bar>
					</BarChart>
				</ResponsiveContainer>
			</Grid>
		</Grid>
	);
};

export default BarCharts;

import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "../../dashboard/styles";
import axiosInstance from "../../../service/dashboardAxiosApi";
import { useTranslation } from "react-i18next";

// const LineChart = () => {
//   const classes = useStyles();
//   const [cases, setCases] = useState([]);
//   const { t } = useTranslation();

//   useEffect(() => {
//     const getCases = async () => {
//       try {
//         const response = await axiosInstance.get('pubcases/');
//         const data = response.data.approved.map((e) => ({
//           ...e,
//           date: new Date(e.question_4),
//         }));
//         setCases(data);
//       } catch (err) {
//         console.error('Error fetching cases:', err);
//       }
//     };
//     getCases();
//   }, []);

//   // Group cases by year and month
//   const groupedCases = cases.reduce((acc, { date }) => {
//     const yearMonth = `${date.getFullYear()}-${date.getMonth() + 1}`;
//     if (!acc[yearMonth]) {
//       acc[yearMonth] = 0;
//     }
//     acc[yearMonth]++;
//     return acc;
//   }, {});

//   // Generate labels and data for each dataset
//   const labels = [];
//   const data2021 = [];
//   const data2022 = [];

//   Object.entries(groupedCases).forEach(([yearMonth, count]) => {
//     const [year, month] = yearMonth.split('-');
//     const label = `${new Date(year, month - 1).toLocaleString('default', { month: 'short' })} ${year}`;
//     labels.push(label);

//     if (year === '2021') {
//       data2021.push(count);
//     } else if (year === '2022') {
//       data2022.push(count);
//     }
//   });

//   const data = {
//     labels,
//     datasets: [
//       {
//         data: data2021,
//         label: '2021',
//         fill: false,
//         backgroundColor: 'rgba(75,192,192,0.2)',
//         borderColor: 'rgba(75,192,192,1)',
//       },
//       {
//         data: data2022,
//         label: '2022',
//         fill: false,
//         backgroundColor: '#4F2970',
//         borderColor: '#4F2970',
//       },
//     ],
//   };

//   return (
//     <Grid container direction="column" className={classes.leftAndRightAndTopAndBottom}>
//       <Grid item sm={12} xs={12}>
//         <Typography className={classes.typochart}>
//           {t('case-statistic-box2')}
//         </Typography>
//       </Grid>

//       <Grid item sm={12} xs={12}>
//         <Line data={data} height={82} />
//       </Grid>
//     </Grid>
//   );
// };

// export default LineChart;

const LineChart = () => {
	const classes = useStyles();
	const [cases, setcases] = useState([]);
	const { t } = useTranslation();

	useEffect(() => {
		const getCase = async () => {
			await axiosInstance
				.get(`pubcases/`)
				.then((res) => res.data.approved)
				.then((data) => {
					const dt = data.map((e) => ({
						id: e.id,
						caseid: e.caseid,
						org: e.organization,
						staff: e.question_2,
						doc: e.question_3,
						// dofdoc: e.question_4.slice(6), //Uncomment this linein 2023 When the data collection reaches 3 years in a row
						dateofdoc: e.question_4.slice(2),
						code: e.question_5.toUpperCase(),
						age: e.question_6_a,
						gen: e.question_7,
					}));
					setcases(dt);
					//console.log(dt);
					//console.log(data);
				})
				.catch((err) => {
					if (err.request) {
						return null;
					} else if (err.response) {
						return null;
					}
				});
		};
		getCase();
	}, [cases.id]); //axiosInstance
	//const a = cases.map(e=>e.dofdoc.slice(6));
	//console.log(cases);
	//console.log(cases.map(e=>e.dofdoc));
	//console.log(new Set(cases.map(e=>e.dofdoc)));

	//TODO: In 2023, the table will have a yearly datasets
	//Uncomment when the data collection reaches 3 years in a row
	// var c = cases.map((e) => e.dofdoc);
	// var map = c.reduce(function (prev, cur) {
	// 	prev[cur] = (prev[cur] || 0) + 1;
	// 	return prev;
	// }, {});

	//TODO: In 2023, the table will have a yearly datasets
	//Uncomment when the data collection reaches 3 years in a row
	// const data = {
	// 	labels: [...new Set(cases.map((e) => e.dofdoc).sort((a, b) => a - b))],
	// 	datasets: [
	// 		{
	// 			data: Object.values(map),
	// 			label: t("case-statistic-box2"),
	// 			fill: false,
	// 			backgroundColor: "rgba(75,192,192,0.2)",
	// 			borderColor: "rgba(75,192,192,1)",
	// 		},
	// 	],
	// };

	// CASE DOCUMENT LINE CHART DATA 2022

	// Only fetch cases of 2021
	var caseQ42021 = cases
		.map((e) => e.dateofdoc)
		.filter((e) => e.includes("/2021"));

	// Slice the month only and sort the data
	var monthvar2021 = caseQ42021
		.map((e) => e.slice(1, -5))
		.sort((a, b) => {
			if (a < b) {
				return -1;
			}
			if (a > b) {
				return 1;
			}
			return 0;
		});

	// Count the occurence of each month
	var mapQ4M2021 = monthvar2021.reduce(
		(acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
		new Map()
	);

	// Create an array of the number of occurences
	var docData21 = Array.from(mapQ4M2021.values());
	// var mapQ4M2021 = monthvar2021.reduce(function (prev, cur) {
	// 	prev[cur] = (prev[cur] || 0) + 1;
	// 	return prev;
	// }, {});

	// CASE DOCUMENT LINE CHART DATA 2022

	// Only fetch cases of 2022
	var caseQ42022 = cases
		.map((e) => e.dateofdoc)
		.filter((e) => e.includes("/2022"));

	// Slice the month only and sort the data
	var monthvar2022 = caseQ42022
		.map((e) => e.slice(1, -5))
		.sort((a, b) => {
			if (a < b) {
				return -1;
			}
			if (a > b) {
				return 1;
			}
			return 0;
		});
	// var mapQ4M2022 = monthvar2022.reduce(function (prev, cur) {
	// 	prev[cur] = (prev[cur] || 0) + 1;
	// 	return prev;
	// }, {});

	// Count the occurence of each month
	var mapQ4M2022 = monthvar2022.reduce(
		(acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
		new Map()
	);

	// Create an array of the number of occurences
	var docData22 = Array.from(mapQ4M2022.values());

	// CASE DOCUMENT LINE CHART DATA 2023

	// Only fetch cases of 2023
	var caseQ42023 = cases
		.map((e) => e.dateofdoc)
		.filter((e) => e.includes("/2023"));

	// Slice the month only and sort the data
	var monthvar2023 = caseQ42023
		.map((e) => e.slice(1, -5))
		.sort((a, b) => {
			if (a < b) {
				return -1;
			}
			if (a > b) {
				return 1;
			}
			return 0;
		});
	// var mapQ4M2022 = monthvar2022.reduce(function (prev, cur) {
	// 	prev[cur] = (prev[cur] || 0) + 1;
	// 	return prev;
	// }, {});

	// Count the occurence of each month
	var mapQ4M2023 = monthvar2023.reduce(
		(acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
		new Map()
	);

	// Create an array of the number of occurences
	var docData23 = Array.from(mapQ4M2023.values());

	// CASE DOCUMENT LINE CHART DATA 2024

	// Only fetch cases of 2024
	var caseQ42024 = cases
		.map((e) => e.dateofdoc)
		.filter((e) => e.includes("/2024"));

	// Slice the month only and sort the data
	var monthvar2024 = caseQ42024
		.map((e) => e.slice(1, -5))
		.sort((a, b) => {
			if (a < b) {
				return -1;
			}
			if (a > b) {
				return 1;
			}
			return 0;
		});
	// var mapQ4M2022 = monthvar2022.reduce(function (prev, cur) {
	// 	prev[cur] = (prev[cur] || 0) + 1;
	// 	return prev;
	// }, {});

	// Count the occurence of each month
	var mapQ4M2024 = monthvar2024.reduce(
		(acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
		new Map()
	);

	// Create an array of the number of occurences
	var docData24 = Array.from(mapQ4M2024.values());

	const data = {
		labels: [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		],
		datasets: [
			{
				data: [0, 0, 0, ...Object.values(docData21)],
				label: "2021",
				fill: false,
				backgroundColor: "rgba(75,192,192,0.2)",
				borderColor: "rgba(75,192,192,1)",
			},
			{
				data: Object.values(docData22),
				label: "2022",
				fill: false,
				backgroundColor: "#4F2970",
				borderColor: "#4F2970",
			},
			{
				data: Object.values(docData23),
				label: "2023",
				fill: false,
				backgroundColor: "#FFDA83",
				borderColor: "#FFDA83",
			},
			{
				data: Object.values(docData24),
				label: "2024",
				fill: false,
				backgroundColor: "#A3A1FB",
				borderColor: "#A3A1FB",
			},
		],

		// options: [{
		// 	legend: { display: false },
		// 	scales: {
		// 		yAxes: [
		// 			{
		// 				ticks: {
		// 					precision: 0,
		// 					beginAtZero: true,
		// 					allowDecimals: false,
		// 					stacked: true,
		// 				},
		// 			},
		// 		],
		// 		xAxes: [
		// 			{
		// 				ticks: {
		// 					precision: 0,
		// 					beginAtZero: true,
		// 					allowDecimals: false,
		// 					stacked: true,
		// 				},
		// 			},
		// 		],
		// 	},
		// }]
	};

	return (
		<Grid
			container
			direction="column"
			className={classes.leftAndRightAndTopAndBottom}
		>
			<Grid item sm={12} xs={12}>
				<Typography className={classes.typochart}>
					{t("case-statistic-box2")}
				</Typography>
			</Grid>

			<Grid item sm={12} xs={12}>
				<Line
					data={data}
					// Height of graph
					height={82}
				/>
				{/* Uncomment this line in 2023 When the data collection reaches 3 years in a row */}
				{/* <Line
					data={data}
					height={85}
				/> */}
			</Grid>
		</Grid>
	);
};

export default LineChart;

import React from 'react'
import {Switch} from 'react-router-dom'
import Incedent_info from '../../component/datacollector/incedent_info'
import Perpretor from '../../component/datacollector/perpretor'
import Identify_organisation from '../../component/datacollector/identify_organisation'
import Profile_de_la_survivantame from '../../component/datacollector/profile_de_la_survivant'
import Need_care from '../../component/datacollector/need_care'
// import Default from '../../page/default'
import useStyles from './style'
import ProtectedRoute from './protectedRoute'




const Case = () => {
    const classes = useStyles()
    return (
       <div className={classes.root}>
            <React.Fragment>
                {/* <Navbar/> */}
                <Switch>
                    <ProtectedRoute exact path={"/"+process.env.REACT_APP_COLLECT_LINK+'/collect/form/besion'} component={Need_care}/>
                    <ProtectedRoute exact path={"/"+process.env.REACT_APP_COLLECT_LINK+'/collect/form/perpretor'} component={Perpretor}/>
                    <ProtectedRoute exact path={"/"+process.env.REACT_APP_COLLECT_LINK+'/collect/form/info'} component={Incedent_info}/>
                    <ProtectedRoute exact path={"/"+process.env.REACT_APP_COLLECT_LINK+'/collect/form/profile'} component={Profile_de_la_survivantame}/>
                    <ProtectedRoute exact path='' component={Identify_organisation}/>  
                    {/* <Route  component={Default}/>      */}
                </Switch>                
        </React.Fragment>
       </div>
    )
}






export default Case;
import React, { useState, useEffect } from "react";
import {
	Grid,
	Card,
	Typography,
	CardContent,
	TextField,
	InputAdornment, 
	IconButton,
} from "@material-ui/core";
import useStyles from "./style";
import { Redirect } from "react-router-dom";
import { BtnLogin } from "../../widget/util";
import oxide from "../../../assets/oxide.svg";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../service/dashboardAxiosApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const Login = () => {
	const classes = useStyles();
	const history = useHistory();
	const [ison, setison] = useState(false);
	// const [tkn, settkn] = useState('')
	// const [token, settoken] = useState('')
	// const [id, setid] = useState('')
	// const [username, setusername] = useState()
	const [msg, setmsg] = useState();
	const [showPassword, setShowPassword] = useState(false);

	const { register, handleSubmit } = useForm();
	const onSubmit = (data, e) => {
		e.preventDefault();
		//  console.log(data);
		handLogin(data.user, data.pass);
	}; 

	const handLogin = async (username, password) => {
		//axios
		setison(true);
		await axiosInstance
			.post("login/", { username: username, password: password })
			.then((res) => {
				const usrdata = res.data.authenticatedUser;
				// console.log(usrdata);
				const data = (axiosInstance.defaults.headers["Authorization"] =
					"Bearer " + res.data.access);
				if (usrdata.role === "4" && usrdata.activated === true) {
					setison(false);
					setmsg(
						"You are not authorized to access this dashboard, please contact the administrator!"
					);
					return null;
				} else if (usrdata.role === "4" && usrdata.activated === false) {
					setison(false);
					setmsg(
						"You are not authorized to access this dashboard, please contact the administrator!"
					);
					return null;
				} else if (usrdata.role < 4 && usrdata.activated === false) {
					setison(false);

					setmsg("This user is disabled, please contact the administrator!");
					return null;
				} else if (usrdata.role < 4 && usrdata.activated === true) {
					sessionStorage.setItem("tokens", res.data.access);
					sessionStorage.setItem("refreshs", res.data.refresh);

					sessionStorage.setItem("users", usrdata.username);
					sessionStorage.setItem("role", usrdata.role);
					sessionStorage.setItem("orgs", usrdata.organization);
					sessionStorage.setItem("ids", usrdata.id);
					sessionStorage.setItem("value", 0);
					history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard`);
					return data;
				}
			})

			.catch((err) => {
				if (err.response) {
					setison(false);
					const msg = err.response.data.non_field_errors;
					setmsg(msg);
					//console.log(err.response.data.non_field_errors);
				} else if (err.request) {
					setison(false);
					setmsg(
						"Please, your request could'nt reach the server ,check your internet connexion"
					);
					//console.log("something went wrong");
				}
			});
	};

	useEffect(() => {}, []);

	if (sessionStorage.getItem("tokens") != null) {
		return (
			<Redirect to={"/" + process.env.REACT_APP_DASHBOAD_LINK + "/dashboard"} />
		);
	}

	return (
		<div style={{ display: "flex" }}>
			<Grid container justifyContent="center">
				<Grid item sm={3} md={4}></Grid>
				<Grid item sm={6} md={4}>
					<Grid container justifyContent="center">
						<Grid item xs={12} sm={12}>
							<Typography
								variant="h4"
								align="center"
								className={classes.typoBig}
							>
								<strong>DASHBOARD</strong>
							</Typography>
						</Grid>

						<Grid item xs={12} sm={12}>
							<Card>
								<CardContent>
									<form onSubmit={handleSubmit(onSubmit)}>
										<Grid
											container
											spacing={3}
											className={classes.leftandrightInput}
										>
											<Grid item xs={12} sm={12}>
												<Typography
													align="center"
													variant="h5"
													className={classes.titleLogin}
												>
													<b>SIGN IN</b>
												</Typography>
												<Typography
													align="center"
													className={classes.subtitleLogin}
												>
													Please enter your log-in details as provided by the
													Dashboard administration{" "}
												</Typography>
											</Grid>

											<Grid item xs={12} sm={12}>
												<TextField
													required
													fullWidth
													type="text"
													variant="outlined"
													size="medium"
													label="Username"
													name="user"
													inputRef={register}
												/>
											</Grid>

											<Grid item xs={12} sm={12}>
												<TextField
													type={showPassword ? "text" : "password"}
													required
													fullWidth
													variant="outlined"
													size="medium"
													label="Password"
													name="pass"
													inputRef={register}
													InputProps={{
														endAdornment: (
														  <InputAdornment position="end">
															<IconButton
															  onClick={() => setShowPassword(!showPassword)}
															>
															  {showPassword ? <Visibility /> : <VisibilityOff />}
															</IconButton>
														  </InputAdornment>
														)
													  }}
												/>
											</Grid>
											<Grid item xs={12} sm={12}>
												<Grid container justifyContent="flex-end">
													<Grid item>
														{ison === true ? (
															<CircularProgress color="secondary" size={10} />
														) : (
															<BtnLogin content="SIGN IN" type="submit" />
														)}
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</form>
									<Grid container justifyContent="center">
										<Grid item>
											<Typography style={{ color: "red" }}> {msg} </Typography>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
						<Grid container justifyContent="center">
							<Grid item>
								<Typography variant="subtitle2" className={classes.foot}>
									POWERED BY:
								</Typography>
							</Grid>
							{/* <Grid item>
                            <Typography>POWERED BY:</Typography>
                        </Grid> */}
						</Grid>
						<Grid container justifyContent="center">
							<Grid item>
								<img src={oxide} alt="Oxide Logo" width="50px" />
							</Grid>
							{/* <Grid item>
                            <Typography>POWERED BY:</Typography>
                        </Grid> */}
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={3} md={4}></Grid>
			</Grid>
		</div>
	);
};

export default Login;

import React, { useState, useEffect } from "react";
import { Grid, IconButton } from "@material-ui/core";
// import useStyles from './style.js'
import PostMedia from "../widgets/postMedia";
import axiosInstance from "../../../service/axiosPub";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import Pagination from "@material-ui/lab/Pagination";
import PostMediaSkeleton from "../../../component/dashboard/campain_observatory/widget/postmediaSkeleton";
import CloudOffTwoToneIcon from "@material-ui/icons/CloudOffTwoTone";
import RestoreIcon from "@material-ui/icons/Restore";
import StorageIcon from "@material-ui/icons/Storage";
import { Typography } from "@material-ui/core";

const AllPost = (props) => {
	// const classes = useStyles();
	const [postdata, setpostdata] = useState([]);
	const { t } = useTranslation();
	const [spinner, setspinner] = useState(true);
	const [errmsg, seterrmsg] = useState("");
	const [statecode, setstatecode] = useState(false);

	const getPost = async () => {
		setspinner(true);
		axiosInstance
			.get(`pubposts/`)
			.then((res) => {
				if (res.status === 200) {
					const data = res.data.posts;
					setspinner(false);
					setpostdata(data);
					setstatecode(true);
					seterrmsg("Posts successfull fetched");
				}
			})
			.catch((err) => {
				setspinner(false);
				if (err.response) {
					seterrmsg("Please check your internet connexion");
				} else if (err.request) {
					seterrmsg("Please check your internet connexion");
				}
			});
	};

	useEffect(() => {
		getPost();
	}, []); //axiosInstance

	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(6);
	// Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const all = postdata.filter((e) => e.category !== "");

	const alltotal = all.slice(indexOfFirstPost, indexOfLastPost);

	const handleAll = (value) => {
		setCurrentPage(value);
	};
	const handletotal = (num) => {
		const value = Math.ceil(num.length / postsPerPage);
		return value;
	};
	if (statecode === false) {
		return (
			<div>
				<Grid
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
				>
					<Grid item xs={12} sm={12}>
						{spinner === true ? (
							<Grid container direction="row" spacing={2}>
								<Grid item xs={12} sm={4}>
									<PostMediaSkeleton />
								</Grid>
								<Grid item xs={12} sm={4}>
									<PostMediaSkeleton />
								</Grid>
								<Grid item xs={12} sm={4}>
									<PostMediaSkeleton />
								</Grid>
								<Grid item xs={12} sm={4}>
									<PostMediaSkeleton />
								</Grid>
								<Grid item xs={12} sm={4}>
									<PostMediaSkeleton />
								</Grid>
								<Grid item xs={12} sm={4}>
									<PostMediaSkeleton />
								</Grid>
								<Grid item xs={12} sm={4}>
									<PostMediaSkeleton />
								</Grid>
								<Grid item xs={12} sm={4}>
									<PostMediaSkeleton />
								</Grid>
								<Grid item xs={12} sm={4}>
									<PostMediaSkeleton />
								</Grid>
							</Grid>
						) : (
							<Grid container direction="row" justifyContent="center">
								<Grid item xs={12} sm={4}>
									<CloudOffTwoToneIcon size={40} />
								</Grid>
								<Grid item xs={12} sm={8}>
									<Typography>{errmsg}</Typography>
								</Grid>
								<Grid item xs={12} sm={8}>
									<IconButton
										color="primary"
										aria-label="Restart"
										component="span"
										onClick={getPost}
									>
										<RestoreIcon />
									</IconButton>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			</div>
		);
	} else if (statecode === true && postdata === "") {
		return (
			<Grid container direction="row" justifyContent="center">
				<Grid xs={12} sm={4}></Grid>
				<Grid xs={12} sm={4}>
					<Grid container direction="row">
						<Grid item xs={12} sm={4}>
							<StorageIcon size={60} />
						</Grid>
						<Grid item xs={12} sm={8}>
							<Typography>No posts found</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid xs={12} sm={4}></Grid>
			</Grid>
		);
	} else if (statecode === true && postdata !== []) {
		return (
			<div>
				<Grid
					container
					direction="row"
					spacing={3}
					style={{ marginBottom: 50 }}
				>
					{alltotal.map((post) => (
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<PostMedia
								category={
									post.category === "Campaign news"
										? t("campaigntab")
										: t("reporttab")
								}
								title={post.title}
								image={post.image}
								color={
									post.category === "Campaign news" ? "#208BD1" : "#D12028"
								}
								description={ReactHtmlParser(
									post.content.length > 180
										? post.content.substr(0, 180) + " ...."
										: post.content
								)}
								posteddate={post.created.substr(0, 10)}
								readmoreClicked={() => {
									props.handleReadmore();
									localStorage.setItem("pstId", post.id);
								}}
							/>
						</Grid>
					))}
				</Grid>
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{ marginBottom: 50 }}
				>
					<Grid>
						<Pagination
							count={handletotal(all)}
							page={currentPage}
							onChange={handleAll}
							defaultPage={currentPage}
							variant="outlined"
							color="secondary"
						/>
					</Grid>
				</Grid>
			</div>
		);
	}
};

export default AllPost;

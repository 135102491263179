// YES AND NO QUESTIONS
export const optionsOuiAndNon = [
	{
		answer: "Oui",
		keyData: "A",
	},
	{
		answer: "Non",
		keyData: "B",
	},
];

// QUESTION 6 B
export const optionsQ6B = [
	{
		group: "0-5 ans",
		keyData: "A",
	},
	{
		group: "6-17 ans",
		keyData: "B",
	},
	{
		group: "18-29 ans",
		keyData: "C",
	},
	{
		group: "30-49 ans",
		keyData: "D",
	},
	{
		group: "50-64 ans",
		keyData: "E",
	},
	{
		group: "65 ans et plus",
		keyData: "F",
	},
];

// QUESTION 7
export const optionsQ7 = [
	{
		genre: "Féminin",
		keyData: "A",
	},
	{
		genre: "Masculin",
		keyData: "B",
	},
	{
		genre: "Autre",
		keyData: "C",
	},
];

// QUESTION 8A
export const optionsQ8A = [
	{
		etatCivil: "Célibataire",
		keyData: "A",
	},
	{
		etatCivil: "Union de fait (vivre ensemble sans être mariés)",
		keyData: "B",
	},
	{
		etatCivil: "Marié(e)",
		keyData: "C",
	},
	{
		etatCivil: "Séparé(e)",
		keyData: "D",
	},
	{
		etatCivil: "Divorcé(e)",
		keyData: "E",
	},
	{
		etatCivil: "Veuve / Veuf",
		keyData: "F",
	},
];

// QUESTION 10A
export const optionsQ10AViolenceSexuelle = [
	{
		incident: "Viol",
		keyData: "A",
	},
	{
		incident: "Viol conjugal entre couples mariés et non mariés",
		keyData: "B",
	},
	{
		incident: "Agression sexuelle sur mineur",
		keyData: "C",
	},
	{
		incident: "Coercition sexuelle",
		keyData: "D",
	},
	{
		incident: "Harcèlement sexuel",
		keyData: "E",
	},
	{
		incident: "Attouchements inappropriés",
		keyData: "F",
	},
	{
		incident:
			"Forcer la victime à avoir des relations sexuelles sans préservatif",
		keyData: "G",
	},
	{
		incident:
			"Empêcher la victime de faire ses propres choix quant à la décision d'avoir ou non un bébé",
		keyData: "H",
	},
	{
		incident: "Autres",
		keyData: "I",
	},
];

// QUESTION 10B
export const optionsQ10BViolencePhysique = [
	{
		incident: "Meurtre / féminicide",
		keyData: "A",
	},
	{
		incident: "Coups de poing et/ou coups de pieds",
		keyData: "B",
	},
	{
		incident: "Coups portés avec des objets",
		keyData: "C",
	},
	{
		incident: "Attaque à l’acide",
		keyData: "D",
	},
	{
		incident: "Attaque au couteau ou à la machette",
		keyData: "E",
	},
	{
		incident: "Attaque à l’eau chaude, à l’huile chaude ou autre objet chaud",
		keyData: "F",
	},
	{
		incident: "Privation de la liberté physique de la victime",
		keyData: "G",
	},
	{
		incident: "Privation de nourriture de la victime",
		keyData: "H",
	},
	{
		incident: "Négligence physique d’enfant (si la victime est un enfant)",
		keyData: "I",
	},
	{
		incident:
			"Négligence physique de personnes âgées (si la victime est une personne âgée)",
		keyData: "J",
	},
	{
		incident: "Destruction d’objets dans la maison de la victime",
		keyData: "K",
	},
	{
		incident:
			"Rites de veuvage affectant la santé physique et mentale de la victime",
		keyData: "L",
	},
	{
		incident: "Autres",
		keyData: "M",
	},
];

// QUESTION 10C
export const optionsQ10CViolencePsychologique = [
	{
		incident: "Menaces",
		keyData: "A",
	},
	{
		incident: "Injures",
		keyData: "B",
	},
	{
		incident: "Négligence émotionnelle",
		keyData: "C",
	},
	{
		incident: "Humiliation de la victime face aux autres",
		keyData: "D",
	},
	{
		incident: "Attaques visant à nuire à la réputation de la victime",
		keyData: "E",
	},
	{
		incident: "Isolement de la victime de sa famille d’origine ou ses amis",
		keyData: "F",
	},
	{
		incident: "Contrôle des mouvements de la victime",
		keyData: "G",
	},
	{
		incident: "Contrôle de la communication de la victime",
		keyData: "H",
	},
	{
		incident: "Refus de divorce malgré le souhait de la victime de se divorcer",
		keyData: "I",
	},
	{
		incident: "Abus spirituel / abus religieux de la victime",
		keyData: "J",
	},
	{
		incident: "Autres",
		keyData: "K",
	},
];

// QUESTION 10D
export const optionsQ10DViolenceEconomique = [
	{
		incident: "L’auteur abandonne ou chasse la victime",
		keyData: "A",
	},
	{
		incident: "Confiscation des revenus de la victime",
		keyData: "B",
	},
	{
		incident:
			"Interdiction de participation à la gestion des revenus du ménage",
		keyData: "C",
	},
	{
		incident: "Restriction de l’accès à l'éducation",
		keyData: "D",
	},
	{
		incident: "Restriction de l’accès à l'emploi",
		keyData: "E",
	},
	{
		incident: "Restriction de l’accès aux ressources économiques",
		keyData: "F",
	},
	{
		incident:
			"Privation d’une prise en charge médicale, de médicaments ou d'appareils d’assistance",
		keyData: "G",
	},
	{
		incident: "Lévirat après le mort du conjoint",
		keyData: "H",
	},
	{
		incident:
			"Perte des propriétés et des biens / non accès à l’héritage après le mort du conjoint ou d’un parent",
		keyData: "I",
	},
	{
		incident: "Perte des enfants après la mort du conjoint",
		keyData: "J",
	},
	{
		incident: "Autres",
		keyData: "K",
	},
];

// QUESTION 11A_OUI_MONTH
export const optionsQ11OuiMonth = [
	{
		month: "Janvier",
		keyData: "1",
	},
	{
		month: "Février",
		keyData: "2",
	},
	{
		month: "Mars",
		keyData: "3",
	},
	{
		month: "Avril",
		keyData: "4",
	},
	{
		month: "Mai",
		keyData: "5",
	},
	{
		month: "Juin",
		keyData: "6",
	},
	{
		month: "Juillet",
		keyData: "7",
	},
	{
		month: "Août",
		keyData: "8",
	},
	{
		month: "Septembre",
		keyData: "9",
	},
	{
		month: "Octobre",
		keyData: "10",
	},
	{
		month: "Novembre",
		keyData: "11",
	},
	{
		month: "Decembre",
		keyData: "12",
	},
];

// QUESTION 12 B
export const optionsQuestion12B = [
	{
		milieu: "Milieu urbain",
		keyData: "A",
	},
	{
		milieu: "Milieu semi-urbain",
		keyData: "B",
	},
	{
		milieu: "Milieu rural",
		keyData: "C",
	},
];

// QUESTION 13B_A
export const optionsQ13B_APrejudicePhysique = [
	{
		prejudice: "Décès",
		keyData: "A",
	},
	{
		prejudice: "Maladies (par exemple hypertension)",
		keyData: "B",
	},
	{
		prejudice: "Blessures",
		keyData: "C",
	},
	{
		prejudice: "Douleurs",
		keyData: "D",
	},
	{
		prejudice: "Troubles gynécologiques et reproductifs",
		keyData: "E",
	},
	{
		prejudice: "Invalidité / Handicap",
		keyData: "F",
	},
	{
		prejudice: "Grossesse non voulue",
		keyData: "G",
	},
	{
		prejudice: "Perte de l'enfant à naître",
		keyData: "H",
	},
	{
		prejudice: "Abus d'alcool",
		keyData: "I",
	},
	{
		prejudice: "Abus de drogues",
		keyData: "J",
	},
	{
		prejudice: "Abus de médicaments",
		keyData: "K",
	},
	{
		prejudice: "Autres",
		keyData: "L",
	},
];

// QUESTION 13B_B
export const optionsQ13B_BPrejudiceEmotionnel = [
	{
		prejudice: "Risque de suicide",
		keyData: "A",
	},
	{
		prejudice: "Peur et anxiété",
		keyData: "B",
	},
	{
		prejudice: "Dépression",
		keyData: "C",
	},
	{
		prejudice: "Détresse émotionnelle",
		keyData: "D",
	},
	{
		prejudice: "Sentiment de honte",
		keyData: "E",
	},
	{
		prejudice: "Sentiment de colère",
		keyData: "F",
	},
	{
		prejudice: "Troubles de sommeil",
		keyData: "G",
	},
	{
		prejudice: "Troubles alimentaires",
		keyData: "H",
	},
	{
		prejudice: "Perte d’espoir",
		keyData: "I",
	},
	{
		prejudice: "Manque de confiance en soi",
		keyData: "J",
	},
	{
		prejudice: "Autres",
		keyData: "K",
	},
];

// QUESTION 13B_C
export const optionsQ13B_CPrejudiceEconomique = [
	{
		prejudice: "Perte ou manque des moyens de subsistance",
		keyData: "A",
	},
	{
		prejudice: "Perte ou manque de revenus",
		keyData: "B",
	},
	{
		prejudice: "Sexe de survie",
		keyData: "C",
	},
	{
		prejudice: "Perte de maison",
		keyData: "D",
	},
	{
		prejudice: "Déménagements fréquents",
		keyData: "E",
	},
	{
		prejudice: "Situation de sans-abri",
		keyData: "F",
	},
	{
		prejudice: "Pas ou peu d'éducation ou de formation",
		keyData: "G",
	},
	{
		prejudice: "Autres",
		keyData: "H",
	},
];

// QUESTION 13B_D
export const optionsQ13B_DImpactSurLesRelations = [
	{
		impact: "Rupture de la famille (famille créée par la victime et l’auteur)",
		keyData: "A",
	},
	{
		impact: "Conflits domestiques réguliers",
		keyData: "B",
	},
	{
		impact: "Abandon de la victime par sa famille d’origine (parents)",
		keyData: "C",
	},
	{
		impact: "Stigmatisation de la victime par la communauté",
		keyData: "D",
	},
	{
		impact: "Perte de la garde ou de contact avec propres enfants",
		keyData: "E",
	},
	{
		impact: "Isolement social de la victime",
		keyData: "F",
	},
	{
		impact: "Autres",
		keyData: "G",
	},
];

// QUESTION 14A_OUI
export const optionsQ14A_OUI = [
	{
		agresseur: "Mère",
		keyData: "A",
	},
	{
		agresseur: "Père",
		keyData: "B",
	},
	{
		agresseur: "Tuteur / gardien (homme)",
		keyData: "C",
	},
	{
		agresseur: "Tuteur / gardienne (femme)",
		keyData: "D",
	},
	{
		agresseur: "Propres frères",
		keyData: "E",
	},
	{
		agresseur: "Propres sœurs",
		keyData: "F",
	},
	{
		agresseur: "Propres enfants adultes",
		keyData: "G",
	},
	{
		agresseur: "Grand-mere",
		keyData: "H",
	},
	{
		agresseur: "Grand-père",
		keyData: "I",
	},
	{
		agresseur: "Belle-mère",
		keyData: "J",
	},
	{
		agresseur: "Beaux-père",
		keyData: "K",
	},
	{
		agresseur: "Beaux-frères",
		keyData: "L",
	},
	{
		agresseur: "Belles-sœurs",
		keyData: "M",
	},
	{
		agresseur: "Oncles",
		keyData: "N",
	},
	{
		agresseur: "Tantes",
		keyData: "O",
	},
	{
		agresseur: "Autre(s) membre(s) de la famille de la victime",
		keyData: "P",
	},
];

// QUESTION 14B_OUI
export const optionsQ14B_OUI = [
	{
		agresseur: "Mari",
		keyData: "A",
	},
	{
		agresseur: "Ex-mari",
		keyData: "B",
	},
	{
		agresseur: "Epouse",
		keyData: "C",
	},
	{
		agresseur: "Ex-épouse",
		keyData: "D",
	},
	{
		agresseur: "Partenaire intime actuel qui est homme",
		keyData: "E",
	},
	{
		agresseur: "Partenaire intime actuel qui est femme",
		keyData: "F",
	},
	{
		agresseur: "Ex-partenaire intime qui est homme",
		keyData: "G",
	},
	{
		agresseur: "Ex-partenaire intime qui est femme",
		keyData: "H",
	},
];

// QUESTION 14C_OUI
export const optionsQ14C_OUI = [
	{
		agresseur: "Patron (homme) de personnel domestique",
		keyData: "A",
	},
	{
		agresseur: "Patronne (femme) de personnel domestique",
		keyData: "B",
	},
	{
		agresseur: "Personnel domestique (homme)",
		keyData: "C",
	},
	{
		agresseur: "Personnel domestique (femme)",
		keyData: "D",
	},
	{
		agresseur: "Bailleur / propriétaire de la maison (homme)",
		keyData: "E",
	},
	{
		agresseur: "Bailleresse / propriétaire de la maison (femme)",
		keyData: "F",
	},
	{
		agresseur: "Autre(s) membre(s) du cercle restreint de la victime",
		keyData: "G",
	},
];

// QUESTION 15_OUI
export const optionsQ15_OUI = [
	{
		endroit: "Même ménage",
		keyData: "A",
	},
	{
		endroit: "Même parcelle",
		keyData: "B",
	},
	{
		endroit: "Maison d’enfants",
		keyData: "C",
	},
	{
		endroit: "Camp de réfugiés",
		keyData: "D",
	},
];

// QUESTION 18A
export const optionsQ18A = [
	{
		priseEncharge: "Soins médicaux",
		keyData: "A",
	},
	{
		priseEncharge: "Assistance psychosociale",
		keyData: "B",
	},
	{
		priseEncharge: "Assistance en médiation familial",
		keyData: "C",
	},
	{
		priseEncharge: "Assistance juridique",
		keyData: "D",
	},
	{
		priseEncharge:
			"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale",
		keyData: "E",
	},
	{
		priseEncharge:
			"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile",
		keyData: "F",
	},
	{
		priseEncharge: "Assistance socio-économique",
		keyData: "G",
	},
	{
		priseEncharge: "Développement d’un plan de sécurité pour la victime de VD",
		keyData: "H",
	},
];

// QUESTION 19OUI, 20
export const optionsPriseEnChargeQ19Q20 = [
	{
		priseEncharge: "Soins médicaux",
		keyData: "A",
	},
	{
		priseEncharge:
			"Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)",
		keyData: "B",
	},
	{
		priseEncharge: "Assistance psychosociale",
		keyData: "C",
	},
	{
		priseEncharge: "Assistance en médiation familial",
		keyData: "D",
	},
	{
		priseEncharge: "Assistance juridique",
		keyData: "E",
	},
	{
		priseEncharge:
			"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale",
		keyData: "F",
	},
	{
		priseEncharge:
			"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile",
		keyData: "G",
	},
	{
		priseEncharge: "Assistance socio-économique",
		keyData: "H",
	},
	{
		priseEncharge: "Développement d’un plan de sécurité pour la victime de VD",
		keyData: "I",
	},
];

// QUESTION 21
export const optionsQ21 = [
	{
		priseEncharge: "Soins médicaux",
		keyData: "A",
	},
	{
		priseEncharge:
			"Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)",
		keyData: "B",
	},
	{
		priseEncharge: "Assistance psychosociale",
		keyData: "C",
	},
	{
		priseEncharge: "Assistance en médiation familial",
		keyData: "D",
	},
	{
		priseEncharge: "Assistance juridique",
		keyData: "E",
	},
	{
		priseEncharge:
			"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale",
		keyData: "F",
	},
	{
		priseEncharge:
			"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile",
		keyData: "G",
	},
	{
		priseEncharge: "Assistance socio-économique",
		keyData: "H",
	},
	{
		priseEncharge: "Développement d’un plan de sécurité pour la victime de VD",
		keyData: "I",
	},
	{
		priseEncharge:
			"Cette situation ne s’applique pas au cas documenté comme notre organisation peut fournir tous les services demandés",
		keyData: "J",
	},
];

// QUESTION 22
export const optionsQ22 = [
	{
		priseEncharge: "Soins médicaux",
		keyData: "A",
	},
	{
		priseEncharge:
			"Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)",
		keyData: "B",
	},
	{
		priseEncharge: "Assistance psychosociale",
		keyData: "C",
	},
	{
		priseEncharge: "Assistance en médiation familial",
		keyData: "D",
	},
	{
		priseEncharge: "Assistance juridique",
		keyData: "E",
	},
	{
		priseEncharge:
			"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale",
		keyData: "F",
	},
	{
		priseEncharge:
			"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile",
		keyData: "G",
	},
	{
		priseEncharge: "Assistance socio-économique",
		keyData: "H",
	},
	{
		priseEncharge: "Développement d’un plan de sécurité pour la victime de VD",
		keyData: "I",
	},
	{
		priseEncharge:
			"Cette situation ne s’applique pas au cas documenté comme tous les services demandés peuvent être fournis par notre organisation et/ou nos partenaires de référence.",
		keyData: "J",
	},
];

// QUESTION 24
export const optionsQ24 = [
	{
		victimAnswer: "Non, la victime cherche à rester chez elle",
		keyData: "A",
	},
	{
		victimAnswer:
			"Oui, la victime cherche à quitter son foyer de façon temporaire",
		keyData: "B",
	},
	{
		victimAnswer:
			"Oui, la victime cherche à quitter son foyer de façon permanente",
		keyData: "C",
	},
];

// QUESTION 27
export const optionsQ27 = [
	{
		privacy:
			"Avec localisation (province et territoire ou ville provinciale) et âge",
		keyData: "A",
	},
	{
		privacy: "Complétement anonyme",
		keyData: "B",
	},
];

import React from "react";
import { Grid } from "@material-ui/core";
import useStyles from "../../component/website/campain/style";
import { useTranslation } from "react-i18next";
import Page_not_found from "../../../assets/Page_not_found.svg";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Link } from "react-router-dom";
import PointdownEmoji from "../../../assets/PointdownEmoji.svg";

const Default = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		// <div>
		<Grid container>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Grid container className={classes.section_article}></Grid>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Grid container direction="column" spacing={0}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container justifyContent="center" alignItems="center">
							<Grid item className={classes.marginTopBottom}>
								<img src={Page_not_found} alt="404 figure" className={classes.imgs} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid
							container
							justifyContent="center"
							alignItems="center"
							className={classes.marginTopBottom404}
						>
							<Grid item>
								<Typography className={classes.label}>
									{t("404-page-title")}{" "}
								</Typography>
								<center>
									<img src={PointdownEmoji} alt="Emoji" width="20px" />
								</center>
							</Grid>
						</Grid>
						<Grid
							container
							justifyContent="center"
							alignItems="center"
							className={classes.marginTopBottom404}
						>
							<Grid item>
								<Typography className={classes.label1}>
									{props.location.pathname}
								</Typography>
							</Grid>
						</Grid>
						<Grid
							container
							justifyContent="center"
							alignItems="center"
							className={classes.marginTopBottom404}
						>
							<Grid item>
								<Link to="/">
									<Button
										className={classes.label2}
										variant="text"
										startIcon={<KeyboardBackspaceIcon />}
									>
										{t("404-page-return")}
									</Button>
								</Link>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
		//      <Grid container className={classes.section_article}> </Grid>
		//     {/* <div>
		//        <div>
		//             <h2>404</h2>
		//             <h2>Error</h2>
		//             <h3>Page not found</h3>
		//             <h4>the resquest URL <span>{props.location.pathname}</span> </h4>
		//        </div>
		//    </div>    */}
		// </div>
	);
};
export default Default;

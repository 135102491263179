import React, { useState, useEffect } from "react";
import useStyles from "../styles";
import BackPaper from "../../../widget/backpaper";
import {
	Grid,
	Typography,
	Card,
	CardContent,
	Select,
	MenuItem,
	Divider,
} from "@material-ui/core";
import { TextForm, BtnContained, BtnOutline } from "../../../widget/util";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import axiosInstance from "../../../service/dashboardAxiosApi";
import CircularProgress from "@material-ui/core/CircularProgress";
// import {CKEditor}  from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
// import { config } from './editorConfig'
import ReactQuill from "react-quill";
// import 'react-quill/dist/quill.snow.css';
// import 'react-quill/dist/quill.bubble.css';

const Createpost = () => {
	const classes = useStyles();
	// const location = useLocation();
	const history = useHistory();
	const [isupdate, setisupdate] = useState(false);
	const [ermsg, setermsg] = useState("");
	const [addData, setaddData] = useState("");
	const [doc, setdoc] = useState("");
	const [img, setimg] = useState("");
	const [user, setuser] = useState("");
	const [errmsg, seterrmsg] = useState(
		"Supported file extensions: .jpg, jpeg, png. Maximum Size: 2 MB."
	);
	// var reactQuillRef=null;
	// ClassicEditor.defaultConfig = config

	const handleChange = (editor) => {
		// const data  = editor.getData();

		const data = editor;
		//  console.log(data);
		setaddData(data);
	};

	const handleDoc = (e) => {
		const value = e.target.files[0];
		setdoc(value);
		console.log(value);
	};
	const handleImage = (e) => {
		const value = e.target.files[0];
		const limit = 2639815;

		if (value !== null) {
			console.log(value.size);

			if (!value.name.match(/\.(jpg|jpeg|png)$/)) {
				seterrmsg("The file is not supported!");
			} else if (value.name.match(/\.(jpg|jpeg|png)$/) && value.size > limit) {
				seterrmsg("The file is too large. Size should not exceed 2 MB.");
			} else if (value.name.match(/\.(jpg|jpeg|png)$/) && value.size <= limit) {
				setimg(value);
				console.log("support");
				//success msg
				seterrmsg("File and size supported!");
			}
		} else {
			seterrmsg(
				"Supported file extensions: .jpg, jpeg, png. Maximum Size: 2 MB."
			);
		}
	};

	var formats = [
		"font",
		"size",
		"bold",
		"italic",
		"underline",
		"header",
		"list",
		"bullet",
		"align",
		"color",
		"background",
		"link",
		"video",
		"blockquote",
		"script",
		"indent",
	];
	const { register, handleSubmit } = useForm();
	const onSubmit = (data, e) => {
		e.preventDefault();

		addpost(data.title, data.category, data.link, img, doc, user, addData);
		// console.log(img);
		// console.log(doc);
		// console.log(data);
	};

	const addpost = async (title, category, link, img, doc, author, content) => {
		setisupdate(true);
		const formData = new FormData();

		formData.append("title", title);
		formData.append("category", category);
		formData.append("image", img);
		formData.append("document", doc);
		formData.append("link", link);
		formData.append("content", content);
		formData.append("author", author);

		// const boby= {
		//     "title":title,
		//     "category":category,
		//     "image":img,
		//     "document":doc,
		//     "link":link,
		//     "content":content,
		//     "author":author
		// }
		// console.log(formData);
		// console.log(boby);

		// const config = {
		//     headers: {
		//         'Content-Type': 'application/x-www-form-urlencoded'
		//                 }
		// }

		axiosInstance
			.post("addpost/", formData)
			.then((data) => {
				setermsg(data.message);
				history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/posts`);
			})
			.catch((err) => {
				setisupdate(false);

				if (err.request) {
					if (err.request.responseText) {
						setermsg("Enter a valid URL.");
					} else {
						setermsg(
							"Your request could not reach the server, check your internet connexion"
						);
					}

					console.log(err.request);
				} else if (err.response) {
					setermsg("Please contact the Admin");
					console.log(err.response);
				}
			});
	};

	const backBtn = () => {
		history.push({
			pathname: `/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/posts`,
		});
	};
	const getUser = () => {
		const user = localStorage.getItem("users");
		setuser(user);
		//console.log("my token \t\t\t"+user);
	};
	useEffect(() => {
		getUser();
	}, [axiosInstance, user]);
	return (
		<BackPaper
			content={
				<div>
					<Grid container direction="row">
						<Grid item xs={12} sm={12}>
							<Typography variant="h4" className={classes.bigtitle}>
								{" "}
								Create post
							</Typography>
						</Grid>
					</Grid>
					<Divider />
					<Grid container direction="row">
						<Grid item xs={12} sm={12}>
							<Card className={classes.grid}>
								<CardContent>
									<form onSubmit={handleSubmit(onSubmit)}>
										<Grid container>
											<Grid item sm={1}></Grid>
											<Grid item sm={10}>
												{/* left side */}
												<Grid
													container
													spacing={4}
													className={classes.margin_Top_30}
												>
													<Grid item xs={12} sm={6}>
														<Grid
															container
															className={classes.margin_Bottom_30}
															spacing={2}
														>
															<Grid item sm={12} xs={12}>
																<Typography className={classes.editDetailTypo}>
																	Title
																</Typography>
																<TextForm
																	//defaultValue={location.state.last_name}
																	name="title"
																	required
																	inputRef={register}
																	placeholder="Title"
																/>
															</Grid>
														</Grid>
														<Grid
															container
															className={classes.margin_Bottom_30}
															spacing={2}
														>
															<Grid item sm={12} xs={12}>
																<Typography className={classes.editDetailTypo}>
																	Link
																</Typography>
																<TextForm
																	//defaultValue={location.state.organization}
																	name="link"
																	inputRef={register}
																	type="link"
																	placeholder="e.g. https://oxide-digital.com/contact"
																/>
															</Grid>
															<Grid item sm={12} xs={12}>
																<Typography className={classes.editDetailTypo}>
																	Cover image
																</Typography>
																<TextForm
																	//defaultValue={location.state.organization}
																	name="img"
																	type="file"
																	helperText={errmsg}
																	//inputRef={register}
																	onchange={handleImage}
																	//onChange={e=>register({name:'img',value:e.target.value})}
																/>
															</Grid>
														</Grid>
													</Grid>
													{/* right side */}
													<Grid item xs={12} sm={6}>
														<Grid
															container
															className={classes.margin_Bottom_30}
															spacing={2}
														>
															<Grid item sm={12} xs={12}>
																<Typography className={classes.editDetailTypo}>
																	Categories
																</Typography>

																<Select
																	required
																	variant="outlined"
																	size="small"
																	fullWidth
																	style={{ height: 40 }}
																	// //defaultValue={location.state.role}

																	// value={age}
																	onChange={(e) =>
																		register({
																			name: "category",
																			value: e.target.value,
																		})
																	}
																>
																	<MenuItem value={"ARTICLE"}>
																		Reports & articles
																	</MenuItem>
																	<MenuItem value={"NEWS"}>
																		Campaign news
																	</MenuItem>
																</Select>
															</Grid>
														</Grid>
														<Grid
															container
															className={classes.margin_Bottom_30}
															spacing={2}
														>
															<Grid item sm={12} xs={12}>
																<Typography className={classes.editDetailTypo}>
																	Document
																</Typography>
																<TextForm
																	placeholder="document"
																	//defaultValue={location.state.first_name}
																	type="file"
																	name="doc"
																	//onChange={e=>register({name:'doc',value:e.target.value})}

																	onchange={handleDoc}
																	// inputRef={register}
																/>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
												<Grid item sm={12} xs={12} className={classes.ckeditor}>
													<ReactQuill
														theme="snow"
														onChange={handleChange}
														modules={{
															toolbar: {
																container: [
																	[{ font: [] }],
																	["bold", "italic", "underline"],
																	[{ header: [1, 2, 3, 4, 5, 6, false] }],
																	[{ list: "ordered" }, { list: "bullet" }],
																	[{ script: "sub" }, { script: "super" }],
																	[{ indent: "-1" }, { indent: "+1" }],
																	[{ direction: "rtl" }],
																	[{ align: [] }],
																	[{ color: [] }, { background: [] }],
																	["link", "video"],
																	["blockquote"],
																	["clean"],
																],
															},
														}}
														formats={formats}
														placeholder="Write content here"
														style={{ height: 300 }}
													/>
												</Grid>
												{/* button */}
												<Grid item xs={12} sm={12}>
													<Grid
														container
														className={classes.margin_Bottom_30}
														justifyContent="flex-end"
													>
														<Grid
															container
															justifyContent="flex-end"
															className={classes.margin_Bottom_30}
														>
															<Grid item>
																<Typography style={{ color: "red" }}>
																	{" "}
																	{ermsg}{" "}
																</Typography>
															</Grid>
														</Grid>
														<Grid item>
															<BtnOutline content="Back" onClick={backBtn} />

															{isupdate === true ? (
																<CircularProgress color="secondary" />
															) : (
																<BtnContained content="POST" type="submit" />
															)}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											<Grid item sm={1}></Grid>
										</Grid>
									</form>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</div>
			}
		/>
	);
};

export default Createpost;

import React from 'react'
import {Switch, Route} from 'react-router-dom'
import Home from './home'
import Login from './login'
import Revoir from './routerevoir'
import Case from './case'
import ProtectedRoute from './protectedRoute'



const Cases=()=>{
  // console.log(process.env.REACT_APP_COLLECT_LINK);
    return(
        <React.Fragment >
            <Switch>
                 <Route exact path={"/"+process.env.REACT_APP_COLLECT_LINK+'/collect/login'} component={Login}/>
                <ProtectedRoute exact path={'/'+process.env.REACT_APP_COLLECT_LINK+'/collect'} component={Home}/>
                <ProtectedRoute  path={"/"+process.env.REACT_APP_COLLECT_LINK+'/collect/form'} component={Case}/>
                <ProtectedRoute  path={"/"+process.env.REACT_APP_COLLECT_LINK+'/collect/main'} component={Revoir}/>
            </Switch>
        </React.Fragment>
    )
}
export default Cases;
import React from 'react';
import { Grid, Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import useStyles from "./style";
import oxide from "../../../assets/oxide.svg";
import { useHistory } from "react-router-dom";

const Submit = () => {
	const classes = useStyles();
	const history = useHistory();

	setTimeout(() => {
		sessionStorage.removeItem("organization");
		sessionStorage.removeItem("profile");
		sessionStorage.removeItem("info");
		sessionStorage.removeItem("perpetor");
		sessionStorage.removeItem("need");
		history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect`);
	}, 10000);

	return (
		<div style={{ height: "100vh" }}>
			<Grid container justifyContent="center" spacing={3}>
				<Grid item xs={4} sm={2}>
					<Grid container justifyContent="center">
						<CheckCircleOutlineIcon
							style={{ fontSize: 100, color: "#74D477", marginTop: 120 }}
						/>
					</Grid>

					<Grid container justifyContent="center" style={{ marginTop: 20 }}>
						<Typography>Soumis</Typography>
					</Grid>

					<Grid container justifyContent="center">
						<Grid item>
							<Typography variant="subtitle2" className={classes.foot}>
								POWERED BY:
							</Typography>
						</Grid>
					</Grid>
					<Grid container justifyContent="center">
						<Grid item>
							<img src={oxide} width="50px" alt="Oxide" />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export default Submit;

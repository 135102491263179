import React, { useState, useEffect } from "react";
import {
	AppBar,
	Toolbar,
	IconButton,
	Select,
	MenuItem,
	Grid,
	Typography,
	Box,
	TextField,
	RadioGroup,
	Radio,
	FormControlLabel,
	FormGroup,
	Checkbox,
	FormControl,
	//TextareaAutosize,
} from "@material-ui/core";
import useStyles from "./style";
import {
	q16,
	q17,
	q18,
	q18a,
	q18b,
	q18c,
	q19,
	q20,
	q20sub,
	q21,
	q21sub,
	q22,
	q22sub,
	q23,
	q24,
	q24a,
	q25,
	q26,
	q27,
	precedent,
	grp5,
	revoir,
} from "../../widget/i1e";
import { BtnContained, BtnOutline } from "../../widget/util";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const Need_care = () => {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const [orgData, setorgData] = useState([]);
	const [need, setneed] = useState([]);
	const [q16value, setq16value] = useState();
	const [q16Ouivalue, setq16Ouivalue] = useState();
	const [q17value, setq17value] = useState();
	const [q18value, setq18value] = useState();
	const [q19value, setq19value] = useState();
	const [q22value, setq22value] = useState();
	const [q18Ouivalue, setq18Ouivalue] = useState();
	const [q24value, setq24value] = useState();
	const [q24avalue, setq24avalue] = useState();
	const [q25value, setq25value] = useState();
	const [q27value, setq27value] = useState();
	const [q23value, setq23value] = useState();
	const [openSnackBar, setOpenSnackbar] = useState(false);
	const [open, setOpen] = useState(false);
	const [msg, setmsg] = useState();

	// snackbar
	const handleClose = () => {
		setOpen(false);
	};

	const handleSnackBar = () => {
		setOpenSnackbar(true);
	};

	const handleCloseSnackBar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpenSnackbar(false);
	};

	//controler for the navbar
	const [select, setSelect] = useState(6);
	const handleChange = (event) => {
		setSelect(event.target.value);
	};
	//

	//q18OuiA
	const [q18OuiAactivator, setq18OuiAactivator] = useState(false);

	const [soinsMedicaux, setsoinsMedicaux] = useState();
	const [soinsMedicauxChecked, setsoinsMedicauxChecked] = useState(false);
	const q18OuiAsoinsMedicaux = (e) => {
		const value = e.target.value;

		if (!soinsMedicauxChecked) {
			setsoinsMedicaux(value);
			setsoinsMedicauxChecked(true);
			setq18OuiAactivator(true);
			//console.log(soinsMedicauxChecked);
			//console.log(soinsMedicaux);
		} else {
			setsoinsMedicaux();
			setsoinsMedicauxChecked(false);
			setq18OuiAactivator(false);
			//console.log(soinsMedicauxChecked);
			//console.log(soinsMedicaux);
		}
	};

	const [assistancePsychosocial, setassistancePsychosocial] = useState();
	const [assistancePsychosocialChecked, setassistancePsychosocialChecked] =
		useState(false);
	const q18OuiAassistancePsychosocial = (e) => {
		const value = e.target.value;

		if (!assistancePsychosocialChecked) {
			setassistancePsychosocial(value);
			setassistancePsychosocialChecked(true);
			setq18OuiAactivator(true);
			//console.log(assistancePsychosocialChecked);
			//console.log(assistancePsychosocial);
		} else {
			setassistancePsychosocial();
			setassistancePsychosocialChecked(false);
			setq18OuiAactivator(false);
			//console.log(assistancePsychosocialChecked);
			//console.log(assistancePsychosocial);
		}
	};
	const [assistanceJuridique, setassistanceJuridique] = useState();
	const [assistanceJuridiqueChecked, setassistanceJuridiqueChecked] =
		useState(false);
	const q18OuiAassistanceJuridique = (e) => {
		const value = e.target.value;

		if (!assistanceJuridiqueChecked) {
			setassistanceJuridique(value);
			setassistanceJuridiqueChecked(true);
			setq18OuiAactivator(true);
			//console.log(assistanceJuridiqueChecked);
			//console.log(assistanceJuridique);
		} else {
			setassistanceJuridique();
			setassistanceJuridiqueChecked(false);
			setq18OuiAactivator(false);
			//console.log(assistanceJuridiqueChecked);
			//console.log(assistanceJuridique);
		}
	};
	const [assisstMed, setassisstMed] = useState();
	const [assisstMedChecked, setassisstMedChecked] = useState(false);
	const q18OuiAassisstMed = (e) => {
		const value = e.target.value;

		if (!assisstMedChecked) {
			setassisstMed(value);
			setassisstMedChecked(true);
			setq18OuiAactivator(true);
			//console.log(assisstMedChecked);
			//console.log(assisstMed);
		} else {
			setassisstMed();
			setassisstMedChecked(false);
			setq18OuiAactivator(false);
			//console.log(assisstMedChecked);
			//console.log(assisstMed);
		}
	};

	const [denonciationDuCasAvecSuite, setdenonciationDuCasAvecSuite] =
		useState();
	const [
		denonciationDuCasAvecSuiteChecked,
		setdenonciationDuCasAvecSuiteChecked,
	] = useState(false);
	const q18OuiAdenonciationDuCasAvecSuite = (e) => {
		const value = e.target.value;

		if (!denonciationDuCasAvecSuiteChecked) {
			setdenonciationDuCasAvecSuite(value);
			setdenonciationDuCasAvecSuiteChecked(true);
			setq18OuiAactivator(true);
			//console.log(denonciationDuCasAvecSuiteChecked);
			//console.log(denonciationDuCasAvecSuite);
		} else {
			setdenonciationDuCasAvecSuite();
			setdenonciationDuCasAvecSuiteChecked(false);
			setq18OuiAactivator(false);
			//console.log(denonciationDuCasAvecSuiteChecked);
			//console.log(denonciationDuCasAvecSuite);
		}
	};

	const [denonciationDuCasSansSuite, setdenonciationDuCasSansSuite] =
		useState();
	const [
		denonciationDuCasSansSuiteChecked,
		setdenonciationDuCasSansSuiteChecked,
	] = useState(false);
	const q18OuiAdenonciationDuCasSansSuite = (e) => {
		const value = e.target.value;

		if (!denonciationDuCasSansSuiteChecked) {
			setdenonciationDuCasSansSuite(value);
			setdenonciationDuCasSansSuiteChecked(true);
			setq18OuiAactivator(true);
			//console.log(denonciationDuCasSansSuiteChecked);
			//console.log(denonciationDuCasSansSuite);
		} else {
			setdenonciationDuCasSansSuite();
			setdenonciationDuCasSansSuiteChecked(false);
			setq18OuiAactivator(false);
			//console.log(denonciationDuCasSansSuiteChecked);
			//console.log(denonciationDuCasSansSuite);
		}
	};

	const [assistSocio, setassistSocio] = useState();
	const [assistSocioChecked, setassistSocioChecked] = useState(false);
	const q18OuiAassistSocio = (e) => {
		const value = e.target.value;

		if (!assistSocioChecked) {
			setassistSocio(value);
			setassistSocioChecked(true);
			setq18OuiAactivator(true);
			//console.log(assistSocioChecked);
			//console.log(assistSocio);
		} else {
			setassistSocio();
			setassistSocioChecked(false);
			setq18OuiAactivator(false);
			//console.log(assistSocioChecked);
			//console.log(assistSocio);
		}
	};
	const [dvplan, setdvplan] = useState();
	const [dvplanChecked, setdvplanChecked] = useState(false);
	const q18OuiAdvplan = (e) => {
		const value = e.target.value;

		if (!dvplanChecked) {
			setdvplan(value);
			setdvplanChecked(true);
			setq18OuiAactivator(true);
			//console.log(dvplanChecked);
			//console.log(dvplan);
		} else {
			setdvplan();
			setdvplanChecked(false);
			setq18OuiAactivator(false);
			//console.log(dvplanChecked);
			//console.log(dvplan);
		}
	};

	//q19

	const [q19activator, setq19activator] = useState(false);

	const [soinsMedicaux2, setsoinsMedicaux2] = useState();
	const [soinsMedicaux2Checked, setsoinsMedicaux2Checked] = useState(false);
	const q19soinsMedicaux2 = (e) => {
		const value = e.target.value;

		if (!soinsMedicaux2Checked) {
			setsoinsMedicaux2(value);
			setsoinsMedicaux2Checked(true);
			setq19activator(true);
			//console.log(soinsMedicaux2Checked);
			//console.log(soinsMedicaux2);
		} else {
			setsoinsMedicaux2();
			setsoinsMedicaux2Checked(false);
			setq19activator(false);
			//console.log(soinsMedicaux2Checked);
			//console.log(soinsMedicaux2);
		}
	};
	const [soinsMedicauxDans72heures, setsoinsMedicauxDans72heures] = useState();
	const [
		soinsMedicauxDans72heuresChecked,
		setsoinsMedicauxDans72heuresChecked,
	] = useState(false);
	const q19soinsMedicauxDans72heures = (e) => {
		const value = e.target.value;

		if (!soinsMedicauxDans72heuresChecked) {
			setsoinsMedicauxDans72heures(value);
			setsoinsMedicauxDans72heuresChecked(true);
			setq19activator(true);
			//console.log(soinsMedicauxDans72heuresChecked);
			//console.log(soinsMedicauxDans72heures);
		} else {
			setsoinsMedicauxDans72heures();
			setsoinsMedicauxDans72heuresChecked(false);
			setq19activator(false);
			//console.log(soinsMedicauxDans72heuresChecked);
			//console.log(soinsMedicauxDans72heures);
		}
	};
	const [assistancePsychosocial2, setassistancePsychosocial2] = useState();
	const [assistancePsychosocial2Checked, setassistancePsychosocial2Checked] =
		useState(false);
	const q19assistancePsychosocial2 = (e) => {
		const value = e.target.value;

		if (!assistancePsychosocial2Checked) {
			setassistancePsychosocial2(value);
			setassistancePsychosocial2Checked(true);
			setq19activator(true);
			//console.log(assistancePsychosocial2Checked);
			//console.log(assistancePsychosocial2);
		} else {
			setassistancePsychosocial2();
			setassistancePsychosocial2Checked(false);
			setq19activator(false);
			//console.log(assistancePsychosocial2Checked);
			//console.log(assistancePsychosocial2);
		}
	};
	const [assistanceEnMediation2, setassistanceEnMediation2] = useState();
	const [assistanceEnMediation2Checked, setassistanceEnMediation2Checked] =
		useState(false);
	const q19assistanceEnMediation2 = (e) => {
		const value = e.target.value;

		if (!assistanceEnMediation2Checked) {
			setassistanceEnMediation2(value);
			setassistanceEnMediation2Checked(true);
			setq19activator(true);
			//console.log(assistanceEnMediation2Checked);
			//console.log(assistanceEnMediation2);
		} else {
			setassistanceEnMediation2();
			setassistanceEnMediation2Checked(false);
			setq19activator(false);
			//console.log(assistanceEnMediation2Checked);
			//console.log(assistanceEnMediation2);
		}
	};

	const [assistanceJuri, setassistanceJuri] = useState();
	const [assistanceJuriChecked, setassistanceJuriChecked] = useState(false);
	const q19assistanceJuri = (e) => {
		const value = e.target.value;

		if (!assistanceJuriChecked) {
			setassistanceJuri(value);
			setassistanceJuriChecked(true);
			setq19activator(true);
			//console.log(assistanceJuriChecked);
			//console.log(assistanceJuri);
		} else {
			setassistanceJuri();
			setassistanceJuriChecked(false);
			setq19activator(false);
			//console.log(assistanceJuriChecked);
			//console.log(assistanceJuri);
		}
	};

	const [assistanceJuriPourDenoncer, setassistanceJuriPourDenoncer] =
		useState();
	const [
		assistanceJuriPourDenoncerChecked,
		setassistanceJuriPourDenoncerChecked,
	] = useState(false);
	const q19assistanceJuriPourDenoncer = (e) => {
		const value = e.target.value;

		if (!assistanceJuriPourDenoncerChecked) {
			setassistanceJuriPourDenoncer(value);
			setassistanceJuriPourDenoncerChecked(true);
			setq19activator(true);
			//console.log(assistanceJuriPourDenoncerChecked);
			//console.log(assistanceJuriPourDenoncer);
		} else {
			setassistanceJuriPourDenoncer();
			setassistanceJuriPourDenoncerChecked(false);
			setq19activator(false);
			//console.log(assistanceJuriPourDenoncerChecked);
			//console.log(assistanceJuriPourDenoncer);
		}
	};

	const [
		assistanceJuriPourDenoncerCivile,
		setassistanceJuriPourDenoncerCivile,
	] = useState();
	const [
		assistanceJuriPourDenoncerCivileChecked,
		setassistanceJuriPourDenoncerCivileChecked,
	] = useState(false);
	const q19assistanceJuriPourDenoncerCivile = (e) => {
		const value = e.target.value;

		if (!assistanceJuriPourDenoncerCivileChecked) {
			setassistanceJuriPourDenoncerCivile(value);
			setassistanceJuriPourDenoncerCivileChecked(true);
			setq19activator(true);
			//console.log(assistanceJuriPourDenoncerCivileChecked);
			//console.log(assistanceJuriPourDenoncerCivile);
		} else {
			setassistanceJuriPourDenoncerCivile();
			setassistanceJuriPourDenoncerCivileChecked(false);
			setq19activator(false);
			//console.log(assistanceJuriPourDenoncerCivileChecked);
			//console.log(assistanceJuriPourDenoncerCivile);
		}
	};

	const [assistanceSocioEconomique2, setassistanceSocioEconomique2] =
		useState();
	const [
		assistanceSocioEconomique2Checked,
		setassistanceSocioEconomique2Checked,
	] = useState(false);
	const q19assistanceSocioEconomique2 = (e) => {
		const value = e.target.value;

		if (!assistanceSocioEconomique2Checked) {
			setassistanceSocioEconomique2(value);
			setassistanceSocioEconomique2Checked(true);
			setq19activator(true);
			//console.log(assistanceSocioEconomique2Checked);
			//console.log(assistanceSocioEconomique2);
		} else {
			setassistanceSocioEconomique2();
			setassistanceSocioEconomique2Checked(false);
			setq19activator(false);
			//console.log(assistanceSocioEconomique2Checked);
			//console.log(assistanceSocioEconomique2);
		}
	};
	const [dvPlanDeSecurite, setdvPlanDeSecurite] = useState();
	const [dvPlanDeSecuriteChecked, setdvPlanDeSecuriteChecked] = useState(false);
	const q19dvPlanDeSecurite = (e) => {
		const value = e.target.value;

		if (!dvPlanDeSecuriteChecked) {
			setdvPlanDeSecurite(value);
			setdvPlanDeSecuriteChecked(true);
			setq19activator(true);
			//console.log(dvPlanDeSecuriteChecked);
			//console.log(dvPlanDeSecurite);
		} else {
			setdvPlanDeSecurite();
			setdvPlanDeSecuriteChecked(false);
			setq19activator(false);
			//console.log(dvPlanDeSecuriteChecked);
			//console.log(dvPlanDeSecurite);
		}
	};
	//q20

	const [q20activator, setq20activator] = useState(false);

	const [soinsMedicaux3, setsoinsMedicaux3] = useState();
	const [soinsMedicaux3Checked, setsoinsMedicaux3Checked] = useState(false);
	const q20soinsMedicaux3 = (e) => {
		const value = e.target.value;

		if (!soinsMedicaux3Checked) {
			setsoinsMedicaux3(value);
			setsoinsMedicaux3Checked(true);
			setq20activator(true);
			//console.log(soinsMedicaux3Checked);
			//console.log(soinsMedicaux3);
		} else {
			setsoinsMedicaux3();
			setsoinsMedicaux3Checked(false);
			setq20activator(false);
			//console.log(soinsMedicaux3Checked);
			//console.log(soinsMedicaux3);
		}
	};
	const [soinsMedicauxDans72heures2, setsoinsMedicauxDans72heures2] =
		useState();
	const [
		soinsMedicauxDans72heures2Checked,
		setsoinsMedicauxDans72heures2Checked,
	] = useState(false);
	const q20soinsMedicauxDans72heures2 = (e) => {
		const value = e.target.value;

		if (!soinsMedicauxDans72heures2Checked) {
			setsoinsMedicauxDans72heures2(value);
			setsoinsMedicauxDans72heures2Checked(true);
			setq20activator(true);
			//console.log(soinsMedicauxDans72heures2Checked);
			//console.log(soinsMedicauxDans72heures2);
		} else {
			setsoinsMedicauxDans72heures2();
			setsoinsMedicauxDans72heures2Checked(false);
			setq20activator(false);
			//console.log(soinsMedicauxDans72heures2Checked);
			//console.log(soinsMedicauxDans72heures2);
		}
	};
	const [assistancePsychosocial3, setassistancePsychosocial3] = useState();
	const [assistancePsychosocial3Checked, setassistancePsychosocial3Checked] =
		useState(false);
	const q20assistancePsychosocial3 = (e) => {
		const value = e.target.value;

		if (!assistancePsychosocial3Checked) {
			setassistancePsychosocial3(value);
			setassistancePsychosocial3Checked(true);
			setq20activator(true);
			//console.log(assistancePsychosocial3Checked);
			//console.log(assistancePsychosocial3);
		} else {
			setassistancePsychosocial3();
			setassistancePsychosocial3Checked(false);
			setq20activator(false);
			//console.log(assistancePsychosocial3Checked);
			//console.log(assistancePsychosocial3);
		}
	};
	const [assistanceEnMediationFam3, setassistanceEnMediationFam3] = useState();
	const [
		assistanceEnMediationFam3Checked,
		setassistanceEnMediationFam3Checked,
	] = useState(false);
	const q20assistanceEnMediationFam3 = (e) => {
		const value = e.target.value;

		if (!assistanceEnMediationFam3Checked) {
			setassistanceEnMediationFam3(value);
			setassistanceEnMediationFam3Checked(true);
			setq20activator(true);
			//console.log(assistanceEnMediationFam3Checked);
			//console.log(assistanceEnMediationFam3);
		} else {
			setassistanceEnMediationFam3();
			setassistanceEnMediationFam3Checked(false);
			setq20activator(false);
			//console.log(assistanceEnMediationFam3Checked);
			//console.log(assistanceEnMediationFam3);
		}
	};

	const [assistanceJuri2, setassistanceJuri2] = useState();
	const [assistanceJuri2Checked, setassistanceJuri2Checked] = useState(false);
	const q20assistanceJuri2 = (e) => {
		const value = e.target.value;

		if (!assistanceJuri2Checked) {
			setassistanceJuri2(value);
			setassistanceJuri2Checked(true);
			setq20activator(true);
			//console.log(assistanceJuri2Checked);
			//console.log(assistanceJuri2);
		} else {
			setassistanceJuri2();
			setassistanceJuri2Checked(false);
			setq20activator(false);
			//console.log(assistanceJuri2Checked);
			//console.log(assistanceJuri2);
		}
	};

	const [assistanceJuriPourDenoncer2, setassistanceJuriPourDenoncer2] =
		useState();
	const [
		assistanceJuriPourDenoncer2Checked,
		setassistanceJuriPourDenoncer2Checked,
	] = useState(false);
	const q20assistanceJuriPourDenoncer2 = (e) => {
		const value = e.target.value;

		if (!assistanceJuriPourDenoncer2Checked) {
			setassistanceJuriPourDenoncer2(value);
			setassistanceJuriPourDenoncer2Checked(true);
			setq20activator(true);
			//console.log(assistanceJuriPourDenoncer2Checked);
			//console.log(assistanceJuriPourDenoncer2);
		} else {
			setassistanceJuriPourDenoncer2();
			setassistanceJuriPourDenoncer2Checked(false);
			setq20activator(false);
			//console.log(assistanceJuriPourDenoncer2Checked);
			//console.log(assistanceJuriPourDenoncer2);
		}
	};

	const [
		assistanceJuriPourDenoncer2Civile,
		setassistanceJuriPourDenoncer2Civile,
	] = useState();
	const [
		assistanceJuriPourDenoncer2CivileChecked,
		setassistanceJuriPourDenoncer2CivileChecked,
	] = useState(false);
	const q20assistanceJuriPourDenoncer2Civile = (e) => {
		const value = e.target.value;

		if (!assistanceJuriPourDenoncer2CivileChecked) {
			setassistanceJuriPourDenoncer2Civile(value);
			setassistanceJuriPourDenoncer2CivileChecked(true);
			setq20activator(true);
			//console.log(assistanceJuriPourDenoncer2CivileChecked);
			//console.log(assistanceJuriPourDenoncer2Civile);
		} else {
			setassistanceJuriPourDenoncer2Civile();
			setassistanceJuriPourDenoncer2CivileChecked(false);
			setq20activator(false);
			//console.log(assistanceJuriPourDenoncer2CivileChecked);
			//console.log(assistanceJuriPourDenoncer2Civile);
		}
	};

	const [assistanceSocioEconomique3, setassistanceSocioEconomique3] =
		useState();
	const [
		assistanceSocioEconomique3Checked,
		setassistanceSocioEconomique3Checked,
	] = useState(false);
	const q20assistanceSocioEconomique3 = (e) => {
		const value = e.target.value;

		if (!assistanceSocioEconomique3Checked) {
			setassistanceSocioEconomique3(value);
			setassistanceSocioEconomique3Checked(true);
			setq20activator(true);
			//console.log(assistanceSocioEconomique3Checked);
			//console.log(assistanceSocioEconomique3);
		} else {
			setassistanceSocioEconomique3();
			setassistanceSocioEconomique3Checked(false);
			setq20activator(false);
			//console.log(assistanceSocioEconomique3Checked);
			//console.log(assistanceSocioEconomique3);
		}
	};
	const [dvPlanDeSecurite2, setdvPlanDeSecurite2] = useState();
	const [dvPlanDeSecurite2Checked, setdvPlanDeSecurite2Checked] =
		useState(false);
	const q20dvPlanDeSecurite2 = (e) => {
		const value = e.target.value;

		if (!dvPlanDeSecurite2Checked) {
			setdvPlanDeSecurite2(value);
			setdvPlanDeSecurite2Checked(true);
			setq20activator(true);
			//console.log(dvPlanDeSecurite2Checked);
			//console.log(dvPlanDeSecurite2);
		} else {
			setdvPlanDeSecurite2();
			setdvPlanDeSecurite2Checked(false);
			setq20activator(false);
			//console.log(dvPlanDeSecurite2Checked);
			//console.log(dvPlanDeSecurite2);
		}
	};

	//q21

	const [q21activator, setq21activator] = useState(false);

	const [soinsMedicaux4, setsoinsMedicaux4] = useState();
	const [soinsMedicaux4Checked, setsoinsMedicaux4Checked] = useState(false);
	const q21soinsMedicaux4 = (e) => {
		const value = e.target.value;

		if (!soinsMedicaux4Checked) {
			setsoinsMedicaux4(value);
			setsoinsMedicaux4Checked(true);
			setq21activator(true);
			//console.log(soinsMedicaux4Checked);
			//console.log(soinsMedicaux4);
		} else {
			setsoinsMedicaux4();
			setsoinsMedicaux4Checked(false);
			setq21activator(false);
			//console.log(soinsMedicaux4Checked);
			//console.log(soinsMedicaux4);
		}
	};
	const [soinsMedicauxDans72heures3, setsoinsMedicauxDans72heures3] =
		useState();
	const [
		soinsMedicauxDans72heures3Checked,
		setsoinsMedicauxDans72heures3Checked,
	] = useState(false);
	const q21soinsMedicauxDans72heures3 = (e) => {
		const value = e.target.value;

		if (!soinsMedicauxDans72heures3Checked) {
			setsoinsMedicauxDans72heures3(value);
			setsoinsMedicauxDans72heures3Checked(true);
			setq21activator(true);
			//console.log(soinsMedicauxDans72heures3Checked);
			//console.log(soinsMedicauxDans72heures3);
		} else {
			setsoinsMedicauxDans72heures3();
			setsoinsMedicauxDans72heures3Checked(false);
			setq21activator(false);
			//console.log(soinsMedicauxDans72heures3Checked);
			//console.log(soinsMedicauxDans72heures3);
		}
	};
	const [assistancePsychosocial4, setassistancePsychosocial4] = useState();
	const [assistancePsychosocial4Checked, setassistancePsychosocial4Checked] =
		useState(false);
	const q21assistancePsychosocial4 = (e) => {
		const value = e.target.value;

		if (!assistancePsychosocial4Checked) {
			setassistancePsychosocial4(value);
			setassistancePsychosocial4Checked(true);
			setq21activator(true);
			//console.log(assistancePsychosocial4Checked);
			//console.log(assistancePsychosocial4);
		} else {
			setassistancePsychosocial4();
			setassistancePsychosocial4Checked(false);
			setq21activator(false);
			//console.log(assistancePsychosocial4Checked);
			//console.log(assistancePsychosocial4);
		}
	};
	const [assistanceEnMediation4, setassistanceEnMediation4] = useState();
	const [assistanceEnMediation4Checked, setassistanceEnMediation4Checked] =
		useState(false);
	const q21assistanceEnMediation4 = (e) => {
		const value = e.target.value;

		if (!assistanceEnMediation4Checked) {
			setassistanceEnMediation4(value);
			setassistanceEnMediation4Checked(true);
			setq21activator(true);
			//console.log(assistanceEnMediation4Checked);
			//console.log(assistanceEnMediation4);
		} else {
			setassistanceEnMediation4();
			setassistanceEnMediation4Checked(false);
			setq21activator(false);
			//console.log(assistanceEnMediation4Checked);
			//console.log(assistanceEnMediation4);
		}
	};

	const [assistanceJuri3, setassistanceJuri3] = useState();
	const [assistanceJuri3Checked, setassistanceJuri3Checked] = useState(false);
	const q21assistanceJuri3 = (e) => {
		const value = e.target.value;

		if (!assistanceJuri3Checked) {
			setassistanceJuri3(value);
			setassistanceJuri3Checked(true);
			setq21activator(true);
			//console.log(assistanceJuri3Checked);
			//console.log(assistanceJuri3);
		} else {
			setassistanceJuri3();
			setassistanceJuri3Checked(false);
			setq21activator(false);
			//console.log(assistanceJuri3Checked);
			//console.log(assistanceJuri3);
		}
	};

	const [assistanceJuriPourDenoncer3, setassistanceJuriPourDenoncer3] =
		useState();
	const [
		assistanceJuriPourDenoncer3Checked,
		setassistanceJuriPourDenoncer3Checked,
	] = useState(false);
	const q21assistanceJuriPourDenoncer3 = (e) => {
		const value = e.target.value;

		if (!assistanceJuriPourDenoncer3Checked) {
			setassistanceJuriPourDenoncer3(value);
			setassistanceJuriPourDenoncer3Checked(true);
			setq21activator(true);
			//console.log(assistanceJuriPourDenoncer3Checked);
			//console.log(assistanceJuriPourDenoncer3);
		} else {
			setassistanceJuriPourDenoncer3();
			setassistanceJuriPourDenoncer3Checked(false);
			setq21activator(false);
			//console.log(assistanceJuriPourDenoncer3Checked);
			//console.log(assistanceJuriPourDenoncer3);
		}
	};

	const [
		assistanceJuriPourDenoncer3Civile,
		setassistanceJuriPourDenoncer3Civile,
	] = useState();
	const [
		assistanceJuriPourDenoncer3CivileChecked,
		setassistanceJuriPourDenoncer3CivileChecked,
	] = useState(false);
	const q21assistanceJuriPourDenoncer3Civile = (e) => {
		const value = e.target.value;

		if (!assistanceJuriPourDenoncer3CivileChecked) {
			setassistanceJuriPourDenoncer3Civile(value);
			setassistanceJuriPourDenoncer3CivileChecked(true);
			setq21activator(true);
			//console.log(assistanceJuriPourDenoncer3CivileChecked);
			//console.log(assistanceJuriPourDenoncer3Civile);
		} else {
			setassistanceJuriPourDenoncer3Civile();
			setassistanceJuriPourDenoncer3CivileChecked(false);
			setq21activator(false);
			//console.log(assistanceJuriPourDenoncer3CivileChecked);
			//console.log(assistanceJuriPourDenoncer3Civile);
		}
	};

	const [assistanceSocioEconomique4, setassistanceSocioEconomique4] =
		useState();
	const [
		assistanceSocioEconomique4Checked,
		setassistanceSocioEconomique4Checked,
	] = useState(false);
	const q21assistanceSocioEconomique4 = (e) => {
		const value = e.target.value;

		if (!assistanceSocioEconomique4Checked) {
			setassistanceSocioEconomique4(value);
			setassistanceSocioEconomique4Checked(true);
			setq21activator(true);
			//console.log(assistanceSocioEconomique4Checked);
			//console.log(assistanceSocioEconomique4);
		} else {
			setassistanceSocioEconomique4();
			setassistanceSocioEconomique4Checked(false);
			setq21activator(false);
			//console.log(assistanceSocioEconomique4Checked);
			//console.log(assistanceSocioEconomique4);
		}
	};
	const [dvPlanDeSecurite3, setdvPlanDeSecurite3] = useState();
	const [dvPlanDeSecurite3Checked, setdvPlanDeSecurite3Checked] =
		useState(false);
	const q21dvPlanDeSecurite3 = (e) => {
		const value = e.target.value;

		if (!dvPlanDeSecurite3Checked) {
			setdvPlanDeSecurite3(value);
			setdvPlanDeSecurite3Checked(true);
			setq21activator(true);
			//console.log(dvPlanDeSecurite3Checked);
			//console.log(dvPlanDeSecurite3);
		} else {
			setdvPlanDeSecurite3();
			setdvPlanDeSecurite3Checked(false);
			setq21activator(false);
			//console.log(dvPlanDeSecurite3Checked);
			//console.log(dvPlanDeSecurite3);
		}
	};
	const [serviceDemande, setserviceDemande] = useState();
	const [serviceDemandeChecked, setserviceDemandeChecked] = useState(false);
	const q21serviceDemande = (e) => {
		const value = e.target.value;

		if (!serviceDemandeChecked) {
			setserviceDemande(value);
			setserviceDemandeChecked(true);
			setq21activator(true);
			//console.log(serviceDemandeChecked);
			console.log(serviceDemande);
		} else {
			setserviceDemande();
			setserviceDemandeChecked(false);
			setq21activator(false);
			//console.log(dvPlanDeSecurite3Checked);
			//console.log(dvPlanDeSecurite3);
		}
	};

	//q22

	const [q22activator, setq22activator] = useState(false);

	const [soinsMedicaux5, setsoinsMedicaux5] = useState();
	const [soinsMedicaux5Checked, setsoinsMedicaux5Checked] = useState(false);
	const q22soinsMedicaux5 = (e) => {
		const value = e.target.value;

		if (!soinsMedicaux5Checked) {
			setsoinsMedicaux5(value);
			setsoinsMedicaux5Checked(true);
			setq22activator(true);
			//console.log(soinsMedicaux5Checked);
			//console.log(soinsMedicaux5);
		} else {
			setsoinsMedicaux5();
			setsoinsMedicaux5Checked(false);
			setq22activator(false);
			//console.log(soinsMedicaux5Checked);
			//console.log(soinsMedicaux5);
		}
	};
	const [soinsMedicauxDans72heures4, setsoinsMedicauxDans72heures4] =
		useState();
	const [
		soinsMedicauxDans72heures4Checked,
		setsoinsMedicauxDans72heures4Checked,
	] = useState(false);
	const q22soinsMedicauxDans72heures4 = (e) => {
		const value = e.target.value;

		if (!soinsMedicauxDans72heures4Checked) {
			setsoinsMedicauxDans72heures4(value);
			setsoinsMedicauxDans72heures4Checked(true);
			setq22activator(true);
			//console.log(soinsMedicauxDans72heures4Checked);
			//console.log(soinsMedicauxDans72heures4);
		} else {
			setsoinsMedicauxDans72heures4();
			setsoinsMedicauxDans72heures4Checked(false);
			setq22activator(false);
			//console.log(soinsMedicauxDans72heures4Checked);
			//console.log(soinsMedicauxDans72heures4);
		}
	};
	const [assistancePsychosocial5, setassistancePsychosocial5] = useState();
	const [assistancePsychosocial5Checked, setassistancePsychosocial5Checked] =
		useState(false);
	const q22assistancePsychosocial5 = (e) => {
		const value = e.target.value;

		if (!assistancePsychosocial5Checked) {
			setassistancePsychosocial5(value);
			setassistancePsychosocial5Checked(true);
			setq22activator(true);
			//console.log(assistancePsychosocial5Checked);
			//console.log(assistancePsychosocial5);
		} else {
			setassistancePsychosocial5();
			setassistancePsychosocial5Checked(false);
			setq22activator(false);
			//console.log(assistancePsychosocial5Checked);
			//console.log(assistancePsychosocial5);
		}
	};
	const [assistanceEnMediation5, setassistanceEnMediation5] = useState();
	const [assistanceEnMediation5Checked, setassistanceEnMediation5Checked] =
		useState(false);
	const q22assistanceEnMediation5 = (e) => {
		const value = e.target.value;

		if (!assistanceEnMediation5Checked) {
			setassistanceEnMediation5(value);
			setassistanceEnMediation5Checked(true);
			setq22activator(true);
			//console.log(assistanceEnMediation5Checked);
			//console.log(assistanceEnMediation5);
		} else {
			setassistanceEnMediation5();
			setassistanceEnMediation5Checked(false);
			setq22activator(false);
			//console.log(assistanceEnMediation5Checked);
			//console.log(assistanceEnMediation5);
		}
	};

	const [assistanceJuri4, setassistanceJuri4] = useState();
	const [assistanceJuri4Checked, setassistanceJuri4Checked] = useState(false);
	const q22assistanceJuri4 = (e) => {
		const value = e.target.value;

		if (!assistanceJuri4Checked) {
			setassistanceJuri4(value);
			setassistanceJuri4Checked(true);
			setq22activator(true);
			//console.log(assistanceJuri4Checked);
			//console.log(assistanceJuri4);
		} else {
			setassistanceJuri4();
			setassistanceJuri4Checked(false);
			setq22activator(false);
			//console.log(assistanceJuri4Checked);
			//console.log(assistanceJuri4);
		}
	};

	const [assistanceJuriPourDenoncer4, setassistanceJuriPourDenoncer4] =
		useState();
	const [
		assistanceJuriPourDenoncer4Checked,
		setassistanceJuriPourDenoncer4Checked,
	] = useState(false);
	const q22assistanceJuriPourDenoncer4 = (e) => {
		const value = e.target.value;

		if (!assistanceJuriPourDenoncer4Checked) {
			setassistanceJuriPourDenoncer4(value);
			setassistanceJuriPourDenoncer4Checked(true);
			setq22activator(true);
			//console.log(assistanceJuriPourDenoncer4Checked);
			//console.log(assistanceJuriPourDenoncer4);
		} else {
			setassistanceJuriPourDenoncer4();
			setassistanceJuriPourDenoncer4Checked(false);
			setq22activator(false);
			//console.log(assistanceJuriPourDenoncer4Checked);
			//console.log(assistanceJuriPourDenoncer4);
		}
	};

	const [
		assistanceJuriPourDenoncer4Civile,
		setassistanceJuriPourDenoncer4Civile,
	] = useState();
	const [
		assistanceJuriPourDenoncer4CivileChecked,
		setassistanceJuriPourDenoncer4CivileChecked,
	] = useState(false);
	const q22assistanceJuriPourDenoncer4Civile = (e) => {
		const value = e.target.value;

		if (!assistanceJuriPourDenoncer4CivileChecked) {
			setassistanceJuriPourDenoncer4Civile(value);
			setassistanceJuriPourDenoncer4CivileChecked(true);
			setq22activator(true);
			//console.log(assistanceJuriPourDenoncer4CivileChecked);
			//console.log(assistanceJuriPourDenoncer4Civile);
		} else {
			setassistanceJuriPourDenoncer4Civile();
			setassistanceJuriPourDenoncer4CivileChecked(false);
			setq22activator(false);
			//console.log(assistanceJuriPourDenoncer4CivileChecked);
			//console.log(assistanceJuriPourDenoncer4Civile);
		}
	};

	const [assistanceSocioEconomique5, setassistanceSocioEconomique5] =
		useState();
	const [
		assistanceSocioEconomique5Checked,
		setassistanceSocioEconomique5Checked,
	] = useState(false);
	const q22assistanceSocioEconomique5 = (e) => {
		const value = e.target.value;

		if (!assistanceSocioEconomique5Checked) {
			setassistanceSocioEconomique5(value);
			setassistanceSocioEconomique5Checked(true);
			setq22activator(true);
			//console.log(assistanceSocioEconomique5Checked);
			//console.log(assistanceSocioEconomique5);
		} else {
			setassistanceSocioEconomique5();
			setassistanceSocioEconomique5Checked(false);
			setq22activator(false);
			//console.log(assistanceSocioEconomique5Checked);
			//console.log(assistanceSocioEconomique5);
		}
	};
	const [dvPlanDeSecurite4, setdvPlanDeSecurite4] = useState();
	const [dvPlanDeSecurite4Checked, setdvPlanDeSecurite4Checked] =
		useState(false);
	const q22dvPlanDeSecurite4 = (e) => {
		const value = e.target.value;

		if (!dvPlanDeSecurite4Checked) {
			setdvPlanDeSecurite4(value);
			setdvPlanDeSecurite4Checked(true);
			setq22activator(true);
			//console.log(dvPlanDeSecurite4Checked);
			//console.log(dvPlanDeSecurite4);
		} else {
			setdvPlanDeSecurite4();
			setdvPlanDeSecurite4Checked(false);
			setq22activator(false);
			//console.log(dvPlanDeSecurite4Checked);
			//console.log(dvPlanDeSecurite4);
		}
	};

	const [patenaireDeRef, setpatenaireDeRef] = useState();
	const [patenaireDeRefChecked, setpatenaireDeRefChecked] = useState(false);
	const q22patenaireDeRef = (e) => {
		const value = e.target.value;

		if (!patenaireDeRefChecked) {
			setpatenaireDeRef(value);
			setpatenaireDeRefChecked(true);
			setq22activator(true);
			//console.log(patenaireDeRefChecked);
			//console.log(patenaireDeRef);
		} else {
			setpatenaireDeRef();
			setpatenaireDeRefChecked(false);
			setq22activator(false);
			//console.log(patenaireDeRefChecked);
			//console.log(dvPlanDeSecurite4);
		}
	};

	const [q18bool, setq18bool] = useState(false);
	const q18Check = (e) => {
		const value = e.target.value;
		if (value === "A") {
			//console.log('q18',value);
			setq18value(value);
			setq18OuiAactivator(false);
			setq18bool(true);
		} else {
			//console.log('q18',value);
			setq18OuiAactivator(true);
			setq18bool(true);
			setq18value(value);
			setsoinsMedicauxChecked(false);
			setsoinsMedicaux();
			setassistancePsychosocialChecked(false);
			setassistancePsychosocial();
			setassistanceJuridiqueChecked(false);
			setassistanceJuridique();
			setassisstMedChecked(false);
			setassisstMed();
			setdenonciationDuCasAvecSuiteChecked(false);
			setdenonciationDuCasAvecSuite();
			setdenonciationDuCasSansSuiteChecked(false);
			setdenonciationDuCasSansSuite();
			setassistSocioChecked(false);
			setassistSocio();
			setdvplanChecked(false);
			setdvplan();
			setq18Act(false);
			setq18autr();
		}
	};

	const [q18autr, setq18autr] = useState();
	const [q18Act, setq18Act] = useState(false);
	const q18autreCheck = (e) => {
		const value = e.target.value;
		if (!q18Act) {
			//console.log("q18 autre",value);
			setq18autr(value);
			setq18Act(true);
			setq18OuiAactivator(true);
		} else {
			//console.log("q18 autre",value);
			setq18autr();
			setq18Act(false);
			setq18OuiAactivator(false);
		}
	};

	const [q19bool, setq19bool] = useState(false);
	const q19Check = (e) => {
		const value = e.target.value;
		if (value === "A") {
			//console.log('q19',value);
			setq19value(value);
			setq19activator(false);
			setq19bool(true);
		} else {
			setq19activator(true);
			//console.log('q19',value);
			setq19value(value);
			setq19bool(true);

			setsoinsMedicaux2();
			setsoinsMedicaux2Checked(false);
			setsoinsMedicauxDans72heures();
			setsoinsMedicauxDans72heuresChecked(false);
			setassistancePsychosocial2();
			setassistancePsychosocial2Checked(false);
			setassistanceEnMediation2();
			setassistanceEnMediation2Checked(false);
			setassistanceJuri();
			setassistanceJuriChecked(false);
			setassistanceJuriPourDenoncer();
			setassistanceJuriPourDenoncerChecked(false);
			setassistanceSocioEconomique2();
			setassistanceSocioEconomique2Checked(false);
			setdvPlanDeSecurite();
			setdvPlanDeSecuriteChecked(false);
			setq19autr();
			setq19Act(false);
		}
	};
	//q19autre controler
	const [q19autr, setq19autr] = useState();
	const [q19Act, setq19Act] = useState(false);
	const q19autreCheck = (e) => {
		const value = e.target.value;
		if (!q19Act) {
			//console.log("q19 autre",value);
			setq19autr(value);
			setq19activator(true);
			setq19Act(true);
		} else {
			//console.log("q19 autre",value);
			setq19autr();
			setq19Act(false);
			setq19activator(false);
		}
	};

	//q20autr controler

	const [q20autr, setq20autr] = useState();
	const [q20Act, setq20Act] = useState(false);
	const q20autreCheck = (e) => {
		const value = e.target.value;
		if (!q20Act) {
			//console.log("q20 autre",value);
			setq20autr(value);
			setq20Act(true);
			setq20activator(true);
		} else {
			//console.log("q20 autre",value);
			setq20autr();
			setq20Act(false);
			setq20activator(false);
		}
	};

	//q21autr controler

	const [q21autr, setq21autr] = useState();
	const [q21Act, setq21Act] = useState(false);
	const q21autreCheck = (e) => {
		const value = e.target.value;
		if (!q21Act) {
			//console.log("q21 autre",value);
			setq21autr(value);
			setq21Act(true);
			setq21activator(true);
		} else {
			//console.log("q21 autre",value);
			setq21autr();
			setq21Act(false);
			setq21activator(false);
		}
	};

	//   const q22Check = (e)=>{
	//     const value = e.target.value
	//     if(value==="A"){
	//         //console.log('q22',value);
	//     setq22value(value)
	//     }else{
	//         //console.log('q22',value);
	//         setq22value(value)
	//         setsoinsMedicaux5()
	//         setsoinsMedicaux5Checked(false)
	//         setsoinsMedicauxDans72heures4()
	//          setsoinsMedicauxDans72heures4Checked(false)
	//          setassistancePsychosocial5()
	//          setassistancePsychosocial5Checked(false)
	//          setassistanceEnMediation5()
	//          setassistanceEnMediation5Checked(false)
	//          setassistanceJuri4()
	//          setassistanceJuri4Checked(false)
	//          setassistanceJuriPourDenoncer4()
	//          setassistanceJuriPourDenoncer4Checked(false)
	//          setassistanceSocioEconomique5()
	//          setassistanceSocioEconomique5Checked(false)
	//          setdvPlanDeSecurite4()
	//          setdvPlanDeSecurite4Checked(false)

	//     }
	//   }

	const [q22autr, setq22autr] = useState();
	const [q22Act, setq22Act] = useState(false);
	const q22autreCheck = (e) => {
		const value = e.target.value;
		if (!q22Act) {
			//console.log("q22 autre",value);
			setq22autr(value);
			setq22Act(true);
			setq21activator(true);
		} else {
			//console.log("q22 autre",value);
			setq22autr();
			setq22Act(false);
			setq21activator(false);
		}
	};
	const [q16ouibool, setq16ouibool] = useState(false);

	const [q16bool, setq16bool] = useState(false);
	const q16Check = (e) => {
		const value = e.target.value;
		//console.log('q16',value);
		setq16value(value);
		if (value === "B") {
			setq16Ouivalue("");
			setq16bool(true);
			setq16ouibool(true);
		} else if (value === "A") {
			setq16bool(true);
			setq16ouibool(false);
		}
		//setq16bool(true)
	};
	const q16OuiCheck = (e) => {
		const value = e.target.value;
		//console.log('q16Oui',value);
		setq16ouibool(true);
		setq16Ouivalue(value);
	};

	const [q17bool, setq17bool] = useState(false);
	const q17Check = (e) => {
		const value = e.target.value;
		//console.log('q17',value);
		setq17value(value);
		setq17bool(true);
	};

	const [q23bool, setq23bool] = useState(false);
	const q23Check = (e) => {
		const value = e.target.value;
		//console.log('q21',value);
		setq23value(value);
		setq23bool(true);
	};

	const [q24bool, setq24bool] = useState(false);
	const q24Check = (e) => {
		const value = e.target.value;
		//console.log('q22',value);
		if (value === "A") {
			setq24avalue("");
			setq24bool(true);
		} else {
			setq24bool(true);
		}
		setq24value(value);
	};

	const q24aCheck = (e) => {
		const value = e.target.value;
		//console.log('q22a',value);
		setq24avalue(value);
	};
	const [q25bool, setq25bool] = useState(false);
	const q25Check = (e) => {
		const value = e.target.value;
		if (value === "B") {
			//console.log('q23',value);
			setq25value(value);
			setq27value();
			setq25bool(true);
		} else {
			//console.log('q23',value);
			setq25value(value);
			setq25bool(true);
		}
	};

	const q27Check = (e) => {
		const value = e.target.value;
		//console.log('q27',value);
		setq27value(value);
	};

	const { register, handleSubmit, reset } = useForm();

	const onSubmit = (data, e) => {
		e.preventDefault();
		// //console.log(data);

		if (
			q16bool !== false &&
			q16ouibool !== false &&
			q18OuiAactivator !== false &&
			q19activator !== false &&
			q17bool !== false &&
			q18bool !== false &&
			q19bool !== false &&
			q19activator !== false &&
			q20activator !== false &&
			q21activator !== false &&
			q22activator !== false &&
			q23bool !== false &&
			q24bool !== false &&
			q25bool !== false
		) {
			if (q25value === "B") {
				//console.log(data);
				const need = [
					{
						q16: q16value,
						q16bool: q16bool,
						q16ouibool: q16ouibool,
						q16a: q16Ouivalue,
						q17: q17value,
						q17bool: q17bool,
						q18: q18value,
						q18bool: q18bool,
						q18a: [
							`${soinsMedicaux !== null ? soinsMedicaux : ""}`,
							`${
								assistancePsychosocial !== null ? assistancePsychosocial : ""
							}`,
							`${assistanceJuridique !== null ? assistanceJuridique : ""}`,
							`${assisstMed !== null ? assisstMed : ""}`,
							`${
								denonciationDuCasAvecSuite !== null
									? denonciationDuCasAvecSuite
									: ""
							}`,
							`${
								denonciationDuCasSansSuite !== null
									? denonciationDuCasSansSuite
									: ""
							}`,
							`${assistSocio !== null ? assistSocio : ""}`,
							`${dvplan !== null ? dvplan : ""}`,
						],
						//
						//
						q18abool: q18OuiAactivator,
						q18ap: [
							`${soinsMedicaux !== null ? soinsMedicaux : ""}`,
							`${
								assistancePsychosocial !== null ? assistancePsychosocial : ""
							}`,
							`${assistanceJuridique !== null ? assistanceJuridique : ""}`,
							`${assisstMed !== null ? assisstMed : ""}`,
							`${
								denonciationDuCasAvecSuite !== null
									? denonciationDuCasAvecSuite
									: ""
							}`,
							`${
								denonciationDuCasSansSuite !== null
									? denonciationDuCasSansSuite
									: ""
							}`,
							`${assistSocio !== null ? assistSocio : ""}`,
							`${dvplan !== null ? dvplan : ""}`,
						]
							.toString()
							.replace(/['"]+/g, ""),
						q18autre: data.q18autre !== null ? data.q18autre : "",
						q18b: data.q18b !== null ? data.q18b : "",
						q18c: data.q18c !== null ? data.q18c : "",
						q19: q19value,
						q19bool: q19bool,
						q19oui: [
							`${soinsMedicaux2 !== null ? soinsMedicaux2 : ""}`,
							`${
								soinsMedicauxDans72heures !== null
									? soinsMedicauxDans72heures
									: ""
							}`,
							`${
								assistancePsychosocial2 !== null ? assistancePsychosocial2 : ""
							}`,
							`${
								assistanceEnMediation2 !== null ? assistanceEnMediation2 : ""
							}`,
							`${assistanceJuri !== null ? assistanceJuri : ""}`,
							`${
								assistanceJuriPourDenoncer !== null
									? assistanceJuriPourDenoncer
									: ""
							}`,
							`${
								assistanceJuriPourDenoncerCivile !== null
									? assistanceJuriPourDenoncerCivile
									: ""
							}`,
							`${
								assistanceSocioEconomique2 !== null
									? assistanceSocioEconomique2
									: ""
							}`,
							`${dvPlanDeSecurite !== null ? dvPlanDeSecurite : ""}`,
						],
						//
						q19ouibool: q19activator,
						q19ouip: [
							`${soinsMedicaux2 !== null ? soinsMedicaux2 : ""}`,
							`${
								soinsMedicauxDans72heures !== null
									? soinsMedicauxDans72heures
									: ""
							}`,
							`${
								assistancePsychosocial2 !== null ? assistancePsychosocial2 : ""
							}`,
							`${
								assistanceEnMediation2 !== null ? assistanceEnMediation2 : ""
							}`,
							`${assistanceJuri !== null ? assistanceJuri : ""}`,
							`${
								assistanceJuriPourDenoncer !== null
									? assistanceJuriPourDenoncer
									: ""
							}`,
							`${
								assistanceJuriPourDenoncerCivile !== null
									? assistanceJuriPourDenoncerCivile
									: ""
							}`,
							`${
								assistanceSocioEconomique2 !== null
									? assistanceSocioEconomique2
									: ""
							}`,
							`${dvPlanDeSecurite !== null ? dvPlanDeSecurite : ""}`,
						]
							.toString()
							.replace(/['"]+/g, ""),
						q19ouiAutre: data.q19autre !== null ? data.q19autre : "",
						q20bool: q20activator,
						q20: [
							`${soinsMedicaux3 !== null ? soinsMedicaux3 : ""}`,
							`${
								soinsMedicauxDans72heures2 !== null
									? soinsMedicauxDans72heures2
									: ""
							}`,
							`${
								assistancePsychosocial3 !== null ? assistancePsychosocial3 : ""
							}`,
							`${
								assistanceEnMediationFam3 !== null
									? assistanceEnMediationFam3
									: ""
							}`,
							`${assistanceJuri2 !== null ? assistanceJuri2 : ""}`,
							`${
								assistanceJuriPourDenoncer2 !== null
									? assistanceJuriPourDenoncer2
									: ""
							}`,
							`${
								assistanceJuriPourDenoncer2Civile !== null
									? assistanceJuriPourDenoncer2Civile
									: ""
							}`,
							`${
								assistanceSocioEconomique3 !== null
									? assistanceSocioEconomique3
									: ""
							}`,
							`${dvPlanDeSecurite2 !== null ? dvPlanDeSecurite2 : ""}`,
						],
						//
						q20p: [
							`${soinsMedicaux3 !== null ? soinsMedicaux3 : ""}`,
							`${
								soinsMedicauxDans72heures2 !== null
									? soinsMedicauxDans72heures2
									: ""
							}`,
							`${
								assistancePsychosocial3 !== null ? assistancePsychosocial3 : ""
							}`,
							`${
								assistanceEnMediationFam3 !== null
									? assistanceEnMediationFam3
									: ""
							}`,
							`${assistanceJuri2 !== null ? assistanceJuri2 : ""}`,
							`${
								assistanceJuriPourDenoncer2 !== null
									? assistanceJuriPourDenoncer2
									: ""
							}`,
							`${
								assistanceJuriPourDenoncer2Civile !== null
									? assistanceJuriPourDenoncer2Civile
									: ""
							}`,
							`${
								assistanceSocioEconomique3 !== null
									? assistanceSocioEconomique3
									: ""
							}`,
							`${dvPlanDeSecurite2 !== null ? dvPlanDeSecurite2 : ""}`,
						]
							.toString()
							.replace(/['"]+/g, ""),
						q20autre: data.q20autre !== null ? data.q20autre : "",
						q21bool: q21activator,
						q21: [
							`${soinsMedicaux4 !== null ? soinsMedicaux4 : ""}`,
							`${
								soinsMedicauxDans72heures3 !== null
									? soinsMedicauxDans72heures3
									: ""
							}`,
							`${
								assistancePsychosocial4 !== null ? assistancePsychosocial4 : ""
							}`,
							`${
								assistanceEnMediation4 !== null ? assistanceEnMediation4 : ""
							}`,
							`${assistanceJuri3 !== null ? assistanceJuri3 : ""}`,
							`${
								assistanceJuriPourDenoncer3 !== null
									? assistanceJuriPourDenoncer3
									: ""
							}`,
							`${
								assistanceJuriPourDenoncer3Civile !== null
									? assistanceJuriPourDenoncer3Civile
									: ""
							}`,
							`${
								assistanceSocioEconomique4 !== null
									? assistanceSocioEconomique4
									: ""
							}`,
							`${dvPlanDeSecurite3 !== null ? dvPlanDeSecurite3 : ""}`,
							`${serviceDemande !== null ? serviceDemande : ""}`,
						],
						//
						q21p: [
							`${soinsMedicaux4 !== null ? soinsMedicaux4 : ""}`,
							`${
								soinsMedicauxDans72heures3 !== null
									? soinsMedicauxDans72heures3
									: ""
							}`,
							`${
								assistancePsychosocial4 !== null ? assistancePsychosocial4 : ""
							}`,
							`${
								assistanceEnMediation4 !== null ? assistanceEnMediation4 : ""
							}`,
							`${assistanceJuri3 !== null ? assistanceJuri3 : ""}`,
							`${
								assistanceJuriPourDenoncer3 !== null
									? assistanceJuriPourDenoncer3
									: ""
							}`,
							`${
								assistanceJuriPourDenoncer3Civile !== null
									? assistanceJuriPourDenoncer3Civile
									: ""
							}`,
							`${
								assistanceSocioEconomique4 !== null
									? assistanceSocioEconomique4
									: ""
							}`,
							`${dvPlanDeSecurite3 !== null ? dvPlanDeSecurite3 : ""}`,
							`${serviceDemande !== null ? serviceDemande : ""}`,
						]
							.toString()
							.replace(/['"]+/g, ""),
						q21autre: data.q21autre !== null ? data.q21autre : "",
						q22bool: q22activator,
						q22oui: [
							`${soinsMedicaux5 !== null ? soinsMedicaux5 : ""}`,
							`${
								soinsMedicauxDans72heures4 !== null
									? soinsMedicauxDans72heures4
									: ""
							}`,
							`${
								assistancePsychosocial5 !== null ? assistancePsychosocial5 : ""
							}`,
							`${
								assistanceEnMediation5 !== null ? assistanceEnMediation5 : ""
							}`,
							`${assistanceJuri4 !== null ? assistanceJuri4 : ""}`,
							`${
								assistanceJuriPourDenoncer4 !== null
									? assistanceJuriPourDenoncer4
									: ""
							}`,
							`${
								assistanceJuriPourDenoncer4Civile !== null
									? assistanceJuriPourDenoncer4Civile
									: ""
							}`,
							`${
								assistanceSocioEconomique5 !== null
									? assistanceSocioEconomique5
									: ""
							}`,
							`${dvPlanDeSecurite4 !== null ? dvPlanDeSecurite4 : ""}`,
							`${patenaireDeRef !== null ? patenaireDeRef : ""}`,
						],
						//
						q22ouip: [
							`${soinsMedicaux5 !== null ? soinsMedicaux5 : ""}`,
							`${
								soinsMedicauxDans72heures4 !== null
									? soinsMedicauxDans72heures4
									: ""
							}`,
							`${
								assistancePsychosocial5 !== null ? assistancePsychosocial5 : ""
							}`,
							`${
								assistanceEnMediation5 !== null ? assistanceEnMediation5 : ""
							}`,
							`${assistanceJuri4 !== null ? assistanceJuri4 : ""}`,
							`${
								assistanceJuriPourDenoncer4 !== null
									? assistanceJuriPourDenoncer4
									: ""
							}`,
							`${
								assistanceJuriPourDenoncer4Civile !== null
									? assistanceJuriPourDenoncer4Civile
									: ""
							}`,
							`${
								assistanceSocioEconomique5 !== null
									? assistanceSocioEconomique5
									: ""
							}`,
							`${dvPlanDeSecurite4 !== null ? dvPlanDeSecurite4 : ""}`,
							`${patenaireDeRef !== null ? patenaireDeRef : ""}`,
						]
							.toString()
							.replace(/['"]+/g, ""),
						q22autre: data.q22autre !== null ? data.q22autre : "",
						q23: q23value !== null ? q23value : "",
						q23bool: q23bool,
						q24: q24value !== null ? q24value : "",
						q24bool: q24bool,
						q24a: q24avalue !== null ? q24avalue : "",
						q25: q25value !== null ? q25value : "",
						q25bool: q25bool,
						q26: q25value !== "B" ? data.q26 : "",
						q27: q27value !== null ? q27value : "",
					},
				];
				//console.log(need);
				sessionStorage.setItem("need", JSON.stringify(need));
				if (orgData !== null) {
					handleSauvegarde();
				} else {
					handleSuivant();
				}
			} else if (data.q26 !== null && q27value !== null) {
				//console.log(data);
				const need = [
					{
						q16: q16value,
						q16bool: q16bool,
						q16a: q16Ouivalue,
						//q16bool: q16bool,
						q16ouibool: q16ouibool,
						q18abool: q18OuiAactivator,
						q17: q17value,
						q17bool: q17bool,
						q18: q18value,
						q18bool: q18bool,
						q18a: [
							`${soinsMedicaux !== null ? soinsMedicaux : ""}`,
							`${
								assistancePsychosocial !== null ? assistancePsychosocial : ""
							}`,
							`${assistanceJuridique !== null ? assistanceJuridique : ""}`,
							`${assisstMed !== null ? assisstMed : ""}`,
							`${
								denonciationDuCasAvecSuite !== null
									? denonciationDuCasAvecSuite
									: ""
							}`,
							`${
								denonciationDuCasSansSuite !== null
									? denonciationDuCasSansSuite
									: ""
							}`,
							`${assistSocio !== null ? assistSocio : ""}`,
							`${dvplan !== null ? dvplan : ""}`,
						],
						//
						q18ap: [
							`${soinsMedicaux !== null ? soinsMedicaux : ""}`,
							`${
								assistancePsychosocial !== null ? assistancePsychosocial : ""
							}`,
							`${assistanceJuridique !== null ? assistanceJuridique : ""}`,
							`${assisstMed !== null ? assisstMed : ""}`,
							`${
								denonciationDuCasAvecSuite !== null
									? denonciationDuCasAvecSuite
									: ""
							}`,
							`${
								denonciationDuCasSansSuite !== null
									? denonciationDuCasSansSuite
									: ""
							}`,
							`${assistSocio !== null ? assistSocio : ""}`,
							`${dvplan !== null ? dvplan : ""}`,
						]
							.toString()
							.replace(/['"]+/g, ""),
						q18autre: data.q18autre,
						q18b: data.q18b,
						q18c: data.q18c,
						q19: q19value,
						q19bool: q19bool,
						q19oui: [
							`${soinsMedicaux2 !== null ? soinsMedicaux2 : ""}`,
							`${
								soinsMedicauxDans72heures !== null
									? soinsMedicauxDans72heures
									: ""
							}`,
							`${
								assistancePsychosocial2 !== null ? assistancePsychosocial2 : ""
							}`,
							`${
								assistanceEnMediation2 !== null ? assistanceEnMediation2 : ""
							}`,
							`${assistanceJuri !== null ? assistanceJuri : ""}`,
							`${
								assistanceJuriPourDenoncer !== null
									? assistanceJuriPourDenoncer
									: ""
							}`,
							`${
								assistanceJuriPourDenoncerCivile !== null
									? assistanceJuriPourDenoncerCivile
									: ""
							}`,
							`${
								assistanceSocioEconomique2 !== null
									? assistanceSocioEconomique2
									: ""
							}`,
							`${dvPlanDeSecurite !== null ? dvPlanDeSecurite : ""}`,
						],
						//
						q19ouip: [
							`${soinsMedicaux2 !== null ? soinsMedicaux2 : ""}`,
							`${
								soinsMedicauxDans72heures !== null
									? soinsMedicauxDans72heures
									: ""
							}`,
							`${
								assistancePsychosocial2 !== null ? assistancePsychosocial2 : ""
							}`,
							`${
								assistanceEnMediation2 !== null ? assistanceEnMediation2 : ""
							}`,
							`${assistanceJuri !== null ? assistanceJuri : ""}`,
							`${
								assistanceJuriPourDenoncer !== null
									? assistanceJuriPourDenoncer
									: ""
							}`,
							`${
								assistanceJuriPourDenoncerCivile !== null
									? assistanceJuriPourDenoncerCivile
									: ""
							}`,
							`${
								assistanceSocioEconomique2 !== null
									? assistanceSocioEconomique2
									: ""
							}`,
							`${dvPlanDeSecurite !== null ? dvPlanDeSecurite : ""}`,
						]
							.toString()
							.replace(/['"]+/g, ""),
						q19ouibool: q19activator,
						q19ouiAutre: data.q19autre !== null ? data.q19autre : "",
						q20: [
							`${soinsMedicaux3 !== null ? soinsMedicaux3 : ""}`,
							`${
								soinsMedicauxDans72heures2 !== null
									? soinsMedicauxDans72heures2
									: ""
							}`,
							`${
								assistancePsychosocial3 !== null ? assistancePsychosocial3 : ""
							}`,
							`${
								assistanceEnMediationFam3 !== null
									? assistanceEnMediationFam3
									: ""
							}`,
							`${assistanceJuri2 !== null ? assistanceJuri2 : ""}`,
							`${
								assistanceJuriPourDenoncer2 !== null
									? assistanceJuriPourDenoncer2
									: ""
							}`,
							`${
								assistanceJuriPourDenoncer2Civile !== null
									? assistanceJuriPourDenoncer2Civile
									: ""
							}`,
							`${
								assistanceSocioEconomique3 !== null
									? assistanceSocioEconomique3
									: ""
							}`,
							`${dvPlanDeSecurite2 !== null ? dvPlanDeSecurite2 : ""}`,
						],
						//
						q20bool: q20activator,
						q20p: [
							`${soinsMedicaux3 !== null ? soinsMedicaux3 : ""}`,
							`${
								soinsMedicauxDans72heures2 !== null
									? soinsMedicauxDans72heures2
									: ""
							}`,
							`${
								assistancePsychosocial3 !== null ? assistancePsychosocial3 : ""
							}`,
							`${
								assistanceEnMediationFam3 !== null
									? assistanceEnMediationFam3
									: ""
							}`,
							`${assistanceJuri2 !== null ? assistanceJuri2 : ""}`,
							`${
								assistanceJuriPourDenoncer2 !== null
									? assistanceJuriPourDenoncer2
									: ""
							}`,
							`${
								assistanceJuriPourDenoncer2Civile !== null
									? assistanceJuriPourDenoncer2Civile
									: ""
							}`,
							`${
								assistanceSocioEconomique3 !== null
									? assistanceSocioEconomique3
									: ""
							}`,
							`${dvPlanDeSecurite2 !== null ? dvPlanDeSecurite2 : ""}`,
						]
							.toString()
							.replace(/['"]+/g, ""),
						q20autre: data.q20autre !== null ? data.q20autre : "",
						q21bool: q21activator,

						q21: [
							`${soinsMedicaux4 !== null ? soinsMedicaux4 : ""}`,
							`${
								soinsMedicauxDans72heures3 !== null
									? soinsMedicauxDans72heures3
									: ""
							}`,
							`${
								assistancePsychosocial4 !== null ? assistancePsychosocial4 : ""
							}`,
							`${
								assistanceEnMediation4 !== null ? assistanceEnMediation4 : ""
							}`,
							`${assistanceJuri3 !== null ? assistanceJuri3 : ""}`,
							`${
								assistanceJuriPourDenoncer3 !== null
									? assistanceJuriPourDenoncer3
									: ""
							}`,
							`${
								assistanceJuriPourDenoncer3Civile !== null
									? assistanceJuriPourDenoncer3Civile
									: ""
							}`,
							`${
								assistanceSocioEconomique4 !== null
									? assistanceSocioEconomique4
									: ""
							}`,
							`${dvPlanDeSecurite3 !== null ? dvPlanDeSecurite3 : ""}`,
							`${serviceDemande !== null ? serviceDemande : ""}`,
						],
						//
						q21p: [
							`${soinsMedicaux4 !== null ? soinsMedicaux4 : ""}`,
							`${
								soinsMedicauxDans72heures3 !== null
									? soinsMedicauxDans72heures3
									: ""
							}`,
							`${
								assistancePsychosocial4 !== null ? assistancePsychosocial4 : ""
							}`,
							`${
								assistanceEnMediation4 !== null ? assistanceEnMediation4 : ""
							}`,
							`${assistanceJuri3 !== null ? assistanceJuri3 : ""}`,
							`${
								assistanceJuriPourDenoncer3 !== null
									? assistanceJuriPourDenoncer3
									: ""
							}`,
							`${
								assistanceJuriPourDenoncer3Civile !== null
									? assistanceJuriPourDenoncer3Civile
									: ""
							}`,
							`${
								assistanceSocioEconomique4 !== null
									? assistanceSocioEconomique4
									: ""
							}`,
							`${dvPlanDeSecurite3 !== null ? dvPlanDeSecurite3 : ""}`,
							`${serviceDemande !== null ? serviceDemande : ""}`,
						]
							.toString()
							.replace(/['"]+/g, ""),
						q21autre: data.q21autre !== null ? data.q21autre : "",
						q22oui: [
							`${soinsMedicaux5 !== null ? soinsMedicaux5 : ""}`,
							`${
								soinsMedicauxDans72heures4 !== null
									? soinsMedicauxDans72heures4
									: ""
							}`,
							`${
								assistancePsychosocial5 !== null ? assistancePsychosocial5 : ""
							}`,
							`${
								assistanceEnMediation5 !== null ? assistanceEnMediation5 : ""
							}`,
							`${assistanceJuri4 !== null ? assistanceJuri4 : ""}`,
							`${
								assistanceJuriPourDenoncer4 !== null
									? assistanceJuriPourDenoncer4
									: ""
							}`,
							`${
								assistanceJuriPourDenoncer4Civile !== null
									? assistanceJuriPourDenoncer4Civile
									: ""
							}`,
							`${
								assistanceSocioEconomique5 !== null
									? assistanceSocioEconomique5
									: ""
							}`,
							`${dvPlanDeSecurite4 !== null ? dvPlanDeSecurite4 : ""}`,
							`${patenaireDeRef !== null ? patenaireDeRef : ""}`,
						],
						//
						q22bool: q22activator,
						q22ouip: [
							`${soinsMedicaux5 !== null ? soinsMedicaux5 : ""}`,
							`${
								soinsMedicauxDans72heures4 !== null
									? soinsMedicauxDans72heures4
									: ""
							}`,
							`${
								assistancePsychosocial5 !== null ? assistancePsychosocial5 : ""
							}`,
							`${
								assistanceEnMediation5 !== null ? assistanceEnMediation5 : ""
							}`,
							`${assistanceJuri4 !== null ? assistanceJuri4 : ""}`,
							`${
								assistanceJuriPourDenoncer4 !== null
									? assistanceJuriPourDenoncer4
									: ""
							}`,
							`${
								assistanceJuriPourDenoncer4Civile !== null
									? assistanceJuriPourDenoncer4Civile
									: ""
							}`,
							`${
								assistanceSocioEconomique5 !== null
									? assistanceSocioEconomique5
									: ""
							}`,
							`${dvPlanDeSecurite4 !== null ? dvPlanDeSecurite4 : ""}`,
							`${patenaireDeRef !== null ? patenaireDeRef : ""}`,
						]
							.toString()
							.replace(/['"]+/g, ""),
						q22autre: data.q22autre !== null ? data.q22autre : "",
						q23: q23value !== null ? q23value : "",
						q23bool: q23bool,
						q24: q24value !== null ? q24value : "",
						q24bool: q24bool,
						q24a: q24avalue !== null ? q24avalue : "",
						q25: q25value !== null ? q25value : "",
						q25bool: q25bool,
						q26: data.q26 !== null ? data.q26 : "",
						q27: q27value !== null ? q27value : "",
					},
				];
				//console.log(need);
				sessionStorage.setItem("need", JSON.stringify(need));
				if (orgData !== null) {
					handleSauvegarde();
				} else {
					handleSuivant();
				}
			} else {
				//console.log('no data');
			}
		} else {
			setmsg(
				"Question sans réponse détectée. Veuillez vérifier que vous avez répondu à toutes les questions avant de continuer."
			);
			handleSnackBar();
			//console.log('no data');
		}
	};

	const handleSuivant = () => {
		history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/main`);
	};
	const handleRetour = () => {
		history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/main/changes`);
	};

	const handleSauvegarde = () => {
		history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/main/changes`);
	};

	const getNeedAndCare = () => {
		const data = sessionStorage.getItem("need");
		////console.log('data from local storage',JSON.parse(data));
		return setneed(JSON.parse(data));
	};

	useEffect(() => {
		setorgData(location.data);
		getNeedAndCare();

		if (need !== null) {
			//
			setq16ouibool(need.map((e) => e.q16ouibool));
			setq18OuiAactivator(need.map((e) => e.q18abool));
			setq19activator(need.map((e) => e.q19ouibool));
			setq16bool(need.map((e) => e.q16bool));
			setq17bool(need.map((e) => e.q17bool));
			setq18bool(need.map((e) => e.q18bool));
			setq19bool(need.map((e) => e.q19bool));
			setq20activator(need.map((e) => e.q20bool));
			setq21activator(need.map((e) => e.q21bool));
			setq22activator(need.map((e) => e.q22bool));
			setq23bool(need.map((e) => e.q23bool));
			setq24bool(need.map((e) => e.q24bool));
			setq25bool(need.map((e) => e.q25bool));
			setq16value(need.map((e) => e.q16));
			setq16Ouivalue(need.map((e) => e.q16a));
			setq18Ouivalue(need.map((e) => e.q18a));
			setq17value(need.map((e) => e.q17));
			setq18value(need.map((e) => e.q18));
			setq19value(need.map((e) => e.q19));
			setq22value(need.map((e) => e.q22));
			setq23value(need.map((e) => e.q23));
			setq24value(need.map((e) => e.q24));
			setq24avalue(need.map((e) => e.q24a));
			setq25value(need.map((e) => e.q25));
			setq27value(need.map((e) => e.q27));
			reset({
				q18autre: need.map((e) => e.q18autre),
				q18b: need.map((e) => e.q18b),
				q18c: need.map((e) => e.q18c),
				q19ouiAutre: need.map((e) => e.q19ouiAutre),
				q20autre: need.map((e) => e.q20autre),
				q21autre: need.map((e) => e.q21autre),
				q22autre: need.map((e) => e.q22autre),
				q26: need.map((e) => e.q26),
			});

			const q18autre = need.map((e) => e.q18autre);
			if (q18autre === "") {
				setq18Act(false);
			} else {
				setq18Act(true);
			}
			const q19Autre = need.map((e) => e.q19ouiAutre);
			if (q19Autre === "") {
				setq19Act(false);
			} else {
				setq19Act(true);
			}
			const q20autre = need.map((e) => e.q20autre);
			if (q20autre === "") {
				setq20Act(false);
			} else {
				setq20Act(true);
			}
			const q21autre = need.map((e) => e.q21autre);
			if (q21autre === "") {
				setq21Act(false);
			} else {
				setq21Act(true);
			}

			const q22autre = need.map((e) => e.q22autre);

			if (q22autre === "") {
				setq22Act(false);
			} else {
				setq22Act(true);
			}

			//q18 initial values
			const soinsMed = need.map((e) => e.q18a[0]);
			if (soinsMed === "A") {
				setsoinsMedicauxChecked(true);
				setsoinsMedicaux(soinsMed);

				setq18OuiAactivator(true);
			} else {
				setsoinsMedicauxChecked(false);
				setsoinsMedicaux();

				setq18OuiAactivator(true);
			}

			const assistPsy = need.map((e) => e.q18a[1]);
			if (assistPsy === "B") {
				setassistancePsychosocialChecked(true);
				setassistancePsychosocial(assistPsy);

				setq18OuiAactivator(true);
			} else {
				setassistancePsychosocialChecked(false);
				setassistancePsychosocial();

				setq18OuiAactivator(true);
			}
			const assistMe = need.map((e) => e.q18a[2]);
			if (assistMe === "C") {
				setassisstMedChecked(true);
				setassisstMed(assistMe);

				setq18OuiAactivator(true);
			} else {
				setassisstMedChecked(false);
				setassisstMed();

				setq18OuiAactivator(true);
			}

			const assistJuri = need.map((e) => e.q18a[3]);
			if (assistJuri === "D") {
				setassistanceJuridiqueChecked(true);
				setassistanceJuridique(assistJuri);

				setq18OuiAactivator(true);
			} else {
				setassistanceJuridiqueChecked(false);
				setassistanceJuridique();

				setq18OuiAactivator(true);
			}

			const denoDuCasAvecSuite = need.map((e) => e.q18a[4]);
			if (denoDuCasAvecSuite === "E") {
				setdenonciationDuCasAvecSuiteChecked(true);
				setdenonciationDuCasAvecSuite(denoDuCasAvecSuite);

				setq18OuiAactivator(true);
			} else {
				setdenonciationDuCasAvecSuiteChecked(false);
				setdenonciationDuCasAvecSuite();

				setq18OuiAactivator(true);
			}
			const denoDuCasSansSuite = need.map((e) => e.q18a[5]);
			if (denoDuCasSansSuite === "F") {
				setdenonciationDuCasSansSuiteChecked(true);
				setdenonciationDuCasSansSuite(denoDuCasSansSuite);

				setq18OuiAactivator(true);
			} else {
				setdenonciationDuCasSansSuiteChecked(false);
				setdenonciationDuCasSansSuite();

				setq18OuiAactivator(true);
			}

			const assistSoci = need.map((e) => e.q18a[6]);
			if (assistSoci === "G") {
				setassistSocioChecked(true);
				setassistSocio(assistSoci);

				setq18OuiAactivator(true);
			} else {
				setassistSocioChecked(false);
				setassistSocio();

				setq18OuiAactivator(true);
			}

			const dvpl = need.map((e) => e.q18a[7]);
			if (dvpl === "H") {
				setdvplanChecked(true);
				setdvplan(dvpl);

				setq18OuiAactivator(true);
			} else {
				setdvplanChecked(false);
				setdvplan();

				setq18OuiAactivator(true);
			}

			//q19 initial value

			const soinMed = need.map((e) => e.q19oui[0]);
			if (soinMed === "A") {
				setsoinsMedicaux2Checked(true);
				setsoinsMedicaux2(soinMed);
				setq19activator(true);
			} else {
				setsoinsMedicaux2Checked(false);
				setsoinsMedicaux2();
				setq19activator(true);
			}

			const soinMed72 = need.map((e) => e.q19oui[1]);
			if (soinMed72 === "B") {
				setsoinsMedicauxDans72heuresChecked(true);
				setsoinsMedicauxDans72heures(soinMed72);
				setq19activator(true);
			} else {
				setsoinsMedicauxDans72heuresChecked(false);
				setsoinsMedicauxDans72heures();
				setq19activator(true);
			}

			const asPsyco = need.map((e) => e.q19oui[2]);
			if (asPsyco === "C") {
				setassistancePsychosocial2Checked(true);
				setassistancePsychosocial2(asPsyco);
				setq19activator(true);
			} else {
				setassistancePsychosocial2Checked(false);
				setassistancePsychosocial2();
				setq19activator(true);
			}

			const asMed = need.map((e) => e.q19oui[3]);
			if (asMed === "D") {
				setassistanceEnMediation2Checked(true);
				setassistanceEnMediation2(asMed);
				setq19activator(true);
			} else {
				setassistanceEnMediation2Checked(false);
				setassistanceEnMediation2();
				setq19activator(true);
			}

			const asJuri = need.map((e) => e.q19oui[4]);
			if (asJuri === "E") {
				setassistanceJuriChecked(true);
				setassistanceJuri(asJuri);
				setq19activator(true);
			} else {
				setassistanceJuriChecked(false);
				setassistanceJuri();
				setq19activator(true);
			}

			const asJuriPrDen = need.map((e) => e.q19oui[5]);
			if (asJuriPrDen === "F") {
				setassistanceJuriPourDenoncerChecked(true);
				setassistanceJuriPourDenoncer(asJuriPrDen);
				setq19activator(true);
			} else {
				setassistanceJuriPourDenoncerChecked(false);
				setassistanceJuriPourDenoncer();
				setq19activator(true);
			}
			//added
			const asJuriPrDenCivile = need.map((e) => e.q19oui[6]);
			if (asJuriPrDenCivile === "G") {
				setassistanceJuriPourDenoncerCivile(asJuriPrDenCivile);
				setassistanceJuriPourDenoncerCivileChecked(true);
				setq19activator(true);
			} else {
				setassistanceJuriPourDenoncerCivile();
				setassistanceJuriPourDenoncerCivileChecked(false);
				setq19activator(true);
			}

			const asSocEcom = need.map((e) => e.q19oui[7]);
			if (asSocEcom === "H") {
				setassistanceSocioEconomique2Checked(true);
				setassistanceSocioEconomique2(asSocEcom);
				setq19activator(true);
			} else {
				setassistanceSocioEconomique2Checked(false);
				setassistanceSocioEconomique2();
				setq19activator(true);
			}

			const dvpln = need.map((e) => e.q19oui[8]);
			if (dvpln === "I") {
				setdvPlanDeSecuriteChecked(true);
				setdvPlanDeSecurite(dvpln);
				setq19activator(true);
			} else {
				setdvPlanDeSecuriteChecked(false);
				setdvPlanDeSecurite();
				setq19activator(true);
			}

			//q20 initial value

			const soinMed1 = need.map((e) => e.q20[0]);
			if (soinMed1 === "A") {
				setsoinsMedicaux3Checked(true);
				setsoinsMedicaux3(soinMed1);
				//setq20activator(true)
			} else {
				setsoinsMedicaux3Checked(false);
				setsoinsMedicaux3();
				//setq20activator(true)
			}

			const soinMed721 = need.map((e) => e.q20[1]);
			if (soinMed721 === "B") {
				setsoinsMedicauxDans72heures2Checked(true);
				setsoinsMedicauxDans72heures2(soinMed721);
				//setq20activator(true)
			} else {
				setsoinsMedicauxDans72heures2Checked(false);
				setsoinsMedicauxDans72heures2();
				//setq20activator(true)
			}

			const asPsyco1 = need.map((e) => e.q20[2]);
			if (asPsyco1 === "C") {
				setassistancePsychosocial3Checked(true);
				setassistancePsychosocial3(asPsyco1);
				//setq20activator(true)
			} else {
				setassistancePsychosocial3Checked(false);
				setassistancePsychosocial3();
				//setq20activator(true)
			}

			const asMed1 = need.map((e) => e.q20[3]);
			if (asMed1 === "D") {
				setassistanceEnMediationFam3Checked(true);
				setassistanceEnMediationFam3(asMed1);
				//setq20activator(true)
			} else {
				setassistanceEnMediationFam3Checked(false);
				setassistanceEnMediationFam3();
				//setq20activator(true)
			}

			const asJuri1 = need.map((e) => e.q20[4]);
			if (asJuri1 === "E") {
				setassistanceJuri2Checked(true);
				setassistanceJuri2(asJuri1);
				//setq20activator(true)
			} else {
				setassistanceJuri2Checked(false);
				setassistanceJuri2();
				//setq20activator(true)
			}

			const asJuriPrDen1 = need.map((e) => e.q20[5]);
			if (asJuriPrDen1 === "F") {
				setassistanceJuriPourDenoncer2Checked(true);
				setassistanceJuriPourDenoncer2(asJuriPrDen1);
				//setq20activator(true)
			} else {
				setassistanceJuriPourDenoncer2Checked(false);
				setassistanceJuriPourDenoncer2();
				//setq20activator(true)
			}

			//added
			const asJuriPrDenCivile1 = need.map((e) => e.q20[6]);
			if (asJuriPrDenCivile === "G") {
				setassistanceJuriPourDenoncer2Civile(asJuriPrDenCivile1);
				setassistanceJuriPourDenoncer2CivileChecked(true);
				//setq20activator(true)
			} else {
				setassistanceJuriPourDenoncer2Civile();
				setassistanceJuriPourDenoncer2CivileChecked(false);
				//setq20activator(true)
			}

			const asSocEcom1 = need.map((e) => e.q20[7]);
			if (asSocEcom1 === "H") {
				setassistanceSocioEconomique3Checked(true);
				setassistanceSocioEconomique3(asSocEcom1);
				//setq20activator(true)
			} else {
				setassistanceSocioEconomique3Checked(false);
				setassistanceSocioEconomique3();
				//setq20activator(true)
			}

			const dvpln1 = need.map((e) => e.q20[8]);
			if (dvpln1 === "I") {
				setdvPlanDeSecurite2Checked(true);
				setdvPlanDeSecurite2(dvpln1);
				//setq20activator(true)
			} else {
				setdvPlanDeSecurite2Checked(false);
				setdvPlanDeSecurite2();
				//setq20activator(true)
			}

			//q21 initial value

			const soinMed2 = need.map((e) => e.q21[0]);
			if (soinMed2 === "A") {
				setsoinsMedicaux4Checked(true);
				setsoinsMedicaux4(soinMed2);
				//setq21activator(true)
			} else {
				setsoinsMedicaux4Checked(false);
				setsoinsMedicaux4();
				//setq21activator(true)
			}

			const soinMed722 = need.map((e) => e.q21[1]);
			if (soinMed722 === "B") {
				setsoinsMedicauxDans72heures3Checked(true);
				setsoinsMedicauxDans72heures3(soinMed722);
				//setq21activator(true)
			} else {
				setsoinsMedicauxDans72heures3Checked(false);
				setsoinsMedicauxDans72heures3();
				//setq21activator(true)
			}

			const asPsyco2 = need.map((e) => e.q21[2]);
			if (asPsyco2 === "C") {
				setassistancePsychosocial4Checked(true);
				setassistancePsychosocial4(asPsyco2);
				//setq21activator(true)
			} else {
				setassistancePsychosocial4Checked(false);
				setassistancePsychosocial4();
				//setq21activator(true)
			}

			const asMed2 = need.map((e) => e.q21[3]);
			if (asMed2 === "D") {
				setassistanceEnMediation4Checked(true);
				setassistanceEnMediation4(asMed2);
				//setq21activator(true)
			} else {
				setassistanceEnMediation4Checked(false);
				setassistanceEnMediation4();
				//setq21activator(true)
			}

			const asJuri2 = need.map((e) => e.q21[4]);
			if (asJuri2 === "E") {
				setassistanceJuri3Checked(true);
				setassistanceJuri3(asJuri2);
				//setq21activator(true)
			} else {
				setassistanceJuri3Checked(false);
				setassistanceJuri3();
				//setq21activator(true)
			}

			const asJuriPrDen2 = need.map((e) => e.q21[5]);
			if (asJuriPrDen2 === "F") {
				setassistanceJuriPourDenoncer3Checked(true);
				setassistanceJuriPourDenoncer3(asJuriPrDen2);
				//setq21activator(true)
			} else {
				setassistanceJuriPourDenoncer3Checked(false);
				setassistanceJuriPourDenoncer3();
				//setq21activator(true)
			}
			//added
			const asJuriPrDenCivile2 = need.map((e) => e.q21[6]);
			if (asJuriPrDenCivile === "G") {
				setassistanceJuriPourDenoncer3Civile(asJuriPrDenCivile2);
				setassistanceJuriPourDenoncer3CivileChecked(true);
				//setq21activator(true)
			} else {
				setassistanceJuriPourDenoncer3Civile();
				setassistanceJuriPourDenoncer3CivileChecked(false);
				//setq21activator(true)
			}

			const asSocEcom2 = need.map((e) => e.q21[7]);
			if (asSocEcom2 === "H") {
				setassistanceSocioEconomique4Checked(true);
				setassistanceSocioEconomique4(asSocEcom2);
				//setq21activator(true)
			} else {
				setassistanceSocioEconomique4Checked(false);
				setassistanceSocioEconomique4();
				//setq21activator(true)
			}

			const dvpln2 = need.map((e) => e.q21[8]);
			if (dvpln2 === "I") {
				setdvPlanDeSecurite3Checked(true);
				setdvPlanDeSecurite3(dvpln2);
				//setq21activator(true)
			} else {
				setdvPlanDeSecurite3Checked(false);
				setdvPlanDeSecurite3();
				//setq21activator(true)
			}

			const servDemande = need.map((e) => e.q21[9]);
			if (servDemande === "J") {
				setserviceDemandeChecked(true);
				setserviceDemande(servDemande);
				//setq21activator(true)
			} else {
				setserviceDemandeChecked(false);
				setserviceDemande();
				//setq21activator(true)
			}

			//q22 initial value

			const soinMed3 = need.map((e) => e.q22oui[0]);
			if (soinMed3 === "A") {
				setsoinsMedicaux5Checked(true);
				setsoinsMedicaux5(soinMed3);
				//setq22activator(true)
			} else {
				setsoinsMedicaux5Checked(false);
				setsoinsMedicaux5();
				//setq22activator(true)
			}

			const soinMed723 = need.map((e) => e.q22oui[1]);
			if (soinMed723 === "B") {
				setsoinsMedicauxDans72heures4Checked(true);
				setsoinsMedicauxDans72heures4(soinMed723);
				//setq22activator(true)
			} else {
				setsoinsMedicauxDans72heures4Checked(false);
				setsoinsMedicauxDans72heures4();
				//setq22activator(true)
			}

			const asPsyco3 = need.map((e) => e.q22oui[2]);
			if (asPsyco3 === "C") {
				setassistancePsychosocial5Checked(true);
				setassistancePsychosocial5(asPsyco3);
				//setq22activator(true)
			} else {
				setassistancePsychosocial5Checked(false);
				setassistancePsychosocial5();
				//setq22activator(true)
			}

			const asMed3 = need.map((e) => e.q22oui[3]);
			if (asMed3 === "D") {
				setassistanceEnMediation5Checked(true);
				setassistanceEnMediation5(asMed3);
				//setq22activator(true)
			} else {
				setassistanceEnMediation5Checked(false);
				setassistanceEnMediation5();
				//setq22activator(true)
			}

			const asJuri3 = need.map((e) => e.q22oui[4]);
			if (asJuri3 === "E") {
				setassistanceJuri4Checked(true);
				setassistanceJuri4(asJuri3);
				//setq22activator(true)
			} else {
				setassistanceJuri4Checked(false);
				setassistanceJuri4();
				//setq22activator(true)
			}

			const asJuriPrDen3 = need.map((e) => e.q22oui[5]);
			if (asJuriPrDen3 === "F") {
				setassistanceJuriPourDenoncer4Checked(true);
				setassistanceJuriPourDenoncer4(asJuriPrDen3);
				//setq22activator(true)
			} else {
				setassistanceJuriPourDenoncer4Checked(false);
				setassistanceJuriPourDenoncer4();
				//setq22activator(true)
			}
			//added
			const asJuriPrDenCivile3 = need.map((e) => e.q22oui[6]);
			if (asJuriPrDenCivile === "G") {
				setassistanceJuriPourDenoncer4Civile(asJuriPrDenCivile3);
				setassistanceJuriPourDenoncer4CivileChecked(true);
				//setq22activator(true)
			} else {
				setassistanceJuriPourDenoncer4Civile();
				setassistanceJuriPourDenoncer4CivileChecked(false);
				//setq22activator(true)
			}

			const asSocEcom3 = need.map((e) => e.q22oui[7]);
			if (asSocEcom3 === "H") {
				setassistanceSocioEconomique5Checked(true);
				setassistanceSocioEconomique5(asSocEcom3);
				//setq22activator(true)
			} else {
				setassistanceSocioEconomique5Checked(false);
				setassistanceSocioEconomique5();
				//setq22activator(true)
			}

			const dvpln3 = need.map((e) => e.q22oui[8]);
			if (dvpln3 === "I") {
				setdvPlanDeSecurite4Checked(true);
				setdvPlanDeSecurite4(dvpln3);
				//setq22activator(true)
			} else {
				setdvPlanDeSecurite4Checked(false);
				setdvPlanDeSecurite4();
				//setq22activator(true)
			}

			const partDeRef = need.map((e) => e.q22oui[9]);
			if (partDeRef === "J") {
				setpatenaireDeRefChecked(true);
				setpatenaireDeRef(partDeRef);
				//setq22activator(true)
			} else {
				setpatenaireDeRefChecked(false);
				setpatenaireDeRef();
				//setq22activator(true)
			}
			// setq23value(need.map(e=>e.q23))
			// setq24value(need.map(e=>e.q24))
			// setq24avalue(need.map(e=>e.q24a))
			// setq25value(need.map(e=>e.q25))
			// setq27value(need.map(e=>e.q27))
		}
	}, [orgData, need, location.data, reset]); // Just added need, location.data, reset
	// console.log(need);

	return (
		<div>
			<div className={classes.root}>
				<AppBar position="absolute" className={classes.appbar}>
					<Toolbar>
						{orgData !== null ? null : (
							<IconButton
								edge="start"
								className={classes.menuButton}
								color="inherit"
								aria-label="open drawer"
								component={Link}
								to="/collect"
							>
								{/* <ArrowBack/> */}
							</IconButton>
						)}
						<Typography
							className={classes.titleAppBar}
							variant="h6"
							noWrap
						></Typography>
						<div className={classes.search}>
							<div className={classes.searchIcon}></div>
							{orgData !== null ? (
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={select}
									fullWidth
									className={classes.select}
									onChange={handleChange}
								>
									<MenuItem value={6}>
										SITUATION ET BESIONS DE PRISE EN CHARGE DE LA / DU
										SURVIVANT(E)
									</MenuItem>
								</Select>
							) : (
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={select}
									fullWidth
									className={classes.select}
									onChange={handleChange}
								>
									<MenuItem
										value={2}
										component={Link}
										to={
											"/" + process.env.REACT_APP_COLLECT_LINK + "/collect/form"
										}
									>
										IDENTIFICATION DE L'ORGANISATION
									</MenuItem>
									<MenuItem
										value={3}
										component={Link}
										to={
											"/" +
											process.env.REACT_APP_COLLECT_LINK +
											"/collect/form/profile"
										}
									>
										PROFIL DE LA / DU SURVIVANT(E)
									</MenuItem>
									<MenuItem
										value={4}
										component={Link}
										to={
											"/" +
											process.env.REACT_APP_COLLECT_LINK +
											"/collect/form/info"
										}
									>
										INFORMATION SUR L'INCIDENT DE VIOLENCE DOMESTIQUE DOCUMENTÉ
									</MenuItem>
									<MenuItem
										value={5}
										component={Link}
										to={
											"/" +
											process.env.REACT_APP_COLLECT_LINK +
											"/collect/form/perpretor"
										}
									>
										PROFIL DE L'AUTEUR DE L'ACTE DE VIOLENCE DOMESTIQUE
									</MenuItem>
									<MenuItem
										value={6}
										component={Link}
										to={
											"/" +
											process.env.REACT_APP_COLLECT_LINK +
											"/collect/form/besion"
										}
									>
										SITUATION ET BESIONS DE PRISE EN CHARGE DE LA / DU
										SURVIVANT(E){" "}
									</MenuItem>
								</Select>
							)}
						</div>
					</Toolbar>
				</AppBar>
			</div>
			<div>
				<Grid container justifyContent="center" spacing={3}>
					<Grid item xs={11} sm={8}>
						<Typography
							xs={11}
							sm={8}
							className={classes.bigtitle}
							align="left"
						>
							{grp5.toUpperCase()}
						</Typography>
					</Grid>
				</Grid>
				<Grid container justifyContent="center" spacing={3}>
					<Grid item className={classes.content} xs={11} sm={8}>
						<Grid container direction="row">
							<Grid item sm={1} xs={0}></Grid>
							<Grid item sm={10} xs="12" className={classes.marginTop_40}>
								<form onSubmit={handleSubmit(onSubmit)}>
									<Grid container spacing={1} direction="row">
										<Grid item xs={11} sm={10} className={classes.marginTop_20}>
											<Typography className={classes.label}>{q16}</Typography>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.radiolabel}
												>
													<FormControlLabel
														checked={q16value === "B" ? true : false}
														value="B"
														onClick={q16Check}
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
													<FormControlLabel
														checked={q16value === "A" ? true : false}
														value="A"
														onClick={q16Check}
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
										<Grid item xs={11} sm={10} className={classes.marginTop_20}>
											{q16value === "A" ? (
												<FormControl component="fieldset">
													<Typography className={classes.subradio}>
														Si oui, les autorités ont-elles donné suite à la
														plainte :
													</Typography>
													<RadioGroup
														name="occupation"
														className={classes.radiolabel}
													>
														<FormControlLabel
															checked={q16Ouivalue === "B" ? true : false}
															value="B"
															onClick={q16OuiCheck}
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Non
																</Box>
															}
														/>
														<FormControlLabel
															checked={q16Ouivalue === "A" ? true : false}
															value="A"
															onClick={q16OuiCheck}
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Oui
																</Box>
															}
														/>
													</RadioGroup>
												</FormControl>
											) : null}
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										direction="row"
										className={classes.marginBottom_30}
									>
										<Grid item xs={11} sm={10} className={classes.marginTop_20}>
											<Typography className={classes.label}>{q17}</Typography>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.radiolabel}
												>
													<FormControlLabel
														checked={q17value === "B" ? true : false}
														value="B"
														onClick={q17Check}
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
													<FormControlLabel
														checked={q17value === "A" ? true : false}
														value="A"
														onClick={q17Check}
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>

									<Grid container spacing={1} direction="row">
										<Grid item xs={10} sm={10}>
											<Typography className={classes.label}>{q18}</Typography>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.radiolabel}
												>
													<FormControlLabel
														checked={q18value === "B" ? true : false}
														value="B"
														onClick={q18Check}
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
													<FormControlLabel
														checked={q18value === "A" ? true : false}
														value="A"
														onClick={q18Check}
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
												</RadioGroup>
											</FormControl>
											{q18value === "A" ? (
												<div>
													<Typography className={classes.sublabel}>
														{q18a}
													</Typography>
													<FormGroup className={classes.marginBottom_30}>
														<FormControlLabel
															control={
																<Checkbox
																	checked={soinsMedicauxChecked}
																	value={"A"}
																	onChange={q18OuiAsoinsMedicaux}
																/>
															}
															label="Soins médicaux"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={assistancePsychosocialChecked}
																	value={"B"}
																	onChange={q18OuiAassistancePsychosocial}
																/>
															}
															label="Assistance psychosociale"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={assisstMedChecked}
																	value={"C"}
																	onChange={q18OuiAassisstMed}
																/>
															}
															label="Assistance en médiation familiale"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={assistanceJuridiqueChecked}
																	value={"D"}
																	onChange={q18OuiAassistanceJuridique}
																/>
															}
															label="Assistance juridique"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={denonciationDuCasAvecSuiteChecked}
																	value={"E"}
																	onChange={q18OuiAdenonciationDuCasAvecSuite}
																/>
															}
															label="Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={denonciationDuCasSansSuiteChecked}
																	value={"F"}
																	onChange={q18OuiAdenonciationDuCasSansSuite}
																/>
															}
															label="Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={assistSocioChecked}
																	value={"G"}
																	onChange={q18OuiAassistSocio}
																/>
															}
															label="Assistance socio-économique"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={dvplanChecked}
																	value={"H"}
																	onChange={q18OuiAdvplan}
																/>
															}
															label="Développement d’un plan de sécurité pour la victime de VD"
														/>

														<FormControlLabel
															control={
																<Checkbox
																	checked={q18Act}
																	onChange={q18autreCheck}
																	value={"oui"}
																/>
															}
															label={
																<TextField
																	disabled={q18Act === true ? false : true}
																	label="Autre, notamment "
																	variant="outlined"
																	style={{ width: "100%" }}
																	size="small"
																	fullWidth
																	name="q18autre"
																	inputRef={register}
																/>
															}
														/>
													</FormGroup>
													<Typography className={classes.sublabel}>
														{q18b}
													</Typography>
													<TextField
														id="outlined-multiline-static"
														// label="Il s’agit d’une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé. Veuillez fournir ici un bref aperçu du cas en français."
														placeholder="Notez la réponse"
														required
														multiline
														className={classes.textarea}
														rows={8}
														name="q18b"
														inputRef={register}
														//defaultValue="Default Value"
														variant="outlined"
													/>
													<Typography className={classes.sublabel}>
														{q18c}
													</Typography>
													<TextField
														id="outlined-multiline-static"
														// label="Il s’agit d’une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé. Veuillez fournir ici un bref aperçu du cas en français."
														placeholder="Notez la réponse"
														required
														multiline
														className={classes.textarea}
														rows={8}
														name="q18c"
														inputRef={register}
														//defaultValue="Default Value"
														variant="outlined"
													/>
												</div>
											) : null}
										</Grid>
									</Grid>
									<Grid container spacing={1} direction="row">
										<Grid container spacing={1} direction="row">
											<Grid
												item
												xs={11}
												sm={10}
												className={classes.marginTop_20}
											>
												<Typography className={classes.label}>{q19}</Typography>
												<FormControl component="fieldset">
													<RadioGroup
														name="occupation"
														className={classes.radiolabel}
													>
														<FormControlLabel
															checked={q19value === "B" ? true : false}
															value="B"
															onClick={q19Check}
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Non
																</Box>
															}
														/>
														<FormControlLabel
															checked={q19value === "A" ? true : false}
															value="A"
															onClick={q19Check}
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Oui
																</Box>
															}
														/>
													</RadioGroup>
												</FormControl>
												{q19value === "A" ? (
													<FormGroup className={classes.marginBottom_30}>
														<Typography className={classes.subradio}>
															Si oui : (choix multiple est possible.)
														</Typography>

														<FormControlLabel
															control={
																<Checkbox
																	checked={soinsMedicaux2Checked}
																	value={"A"}
																	onChange={q19soinsMedicaux2}
																/>
															}
															label="Soins médicaux"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={soinsMedicauxDans72heuresChecked}
																	value={"B"}
																	onChange={q19soinsMedicauxDans72heures}
																/>
															}
															label="Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={assistancePsychosocial2Checked}
																	value={"C"}
																	onChange={q19assistancePsychosocial2}
																/>
															}
															label="Assistance psychosociale"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={assistanceEnMediation2Checked}
																	value={"D"}
																	onChange={q19assistanceEnMediation2}
																/>
															}
															label="Assistance en médiation familiale"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={assistanceJuriChecked}
																	value={"E"}
																	onChange={q19assistanceJuri}
																/>
															}
															label="Assistance juridique"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={assistanceJuriPourDenoncerChecked}
																	value={"F"}
																	onChange={q19assistanceJuriPourDenoncer}
																/>
															}
															label="Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={
																		assistanceJuriPourDenoncerCivileChecked
																	}
																	value={"G"}
																	onChange={q19assistanceJuriPourDenoncerCivile}
																/>
															}
															label="Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={assistanceSocioEconomique2Checked}
																	value={"H"}
																	onChange={q19assistanceSocioEconomique2}
																/>
															}
															label="Assistance socio-économique"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={dvPlanDeSecuriteChecked}
																	value={"I"}
																	onChange={q19dvPlanDeSecurite}
																/>
															}
															label="Développement d’un plan de sécurité pour la victime de VD"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={q19Act}
																	onChange={q19autreCheck}
																	value={"oui"}
																/>
															}
															label={
																<TextField
																	disabled={q19Act === true ? false : true}
																	label="Autre, notamment "
																	variant="outlined"
																	style={{ width: "100%" }}
																	size="small"
																	fullWidth
																	name="q19autre"
																	inputRef={register}
																/>
															}
														/>
													</FormGroup>
												) : null}
											</Grid>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										direction="row"
										className={classes.marginBottom_30}
									>
										<Grid container spacing={1} direction="row">
											<Grid
												item
												xs={11}
												sm={10}
												className={classes.marginTop_20}
											>
												<Typography className={classes.label}>
													{q20}
													<small style={{ fontWeight: 200 }}> {q20sub}</small>
												</Typography>
												<FormGroup>
													<FormControlLabel
														control={
															<Checkbox
																checked={soinsMedicaux3Checked}
																value={"A"}
																onChange={q20soinsMedicaux3}
															/>
														}
														label="Soins médicaux"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={soinsMedicauxDans72heures2Checked}
																value={"B"}
																onChange={q20soinsMedicauxDans72heures2}
															/>
														}
														label="Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={assistancePsychosocial3Checked}
																value={"C"}
																onChange={q20assistancePsychosocial3}
															/>
														}
														label="Assistance psychosociale"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={assistanceEnMediationFam3Checked}
																value={"D"}
																onChange={q20assistanceEnMediationFam3}
															/>
														}
														label="Assistance en médiation familiale"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={assistanceJuri2Checked}
																value={"E"}
																onChange={q20assistanceJuri2}
															/>
														}
														label="Assistance juridique"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={assistanceJuriPourDenoncer2Checked}
																value={"F"}
																onChange={q20assistanceJuriPourDenoncer2}
															/>
														}
														label="Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={
																	assistanceJuriPourDenoncer2CivileChecked
																}
																value={"G"}
																onChange={q20assistanceJuriPourDenoncer2Civile}
															/>
														}
														label="Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={assistanceSocioEconomique3Checked}
																value={"H"}
																onChange={q20assistanceSocioEconomique3}
															/>
														}
														label="Assistance socio-économique"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={dvPlanDeSecurite2Checked}
																value={"I"}
																onChange={q20dvPlanDeSecurite2}
															/>
														}
														label="Développement d’un plan de sécurité pour la victime de VD"
													/>

													<FormControlLabel
														control={
															<Checkbox
																checked={q20Act}
																onChange={q20autreCheck}
																value={"oui"}
															/>
														}
														label={
															<TextField
																disabled={q20Act === true ? false : true}
																label="Autre, notamment "
																variant="outlined"
																style={{ width: "100%" }}
																size="small"
																fullWidth
																name="q20autre"
																inputRef={register}
															/>
														}
													/>
												</FormGroup>
											</Grid>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										direction="row"
										className={classes.marginBottom_30}
									>
										<Grid container spacing={1} direction="row">
											<Grid
												item
												xs={11}
												sm={10}
												className={classes.marginTop_20}
											>
												<Typography className={classes.label}>
													{q21}
													<small style={{ fontWeight: 200 }}>{q21sub}</small>
												</Typography>
												<FormGroup>
													<FormControlLabel
														control={
															<Checkbox
																checked={soinsMedicaux4Checked}
																value={"A"}
																onChange={q21soinsMedicaux4}
															/>
														}
														label="Soins médicaux"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={soinsMedicauxDans72heures3Checked}
																value={"B"}
																onChange={q21soinsMedicauxDans72heures3}
															/>
														}
														label="Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={assistancePsychosocial4Checked}
																value={"C"}
																onChange={q21assistancePsychosocial4}
															/>
														}
														label="Assistance psychosociale"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={assistanceEnMediation4Checked}
																value={"D"}
																onChange={q21assistanceEnMediation4}
															/>
														}
														label="Assistance en médiation familiale"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={assistanceJuri3Checked}
																value={"E"}
																onChange={q21assistanceJuri3}
															/>
														}
														label="Assistance juridique"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={assistanceJuriPourDenoncer3Checked}
																value={"F"}
																onChange={q21assistanceJuriPourDenoncer3}
															/>
														}
														label="Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={
																	assistanceJuriPourDenoncer3CivileChecked
																}
																value={"G"}
																onChange={q21assistanceJuriPourDenoncer3Civile}
															/>
														}
														label="Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={assistanceSocioEconomique4Checked}
																value={"H"}
																onChange={q21assistanceSocioEconomique4}
															/>
														}
														label="Assistance socio-économique"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={dvPlanDeSecurite3Checked}
																value={"I"}
																onChange={q21dvPlanDeSecurite3}
															/>
														}
														label="Développement d’un plan de sécurité pour la victime de VD"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={serviceDemandeChecked}
																value={"J"}
																onChange={q21serviceDemande}
															/>
														}
														label="Cette situation ne s’applique pas au cas documenté comme notre organisation peut fournir tous les services demandés"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={q21Act}
																onChange={q21autreCheck}
																value={"oui"}
															/>
														}
														label={
															<TextField
																disabled={q21Act === true ? false : true}
																label="Autre, notamment "
																variant="outlined"
																style={{ width: "100%" }}
																size="small"
																fullWidth
																name="q21autre"
																inputRef={register}
															/>
														}
													/>
												</FormGroup>
											</Grid>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										direction="row"
										className={classes.marginBottom_30}
									>
										<Grid container spacing={1} direction="row">
											<Grid
												item
												xs={11}
												sm={10}
												className={classes.marginTop_20}
											>
												<Typography className={classes.label}>
													{q22}
													<small style={{ fontWeight: 200 }}>{q22sub}</small>
												</Typography>

												<FormGroup className={classes.marginBottom_30}>
													<FormControlLabel
														control={
															<Checkbox
																checked={soinsMedicaux5Checked}
																value={"A"}
																onChange={q22soinsMedicaux5}
															/>
														}
														label="Soins médicaux"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={soinsMedicauxDans72heures4Checked}
																value={"B"}
																onChange={q22soinsMedicauxDans72heures4}
															/>
														}
														label="Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={assistancePsychosocial5Checked}
																value={"C"}
																onChange={q22assistancePsychosocial5}
															/>
														}
														label="Assistance psychosociale"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={assistanceEnMediation5Checked}
																value={"D"}
																onChange={q22assistanceEnMediation5}
															/>
														}
														label="Assistance en médiation familiale"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={assistanceJuri4Checked}
																value={"E"}
																onChange={q22assistanceJuri4}
															/>
														}
														label="Assistance juridique"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={assistanceJuriPourDenoncer4Checked}
																value={"F"}
																onChange={q22assistanceJuriPourDenoncer4}
															/>
														}
														label="Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={
																	assistanceJuriPourDenoncer4CivileChecked
																}
																value={"G"}
																onChange={q22assistanceJuriPourDenoncer4Civile}
															/>
														}
														label="Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={assistanceSocioEconomique5Checked}
																value={"H"}
																onChange={q22assistanceSocioEconomique5}
															/>
														}
														label="Assistance socio-économique"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={dvPlanDeSecurite4Checked}
																value={"I"}
																onChange={q22dvPlanDeSecurite4}
															/>
														}
														label="Développement d’un plan de sécurité pour la victime de VD"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={patenaireDeRefChecked}
																value={"J"}
																onChange={q22patenaireDeRef}
															/>
														}
														label="Cette situation ne s’applique pas au cas documenté comme tous les services demandés peuvent être fournis par notre organisation et/ou nos partenaires de référence. "
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={q22Act}
																onChange={q22autreCheck}
																value={"oui"}
															/>
														}
														label={
															<TextField
																disabled={q22Act === true ? false : true}
																label="Autre, notamment "
																variant="outlined"
																style={{ width: "100%" }}
																size="small"
																fullWidth
																name="q22autre"
																inputRef={register}
															/>
														}
													/>
												</FormGroup>
											</Grid>
										</Grid>
										<Grid
											container
											spacing={1}
											direction="row"
											className={classes.marginBottom_30}
										>
											<Grid
												item
												xs={11}
												sm={10}
												className={classes.marginTop_20}
											>
												<Typography className={classes.label}>{q23}</Typography>
												<FormControl component="fieldset">
													<RadioGroup
														name="occupation"
														className={classes.radiolabel}
													>
														<FormControlLabel
															checked={q23value === "A" ? true : false}
															value="A"
															onClick={q23Check}
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Oui
																</Box>
															}
														/>
														<FormControlLabel
															checked={q23value === "B" ? true : false}
															value="B"
															onClick={q23Check}
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Non
																</Box>
															}
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
										<Grid
											container
											spacing={1}
											direction="row"
											className={classes.marginBottom_30}
										>
											<Grid
												item
												xs={11}
												sm={10}
												className={classes.marginTop_20}
											>
												<Typography className={classes.label}>{q24}</Typography>
												<FormControl
													component="fieldset"
													className={classes.marginBottom_30}
												>
													<RadioGroup
														name="occupation"
														className={classes.radiolabel}
													>
														<FormControlLabel
															checked={q24value === "A" ? true : false}
															value="A"
															onClick={q24Check}
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Non, la victime cherche à rester chez elle
																</Box>
															}
														/>
														<FormControlLabel
															checked={q24value === "B" ? true : false}
															value="B"
															onClick={q24Check}
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Oui, la victime cherche à quitter son foyer de
																	façon temporaire
																</Box>
															}
														/>
														<FormControlLabel
															checked={q24value === "C" ? true : false}
															value="C"
															onClick={q24Check}
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Oui, la victime cherche à quitter son foyer de
																	façon permanente
																</Box>
															}
														/>
													</RadioGroup>
												</FormControl>
												{q24value === "A" ? null : (
													<div>
														<Typography className={classes.sublabel}>
															{q24a}
														</Typography>
														<FormControl component="fieldset">
															<RadioGroup
																name="occupation"
																className={classes.radiolabel}
															>
																<FormControlLabel
																	checked={q24avalue === "A" ? true : false}
																	value="A"
																	onClick={q24aCheck}
																	control={<Radio size="small" />}
																	label={
																		<Box component="div" fontSize={13}>
																			Oui
																		</Box>
																	}
																/>
																<FormControlLabel
																	checked={q24avalue === "B" ? true : false}
																	value="B"
																	onClick={q24aCheck}
																	control={<Radio size="small" />}
																	label={
																		<Box component="div" fontSize={13}>
																			Non
																		</Box>
																	}
																/>
															</RadioGroup>
														</FormControl>
													</div>
												)}
											</Grid>
										</Grid>
										<Grid
											container
											spacing={1}
											direction="row"
											className={classes.marginBottom_30}
										>
											<Grid
												item
												xs={11}
												sm={10}
												className={classes.marginTop_20}
											>
												<Typography className={classes.label}>{q25}</Typography>
												<FormControl component="fieldset">
													<RadioGroup
														name="occupation"
														className={classes.radiolabel}
													>
														<FormControlLabel
															checked={q25value === "A" ? true : false}
															value="A"
															onClick={q25Check}
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Oui
																</Box>
															}
														/>
														<FormControlLabel
															checked={q25value === "B" ? true : false}
															value="B"
															onClick={q25Check}
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Non
																</Box>
															}
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
										{q25value === "B" ? null : (
											<div>
												<Grid container spacing={1} direction="row">
													<Grid
														item
														xs={11}
														sm={10}
														className={classes.marginTop_20}
													>
														<Typography className={classes.label}>
															{q26}
														</Typography>
														<TextField
															id="outlined-multiline-static"
															// label="Il s’agit d’une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé. Veuillez fournir ici un bref aperçu du cas en français."
															placeholder="Notez la réponse"
															required
															multiline
															className={classes.textarea}
															rows={8}
															name="q26"
															inputRef={register}
															//defaultValue="Default Value"
															variant="outlined"
														/>
													</Grid>
												</Grid>
												<Grid
													container
													spacing={1}
													direction="row"
													className={classes.marginBottom_30}
												>
													<Grid
														item
														xs={11}
														sm={10}
														className={classes.marginTop_20}
													>
														<Typography className={classes.label}>
															{q27}
														</Typography>
														<FormControl component="fieldset">
															<RadioGroup
																name="occupation"
																className={classes.radiolabel}
															>
																<FormControlLabel
																	checked={q27value === "A" ? true : false}
																	value="A"
																	onClick={q27Check}
																	control={<Radio size="small" />}
																	label={
																		<Box component="div" fontSize={13}>
																			Avec localisation (province et territoire
																			ou ville provinciale) et âge
																		</Box>
																	}
																/>
																<FormControlLabel
																	checked={q27value === "B" ? true : false}
																	value="B"
																	onClick={q27Check}
																	control={<Radio size="small" />}
																	label={
																		<Box component="div" fontSize={13}>
																			Complétement anonyme
																		</Box>
																	}
																/>
															</RadioGroup>
														</FormControl>
													</Grid>
												</Grid>
											</div>
										)}
									</Grid>
									<Grid
										container
										alignItems="center"
										direction="column"
										spacing={2}
									>
										<Grid item xs={12} sm={12}>
											<Typography
												style={{
													color: "#4F2970",
													fontWeight: "bold",
													fontSize: 14,
												}}
											>
												Clôture de l’entretien
											</Typography>
										</Grid>
										<Grid item xs={12} sm={12}>
											<Typography
												style={{ color: "#4F2970", fontSize: 14 }}
												align="center"
											>
												(Veuillez remercier la ou le survivant(e) et organiser
												ensemble la suite pour la prise en charge.)
											</Typography>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										direction="row"
										justifyContent="flex-end"
									>
										<Grid item className={classes.marginButton}>
											{/* <BtnOutline 
                                component={Link}
                                to='/collect/form/perpretor'
                                content={precedent} onClick={()=>{
                                //console.log('hello elijah');
                            }}/>
                            <BtnContained
                                // component={Link}
                                // to='/collect/form/revoir'
                                type='submit'
                                content={revoir}
                            /> */}
											{orgData !== null ? (
												<div>
													<BtnOutline onClick={handleRetour} content="RETOUR" />
													<BtnContained
														type="submit"
														//onClick={handleSauvegarde}
														content="SAUVEGARDER"
													/>
												</div>
											) : (
												<div>
													<BtnOutline
														component={Link}
														to={
															"/" +
															process.env.REACT_APP_COLLECT_LINK +
															"/collect/form/perpretor"
														}
														content={precedent}
													/>
													<BtnContained
														// component={Link}
														// to='/collect/form/revoir'
														type="submit"
														content={revoir}
													/>
												</div>
											)}
										</Grid>
									</Grid>
								</form>
								<Grid item sm={1} xs={0}></Grid>
							</Grid>
						</Grid>
					</Grid>
					<Snackbar
						open={openSnackBar}
						autoHideDuration={10}
						onClose={handleClose}
					>
						<Alert onClose={handleCloseSnackBar} severity="error">
							<Typography>{msg}</Typography>
						</Alert>
					</Snackbar>
				</Grid>
			</div>
		</div>
	);
};

export default Need_care;

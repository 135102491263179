import React, { useState } from "react";
import {
	Grid,
	IconButton,
	Select,
	MenuItem,
	Typography,
	Paper,
} from "@material-ui/core";
import BarCharts from "../../case_statistic/sub/chart/bar";
import useStyles from "../../styles";
import DoughnutCharts from "../../case_statistic/sub/chart/doughtnut";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { Cell } from "recharts";
import StopIcon from "@material-ui/icons/Stop";
import CloudOffTwoToneIcon from "@material-ui/icons/CloudOffTwoTone";
import RestoreIcon from "@material-ui/icons/Restore";
import StorageIcon from "@material-ui/icons/Storage";
import Skeleton from "@material-ui/lab/Skeleton";

const Quantitative = ({
	data,
	errmsg,
	getApprove,
	statecode,
	spinner,
	// getq10a,
}) => {
	// const {data}=props

	//console.log(getq10a);

	const [value, setvalue] = useState(0);
	const handleChange = (event) => {
		setvalue(event.target.value);
	};
	const classes = useStyles();

	const handleCase = (e) => {
		const value = e.target.value;

		if (value === 0) {
			setvalue(value);
		} else if (value === 1) {
			setvalue(value);
		} else if (value === 2) {
			setvalue(value);
		} else if (value === 3) {
			setvalue(value);
		} else if (value === 4) {
			setvalue(value);
		} else if (value === 5) {
			setvalue(value);
		} else if (value === 6) {
			setvalue(value);
		} else if (value === 7) {
			setvalue(value);
		} else if (value === 8) {
			setvalue(value);
		} else if (value === 9) {
			setvalue(value);
		} else if (value === 10) {
			setvalue(value);
		} else if (value === 11) {
			setvalue(value);
		} else if (value === 12) {
			setvalue(value);
		} else if (value === 13) {
			setvalue(value);
		} else if (value === 14) {
			setvalue(value);
		} else if (value === 15) {
			setvalue(value);
		} else if (value === 16) {
			setvalue(value);
		} else if (value === 17) {
			setvalue(value);
		} else if (value === 18) {
			setvalue(value);
		} else if (value === 19) {
			setvalue(value);
		} else if (value === 20) {
			setvalue(value);
		}
	};
	//getting gpe d age

	const ans5 = data.filter(
		(item) => item.question_6_b.indexOf("0-5 ans") > -1
	).length;
	const ans17 = data.filter(
		(item) => item.question_6_b.indexOf("6-17 ans") > -1
	).length;
	const ans29 = data.filter(
		(item) => item.question_6_b.indexOf("18-29 ans") > -1
	).length;
	const ans49 = data.filter(
		(item) => item.question_6_b.indexOf("30-49 ans") > -1
	).length;
	const ans64 = data.filter(
		(item) => item.question_6_b.indexOf("50-64 ans") > -1
	).length;
	const ans65 = data.filter(
		(item) => item.question_6_b.indexOf("65 ans et plus") > -1
	).length;
	const grpdAge = [
		{ value: ans5 },
		{ value: ans17 },
		{ value: ans29 },
		{ value: ans49 },
		{ value: ans64 },
		{ value: ans65 },
	];
	const grpeColors = [
		"#FFDA83",
		"#A3A1FB",
		"#D12028",
		"#F48C47",
		"#1D384C",
		"#60C1CB",
	];

	//

	//getting gender
	const autre = data.filter((e) => e.question_7 === "Autre");
	const feminin = data.filter((e) => e.question_7 === "Féminin");
	const masculin = data.filter((e) => e.question_7 === "Masculin");

	const genderColor = ["#00A1AF", "#D12028", "#1D384C"];
	const genData = [
		{
			total: `${feminin.length}`,
		},
		{
			total: `${masculin.length}`,
		},
		{
			total: `${autre.length}`,
		},
	];

	// question 8a

	const celib = data.filter((e) => e.question_8_a === "Célibataire");
	const marie = data.filter((e) => e.question_8_a === "Marié(e)");
	const separe = data.filter((e) => e.question_8_a === "Séparé(e)");
	const divorce = data.filter((e) => e.question_8_a === "Divorcé(e)");
	const veuf = data.filter((e) => e.question_8_a === "Veuve / Veuf");
	//console.log(celib);
	const union = data.filter(
		(e) => e.question_8_a === "Union de fait (vivre ensemble sans être mariés)"
	);
	const etatColor = [
		"#00A1AF",
		"#FFDA83",
		"#A3A1FB",
		"#F48C47",
		"#55D8FE",
		"#1D384C",
	];
	const etatcivile = [
		{
			total: `${celib.length}`,
		},
		{
			total: `${marie.length}`,
		},
		{
			total: `${separe.length}`,
		},
		{
			total: `${divorce.length}`,
		},
		{
			total: `${union.length}`,
		},
		{
			total: `${veuf.length}`,
		},
	];
	// question 8b

	const yesq8b = data.filter((e) => e.question_8_b === "Oui");
	const nonq8b = data.filter((e) => e.question_8_b === "Non");

	const q8b = [
		{
			total: `${yesq8b.length}`,
		},
		{
			total: `${nonq8b.length}`,
		},
	];

	// question 8c

	const yesq8c = data.filter((e) => e.question_8_c === "Oui");
	const nonq8c = data.filter((e) => e.question_8_c === "Non");

	const q8c = [
		{
			total: `${yesq8c.length}`,
		},
		{
			total: `${nonq8c.length}`,
		},
	];

	// question 8d

	const yesq8d = data.filter((e) => e.question_8_d === "Oui");
	const nonq8d = data.filter((e) => e.question_8_d === "Non");

	const q8d = [
		{
			total: `${yesq8d.length}`,
		},
		{
			total: `${nonq8d.length}`,
		},
	];
	// question 8e

	const yesq8e = data.filter((e) => e.question_8_e === "Oui");
	const nonq8e = data.filter((e) => e.question_8_e === "Non");

	const q8e = [
		{
			total: `${yesq8e.length}`,
		},
		{
			total: `${nonq8e.length}`,
		},
	];
	// question 8f

	const yesq8f = data.filter((e) => e.question_8_f === "Oui");
	const nonq8f = data.filter((e) => e.question_8_f === "Non");

	const q8f = [
		{
			total: `${yesq8f.length}`,
		},
		{
			total: `${nonq8f.length}`,
		},
	];

	//question 10

	const viol = data.filter((e) => e.question_10_a.indexOf("Viol") > -1);
	const conj = data.filter(
		(e) =>
			e.question_10_a.indexOf(
				"Viol conjugal entre couples mariés et non mariés"
			) > -1
	);
	const agr = data.filter(
		(e) => e.question_10_a.indexOf("Agression sexuelle sur mineur") > -1
	);
	const coe = data.filter(
		(e) => e.question_10_a.indexOf("Coercition sexuelle") > -1
	);
	const harc = data.filter(
		(e) => e.question_10_a.indexOf("Harcèlement sexuel") > -1
	);
	const att = data.filter(
		(e) => e.question_10_a.indexOf("Attouchements inappropriés") > -1
	);
	const forc = data.filter(
		(e) =>
			e.question_10_a.indexOf(
				"Forcer la victime à avoir des relations sexuelles sans préservatif"
			) > -1
	);
	const emp = data.filter(
		(e) =>
			e.question_10_a.indexOf(
				"Empêcher la victime de faire ses propres choix quant à la décision d'avoir ou non un bébé"
			) > -1
	);
	const autra = data.filter((e) => e.question_10_a.indexOf("Autres") > -1);
	//console.log(viol.length);
	const q10aa = [
		{ name: "Viol", color: "#f44336", total: `${viol.length}`, id: 1 },
		{
			name: "Viol conjugal entre couples mariés et non mariés",
			color: "#e91e63",
			total: `${conj.length}`,
			id: 2,
		},
		{
			name: "Agression sexuelle sur mineur",
			color: "#9c27b0",
			total: `${agr.length}`,
			id: 3,
		},
		{
			name: "Coercition sexuelle",
			color: "#673ab7",
			total: `${coe.length}`,
			id: 4,
		},
		{
			name: "Harcèlement sexuel",
			color: "#00A1AF",
			total: `${harc.length}`,
			id: 5,
		},
		{
			name: "Attouchements inappropriés",
			color: "#FFDA83",
			total: `${att.length}`,
			id: 6,
		},
		{
			name: "Forcer la victime à avoir des relations sexuelles sans préservatif",
			color: "#A3A1FB",
			total: `${forc.length}`,
			id: 7,
		},
		{
			name: "Empêcher la victime de faire ses propres choix quant à la décision d'avoir ou non un bébé",
			color: "#F48C47",
			total: `${emp.length}`,
			id: 8,
		},
		{ name: "Autres", color: "#55D8FE", total: `${autra.length}`, id: 9 },
	];

	const q10a = [
		{
			total: `${viol.length}`,
		},
		{
			total: `${conj.length}`,
		},
		{
			total: `${agr.length}`,
		},
		{
			total: `${coe.length}`,
		},
		{
			total: `${harc.length}`,
		},
		{
			total: `${att.length}`,
		},
		{
			total: `${forc.length}`,
		},
		{
			total: `${emp.length}`,
		},
		{
			total: `${autra.length}`,
		},
	];
	const meu = data.filter(
		(e) => e.question_10_b.indexOf("Meurtre / féminicide") > -1
	);
	const cpdepoing = data.filter(
		(e) => e.question_10_b.indexOf("Coups de poing et/ou coups de pieds") > -1
	);
	const cpe = data.filter(
		(e) => e.question_10_b.indexOf("Coups portés avec des objets") > -1
	);
	const atta = data.filter(
		(e) => e.question_10_b.indexOf("Attaque à l’acide") > -1
	);
	const attq = data.filter(
		(e) => e.question_10_b.indexOf("Attaque au couteau ou à la machette") > -1
	);
	const attAeaux = data.filter(
		(e) =>
			e.question_10_b.indexOf(
				"Attaque à l’eau chaude, à l’huile chaude ou autre objet chaud"
			) > -1
	);
	const priv = data.filter(
		(e) =>
			e.question_10_b.indexOf(
				"Privation de la liberté physique de la victime"
			) > -1
	);
	const privDeN = data.filter(
		(e) => e.question_10_b.indexOf("Privation de nourriture de la victime") > -1
	);
	const negl = data.filter(
		(e) =>
			e.question_10_b.indexOf(
				"Négligence physique d’enfant (si la victime est un enfant)"
			) > -1
	);
	const ngl = data.filter(
		(e) =>
			e.question_10_b.indexOf(
				"Négligence physique de personnes âgées (si la victime est une personne âgée)"
			) > -1
	);
	const destr = data.filter(
		(e) =>
			e.question_10_b.indexOf(
				"Destruction d’objets dans la maison de la victime"
			) > -1
	);
	const rites = data.filter(
		(e) =>
			e.question_10_b.indexOf(
				"Rites de veuvage affectant la santé physique et mentale de la victime"
			) > -1
	);
	const autrb = data.filter((e) => e.question_10_b.indexOf("Autres") > -1);

	const q10bb = [
		{
			name: "Meurtre / féminicide",
			color: "#f44336",
			total: `${meu.length}`,
			id: 1,
		},
		{
			name: "Coups de poing et/ou coups de pieds",
			color: "#e91e63",
			total: `${cpdepoing.length}`,
			id: 2,
		},
		{
			name: "Coups portés avec des objets",
			color: "#9c27b0",
			total: `${cpe.length}`,
			id: 3,
		},
		{ name: "Attaque à l’acide", color: "#673ab7", total: `${atta.length}` },
		{
			name: "Attaque au couteau ou à la machette",
			color: "#673ab7",
			total: `${attq.length}`,
			id: 4,
		},
		{
			name: "Attaque à l’eau chaude, à l’huile chaude ou autre objet chaud",
			color: "#00A1AF",
			total: `${attAeaux.length}`,
			id: 5,
		},
		{
			name: "Privation de la liberté physique de la victime",
			color: "#FFDA83",
			total: `${priv.length}`,
			id: 6,
		},
		{
			name: "Privation de nourriture de la victime",
			color: "#A3A1FB",
			total: `${privDeN.length}`,
			id: 7,
		},
		{
			name: "Négligence physique d’enfant (si la victime est un enfant)",
			color: "#F48C47",
			total: `${negl.length}`,
			id: 8,
		},
		{
			name: "Négligence physique de personnes âgées (si la victime est une personne âgée)",
			color: "#55D8FE",
			total: `${ngl.length}`,
			id: 9,
		},
		{
			name: "Destruction d’objets dans la maison de la victime",
			color: "#1D384C",
			total: `${destr.length}`,
			id: 10,
		},
		{
			name: "Rites de veuvage affectant la santé physique et mentale de la victime",
			color: "#3f51b5",
			total: `${rites.length}`,
			id: 11,
		},
		{ name: "Autres", color: "#009688", total: `${autrb.length}`, id: 12 },
	];
	const q10b = [
		{
			total: `${meu.length}`,
		},
		{
			total: `${cpdepoing.length}`,
		},
		{
			total: `${cpe.length}`,
		},
		{
			total: `${atta.length}`,
		},
		{
			total: `${attq.length}`,
		},
		{
			total: `${attAeaux.length}`,
		},
		{
			total: `${priv.length}`,
		},
		{
			total: `${privDeN.length}`,
		},
		{
			total: `${negl.length}`,
		},
		{
			total: `${ngl.length}`,
		},
		{
			total: `${destr.length}`,
		},
		{
			total: `${rites.length}`,
		},
		{
			total: `${autrb.length}`,
		},
	];

	const men = data.filter((e) => e.question_10_c.indexOf("Menaces") > -1);
	const inj = data.filter((e) => e.question_10_c.indexOf("Injures") > -1);
	const neglem = data.filter(
		(e) => e.question_10_c.indexOf("Négligence émotionnelle") > -1
	);
	const hum = data.filter(
		(e) =>
			e.question_10_c.indexOf("Humiliation de la victime face aux autres") > -1
	);
	const attvisa = data.filter(
		(e) =>
			e.question_10_c.indexOf(
				"Attaques visant à nuire à la réputation de la victime"
			) > -1
	);
	const islment = data.filter(
		(e) =>
			e.question_10_c.indexOf(
				"Isolement de la victime de sa famille d’origine ou ses amis"
			) > -1
	);
	const ctrDesM = data.filter(
		(e) => e.question_10_c.indexOf("Contrôle des mouvements de la victime") > -1
	);
	const ctrDeCom = data.filter(
		(e) =>
			e.question_10_c.indexOf("Contrôle de la communication de la victime") > -1
	);
	const refus = data.filter(
		(e) =>
			e.question_10_c.indexOf(
				"Refus de divorce malgré le souhait de la victime de se divorcer"
			) > -1
	);
	const ab = data.filter(
		(e) =>
			e.question_10_c.indexOf("Abus spirituel / abus religieux de la victime") >
			-1
	);
	const autrbq10c = data.filter((e) => e.question_10_c.indexOf("Autres") > -1);

	const q10c = [
		{
			total: `${men.length}`,
		},
		{
			total: `${inj.length}`,
		},
		{
			total: `${neglem.length}`,
		},
		{
			total: `${hum.length}`,
		},
		{
			total: `${attvisa.length}`,
		},
		{
			total: `${islment.length}`,
		},
		{
			total: `${ctrDesM.length}`,
		},
		{
			total: `${ctrDeCom.length}`,
		},
		{
			total: `${refus.length}`,
		},
		{
			total: `${ab.length}`,
		},
		{
			total: `${autrbq10c.length}`,
		},
	];

	const q10cc = [
		{ name: "Menaces", color: "#f44336", total: `${men.length}`, id: 1 },
		{ name: "Injures", color: "#e91e63", total: `${inj.length}`, id: 2 },
		{
			name: "Négligence émotionnelle",
			color: "#9c27b0",
			total: `${neglem.length}`,
			id: 3,
		},
		{
			name: "Humiliation de la victime face aux autres",
			color: "#673ab7",
			total: `${hum.length}`,
			id: 4,
		},
		{
			name: "Attaques visant à nuire à la réputation de la victime",
			color: "#00A1AF",
			total: `${attvisa.length}`,
			id: 5,
		},
		{
			name: "Isolement de la victime de sa famille d’origine ou ses amis",
			color: "#FFDA83",
			total: `${islment.length}`,
			id: 6,
		},
		{
			name: "Contrôle des mouvements de la victime",
			color: "#A3A1FB",
			total: `${ctrDesM.length}`,
			id: 7,
		},
		{
			name: "Contrôle de la communication de la victime",
			color: "#F48C47",
			total: `${ctrDeCom.length}`,
			id: 8,
		},
		{
			name: "Refus de divorce malgré le souhait de la victime de se divorcer",
			color: "#55D8FE",
			total: `${refus.length}`,
			id: 9,
		},
		{
			name: "Abus spirituel / abus religieux de la victime",
			color: "#1D384C",
			total: `${ab.length}`,
			id: 10,
		},
		{ name: "Autres", color: "#3f51b5", total: `${autrbq10c.length}`, id: 11 },
	];

	const auteur = data.filter(
		(e) =>
			e.question_10_d.indexOf("L’auteur abandonne ou chasse la victime") > -1
	);
	const cnfiscat = data.filter(
		(e) =>
			e.question_10_d.indexOf("Confiscation des revenus de la victime") > -1
	);
	const interdictio = data.filter(
		(e) =>
			e.question_10_d.indexOf(
				"Interdiction de participation à la gestion des revenus du ménage"
			) > -1
	);
	const restDeLAcces = data.filter(
		(e) => e.question_10_d.indexOf("Restriction de l’accès à l'éducation") > -1
	);
	const restDeLAccesALempl = data.filter(
		(e) => e.question_10_d.indexOf("Restriction de l’accès à l'emploi") > -1
	);
	const RestRessEcom = data.filter(
		(e) =>
			e.question_10_d.indexOf(
				"Restriction de l’accès aux ressources économiques"
			) > -1
	);
	const privDePrise = data.filter(
		(e) =>
			e.question_10_d.indexOf(
				"Privation d’une prise en charge médicale, de médicaments ou d'appareils d’assistance"
			) > -1
	);
	const levirat = data.filter(
		(e) => e.question_10_d.indexOf("Lévirat après la mort du conjoint") > -1
	);
	const perteProp = data.filter(
		(e) =>
			e.question_10_d.indexOf(
				"Perte des propriétés et des biens / non accès à l’héritage après la mort du conjoint ou d’un parent"
			) > -1
	);
	const pertEnf = data.filter(
		(e) =>
			e.question_10_d.indexOf("Perte des enfants après la mort du conjoint") >
			-1
	);
	const autrbq10d = data.filter((e) => e.question_10_d.indexOf("Autres") > -1);

	const q10d = [
		{
			total: `${auteur.length}`,
		},
		{
			total: `${cnfiscat.length}`,
		},
		{
			total: `${interdictio.length}`,
		},
		{
			total: `${restDeLAcces.length}`,
		},
		{
			total: `${restDeLAccesALempl.length}`,
		},
		{
			total: `${RestRessEcom.length}`,
		},
		{
			total: `${privDePrise.length}`,
		},
		{
			total: `${levirat.length}`,
		},
		{
			total: `${perteProp.length}`,
		},
		{
			total: `${pertEnf.length}`,
		},
		{
			total: `${autrbq10d.length}`,
		},
	];

	const q10dd = [
		{
			name: "L’auteur abandonne ou chasse la victime",
			color: "#f44336",
			total: `${auteur.length}`,
			id: 1,
		},
		{
			name: "Confiscation des revenus de la victime",
			color: "#e91e63",
			total: `${cnfiscat.length}`,
			id: 2,
		},
		{
			name: "Interdiction de participation à la gestion des revenus du ménage",
			color: "#9c27b0",
			total: `${interdictio.length}`,
			id: 3,
		},
		{
			name: "Restriction de l’accès à l'éducation",
			color: "#673ab7",
			total: `${restDeLAcces.length}`,
			id: 4,
		},
		{
			name: "Restriction de l’accès à l'emploi",
			color: "#00A1AF",
			total: `${restDeLAccesALempl.length}`,
			id: 5,
		},
		{
			name: "Restriction de l’accès aux ressources économiques",
			color: "#FFDA83",
			total: `${RestRessEcom.length}`,
			id: 6,
		},
		{
			name: "Privation d’une prise en charge médicale, de médicaments ou d'appareils d’assistance",
			color: "#A3A1FB",
			total: `${privDePrise.length}`,
			id: 7,
		},
		{
			name: "Lévirat après la mort du conjoint",
			color: "#F48C47",
			total: `${levirat.length}`,
			id: 8,
		},
		{
			name: "Perte des propriétés et des biens / non accès à l’héritage après la mort du conjoint ou d’un parent",
			color: "#55D8FE",
			total: `${perteProp.length}`,
			id: 9,
		},
		{
			name: "Perte des enfants après la mort du conjoint",
			color: "#1D384C",
			total: `${pertEnf.length}`,
			id: 10,
		},
		{ name: "Autres", color: "#3f51b5", total: `${autrbq10d.length}`, id: 11 },
	];

	//question 11

	const yesq11a = data.filter((e) => e.question_11_a === "Oui");
	const nonq11a = data.filter((e) => e.question_11_a === "Non");

	const q11a = [
		{
			total: `${yesq11a.length}`,
		},
		{
			total: `${nonq11a.length}`,
		},
	];

	const q11aa = [
		{ name: "Oui", color: "#9e9e9e", total: `${yesq11a.length}` },
		{ name: "Non", color: "#795548", total: `${nonq11a.length}` },
	];

	const yesq11b = data.filter((e) => e.question_11_b === "Oui");
	const nonq11b = data.filter((e) => e.question_11_b === "Non");

	const q11bb = [
		{ name: "Oui", color: "#55D8FE", total: `${yesq11b.length}` },
		{ name: "Non", color: "#795548", total: `${nonq11b.length}` },
	];

	const q11b = [
		{
			total: `${yesq11b.length}`,
		},
		{
			total: `${nonq11b.length}`,
		},
	];

	//question 12

	const ubrn = data.filter((e) => e.question_12_b === "Milieu urbain");
	const semi = data.filter((e) => e.question_12_b === "Milieu semi-urbain");
	const rural = data.filter((e) => e.question_12_b === "Milieu rural");

	const q12bb = [
		{ name: "Milieu urbain", color: "#55D8FE", total: `${ubrn.length}` },
		{ name: "Milieu semi-urbain", color: "#F48C47", total: `${semi.length}` },
		{ name: "Milieu rural", color: "#795548", total: `${semi.length}` },
	];
	const q12b = [
		{
			total: `${ubrn.length}`,
		},
		{
			total: `${semi.length}`,
		},
		{
			total: `${rural.length}`,
		},
	];
	//question 13b

	const dec = data.filter((e) => e.question_13_b_a.indexOf("Décès") > -1);
	const mal = data.filter(
		(e) => e.question_13_b_a.indexOf("Maladies (par exemple hypertension)") > -1
	);
	const bles = data.filter((e) => e.question_13_b_a.indexOf("Blessures") > -1);
	const doun = data.filter((e) => e.question_13_b_a.indexOf("Douleurs") > -1);
	const trblgyn = data.filter(
		(e) =>
			e.question_13_b_a.indexOf("Troubles gynécologiques et reproductifs") > -1
	);
	const handicp = data.filter(
		(e) => e.question_13_b_a.indexOf("Invalidité / Handicap") > -1
	);
	const gross = data.filter(
		(e) => e.question_13_b_a.indexOf("Grossesse non voulue") > -1
	);
	const perterDeLenfa = data.filter(
		(e) => e.question_13_b_a.indexOf("Perte de l'enfant à naître") > -1
	);
	const abusDAlcool = data.filter(
		(e) => e.question_13_b_a.indexOf("Abus d'alcool") > -1
	);
	const abusDeDrogue = data.filter(
		(e) => e.question_13_b_a.indexOf("Abus de drogues") > -1
	);
	const abusDemedicament = data.filter(
		(e) => e.question_13_b_a.indexOf("Abus de médicaments") > -1
	);
	const autrq13ba = data.filter(
		(e) => e.question_13_b_a.indexOf("Autres") > -1
	);

	const q13b = [
		{
			total: `${dec.length}`,
		},
		{
			total: `${mal.length}`,
		},
		{
			total: `${bles.length}`,
		},
		{
			total: `${doun.length}`,
		},
		{
			total: `${trblgyn.length}`,
		},
		{
			total: `${handicp.length}`,
		},
		{
			total: `${gross.length}`,
		},
		{
			total: `${perterDeLenfa.length}`,
		},
		{
			total: `${abusDAlcool.length}`,
		},
		{
			total: `${abusDeDrogue.length}`,
		},
		{
			total: `${abusDemedicament.length}`,
		},
		{
			total: `${autrq13ba.length}`,
		},
	];

	const q13baa = [
		{ name: "Décès", color: "#f44336", total: `${dec.length}`, id: 1 },
		{
			name: "Maladies (par exemple hypertension)",
			color: "#e91e63",
			total: `${mal.length}`,
			id: 2,
		},
		{ name: "Blessures", color: "#9c27b0", total: `${bles.length}`, id: 3 },
		{ name: "Douleurs", color: "#673ab7", total: `${doun.length}`, id: 4 },
		{
			name: "Troubles gynécologiques et reproductifs",
			color: "#00A1AF",
			total: `${trblgyn.length}`,
			id: 5,
		},
		{
			name: "Invalidité / Handicap",
			color: "#FFDA83",
			total: `${handicp.length}`,
			id: 6,
		},
		{
			name: "Grossesse non voulue",
			color: "#A3A1FB",
			total: `${gross.length}`,
			id: 7,
		},
		{
			name: "Perte de l'enfant à naître",
			color: "#F48C47",
			total: `${perterDeLenfa.length}`,
			id: 8,
		},
		{
			name: "Abus d'alcool",
			color: "#55D8FE",
			total: `${abusDAlcool.length}`,
			id: 9,
		},
		{
			name: "Abus de drogues",
			color: "#1D384C",
			total: `${abusDeDrogue.length}`,
			id: 10,
		},
		{
			name: "Abus de médicaments",
			color: "#3f51b5",
			total: `${abusDemedicament.length}`,
			id: 11,
		},
		{ name: "Autres", color: "#009688", total: `${autrq13ba.length}`, id: 12 },
	];

	const risq = data.filter(
		(e) => e.question_13_b_b.indexOf("Risque de suicide") > -1
	);
	const peur = data.filter(
		(e) => e.question_13_b_b.indexOf("Peur et anxiété") > -1
	);
	const depr = data.filter((e) => e.question_13_b_b.indexOf("Dépression") > -1);
	const detrss = data.filter(
		(e) => e.question_13_b_b.indexOf("Détresse émotionnelle") > -1
	);
	const sentim = data.filter(
		(e) => e.question_13_b_b.indexOf("Sentiment de honte") > -1
	);
	const sntm = data.filter(
		(e) => e.question_13_b_b.indexOf("Sentiment de colère") > -1
	);
	const trblDeSom = data.filter(
		(e) => e.question_13_b_b.indexOf("Troubles de sommeil") > -1
	);
	const trblAlim = data.filter(
		(e) => e.question_13_b_b.indexOf("Troubles alimentaires") > -1
	);
	const ptDeLespoir = data.filter(
		(e) => e.question_13_b_b.indexOf("Perte d’espoir") > -1
	);
	const manqueDeConf = data.filter(
		(e) => e.question_13_b_b.indexOf("Manque de confiance en soi") > -1
	);
	const autrbq13bb = data.filter(
		(e) => e.question_13_b_b.indexOf("Autres") > -1
	);
	const q13bb = [
		{
			total: `${risq.length}`,
		},
		{
			total: `${peur.length}`,
		},
		{
			total: `${depr.length}`,
		},
		{
			total: `${detrss.length}`,
		},
		{
			total: `${sentim.length}`,
		},
		{
			total: `${sntm.length}`,
		},
		{
			total: `${trblDeSom.length}`,
		},
		{
			total: `${trblAlim.length}`,
		},
		{
			total: `${ptDeLespoir.length}`,
		},
		{
			total: `${manqueDeConf.length}`,
		},
		{
			total: `${autrbq13bb.length}`,
		},
	];
	const q13bbb = [
		{
			name: "Risque de suicide",
			color: "#f44336",
			total: `${risq.length}`,
			id: 1,
		},
		{
			name: "Peur et anxiété",
			color: "#e91e63",
			total: `${peur.length}`,
			id: 2,
		},
		{ name: "Dépression", color: "#9c27b0", total: `${depr.length}`, id: 3 },
		{
			name: "Détresse émotionnelle",
			color: "#673ab7",
			total: `${detrss.length}`,
			id: 4,
		},
		{
			name: "Sentiment de honte",
			color: "#00A1AF",
			total: `${sentim.length}`,
			id: 5,
		},
		{
			name: "Sentiment de colère",
			color: "#FFDA83",
			total: `${sntm.length}`,
			id: 6,
		},
		{
			name: "Troubles de sommeil",
			color: "#A3A1FB",
			total: `${trblDeSom.length}`,
			id: 7,
		},
		{
			name: "Troubles alimentaires",
			color: "#F48C47",
			total: `${trblAlim.length}`,
			id: 8,
		},
		{
			name: "Perte d’espoir",
			color: "#55D8FE",
			total: `${ptDeLespoir.length}`,
			id: 9,
		},
		{
			name: "Manque de confiance en soi",
			color: "#1D384C",
			total: `${manqueDeConf.length}`,
			id: 10,
		},
		{ name: "Autres", color: "#3f51b5", total: `${autrbq13bb.length}`, id: 11 },
	];

	const pterDeMoyeb = data.filter(
		(e) =>
			e.question_13_b_c.indexOf("Perte ou manque des moyens de subsistance") >
			-1
	);
	const manqDeRevenu = data.filter(
		(e) => e.question_13_b_c.indexOf("Perte ou manque de revenus") > -1
	);
	const sexDesur = data.filter(
		(e) => e.question_13_b_c.indexOf("Sexe de survie") > -1
	);
	const pteDeMaison = data.filter(
		(e) => e.question_13_b_c.indexOf("Perte de maison") > -1
	);
	const Demenag = data.filter(
		(e) => e.question_13_b_c.indexOf("Déménagements fréquents") > -1
	);
	const situatio = data.filter(
		(e) => e.question_13_b_c.indexOf("Situation de sans-abri") > -1
	);
	const pas = data.filter(
		(e) =>
			e.question_13_b_c.indexOf("Pas ou peu d'éducation ou de formation") > -1
	);
	const autrbq13bc = data.filter(
		(e) => e.question_13_b_c.indexOf("Autres") > -1
	);

	const q13c = [
		{
			total: `${pterDeMoyeb.length}`,
		},
		{
			total: `${manqDeRevenu.length}`,
		},
		{
			total: `${sexDesur.length}`,
		},
		{
			total: `${pteDeMaison.length}`,
		},
		{
			total: `${Demenag.length}`,
		},
		{
			total: `${situatio.length}`,
		},
		{
			total: `${pas.length}`,
		},
		{
			total: `${autrbq13bc.length}`,
		},
	];

	const q13cc = [
		{
			name: "Perte ou manque des moyens de subsistance",
			color: "#f44336",
			total: `${pterDeMoyeb.length}`,
			id: 1,
		},
		{
			name: "Perte ou manque de revenus",
			color: "#e91e63",
			total: `${manqDeRevenu.length}`,
			id: 2,
		},
		{
			name: "Sexe de survie",
			color: "#9c27b0",
			total: `${sexDesur.length}`,
			id: 3,
		},
		{
			name: "Perte de maison",
			color: "#673ab7",
			total: `${pteDeMaison.length}`,
			id: 4,
		},
		{
			name: "Déménagements fréquents",
			color: "#00A1AF",
			total: `${Demenag.length}`,
			id: 5,
		},
		{
			name: "Situation de sans-abri",
			color: "#FFDA83",
			total: `${situatio.length}`,
			id: 6,
		},
		{
			name: "Pas ou peu d'éducation ou de formation",
			color: "#A3A1FB",
			total: `${pas.length}`,
			id: 7,
		},
		{ name: "Autres", color: "#F48C47", total: `${autrbq13bc.length}`, id: 8 },
	];

	const rptureDeLaFam = data.filter(
		(e) =>
			e.question_13_b_d.indexOf(
				"Rupture de la famille (famille créée par la victime et l’auteur)"
			) > -1
	);
	const cnflits = data.filter(
		(e) => e.question_13_b_d.indexOf("Conflits domestiques réguliers") > -1
	);
	const abn = data.filter(
		(e) =>
			e.question_13_b_d.indexOf(
				"Abandon de la victime par sa famille d’origine (parents)"
			) > -1
	);
	const stigmat = data.filter(
		(e) =>
			e.question_13_b_d.indexOf(
				"Stigmatisation de la victime par la communauté"
			) > -1
	);
	const perteDelaGarde = data.filter(
		(e) =>
			e.question_13_b_d.indexOf(
				"Perte de la garde ou de contact avec propres enfants"
			) > -1
	);
	const isolmentSocial = data.filter(
		(e) => e.question_13_b_d.indexOf("Isolement social de la victime") > -1
	);
	const autrbq13bd = data.filter(
		(e) => e.question_13_b_d.indexOf("Autres") > -1
	);
	const q13bd = [
		{
			total: `${rptureDeLaFam.length}`,
		},
		{
			total: `${cnflits.length}`,
		},
		{
			total: `${abn.length}`,
		},
		{
			total: `${stigmat.length}`,
		},
		{
			total: `${perteDelaGarde.length}`,
		},
		{
			total: `${isolmentSocial.length}`,
		},
		{
			total: `${autrbq13bd.length}`,
		},
	];
	const q13bdd = [
		{
			name: "Rupture de la famille (famille créée par la victime et l’auteur)",
			color: "#f44336",
			total: `${rptureDeLaFam.length}`,
			id: 1,
		},
		{
			name: "Conflits domestiques réguliers",
			color: "#e91e63",
			total: `${cnflits.length}`,
			id: 2,
		},
		{
			name: "Abandon de la victime par sa famille d’origine (parents)",
			color: "#9c27b0",
			total: `${abn.length}`,
			id: 3,
		},
		{
			name: "Stigmatisation de la victime par la communauté",
			color: "#673ab7",
			total: `${stigmat.length}`,
			id: 4,
		},
		{
			name: "Perte de la garde ou de contact avec propres enfants",
			color: "#00A1AF",
			total: `${perteDelaGarde.length}`,
			id: 5,
		},
		{
			name: "Isolement social de la victime",
			color: "#FFDA83",
			total: `${isolmentSocial.length}`,
			id: 6,
		},
		{ name: "Autres", color: "#A3A1FB", total: `${autrbq13bd.length}`, id: 7 },
	];

	//question 14a
	const yesq14a = data.filter((e) => e.question_14_a === "Oui");
	const nonq14a = data.filter((e) => e.question_14_a === "Non");

	const q14aa = [
		{ name: "Oui", color: "#55D8FE", total: `${yesq14a.length}` },
		{ name: "Non", color: "#795548", total: `${nonq14a.length}` },
	];

	const q14a = [
		{
			total: `${yesq14a.length}`,
		},
		{
			total: `${nonq14a.length}`,
		},
	];

	const mer = data.filter((e) => e.question_14_a_oui.indexOf("Mère") > -1);
	const per = data.filter((e) => e.question_14_a_oui.indexOf("Père") > -1);
	const tut = data.filter(
		(e) => e.question_14_a_oui.indexOf("Tuteur / gardien (homme)>-1)") > -1
	);
	const tutfem = data.filter(
		(e) => e.question_14_a_oui.indexOf("Tuteur / gardienne (femme)>-1)") > -1
	);
	const propf = data.filter(
		(e) => e.question_14_a_oui.indexOf("Propres frères") > -1
	);
	const props = data.filter(
		(e) => e.question_14_a_oui.indexOf("Propres sœurs") > -1
	);
	const propenfa = data.filter(
		(e) => e.question_14_a_oui.indexOf("Propres enfants adultes") > -1
	);
	const gdme = data.filter(
		(e) => e.question_14_a_oui.indexOf("Grand-mère") > -1
	);
	const gdper = data.filter(
		(e) => e.question_14_a_oui.indexOf("Grand-père") > -1
	);
	const belmer = data.filter(
		(e) => e.question_14_a_oui.indexOf("Belle-mère") > -1
	);
	const boper = data.filter(
		(e) => e.question_14_a_oui.indexOf("Beau-père") > -1
	);
	const bofer = data.filter(
		(e) => e.question_14_a_oui.indexOf("Beaux-frères") > -1
	);
	const belso = data.filter(
		(e) => e.question_14_a_oui.indexOf("Belles-sœurs") > -1
	);
	const oncl = data.filter((e) => e.question_14_a_oui.indexOf("Oncles") > -1);
	const tan = data.filter((e) => e.question_14_a_oui.indexOf("Tantes") > -1);
	const autrbq14a = data.filter(
		(e) =>
			e.question_14_a_oui.indexOf(
				"Autre(s) membre(s) de la famille de la victime"
			) > -1
	);

	const q14aouip = [
		{
			total: `${mer.length}`,
		},
		{
			total: `${per.length}`,
		},
		{
			total: `${tut.length}`,
		},
		{
			total: `${tutfem.length}`,
		},
		{
			total: `${propf.length}`,
		},
		{
			total: `${props.length}`,
		},
		{
			total: `${propenfa.length}`,
		},
		{
			total: `${gdme.length}`,
		},
		{
			total: `${gdper.length}`,
		},
		{
			total: `${belmer.length}`,
		},
		{
			total: `${boper.length}`,
		},
		{
			total: `${bofer.length}`,
		},
		{
			total: `${belso.length}`,
		},
		{
			total: `${oncl.length}`,
		},
		{
			total: `${tan.length}`,
		},
		{
			total: `${autrbq14a.length}`,
		},
	];

	const q14aoui = [
		{ name: "Mère", color: "#f44336", total: `${mer.length}` },
		{ name: "Père", color: "#e91e63", total: `${per.length}` },
		{
			name: "Tuteur / gardien (homme)",
			color: "#9c27b0",
			total: `${tut.length}`,
		},
		{
			name: "Tuteur / gardienne (femme)",
			color: "#673ab7",
			total: `${tutfem.length}`,
		},
		{ name: "Propres frères", color: "#00A1AF", total: `${propf.length}` },
		{ name: "Propres sœurs", color: "#FFDA83", total: `${props.length}` },
		{
			name: "Propres enfants adultes",
			color: "#A3A1FB",
			total: `${propenfa.length}`,
		},
		{ name: "Grand-mère", color: "#55D8FE", total: `${gdme.length}` },
		{ name: "Grand-père", color: "#1D384C", total: `${gdper.length}` },
		{ name: "Belle-mère", color: "#3f51b5", total: `${belmer.length}` },
		{ name: "Beau-père", color: "#F48C47", total: `${boper.length}` },
		{ name: "Beaux-frères", color: "#009688", total: `${bofer.length}` },
		{ name: "Belles-sœurs", color: "#4caf50", total: `${belso.length}` },
		{ name: "Oncles", color: "#795548", total: `${oncl.length}` },
		{ name: "Tantes", color: "#9e9e9e", total: `${tan.length}` },
		{
			name: "Autre(s) membre(s) de la famille de la victime",
			color: "#cfcfcf",
			total: `${autrbq14a.length}`,
		},
	];

	const yesq14b = data.filter((e) => e.question_14_b === "Oui");
	const nonq14b = data.filter((e) => e.question_14_b === "Non");

	const q14bb = [
		{ name: "Oui", color: "#55D8FE", total: `${yesq14b.length}` },
		{ name: "Non", color: "#795548", total: `${nonq14b.length}` },
	];

	const q14b = [
		{
			total: `${yesq14b.length}`,
		},
		{
			total: `${nonq14b.length}`,
		},
	];

	const mari = data.filter((e) => e.question_14_b_oui.indexOf("Mari") > -1);
	const exmari = data.filter(
		(e) => e.question_14_b_oui.indexOf("Ex-mari") > -1
	);
	const epo = data.filter((e) => e.question_14_b_oui.indexOf("Epouse") > -1);
	const expo = data.filter(
		(e) => e.question_14_b_oui.indexOf("Ex-épouse") > -1
	);
	const partinthom = data.filter(
		(e) =>
			e.question_14_b_oui.indexOf("Partenaire intime actuel qui est homme") > -1
	);
	const partintfem = data.filter(
		(e) =>
			e.question_14_b_oui.indexOf("Partenaire intime actuel qui est femme") > -1
	);
	const expartinthom = data.filter(
		(e) =>
			e.question_14_b_oui.indexOf("Ex-partenaire intime qui est homme") > -1
	);
	const expartintfem = data.filter(
		(e) =>
			e.question_14_b_oui.indexOf("Ex-partenaire intime qui est femme") > -1
	);
	const q14bouip = [
		{
			total: `${mari.length}`,
		},
		{
			total: `${exmari.length}`,
		},
		{
			total: `${epo.length}`,
		},
		{
			total: `${expo.length}`,
		},
		{
			total: `${partinthom.length}`,
		},
		{
			total: `${partintfem.length}`,
		},
		{
			total: `${expartinthom.length}`,
		},
		{
			total: `${expartintfem.length}`,
		},
	];
	const q14boui = [
		{ name: "Mari", color: "#f44336", total: `${mari.length}` },
		{ name: "Ex-mari", color: "#9e9e9e", total: `${exmari.length}` },
		{ name: "Epouse", color: "#795548", total: `${epo.length}` },
		{ name: "Ex-épouse", color: "#4caf50", total: `${expo.length}` },
		{
			name: "Partenaire intime actuel qui est homme",
			color: "#009688",
			total: `${partinthom.length}`,
		},
		{
			name: "Partenaire intime actuel qui est femme",
			color: "#3f51b5",
			total: `${partintfem.length}`,
		},
		{
			name: "Ex-partenaire intime qui est homme",
			color: "#1D384C",
			total: `${expartinthom.length}`,
		},
		{
			name: "Ex-partenaire intime qui est femme",
			color: "#55D8FE",
			total: `${expartintfem.length}`,
		},
	];

	const patr = data.filter(
		(e) =>
			e.question_14_c.indexOf("Patron (homme) de personnel domestique") > -1
	);
	const patrn = data.filter(
		(e) =>
			e.question_14_c.indexOf("Patronne (femme) de personnel domestique") > -1
	);
	const pers = data.filter(
		(e) => e.question_14_c.indexOf("Personnel domestique (homme)") > -1
	);
	const persfem = data.filter(
		(e) => e.question_14_c.indexOf("Personnel domestique (femme)") > -1
	);
	const bail = data.filter(
		(e) =>
			e.question_14_c.indexOf("Bailleur / propriétaire de la maison (homme)") >
			-1
	);
	const bailress = data.filter(
		(e) =>
			e.question_14_c.indexOf(
				"Bailleresse / propriétaire de la maison (femme)"
			) > -1
	);
	const autrq14c = data.filter(
		(e) =>
			e.question_14_c.indexOf(
				"Autre(s) membre(s) du cercle restreint de la victime"
			) > -1
	);
	const q14c = [
		{
			total: `${patr.length}`,
		},
		{
			total: `${patrn.length}`,
		},
		{
			total: `${pers.length}`,
		},
		{
			total: `${persfem.length}`,
		},
		{
			total: `${bail.length}`,
		},
		{
			total: `${bailress.length}`,
		},
		{
			total: `${autrq14c.length}`,
		},
	];
	const q14cc = [
		{
			name: "Patron (homme) de personnel domestique",
			color: "#f44336",
			total: `${patr.length}`,
		},
		{
			name: "Patronne (femme) de personnel domestique",
			color: "#1D384C",
			total: `${patrn.length}`,
		},
		{
			name: "Personnel domestique (homme)",
			color: "#3f51b5",
			total: `${pers.length}`,
		},
		{
			name: "Personnel domestique (femme)",
			color: "#009688",
			total: `${persfem.length}`,
		},
		{
			name: "Bailleur / propriétaire de la maison (homme)",
			color: "#4caf50",
			total: `${bail.length}`,
		},
		{
			name: "Bailleresse / propriétaire de la maison (femme)",
			color: "#795548",
			total: `${bailress.length}`,
		},
		{
			name: "Autre(s) membre(s) du cercle restreint de la victime",
			color: "#9e9e9e",
			total: `${autrq14c.length}`,
		},
	];

	const yesq15 = data.filter((e) => e.question_15 === "Oui");
	const nonq15 = data.filter((e) => e.question_15 === "Non");

	const q15p = [
		{ name: "Oui", color: "#55D8FE", total: `${yesq15.length}` },
		{ name: "Non", color: "#795548", total: `${nonq15.length}` },
	];

	const q15 = [
		{
			total: `${yesq15.length}`,
		},
		{
			total: `${nonq15.length}`,
		},
	];
	const mmenage = data.filter(
		(e) => e.question_15_oui.indexOf("Même ménage") > -1
	);
	const mparc = data.filter(
		(e) => e.question_15_oui.indexOf("Même parcelle") > -1
	);
	const mezo = data.filter(
		(e) => e.question_15_oui.indexOf("Maison d’enfants") > -1
	);
	const camp = data.filter(
		(e) => e.question_15_oui.indexOf("Camp de réfugiés") > -1
	);
	const q15ouip = [
		{
			total: `${mmenage.length}`,
		},
		{
			total: `${mparc.length}`,
		},
		{
			total: `${mezo.length}`,
		},
		{
			total: `${camp.length}`,
		},
	];
	const q15oui = [
		{ name: "Même ménage", color: "#f44336", total: `${mmenage.length}` },
		{ name: "Même parcelle", color: "#9e9e9e", total: `${mparc.length}` },
		{ name: "Maison d’enfants", color: "#3f51b5", total: `${mezo.length}` },
		{ name: "Camp de réfugiés", color: "#F48C47", total: `${camp.length}` },
	];

	//question 16

	const yesq16 = data.filter((e) => e.question_16 === "Oui");
	const nonq16 = data.filter((e) => e.question_16 === "Non");

	const q16p = [
		{ name: "Oui", color: "#55D8FE", total: `${yesq16.length}` },
		{ name: "Non", color: "#795548", total: `${nonq16.length}` },
	];

	const q16 = [
		{
			total: `${yesq16.length}`,
		},
		{
			total: `${nonq16.length}`,
		},
	];

	const yesq16oui = data.filter((e) => e.question_16_oui === "Oui");
	const nonq16oui = data.filter((e) => e.question_16_oui === "Non");

	const q16ouip = [
		{ name: "Oui", color: "#55D8FE", total: `${yesq16oui.length}` },
		{ name: "Non", color: "#795548", total: `${nonq16oui.length}` },
	];
	//console.log(yesq16oui);
	const q16oui = [
		{
			total: `${yesq16oui.length}`,
		},
		{
			total: `${nonq16oui.length}`,
		},
	];

	const yesq17 = data.filter((e) => e.question_17 === "Oui");
	const nonq17 = data.filter((e) => e.question_17 === "Non");

	const q17p = [
		{ name: "Oui", color: "#55D8FE", total: `${yesq17.length}` },
		{ name: "Non", color: "#795548", total: `${nonq17.length}` },
	];

	const q17 = [
		{
			total: `${yesq17.length}`,
		},
		{
			total: `${nonq17.length}`,
		},
	];

	const yesq18 = data.filter((e) => e.question_18 === "Oui");
	const nonq18 = data.filter((e) => e.question_18 === "Non");

	const q18p = [
		{ name: "Oui", color: "#55D8FE", total: `${yesq18.length}` },
		{ name: "Non", color: "#795548", total: `${nonq18.length}` },
	];

	const q18 = [
		{
			total: `${yesq18.length}`,
		},
		{
			total: `${nonq18.length}`,
		},
	];

	const med = data.filter(
		(e) => e.question_18_a.indexOf("Soins médicaux") > -1
	);
	const assistpysc = data.filter(
		(e) => e.question_18_a.indexOf("Assistance psychosociale") > -1
	);
	const assistMed = data.filter(
		(e) => e.question_18_a.indexOf("Assistance en médiation familiale") > -1
	);
	const assistJuri = data.filter(
		(e) => e.question_18_a.indexOf("Assistance juridique") > -1
	);
	const assistpenal = data.filter(
		(e) =>
			e.question_18_a.indexOf(
				"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale"
			) > -1
	);
	const assistcivile = data.filter(
		(e) =>
			e.question_18_a.indexOf(
				"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile"
			) > -1
	);
	const assistSocisl = data.filter(
		(e) => e.question_18_a.indexOf("Assistance socio-économique") > -1
	);
	const dev = data.filter(
		(e) =>
			e.question_18_a.indexOf(
				"Développement d’un plan de sécurité pour la victime de VD"
			) > -1
	);
	const q18ap = [
		{
			total: `${med.length}`,
		},
		{
			total: `${assistpysc.length}`,
		},
		{
			total: `${assistMed.length}`,
		},
		{
			total: `${assistJuri.length}`,
		},
		{
			total: `${assistpenal.length}`,
		},
		{
			total: `${assistcivile.length}`,
		},
		{
			total: `${assistSocisl.length}`,
		},
		{
			total: `${dev.length}`,
		},
	];
	const q18a = [
		{ name: "Soins médicaux", color: "#f44336", total: `${med.length}` },
		{
			name: "Assistance psychosociale",
			color: "#9e9e9e",
			total: `${assistpysc.length}`,
		},
		{
			name: "Assistance en médiation familiale",
			color: "#795548",
			total: `${assistMed.length}`,
		},
		{
			name: "Assistance juridique",
			color: "#4caf50",
			total: `${assistJuri.length}`,
		},
		{
			name: "Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale",
			color: "#009688",
			total: `${assistpenal.length}`,
		},
		{
			name: "Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile",
			color: "#3f51b5",
			total: `${assistcivile.length}`,
		},
		{
			name: "Assistance socio-économique",
			color: "#1D384C",
			total: `${assistSocisl.length}`,
		},
		{
			name: "Développement d’un plan de sécurité pour la victime de VD",
			color: "#55D8FE",
			total: `${dev.length}`,
		},
	];

	const yesq19 = data.filter((e) => e.question_19 === "Oui");
	const nonq19 = data.filter((e) => e.question_19 === "Non");

	// console.log(yesq19);
	const q19p = [
		{ name: "Oui", color: "#55D8FE", total: `${yesq19.length}` },
		{ name: "Non", color: "#795548", total: `${nonq19.length}` },
	];

	const q19 = [
		{
			total: `${yesq19.length}`,
		},
		{
			total: `${nonq19.length}`,
		},
	];
	const soinMed = data.filter(
		(e) => e.question_19_oui.indexOf("Soins médicaux") > -1
	);
	const autsoinMedDans = data.filter(
		(e) =>
			e.question_19_oui.indexOf(
				"Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)"
			) > -1
	);
	const assPsy = data.filter(
		(e) => e.question_19_oui.indexOf("Assistance psychosociale") > -1
	);
	const assMedFam = data.filter(
		(e) => e.question_19_oui.indexOf("Assistance en médiation familiale") > -1
	);
	const assJuri = data.filter(
		(e) => e.question_19_oui.indexOf("Assistance juridique") > -1
	);
	const assPenal = data.filter(
		(e) =>
			e.question_19_oui.indexOf(
				"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale"
			) > -1
	);
	const assCivle = data.filter(
		(e) =>
			e.question_19_oui.indexOf(
				"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile"
			) > -1
	);
	const assSocio = data.filter(
		(e) => e.question_19_oui.indexOf("Assistance socio-économique") > -1
	);
	const development = data.filter(
		(e) =>
			e.question_19_oui.indexOf(
				"Développement d’un plan de sécurité pour la victime de VD"
			) > -1
	);
	const q19ouip = [
		{ total: `${soinMed.length}` },
		{ total: `${autsoinMedDans.length}` },
		{ total: `${assPsy.length}` },
		{ total: `${assMedFam.length}` },
		{ total: `${assJuri.length}` },
		{ total: `${assPenal.length}` },
		{ total: `${assCivle.length}` },
		{ total: `${assSocio.length}` },
		{ total: `${development.length}` },
	];
	// console.log(q19ouip);
	const q19oui = [
		{ name: "Soins médicaux", color: "#f44336", total: `${soinMed.length}` },
		{
			name: "Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)",
			color: "#F48C47",
			total: `${autsoinMedDans.length}`,
		},
		{
			name: "Assistance psychosociale",
			color: "#55D8FE",
			total: `${assPsy.length}`,
		},
		{
			name: "Assistance en médiation familiale",
			color: "#1D384C",
			total: `${assMedFam.length}`,
		},
		{
			name: "Assistance juridique",
			color: "#3f51b5",
			total: `${assJuri.length}`,
		},
		{
			name: "Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale",
			color: "#009688",
			total: `${assPenal.length}`,
		},
		{
			name: "Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile",
			color: "#4caf50",
			total: `${assCivle.length}`,
		},
		{
			name: "Assistance socio-économique",
			color: "#795548",
			total: `${assSocio.length}`,
		},
		{
			name: "Développement d’un plan de sécurité pour la victime de VD",
			color: "#9e9e9e",
			total: `${development.length}`,
		},
	];

	const soinMed1 = data.filter(
		(e) => e.question_20.indexOf("Soins médicaux") > -1
	);
	const autsoinMedDans1 = data.filter(
		(e) =>
			e.question_20.indexOf(
				"Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)"
			) > -1
	);
	const assPsy1 = data.filter(
		(e) => e.question_20.indexOf("Assistance psychosociale") > -1
	);
	const assMedFam1 = data.filter(
		(e) => e.question_20.indexOf("Assistance en médiation familiale") > -1
	);
	const assJuri1 = data.filter(
		(e) => e.question_20.indexOf("Assistance juridique") > -1
	);
	const assPenal1 = data.filter(
		(e) =>
			e.question_20.indexOf(
				"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale"
			) > -1
	);
	const assCivle1 = data.filter(
		(e) =>
			e.question_20.indexOf(
				"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile"
			) > -1
	);
	const assSocio1 = data.filter(
		(e) => e.question_20.indexOf("Assistance socio-économique") > -1
	);
	const development1 = data.filter(
		(e) =>
			e.question_20.indexOf(
				"Développement d’un plan de sécurité pour la victime de VD"
			) > -1
	);

	const q20p = [
		{ total: `${soinMed1.length}` },
		{ total: `${autsoinMedDans1.length}` },
		{ total: `${assPsy1.length}` },
		{ total: `${assMedFam1.length}` },
		{ total: `${assJuri1.length}` },
		{ total: `${assPenal1.length}` },
		{ total: `${assCivle1.length}` },
		{ total: `${assSocio1.length}` },
		{ total: `${development1.length}` },
	];

	const q20 = [
		{ name: "Soins médicaux", color: "#f44336", total: `${soinMed1.length}` },
		{
			name: "Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)",
			color: "#e91e63",
			total: `${autsoinMedDans1.length}`,
		},
		{
			name: "Assistance psychosociale",
			color: "#9c27b0",
			total: `${assPsy1.length}`,
		},
		{
			name: "Assistance en médiation familiale",
			color: "#00A1AF",
			total: `${assMedFam1.length}`,
		},
		{
			name: "Assistance juridique",
			color: "#FFDA83",
			total: `${assJuri1.length}`,
		},
		{
			name: "Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale",
			color: "#A3A1FB",
			total: `${assPenal1.length}`,
		},
		{
			name: "Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile",
			color: "#F48C47",
			total: `${assCivle1.length}`,
		},
		{
			name: "Assistance socio-économique",
			color: "#55D8FE",
			total: `${assSocio1.length}`,
		},
		{
			name: "Développement d’un plan de sécurité pour la victime de VD",
			color: "#3f51b5",
			total: `${development1.length}`,
		},
	];

	const soinMed2 = data.filter(
		(e) => e.question_21.indexOf("Soins médicaux") > -1
	);
	const autsoinMedDans2 = data.filter(
		(e) =>
			e.question_21.indexOf(
				"Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)"
			) > -1
	);
	const assPsy2 = data.filter(
		(e) => e.question_21.indexOf("Assistance psychosociale") > -1
	);
	const assMedFam2 = data.filter(
		(e) => e.question_21.indexOf("Assistance en médiation familiale") > -1
	);
	const assJuri2 = data.filter(
		(e) => e.question_21.indexOf("Assistance juridique") > -1
	);
	const assPenal2 = data.filter(
		(e) =>
			e.question_21.indexOf(
				"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale"
			) > -1
	);
	const assCivle2 = data.filter(
		(e) =>
			e.question_21.indexOf(
				"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile"
			) > -1
	);
	const assSocio2 = data.filter(
		(e) => e.question_21.indexOf("Assistance socio-économique") > -1
	);
	const develop = data.filter(
		(e) =>
			e.question_21.indexOf(
				"Développement d’un plan de sécurité pour la victime de VD"
			) > -1
	);
	const situa = data.filter(
		(e) =>
			e.question_21.indexOf(
				"Cette situation ne s’applique pas au cas documenté comme notre organisation peut fournir tous les services demandés"
			) > -1
	);
	const q21p = [
		{ total: `${soinMed2.length}` },
		{ total: `${autsoinMedDans2.length}` },
		{ total: `${assPsy2.length}` },
		{ total: `${assMedFam2.length}` },
		{ total: `${assJuri2.length}` },
		{ total: `${assPenal2.length}` },
		{ total: `${assCivle2.length}` },
		{ total: `${assSocio2.length}` },
		{ total: `${develop.length}` },
		{ total: `${situa.length}` },
	];
	const q21 = [
		{ name: "Soins médicaux", color: "#f44336", total: `${soinMed2.length}` },
		{
			name: "Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)",
			color: "#00A1AF",
			total: `${autsoinMedDans2.length}`,
		},
		{
			name: "Assistance psychosociale",
			color: "#FFDA83",
			total: `${assPsy2.length}`,
		},
		{
			name: "Assistance en médiation familiale",
			color: "#A3A1FB",
			total: `${assMedFam2.length}`,
		},
		{
			name: "Assistance juridique",
			color: "#F48C47",
			total: `${assJuri2.length}`,
		},
		{
			name: "Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale",
			color: "#55D8FE",
			total: `${assPenal2.length}`,
		},
		{
			name: "Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile",
			color: "#1D384C",
			total: `${assCivle2.length}`,
		},
		{
			name: "Assistance socio-économique",
			color: "#3f51b5",
			total: `${assSocio2.length}`,
		},
		{
			name: "Développement d’un plan de sécurité pour la victime de VD",
			color: "#795548",
			total: `${develop.length}`,
		},
		{
			name: "Cette situation ne s’applique pas au cas documenté comme notre organisation peut fournir tous les services demandés",
			color: "#9e9e9e",
			total: `${situa.length}`,
		},
	];

	const soinMed3 = data.filter(
		(e) => e.question_22.indexOf("Soins médicaux") > -1
	);
	const autsoinMedDans3 = data.filter(
		(e) =>
			e.question_22.indexOf(
				"Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)"
			) > -1
	);
	const assPsy3 = data.filter(
		(e) => e.question_22.indexOf("Assistance psychosociale") > -1
	);
	const assMedFam3 = data.filter(
		(e) => e.question_22.indexOf("Assistance en médiation familiale") > -1
	);
	const assJuri3 = data.filter(
		(e) => e.question_22.indexOf("Assistance juridique") > -1
	);
	const assPenal3 = data.filter(
		(e) =>
			e.question_22.indexOf(
				"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale"
			) > -1
	);
	const assCivle3 = data.filter(
		(e) =>
			e.question_22.indexOf(
				"Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile"
			) > -1
	);
	const assSocio3 = data.filter(
		(e) => e.question_22.indexOf("Assistance socio-économique") > -1
	);
	const develop1 = data.filter(
		(e) =>
			e.question_22.indexOf(
				"Développement d’un plan de sécurité pour la victime de VD"
			) > -1
	);
	const situa1 = data.filter(
		(e) =>
			e.question_22.indexOf(
				"Cette situation ne s’applique pas au cas documenté comme tous les services demandés peuvent être fournis par notre organisation et/ou nos partenaires de référence."
			) > -1
	);

	const q22p = [
		{ total: `${soinMed3.length}` },
		{ total: `${autsoinMedDans3.length}` },
		{ total: `${assPsy3.length}` },
		{ total: `${assMedFam3.length}` },
		{ total: `${assJuri3.length}` },
		{ total: `${assPenal3.length}` },
		{ total: `${assCivle3.length}` },
		{ total: `${assSocio3.length}` },
		{ total: `${develop1.length}` },
		{ total: `${situa1.length}` },
	];

	const q22 = [
		{ name: "Soins médicaux", color: "#e91e63", total: `${soinMed3.length}` },
		{
			name: "Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)",
			color: "#9c27b0",
			total: `${autsoinMedDans3.length}`,
		},
		{
			name: "Assistance psychosociale",
			color: "#673ab7",
			total: `${assPsy3.length}`,
		},
		{
			name: "Assistance en médiation familiale",
			color: "#00A1AF",
			total: `${assMedFam3.length}`,
		},
		{
			name: "Assistance juridique",
			color: "#FFDA83",
			total: `${assJuri3.length}`,
		},
		{
			name: "Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale",
			color: "#A3A1FB",
			total: `${assPenal3.length}`,
		},
		{
			name: "Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile",
			color: "#F48C47",
			total: `${assCivle3.length}`,
		},
		{
			name: "Assistance socio-économique",
			color: "#55D8FE",
			total: `${assSocio3.length}`,
		},
		{
			name: "Développement d’un plan de sécurité pour la victime de VD",
			color: "#3f51b5",
			total: `${develop1.length}`,
		},
		{
			name: "Cette situation ne s’applique pas au cas documenté comme tous les services demandés peuvent être fournis par notre organisation et/ou nos partenaires de référence.",
			color: "#4caf50",
			total: `${situa1.length}`,
		},
	];

	const yesq23 = data.filter((e) => e.question_23 === "Oui");
	const nonq23 = data.filter((e) => e.question_23 === "Non");

	const q23p = [
		{ name: "Oui", color: "#55D8FE", total: `${yesq23.length}` },
		{ name: "Non", color: "#795548", total: `${nonq23.length}` },
	];

	const q23 = [
		{
			total: `${yesq23.length}`,
		},
		{
			total: `${nonq23.length}`,
		},
	];

	const yesq24 = data.filter(
		(e) =>
			e.question_24 ===
			"Oui, la victime cherche à quitter son foyer de façon permanente"
	);
	const yesq24s = data.filter(
		(e) =>
			e.question_24 ===
			"Oui, la victime cherche à quitter son foyer de façon temporaire"
	);
	const nonq24 = data.filter(
		(e) => e.question_24 === "Non, la victime cherche à rester chez elle"
	);
	const q24p = [
		{
			name: "Oui, la victime cherche à quitter son foyer de façon permanente",
			color: "#55D8FE",
			total: `${yesq24.length}`,
		},
		{
			name: "Oui, la victime cherche à quitter son foyer de façon temporaire",
			color: "#795548",
			total: `${yesq24s.length}`,
		},
		{
			name: "Non, la victime cherche à rester chez elle",
			color: "#795548",
			total: `${nonq24.length}`,
		},
	];

	const q24 = [
		{
			total: `${yesq24.length}`,
		},
		{
			total: `${yesq24s.length}`,
		},
		{
			total: `${nonq24.length}`,
		},
	];

	const yesq24a = data.filter((e) => e.question_24a === "Oui");
	const nonq24a = data.filter((e) => e.question_24a === "Non");

	const q24ap = [
		{ name: "Oui", color: "#55D8FE", total: `${yesq24a.length}` },
		{ name: "Non", color: "#795548", total: `${nonq24a.length}` },
	];

	const q24a = [
		{
			total: `${yesq24a.length}`,
		},
		{
			total: `${nonq24a.length}`,
		},
	];

	const yesq25 = data.filter((e) => e.question_25 === "Oui");
	const nonq25 = data.filter((e) => e.question_25 === "Non");

	const q25p = [
		{ name: "Oui", color: "#55D8FE", total: `${yesq25.length}` },
		{ name: "Non", color: "#795548", total: `${nonq25.length}` },
	];

	const q25 = [
		{
			total: `${yesq25.length}`,
		},
		{
			total: `${nonq25.length}`,
		},
	];

	const yesq27 = data.filter(
		(e) =>
			e.question_27 ===
			"Avec localisation (province et territoire ou ville provinciale) et âge'"
	);
	const nonq27 = data.filter((e) => e.question_27 === "Complétement anonyme");

	const q27p = [
		{ name: "Avec localisation ", color: "#55D8FE", total: `${yesq27.length}` },
		{
			name: "Complétement anonyme",
			color: "#795548",
			total: `${nonq27.length}`,
		},
	];

	const q27 = [
		{
			total: `${yesq27.length}`,
		},
		{
			total: `${nonq27.length}`,
		},
	];
	const mycolor = [
		"#f44336",
		"#e91e63",
		"#9c27b0",
		"#673ab7",
		"#00A1AF",
		"#FFDA83",
		"#A3A1FB",
		"#F48C47",
		"#55D8FE",
		"#1D384C",
		"#3f51b5",
		"#009688",
		"#4caf50",
		"#795548",
		"#9e9e9e",
		"#cfcfcf",
	];
	const displayChoosenQuestion = (value) => {
		if (value === 0) {
			return (
				<Grid container direction="row" spacing={4}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							6. Groupe d’âge de la/du survivant(e)
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6}>
						<DoughnutCharts
							data={grpdAge}
							width={200}
							height={200}
							innerRadius="50%"
							outerRadius="100%"
							//typography="Groupe d’âge de la victime"
							filledColor={grpdAge.map((entry, index) => (
								<Cell
									key={`cell-${index}`}
									fill={grpeColors[index % grpeColors.length]}
								/>
							))}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Grid container direction="row">
							<Grid item xs={12} sm={12}>
								<Grid container direction="column" spacing={1}>
									<Grid item xs={12} sm={12}>
										<Grid container direction="row">
											<Grid item xs={1} sm={1}>
												<RadioButtonUncheckedIcon
													style={{
														fontSize: 12,
														color: "#FFDA83",
														marginRight: 10,
													}}
												/>
											</Grid>
											<Grid item xs={10} sm={10}>
												<Typography className={classes.typosubchart}>
													0 à 5 ans
												</Typography>{" "}
											</Grid>
											<Grid item xs={1} sm={1}>
												<Typography className={classes.typosubchart}>
													{ans5}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={12}>
										<Grid container direction="row">
											<Grid item xs={1} sm={1}>
												<RadioButtonUncheckedIcon
													style={{ fontSize: 12, color: "#A3A1FB" }}
												/>
											</Grid>
											<Grid item xs={10} sm={10}>
												<Typography className={classes.typosubchart}>
													6 à 17 ans
												</Typography>{" "}
											</Grid>
											<Grid item xs={1} sm={1}>
												<Typography className={classes.typosubchart}>
													{ans17}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={12}>
										<Grid container direction="row">
											<Grid item xs={1} sm={1}>
												<RadioButtonUncheckedIcon
													style={{ fontSize: 12, color: "#D12028" }}
												/>
											</Grid>
											<Grid item xs={10} sm={10}>
												<Typography className={classes.typosubchart}>
													18 à 29 ans
												</Typography>{" "}
											</Grid>
											<Grid item xs={1} sm={1}>
												<Typography className={classes.typosubchart}>
													{ans29}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={12}>
										<Grid container direction="row">
											<Grid item xs={1} sm={1}>
												<RadioButtonUncheckedIcon
													style={{ fontSize: 12, color: "#F48C47" }}
												/>
											</Grid>
											<Grid item xs={10} sm={10}>
												<Typography className={classes.typosubchart}>
													30 à 49 ans
												</Typography>{" "}
											</Grid>
											<Grid item xs={1} sm={1}>
												<Typography className={classes.typosubchart}>
													{ans49}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={12}>
										<Grid container direction="row">
											<Grid item xs={1} sm={1}>
												<RadioButtonUncheckedIcon
													style={{ fontSize: 12, color: "#1D384C" }}
												/>
											</Grid>
											<Grid item xs={10} sm={10}>
												<Typography className={classes.typosubchart}>
													50 à 64 ans
												</Typography>{" "}
											</Grid>
											<Grid item xs={1} sm={1}>
												<Typography className={classes.typosubchart}>
													{ans64}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Grid container direction="row">
											<Grid item xs={1} sm={1}>
												<RadioButtonUncheckedIcon
													style={{ fontSize: 12, color: "#60C1CB" }}
												/>
											</Grid>
											<Grid item xs={10} sm={10}>
												<Typography className={classes.typosubchart}>
													65 ans et plus
												</Typography>{" "}
											</Grid>
											<Grid item xs={1} sm={1}>
												<Typography className={classes.typosubchart}>
													{ans65}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 1) {
			return (
				<Grid
					container
					direction="row"
					className={classes.leftAndRightAndTopAndBottom}
					spacing={4}
				>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							7. Genre de la/du survivant(e)
						</Typography>
					</Grid>

					<Grid item xs={12} sm={6}>
						<BarCharts
							data={genData}
							filledcolor={genData.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={genderColor[index % 20]} />
							))}
							//  feminin={feminin.length}
							//   masculin={masculin.length}
							//    autre={autre.length}
							//  name="Gender"
						/>
					</Grid>
					<Grid item xs={6}>
						<Grid container>
							<Grid item xs={12}>
								<Grid container direction="row">
									<Grid item xs={6}>
										<Grid container direction="row">
											<Grid item>
												<StopIcon style={{ fontSize: 12, color: "#00A1AF" }} />
											</Grid>
											<Grid item>
												<Typography className={classes.typosubchart}>
													Féminin
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={6}>
										<Grid
											container
											justifyContent="flex-end"
											alignItems="flex-end"
										>
											<Grid item>{feminin.length}</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid container direction="row">
									<Grid item xs={6}>
										<Grid container direction="row">
											<Grid item>
												<StopIcon style={{ fontSize: 12, color: "#D12028" }} />
											</Grid>
											<Grid item>
												<Typography className={classes.typosubchart}>
													Masculin
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={6}>
										<Grid
											container
											justifyContent="flex-end"
											alignItems="flex-end"
										>
											<Grid item>{masculin.length}</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid container direction="row">
									<Grid item xs={6}>
										<Grid container direction="row">
											<Grid item>
												<StopIcon style={{ fontSize: 12, color: "#1D384C" }} />
											</Grid>
											<Grid item>
												<Typography className={classes.typosubchart}>
													Autre
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={6}>
										<Grid
											container
											justifyContent="flex-end"
											alignItems="flex-end"
										>
											<Grid item>{autre.length}</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 2) {
			return (
				<Grid container spacing={4}>
					<Grid item xs={12} sm={12}>
						<Typography className={classes.detailTypoGrow}>
							8. Profil de vulnérabilité de la / du survivant(e)
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} style={{ backgroundColor: "#E0F3F5" }}>
						{" "}
						<Typography className={classes.answer_view}>
							{" "}
							a. Etat civil
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12}>
						<Paper>
							<Grid
								container
								direction="row"
								className={classes.leftAndRightAndTopAndBottom}
								spacing={4}
							>
								<Grid item xs={12} sm={6}>
									<BarCharts
										data={etatcivile}
										// name="Etat civil de la victime"
										filledcolor={etatcivile.map((entry, index) => (
											<Cell
												key={`cell-${index}`}
												fill={etatColor[index % 20]}
											/>
										))}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Grid container>
										<Grid item xs={12} sm={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#00A1AF" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																Célibataire
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{celib.length}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#FFDA83" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																Marié(e)
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{marie.length}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#A3A1FB" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																Séparé(e)
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{separe.length}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#F48C47" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																Divorcé(e)
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{divorce.length}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#55D8FE" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																Union de fait
																{/* (vivre ensemble sans être mariés) */}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{union.length}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#1D384C" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																Veuve/Veuf
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{veuf.length}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						style={{ backgroundColor: "#E0F3F5" }}
					>
						{" "}
						<Typography className={classes.answer_view}>
							{" "}
							b. Identification en tant que personne LGBTI
						</Typography>{" "}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Paper>
							<Grid
								container
								direction="row"
								className={classes.leftAndRightAndTopAndBottom}
								spacing={4}
							>
								<Grid item xs={12} sm={6}>
									<BarCharts
										data={q8b}
										// name="Etat civil de la victime"
										filledcolor={etatcivile.map((entry, index) => (
											<Cell
												key={`cell-${index}`}
												fill={etatColor[index % 20]}
											/>
										))}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Grid container>
										<Grid item xs={12} sm={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#00A1AF" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																Oui
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{yesq8b.length}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#FFDA83" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																Non
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{nonq8b.length}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						style={{ backgroundColor: "#E0F3F5" }}
					>
						{" "}
						<Typography className={classes.answer_view}>
							{" "}
							c. Situation de handicap (des handicaps physiques, psychiques,
							intellectuels ou sensoriels de longue durée)
						</Typography>{" "}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Paper>
							<Grid
								container
								direction="row"
								className={classes.leftAndRightAndTopAndBottom}
								spacing={4}
							>
								<Grid item xs={12} sm={6}>
									<BarCharts
										data={q8c}
										// name="Etat civil de la victime"
										filledcolor={etatcivile.map((entry, index) => (
											<Cell
												key={`cell-${index}`}
												fill={etatColor[index % 20]}
											/>
										))}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Grid container>
										<Grid item xs={12} sm={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#00A1AF" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																Oui
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{yesq8c.length}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#FFDA83" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																Non
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{nonq8c.length}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						style={{ backgroundColor: "#E0F3F5" }}
					>
						{" "}
						<Typography className={classes.answer_view}>
							{" "}
							d. Occupation
						</Typography>{" "}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Paper>
							<Grid
								container
								direction="row"
								className={classes.leftAndRightAndTopAndBottom}
								spacing={4}
							>
								<Grid item xs={12} sm={6}>
									<BarCharts
										data={q8d}
										// name="Etat civil de la victime"
										filledcolor={etatcivile.map((entry, index) => (
											<Cell
												key={`cell-${index}`}
												fill={etatColor[index % 20]}
											/>
										))}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Grid container>
										<Grid item xs={12} sm={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#00A1AF" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																Oui
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{yesq8d.length}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#FFDA83" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																Non
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{nonq8d.length}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						style={{ backgroundColor: "#E0F3F5" }}
					>
						{" "}
						<Typography className={classes.answer_view}>
							{" "}
							e. Mineur (moins de 18 ans)
						</Typography>{" "}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Paper>
							<Grid
								container
								direction="row"
								className={classes.leftAndRightAndTopAndBottom}
								spacing={4}
							>
								<Grid item xs={12} sm={6}>
									<BarCharts
										data={q8e}
										// name="Etat civil de la victime"
										filledcolor={etatcivile.map((entry, index) => (
											<Cell
												key={`cell-${index}`}
												fill={etatColor[index % 20]}
											/>
										))}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Grid container>
										<Grid item xs={12} sm={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#00A1AF" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																Oui
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{yesq8e.length}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#FFDA83" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																Non
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{nonq8e.length}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						style={{ backgroundColor: "#E0F3F5" }}
					>
						{" "}
						<Typography className={classes.answer_view}>
							f. Troisième âge (plus de 65 ans)
						</Typography>{" "}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Paper>
							<Grid
								container
								direction="row"
								className={classes.leftAndRightAndTopAndBottom}
								spacing={4}
							>
								<Grid item xs={12} sm={6}>
									<BarCharts
										data={q8f}
										// name="Etat civil de la victime"
										filledcolor={etatcivile.map((entry, index) => (
											<Cell
												key={`cell-${index}`}
												fill={etatColor[index % 20]}
											/>
										))}
									/>
									{/* {console.log(nonq8f.filter(Boolean).length)} */}
								</Grid>
								<Grid item xs={12} sm={6}>
									<Grid container>
										<Grid item xs={12} sm={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#00A1AF" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																Oui
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{yesq8f.length}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#FFDA83" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																Non
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{nonq8f.length}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			);
		} else if (value === 3) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							10. Types violence domestique
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={2} className={classes.margin_Bottom_20}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									{" "}
									Incindents de violence sexuelle{" "}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BarCharts
									data={q10a}
									// name="Etat civil de la victime"
									filledcolor={q10a.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={mycolor[index % 20]} />
									))}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{q10aa.map((e) => (
									<Grid container direction="row" key={e.id}>
										<Grid item xs={10}>
											<Grid container direction="row">
												<Grid item>
													<RadioButtonUncheckedIcon
														style={{
															fontSize: 12,
															color: `${e.color}`,
															marginRight: 5,
														}}
													/>
												</Grid>
												<Grid item>
													<Typography className={classes.typosubchart}>
														{e.name}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Grid
												container
												justifyContent="flex-end"
												alignItems="flex-end"
											>
												<Grid item className={classes.detailTypoGrow}>
													{e.total}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
					{/*  */}
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={2} className={classes.margin_Bottom_20}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									{" "}
									Incindents de violence physique{" "}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BarCharts
									data={q10b}
									// name="Etat civil de la victime"
									filledcolor={q10b.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={mycolor[index % 20]} />
									))}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{q10bb.map((e) => (
									<Grid container direction="row" key={e.id}>
										<Grid item xs={10}>
											<Grid container direction="row">
												<Grid item>
													<RadioButtonUncheckedIcon
														style={{
															fontSize: 12,
															color: `${e.color}`,
															marginRight: 5,
														}}
													/>
												</Grid>
												<Grid item>
													<Typography className={classes.typosubchart}>
														{e.name}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Grid
												container
												justifyContent="flex-end"
												alignItems="flex-end"
											>
												<Grid item className={classes.detailTypoGrow}>
													{e.total}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
					{/*  */}
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={2} className={classes.margin_Bottom_20}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									{" "}
									Incindents de violence psychologique{" "}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BarCharts
									data={q10c}
									// name="Etat civil de la victime"
									filledcolor={q10c.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={mycolor[index % 20]} />
									))}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{q10cc.map((e) => (
									<Grid container direction="row" key={e.id}>
										<Grid item xs={10}>
											<Grid container direction="row">
												<Grid item>
													<RadioButtonUncheckedIcon
														style={{
															fontSize: 12,
															color: `${e.color}`,
															marginRight: 5,
														}}
													/>
												</Grid>
												<Grid item>
													<Typography className={classes.typosubchart}>
														{e.name}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Grid
												container
												justifyContent="flex-end"
												alignItems="flex-end"
											>
												<Grid item className={classes.detailTypoGrow}>
													{e.total}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
					{/*  */}

					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={2} className={classes.margin_Bottom_20}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									{" "}
									Incindents de violence economique{" "}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BarCharts
									data={q10d}
									// name="Etat civil de la victime"
									filledcolor={q10d.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={mycolor[index % 20]} />
									))}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{q10dd.map((e) => (
									<Grid container direction="row" key={e.id}>
										<Grid item xs={10}>
											<Grid container direction="row">
												<Grid item>
													<RadioButtonUncheckedIcon
														style={{
															fontSize: 12,
															color: `${e.color}`,
															marginRight: 5,
														}}
													/>
												</Grid>
												<Grid item>
													<Typography className={classes.typosubchart}>
														{e.name}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Grid
												container
												justifyContent="flex-end"
												alignItems="flex-end"
											>
												<Grid item className={classes.detailTypoGrow}>
													{e.total}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>

					{/*  */}
				</Grid>
			);
		} else if (value === 4) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							11. S'agit-il d'une situation de violence continue ou d'un
							incident ponctuel ?
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={2} className={classes.margin_Bottom_20}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									{" "}
									a. S'agit-il d'une situation de violence continue?
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container direction="row" spacing={4}>
									<Grid item xs={12} sm={6}>
										<BarCharts
											data={q11a}
											// name="Etat civil de la victime"
											filledcolor={q11a.map((entry, index) => (
												<Cell
													key={`cell-${index}`}
													fill={mycolor[index % 20]}
												/>
											))}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										{q11aa.map((e) => (
											<Grid container direction="row">
												<Grid item xs={10}>
													<Grid container direction="row">
														<Grid item>
															<RadioButtonUncheckedIcon
																style={{
																	fontSize: 12,
																	color: `${e.color}`,
																	marginRight: 5,
																}}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{e.name}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={2}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{e.total}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										))}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container direction="row" spacing={3}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									b. S'agit-il d'un incident ponctuel ?{" "}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container direction="row" spacing={4}>
									<Grid item xs={12} sm={6}>
										<BarCharts
											data={q11b}
											// name="Etat civil de la victime"
											filledcolor={q11b.map((entry, index) => (
												<Cell
													key={`cell-${index}`}
													fill={mycolor[index % 20]}
												/>
											))}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										{q11bb.map((e) => (
											<Grid container direction="row">
												<Grid item xs={10}>
													<Grid container direction="row">
														<Grid item>
															<RadioButtonUncheckedIcon
																style={{
																	fontSize: 12,
																	color: `${e.color}`,
																	marginRight: 5,
																}}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{e.name}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={2}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{e.total}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										))}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 5) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							12. Où est-ce que cet acte de violence domestique a eu lieu ?
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4}>
							{/* <Grid item xs={12} sm={12} md ={12} lg={12} xl={12} style={{backgroundColor:'#E0F3F5'}}> <Typography className={classes.answer_view}> a. S'agit-il d'une situation de violence continue?</Typography></Grid> */}
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container direction="row" spacing={4}>
									<Grid item xs={12} sm={6}>
										<BarCharts
											data={q12b}
											// name="Etat civil de la victime"
											filledcolor={q12b.map((entry, index) => (
												<Cell
													key={`cell-${index}`}
													fill={mycolor[index % 20]}
												/>
											))}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										{q12bb.map((e) => (
											<Grid container direction="row">
												<Grid item xs={10}>
													<Grid container direction="row">
														<Grid item>
															<RadioButtonUncheckedIcon
																style={{
																	fontSize: 12,
																	color: `${e.color}`,
																	marginRight: 5,
																}}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{e.name}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={2}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{e.total}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										))}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 6) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							13. Catégories de préjudice causé
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4} className={classes.margin_Bottom_20}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									{" "}
									Préjudice physique{" "}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BarCharts
									data={q13b}
									// name="Etat civil de la victime"
									filledcolor={q13b.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={mycolor[index % 20]} />
									))}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{q13baa.map((e) => (
									<Grid container direction="row">
										<Grid item xs={10}>
											<Grid container direction="row">
												<Grid item>
													<RadioButtonUncheckedIcon
														style={{
															fontSize: 12,
															color: `${e.color}`,
															marginRight: 5,
														}}
													/>
												</Grid>
												<Grid item>
													<Typography className={classes.typosubchart}>
														{e.name}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Grid
												container
												justifyContent="flex-end"
												alignItems="flex-end"
											>
												<Grid item className={classes.detailTypoGrow}>
													{e.total}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
					{/*  */}
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4} className={classes.margin_Bottom_20}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									{" "}
									Préjudice emotionnel{" "}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BarCharts
									data={q13bb}
									// name="Etat civil de la victime"
									filledcolor={q13bb.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={mycolor[index % 20]} />
									))}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{q13bbb.map((e) => (
									<Grid container direction="row">
										<Grid item xs={10}>
											<Grid container direction="row">
												<Grid item>
													<RadioButtonUncheckedIcon
														style={{
															fontSize: 12,
															color: `${e.color}`,
															marginRight: 5,
														}}
													/>
												</Grid>
												<Grid item>
													<Typography className={classes.typosubchart}>
														{e.name}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Grid
												container
												justifyContent="flex-end"
												alignItems="flex-end"
											>
												<Grid item className={classes.detailTypoGrow}>
													{e.total}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
					{/*  */}
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4} className={classes.margin_Bottom_20}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									{" "}
									Préjudice économique{" "}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BarCharts
									data={q13c}
									// name="Etat civil de la victime"
									filledcolor={q13c.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={mycolor[index % 20]} />
									))}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{q13cc.map((e) => (
									<Grid container direction="row">
										<Grid item xs={10}>
											<Grid container direction="row">
												<Grid item>
													<RadioButtonUncheckedIcon
														style={{
															fontSize: 12,
															color: `${e.color}`,
															marginRight: 5,
														}}
													/>
												</Grid>
												<Grid item>
													<Typography className={classes.typosubchart}>
														{e.name}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Grid
												container
												justifyContent="flex-end"
												alignItems="flex-end"
											>
												<Grid item className={classes.detailTypoGrow}>
													{e.total}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
					{/*  */}

					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4} className={classes.margin_Bottom_20}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									{" "}
									Impact sur les relations{" "}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BarCharts
									data={q13bd}
									// name="Etat civil de la victime"
									filledcolor={q13bd.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={mycolor[index % 20]} />
									))}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{q13bdd.map((e) => (
									<Grid container direction="row">
										<Grid item xs={10}>
											<Grid container direction="row">
												<Grid item>
													<RadioButtonUncheckedIcon
														style={{
															fontSize: 12,
															color: `${e.color}`,
															marginRight: 5,
														}}
													/>
												</Grid>
												<Grid item>
													<Typography className={classes.typosubchart}>
														{e.name}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Grid
												container
												justifyContent="flex-end"
												alignItems="flex-end"
											>
												<Grid item className={classes.detailTypoGrow}>
													{e.total}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>

					{/*  */}
				</Grid>
			);
		} else if (value === 7) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							14. Quelle est la relation entre l'agresseur et la victime
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid
							container
							direction="row"
							spacing={4}
							className={classes.margin_Bottom_20}
						>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									a. Est-ce que l'auteur de l'acte de violence domestique est un
									membre de la famille de la victime ?
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<BarCharts
									data={q14a}
									// name="Etat civil de la victime"
									filledcolor={q14a.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={mycolor[index % 20]} />
									))}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								{q14aa.map((e) => (
									<Grid container direction="row">
										<Grid item xs={10}>
											<Grid container direction="row">
												<Grid item>
													<RadioButtonUncheckedIcon
														style={{
															fontSize: 12,
															color: `${e.color}`,
															marginRight: 5,
														}}
													/>
												</Grid>
												<Grid item>
													<Typography className={classes.typosubchart}>
														{e.name}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Grid
												container
												justifyContent="flex-end"
												alignItems="flex-end"
											>
												<Grid item className={classes.detailTypoGrow}>
													{e.total}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
					{/*  */}
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4} className={classes.margin_Bottom_20}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									{" "}
									Si oui{" "}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BarCharts
									data={q14aouip}
									// name="Etat civil de la victime"
									filledcolor={q14aouip.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={mycolor[index % 20]} />
									))}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{q14aoui.map((e) => (
									<Grid container direction="row">
										<Grid item xs={10}>
											<Grid container direction="row">
												<Grid item>
													<RadioButtonUncheckedIcon
														style={{
															fontSize: 12,
															color: `${e.color}`,
															marginRight: 5,
														}}
													/>
												</Grid>
												<Grid item>
													<Typography className={classes.typosubchart}>
														{e.name}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Grid
												container
												justifyContent="flex-end"
												alignItems="flex-end"
											>
												<Grid item className={classes.detailTypoGrow}>
													{e.total}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
					{/*  */}
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid
							container
							direction="row"
							spacing={4}
							className={classes.margin_Bottom_20}
						>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									{" "}
									b. Est-ce que l'auteur de l'acte de violence domestique est
									le/la conjoint(e) de la victime ou son/sa partenaire intime
									actuel ou ancien ?{" "}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<BarCharts
									data={q14b}
									// name="Etat civil de la victime"
									filledcolor={q14b.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={mycolor[index % 20]} />
									))}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								{q14bb.map((e) => (
									<Grid container direction="row">
										<Grid item xs={10}>
											<Grid container direction="row">
												<Grid item>
													<RadioButtonUncheckedIcon
														style={{
															fontSize: 12,
															color: `${e.color}`,
															marginRight: 5,
														}}
													/>
												</Grid>
												<Grid item>
													<Typography className={classes.typosubchart}>
														{e.name}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Grid
												container
												justifyContent="flex-end"
												alignItems="flex-end"
											>
												<Grid item className={classes.detailTypoGrow}>
													{e.total}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
					{/*  */}

					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4} className={classes.margin_Bottom_20}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									{" "}
									Si oui, l’auteur de la violence domestique est …{" "}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BarCharts
									data={q14bouip}
									// name="Etat civil de la victime"
									filledcolor={q14bouip.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={mycolor[index % 20]} />
									))}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{q14boui.map((e) => (
									<Grid container direction="row">
										<Grid item xs={10}>
											<Grid container direction="row">
												<Grid item>
													<RadioButtonUncheckedIcon
														style={{
															fontSize: 12,
															color: `${e.color}`,
															marginRight: 5,
														}}
													/>
												</Grid>
												<Grid item>
													<Typography className={classes.typosubchart}>
														{e.name}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Grid
												container
												justifyContent="flex-end"
												alignItems="flex-end"
											>
												<Grid item className={classes.detailTypoGrow}>
													{e.total}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>

					{/*  */}
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4} className={classes.margin_Bottom_20}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									{" "}
									c. Si l'auteur de l'acte de violence domestique est ni membre
									de famille ni conjoint ou partenaire intime actuel ou ancien
									de la victime, quelle autre relation existe entre victime et
									auteur ? L'auteur est …{" "}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BarCharts
									data={q14c}
									// name="Etat civil de la victime"
									filledcolor={q14c.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={mycolor[index % 20]} />
									))}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{q14cc.map((e) => (
									<Grid container direction="row">
										<Grid item xs={10}>
											<Grid container direction="row">
												<Grid item>
													<RadioButtonUncheckedIcon
														style={{
															fontSize: 12,
															color: `${e.color}`,
															marginRight: 5,
														}}
													/>
												</Grid>
												<Grid item>
													<Typography className={classes.typosubchart}>
														{e.name}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Grid
												container
												justifyContent="flex-end"
												alignItems="flex-end"
											>
												<Grid item className={classes.detailTypoGrow}>
													{e.total}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 8) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							15. Est-ce que l'agresseur et la victime habitent ensemble ?
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4} className={classes.margin_Bottom_20}>
							{/* <Grid item xs={12} sm={12} md ={12} lg={12} xl={12} style={{backgroundColor:'#E0F3F5'}}> <Typography className={classes.answer_view}> a. S'agit-il d'une situation de violence continue?</Typography></Grid> */}
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container direction="row" spacing={4}>
									<Grid item xs={12} sm={6}>
										<BarCharts
											data={q15}
											// name="Etat civil de la victime"
											filledcolor={q15.map((entry, index) => (
												<Cell
													key={`cell-${index}`}
													fill={mycolor[index % 20]}
												/>
											))}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										{q15p.map((e) => (
											<Grid container direction="row">
												<Grid item xs={10}>
													<Grid container direction="row">
														<Grid item>
															<RadioButtonUncheckedIcon
																style={{
																	fontSize: 12,
																	color: `${e.color}`,
																	marginRight: 5,
																}}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{e.name}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={2}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{e.total}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										))}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									{" "}
									Si oui,
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container direction="row" spacing={4}>
									<Grid item xs={12} sm={6}>
										<BarCharts
											data={q15ouip}
											// name="Etat civil de la victime"
											filledcolor={q15ouip.map((entry, index) => (
												<Cell
													key={`cell-${index}`}
													fill={mycolor[index % 20]}
												/>
											))}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										{q15oui.map((e) => (
											<Grid container direction="row">
												<Grid item xs={10}>
													<Grid container direction="row">
														<Grid item>
															<RadioButtonUncheckedIcon
																style={{
																	fontSize: 12,
																	color: `${e.color}`,
																	marginRight: 5,
																}}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{e.name}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={2}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{e.total}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										))}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 9) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							16. Est-ce que la victime a dénoncé son cas de violence domestique
							auprès d'une autorité judiciaire ?
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4} className={classes.margin_Bottom_20}>
							{/* <Grid item xs={12} sm={12} md ={12} lg={12} xl={12} style={{backgroundColor:'#E0F3F5'}}> <Typography className={classes.answer_view}> a. S'agit-il d'une situation de violence continue?</Typography></Grid> */}
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container direction="row" spacing={4}>
									<Grid item xs={12} sm={6}>
										<BarCharts
											data={q16}
											// name="Etat civil de la victime"
											filledcolor={q16.map((entry, index) => (
												<Cell
													key={`cell-${index}`}
													fill={mycolor[index % 20]}
												/>
											))}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										{q16p.map((e) => (
											<Grid container direction="row">
												<Grid item xs={10}>
													<Grid container direction="row">
														<Grid item>
															<RadioButtonUncheckedIcon
																style={{
																	fontSize: 12,
																	color: `${e.color}`,
																	marginRight: 5,
																}}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{e.name}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={2}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{e.total}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										))}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									{" "}
									Si oui, les autorités ont-elles donné suite à la plainte :
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container direction="row" spacing={4}>
									<Grid item xs={12} sm={6}>
										<BarCharts
											data={q16oui}
											// name="Etat civil de la victime"
											filledcolor={q16oui.map((entry, index) => (
												<Cell
													key={`cell-${index}`}
													fill={mycolor[index % 20]}
												/>
											))}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										{q16ouip.map((e) => (
											<Grid container direction="row">
												<Grid item xs={10}>
													<Grid container direction="row">
														<Grid item>
															<RadioButtonUncheckedIcon
																style={{
																	fontSize: 12,
																	color: `${e.color}`,
																	marginRight: 5,
																}}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{e.name}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={2}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{e.total}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										))}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 10) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							17. Est-ce que la victime bénéficie d'un soutien amical, familial,
							ou d'une autre personne de son environnement social proche ?
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4}>
							{/* <Grid item xs={12} sm={12} md ={12} lg={12} xl={12} style={{backgroundColor:'#E0F3F5'}}> <Typography className={classes.answer_view}> a. S'agit-il d'une situation de violence continue?</Typography></Grid> */}
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container direction="row" spacing={4}>
									<Grid item xs={12} sm={6}>
										<BarCharts
											data={q17}
											// name="Etat civil de la victime"
											filledcolor={q17.map((entry, index) => (
												<Cell
													key={`cell-${index}`}
													fill={mycolor[index % 20]}
												/>
											))}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										{q17p.map((e) => (
											<Grid container direction="row">
												<Grid item xs={10}>
													<Grid container direction="row">
														<Grid item>
															<RadioButtonUncheckedIcon
																style={{
																	fontSize: 12,
																	color: `${e.color}`,
																	marginRight: 5,
																}}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{e.name}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={2}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{e.total}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										))}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 11) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							{" "}
							18. Est-ce que la victime a déjà bénéficié de services de prise en
							charge comme réponse à l'acte de violence domestique documenté ici
							?
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4} className={classes.margin_Bottom_20}>
							{/* <Grid item xs={12} sm={12} md ={12} lg={12} xl={12} style={{backgroundColor:'#E0F3F5'}}> <Typography className={classes.answer_view}> a. S'agit-il d'une situation de violence continue?</Typography></Grid> */}
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container direction="row" spacing={4}>
									<Grid item xs={12} sm={6}>
										<BarCharts
											data={q18}
											// name="Etat civil de la victime"
											filledcolor={q18.map((entry, index) => (
												<Cell
													key={`cell-${index}`}
													fill={mycolor[index % 20]}
												/>
											))}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										{q18p.map((e) => (
											<Grid container direction="row">
												<Grid item xs={10}>
													<Grid container direction="row">
														<Grid item>
															<RadioButtonUncheckedIcon
																style={{
																	fontSize: 12,
																	color: `${e.color}`,
																	marginRight: 5,
																}}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{e.name}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={2}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{e.total}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										))}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									{" "}
									a. De quel(s) volet(s) de prise en charge la victime a-t-elle
									bénéficié ?{" "}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BarCharts
									data={q18ap}
									// name="Etat civil de la victime"
									filledcolor={q18ap.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={mycolor[index % 20]} />
									))}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{q18a.map((e) => (
									<Grid container direction="row">
										<Grid item xs={10}>
											<Grid container direction="row">
												<Grid item>
													<RadioButtonUncheckedIcon
														style={{
															fontSize: 12,
															color: `${e.color}`,
															marginRight: 5,
														}}
													/>
												</Grid>
												<Grid item>
													<Typography className={classes.typosubchart}>
														{e.name}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Grid
												container
												justifyContent="flex-end"
												alignItems="flex-end"
											>
												<Grid item className={classes.detailTypoGrow}>
													{e.total}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 12) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							19. Est-ce que la victime pense avoir encore besoin de certains
							volets de prise en charge pour répondre à l'acte de violence
							domestique documenté ici ?
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4}>
							{/* <Grid item xs={12} sm={12} md ={12} lg={12} xl={12} style={{backgroundColor:'#E0F3F5'}}> <Typography className={classes.answer_view}> a. S'agit-il d'une situation de violence continue?</Typography></Grid> */}
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid
									container
									direction="row"
									spacing={4}
									className={classes.margin_Bottom_20}
								>
									<Grid item xs={12} sm={6}>
										<BarCharts
											data={q19}
											// name="Etat civil de la victime"
											filledcolor={q19.map((entry, index) => (
												<Cell
													key={`cell-${index}`}
													fill={mycolor[index % 20]}
												/>
											))}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										{q19p.map((e) => (
											<Grid container direction="row">
												<Grid item xs={10}>
													<Grid container direction="row">
														<Grid item>
															<RadioButtonUncheckedIcon
																style={{
																	fontSize: 12,
																	color: `${e.color}`,
																	marginRight: 5,
																}}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{e.name}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={2}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{e.total}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										))}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									{" "}
									Si oui :{" "}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BarCharts
									data={q19ouip}
									// name="Etat civil de la victime"
									filledcolor={q19ouip.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={mycolor[index % 20]} />
									))}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{q19oui.map((e) => (
									<Grid container direction="row">
										<Grid item xs={10}>
											<Grid container direction="row">
												<Grid item>
													<RadioButtonUncheckedIcon
														style={{
															fontSize: 12,
															color: `${e.color}`,
															marginRight: 5,
														}}
													/>
												</Grid>
												<Grid item>
													<Typography className={classes.typosubchart}>
														{e.name}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Grid
												container
												justifyContent="flex-end"
												alignItems="flex-end"
											>
												<Grid item className={classes.detailTypoGrow}>
													{e.total}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 13) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							20. En vue des services de prise en charge dont la victime a
							encore besoin (cf. question 19), pour quel(s) volet(s)
							l'organisation qui documente ce cas est-elle capable de fournir
							ces services elle-même ?
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4}>
							{/* <Grid item xs={12} sm={12} md ={12} lg={12} xl={12} style={{backgroundColor:'#E0F3F5'}}> <Typography className={classes.answer_view}> Incindence violence psychologique </Typography></Grid> */}
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BarCharts
									data={q20p}
									// name="Etat civil de la victime"
									filledcolor={q20p.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={mycolor[index % 20]} />
									))}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{q20.map((e) => (
									<Grid container direction="row">
										<Grid item xs={10}>
											<Grid container direction="row">
												<Grid item>
													<RadioButtonUncheckedIcon
														style={{
															fontSize: 12,
															color: `${e.color}`,
															marginRight: 5,
														}}
													/>
												</Grid>
												<Grid item>
													<Typography className={classes.typosubchart}>
														{e.name}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Grid
												container
												justifyContent="flex-end"
												alignItems="flex-end"
											>
												<Grid item className={classes.detailTypoGrow}>
													{e.total}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 14) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							{" "}
							21. En vue des services de prise en charge dont la victime a
							encore besoin (cf. question 19), pour quel(s) volet(s)
							l’organisation qui documente ce cas est-elle capable de référer la
							victime ?
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4}>
							{/* <Grid item xs={12} sm={12} md ={12} lg={12} xl={12} style={{backgroundColor:'#E0F3F5'}}> <Typography className={classes.answer_view}> Incindence violence psychologique </Typography></Grid> */}
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BarCharts
									data={q21p}
									// name="Etat civil de la victime"
									filledcolor={q21p.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={mycolor[index % 20]} />
									))}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{q21.map((e) => (
									<Grid container direction="row">
										<Grid item xs={10}>
											<Grid container direction="row">
												<Grid item>
													<RadioButtonUncheckedIcon
														style={{
															fontSize: 12,
															color: `${e.color}`,
															marginRight: 5,
														}}
													/>
												</Grid>
												<Grid item>
													<Typography className={classes.typosubchart}>
														{e.name}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Grid
												container
												justifyContent="flex-end"
												alignItems="flex-end"
											>
												<Grid item className={classes.detailTypoGrow}>
													{e.total}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 15) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							{" "}
							22. En vue des services de prise en charge dont la victime a
							encore besoin (cf. question 19) pour quels volets est-il
							impossible de trouver une prise en charge dans l'immédiat ?
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4}>
							{/* <Grid item xs={12} sm={12} md ={12} lg={12} xl={12} style={{backgroundColor:'#E0F3F5'}}> <Typography className={classes.answer_view}> Incindence violence psychologique </Typography></Grid> */}
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BarCharts
									data={q22p}
									// name="Etat civil de la victime"
									filledcolor={q22p.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={mycolor[index % 20]} />
									))}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{q22.map((e) => (
									<Grid container direction="row">
										<Grid item xs={10}>
											<Grid container direction="row">
												<Grid item>
													<RadioButtonUncheckedIcon
														style={{
															fontSize: 12,
															color: `${e.color}`,
															marginRight: 5,
														}}
													/>
												</Grid>
												<Grid item>
													<Typography className={classes.typosubchart}>
														{e.name}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Grid
												container
												justifyContent="flex-end"
												alignItems="flex-end"
											>
												<Grid item className={classes.detailTypoGrow}>
													{e.total}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 16) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							{" "}
							23. La victime se sent-elle suffisamment en sécurité pour rentrer
							chez elle ?
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4}>
							{/* <Grid item xs={12} sm={12} md ={12} lg={12} xl={12} style={{backgroundColor:'#E0F3F5'}}> <Typography className={classes.answer_view}> a. S'agit-il d'une situation de violence continue?</Typography></Grid> */}
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container direction="row" spacing={4}>
									<Grid item xs={12} sm={6}>
										<BarCharts
											data={q23}
											// name="Etat civil de la victime"
											filledcolor={q23.map((entry, index) => (
												<Cell
													key={`cell-${index}`}
													fill={mycolor[index % 20]}
												/>
											))}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										{q23p.map((e) => (
											<Grid container direction="row">
												<Grid item xs={10}>
													<Grid container direction="row">
														<Grid item>
															<RadioButtonUncheckedIcon
																style={{
																	fontSize: 12,
																	color: `${e.color}`,
																	marginRight: 5,
																}}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{e.name}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={2}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{e.total}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										))}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 17) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							{" "}
							24. La victime cherche-elle à quitter son foyer de façon
							temporaire ou de façon permanente à cause des violences
							domestiques, afin d'établir une distance physique avec l'agresseur
							?
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4}>
							{/* <Grid item xs={12} sm={12} md ={12} lg={12} xl={12} style={{backgroundColor:'#E0F3F5'}}> <Typography className={classes.answer_view}> a. S'agit-il d'une situation de violence continue?</Typography></Grid> */}
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={4}>
									<Grid item xs={12} sm={12}>
										<BarCharts
											data={q24}
											// name="Etat civil de la victime"
											filledcolor={q24.map((entry, index) => (
												<Cell
													key={`cell-${index}`}
													fill={mycolor[index % 20]}
												/>
											))}
										/>
									</Grid>
									<Grid item xs={12} sm={12}>
										{q24p.map((e) => (
											<Grid container direction="row">
												<Grid item xs={10}>
													<Grid container direction="row">
														<Grid item>
															<RadioButtonUncheckedIcon
																style={{
																	fontSize: 12,
																	color: `${e.color}`,
																	marginRight: 5,
																}}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{e.name}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={2}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{e.total}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										))}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								style={{ backgroundColor: "#E0F3F5" }}
							>
								{" "}
								<Typography className={classes.answer_view}>
									{" "}
									Si oui,
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container direction="row" spacing={4}>
									<Grid item xs={12} sm={6}>
										<BarCharts
											data={q24a}
											// name="Etat civil de la victime"
											filledcolor={q24a.map((entry, index) => (
												<Cell
													key={`cell-${index}`}
													fill={mycolor[index % 20]}
												/>
											))}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										{q24ap.map((e) => (
											<Grid container direction="row">
												<Grid item xs={10}>
													<Grid container direction="row">
														<Grid item>
															<RadioButtonUncheckedIcon
																style={{
																	fontSize: 12,
																	color: `${e.color}`,
																	marginRight: 5,
																}}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{e.name}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={2}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{e.total}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										))}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 18) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							{" "}
							25. Est-ce que la victime souhaite de partager un message direct
							avec le public au travers le site web de l'Observatoire ?
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4}>
							{/* <Grid item xs={12} sm={12} md ={12} lg={12} xl={12} style={{backgroundColor:'#E0F3F5'}}> <Typography className={classes.answer_view}> a. S'agit-il d'une situation de violence continue?</Typography></Grid> */}
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container direction="row" spacing={4}>
									<Grid item xs={12} sm={6}>
										<BarCharts
											data={q25}
											// name="Etat civil de la victime"
											filledcolor={q25.map((entry, index) => (
												<Cell
													key={`cell-${index}`}
													fill={mycolor[index % 20]}
												/>
											))}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										{q25p.map((e) => (
											<Grid container direction="row">
												<Grid item xs={10}>
													<Grid container direction="row">
														<Grid item>
															<RadioButtonUncheckedIcon
																style={{
																	fontSize: 12,
																	color: `${e.color}`,
																	marginRight: 5,
																}}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{e.name}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={2}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{e.total}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										))}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (value === 19) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={classes.detailTypoGrow}>
							{" "}
							27. Est-ce que la victime souhaite de partager son message direct
							avec sa localisation et son âge ou rester complètement anonyme ?
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={4}>
							{/* <Grid item xs={12} sm={12} md ={12} lg={12} xl={12} style={{backgroundColor:'#E0F3F5'}}> <Typography className={classes.answer_view}> a. S'agit-il d'une situation de violence continue?</Typography></Grid> */}
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container direction="row" spacing={4}>
									<Grid item xs={12} sm={6}>
										<BarCharts
											data={q27}
											// name="Etat civil de la victime"
											filledcolor={q27.map((entry, index) => (
												<Cell
													key={`cell-${index}`}
													fill={mycolor[index % 20]}
												/>
											))}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										{q27p.map((e) => (
											<Grid container direction="row">
												<Grid item xs={10}>
													<Grid container direction="row">
														<Grid item>
															<RadioButtonUncheckedIcon
																style={{
																	fontSize: 12,
																	color: `${e.color}`,
																	marginRight: 5,
																}}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{e.name}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={2}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item className={classes.detailTypoGrow}>
															{e.total}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										))}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		}
	};

	const displayData = () => {
		if (statecode === false) {
			if (spinner === true) {
				return (
					<Grid container spacing={4}>
						<Grid item xs={12} sm={6}>
							<Skeleton variant="rect" height={40} />
						</Grid>
						<Grid item xs={12} sm={12}>
							<Skeleton variant="text" />
						</Grid>
						<Grid item xs={12} sm={12}>
							<Grid container direction="row" spacing={4}>
								<Grid item xs={12} sm={4}>
									{" "}
									<Skeleton variant="circle" width={250} height={250} />
								</Grid>
								<Grid item xs={12} sm={8}>
									{" "}
									<Skeleton variant="rect" height={250} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				);
			} else {
				return (
					<Grid container direction="row" justifyContent="center">
						<Grid item xs={12} sm={4}></Grid>
						<Grid item xs={12} sm={4}>
							<Grid container justifyContent="center">
								<Grid item>
									<CloudOffTwoToneIcon size={40} />
								</Grid>
								<Grid item>
									<Typography className={classes.detailTypoGrow}>
										{errmsg}
									</Typography>
								</Grid>
							</Grid>
							<Grid container justifyContent="center">
								<Grid item>
									<IconButton
										color="primary"
										aria-label="Restart"
										component="span"
										onClick={() => getApprove()}
									>
										<RestoreIcon />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={4}></Grid>
					</Grid>
				);
			}
		} else if (statecode === true && data === "") {
			return (
				<Grid container direction="row" justifyContent="center">
					<Grid xs={12} sm={4}></Grid>
					<Grid xs={12} sm={4}>
						<Grid container direction="row">
							<Grid item xs={12} sm={4}>
								<StorageIcon size={60} />
							</Grid>
							<Grid item xs={12} sm={8}>
								<Typography className={classes.detailTypoGrow}>
									No data found
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid xs={12} sm={4}></Grid>
				</Grid>
			);
		} else if (statecode === true && data !== "") {
			return (
				<div>
					<Grid container>
						<Grid item xs={12} sm={12}>
							{/* selector for question */}
							<Grid container className={classes.margin_Bottom_30}>
								<Grid item xs={12} sm={6}>
									<Select
										className={classes.inputSelector}
										value={value}
										// TODO: Fix onChange={handleChange} 
										variant="outlined"
										style={{ height: 40 }}
										onChange={handleCase}
										fullWidth
										autoWidth
									>
										<MenuItem className={classes.detailTypoGrow} value={0}>
											6. Groupe d’âge de la / du survivant(e)
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={1}>
											7. Genre de la / du survivant(e)
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={2}>
											8. Profil de vulnérabilité de la / du survivant(e)
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={3}>
											10. Types de violence domestique
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={4}>
											11. S'agit-il d'une situation de violence continue ou d'un
											incident ponctuel ?
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={5}>
											12. Où est-ce que cet acte de violence domestique a eu
											lieu ?
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={6}>
											13. Catégories de préjudice causé
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={7}>
											14. Quelle est la relation entre l'agresseur et la victime
											?
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={8}>
											15. Est-ce que l'agresseur et la victime habitent ensemble
											?
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={9}>
											16. Est-ce que la victime a dénoncé son cas de VD auprès
											d'une autorité judiciaire?
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={10}>
											17. Est-ce que la victime bénéficie d'un soutien?
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={11}>
											18. Est-ce que la victime a déjà bénéficié de services de
											prise en charge comme réponse à l'acte de VD documenté ici
											?
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={12}>
											19. Est-ce que la victime pense avoir encore besoin de
											certains volets <br /> de prise en charge pour répondre à
											l'acte de VD documenté ici ?
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={13}>
											20. En vue des services de prise en charge dont la victime
											a encore besoin <br /> (cf. question 19), pour quel(s)
											volet(s) l'organisation qui documente ce cas est-elle
											capable de fournir ces services elle-même ?{" "}
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={14}>
											21. En vue des services de prise en charge dont la victime
											a encore besoin <br />
											(cf. question 19), pour quel(s) volet(s) l’organisation
											qui documente ce cas est-elle capable de référer la
											victime ?
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={15}>
											22. En vue des services de prise en charge dont la victime
											a encore besoin <br />
											(cf. question 19) pour quels volets est-il impossible de
											trouver une prise en charge dans l'immédiat ?
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={16}>
											23. La victime se sent-elle suffisamment en sécurité pour
											rentrer chez elle ?
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={17}>
											24. La victime cherche-elle à quitter son foyer de façon
											temporaire ou de façon <br /> permanente à cause des
											violences domestiques, afin d'établir une distance
											physique avec l'agresseur ?
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={18}>
											25. Est-ce que la victime souhaite de partager un message
											direct avec <br /> le public au travers le site web de
											l'Observatoire ?
										</MenuItem>
										<MenuItem className={classes.detailTypoGrow} value={19}>
											27. Est-ce que la victime souhaite de partager son message
											direct avec <br /> sa localisation et son âge ou rester
											complètement anonyme ?
										</MenuItem>
									</Select>
								</Grid>
							</Grid>
							{/* <Grid container className={classes.margin_Top_30}>
                               <Grid item xs={12} sm={12} style={{backgroundColor:'#E0F3F5'}}  >
                                    <Typography className={classes.answer_view}> a. Demandez la victime ce qui a changé dans sa vie à cause des violences domestiques subies, donc les conséquences causées par l’acte de violence dans sa vie et ses relation</Typography>
                               </Grid>
                           </Grid>
    
                           <Grid container className={classes.margin_Bottom_30}>
                               <Grid item xs={12} sm={12}>
                                   <Typography className={classes.answer}>Answers</Typography>
                               </Grid>
                           </Grid> */}

							<Grid container direction="row" spacing={12}>
								{value !== null ? displayChoosenQuestion(value) : null}
							</Grid>
						</Grid>
					</Grid>
				</div>
			);
		}
	};

	return <div>{displayData()}</div>;
};

export default Quantitative;

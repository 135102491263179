import React from 'react'
import {Switch,Route} from'react-router-dom'
import Users from '../../component/dashboard/users/users'
import Posts from '../../component/dashboard/campain_observatory/posts'
import Cases from '../../component/dashboard/case/cases'
import Analysis from '../../component/dashboard/analysis/analysis'
import Maps from '../../component/dashboard/case_bank/case_map'
import Main from './main'
import Statistic from '../../component/dashboard/case_statistic/case_statistic'
import AddUser from '../../component/dashboard/users/adduser'
import Detail from '../../component/dashboard/users/detail'
// import Changepassword from  '../../component/dashboard/users/changepassword'
import Edit from '../../component/dashboard/users/edit'
import CaseDetail from '../../component/dashboard/case/case_detail'
import Editcase from '../../component/dashboard/case/edit_case'
import Createpost from '../../component/dashboard/campain_observatory/post_create'
//import axios from 'axios'
import Detail_post from '../../component/dashboard/campain_observatory/pages/sub/post_detail'
import Editpost from '../../component/dashboard/campain_observatory/post_edit'
// import ScrollToTop from './scrollTop'



const MainRoutes = () => {
  
    return (
        <div>
            {/* <ScrollToTop> */}
                <Main>
                    <Switch>
                    <Route exact path = {'/'+process.env.REACT_APP_DASHBOAD_LINK+'/dashboard/case/statistic'} component={Statistic} />
                    <Route exact path={'/'+process.env.REACT_APP_DASHBOAD_LINK+'/dashboard'} component={Cases}/>
                    <Route exact path={'/'+process.env.REACT_APP_DASHBOAD_LINK+'/dashboard/posts'} component={Posts}/>
                    <Route exact path={'/'+process.env.REACT_APP_DASHBOAD_LINK+'/dashboard/analysis'} component={Analysis}/>
                    <Route exact path={'/'+process.env.REACT_APP_DASHBOAD_LINK+'/dashboard/maps'} component={Maps}/>
                    <Route exact path={'/'+process.env.REACT_APP_DASHBOAD_LINK+'/dashboard/users'} component={Users}/>
                    <Route exact path={'/'+process.env.REACT_APP_DASHBOAD_LINK+'/dashboard/user/add'} component={AddUser} />
                    <Route exact path={'/'+process.env.REACT_APP_DASHBOAD_LINK+'/dashboard/user/detail/:id'} component={Detail}/>
                    <Route exact path = {'/'+process.env.REACT_APP_DASHBOAD_LINK+'/dashboard/user/edit/:id'} component={Edit}/>
                    <Route exact path = {'/'+process.env.REACT_APP_DASHBOAD_LINK+'/dashboard/case/detail/:id'} component={CaseDetail} />
                    <Route exact path = {'/'+process.env.REACT_APP_DASHBOAD_LINK+'/dashboard/case/edit/:id'} component={Editcase} />
                    <Route exact path = {'/'+process.env.REACT_APP_DASHBOAD_LINK+'/dashboard/post/create'} component={Createpost} />
                    <Route exact path = {'/'+process.env.REACT_APP_DASHBOAD_LINK+'/dashboard/post/detail'} component={Detail_post} />
                    <Route exact path = {'/'+process.env.REACT_APP_DASHBOAD_LINK+'/dashboard/post/edit'} component={Editpost} />
                    </Switch>
            </Main>
            {/* </ScrollToTop> */}
       
        </div>
    )
}

export default MainRoutes;

import React from "react";
import { Grid, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import useStyles from "../index/style";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const Footer = () => {
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	return (
		<div>
			<Grid container direction="row" className={classes.margin_section}>
				<Grid item xs={12} sm={12} lg={6} md={8}>
					<Grid container alignItems="flex-start" spacing={3}>
						<Grid item xs={12} sm={12} md={12}>
							<Typography className={classes.white_color_footer_partner}>
								{t("footer")}
							</Typography>
							<Typography className={classes.white_color_footer_subtitle}>
								{t("country")}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Grid container spacing={1}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={classes.white_color_footer_partner}>
										{t("footer-partners")}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={classes.white_color}>
										{t("footer-partners-text")}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<NavLink
								className={classes.Link_stl2}
								to={"/" + i18n.language + t("link-partners")}
							>
								<Typography>
									<br />
									{t("footer-partners-link")}
								</Typography>
							</NavLink>
							<br />
							<hr className={classes.foot_link_hr} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={12} md={4} lg={6}>
					<Grid item>
						<Grid
							container
							direction="column"
							justifyContent="center"
							className={classes.right_align}
						>
							<Typography className={classes.white_color}>
								<List disablePadding>
									<Grid className={classes.marginBottom_2}>
										<Typography className={classes.white_color_footer_partner}>
											{t("product")}
										</Typography>
									</Grid>
									<Grid className={classes.marginBottom_1}>
										<NavLink className={classes.Link_stl} to="/">
											{t("home")}
										</NavLink>
									</Grid>
									<Grid className={classes.marginBottom_1}>
										<NavLink
											className={classes.Link_stl}
											to={"/" + i18n.language + t("link-about")}
										>
											{t("about")}
										</NavLink>
									</Grid>
									<Grid className={classes.marginBottom_1}>
										<NavLink
											className={classes.Link_stl}
											to={"/" + i18n.language + t("link-overview")}
										>
											{t("footer-overview")}
										</NavLink>
									</Grid>
									<Grid className={classes.marginBottom_1}>
										<NavLink
											className={classes.Link_stl}
											to={
												"/" +
												i18n.language +
												t("link-What-is-domestic-violence")
											}
										>
											{t("sectionLeft")}
										</NavLink>
									</Grid>
									<Grid className={classes.marginBottom_1}>
										<NavLink
											className={classes.Link_stl}
											to={"/" + i18n.language + t("link-Where-to-find-help")}
										>
											{t("serctioncenter")}
										</NavLink>
									</Grid>
									<Grid className={classes.marginBottom_1}>
										<NavLink
											className={classes.Link_stl}
											to={
												"/" +
												i18n.language +
												t("link-Access-to-justice-and-reparations")
											}
										>
											{t("sectionrigth")}
										</NavLink>
									</Grid>
									<Grid className={classes.marginBottom_1}>
										<NavLink
											className={classes.Link_stl}
											to={"/" + i18n.language + t("link-campain")}
										>
											{t("campain")}
										</NavLink>
									</Grid>
									<Grid className={classes.marginBottom_1}>
										<NavLink
											className={classes.Link_stl}
											to={"/" + i18n.language + t("link-news-and-report")}
										>
											{t("campaign-title")}
										</NavLink>
									</Grid>

									<Grid className={classes.marginBottom_1}>
										<NavLink
											className={classes.Link_stl}
											to={"/" + i18n.language + t("link-case-statistic")}
										>
											{t("case-statistic")}
										</NavLink>
									</Grid>
									<Grid className={classes.marginBottom_1}>
										<NavLink
											className={classes.Link_stl}
											to={"/" + i18n.language + t("link-case-database")}
										>
											{t("case-databank")}
										</NavLink>
									</Grid>

									<Grid className={classes.marginBottom_1}>
										<NavLink
											className={classes.Link_stl}
											to={"/" + i18n.language + t("link-faq")}
										>
											{t("faq")}
										</NavLink>
									</Grid>

									<Grid className={classes.marginBottom_1}>
										<NavLink
											className={classes.Link_stl}
											to={"/" + i18n.language + t("link-contact-us")}
										>
											{t("contact")}
										</NavLink>
									</Grid>
									<Grid className={classes.marginBottom_1}>
										<NavLink
											className={classes.Link_stl}
											to={"/" + i18n.language + t("link-Sitemap")}
										>
											{t("sitemap")}
										</NavLink>
									</Grid>
								</List>
								<hr
									style={{
										color: "#FFFF",
										backgroundColor: "#FFFF",
										height: 0.5,
										borderColor: "#FFF",
									}}
								/>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{/* </Grid> */}
		</div>
	);
};

export default Footer;

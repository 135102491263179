import React, { useState,useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Button, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withRouter, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@material-ui/icons/Language";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';




const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBarTransparent: {
    backgroundColor: 'transparent'
},
 appBarColors: {
    backgroundColor: '#1D384C'

},
  menuButton: {
    marginRight: theme.spacing(2),
  },

  headerOptions: {
    display: "flex",
    flex: 1,
    justifyContent: "space-evenly",
  },
  language: {
    fontWeight:300,
    color: "white",
    [theme.breakpoints.down("xs")]: {
      //  flexGrow: 2,
      //  marginLeft:60,
      fontSize: 10,
    },
    [theme.breakpoints.up("sm")]: {},
  },

  paragraphe: {
    "&:hover": {
      color: "#00A1AF",
      fontWeight:700,
    },
    fontWeight:300,
    [theme.breakpoints.down("xs")]: {
      //  flexGrow: 2,
      //  marginLeft:60,
      fontSize: 10,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 14,
    },
   
  },
  mobileWeight:{
    fontWeight:300,
  
  },
  icon:{
    [theme.breakpoints.down("sm")]: {
    
      fontSize: 15,
    },
  }
}));

const ApptopBar = (props) => {
  const classes = useStyles();
  const { history } = props;
  const url = props.location.pathname

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorlan, setAnchorlan] = React.useState(null);
  const { t, i18n } = useTranslation();
  
  //console.log(i18n);
  const open = Boolean(anchorEl);
  const [lan, setlan] = useState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [navBackground, setNavBackground] = useState('appBarTransparent')
    const navRef = React.useRef()
    navRef.current = navBackground

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClick = (pageURL) => {
    history.push(pageURL);
    setAnchorEl(null);
  };

  const handlelanguage = (event) => {
    setAnchorlan(event.currentTarget);
    
  };
  const handleCloseLan = () => {
    setAnchorlan(null);
  };
 // console.log(url);
  const changeLanguage = (e) => {
    const value = e;
    //console.log(value);
    if (value === "en") {
      setlan("En");
      setAnchorlan(null);
    } else if (value === "fr") {
      setlan("Fr");
      setAnchorlan(null);
    }
    
    if(url===`/${i18n.language}/À_propos_de_l'Observatoire`|| url===`/${i18n.language}/About_the_Observatory`){
     
      i18n.changeLanguage(value,()=>{
        history.push(`/${value}${t('link-about')}`)
      });
    }
    else if(url===`/${i18n.language}/Campaigne`|| url ===`/${i18n.language}/Campaign` ){
      i18n.changeLanguage(value,()=>{
        history.push(`/${value}${t('link-campain')}`)
      });
    }
    else if(url===`/${i18n.language}/Rapports_and_Articles`|| url ===`/${i18n.language}/Reports_and_articles` ){
      i18n.changeLanguage(value,()=>{
        history.push(`/${value}${t('link-news-and-report')}`)
      });
    }
    else if(url===`/${i18n.language}/Statistiques_des_cas`|| url ===`/${i18n.language}/Case_Statistics` ){
      i18n.changeLanguage(value,()=>{
        history.push(`/${value}${t('link-case-statistic')}`)
      });
    }
    else if(url===`/${i18n.language}/Banque_de_données_des_cas`|| url ===`/${i18n.language}/Case_Database` ){
        i18n.changeLanguage(value,()=>{
        history.push(`/${value}${t('link-case-database')}`)
      });
    }
    else if(url===`/${i18n.language}/Foire_aux_questions`|| url ===`/${i18n.language}/${i18n.language}equently_Asked_Questions` ){
        i18n.changeLanguage(value,()=>{
        history.push(`/${value}${t('link-faq')}`)
      });
    }
    else if(url===`/${i18n.language}/Contactez-nous`|| url ===`/${i18n.language}/Contact_Us` ){
        i18n.changeLanguage(value,()=>{
        history.push(`/${value}${t('link-contact-us')}`)
      });
    }
    else if(url===`/${i18n.language}/Article`|| url ===`/${i18n.language}/Article` ){
        i18n.changeLanguage(value,()=>{
        history.push(`/${value}${t('link-post-detail')}`)
      });
    }
    else if(url===`/${i18n.language}/Aperçu_des_violences_domestiques`|| url ===`/${i18n.language}/Overview_of_domestic_violence` ){
        i18n.changeLanguage(value,()=>{
        history.push(`/${value}${t('link-overview')}`)
      });
    }
    else if(url===`/${i18n.language}/Les_organisations_partenaires`|| url ===`/${i18n.language}/Partner_organizations` ){
        i18n.changeLanguage(value,()=>{
        history.push(`/${value}${t('link-partners')}`)
      });
    }
    else if(url===`/${i18n.language}/Qu_est_ce_que_la_violence_domestique`|| url ===`/${i18n.language}/What_is_domestic_violence` ){
        i18n.changeLanguage(value,()=>{
        history.push(`/${value}${t('link-What-is-domestic-violence')}`)
      });
    }
    else if(url===`/${i18n.language}/Où_trouver_de_l'_aide`|| url ===`/${i18n.language}/Where_to_find_help` ){
        i18n.changeLanguage(value,()=>{
        history.push(`/${value}${t('link-Where-to-find-help')}`)
      });
    }
    else if(url===`/${i18n.language}/Accès_à_la_justice_et_aux_réparations`|| url ===`/${i18n.language}/Access_to_justice_and_reparations` ){
        i18n.changeLanguage(value,()=>{
        history.push(`/${value}${t('link-Access-to-justice-and-reparations')}`)
      });
    }
    else if(url===`/${i18n.language}/Site_map`|| url ===`/${i18n.language}/Site_map` ){
        i18n.changeLanguage(value,()=>{
        history.push(`/${value}${t('link-Sitemap')}`)
      });
    }else{
      i18n.changeLanguage(value)
    }
   
    
    // i18n.changeLanguage(value,()=>{
    //   history.push()
    // });
    
   // window.location.reload()


   // window.location.reload();
   

  };

    //console.log(i18n);

  const menuItems = [
    {
      menuTitle: `${t("home")}`,
      pageURL: "/",
    },
    {
      menuTitle: `${t("about")}`,
      pageURL: `/${i18n.language}${t('link-about')}`,
    },
    {
      menuTitle: `${t("campain")}`,
      pageURL: `/${i18n.language}${t('link-campain')}`,
    },
    // {
    //   menuTitle: `${t("campaign-title")}`,
    //   pageURL: `/${i18n.language}${t('link-news-and-report')}`,
    // },

   
    {
      menuTitle: `${t("case-statistic")}`,
      pageURL: `/${i18n.language}${t('link-case-statistic')}`,
    },

    {
      menuTitle: `${t("case-databank")}`,
      pageURL: `/${i18n.language}${t('link-case-database')}`,
    },
    {
      menuTitle: `${t("faq")}`,
      pageURL: `/${i18n.language}${t('link-faq')}`,
    },
    
    {
      menuTitle: `${t("contact")}`,
      pageURL: `/${i18n.language}${t('link-contact-us')}`,
    },
  ];

  useEffect(() => {
    setlan(i18n.language)
  //  const url = 
 
 
    const handleScroll = () => {
      const show = window.scrollY > 50
      if (show) {
          setNavBackground('appBarColors')
      } else {
          setNavBackground('appBarTransparent')
      }
  }
  document.addEventListener('scroll', handleScroll)
  return () => {
      document.removeEventListener('scroll', handleScroll)
  }
  

  }, [i18n.language]) //Just added i18n.language #Joe
  // console.log(lan);
  // console.log(props.location.pathname);
  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
       // color='transparent'
        // elevation='none'
        elevation={0}
        className={classes[navRef.current]}
       // style={{ backgroundColor: "#1D384C", textDecoration: "none" }}
      >
        <Toolbar>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={10} sm={10}>
              {isMobile ? (
                <>
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={handleMenu}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                  >
                    {menuItems.map((menuItem) => {
                      const { menuTitle, pageURL } = menuItem;
                      return (
                        <MenuItem key={menuTitle} className={classes.mobileWeight} onClick={() => handleMenuClick(pageURL)}>
                          {menuTitle}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </>
              ) : (
                <div className={classes.headerOptions}>
                  <NavLink
                    activeClassName="current"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      marginTop: "8px",
                      

                    }}
                    to="/"
                  >
                    <Typography className={classes.paragraphe}>
                      {t("home")}
                    </Typography>
                  </NavLink>
                  <NavLink
                    activeStyle={{
                    fontWeight:700,
                    color: "#00A1AF"
                  }}
                    activeClassName="is-active"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      marginTop: "8px",
                      

                    }}
                    to={'/'+i18n.language+t('link-about')}
                  >
                    <Typography className={classes.paragraphe}>
                      {t("about")}
                    </Typography>
                  </NavLink>
                  <NavLink
                   activeStyle={{
                    fontWeight:700,
                    color: "#00A1AF"
                  }}
                    activeClassName="is-active"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      marginTop: "8px",
                    }}
                    to={'/'+i18n.language+t('link-campain')} 
                  >
                    <Typography className={classes.paragraphe}>
                      {t("campain")}
                    </Typography>
                  </NavLink>
                  {/* link for new and report */}
                  {/* <NavLink
                   activeStyle={{
                    fontWeight:700,
                    color: "#00A1AF"
                  }}
                    activeClassName="is-active"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      marginTop: "8px",
                    }}
                    to={'/'+i18n.language+t('link-news-and-report')} 
                  >
                    <Typography className={classes.paragraphe}>
                      {t("campaign-title")}
                    </Typography>
                  </NavLink> */}

                  <NavLink
                   activeStyle={{
                    fontWeight:700,
                    color: "#00A1AF"
                  }}
                    activeClassName="is-active"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      marginTop: "8px",
                    }}
                    to={'/'+i18n.language+t('link-case-statistic')} 
                  >
                    <Typography className={classes.paragraphe}>
                      {t("case-statistic")}
                    </Typography>
                  </NavLink>
                  <NavLink
                   activeStyle={{
                    fontWeight:700,
                    color: "#00A1AF"
                  }}
                    activeClassName="is-active"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      marginTop: "8px",
                    }}
                    to={'/'+i18n.language+t('link-case-database')} 
                  >
                    <Typography className={classes.paragraphe}>
                      {t("case-databank")}
                    </Typography>
                  </NavLink>

                  <NavLink
                   activeStyle={{
                    fontWeight:700,
                    color: "#00A1AF"
                  }}
                    activeClassName="is-active"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      marginTop: "8px",
                    }}
                    to={'/'+i18n.language+t('link-faq')}
                  >
                    <Typography className={classes.paragraphe}>
                      {t("faq")}
                    </Typography>
                  </NavLink>
                  <NavLink
                   activeStyle={{
                    
                    color: "#00A1AF"
                  }}
                    // activeClassName="is-active"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      marginTop: "8px",
                    }}
                    to={'/'+i18n.language+t('link-contact-us')} 
                  >
                   
                    <Typography className={classes.paragraphe}>
                    {t("contact")}
                    </Typography>
                  </NavLink>
                </div>
              )}
            </Grid>
            <Grid item xs={2} sm={2}>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handlelanguage}
                className={classes.language}
                // endIcon={}
              
              >
                {lan === null ? <LanguageIcon /> :<>{lan}<KeyboardArrowDownIcon className={classes.icon}/></>  }
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorlan}
                keepMounted
                open={Boolean(anchorlan)}
                onClose={handleCloseLan}
              >
                <MenuItem onClick={() => changeLanguage("en")}>En</MenuItem>
                <MenuItem onClick={() => changeLanguage("fr")}>Fr</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withRouter(ApptopBar);

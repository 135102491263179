import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import useStyles from "../../../dashboard/styles";
import { useTranslation } from "react-i18next";

const useRowStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},
});

function createData(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8,
	tpn9,
	vl9
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
			{ contentID: `${tpn9}`, amount: `${vl9}` },
		],
	};
}

function createData2(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8,
	tpn9,
	vl9,
	tpn10,
	vl10,
	tpn11,
	vl11,
	tpn12,
	vl12,
	tpn13,
	vl13
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
			{ contentID: `${tpn9}`, amount: `${vl9}` },
			{ contentID: `${tpn10}`, amount: `${vl10}` },
			{ contentID: `${tpn11}`, amount: `${vl11}` },
			{ contentID: `${tpn12}`, amount: `${vl12}` },
			{ contentID: `${tpn13}`, amount: `${vl13}` },
		],
	};
}

function createData3(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8,
	tpn9,
	vl9,
	tpn10,
	vl10,
	tpn11,
	vl11
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
			{ contentID: `${tpn9}`, amount: `${vl9}` },
			{ contentID: `${tpn10}`, amount: `${vl10}` },
			{ contentID: `${tpn11}`, amount: `${vl11}` },
		],
	};
}

function createData4(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8,
	tpn9,
	vl9,
	tpn10,
	vl10,
	tpn11,
	vl11
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
			{ contentID: `${tpn9}`, amount: `${vl9}` },
			{ contentID: `${tpn10}`, amount: `${vl10}` },
			{ contentID: `${tpn11}`, amount: `${vl11}` },
		],
	};
}
function Row(props) {
	const { row } = props;
	const [open, setOpen] = React.useState(false);
	const classes = useRowStyles();
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>

				<TableCell component="th" scope="row">
					<Typography
						variant="button"
						style={{ fontWeight: 330, textTransform: "unset", textAlign: "left", cursor: "pointer"}}
						onClick={() => setOpen(!open)}
					>
						{row.name}
					</Typography>
				</TableCell>

				<TableCell align="right" style={{ fontWeight: 330 }}>
					{row.total}
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							{/* <Typography variant="h6" gutterBottom component="div">
                Type
              </Typography> */}
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell style={{ fontWeight: 300, fontSize: 18 }}>
											Incidents
										</TableCell>
										<TableCell
											align="right"
											style={{ fontWeight: 300, fontSize: 18 }}
										>
											{t("cases")}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{row.history.map((historyRow) => (
										<TableRow key={historyRow.contentID}>
											<TableCell style={{ fontWeight: 300 }}>
												{historyRow.contentID}
											</TableCell>
											<TableCell align="right" style={{ fontWeight: 300 }}>
												{historyRow.amount}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

Row.propTypes = {
	row: PropTypes.shape({
		// calories : PropTypes.number.isRequired,
		// carbs: PropTypes.number.isRequired,
		// fat: PropTypes.number.isRequired,
		history: PropTypes.arrayOf(
			PropTypes.shape({
				amount: PropTypes.number.isRequired,
				contentID: PropTypes.any.isRequired,
				// contentID: PropTypes.string.isRequired,
			})
		).isRequired,
		name: PropTypes.string.isRequired,
		total: PropTypes.number.isRequired,
	}).isRequired,
};

const Tabls = ({
	title1,
	title2,
	incidentTotal,
	viol,
	conj,
	agr,
	coer,
	har,
	attou,
	etr,
	empl,
	autr,
	physiqueTotal,
	meu,
	coupDepoing,
	coupDobj,
	att,
	attAcouto,
	attqAleau,
	priv,
	privDeNoutr,
	neglDEnfa,
	neglDpers,
	destr,
	rite,
	autr2,
	psychoTotal,
	men,
	inj,
	neglEm,
	humil,
	attVisant,
	isol,
	contr,
	cntrDecom,
	refus,
	abus,
	autr3,
	auteur,
	econTotal,
	conf,
	inter,
	restdeLAccessDeducatio,
	restdeLAccessDemploie,
	restdeLAccessDeconomique,
	privDepriseEncharge,
	levirat,
	pertepropriete,
	perteDenfa,
	autr4,
}) => {
	const { t } = useTranslation();
	const rowsx = [
		createData(
			`${t("case-statistic-violence-categorie1")}`,
			`${incidentTotal}`,
			`${t("case-statistic-incident-of-sex-rape")}`,
			`${viol}`,
			`${t("case-statistic-incident-of-sex-mar")}`,
			`${conj}`,
			`${t("case-statistic-incident-of-sex-sexual")}`,
			`${agr}`,
			`${t("case-statistic-incident-of-sex-coe")}`,
			`${coer}`,
			`${t("case-statistic-incident-of-sex-har")}`,
			`${har}`,
			`${t("case-statistic-incident-of-sex-inappropriete")}`,
			`${attou}`,
			`${t("case-statistic-incident-of-sex-forcing")}`,
			`${etr}`,
			`${t("case-statistic-incident-of-sex-preventing")}`,
			`${empl}`,
			`${t("case-statistic-incident-of-sex-other")}`,
			`${autr}`
		),
		createData2(
			`${t("case-statistic-violence-categorie2")}`,
			`${physiqueTotal}`,
			`${t("case-statistic-incident-of-phys-mur")}`,
			`${meu}`,
			`${t("case-statistic-incident-of-phys-punch")}`,
			`${coupDepoing}`,
			`${t("case-statistic-incident-of-phys-punchwithobj")}`,
			`${coupDobj}`,
			`${t("case-statistic-incident-of-phys-acidattack")}`,
			`${att}`,
			`${t("case-statistic-incident-of-phys-attackwithknife")}`,
			`${attAcouto}`,
			`${t("case-statistic-incident-of-phys-attackwithhotwater")}`,
			`${attqAleau}`,
			`${t("case-statistic-incident-of-phys-deprivation")}`,
			`${priv}`,
			`${t("case-statistic-incident-of-phys-fooddeprivation")}`,
			`${privDeNoutr}`,
			`${t("case-statistic-incident-of-phys-physneglofchild")}`,
			`${neglDEnfa}`,
			`${t("case-statistic-incident-of-phys-physneglofelder")}`,
			`${neglDpers}`,
			`${t("case-statistic-incident-of-phys-destr")}`,
			`${destr}`,
			`${t("case-statistic-incident-of-phys-risk")}`,
			`${rite}`,
			`${t("case-statistic-incident-of-phys-other")}`,
			`${autr2}`
		),
		createData3(
			`${t("case-statistic-violence-categorie3")}`,
			`${psychoTotal}`,
			`${t("case-statistic-incident-of-emot-threat")}`,
			`${men}`,
			`${t("case-statistic-incident-of-emot-insult")}`,
			`${inj}`,
			`${t("case-statistic-incident-of-emot-emot")}`,
			`${neglEm}`,
			`${t("case-statistic-incident-of-emot-hum")}`,
			`${humil}`,
			`${t("case-statistic-incident-of-emot-victimreputatio")}`,
			`${attVisant}`,
			`${t("case-statistic-incident-of-emot-isol")}`,
			`${isol}`,
			`${t("case-statistic-incident-of-emot-ctrmov")}`,
			`${contr}`,
			`${t("case-statistic-incident-of-emot-ctrcomm")}`,
			`${cntrDecom}`,
			`${t("case-statistic-incident-of-emot-refus")}`,
			`${refus}`,
			`${t("case-statistic-incident-of-emot-spiritual")}`,
			`${abus}`,
			`${t("case-statistic-incident-of-emot-other")}`,
			`${autr3}`
		),
		createData4(
			`${t("case-statistic-violence-categorie4")}`,
			`${econTotal}`,
			`${t("case-statistic-incident-of-ecom-perp")}`,
			`${auteur}`,
			`${t("case-statistic-incident-of-ecom-forf")}`,
			`${conf}`,
			`${t("case-statistic-incident-of-ecom-prohibitio")}`,
			`${inter}`,
			`${t("case-statistic-incident-of-ecom-restreducatio")}`,
			`${restdeLAccessDeducatio}`,
			`${t("case-statistic-incident-of-ecom-restremploy")}`,
			`${restdeLAccessDemploie}`,
			`${t("case-statistic-incident-of-ecom-restrecom")}`,
			`${restdeLAccessDeconomique}`,
			`${t("case-statistic-incident-of-ecom-depriv")}`,
			`${privDepriseEncharge}`,
			`${t("case-statistic-incident-of-ecom-levirat")}`,
			`${levirat}`,
			`${t("case-statistic-incident-of-ecom-lossofproperty")}`,
			`${pertepropriete}`,
			`${t("case-statistic-incident-of-ecom-lossofchildren")}`,
			`${perteDenfa}`,
			`${t("case-statistic-incident-of-ecom-other")}`,
			`${autr4}`
		),
	];
	const classes = useStyles();
	return (
		<TableContainer component={Paper} >
			<Table aria-label="collapsible table" >
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell className={classes.typochart}>{title1}</TableCell>
						<TableCell align="right" className={classes.typochart}>
							{title2}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rowsx.map((row) => (
						<Row key={row.name} row={row} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
export { Tabls };

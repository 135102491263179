import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import useStyles from "../../../website/widgets/style";
import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

const PostMediaSkeleton = () => {
	const classes = useStyles();
	//dialog

	return (
		<Card className={classes.posts}>
			<CardActionArea>
				<Skeleton variant="rect" height={140} />
			</CardActionArea>
			<CardActions>
				<Grid container direction="column">
					<Grid item xs={12} sm={12}>
						<Grid container>
							<Grid item md={6} xs={6} justifyContent="center">
								<Skeleton variant="rect" height={20} />
							</Grid>
							<Grid item md={6} xs={6}>
								<Grid
									container
									direction="column"
									justifyContent="center"
									alignItems="flex-end"
								>
									<Skeleton variant="rect" width={60} height={20} />
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} sm={12} className={classes.marginBottom_10}>
							<Grid container direction="row">
								<Grid item xs={6} sm={6}>
									<Grid
										container
										justifyContent="flex-start"
										alignItems="flex-start"
									>
										<Grid item>
											<Skeleton variant="rect" width={80} height={20} />
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={6} sm={6}>
									<Grid
										container
										justifyContent="flex-end"
										alignItems="flex-end"
									>
										<Grid item>
											<Skeleton variant="rect" width={60} height={20} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</CardActions>
		</Card>
	);
};
export default PostMediaSkeleton;

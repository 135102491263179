import { Grid, Typography } from "@material-ui/core";
import useStyles from "../../component/website/campain/style";
import React,{useEffect} from "react";
import Section1 from  '../../component/website/what_is_dv/section1'
import { useTranslation } from 'react-i18next';
import {Helmet} from "react-helmet";



export default function What_is_dv() {
  const classes = useStyles();
  const { t } = useTranslation();

useEffect(() => {
  window.scrollTo(0, 0)

}, [])
  return (
    <div>
      <Helmet>
              <title>{t('sectionLeft')}</title>
              <meta name='description' content={t('sectionLeft-text')}/>
            </Helmet>
      <Grid container className={classes.section_1} alignItems="center">
        <Grid md={1} lg={1} xl={2}></Grid>
        <Grid md={10} lg={10}>
          <Section1 />
        </Grid>
        <Grid md={1} lg={1} xl={2}></Grid>
      </Grid>
      <Grid className={classes.marginTop_13} style={{ padding: "2vh" }}>
        <Grid container alignItems="center">
          <Grid item xs={1} sm={1} md={2} lg={2} xl={2}></Grid>
          <Grid item xs={10} sm={10}  md={8} lg={8} xl={8} className={classes.marginBottom_5}>
            <Grid container direction='column' spacing={2}>
              <Grid item xs={12} sm={12}>              <Grid container>
                   <Grid item><Typography className={classes.dark_txt}>
                   <strong>{t('section-left-content-bold-p1')}</strong>
                   {t('section-left-content-text-p1')}
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
               <Grid container>
                   <Grid item><Typography className={classes.dark_txt}>
                   {t('section-left-content-text-p2')}
                   
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
               <Grid container>
                   <Grid item><Typography className={classes.dark_txt}>
                   <strong >{t('section-left-content-bold-p3')}</strong>
                  
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
               <Grid container>
                   <Grid item><Typography className={classes.dark_txt}>
                   <strong >{t('section-left-content-bold-p4')}</strong>
                   {t('section-left-content-text-p4')}
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
               <Grid container >
                   <Grid item><Typography className={classes.dark_txt}>
                   <strong >{t('section-left-content-bold-p5')}</strong>
                   {t('section-left-content-text-p5')}
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
               <Grid container >
                   <Grid item><Typography className={classes.dark_txt}>
                   <strong >{t('section-left-content-bold-p6')}</strong>
                   {t('section-left-content-text-p6')}
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
               <Grid container >
                   <Grid item><Typography className={classes.dark_txt}>
                   <strong >{t('section-left-content-bold-p7')}</strong>
                   {t('section-left-content-text-p7')}
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
               <Grid container >
                   <Grid item><Typography className={classes.dark_txt}>
                   <strong >{t('section-left-content-bold-p8')}</strong>
                   {t('section-left-content-text-p8')}
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>
              
              
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={2} lg={2} xl={6}></Grid>
        </Grid>
      </Grid>
    </div>
  );
}




import React from 'react';
import {Button,TextField} from '@material-ui/core'
import useStyles from './styles'


const BtnContained=({content,onClick,component,to,type})=>{
   
    const classes= useStyles()
    
    return (
        
            <Button  variant='contained'  type={type} component={component} to={to} className={classes.btnContained} onClick={onClick}>{content}</Button>
               
    
    )
}
const BtnLogin=({content,onClick,component,to})=>{
   
    const classes= useStyles()
    
    return (
        
            <Button  variant='contained'  component={component}  type="submit" to={to} className={classes.btnLogin} onClick={onClick}>{content}</Button>
               
    
    )
}

const BtnContainedSearch=({content,onClick,component,to})=>{
   
    const classes= useStyles()
    
    return (
        
            <Button  variant='contained'  component={component} to={to} className={classes.btnContainedSearch} onClick={onClick}>{content}</Button>
               
    
    )
}





const BtnOutline=({content,onClick,component,to})=>{
   
    const classes= useStyles()
    
    return (
        
            <Button variant='outlined' component={component} to={to}   className={classes.btnColored} onClick={onClick}>{content}</Button>
               
    
    )
}

const BtnText=({content,onClick,component,to})=>{
   
    const classes= useStyles()
    
    return (
        
            <Button  component={component} to={to}   className={classes.btntext} onClick={onClick}>{content}</Button>
               
    
    )
}

const BtnText1=({content,onClick,component,to})=>{
   
    const classes= useStyles()
    
    return (
        
            <Button  component={component} to={to}  type="submit"  className={classes.btntext1} onClick={onClick}>{content}</Button>
               
    
    )
}

const Btndrop=({content,onClick,component,to,endIcon,disabled})=>{
   

    const classes= useStyles()
    return (
        <Button variant='outlined' component={component} type="submit" to={to} endIcon={endIcon} disabled={disabled} className={classes.btndrop} onClick={onClick}>{content}</Button>
    )

}
const Btndrop1=({content,onClick,component,to,endIcon,disabled})=>{
   

    const classes= useStyles()
    return (
        <Button variant='outlined' type="submit" component={component} to={to} endIcon={endIcon} className={classes.btndrop1} disabled={disabled} onClick={onClick}>{content}</Button>
    )

}



const BtnLogout=({content,onClick,component,to,endIcon})=>{
   

    const classes= useStyles()
    return (
        <Button  component={component} to={to} startIcon={endIcon}  type="submit" className={classes.btndrop} onClick={onClick}>{content}</Button>
    )

}



const BtnLogout2=({content,onClick,component,to,endIcon})=>{
   

    const classes= useStyles()
    return (
        <Button  component={component} to={to} startIcon={endIcon}  type="submit" className={classes.btndrop2} onClick={onClick}>{content}</Button>
    )

}


const TextForm = ({label,type,name,inputRef,defaultValue,placeholder,onchange,onClick,required,helperText,error})=>{

    return (
        <TextField
         error={error}
         defaultValue={defaultValue}
         required={required}
         onClick={onClick}
         onChange={onchange}
         label={label}
         placeholder={placeholder}
         variant='outlined'
         type ={type}
         size = 'small'
         name={name}
         inputRef={inputRef}
         fullWidth
         helperText={helperText}
         
        />
    )
  
}




export  {
    
     BtnText1,
     Btndrop1,
     BtnLogin,
     BtnLogout,
     TextForm,
     BtnText,
     Btndrop,
     BtnOutline,
     BtnContained,
     BtnContainedSearch,
     BtnLogout2

}

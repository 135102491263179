import React, { useState, useEffect, useRef } from "react";
import {
	Grid,
	Typography,
	Button,
	TextField,
	Snackbar,
	// Select,
	// MenuItem,
} from "@material-ui/core";
import useStyles from "./style.js";
import { useTranslation } from "react-i18next";
import SendIcon from "@material-ui/icons/Send";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import axiosInstance from "../../../service/axiosPub";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Content = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { register, handleSubmit, reset } = useForm(); //watch
	const [isloading, setisloading] = useState(false);
	const reRef = useRef();
	const [msg, setmsg] = useState("");
	const [state, setState] = React.useState(false);

	const handleClose = () => {
		setState(false);
	};

	const onSubmit = async (data, e) => {
		e.preventDefault();
		const token = await reRef.current.executeAsync();
		// console.log(reRef.current);
		reRef.current.reset();

		if (token !== "") {
			handleContact(
				data.contactname,
				data.contactaddress,
				data.contactnumber,
				data.contactsuj,
				data.contactmsg,
				token
			);
		}
		//  console.log(data);
		// console.log(token);
	};

	const handleContact = (name, email, phonenumber, subject, message, token) => {
		setisloading(true);
		axiosInstance
			.post("contact/", {
				name: name,
				email: email,
				phonenumber: phonenumber,
				subject: subject,
				message: message,
				token: token,
			})
			.then((res) => {
				if (res.status === 200) {
					setisloading(false);
					setState(true);
					setmsg(`${t("succes-msg")}`);
					reset({});
				}
			})
			.catch((err) => {
				setisloading(false);
				setState(true);

				if (err.request) {
					setmsg(`${t("error-msg")}`);
				} else if (err.response) {
					setmsg("Please, check your internet connection");
				}
			});
	};

	useEffect(() => {}, []); //axiosInstance

	return (
		<div>
			<Grid container className={classes.marginTop_13}>
				<Grid container direction="row" className={classes.marginBottom_15}>
					{/* <Grid container direction="row" alignItems="flex-start"> */}
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid container direction="row" spacing={4}>
							<Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12}>
										<Grid container>
											<Grid item xs={12} sm={12}>
												<Typography className={classes.grey_color}>
													{t("contact-name")}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={12}>
												<TextField
													id="outlined-multiline-static"
													placeholder={t("contact-name-placeholder")}
													required
													multiline
													fullWidth
													name="contactname"
													inputRef={register}
													InputProps={{
														classes: { input: classes.textarea },
													}}
													// className={classes.textarea}
													rows={1}
													variant="outlined"
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={12}>
										<Grid container>
											<Grid item xs={12} sm={12}>
												<Typography className={classes.grey_color}>
													{t("contact-address")}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={12}>
												<TextField
													placeholder={t("contact-address-placeholder")}
													required
													fullWidth
													InputProps={{
														classes: { input: classes.textarea },
													}}
													name="contactaddress"
													inputRef={register}
													type="email"
													variant="outlined"
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={12}>
										<Grid container>
											<Grid item xs={12} sm={12}>
												<Typography className={classes.grey_color}>
													{t("contact-number")}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={12}>
												<TextField
													placeholder={t("contact-number-placeholder")}
													required
													fullWidth
													InputProps={{
														classes: { input: classes.textarea },
														inputProps: { min: 1 },
													}}
													type="number"
													name="contactnumber"
													inputRef={register}
													variant="outlined"
												/>
												{/* <Grid container direction='row'>
                       <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                       </Grid>
                       <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                        <TextField
                        placeholder={t('contact-number-placeholder')}
                        required
                        fullWidth
                        InputProps={{
                          classes: { input: classes.textarea},
                          inputProps: { min: 1, max:100 }
                        }}
                        type='number'
                        name='contactnumber'
                        inputRef={register}
                        
                        variant="outlined"
                      />
                       </Grid>
                     </Grid> */}
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={12}>
										<Grid container>
											<Grid item xs={12} sm={12}>
												<Typography className={classes.grey_color}>
													{t("contact-subj")}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={12}>
												<TextField
													id="outlined-multiline-static"
													placeholder={t("contact-name-subj")}
													required
													multiline
													fullWidth
													InputProps={{
														classes: { input: classes.textarea },
													}}
													name="contactsuj"
													inputRef={register}
													rows={1}
													variant="outlined"
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							{/*  */}
							<Grid item sm={12} lg={6} xs={12} md={6} xl={6}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12}>
										<Grid container>
											<Grid item xs={12} sm={12}>
												<Typography className={classes.grey_color}>
													{t("contact-msg")}
												</Typography>
											</Grid>

											<Grid item xs={12} sm={12}>
												<TextField
													id="outlined-multiline-static"
													placeholder={t("contact-msg-placeholder")}
													required
													fullWidth
													multiline
													name="contactmsg"
													inputRef={register}
													InputProps={{
														classes: { input: classes.textarea },
													}}
													rows={12}
													variant="outlined"
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={12}>
										<Grid
											container
											spacing={2}
											justifyContent="flex-end"
											alignItems="flex-end"
										>
											<Grid item>
												<ReCAPTCHA
													ref={reRef}
													size="invisible"
													sitekey={process.env.REACT_APP_SITE_KEY}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={12}>
										<Grid
											container
											spacing={2}
											justifyContent="flex-end"
											alignItems="flex-end"
										>
											<Grid item className={classes.wrapper}>
												<Button
													disabled={isloading}
													type="submit"
													variant="outlined"
													color="secondary"
													className={classes.button}
													endIcon={<SendIcon />}
												>
													{t("contact-send")}
												</Button>

												{isloading === true ? (
													<CircularProgress
														size={24}
														className={classes.buttonProgress}
													/>
												) : null}
											</Grid>
											<Snackbar
												// anchorOrigin=
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "left",
												}}
												autoHideDuration={5000}
												open={state}
												onClose={handleClose}
											>
												<Alert
													severity={
														msg === `${t("succes-msg")}` ? "success" : "error"
													}
												>
													<Typography>{msg}</Typography>
												</Alert>
											</Snackbar>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</Grid>
			</Grid>
			{/* </Grid> */}
		</div>
	);
};

export default Content;

import {makeStyles } from '@material-ui/core/styles'


const drawerWidth = 220;
export default  makeStyles((theme)=>({

  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
      backgroundColor:'#00A1AF'

    },

    
  },
  appBar: {
    [theme.breakpoints.down('md')]: {
      // width: `calc(100% - ${drawerWidth}px)`,
      // marginLeft: drawerWidth,
      backgroundColor:'#4F2970',
      fontWeight:300,
      
      
    },
  
  },
  menuButton: {
    marginRight: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      display: 'none',
      
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    margin: 10,
    
  },
margin_Bottom_30:{
    marginBottom:50,
    marginTop:20
},
margin_Bottom_10:{
    marginTop:10,
    marginBottom:10
},
margin_Top_30:{
    marginTop:30,
    
},
avatar:{
  backgroundColor:'#4F2970'
  
},
avatarSize:{
  color: '#004247',
  width:theme.spacing(10),
  height:theme.spacing(10)

},
typography:{
  color: '#FFFFFF'
},
typography2:{
  color: '#FFFFFF',
  fontSize:11
},

title:{
  color:'rgba(0,0,0,0.87)',
  fontSize:13,
  fontWeight:300
},

icon:{
  color: '#2C2E59'
},

margin_100:{
  marginTop : 100,
  
  [theme.breakpoints.up('xs')]:{
    marginTop: 90
  },

  [theme.breakpoints.between('sm','md')]:{
    marginTop: 350
  },
},

typoBig:{
  color:'#4F2970',
  fontWeight:300,
  marginTop:57,
  marginBottom:49
},
leftandrightInput:{
  padding: 50
},

titleLogin:{
  color:'#4F2970',
  fontWeight:300,
  marginBottom:20,
},
subtitleLogin:{
  color:'#4F2970',
  fontWeight:300,
  fontSize:14,
  marginBottom:30
},

foot:{
  marginTop:20,
  fontSize:12,
  fontWeight:400,
  marginBottom:10
,
}




   

}))
import { alpha, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  section_4: {
    background: "#4BBDC7",
    height: "auto",
  },
  width_80: {
    width: "80vw",
  },
  width_70: {
    width: "70vw",
  },

  big_white_txt: {
    color: "white",
    fontWeight: 700,
    fontSize: "100px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "40px",
    },
  },

  sub_big_white_txt: {
    color: "white",
    fontWeight: 700,
    fontSize: "40px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "16px",
    },
  },

  white_txt: {
    color: "white",
    fontSize: "16px",
  },

  white_color: {
    color: "white",
  },

  green_color: {
    color: "#00A1AF",
  },

  overview_ttl: {
    color: "#1D384C",
    fontWeight: 700,
    textAlign: "center",
    fontSize: "50px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "23px",
    },
  },

  about_ttl: {
    color: "#1D384C",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "50px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "23px",
    },
  },
  goal_ttl: {
    color: "#FFF",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "40px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "15px",
    },
  },

  abt_txt: {
    color: "#1D384C",
    textAlign: "left",
    fontWeight: 400,
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "15px",
    },
  },

  about_link: {
    color: "#00A1AF",
    fontWeight: 700,
    textAlign: "left",
    fontStyle: "italic",
    textDecoration: "underline",
    fontSize: "30px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "15px",
    },
  },

  dark_txt: {
    color: "#1D384C",
    textAlign: "center",
    fontWeight: 400,
    fontSize: "25px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "15px",
    },
  },
  dark_color: {
    color: "#1D384C",
  },
  oxide_color: {
    color: "#EB9B29",
  },
  oxide_text: {
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "12px",
    },
  },

  overview_link: {
    color: "#00A1AF",
    fontWeight: 700,
    textAlign: "center",
    fontStyle: "italic",
    textDecoration: "underline",
    fontSize: "30px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "15px",
    },
  },

  card_circle: {
    height: "175px",
    width: "175px",
    borderRadius: "50%",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      display: "center",
    },
  },
  card_pic: {
    height: "480px",
    width: "480px",
    backgroundColor: "#00A1AF",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      height: "280px",
      width: "320px",
    },
  },
  card_stl: {
    backgroundColor: "#00A1AF",
  },

  goals_stl: {},
  red_color: {
    color: "#D12028",
  },

  posts: {
    //border: '0.4px solid #2D2C2F'
    //maxWidth: 250,
    //padding: 10,
  },
  label:{
    fontSize: 20,
    color: '#2D2C2F',
    fontWeight:380,
   
},
labelwebsite:{
  fontSize: 20,
  color: '#2D2C2F',
  fontWeight:400,
  marginTop:20,
  marginBottom:10,
  [theme.breakpoints.up('xl')]:{
    fontSize: 25,
  },
  [theme.breakpoints.down('xs')]:{
    fontSize: 25,
    width:300
  },
  
  

 
},
date_posted:{
  marginTop:5,
  fontSize: 12,
  color: '#2D2C2F',
  fontWeight:300,
},
btn_more:{
  fontSize: 10,
  //color: theme.palette.secondary,
  fontWeight:300,
  

},
label_desc:{
  fontSize: 15,
  color: '#2D2C2F',
  fontWeight:300,
  height:20,
  width:'auto',
  [theme.breakpoints.up('xl')]:{
    fontSize: 20,
  }
 
},
  posts_media: {
    height: 180,
    [theme.breakpoints.up('xl')]:{
      height:250
    }
   // [theme.breakpoints.down('xs')]:{}
   
  //   width:'100%',
  //   display:'flex',
  //  justifyContent:'center'
   
  },
  content_card:{
    height:180,
    [theme.breakpoints.up('xl')]:{
      height:250
    }
  },

  marginBottom_5: {
    marginBottom: "5vh",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      marginBottom: "2.5vh",
    },
  },
  marginBot_5: {
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      marginBottom: "2.5vh",
    },
  },
  marginLeft_2: {
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      marginLeft: "2.5vh",
    },
  },
  marginTop_2: {
    marginTop: "2.5vh",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      marginTop: "2.5vh",
    },
  },
  right_align: {
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  table_Color1: {
    color: "#323643",
  },
  table_Color2: {
    color: "#606470",
  },

  marginBottom_15: {
    marginBottom: "15vh",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      marginBottom: "5vh",
    },
  },
  marginBottom_10: {
    marginBottom: "10vh",
  },

  overview_ttl2: {
    color: "#1D384C",
    fontWeight: 700,
    marginBottom: 35,
    textAlign: "center",
    fontSize: "1vw",
  },

  marginLeft_10: {
    marginLeft: "10vw",
  },

  marginTop_10: {
    marginTop: "10vh",
  },

  marginTop_13: {
    marginTop: "13vh",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      marginTop: "7vh",
    },
  },

  big_dark_tittle: {
    color: "#1D384C",
    fontWeight: 700,
    textAlign: "center",
  },

  card: {
    marginTop: "100px",
    height: "539px",
    widht: "400px",
  },

  divider_stl: {
    widht: "5px",
    color: "white",
  },

  area: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  cardHome: {
    marginTop: "80px",
    height: "539px",
    widht: "431px",
  },
  notchedOutline: {
    borderWidth: "1px solid",
    borderColor: "#979797 !important",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `#00A1AF !important`,
    },
  },
  cssFocused: {},

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },

  bigtitle: {
    marginTop: "140px",
    marginBottom: "60px",
  },
  disconnect: {
    color: "red",
    marginTop: "30px",
    textDecoration: "underline",
  },
  continue: {
    color: "white",
    backgroundColor: "#00A1AF",
    fontSize: 10,
    width: 100,
    "&:hover": {
      backgroundColor: "#00A1AF",
    },
  },

  dialogTypo: {
    fontWeight: "bold",
  },

  btnContained: {
    backgroundColor: "#00A1AF",
    color: "#FFFFFF",
    fontSize: "10px",
    marginTop: 30,
    "&:hover": {
      backgroundColor: "#00A1AF",
    },
    height: "30px",
    width: "100px",
    marginRight: 10,
    [theme.breakpoints.down("md")]: {
      width: 90,
      marginRight: 10,
    },
    [theme.breakpoints.down("sm")]: {
      width: 80,
      fontSize: 10,
      marginRight: 10,
    },
  },
  btndrop2: {
    [theme.breakpoints.down("sm")]: {
      width: 90,
    },
    [theme.breakpoints.up("sm")]: {
      width: 100,
    },
    //  marginTop:15,
    //  paddingRight:10,
    // marginLeft:10,
    marginTop: 30,

    height: 30,
    color: "white",
    backgroundColor: "#D12028",

    fontSize: 10,
    // color: ' #FFF',
    "&:hover": {
      backgroundColor: "#D12028",
    },
  },
  root: {
    height: 420,
    width: "480",

    [theme.breakpoints.up("xl")]: {
      height: 380,
      width: 640,
      marginRight: "20px",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      height: 380,
      width: 620,
      marginRight: "20px",
    },
    [theme.breakpoints.only("lg")]: {
      height: 380,
      width: 620,
      marginRight: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      height: 420,
      width: 620,
      marginRight: "20px",
    },
    [theme.breakpoints.only("md")]: {
      height: 420,
      width: 620,
      marginRight: "20px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: 420,
      width: 620,
      minWidth: 300,
    },
    [theme.breakpoints.only("sm")]: {
      height: 420,
      width: 620,
      minWidth: 300,
    },
    [theme.breakpoints.between("xs", "sm")]: {
      height: 420,
      width: 620,
      minWidth: 300,
    },
    [theme.breakpoints.only("xs")]: {
      height: 280,
      width: 440,
      minWidth: 300,
    },
    [theme.breakpoints.down("xs")]: {
      height: 280,
      width: 340,
      minWidth: 300,
    },
  },

  media: {
    [theme.breakpoints.up("xl")]: {
      height: 380,
      width: 640,
    },
    [theme.breakpoints.between("lg", "xl")]: {
      height: 380,
      width: 620,
    },
    [theme.breakpoints.only("lg")]: {
      height: 380,
      width: 620,
    },
    [theme.breakpoints.between("md", "lg")]: {
      height: 420,
      width: 620,
    },
    [theme.breakpoints.only("md")]: {
      height: 420,
      width: 620,
      minWidth: 300,
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: 420,
      width: 620,
      minWidth: 300,
    },
    [theme.breakpoints.only("sm")]: {
      height: 420,
      width: 620,
      minWidth: 300,
    },
    [theme.breakpoints.between("xs", "sm")]: {
      height: 420,
      width: 620,
      minWidth: 300,
    },
    [theme.breakpoints.only("xs")]: {
      height: 280,
      width: 440,
      minWidth: 300,
    },
    [theme.breakpoints.down("xs")]: {
      height: 280,
      width: 340,
      minWidth: 300,
    },
    // [theme.breakpoints.between("md", "lg")]: {
    //   height: 380,
    //   width: 640,
    // },
  },
  // marginBottom_10:{
  //   marginTop:20
  // }
}));

import React from 'react';
import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';


const ArticleSkeleton =()=>{
    return (
        <Grid container spacing={1} style={{marginTop:30,marginBottom:50}} >
        <Grid item xs={12} sm={12}><Skeleton variant="rect" height={300}  /></Grid>
        <Grid item xs={12} sm={12}>
        <Skeleton variant="text" height={20} width='50%'  />
        </Grid>
        <Grid item xs={12} sm={12}>
        <Skeleton variant="text" height={15}  width='30%'/>
        </Grid>

        <Grid item xs={12} sm={12}>
        <Skeleton variant="text" height={10}  width='20%'/>
        </Grid>
        <Grid item xs={12} sm={12}><Skeleton variant="rect" height={200}  /></Grid>
        <Grid item xs={12} sm={12}>
        <Skeleton variant="text" height={15}  width='30%'/>
        </Grid>      
        </Grid>
    
                     
    )
}

export default ArticleSkeleton;
import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useStyles from "./style";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const PostMedia = ({
	image,
	imagetitle,
	category,
	title,
	description,
	posteddate,
	readmoreClicked,
	color
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Card className={classes.posts} elevation={(0, 5)}>
			<CardActionArea onClick={readmoreClicked}>
				<CardMedia
					className={classes.posts_media}
					image={image}
					title={imagetitle}
				/>
				<CardContent className={classes.content_card}>
					<Typography
						style={{ color: `${color}`, fontWeight: 300 }}
						gutterBottom
						variant="caption"
						component="h2"
						className={classes.red_color}
					>
						{category}
					</Typography>
					<Typography className={classes.labelwebsite}>{title}</Typography>
					<Typography className={classes.label_desc}>{description}</Typography>
				</CardContent>
			</CardActionArea>
			<CardActions>
				<Grid container>
					<Grid item md={6} xs={6} justifyContent="flex-end">
						<Typography className={classes.date_posted}>
							{t("campaign-postmedia-category-posted")}: {posteddate}
						</Typography>
					</Grid>
					<Grid item md={6} xs={6}>
						<Grid
							container
							direction="column"
							justifyContent="flex-end"
							alignItems="flex-end"
						>
							<Button
								color="secondary"
								className={classes.btn_more}
								onClick={readmoreClicked}
							>
								{t("campaign-postmedia-category-readmore")}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</CardActions>
		</Card>
	);
};
export default PostMedia;

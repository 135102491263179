import React, { useState, useEffect } from "react";
import BackPaper from "../../../widget/backpaper";
import {
	Grid,
	Typography,
	Box,
	FormGroup,
	Checkbox,
	TextField,
	RadioGroup,
	Radio,
	FormControlLabel,
	FormControl,
	Button,
	Select,
	MenuItem,
	List,
	ListItem,
	// InputLabel,
} from "@material-ui/core";
import useStyles from "../styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { useLocation, useHistory } from "react-router-dom";
import axiosInstance from "../../../service/dashboardAxiosApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BtnOutline, BtnContained } from "../../../widget/util";
import { useForm } from "react-hook-form";
import regions from "../../../../assets/regions.json";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
// import MomentUtils from "@date-io/moment";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import CloudOffTwoToneIcon from "@material-ui/icons/CloudOffTwoTone";
import RestoreIcon from "@material-ui/icons/Restore";
//import Skeleton from "@material-ui/lab/Skeleton";
import { useParams } from "react-router-dom";
import {
	optionsOuiAndNon,
	optionsQ6B,
	optionsQ7,
	optionsQ8A,
	optionsQ10AViolenceSexuelle,
	optionsQ10BViolencePhysique,
	optionsQ10CViolencePsychologique,
	optionsQ10DViolenceEconomique,
	optionsQ11OuiMonth,
	optionsQuestion12B,
	optionsQ13B_APrejudicePhysique,
	optionsQ13B_BPrejudiceEmotionnel,
	optionsQ13B_CPrejudiceEconomique,
	optionsQ13B_DImpactSurLesRelations,
	optionsQ14A_OUI,
	optionsQ14B_OUI,
	optionsQ14C_OUI,
	optionsQ15_OUI,
	optionsQ18A,
	optionsPriseEnChargeQ19Q20,
	optionsQ21,
	optionsQ22,
	optionsQ24,
	optionsQ27,
} from "./utils/utilsObject";
import {
	q2,
	q3,
	q4,
	q5,
	q5sub1,
	q5sub2,
	q5sub3,
	q5sub4,
	q6,
	q7,
	q8,
	q8a,
	q8b,
	q8c,
	q8d,
	q8e,
	q8f,
	q9,
	q10,
	q11,
	q11a,
	q11b,
	q12,
	q12a,
	q12b,
	q13,
	q13a,
	q13b,
	q14,
	q14a,
	q14b,
	q14c,
	q15,
	q16,
	q17,
	q18,
	q18a,
	q18b,
	q18c,
	q19,
	q20,
	q20sub,
	q21sub,
	q22sub,
	q21,
	q22,
	q23,
	q24,
	q24a,
	q25,
	q26,
	q27,
} from "../../../widget/i1e";

// MAIN FUNCTION
const Editcase = () => {
	// UTILITIES STATE VARIABLES
	const classes = useStyles();
	const location = useLocation();
	const history = useHistory();
	const [rowId, setRowId] = useState();
	const [editCase, setEditCase] = useState([]); //All case data in here
	const [spinner, setSpinner] = useState(true);
	const [statusCode, setStatusCode] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [regionMap, setRegionMap] = useState([]);
	const [lat, setLat] = useState(0);
	const [long, setLong] = useState(0);
	const [isUpdate, setIsUpdate] = useState(false);


	// CASE QUESTION STATE VARIABLES
	const [caseID, setCaseID] = useState("");
	const [question2Data, setQuestion2Data] = useState("");
	const [question3Data, setQuestion3Data] = useState([]);
	const [provinceQ3, setProvinceQ3] = useState("");
	const [cityQ3, setCityQ3] = useState("");
	const [question4Data, setQuestion4Data] = React.useState(Date()); //new Date()
	const [question5Data, setQuestion5Data] = useState("");
	const [question6AData, setQuestion6AData] = useState("");
	const [question6BData, setQuestion6BData] = useState("");
	const [question6BPostData, setQuestion6BPostData] = useState("");
	const [question7Data, setQuestion7Data] = useState("");
	const [question7PostData, setQuestion7PostData] = useState("");
	const [question8AData, setQuestion8AData] = useState("");
	const [question8APostData, setQuestion8APostData] = useState("");
	const [question8BData, setQuestion8BData] = useState("");
	const [question8CData, setQuestion8CData] = useState("");
	const [question8DData, setQuestion8DData] = useState("");
	const [question8EData, setQuestion8EData] = useState("");
	const [question8FData, setQuestion8FData] = useState("");
	const [question9Data, setQuestion9Data] = useState("");
	const [question10AData, setQuestion10AData] = useState([]);
	const [question10BData, setQuestion10BData] = useState([]);
	const [question10CData, setQuestion10CData] = useState([]);
	const [question10DData, setQuestion10DData] = useState([]);
	const [question11AData, setQuestion11AData] = useState("");
	const [question11AOuiData, setQuestion11AOuiData] = useState("");
	const [question11AOuiMonthData, setQuestion11AOuiMonthData] = useState("");
	const [question11AOuiMonthPostData, setQuestion11AOuiMonthPostData] =
		useState("");
	const [question11BData, setQuestion11BData] = useState("");
	const [question11BOuiData, setQuestion11BOuiData] = useState("");
	const [question11BOuiMonthData, setQuestion11BOuiMonthData] = useState("");
	const [question11BOuiMonthPostData, setQuestion11BOuiMonthPostData] =
		useState("");
	const [question12APostData, setQuestion12APostData] = useState("");
	const [provinceQ12A, setProvinceQ12A] = useState("");
	const [cityQ12A, setCityQ12A] = useState("");
	const [question12BData, setQuestion12BData] = useState("");
	const [question13AData, setQuestion13AData] = useState("");
	const [question13BAData, setQuestion13BAData] = useState([]);
	const [question13BBData, setQuestion13BBData] = useState([]);
	const [question13BCData, setQuestion13BCData] = useState([]);
	const [question13BDData, setQuestion13BDData] = useState([]);
	const [question14AData, setQuestion14AData] = useState("");
	const [question14AOuiData, setQuestion14AOuiData] = useState([]);
	const [question14BData, setQuestion14BData] = useState("");
	const [question14BOuiData, setQuestion14BOuiData] = useState([]);
	const [question14CData, setQuestion14CData] = useState([]);
	const [question15Data, setQuestion15Data] = useState("");
	const [question15OuiData, setQuestion15OuiData] = useState([]);
	const [question15OuiAutreData, setQuestion15OuiAutreData] = useState("");
	const [question16Data, setQuestion16Data] = useState("");
	const [question16OuiData, setQuestion16OuiData] = useState("");
	const [question17Data, setQuestion17Data] = useState("");
	const [question18Data, setQuestion18Data] = useState("");
	const [question18AData, setQuestion18AData] = useState([]);
	const [question18AAutreData, setQuestion18AAutreData] = useState("");
	const [question18BData, setQuestion18BData] = useState("");
	const [question18CData, setQuestion18CData] = useState("");
	const [question19Data, setQuestion19Data] = useState("");
	const [question19OuiData, setQuestion19OuiData] = useState([]);
	const [question19AutreData, setQuestion19AutreData] = useState("");
	const [question20Data, setQuestion20Data] = useState([]);
	const [question20AutreData, setQuestion20AutreData] = useState("");
	const [question21Data, setQuestion21Data] = useState([]);
	const [question21AutreData, setQuestion21AutreData] = useState("");
	const [question22Data, setQuestion22Data] = useState([]);
	const [question22AutreData, setQuestion22AutreData] = useState("");
	const [question23Data, setQuestion23Data] = useState("");
	const [question24Data, setQuestion24Data] = useState("");
	const [question24AData, setQuestion24AData] = useState("");
	const [question25Data, setQuestion25Data] = useState("");
	const [question26Data, setQuestion26Data] = useState("");
	const [question27Data, setQuestion27Data] = useState("");
	const { id } = useParams();

	// FETCHING CASE DATA
	const getEditCase = async () => {
    
		await axiosInstance
			.get(`case/edit/${id}/`)
			.then((res) => {
				//if (res.status === 200) {

				setStatusCode(true);
				setRegionMap(regions.province);

				var data = res.data.caseItem;
				setEditCase(data);
				setRowId(data.id);

				setCaseID(data.caseid);
				setQuestion2Data(data.question_2);
				setQuestion3Data(data.question_3);
				setQuestion4Data(data.question_4);
				setQuestion5Data(data.question_5);
				setQuestion6AData(data.question_6_a);
				setQuestion6BData(data.question_6_b);
				setQuestion7Data(data.question_7);
				setQuestion8AData(data.question_8_a);
				setQuestion8BData(data.question_8_b);
				setQuestion8CData(data.question_8_c);
				setQuestion8DData(data.question_8_d);
				setQuestion8EData(data.question_8_e);
				setQuestion8FData(data.question_8_f);
				setQuestion9Data(data.question_9);
				setQuestion10AData(
					//filter empty string on array
					data.question_10_a.filter((el) => {
						return el !== "";
					})
				);
				setQuestion10BData(
					//filter empty string on array
					data.question_10_b.filter((el) => {
						return el !== "";
					})
				);
				setQuestion10CData(
					//filter empty string on array
					data.question_10_c.filter((el) => {
						return el !== "";
					})
				);
				setQuestion10DData(
					//filter empty string on array
					data.question_10_d.filter((el) => {
						return el !== "";
					})
				);
				setQuestion11AData(data.question_11_a);
				setQuestion11AOuiData(data.question_11_a_oui);
				setQuestion11AOuiMonthData(data.question_11_a_oui_month);
				setQuestion11AOuiMonthPostData(
					optionsQ11OuiMonth
						.filter((e) => e.month === data.question_11_a_oui_month)
						.map((e) => e.keyData)
				);
				setQuestion11BData(data.question_11_b);
				setQuestion11BOuiData(data.question_11_b_oui);
				setQuestion11BOuiMonthData(data.question_11_b_oui_month);
				setQuestion11BOuiMonthPostData(
					optionsQ11OuiMonth
						.filter((e) => e.month === data.question_11_b_oui_month)
						.map((e) => e.keyData)
				);
				setQuestion12APostData(data.question_12_a);
				setProvinceQ12A(data.province);
				setCityQ12A(data.city);
				setQuestion12BData(data.question_12_b);
				setQuestion13AData(data.question_13_a);
				setQuestion13BAData(
					//filter empty string on array
					data.question_13_b_a.filter((el) => {
						return el !== "";
					})
				);
				setQuestion13BBData(
					//filter empty string on array
					data.question_13_b_b.filter((el) => {
						return el !== "";
					})
				);
				setQuestion13BCData(
					//filter empty string on array
					data.question_13_b_c.filter((el) => {
						return el !== "";
					})
				);
				setQuestion13BDData(
					//filter empty string on array
					data.question_13_b_d.filter((el) => {
						return el !== "";
					})
				);
				setQuestion14AData(data.question_14_a);
				setQuestion14AOuiData(
					//filter empty string on array
					data.question_14_a_oui.filter((el) => {
						return el !== "";
					})
				);
				setQuestion14BData(data.question_14_b);
				setQuestion14BOuiData(
					//filter empty string on array
					data.question_14_b_oui.filter((el) => {
						return el !== "";
					})
				);
				setQuestion14CData(
					//filter empty string on array
					data.question_14_c.filter((el) => {
						return el !== "";
					})
				);
				setQuestion15Data(data.question_15);
				setQuestion15OuiData(
					//filter empty string on array
					data.question_15_oui.filter((el) => {
						return el !== "";
					})
				);
				setQuestion15OuiAutreData(data.question_15_oui_autre);
				setQuestion16Data(data.question_16);
				setQuestion16OuiData(data.question_16_oui);
				setQuestion17Data(data.question_17);
				setQuestion18Data(data.question_18);
				setQuestion18AData(
					//filter empty string on array
					data.question_18_a.filter((el) => {
						return el !== "";
					})
				);
				setQuestion18AAutreData(data.question_18_a_autre);
				setQuestion18BData(data.question_18_b);
				setQuestion18CData(data.question_18_c);
				setQuestion19Data(data.question_19);
				setQuestion19OuiData(
					//filter empty string on array
					data.question_19_oui.filter((el) => {
						return el !== "";
					})
				);
				setQuestion19AutreData(data.question_19_autre);
				setQuestion20Data(
					//filter empty string on array
					data.question_20.filter((el) => {
						return el !== "";
					})
				);
				setQuestion20AutreData(data.question_20_autre);
				setQuestion21Data(
					//filter empty string on array
					data.question_21.filter((el) => {
						return el !== "";
					})
				);
				setQuestion21AutreData(data.question_21_autre);
				setQuestion22Data(
					//filter empty string on array
					data.question_22.filter((el) => {
						return el !== "";
					})
				);
				setQuestion22AutreData(data.question_22_autre);
				setQuestion23Data(data.question_23);
				setQuestion24Data(data.question_24);
				setQuestion24AData(data.question_24_a);
				setQuestion25Data(data.question_25);
				setQuestion26Data(data.question_26);
				setQuestion27Data(data.question_27);

				//Getting the lat and long
				setLat(data.lat);
				setLong(data.lon);
				var question3DataSet = data.question_3.split("/"); // Geting and splitting the city and province from Question 3
				setProvinceQ3(question3DataSet[0].trim()); // Trim helps to clear empty space
				setCityQ3(question3DataSet[1].trim());
				setSpinner(false);
				//}
			})
			.catch((err) => {
				setSpinner(false);
				//setStatusCode(false);

				if (err.request) {
					setErrorMessage(
						"Couldn't reach the server. Please check your internet connexion!"
					);
				} else {
					setErrorMessage("No response! Please try again!");
				}
			});
	};

	// DISPLAYING CASE DATA
	useEffect(() => {
		// setTimeout(() => getEditCase(), 1000);
		getEditCase();
	}, []);

	// ----------------- HANDLERS AND UTILITIES  -----------------

	// GET THE SELECTED PROVINCE FROM THE SELECT FIELD IN QUESTION 3
	const handleQ3ChangeProvince = (e) => {
		const value = e.target.value;
		setCityQ3(""); // Resetting the city
		return setProvinceQ3(value);
	};

	// GET THE SELECTED CITY FROM THE SELECT FIELD IN QUESTION 3
	const handleQ3ChangeCity = (e) => {
		const value = e.target.value;
		setQuestion3Data(`${provinceQ3} / ${value}`); // Set the province and city into "question3Data" to send to the db
		return setCityQ3(value);
	};

	// HANDLE DATE CHANGE FROM QUESTION 4
	const handleQ4DateChange = (date) => {
		var newDate = format(new Date(date), "dd/MM/yyyy");
		//var newDate = format(date, "dd/MM/yyyy");
		return setQuestion4Data(newDate);
	};

	// HANDLE DATA CHANGE FROM QUESTION 6B
	const handleQuestion6BDataChange = (e) => {
		const value = e.target.value;
		setQuestion6BPostData(
			optionsQ6B.filter((e) => e.group === value).map((e) => e.keyData)
		);
		return setQuestion6BData(value);
	};

	// HANDLE DATA CHANGE FROM QUESTION 7
	const handleQuestion7DataChange = (e) => {
		const value = e.target.value;
		setQuestion7PostData(
			optionsQ7.filter((e) => e.genre === value).map((e) => e.keyData)
		);
		return setQuestion7Data(value);
	};

	// HANDLE DATA CHANGE FROM QUESTION 8A
	const handleQuestion8ADataChange = (e) => {
		const value = e.target.value;
		setQuestion8APostData(
			optionsQ8A.filter((e) => e.etatCivil === value).map((e) => e.keyData)
		);
		return setQuestion8AData(value);
	};

	// HANDLE CLICK EVENT CHANGE FROM QUESTION 8E
	const handleQuestion8EDataChange = (e) => {
		const value = e.target.value;
		value === "A" ? setQuestion8FData("B") : setQuestion8EData(value);

		setQuestion8EData(value);
	};

	// HANDLE CLICK EVENT CHANGE FROM QUESTION 8F
	const handleQuestion8FDataChange = (e) => {
		const value = e.target.value;
		value === "A" ? setQuestion8EData("B") : setQuestion8FData(value);
		setQuestion8FData(value);
	};

	// HANDLE DATA CHANGE FROM QUESTION 11A
	const handleQuestion11ADataChange = (e) => {
		const value = e.target.value;
		if (value === "B") {
			setQuestion11AOuiData("");
			setQuestion11AOuiMonthPostData("");
		} else {
			setQuestion11AData(value);
		}

		return setQuestion11AData(value);
	};

	// HANDLE DATA CHANGE FROM QUESTION 11A OUI MONTH
	const handleQuestion11AOuiMonthDataChange = (e) => {
		const value = e.target.value;
		setQuestion11AOuiMonthPostData(
			optionsQ11OuiMonth.filter((e) => e.month === value).map((e) => e.keyData)
		);
		return setQuestion11AOuiMonthData(value);
	};

	// HANDLE DATA CHANGE FROM QUESTION 11B
	const handleQuestion11BDataChange = (e) => {
		const value = e.target.value;
		if (value === "B") {
			setQuestion11BOuiData("");
			setQuestion11BOuiMonthPostData("");
		} else {
			setQuestion11BData(value);
		}

		return setQuestion11BData(value);
	};

	// HANDLE DATA CHANGE FROM QUESTION 11B OUI MONTH
	const handleQuestion11BOuiMonthDataChange = (e) => {
		const value = e.target.value;
		setQuestion11BOuiMonthPostData(
			optionsQ11OuiMonth.filter((e) => e.month === value).map((e) => e.keyData)
		);
		return setQuestion11BOuiMonthData(value);
	};

	// GET THE SELECTED PROVINCE FROM THE SELECT FIELD IN QUESTION 12A
	const handleQ12AChangeProvince = (e) => {
		const value = e.target.value;
		setCityQ12A(""); // Resetting the city
		return setProvinceQ12A(value);
	};

	// GET THE SELECTED CITY FROM THE SELECT FIELD IN QUESTION 12A
	const handleQ12AChangeCity = (e) => {
		const value = e.target.value;
		setQuestion12APostData(`${provinceQ12A} / ${value}`); // Set the province and city into "question12PostData" to send to the db
		//Update the lat and long based on the city
		const lon = regionMap.map((e) =>
			e.cities.filter((e) => e.name === value).map((e) => e.coordinates[0])
		);
		const lat = regionMap.map((e) =>
			e.cities.filter((e) => e.name === value).map((e) => e.coordinates[1])
		);

		// SETTING THE LATITUDE AND THE LONGITUTE TO THE STATE
		setLong(lon.map((e) => e[0]).filter((e) => e !== undefined));

		setLat(lat.map((e) => e[0]).filter((e) => e !== undefined));
		return setCityQ12A(value);
	};

	// HANDLE DATA CHANGE FROM QUESTION 12B
	const handleQuestion12BDataChange = (e) => {
		const value = e.target.value;
		return setQuestion12BData(value);
	};

	// HANDLE DATA CHANGE FROM QUESTION 14A
	const handleQuestion14ADataChange = (e) => {
		const value = e.target.value;
		if (value === "B") {
			setQuestion14AOuiData([]);
		} else {
			setQuestion14AData(value);
		}

		return setQuestion14AData(value);
	};

	// HANDLE DATA CHANGE FROM QUESTION 14A
	const handleQuestion14BDataChange = (e) => {
		const value = e.target.value;
		if (value === "B") {
			setQuestion14BOuiData([]);
		} else {
			setQuestion14BData(value);
		}

		return setQuestion14BData(value);
	};

	// HANDLE DATA CHANGE FROM QUESTION 14A
	const handleQuestion15DataChange = (e) => {
		const value = e.target.value;
		if (value === "B") {
			setQuestion15OuiData([]);
		} else {
			setQuestion15Data(value);
		}

		return setQuestion15Data(value);
	};

	// HANDLE DATA CHANGE FROM QUESTION 16
	const handleQuestion16DataChange = (e) => {
		const value = e.target.value;
		if (value === "B") {
			setQuestion16OuiData("");
		} else {
			setQuestion16Data(value);
		}

		return setQuestion16Data(value);
	};

	// HANDLE DATA CHANGE FROM QUESTION 24
	const handleQuestion24DataChange = (e) => {
		const value = e.target.value;
		if (value === "A") {
			setQuestion24AData("");
		} else {
			setQuestion24Data(value);
		}

		return setQuestion24Data(value);
	};

	// HANDLE DATA CHANGE FROM QUESTION 25
	const handleQuestion25DataChange = (e) => {
		const value = e.target.value;
		if (value === "B") {
			setQuestion26Data("");
			setQuestion27Data("");
		} else {
			setQuestion25Data(value);
		}

		return setQuestion25Data(value);
	};

	// HANDLE FORM SUBMIT

	const { handleSubmit } = useForm({
		//mode: "onBlur",
		mode: "onSubmit",
	});

	// FUNCTION TO SUBMIT UPDATED DATA
	const onSubmit = async (data, e) => {
		e.preventDefault();
		// console.log(JSON.stringify(data));
		//alert("SUCCESS!! :-)\n\n" + JSON.stringify(data));
		// await axiosInstance.patch(`case/${editCase.id}/`, data);
		setIsUpdate(true);

		await axiosInstance
			.patch(`case/${id}/`, {
				caseid: caseID,
				// question_1: editCase.id,
				question_2: question2Data,
				question_3: question3Data,
				question_4: question4Data,
				question_5: question5Data,
				question_6_a: question6AData,
				question_6_b: question6BPostData[0],
				question_7: question7PostData[0],
				question_8_a: question8APostData[0],
				question_8_b: question8BData,
				question_8_c: question8CData,
				question_8_d: question8DData,
				question_8_e: question8EData,
				question_8_f: question8FData,
				question_9: question9Data,
				question_10_a: question10AData.sort(),
				question_10_b: question10BData.sort(),
				question_10_c: question10CData.sort(),
				question_10_d: question10DData.sort(),
				question_11_a: question11AData,
				question_11_a_oui: question11AOuiData,
				question_11_a_oui_month: question11AOuiMonthPostData.toString(),
				question_11_b: question11BData,
				question_11_b_oui: question11BOuiData,
				question_11_b_oui_month: question11BOuiMonthPostData.toString(),
				question_12_a: question12APostData,
				question_12_b: question12BData,
				question_13_a: question13AData,
				question_13_b_a: question13BAData.sort(),
				question_13_b_b: question13BBData.sort(),
				question_13_b_c: question13BCData.sort(),
				question_13_b_d: question13BDData.sort(),
				question_14_a: question14AData,
				question_14_a_oui: question14AOuiData.sort(),
				question_14_b: question14BData,
				question_14_b_oui: question14BOuiData.sort(),
				question_14_c: question14CData,
				question_15: question15Data,
				question_15_oui: question15OuiData.sort(),
				question_15_oui_autre: question15OuiAutreData,
				question_16: question16Data,
				question_16_oui: question16OuiData,
				question_17: question17Data,
				question_18: question18Data,
				question_18_a: question18AData.sort(),
				question_18_a_autre: question18AAutreData,
				question_18_b: question18BData,
				question_18_c: question18CData,
				question_19: question19Data,
				question_19_oui: question19OuiData.sort(),
				question_19_autre: question19AutreData,
				question_20: question20Data.sort(),
				question_20_autre: question20AutreData,
				question_21: question21Data.sort(),
				question_21_autre: question21AutreData,
				question_22: question22Data.sort(),
				question_22_autre: question22AutreData,
				question_23: question23Data,
				question_24: question24Data,
				question_24_a: question24AData,
				question_25: question25Data,
				question_26: question26Data,
				question_27: question27Data,

				// Sending the lat and long based on the city
				lat: lat[0],
				lon: long[0],
			})
			.then((res) => {
				setIsUpdate(false);
				// sessionStorage.setItem("rowId", rowId);
				history.push({
					pathname: `/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/case/detail/${id}`,
				});
			})
			.catch((err) => {
				setIsUpdate(false);
				if (err.response) {
					// console.log(err.response);
					setErrorMessage("Please your request  could not reach  server");
					// } else if (err.request) {
					// 	setErrorMessage("Please check your internet connexion");
					// console.log(err.request);
				} else {
					setErrorMessage("No response from server, try again later");
				}
			});
	};

	// BACK BUTTON
	const backBtn = () => {
		history.push({
			pathname: `/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/case/detail/${id}`,
			row: rowId,
		});
	};

	// A FUNCTION TO DISPLAY ALL CASE DATA
	const displayData = () => {

		if (spinner === true) {
      
			return (
				<Grid
					container
					spacing={4}
					justifyContent="center"
					alignItems="center"
					direction="column"
					style={{ height: 600 }}
				>
					<Grid item>
						<CircularProgress color="secondary" size={60} />
					</Grid>
					<Grid item>
						<Typography className={classes.detailTypo}>Loading....</Typography>
					</Grid>
				</Grid>
			);
		} else if (statusCode === true) {
			return (
				<div>
					<Grid container direction="row" spacing={4}>
						<Grid item xs={1} sm={1}></Grid>
						<Grid item sm={8} xs={11} className={classes.margin_Top_30}>
							<form onSubmit={handleSubmit(onSubmit)}>
								{/* CASE ID FIELD */}
								<Grid container spacing={4}>
									<Grid item xs={12} sm={12}>
										<Grid container>
											<Grid item xs={12} sm={12}>
												<Typography className={classes.label}>
													Case ID
												</Typography>
											</Grid>
										</Grid>
										<Grid container spacing={4}>
											<Grid item xs={12} sm={6}>
												<TextField
													required
													name="caseid"
													value={caseID}
													onChange={(e) => setCaseID(e.target.value)}
													// inputRef={register}
													variant="outlined"
													size="small"
													fullWidth
												/>
											</Grid>
											<Grid item sm={6}></Grid>
										</Grid>

										<Grid container spacing={4}>
											<Grid item xs={12} sm={12}>
												<Typography className={classes.label}>{q2}</Typography>
											</Grid>
										</Grid>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={6}>
												<TextField
													required
													name="question_2"
													value={question2Data}
													onChange={(e) => setQuestion2Data(e.target.value)}
													// inputRef={register}
													variant="outlined"
													size="small"
													fullWidth
												/>
											</Grid>
											<Grid item sm={6}></Grid>
										</Grid>
									</Grid>
								</Grid>

								{/* QUESTION 3 */}
								<Grid
									container
									spacing={4}
									direction="row"
									className={classes.marginBottom_30}
								>
									<Grid item xs={12} sm={12}>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={12}>
												<Grid container>
													<Grid item xs={12} sm={12}>
														<Typography className={classes.label}>
															{q3}
														</Typography>
													</Grid>
												</Grid>
												<Grid container spacing={2}>
													<Grid item xs={12} sm={6}>
														<Typography className={classes.sublabel}>
															Province*
														</Typography>
														<Select
															required
															variant="outlined"
															size="small"
															fullWidth
															displayEmpty
															defaultValue={provinceQ3}
															value={provinceQ3}
															style={{ height: 40 }}
															onChange={handleQ3ChangeProvince}
															//inputRef={register}
														>
															{cityQ3 !== null
																? regionMap.map((e) => (
																		<MenuItem
																			key={e.id}
																			className={classes.detailTypo}
																			value={e.name}
																		>
																			{e.name}
																		</MenuItem>
																  ))
																: null}
														</Select>
													</Grid>

													<Grid item sm={6} xs={12}>
														<Typography className={classes.sublabel}>
															Ville/Territoire*
														</Typography>
														<Select
															required
															displayEmpty
															variant="outlined"
															size="small"
															fullWidth
															style={{ height: 40 }}
															defaultValue={cityQ3}
															value={cityQ3}
															onChange={handleQ3ChangeCity}
															// inputRef={register}
														>
															{regionMap
																.filter((e) => e.name === provinceQ3)
																.map((e) =>
																	e.cities.map((e) => (
																		<MenuItem
																			key={e.id}
																			className={classes.detailTypo}
																			value={e.name}
																		>
																			{e.name}
																		</MenuItem>
																	))
																)}
														</Select>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>

								{/* QUESTION 4 */}
								<Grid
									container
									spacing={4}
									direction="row"
									className={classes.bottom_margin}
								>
									<Grid item xs={12} sm={12}>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={12}>
												<Grid container>
													<Grid item xs={12} sm={12}>
														<Typography className={classes.label}>
															{q4}
														</Typography>
													</Grid>
												</Grid>
												<Grid container spacing={3}>
													<Grid item xs={12} sm={6}>
														<Typography style={{ marginBottom: "0px" }}>
															{question4Data}
														</Typography>
														<MuiPickersUtilsProvider utils={DateFnsUtils}>
															{/* <MuiPickersUtilsProvider utils={MomentUtils}> */}
															<div>
																<KeyboardDatePicker
																	helperText={""}
																	error={false}
																	name={"question_4"}
																	id="date-picker-dialog"
																	value={question4Data}
																	onChange={handleQ4DateChange}
																	showTodayButton={true}
																	size="small"
																	KeyboardButtonProps={{
																		"aria-label": "change date",
																	}}
																	format={"MM/dd/yyyy"}
																	//clearable
																	//defaultValue="Click to change date"
																	//views={["date","month", "year"]}
																	// required
																	//fullWidth
																	//autoOk={true}
																	//disableToolbar
																	//inputVariant="outlined"
																	//format="dd/MM/yyyy"
																	//mask={[/d/, /d/, "/", /d/, /d/, "/", /d/, /d/, /d/, /d/]}
																	//margin="small"
																	//label={question4Data}
																	//rifmFormatter={dateFormatter}
																/>
															</div>
														</MuiPickersUtilsProvider>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>

								{/* QUESTION 5 */}
								<Grid
									container
									spacing={4}
									direction="row"
									className={classes.bottom_margin}
								>
									<Grid item xs={11} sm={11}>
										<Grid container direction="row">
											<Grid item xs={12} sm={12}>
												<Typography className={classes.label}>{q5}</Typography>
												<Typography className={classes.sublabel}>
													<small>{q5sub1}</small>
												</Typography>
												<Typography className={classes.sublabel}>
													<small>{q5sub2}</small>
												</Typography>
												<Typography className={classes.sublabel}>
													<small>{q5sub3}</small>
												</Typography>
												<Typography className={classes.sublabel}>
													<small>{q5sub4}</small>
												</Typography>
											</Grid>

											<Grid item xs={12} sm={12}>
												<TextField
													required
													type="text"
													variant="outlined"
													size="small"
													name="question_4"
													value={question5Data}
													onChange={(e) => setQuestion5Data(e.target.value)}
													// inputRef={register}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>

								{/* QUESTION 6 */}
								<Grid container direction="row" spacing={1}>
									<Grid item xs={12} sm={6}>
										<Grid item xs={12} sm={12}>
											<Typography className={classes.label}>{q6}</Typography>
										</Grid>
										<Grid
											container
											justifyContent="space-between"
											spacing={2}
											className={classes.bottom_margin}
										>
											{/* QUESTION 6A */}
											<Grid item sm={6} xs={12}>
												<Typography className={classes.sublabel}>
													Age précis*
												</Typography>
												<TextField
													required
													variant="outlined"
													size="small"
													fullWidth
													type="number"
													name="question_6_a"
													value={question6AData}
													onChange={(e) => setQuestion6AData(e.target.value)}
													// inputRef={register}
												/>
											</Grid>
											{/* QUESTION 6 B */}
											<Grid item sm={6} xs={12}>
												<Typography className={classes.sublabel}>
													Groupe d’âge*
												</Typography>

												<Select
													required
													variant="outlined"
													size="small"
													fullWidth
													style={{ height: 40 }}
													name="question_6_b"
													value={question6BData}
													defaultValue={question6BData}
													onChange={handleQuestion6BDataChange}
												>
													{optionsQ6B.map((e) => (
														<MenuItem
															className={classes.detailTypo}
															key={e.keyData}
															value={e.group}
														>
															{e.group}
														</MenuItem>
													))}
												</Select>
											</Grid>
										</Grid>
									</Grid>

									{/* QUESTION 7 */}
									<Grid item sm={6} xs={12}>
										<Grid item xs={12} sm={12}>
											<Typography className={classes.label}>{q7}</Typography>
										</Grid>
										<Typography className={classes.sublabel}>Genre*</Typography>

										<Select
											required
											variant="outlined"
											size="small"
											fullWidth
											style={{ height: 40 }}
											name="question_7"
											value={question7Data}
											defaultValue={question7Data}
											onChange={handleQuestion7DataChange}
										>
											{optionsQ7.map((e) => (
												<MenuItem
													className={classes.detailTypo}
													key={e.keyData}
													value={e.genre}
												>
													{e.genre}
												</MenuItem>
											))}
										</Select>
									</Grid>
								</Grid>

								{/* QUESTION 8 */}
								<Grid container spacing={1}>
									<Grid item xs={12} sm={12}>
										<Grid container spacing={1} direction="row">
											<Grid item xs={12} sm={12}>
												<Grid container spacing={1}>
													<Grid item xs={12} sm={12}>
														<Grid container>
															<Grid item xs={12} sm={12}>
																<Typography className={classes.label}>
																	{q8}
																</Typography>
															</Grid>
														</Grid>

														{/* QUESTION 8A */}
														<Grid container spacing={2}>
															<Grid
																item
																xs={12}
																sm={6}
																className={classes.marginBottom_30}
															>
																<Grid item xs={12} sm={12}>
																	<Typography className={classes.sublabel}>
																		{q8a}
																	</Typography>
																</Grid>
																<Select
																	required
																	variant="outlined"
																	size="small"
																	fullWidth
																	style={{ height: 40 }}
																	name="question_8A"
																	value={question8AData}
																	defaultValue={question8AData}
																	onChange={handleQuestion8ADataChange}
																>
																	{optionsQ8A.map((e) => (
																		<MenuItem
																			className={classes.detailTypo}
																			key={e.keyData}
																			value={e.etatCivil}
																		>
																			{e.etatCivil}
																		</MenuItem>
																	))}
																</Select>
															</Grid>

															{/* QUESTION 8B */}
															<Grid item sm={6}>
																<Grid item xs={12} sm={12}>
																	<Typography className={classes.sublabel}>
																		{q8b}
																	</Typography>
																</Grid>
																<FormControl component="fieldset">
																	<RadioGroup
																		name="question_8B"
																		className={classes.radiolabel}
																	>
																		{optionsOuiAndNon.map((e) => (
																			<FormControlLabel
																				key={e.keyData}
																				checked={
																					question8BData
																						? question8BData === e.keyData
																						: false
																				}
																				value={e.keyData}
																				onClick={(e) =>
																					setQuestion8BData(e.target.value)
																				}
																				control={<Radio size="small" />}
																				label={
																					<Box
																						className={classes.detailTypo}
																						component="div"
																						fontSize={13}
																					>
																						{e.answer}
																					</Box>
																				}
																			/>
																		))}
																	</RadioGroup>
																</FormControl>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>

											{/* QUESTION 8C */}
											<Grid item xs={12} sm={12}>
												<Grid container spacing={1}>
													<Grid item xs={12} sm={12}>
														<Grid
															container
															spacing={2}
															style={{ marginTop: 20 }}
														>
															<Grid item sm={6}>
																<Grid item xs={12} sm={12}>
																	<Typography className={classes.sublabel}>
																		{q8c}
																	</Typography>
																</Grid>
																<FormControl component="fieldset">
																	<RadioGroup
																		name="question_8C"
																		className={classes.radiolabel}
																	>
																		{optionsOuiAndNon.map((e) => (
																			<FormControlLabel
																				key={e.keyData}
																				checked={
																					question8CData
																						? question8CData === e.keyData
																						: false
																				}
																				value={e.keyData}
																				onClick={(e) =>
																					setQuestion8CData(e.target.value)
																				}
																				control={<Radio size="small" />}
																				label={
																					<Box
																						className={classes.detailTypo}
																						component="div"
																						fontSize={13}
																					>
																						{e.answer}
																					</Box>
																				}
																			/>
																		))}
																	</RadioGroup>
																</FormControl>
															</Grid>

															{/* QUESTION 8D */}
															<Grid item sm={6}>
																<Grid item xs={12} sm={12}>
																	<Typography className={classes.sublabel}>
																		{q8d}
																	</Typography>
																</Grid>
																<FormControl component="fieldset">
																	<RadioGroup
																		name="question_8D"
																		className={classes.radiolabel}
																	>
																		{optionsOuiAndNon.map((e) => (
																			<FormControlLabel
																				key={e.keyData}
																				checked={
																					question8DData
																						? question8DData === e.keyData
																						: false
																				}
																				value={e.keyData}
																				onClick={(e) =>
																					setQuestion8DData(e.target.value)
																				}
																				control={<Radio size="small" />}
																				label={
																					<Box
																						className={classes.detailTypo}
																						component="div"
																						fontSize={13}
																					>
																						{e.answer}
																					</Box>
																				}
																			/>
																		))}
																	</RadioGroup>
																</FormControl>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>

											{/* QUESTION 8E */}
											<Grid
												item
												xs={12}
												sm={12}
												className={classes.bottom_margin}
											>
												<Grid container spacing={1} style={{ marginTop: 20 }}>
													<Grid item xs={12} sm={12}>
														<Grid container spacing={2}>
															<Grid item xs={12} sm={6}>
																<Grid item xs={12} sm={12}>
																	<Typography className={classes.sublabel}>
																		{q8e}
																	</Typography>
																</Grid>
																<FormControl component="fieldset">
																	<RadioGroup
																		name="question_8E"
																		className={classes.radiolabel}
																		required
																	>
																		<FormControlLabel
																			checked={
																				question8EData === "A" ? true : false
																			}
																			value="A"
																			onClick={handleQuestion8EDataChange}
																			disabled={
																				question8FData === "A" ? true : false
																			}
																			control={
																				<Radio size="small" required={true} />
																			}
																			label={
																				<Box
																					className={classes.detailTypo}
																					component="div"
																					fontSize={13}
																				>
																					Oui
																				</Box>
																			}
																		/>
																		<FormControlLabel
																			checked={
																				question8EData === "B" ? true : false
																			}
																			value="B"
																			onClick={handleQuestion8EDataChange}
																			control={
																				<Radio size="small" required={true} />
																			}
																			label={
																				<Box
																					className={classes.detailTypo}
																					component="div"
																					fontSize={13}
																				>
																					Non
																				</Box>
																			}
																		/>
																	</RadioGroup>
																</FormControl>
															</Grid>

															{/* QUESTION 8F */}
															<Grid item sm={6}>
																<Grid item xs={12} sm={12}>
																	<Typography className={classes.sublabel}>
																		{q8f}
																	</Typography>
																</Grid>
																<FormControl component="fieldset">
																	<RadioGroup
																		name="question_8F"
																		className={classes.radiolabel}
																		required
																	>
																		<FormControlLabel
																			checked={
																				question8FData === "A" ? true : false
																			}
																			value="A"
																			onClick={handleQuestion8FDataChange}
																			disabled={
																				question8EData === "A" ? true : false
																			}
																			control={
																				<Radio size="small" required={true} />
																			}
																			label={
																				<Box
																					className={classes.detailTypo}
																					component="div"
																					fontSize={13}
																				>
																					Oui
																				</Box>
																			}
																		/>
																		<FormControlLabel
																			checked={
																				question8FData === "B" ? true : false
																			}
																			value="B"
																			onClick={handleQuestion8FDataChange}
																			control={
																				<Radio size="small" required={true} />
																			}
																			label={
																				<Box
																					className={classes.detailTypo}
																					component="div"
																					fontSize={13}
																				>
																					Non
																				</Box>
																			}
																		/>
																	</RadioGroup>
																</FormControl>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>

										{/* QUESTION 9 */}
										<Grid
											container
											spacing={4}
											direction="row"
											className={classes.bottom_margin}
										>
											<Grid item xs={11} sm={12}>
												<Typography className={classes.label}>{q9}</Typography>
												<TextField
													required
													placeholder="Il s’agit d’une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé. Veuillez fournir ici un bref aperçu du cas en français."
													multiline
													className={classes.textarea}
													rows={6}
													name="question_9"
													value={question9Data}
													onChange={(e) => setQuestion9Data(e.target.value)}
													// inputRef={register}
													variant="outlined"
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>

								{/* QUESTION 10 */}
								<Grid
									container
									spacing={1}
									direction="row"
									className={classes.marginBottom_30}
								>
									<Grid item xs={12} sm={12}>
										<Typography className={classes.label}>
											{q10}{" "}
											<small style={{ fontWeight: 200 }}>
												(choix multiple est possible)
											</small>
										</Typography>

										<Grid container>
											{/* QUESTION 10A */}
											<Grid item xs={12} sm={12}>
												<Button
													// onClick={}
													variant="text"
													color="default"
													className={classes.button}
													startIcon={
														<ArrowDropDownIcon style={{ fontSize: 30 }} />
													}
												>
													Incidents de violence sexuelle{" "}
													{/* {console.log(question10AData)} */}
												</Button>
												{question10AData ? (
													<List>
														<ListItem>
															<FormGroup>
																{optionsQ10AViolenceSexuelle.map((e) => (
																	<FormControlLabel
																		key={e.keyData}
																		control={
																			<Checkbox
																				name="question_10_a"
																				checked={question10AData
																					.filter(Boolean)
																					.includes(e.keyData)}
																				value={e.keyData}
																				onChange={(e) => {
																					if (e.target.checked) {
																						setQuestion10AData([
																							...question10AData.sort(),
																							e.target.value,
																						]);
																					} else {
																						setQuestion10AData(
																							question10AData
																								.filter(
																									(el) => el !== e.target.value
																								)
																								.sort()
																						);
																					}
																				}}
																			/>
																		}
																		label={
																			<span className={classes.detailTypo}>
																				{e.incident}
																			</span>
																		}
																	/>
																))}
															</FormGroup>
														</ListItem>
													</List>
												) : null}
											</Grid>

											{/* QUESTION 10B */}
											<Grid item xs={12} sm={12}>
												<Button
													// onClick={}
													variant="text"
													color="default"
													className={classes.button}
													startIcon={
														<ArrowDropDownIcon style={{ fontSize: 30 }} />
													}
												>
													Incidents de violence physique (autre que violence
													sexuelle)
													{/* {console.log(question10BData)} */}
												</Button>
												{question10BData ? (
													<List>
														<ListItem>
															<FormGroup>
																{optionsQ10BViolencePhysique.map((e) => (
																	<FormControlLabel
																		key={e.keyData}
																		control={
																			<Checkbox
																				name="question_10_b"
																				checked={question10BData
																					.filter(Boolean)
																					.includes(e.keyData)}
																				value={e.keyData}
																				onChange={(e) => {
																					if (e.target.checked) {
																						setQuestion10BData([
																							...question10BData.sort(),
																							e.target.value,
																						]);
																					} else {
																						setQuestion10BData(
																							question10BData
																								.filter(
																									(el) => el !== e.target.value
																								)
																								.sort()
																						);
																					}
																				}}
																			/>
																		}
																		label={
																			<span className={classes.detailTypo}>
																				{e.incident}
																			</span>
																		}
																	/>
																))}
															</FormGroup>
														</ListItem>
													</List>
												) : null}
											</Grid>

											{/* QUESTION 10C */}
											<Grid item xs={12} sm={12}>
												<Button
													// onClick={}
													variant="text"
													color="default"
													className={classes.button}
													startIcon={
														<ArrowDropDownIcon style={{ fontSize: 30 }} />
													}
												>
													Incidents de violence psychologique
													{/* {console.log(question10CData)} */}
												</Button>
												{question10CData ? (
													<List>
														<ListItem>
															<FormGroup>
																{optionsQ10CViolencePsychologique.map((e) => (
																	<FormControlLabel
																		key={e.keyData}
																		control={
																			<Checkbox
																				name="question_10_c"
																				checked={question10CData
																					.filter(Boolean)
																					.includes(e.keyData)}
																				value={e.keyData}
																				onChange={(e) => {
																					if (e.target.checked) {
																						setQuestion10CData([
																							...question10CData.sort(),
																							e.target.value,
																						]);
																					} else {
																						setQuestion10CData(
																							question10CData
																								.filter(
																									(el) => el !== e.target.value
																								)
																								.sort()
																						);
																					}
																				}}
																			/>
																		}
																		label={
																			<span className={classes.detailTypo}>
																				{e.incident}
																			</span>
																		}
																	/>
																))}
															</FormGroup>
														</ListItem>
													</List>
												) : null}
											</Grid>

											{/* QUESTION 10D */}
											<Grid item xs={12} sm={12}>
												<Button
													variant="text"
													color="default"
													className={classes.button}
													startIcon={
														<ArrowDropDownIcon style={{ fontSize: 30 }} />
													}
												>
													Incidents de violence économique et de déni
													d’opportunités
													{/* {console.log(question10DData)} */}
												</Button>
												{question10DData ? (
													<List>
														<ListItem>
															<FormGroup>
																{optionsQ10DViolenceEconomique.map((e) => (
																	<FormControlLabel
																		key={e.keyData}
																		control={
																			<Checkbox
																				name="question_10_d"
																				checked={question10DData
																					.filter(Boolean)
																					.includes(e.keyData)}
																				value={e.keyData}
																				onChange={(e) => {
																					if (e.target.checked) {
																						setQuestion10DData([
																							...question10DData.sort(),
																							e.target.value,
																						]);
																					} else {
																						setQuestion10DData(
																							question10DData
																								.filter(
																									(el) => el !== e.target.value
																								)
																								.sort()
																						);
																					}
																				}}
																			/>
																		}
																		label={
																			<span className={classes.detailTypo}>
																				{e.incident}
																			</span>
																		}
																	/>
																))}
															</FormGroup>
														</ListItem>
													</List>
												) : null}
											</Grid>
										</Grid>
									</Grid>
								</Grid>

								{/* QUESTION 11 A */}
								<Grid
									container
									spacing={1}
									direction="row"
									className={classes.marginBottom_30}
								>
									<Grid item xs={12} sm={12}>
										<Typography className={classes.label}>{q11}</Typography>
									</Grid>
									<Grid item xs={12} sm={6} className={classes.marginBottom_30}>
										<Typography className={classes.sublabel}>{q11a}</Typography>
										<FormControl component="fieldset">
											<RadioGroup
												name="question_11_a"
												className={classes.radiolabel}
											>
												{optionsOuiAndNon.map((e) => (
													<FormControlLabel
														key={e.keyData}
														checked={
															question11AData
																? question11AData === e.keyData
																: false
														}
														value={e.keyData}
														onClick={handleQuestion11ADataChange}
														control={<Radio size="small" />}
														label={
															<Box
																className={classes.detailTypo}
																component="div"
																fontSize={13}
															>
																{e.answer}
															</Box>
														}
													/>
												))}
												{/* QUESTION 11A OUI MONTH */}

												{question11AData === "A" ? (
													<div>
														<Typography className={classes.subradio}>
															Si oui : Quand cette situation a-t-elle commencé ?
														</Typography>
														<small
															style={{ fontWeight: 100, marginTop: "20px" }}
														>
															Choisissez le mois*
														</small>
														<Select
															variant="outlined"
															size="small"
															fullWidth
															style={{ height: 40 }}
															name="question_11_a_oui_month"
															value={question11AOuiMonthData}
															defaultValue={question11AOuiMonthData}
															onChange={handleQuestion11AOuiMonthDataChange}
														>
															{optionsQ11OuiMonth.map((e) => (
																<MenuItem
																	className={classes.detailTypo}
																	key={e.keyData}
																	value={e.month}
																>
																	{e.month}
																</MenuItem>
															))}
														</Select>

														{/* QUESTION 11A OUI */}
														<TextField
															name="question_11_a_oui"
															label="Entrez l' année"
															variant="outlined"
															value={question11AOuiData}
															onChange={(e) =>
																setQuestion11AOuiData(e.target.value)
															}
															size="small"
															// inputRef={register}
															fullWidth
															style={{
																marginTop: "20px",
																marginBottom: "30px",
															}}
														/>
													</div>
												) : null}
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>

								{/* QUESTION 11 B */}
								<Grid
									container
									spacing={1}
									direction="row"
									className={classes.marginBottom_30}
								>
									<Grid item xs={12} sm={6} className={classes.marginBottom_30}>
										<Typography className={classes.sublabel}>{q11b}</Typography>
										<FormControl component="fieldset">
											<RadioGroup
												name="question_11_b"
												className={classes.radiolabel}
											>
												{optionsOuiAndNon.map((e) => (
													<FormControlLabel
														key={e.keyData}
														checked={
															question11BData
																? question11BData === e.keyData
																: false
														}
														value={e.keyData}
														onChange={handleQuestion11BDataChange}
														control={<Radio size="small" />}
														label={
															<Box
																className={classes.detailTypo}
																component="div"
																fontSize={13}
															>
																{e.answer}
															</Box>
														}
													/>
												))}
												{/* QUESTION 11B OUI MNTH */}

												{question11BData === "A" ? (
													<div>
														<Typography className={classes.subradio}>
															Si oui : quand l'incident a-t-il eu lieu ?
														</Typography>
														<small
															style={{ fontWeight: 100, marginTop: "20px" }}
														>
															Choisissez le mois*
														</small>
														<Select
															variant="outlined"
															size="small"
															fullWidth
															style={{ height: 40 }}
															name="question_11_b_oui_month"
															value={question11BOuiMonthData}
															defaultValue={question11BOuiMonthData}
															onChange={handleQuestion11BOuiMonthDataChange}
														>
															{optionsQ11OuiMonth.map((e) => (
																<MenuItem
																	className={classes.detailTypo}
																	key={e.keyData}
																	value={e.month}
																>
																	{e.month}
																</MenuItem>
															))}
														</Select>

														{/* QUESTION 11B OUI */}
														<TextField
															// sx={{ marginTop: 50}}
															name="question_11_b_oui"
															label="Entrez l' année"
															variant="outlined"
															value={question11BOuiData}
															onChange={(e) =>
																setQuestion11BOuiData(e.target.value)
															}
															size="small"
															// inputRef={register}
															fullWidth
															style={{ marginTop: "20px" }}
														/>
													</div>
												) : null}
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>
								{/* QUESTION 12 */}
								<Grid
									container
									spacing={1}
									direction="row"
									className={classes.spacedown}
									style={{ marginTop: 20 }}
								>
									<Grid item xs={12} sm={12}>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={12}>
												<Grid container>
													<Grid item xs={12} sm={12}>
														<Typography className={classes.label}>
															{q12}
														</Typography>
														{/* <Typography className={classes.sublabel}>
														{provinceQ12A} / <br /> {cityQ12A} / <br />{lat} / <br />{long}
													</Typography>
													<br /> */}
													</Grid>
												</Grid>
												<Grid container spacing={1}>
													<Typography className={classes.sublabel}>
														{q12a}
													</Typography>
													<Grid item xs={12} sm={6}>
														<Typography className={classes.sublabel}>
															Province*
														</Typography>

														<Select
															required
															variant="outlined"
															size="small"
															placeholder="Province"
															fullWidth
															displayEmpty
															defaultValue={provinceQ12A}
															value={provinceQ12A}
															style={{ height: 40 }}
															onChange={handleQ12AChangeProvince}
														>
															{cityQ12A !== null
																? regionMap.map((e) => (
																		<MenuItem
																			key={e.id}
																			className={classes.detailTypo}
																			value={e.name}
																		>
																			{e.name}{" "}
																		</MenuItem>
																  ))
																: null}
														</Select>
													</Grid>

													<Grid item sm={6} xs={12}>
														<Typography className={classes.sublabel}>
															Ville/Territoire*
														</Typography>
														<Select
															required
															variant="outlined"
															size="small"
															fullWidth
															displayEmpty
															defaultValue={cityQ12A}
															value={cityQ12A}
															style={{ height: 40 }}
															onChange={handleQ12AChangeCity}
														>
															{regionMap
																.filter((e) => e.name === provinceQ12A)
																.map((e) =>
																	e.cities.map((e) => (
																		<MenuItem
																			key={e.id}
																			className={classes.detailTypo}
																			value={e.name}
																		>
																			{e.name}
																		</MenuItem>
																	))
																)}
														</Select>
													</Grid>

													<Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
														<Typography className={classes.sublabel}>
															{q12b}
														</Typography>
														<FormControl
															component="fieldset"
															className={classes.marginBottom_30}
														>
															<RadioGroup
																name="question_12_b"
																className={classes.radiolabel}
															>
																{optionsQuestion12B.map((e) => (
																	<FormControlLabel
																		key={e.keyData}
																		checked={
																			question12BData
																				? question12BData === e.keyData
																				: false
																		}
																		value={e.keyData}
																		onClick={handleQuestion12BDataChange}
																		control={<Radio size="small" />}
																		label={
																			<Box
																				className={classes.detailTypo}
																				component="div"
																				fontSize={13}
																			>
																				{e.milieu}{" "}
																			</Box>
																		}
																	/>
																))}
															</RadioGroup>
														</FormControl>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>

								{/* QUESTION 13 */}
								<Grid container spacing={1} direction="row">
									<Grid
										item
										xs={11}
										sm={12}
										className={classes.marginBottom_30}
									></Grid>
									<Typography className={classes.label}>{q13}</Typography>
									{/* QUESTION 13A */}
									<Typography className={classes.sublabel}>{q13a}</Typography>
									<TextField
										required
										placeholder="Il s’agit d’une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui étaient les conséquences et impacts de l’acte de violence domestique. Veuillez fournir ici un bref aperçu des conséquences en français."
										multiline
										className={classes.textarea}
										style={{ marginBottom: 20 }}
										rows={6}
										name="question_13_a"
										value={question13AData}
										onChange={(e) => setQuestion13AData(e.target.value)}
										// inputRef={register}
										variant="outlined"
									/>

									{/* QUESTION 13B */}
									<Typography
										className={classes.sublabel}
										style={{ marginTop: 20 }}
									>
										{q13b}{" "}
										<small style={{ fontWeight: 200 }}>
											(choix multiple est possible)
										</small>
									</Typography>

									<Grid container>
										{/* QUESTION 13B_A */}
										<Grid item xs={12} sm={12}>
											<Button
												variant="text"
												color="default"
												className={classes.button}
												startIcon={
													<ArrowDropDownIcon style={{ fontSize: 30 }} />
												}
											>
												Préjudice physique
											</Button>
											{question13BAData ? (
												<List>
													<ListItem>
														<FormGroup>
															{optionsQ13B_APrejudicePhysique.map((e) => (
																<FormControlLabel
																	key={e.keyData}
																	control={
																		<Checkbox
																			checked={question13BAData
																				.filter(Boolean)
																				.includes(e.keyData)}
																			value={e.keyData}
																			onChange={(e) => {
																				if (e.target.checked) {
																					setQuestion13BAData([
																						...question13BAData.sort(),
																						e.target.value,
																					]);
																				} else {
																					setQuestion13BAData(
																						question13BAData
																							.filter(
																								(el) => el !== e.target.value
																							)
																							.sort()
																					);
																				}
																			}}
																		/>
																	}
																	label={
																		<span className={classes.detailTypo}>
																			{e.prejudice}
																		</span>
																	}
																/>
															))}
														</FormGroup>
													</ListItem>
												</List>
											) : null}
										</Grid>
										{/* QUESTION 13B_B */}
										<Grid item xs={12} sm={12}>
											<Button
												variant="text"
												color="default"
												className={classes.button}
												startIcon={
													<ArrowDropDownIcon style={{ fontSize: 30 }} />
												}
											>
												Préjudice émotionnel
											</Button>
											{question13BBData ? (
												<List>
													<ListItem>
														<FormGroup>
															{optionsQ13B_BPrejudiceEmotionnel.map((e) => (
																<FormControlLabel
																	key={e.keyData}
																	control={
																		<Checkbox
																			checked={question13BBData
																				.filter(Boolean)
																				.includes(e.keyData)}
																			value={e.keyData}
																			onChange={(e) => {
																				if (e.target.checked) {
																					setQuestion13BBData([
																						...question13BBData.sort(),
																						e.target.value,
																					]);
																				} else {
																					setQuestion13BBData(
																						question13BBData
																							.filter(
																								(el) => el !== e.target.value
																							)
																							.sort()
																					);
																				}
																			}}
																		/>
																	}
																	label={
																		<span className={classes.detailTypo}>
																			{e.prejudice}
																		</span>
																	}
																/>
															))}
														</FormGroup>
													</ListItem>
												</List>
											) : null}
										</Grid>
										{/* QUESTION 13B_C */}
										<Grid item xs={12} sm={12}>
											<Button
												variant="text"
												color="default"
												className={classes.button}
												startIcon={
													<ArrowDropDownIcon style={{ fontSize: 30 }} />
												}
											>
												Préjudice économique
											</Button>
											{question13BCData ? (
												<List>
													<ListItem>
														<FormGroup>
															{optionsQ13B_CPrejudiceEconomique.map((e) => (
																<FormControlLabel
																	key={e.keyData}
																	control={
																		<Checkbox
																			checked={question13BCData
																				.filter(Boolean)
																				.includes(e.keyData)}
																			value={e.keyData}
																			onChange={(e) => {
																				if (e.target.checked) {
																					setQuestion13BCData([
																						...question13BCData.sort(),
																						e.target.value,
																					]);
																				} else {
																					setQuestion13BCData(
																						question13BCData
																							.filter(
																								(el) => el !== e.target.value
																							)
																							.sort()
																					);
																				}
																			}}
																		/>
																	}
																	label={
																		<span className={classes.detailTypo}>
																			{e.prejudice}
																		</span>
																	}
																/>
															))}
														</FormGroup>
													</ListItem>
												</List>
											) : null}
										</Grid>
										{/* QUESTION 13B_D */}
										<Grid item xs={12} sm={12}>
											<Button
												variant="text"
												color="default"
												className={classes.button}
												startIcon={
													<ArrowDropDownIcon style={{ fontSize: 30 }} />
												}
											>
												Impact sur les relations
											</Button>
											{question13BDData ? (
												<List>
													<ListItem>
														<FormGroup>
															{optionsQ13B_DImpactSurLesRelations.map((e) => (
																<FormControlLabel
																	key={e.keyData}
																	control={
																		<Checkbox
																			checked={question13BDData
																				.filter(Boolean)
																				.includes(e.keyData)}
																			value={e.keyData}
																			onChange={(e) => {
																				if (e.target.checked) {
																					setQuestion13BDData([
																						...question13BDData.sort(),
																						e.target.value,
																					]);
																				} else {
																					setQuestion13BDData(
																						question13BDData
																							.filter(
																								(el) => el !== e.target.value
																							)
																							.sort()
																					);
																				}
																			}}
																		/>
																	}
																	label={
																		<span className={classes.detailTypo}>
																			{e.impact}
																		</span>
																	}
																/>
															))}
														</FormGroup>
													</ListItem>
												</List>
											) : null}
										</Grid>
									</Grid>
								</Grid>
								{/* QUESTION 14 */}
								<Grid container spacing={1} direction="row">
									<Grid item xs={10} sm={10}>
										<Typography className={classes.label}>
											{q14}
											<small style={{ fontWeight: 200 }}>
												(choix multiple est possible ; cependant il n’est pas
												nécessaire de présenter chaque option à la victime mais
												la personne qui remplit la fiche coche les cases
												adéquates selon les descriptions de la victime.)
											</small>
										</Typography>

										{/* QUESTION 14A */}
										<Typography className={classes.sublabel}>
											{q14a}
											<small style={{ fontWeight: 200 }}>
												(Sans conjoint, ceux-ci font l'objet de la question
												suivante)
											</small>
										</Typography>
										<FormControl component="fieldset">
											<RadioGroup
												name="question_14_a"
												className={classes.radiolabel}
											>
												{optionsOuiAndNon.map((e) => (
													<FormControlLabel
														key={e.keyData}
														checked={
															question14AData
																? question14AData === e.keyData
																: false
														}
														value={e.keyData}
														onClick={handleQuestion14ADataChange}
														control={<Radio size="small" />}
														label={
															<Box
																className={classes.detailTypo}
																component="div"
																fontSize={13}
															>
																{e.answer}
															</Box>
														}
													/>
												))}
											</RadioGroup>
										</FormControl>
										{/* QUESTION 14A OUI */}
										{question14AData === "A" ? (
											<FormGroup className={classes.marginBottom_30}>
												<Typography className={classes.sublabel}>
													<small style={{ fontWeight: 200 }}>
														Si oui (choix multiple est possible)
													</small>
												</Typography>

												{optionsQ14A_OUI.map((e) => (
													<FormControlLabel
														key={e.keyData}
														control={
															<Checkbox
																name="question_10_a_oui"
																checked={
																	question14AOuiData.filter(Boolean).length > 0
																		? question14AOuiData
																				.filter(Boolean)
																				.includes(e.keyData)
																		: false
																}
																value={e.keyData}
																onChange={(e) => {
																	if (e.target.checked) {
																		setQuestion14AOuiData([
																			...question14AOuiData.sort(),
																			e.target.value,
																		]);
																	} else {
																		setQuestion14AOuiData(
																			question14AOuiData
																				.filter((el) => el !== e.target.value)
																				.sort()
																		);
																	}
																}}
															/>
														}
														label={
															<span className={classes.detailTypo}>
																{e.agresseur}
															</span>
														}
													/>
												))}
											</FormGroup>
										) : null}

										{/* QUESTION 14B */}
										<Typography
											className={classes.sublabel}
											style={{ marginTop: 20 }}
										>
											{q14b}
										</Typography>
										<FormControl component="fieldset">
											<RadioGroup
												name="question_14_b"
												className={classes.radiolabel}
											>
												{optionsOuiAndNon.map((e) => (
													<FormControlLabel
														key={e.keyData}
														checked={
															question14BData
																? question14BData === e.keyData
																: false
														}
														value={e.keyData}
														onClick={handleQuestion14BDataChange}
														control={<Radio size="small" />}
														label={
															<Box
																className={classes.detailTypo}
																component="div"
																fontSize={13}
															>
																{e.answer}
															</Box>
														}
													/>
												))}
											</RadioGroup>
										</FormControl>
										{/* QUESTION 14B OUI */}
										{question14BData === "A" ? (
											<FormGroup className={classes.marginBottom_30}>
												<Typography className={classes.sublabel}>
													<small style={{ fontWeight: 200 }}>
														Si oui, l’auteur de la violence domestique est …
													</small>
												</Typography>
												{optionsQ14B_OUI.map((e) => (
													<FormControlLabel
														key={e.keyData}
														control={
															<Checkbox
																name="question_14_b_oui"
																checked={
																	question14BOuiData.filter(Boolean).length > 0
																		? question14BOuiData
																				.filter(Boolean)
																				.includes(e.keyData)
																		: false
																}
																value={e.keyData}
																onChange={(e) => {
																	if (e.target.checked) {
																		setQuestion14BOuiData([
																			...question14BOuiData.sort(),
																			e.target.value,
																		]);
																	} else {
																		setQuestion14BOuiData(
																			question14BOuiData
																				.filter((el) => el !== e.target.value)
																				.sort()
																		);
																	}
																}}
															/>
														}
														label={
															<span className={classes.detailTypo}>
																{e.agresseur}
															</span>
														}
													/>
												))}
											</FormGroup>
										) : null}
										{/* QUESTION 14C */}
										<Typography
											className={classes.sublabel}
											style={{ marginTop: 20 }}
										>
											{q14c}
										</Typography>
										<FormGroup className={classes.marginBottom_30}>
											{optionsQ14C_OUI.map((e) => (
												<FormControlLabel
													key={e.keyData}
													control={
														<Checkbox
															name="question_10_c"
															checked={question14CData
																.filter(Boolean)
																.includes(e.keyData)}
															value={e.keyData}
															onChange={(e) => {
																if (e.target.checked) {
																	setQuestion14CData([
																		...question14CData.sort(),
																		e.target.value,
																	]);
																} else {
																	setQuestion14CData(
																		question14CData
																			.filter((el) => el !== e.target.value)
																			.sort()
																	);
																}
															}}
														/>
													}
													label={
														<span className={classes.detailTypo}>
															{e.agresseur}
														</span>
													}
												/>
											))}
										</FormGroup>
									</Grid>
								</Grid>

								{/* QUESTION 15 */}
								<Grid
									container
									spacing={1}
									direction="row"
									style={{ marginTop: 20 }}
								>
									<Grid container spacing={1} direction="row">
										<Grid item xs={11} sm={10}>
											<Typography className={classes.label}>{q15}</Typography>
											<FormControl component="fieldset">
												<RadioGroup
													name="question_15"
													className={classes.radiolabel}
												>
													{optionsOuiAndNon.map((e) => (
														<FormControlLabel
															key={e.keyData}
															checked={
																question15Data
																	? question15Data === e.keyData
																	: false
															}
															value={e.keyData}
															onClick={handleQuestion15DataChange}
															control={<Radio size="small" />}
															label={
																<Box
																	className={classes.detailTypo}
																	component="div"
																	fontSize={13}
																>
																	{e.answer}
																</Box>
															}
														/>
													))}
												</RadioGroup>
											</FormControl>

											{/* QUESTION 15 OUI */}
											{question15Data === "A" ? (
												<FormGroup className={classes.marginBottom_30}>
													<Typography className={classes.sublabel}>
														<small style={{ fontWeight: 200 }}>Si oui: </small>
													</Typography>
													{optionsQ15_OUI.map((e) => (
														<FormControlLabel
															key={e.keyData}
															control={
																<Checkbox
																	name="question_15_oui"
																	checked={
																		question15OuiData.filter(Boolean).length > 0
																			? question15OuiData
																					.filter(Boolean)
																					.includes(e.keyData)
																			: false
																	}
																	value={e.keyData}
																	onChange={(e) => {
																		if (e.target.checked) {
																			setQuestion15OuiData([
																				...question15OuiData.sort(),
																				e.target.value,
																			]);
																		} else {
																			setQuestion15OuiData(
																				question15OuiData
																					.filter((el) => el !== e.target.value)
																					.sort()
																			);
																		}
																	}}
																/>
															}
															label={
																<span className={classes.detailTypo}>
																	{e.endroit}
																</span>
															}
														/>
													))}
													{/* QUESTION 15 OUI AUTRES*/}
													<FormControlLabel
														style={{ marginTop: 15 }}
														control={
															<Checkbox
																checked={question15OuiAutreData ? true : false}
															/>
														}
														label={
															<TextField
																label="Autre situation de vie en proximité"
																variant="outlined"
																style={{ width: "150%" }}
																size="small"
																fullWidth
																name="question_15_oui_autre"
																value={question15OuiAutreData}
																onChange={(e) =>
																	setQuestion15OuiAutreData(e.target.value)
																}
																// inputRef={register}
															/>
														}
													/>
												</FormGroup>
											) : null}
										</Grid>
									</Grid>
								</Grid>

								{/* QUESTION 16 */}
								<Grid
									container
									spacing={1}
									direction="row"
									style={{ marginTop: 20 }}
								>
									<Grid item xs={11} sm={10} className={classes.marginTop_20}>
										<Typography className={classes.label}>{q16}</Typography>
										<FormControl component="fieldset">
											<RadioGroup
												name="question_16"
												className={classes.radiolabel}
											>
												{optionsOuiAndNon.map((e) => (
													<FormControlLabel
														key={e.keyData}
														checked={
															question16Data
																? question16Data === e.keyData
																: false
														}
														value={e.keyData}
														onClick={handleQuestion16DataChange}
														control={<Radio size="small" />}
														label={
															<Box
																className={classes.detailTypo}
																component="div"
																fontSize={13}
															>
																{e.answer}
															</Box>
														}
													/>
												))}
											</RadioGroup>
										</FormControl>
									</Grid>
									{/* QUESTION 16 OUI */}
									<Grid item xs={11} sm={10} className={classes.marginTop_20}>
										{question16Data === "A" ? (
											<FormControl component="fieldset">
												<Typography className={classes.subradio}>
													Si oui, les autorités ont-elles donné suite à la
													plainte :
												</Typography>
												<RadioGroup
													name="question_16_oui"
													className={classes.radiolabel}
												>
													{optionsOuiAndNon.map((e) => (
														<FormControlLabel
															key={e.keyData}
															checked={
																question16OuiData
																	? question16OuiData === e.keyData
																	: false
															}
															value={e.keyData}
															onClick={(e) =>
																setQuestion16OuiData(e.target.value)
															}
															control={<Radio size="small" />}
															label={
																<Box
																	className={classes.detailTypo}
																	component="div"
																	fontSize={13}
																>
																	{e.answer}
																</Box>
															}
														/>
													))}
												</RadioGroup>
											</FormControl>
										) : null}
									</Grid>
								</Grid>

								{/* QUESTION 17 */}
								<Grid
									container
									spacing={1}
									direction="row"
									className={classes.marginBottom_30}
								>
									<Grid item xs={11} sm={10} className={classes.marginTop_20}>
										<Typography className={classes.label}>{q17}</Typography>
										<FormControl component="fieldset">
											<RadioGroup
												name="question_17"
												className={classes.radiolabel}
											>
												{optionsOuiAndNon.map((e) => (
													<FormControlLabel
														key={e.keyData}
														checked={
															question17Data
																? question17Data === e.keyData
																: false
														}
														value={e.keyData}
														onClick={(e) => setQuestion17Data(e.target.value)}
														control={<Radio size="small" />}
														label={
															<Box
																className={classes.detailTypo}
																component="div"
																fontSize={13}
															>
																{e.answer}
															</Box>
														}
													/>
												))}
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>

								{/* QUESTION 18 */}
								<Grid container spacing={1} direction="row">
									<Grid item xs={10} sm={10}>
										<Typography className={classes.label}>{q18}</Typography>
										<FormControl component="fieldset">
											<RadioGroup
												name="question_18"
												className={classes.radiolabel}
											>
												{optionsOuiAndNon.map((e) => (
													<FormControlLabel
														key={e.keyData}
														checked={
															question18Data
																? question18Data === e.keyData
																: false
														}
														value={e.keyData}
														onClick={(e) => setQuestion18Data(e.target.value)}
														control={<Radio size="small" />}
														label={
															<Box
																className={classes.detailTypo}
																component="div"
																fontSize={13}
															>
																{e.answer}
															</Box>
														}
													/>
												))}
											</RadioGroup>
										</FormControl>
										{/* QUESTION 18 A */}
										{question18Data === "A" ? (
											<div>
												<FormGroup
													className={classes.marginBottom_30}
													style={{ marginTop: 20 }}
												>
													<Typography className={classes.sublabel}>
														{q18a}
													</Typography>

													{optionsQ18A.map((e) => (
														<FormControlLabel
															key={e.keyData}
															control={
																<Checkbox
																	name="question_18_a"
																	checked={
																		question18AData.filter(Boolean).length > 0
																			? question18AData
																					.filter(Boolean)
																					.includes(e.keyData)
																			: false
																	}
																	value={e.keyData}
																	onChange={(e) => {
																		if (e.target.checked) {
																			setQuestion18AData([
																				...question18AData.sort(),
																				e.target.value,
																			]);
																		} else {
																			setQuestion18AData(
																				question18AData
																					.filter((el) => el !== e.target.value)
																					.sort()
																			);
																		}
																	}}
																/>
															}
															label={
																<span className={classes.detailTypo}>
																	{e.priseEncharge}
																</span>
															}
														/>
													))}

													{/* QUESTION 18 A AUTRES */}
													<FormControlLabel
														style={{ marginTop: 15 }}
														control={
															<Checkbox
																checked={question18AAutreData ? true : false}
															/>
														}
														label={
															<TextField
																label="Autre, notamment "
																variant="outlined"
																style={{ width: "100%" }}
																size="small"
																fullWidth
																name="question_18_a_autre"
																value={question18AAutreData}
																onChange={(e) =>
																	setQuestion18AAutreData(e.target.value)
																}
																// inputRef={register}
															/>
														}
													/>
												</FormGroup>

												{/* QUESTION 18B */}
												<Typography
													className={classes.sublabel}
													style={{ marginTop: 20 }}
												>
													{q18b}
												</Typography>
												<TextField
													id="outlined-multiline-static"
													// label="Il s’agit d’une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé. Veuillez fournir ici un bref aperçu du cas en français."
													placeholder="Notez la réponse"
													required
													multiline
													className={classes.textarea}
													rows={8}
													name="question_18_b"
													value={question18BData}
													onChange={(e) => setQuestion18BData(e.target.value)}
													// inputRef={register}
													variant="outlined"
												/>

												{/* QUESTION 18C */}
												<Typography
													className={classes.sublabel}
													style={{ marginTop: 20 }}
												>
													{q18c}
												</Typography>
												<TextField
													id="outlined-multiline-static"
													// label="Il s’agit d’une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé. Veuillez fournir ici un bref aperçu du cas en français."
													placeholder="Notez la réponse"
													required
													multiline
													className={classes.textarea}
													rows={8}
													name="question_18_c"
													value={question18CData}
													onChange={(e) => setQuestion18CData(e.target.value)}
													// inputRef={register}
													variant="outlined"
												/>
											</div>
										) : null}
									</Grid>
								</Grid>

								{/* QUESTION 19 */}
								<Grid
									container
									spacing={1}
									direction="row"
									style={{ marginTop: 20 }}
								>
									<Grid container spacing={1} direction="row">
										<Grid item xs={11} sm={10} className={classes.marginTop_20}>
											<Typography className={classes.label}>{q19}</Typography>
											<FormControl component="fieldset">
												<RadioGroup
													name="question_19"
													className={classes.radiolabel}
												>
													{optionsOuiAndNon.map((e) => (
														<FormControlLabel
															key={e.keyData}
															checked={
																question19Data
																	? question19Data === e.keyData
																	: false
															}
															value={e.keyData}
															onClick={(e) => setQuestion19Data(e.target.value)}
															control={<Radio size="small" />}
															label={
																<Box
																	className={classes.detailTypo}
																	component="div"
																	fontSize={13}
																>
																	{e.answer}
																</Box>
															}
														/>
													))}
												</RadioGroup>
											</FormControl>

											{/* QUESTION 19 OUI*/}
											{question19Data === "A" ? (
												<FormGroup className={classes.marginBottom_30}>
													<Typography className={classes.subradio}>
														Si oui : (choix multiple est possible.)
													</Typography>

													{optionsPriseEnChargeQ19Q20.map((e) => (
														<FormControlLabel
															key={e.keyData}
															control={
																<Checkbox
																	name="question_19_oui"
																	checked={
																		question19OuiData.filter(Boolean).length > 0
																			? question19OuiData
																					.filter(Boolean)
																					.includes(e.keyData)
																			: false
																	}
																	value={e.keyData}
																	onChange={(e) => {
																		if (e.target.checked) {
																			setQuestion19OuiData([
																				...question19OuiData.sort(),
																				e.target.value,
																			]);
																		} else {
																			setQuestion19OuiData(
																				question19OuiData
																					.filter((el) => el !== e.target.value)
																					.sort()
																			);
																		}
																	}}
																/>
															}
															label={
																<span className={classes.detailTypo}>
																	{e.priseEncharge}
																</span>
															}
														/>
													))}
													{/* QUESTION 19 AUTRES */}
													<FormControlLabel
														style={{ marginTop: 15 }}
														control={
															<Checkbox
																checked={question19AutreData ? true : false}
															/>
														}
														label={
															<TextField
																label="Autre, notamment "
																variant="outlined"
																style={{ width: "100%" }}
																size="small"
																fullWidth
																name="question_19_autre"
																value={question19AutreData}
																onChange={(e) =>
																	setQuestion19AutreData(e.target.value)
																}
																// inputRef={register}
															/>
														}
													/>
												</FormGroup>
											) : null}
										</Grid>
									</Grid>
								</Grid>

								{/* QUESTION 20 */}
								<Grid
									container
									spacing={1}
									direction="row"
									className={classes.marginBottom_30}
									style={{ marginTop: 20 }}
								>
									<Grid container spacing={1} direction="row">
										<Grid item xs={11} sm={10} className={classes.marginTop_20}>
											<Typography className={classes.label}>
												{q20}
												<small style={{ fontWeight: 200 }}>{q20sub}</small>
											</Typography>
											<FormGroup>
												{optionsPriseEnChargeQ19Q20.map((e) => (
													<FormControlLabel
														key={e.keyData}
														control={
															<Checkbox
																name="question_20"
																checked={
																	question20Data.filter(Boolean).length > 0
																		? question20Data
																				.filter(Boolean)
																				.includes(e.keyData)
																		: false
																}
																value={e.keyData}
																onChange={(e) => {
																	if (e.target.checked) {
																		setQuestion20Data([
																			...question20Data.sort(),
																			e.target.value,
																		]);
																	} else {
																		setQuestion20Data(
																			question20Data
																				.filter((el) => el !== e.target.value)
																				.sort()
																		);
																	}
																}}
															/>
														}
														label={
															<span className={classes.detailTypo}>
																{e.priseEncharge}
															</span>
														}
													/>
												))}

												{/* QUESTION 20 AUTRES */}
												<FormControlLabel
													style={{ marginTop: 15 }}
													control={
														<Checkbox
															checked={question20AutreData ? true : false}
														/>
													}
													label={
														<TextField
															label="Autre, notamment "
															variant="outlined"
															style={{ width: "100%" }}
															size="small"
															fullWidth
															name="question_20_autre"
															value={question20AutreData}
															onChange={(e) =>
																setQuestion20AutreData(e.target.value)
															}
															// inputRef={register}
														/>
													}
												/>
											</FormGroup>
										</Grid>
									</Grid>
								</Grid>

								{/* QUESTION 21 */}
								<Grid
									container
									spacing={1}
									direction="row"
									className={classes.marginBottom_30}
									style={{ marginTop: 20 }}
								>
									<Grid container spacing={1} direction="row">
										<Grid item xs={11} sm={10} className={classes.marginTop_20}>
											<Typography className={classes.label}>
												{q21}
												<small style={{ fontWeight: 200 }}>{q21sub}</small>
											</Typography>
											<FormGroup>
												{optionsQ21.map((e) => (
													<FormControlLabel
														key={e.keyData}
														control={
															<Checkbox
																name="question_21"
																checked={
																	question21Data.filter(Boolean).length > 0
																		? question21Data
																				.filter(Boolean)
																				.includes(e.keyData)
																		: false
																}
																value={e.keyData}
																onChange={(e) => {
																	if (e.target.checked) {
																		setQuestion21Data([
																			...question21Data.sort(),
																			e.target.value,
																		]);
																	} else {
																		setQuestion21Data(
																			question21Data
																				.filter((el) => el !== e.target.value)
																				.sort()
																		);
																	}
																}}
															/>
														}
														label={
															<span className={classes.detailTypo}>
																{e.priseEncharge}
															</span>
														}
													/>
												))}
												{/* QUESTION 21 AUTRES */}
												<FormControlLabel
													style={{ marginTop: 15 }}
													control={
														<Checkbox
															checked={question21AutreData ? true : false}
														/>
													}
													label={
														<TextField
															label="Autre, notamment "
															variant="outlined"
															style={{ width: "100%" }}
															size="small"
															fullWidth
															name="question_21_autre"
															value={question21AutreData}
															onChange={(e) =>
																setQuestion21AutreData(e.target.value)
															}
															// inputRef={register}
														/>
													}
												/>
											</FormGroup>
										</Grid>
									</Grid>
								</Grid>

								{/* QUESTION 22 */}
								<Grid
									container
									spacing={1}
									direction="row"
									className={classes.marginBottom_30}
									style={{ marginTop: 20 }}
								>
									<Grid container spacing={1} direction="row">
										<Grid item xs={11} sm={10} className={classes.marginTop_20}>
											<Typography className={classes.label}>
												{q22}
												<small style={{ fontWeight: 200 }}>{q22sub}</small>
											</Typography>

											<FormGroup className={classes.marginBottom_30}>
												{optionsQ22.map((e) => (
													<FormControlLabel
														key={e.keyData}
														control={
															<Checkbox
																name="question_22"
																checked={
																	question22Data.filter(Boolean).length > 0
																		? question22Data
																				.filter(Boolean)
																				.includes(e.keyData)
																		: false
																}
																value={e.keyData}
																onChange={(e) => {
																	if (e.target.checked) {
																		setQuestion22Data([
																			...question22Data.sort(),
																			e.target.value,
																		]);
																	} else {
																		setQuestion22Data(
																			question22Data
																				.filter((el) => el !== e.target.value)
																				.sort()
																		);
																	}
																}}
															/>
														}
														label={
															<span className={classes.detailTypo}>
																{e.priseEncharge}
															</span>
														}
													/>
												))}
												{/* QUESTION 22 AUTRES */}
												<FormControlLabel
													style={{ marginTop: 15 }}
													control={
														<Checkbox
															checked={question22AutreData ? true : false}
														/>
													}
													label={
														<TextField
															label="Autre, notamment "
															variant="outlined"
															style={{ width: "100%" }}
															size="small"
															fullWidth
															name="question_22_autre"
															value={question22AutreData}
															onChange={(e) =>
																setQuestion22AutreData(e.target.value)
															}
															// inputRef={register}
														/>
													}
												/>
											</FormGroup>
										</Grid>
									</Grid>
									<br />
									{/* QUESTION 23 */}
									<Grid
										container
										spacing={1}
										direction="row"
										style={{ marginTop: 20 }}
										className={classes.marginBottom_30}
									>
										<Grid item xs={11} sm={10} className={classes.marginTop_20}>
											<Typography className={classes.label}>{q23}</Typography>
											<FormControl component="fieldset">
												<RadioGroup
													name="question_23"
													className={classes.radiolabel}
												>
													{optionsOuiAndNon.map((e) => (
														<FormControlLabel
															key={e.keyData}
															checked={
																question23Data
																	? question23Data === e.keyData
																	: false
															}
															value={e.keyData}
															onClick={(e) => setQuestion23Data(e.target.value)}
															control={<Radio size="small" />}
															label={
																<Box
																	className={classes.detailTypo}
																	component="div"
																	fontSize={13}
																>
																	{e.answer}
																</Box>
															}
														/>
													))}
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>
									<br />
									{/* QUESTION 24 */}
									<Grid
										container
										spacing={1}
										direction="row"
										className={classes.marginBottom_30}
										style={{ marginTop: 20 }}
									>
										<Grid item xs={11} sm={10} className={classes.marginTop_20}>
											<Typography className={classes.label}>{q24}</Typography>
											<FormControl
												component="fieldset"
												className={classes.marginBottom_30}
											>
												<RadioGroup
													name="question_24"
													className={classes.radiolabel}
												>
													{optionsQ24.map((e) => (
														<FormControlLabel
															key={e.keyData}
															checked={
																question24Data
																	? question24Data === e.keyData
																	: false
															}
															value={e.keyData}
															onClick={handleQuestion24DataChange}
															control={<Radio size="small" required={true} />}
															label={
																<Box
																	className={classes.detailTypo}
																	component="div"
																	fontSize={13}
																>
																	{e.victimAnswer}
																</Box>
															}
														/>
													))}
												</RadioGroup>
											</FormControl>

											{/* QUESTION 24A */}
											{question24Data === "A" ? null : (
												<div>
													<Typography
														className={classes.sublabel}
														style={{ marginTop: 20 }}
													>
														{q24a}
													</Typography>
													<FormControl component="fieldset">
														<RadioGroup
															name="question_24_a"
															className={classes.radiolabel}
														>
															{optionsOuiAndNon.map((e) => (
																<FormControlLabel
																	key={e.keyData}
																	checked={
																		question24AData
																			? question24AData === e.keyData
																			: false
																	}
																	value={e.keyData}
																	onClick={(e) =>
																		setQuestion24AData(e.target.value)
																	}
																	control={
																		<Radio size="small" required={true} />
																	}
																	label={
																		<Box
																			className={classes.detailTypo}
																			component="div"
																			fontSize={13}
																		>
																			{e.answer}
																		</Box>
																	}
																/>
															))}
														</RadioGroup>
													</FormControl>
												</div>
											)}
										</Grid>
									</Grid>

									<br />
									{/* QUESTION 25 */}
									<Grid
										container
										spacing={1}
										direction="row"
										className={classes.marginBottom_30}
										style={{ marginTop: 20 }}
									>
										<Grid item xs={11} sm={10} className={classes.marginTop_20}>
											<Typography className={classes.label}>{q25}</Typography>
											<FormControl component="fieldset">
												<RadioGroup
													name="question_25"
													className={classes.radiolabel}
												>
													{optionsOuiAndNon.map((e) => (
														<FormControlLabel
															key={e.keyData}
															checked={
																question25Data
																	? question25Data === e.keyData
																	: false
															}
															value={e.keyData}
															onClick={handleQuestion25DataChange}
															control={<Radio size="small" required={true} />}
															label={
																<Box
																	className={classes.detailTypo}
																	component="div"
																	fontSize={13}
																>
																	{e.answer}
																</Box>
															}
														/>
													))}
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>
									<br />
									{/* QUESTION 26 */}
									{question25Data === "B" ? null : (
										<div>
											<Grid container spacing={1} direction="row">
												<Grid
													item
													xs={11}
													sm={10}
													className={classes.marginTop_20}
													style={{ marginTop: 20 }}
												>
													<Typography className={classes.label}>
														{q26}
													</Typography>
													<TextField
														id="outlined-multiline-static"
														// label="Il s’agit d’une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé. Veuillez fournir ici un bref aperçu du cas en français."
														placeholder="Notez la réponse"
														required
														multiline
														className={classes.textarea}
														rows={8}
														name="question_26"
														value={question26Data}
														onChange={(e) => setQuestion26Data(e.target.value)}
														// inputRef={register}
														variant="outlined"
													/>
												</Grid>
											</Grid>
											<br />
											{/* QUESTION 27 */}
											<Grid
												container
												spacing={1}
												direction="row"
												className={classes.marginBottom_30}
												style={{ marginTop: 20, marginBottom: 40 }}
											>
												<Grid
													item
													xs={11}
													sm={10}
													className={classes.marginTop_20}
												>
													<Typography className={classes.label}>
														{q27}
													</Typography>
													<FormControl component="fieldset">
														<RadioGroup
															name="question_27"
															className={classes.radiolabel}
															required
														>
															{optionsQ27.map((e) => (
																<FormControlLabel
																	key={e.keyData}
																	checked={
																		question27Data
																			? question27Data === e.keyData
																			: false
																	}
																	value={e.keyData}
																	onClick={(e) =>
																		setQuestion27Data(e.target.value)
																	}
																	control={
																		<Radio size="small" required={true} />
																	}
																	label={
																		<Box
																			className={classes.detailTypo}
																			component="div"
																			fontSize={13}
																		>
																			{e.privacy}
																		</Box>
																	}
																/>
															))}
														</RadioGroup>
													</FormControl>
												</Grid>
											</Grid>
										</div>
									)}
								</Grid>

								{/* SAVE BUTTON */}
								<Grid
									container
									className={classes.margin_Bottom_30}
									direction="row"
								>
									<Grid item xs={12} sm={12}>
										<Grid
											container
											className={classes.margin_Bottom_30}
											justifyContent="flex-end"
										>
											<Grid item>
												<BtnOutline content="BACK" onClick={backBtn} />

												{isUpdate === true ? (
													<CircularProgress color="secondary" size={20} />
												) : (
													<BtnContained
														content="SAVE"
														type="submit"
														// onClick={updateCase}
													/>
												)}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</form>
						</Grid>
						<Grid item xs={1} sm={1}></Grid>
					</Grid>
				</div>
			);
		} else {
			return (
				<Grid container spacing={4} className={classes.margin_Top_30}>
					<Grid item xs={12} sm={12}>
						<Grid
							container
							direction="row"
							justifyContent="center"
							style={{ height: "400px" }}
							alignItems="center"
						>
							<Grid item xs={12} sm={4}>
								<Grid container justifyContent="center" spacing={2}>
									<Grid item>
										<CloudOffTwoToneIcon size={40} />
									</Grid>
									<Grid item>
										<span
											className={classes.detailTypo}
											style={{ color: "red" }}
										>
											{errorMessage}
										</span>
									</Grid>
								</Grid>
								<Grid container justifyContent="center" spacing={2}>
									<Grid item>
										<Button
											color="primary"
											variant="text"
											aria-label="Restart"
											component="span"
											onClick={() => {
												getEditCase();
												setSpinner(true);

											}}
											startIcon={<RestoreIcon />}
										>
											reload
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		}
	};

	return (
		<div>
			<BackPaper
				content={
					<div>
						<Grid container direction="row">
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<Typography variant="h4" className={classes.bigtitle}>
									{editCase.caseid}
								</Typography>
								{displayData()}
							</Grid>
						</Grid>
					</div>
				}
			/>
		</div>
	);
};
export default Editcase;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Image from "../assets/images/Bitmap.png";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 280,
    minHeight: 290,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  media: {
    height: 1350,
   
  },
}));

export default function AboutPic() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia className={classes.media} image={Image} title="" />
      </CardActionArea>
    </Card>
  );
}

import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import useStyles from "./style.js";
import GalleryPic from "../widgets/GalleryPic";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
const Section4 = () => {
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	return (
		<Grid
			container
			direction="row"
			className={classes.margin_section}
			spacing={4}
		>
			<Grid iten xs={12} sm={12} md={6} lg={6} xl={6}>
				<GalleryPic />
			</Grid>
			<Grid iten xs={12} sm={12} md={6} lg={6} xl={6}>
				<Grid container justifyContent="center" spacing={2}>
					<Grid item xs={12} sm={12}>
						<Typography className={classes.about_ttl}>{t("about")}</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography className={classes.abt_txt}>
							{t("home-about-text")}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography variant="h6" className={classes.about_link}>
							<NavLink
								activeClassName="is-active"
								style={{
									textDecoration: "none",
									color: "white",
									marginTop: "8px",
								}}
								to={"/" + i18n.language + t("link-about")}
							>
								<Button
									endIcon={<KeyboardArrowRightIcon />}
									// size="Large"
									color="secondary"
									className={classes.btn_txt}
								>
									{t("home-about-link")}
								</Button>
							</NavLink>
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Section4;

// import React,{useState,useEffect} from 'react';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import {DataGrid} from "@material-ui/data-grid";


const useStyles = makeStyles({
  table: {
    height: 400,
  },
});

const columns = [
  { field: 'caseid', headerName: 'CASEID', width: 170 },
  { field: 'question_6_b', headerName: 'Groupe d’âge ', width: 170 },
  {
    field: 'question_7',
    headerName: 'Genre',
    width: 100,
  },
  {
    field: 'question_8_a',
    headerName: 'Etat civil',
    width: 220,
  },
  {
      field: 'question_12_a',
      headerName: 'Ville / Territoire ',
      width: 180,
    },
  {
      field: 'question_12_b',
      headerName: 'Type de milieu',
      width: 180,
    },
];



export default function Casedatabank({casemap,active}) {
  const classes = useStyles();
 // console.log(casemap);

  return (
    <Grid container direction='row' >
      <Grid item xs={12} sm={12} className={classes.table} >
         <DataGrid rows={casemap} columns={columns} pageSize={15} loading={active===true?false:true}    />
      </Grid>
    </Grid >
  );
}
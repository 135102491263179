import React from 'react';
import {
     Grid,
     Typography,
    } from '@material-ui/core'
import useStyles from './style'
import { useTranslation } from 'react-i18next';



const Section1=()=>{
    const classes = useStyles();
    const { t } = useTranslation();

    return(
        <div>
            <Grid container >
                <Grid container >
                    <Grid item >
                        <Typography className={classes.big_white_txt} >
                         {t('sectionrigth')}
                        </Typography>
                    
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Section1;
// import React, { Component } from "react";
// import regions from "./data/regions.json";
// import * as province from "./data/regions.json";
// import { MapContainer, TileLayer, GeoJSON,Popup ,Marker} from 'react-leaflet'
import React from "react";
import { MapContainer, TileLayer,Popup ,Marker} from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster';


// class Map extends Component{
//   state={};
//   componentDidMount() {
//     console.log(regions);
//   }
//   render(){
//     return(
//       <div >
//         <MapContainer center={[-4.214943, 23.284580]} zoom={6} scrollWheelZoom={false}>
//           <TileLayer
//             url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           />
//           <MarkerClusterGroup>
//             <GeoJSON
//               data={regions.features}
//             >
//             <Popup
//             onOpen={()=> console.log("clicked")}
//             >
//               {regions.features.map((a)=> a.properties.kinshasa
//             )}
//             </Popup>
//             </GeoJSON>
//           </MarkerClusterGroup>
//         </MapContainer>
//       </div>
//     );
//   }
// }

const Map =({casemap})=>{
//  console.log(casemap);

  return (
    <MapContainer center={[-4.214943, 23.284580]} zoom={5} scrollWheelZoom={false} attributionControl={false}>
      <TileLayer  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
       <MarkerClusterGroup>
            {
              casemap.map(e=>{
             //   console.log(e.lat);
                return (
                  <Marker position={[`${e.lat}`,`${e.lon}`]}>
                    
                  <Popup>
                     <span>CASEID: {e.caseid}</span><br/>
                     <span>ADDRESS: {e.question_12_a}, 
                     {/* {incident['city']} - {incident['zip_code']} */}
                     </span>
                   <br/>  
                     <span>ORGANISATION: {e.organization}</span><br/>
                  </Popup>
              </Marker>
                )
              })
            }
        </MarkerClusterGroup>

    </MapContainer>
  )
  
}



export default Map;


import React, { useState, useEffect } from "react";
import useStyles from "../styles";
import {
	Grid,
	Button, 
	TextField,
	Typography,
	Card,
	CardContent,
	Divider,
	Select,
	MenuItem, 
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../../service/dashboardAxiosApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams } from 'react-router-dom';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import InputLabel from '@material-ui/core/InputLabel';

const Edit = () => {
	const classes = useStyles();
	const history = useHistory();
	const { id } = useParams();
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [internetError, setInternetError] = useState(false);
	const [responseMessage, setResponseMessage] = useState("");
	const [successResponse, setSuccessResponse] = useState(false);
	const [userData, setUserData] = useState(null);
	const roleLocal = sessionStorage.getItem("role");
	const [isLoading, setIsLoading] = useState(false);
	const [isCancelling, setIsCancelling] = useState(false);
	const [isComponentMounted, setIsComponentMounted] = useState(true);
	
	
	// SNACKBAR ALERT
	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});

	// EFFECT FOR COMPONENT MOUNTING
	useEffect(() => {
        return () => {
            // executed when the component is unmounted
            setIsComponentMounted(false);
        }
    }, []);


	// USER FETCH
	const fetchUser = async () => {
		if (!navigator.onLine) {
		//   console.log("No internet connection.");
		  setResponseMessage("No internet connection.")
		  setInternetError(true);
		  return;
		}
	  
		try {
		  const response = await axiosInstance.get(`/users/${id}`, {timeout: 10000});
		  setUserData({
			...response.data,
			activated: response.data.users.activated 
		  });
		  setInternetError(false);
		  setSuccessResponse(true)
		} catch (error) {
		  if (error.code === 'ECONNABORTED') {
			setResponseMessage("The request took too long - please try again.")
			setInternetError(true);
		  } 

		  setSuccessResponse(false)
		}
	  };
	
	// EFFECT FOR FETCH USER
	useEffect(() => {
		fetchUser();
	  }, [id]);


	// HANDLE INPUT CHANGE
    const handleInputChange = (event) => {
        // Update the user data state when input fields change
        const name = event.target.name;
		const value = event.target.value;
		setUserData(prevState => ({
			...prevState,
			users: {
				...prevState.users,
				[name]: value,
				role: userData.users.role === "Super Admin" ? 1 : userData.users.role === "Admin" ? 2 : userData.users.role === "Author" ? 3 : userData.users.role === "Data Collector" ? 4 : userData.users.role || ''
			}
		}));
    };

	// HANDLE ROLE CHANGE
	const handleRoleChange = (event) => {
		// Update the role state and the userData state when the role changes
		const newRole = Number(event.target.value);
		setUserData(prevState => ({
			...prevState,
			users: {
				...prevState.users,
				role: newRole
			}
		}));
		// {console.log(userData)}
		// {console.log(newRole)}
	};


	// CANCEL EDIT
	const handleCancel = (event) => {
        event.preventDefault();
		setIsCancelling(true)
		setTimeout(() => {
			history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/user/detail/${id}`);
		}, 4000); 
	}

	// USER PATCH UPDATING
    const handleSubmit = async (event) => {
        event.preventDefault();
		setIsLoading(true);

        // Send a PATCH request with the updated data
        try {
			const response = await axiosInstance.patch(`user/${id}/`, userData.users)
			if (isComponentMounted) {
				setResponseMessage(`${response.data.user.first_name} updated successfully!`);
				setSuccessResponse(true);
				setInternetError(false);
				setTimeout(() => {
					setIsLoading(false);
					history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/user/detail/${response.data.user.id}`);
				}, 3000); 
			}
			} catch (error) {
				if (isComponentMounted) {
					// Only proceed if the component is still mounted
					if (error.code === 'ECONNABORTED') {
						setResponseMessage("The request took too long - please try again.")
						setInternetError(true);
					} else {
						if (error.response && error.response.data) {
							// console.log(error.response.data)
						}
						setResponseMessage("Error saving the user. Check your internet or contact admin");
					}
					setSuccessResponse(false);
					setIsLoading(false);
					setTimeout(() => {
						history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/user/detail/${id}`);
					}, 4000); 
				}
			}

			if (isComponentMounted) {
				handleClickSnack();
			}
    };


	//  INTERNET ERROR
	if (internetError) {
		return (
		<div>
			<p>An error occurred. Please check your internet connection and try again.</p>
			<Button variant="contained" color="primary"  onClick={handleInputChange}>Retry</Button>
		</div>
		)
	}

	const handleClickSnack = () => {
		setOpenSnackBar(true);
	};

	const handleCloseScnackBar = (event, reason) => {
		if (reason === 'clickaway') {
		return;
		}

		setOpenSnackBar(false);
	};


	// RENDERING THE FORM DATA
	if (userData) {
		return (
			<Grid container spacing={3}>
					<Grid container direction="row">
						<Grid item xs={12} sm={12}>
							<Typography variant="h4" className={classes.bigtitle}>
								Update user's information
							</Typography>
						</Grid>
						{/* RESPONSE MESSAGE SNACKBAR */}
						<Grid item xs={12} sm={4}>
							<Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseScnackBar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
							{ successResponse ? <Alert onClose={handleCloseScnackBar} severity="success" sx={{ width: '100%' }}>
									{responseMessage} 
								</Alert>
								:
								<Alert onClose={handleCloseScnackBar} severity="error" sx={{ width: '100%' }}>
								{responseMessage}
								</Alert> }
							</Snackbar>
						</Grid>
					</Grid>

					<Divider />

					<Grid container direction="row">
						<Grid item xs={12} sm={12}>
							<Card className={classes.fullWidthCard}>
								<CardContent className={classes.leftAndRightMiddle}>
									<Grid container className={classes.margin_Top_30}>
										<Grid item sm={10}>
										<Grid item className={classes.margin_Bottom_30}>
										<Typography className={classes.detailTypoGrow}>
													<strong >USER'S INFORMATION</strong> 
										</Typography>
										</Grid>
											{/* LEFT SIDE */}
											<Grid container spacing={4} className={classes.cardContentMarginRight}>
												<Grid item xs={12} sm={6}>
												
													<Grid
														container
														spacing={2}
														className={classes.margin_Bottom_30}
													>
														<Grid item sm={12} xs={12}>
														<TextField 
															required
															name="organization"
															label="Organisation" 
															fullWidth
															value={userData.users.organization || ''} 
															onChange={handleInputChange} />
														</Grid>
													</Grid>
													<Grid
														container
														spacing={2}
														className={classes.margin_Bottom_30}
													>
														<Grid item sm={12} xs={12}>
															<TextField 
																required
																name="abbreviation"
																label="Abbreviation Of The Organisation" 
																fullWidth
																value={userData.users.abbreviation || ''} 
																onChange={handleInputChange} />
														</Grid>
													</Grid>
													<Grid
														container
														spacing={2}
														className={classes.margin_Bottom_30}
													>
														<Grid item sm={12} xs={12}>
															<TextField
																required
																name="location"
																label="City/Country" 
																fullWidth
																value={userData.users.location || ''} 
																onChange={handleInputChange} />
														</Grid>
													</Grid>
													<Grid
														container
														spacing={2}
														className={classes.margin_Bottom_30}
													>
														<Grid item sm={12} xs={12}>
															<TextField 
																required
																name="address"
																label="Address" 
																fullWidth
																value={userData.users.address || ''} 
																onChange={handleInputChange} />
														</Grid>
													</Grid>
												</Grid>

												{/* right side */}
												<Grid item xs={12} sm={6}>
													<Grid
														container
														spacing={2}
														className={classes.margin_Bottom_30}
													>
														<Grid item sm={12} xs={12}>
															<TextField 
																required
																name="first_name"
																label="First Name" 
																fullWidth
																value={userData.users.first_name || ''} 
																onChange={handleInputChange} />
														</Grid>
													</Grid>
													<Grid
														container
														spacing={2}
														className={classes.margin_Bottom_30}
													>
														<Grid item sm={12} xs={12}>
															<TextField 
																required
																name="last_name"
																label="Last Name" 
																fullWidth
																value={userData.users.last_name || ''} 
																onChange={handleInputChange} />
														</Grid>
													</Grid>
													<Grid
														container
														spacing={2}
														className={classes.margin_Bottom_30}
													>
														<Grid item sm={12} xs={12}>
															<TextField 
																required
																name="email"
																type="email"
																label="E-mail" 
																fullWidth
																value={userData.users.email || ''} 
																onChange={handleInputChange} />
														</Grid>
													</Grid>
													<Grid
														container
														spacing={2}
														className={classes.margin_Bottom_30}
													>
														<Grid item sm={12} xs={12}>
														<TextField
															required
															name="phone_number"
															label="Phone" 
															fullWidth
															value={userData.users.phone_number || ''} 
															placeholder="e.g +243970000001"
															onChange={handleInputChange} />
														</Grid>
													</Grid>
												</Grid>
											</Grid>

											<Divider
												component="hr"
												className={classes.margin_Bottom_30}
											/>

											{/* BOTTOM LEFT SIDE */}
											<Grid item className={classes.margin_Bottom_30}>
												<Typography className={classes.detailTypoGrow}>
														<strong>LOGIN CREDENTIALS</strong> 
												</Typography>
											</Grid>
											<Grid container spacing={4}  className={classes.cardContentMarginRight}>
												<Grid item xs={12} sm={6}>
													<Grid
														container
														spacing={2}
														className={classes.margin_Bottom_30}
													>
														<Grid item sm={12} xs={12}>
															<TextField
																required
																name="username"
																label="User name" 
																fullWidth
																value={userData.users.username || ''} 
																onChange={handleInputChange} />
														</Grid>
														<Grid item>
															<span style={{ color: "#4F2970" }}>
																 Please note that this username is used to login 
															</span>
														</Grid>
														
													</Grid>
												</Grid>
												<Grid item xs={12} sm={6}>
													<InputLabel id="demo-simple-select-label">Role *</InputLabel>
													{roleLocal === 2 ? (
														<Select
															required
															name="role"
															// variant="outlined"
															label="Select"
															size="small"
															fullWidth
															value={userData.users.role === "Super Admin" ? 1 : userData.users.role === "Admin" ? 2 : userData.users.role === "Author" ? 3 : userData.users.role === "Data Collector" ? 4 : userData.users.role || ''}
															style={{ height: 40 }}
															onChange={handleRoleChange}
														>
															<MenuItem value={3}>Author</MenuItem>
															<MenuItem value={4}>Data Collector</MenuItem>
														</Select>
													) : (
														<Select
															required
															name="role"
															label="Select"
															size="small"
															fullWidth
															value={userData.users.role === "Super Admin" ? 1 : userData.users.role === "Admin" ? 2 : userData.users.role === "Author" ? 3 : userData.users.role === "Data Collector" ? 4 : userData.users.role || ''}
															style={{ height: 40 }}
															onChange={handleRoleChange}
														>
															<MenuItem value={1}>Super Admin</MenuItem>
															<MenuItem value={2}>Admin</MenuItem>
															<MenuItem value={3}>Author</MenuItem>
															<MenuItem value={4}>Data Collector</MenuItem>
														</Select>
														
													)}
												</Grid>
												<Grid item xs={12} sm={6}>

												</Grid>
											</Grid>
											{/* BUTTON */}
											<Grid container spacing={4} className={classes.margin_Bottom_30}>
												<Grid item  >
													<Button 
														variant="contained" 
														color="primary"
														onClick={handleCancel}
														disabled={isCancelling || isLoading}
														>
														{isCancelling ? "CANCELING..." : "CANCEL"}
													</Button>
												</Grid>
												<Grid item  >
													<Button 
														variant="contained" 
														color="secondary"
														onClick={handleSubmit}
														disabled={isLoading || isCancelling}
														>
														{isLoading ? "SAVING..." : "SAVE"}
													</Button>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
			</Grid>
		);
	} else {
        return <CircularProgress />;
    }
};

export default Edit;

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
	Grid,
	Typography,
	Box,
	FormGroup,
	Checkbox,
	TextField,
	RadioGroup,
	Radio,
	FormControlLabel,
	FormControl,
	TextareaAutosize,
	List,
	ListItem,
	Button,
} from "@material-ui/core";
import useStyles from "./reviewstyle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import {
	q1,
	q2,
	q3,
	q4,
	q5,
	q6,
	q6a,
	q6b,
	q7,
	q8,
	q8a,
	q8b,
	q8c,
	q8d,
	q8e,
	q8f,
	q9,
	q10,
	q11,
	q11a,
	q11b,
	q12,
	q12a,
	q12b,
	q13,
	q13a,
	q13b,
	q14,
	q14a,
	q14b,
	q14c,
	q15,
	q16,
	q16oui,
	q17,
	q18,
	q18a,
	q18b,
	q18c,
	//q18d,
	//q18,
	q19,
	q20,
	q21,
	q22,
	q23,
	q24,
	q24a,
	q25,
	q26,
	q27,
	reviewbefore,
	soumettre,
	modifier,
} from "../../widget/i1e";
import { BtnContained, BtnOutline } from "../../widget/util";
import axiosInstance from "../../service/axiosApi";
//import axios from 'axios'
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Revoir = () => {
	const classes = useStyles();
	const [org, setorg] = useState([]);
	const [organization, setorganization] = useState("");
	const [profile, setprofile] = useState([]);
	const [info, setinfo] = useState([]);
	const [perpetor, setperpetor] = useState([]);
	const [q11avalue, setq11avalue] = useState();
	const [q11bvalue, setq11bvalue] = useState();
	const [q14avalue, setq14avalue] = useState();
	const [q14aOui, setq14aOui] = useState([]);
	const [q14bvalue, setq14bvalue] = useState();
	const [q15value, setq15value] = useState();
	const [q18value, setq18value] = useState();
	const [needAndCare, setneedAndCare] = useState([]);
	const [q22value, setq22value] = useState([]);
	const [q16value, setq16value] = useState();
	const [q20value, setq20value] = useState([]);
	const [q21value, setq21value] = useState([]);
	const [q19value, setq19value] = useState([]);
	const [q24value, setq24value] = useState();
	const [q25value, setq25value] = useState();
	const [id, setid] = useState();
	const [token, settoken] = useState("");
	const [quest2, setquest2] = useState("");
	const [quest3, setquest3] = useState("");
	const [quest4, setquest4] = useState("");
	const [quest5, setquest5] = useState("");
	const [quest6a, setquest6a] = useState("");
	const [quest6b, setquest6b] = useState("");
	const [quest7, setquest7] = useState("");
	const [quest8a, setquest8a] = useState("");
	const [quest8b, setquest8b] = useState("");
	const [quest8c, setquest8c] = useState("");
	const [quest8d, setquest8d] = useState("");
	const [quest8e, setquest8e] = useState("");
	const [quest8f, setquest8f] = useState("");
	const [quest9, setquest9] = useState("");
	const [quest10a, setquest10a] = useState("");
	const [quest10b, setquest10b] = useState("");
	const [quest10c, setquest10c] = useState("");
	const [quest10d, setquest10d] = useState("");
	const [quest11a, setquest11a] = useState("");
	const [quest11aOui, setquest11aOui] = useState("");
	const [quest11aOuiMonth, setquest11aOuiMonth] = useState("");
	const [quest11b, setquest11b] = useState("");
	const [quest11boui, setquest11boui] = useState("");
	const [quest11bouiMonth, setquest11bouiMonth] = useState("");
	const [quest12a, setquest12a] = useState("");
	const [quest12b, setquest12b] = useState("");
	const [quest13a, setquest13a] = useState("");
	const [quest13bA, setquest13bA] = useState("");
	const [quest13bB, setquest13bB] = useState("");
	const [quest13bC, setquest13bC] = useState("");
	const [quest13bD, setquest13bD] = useState("");
	const [quest14a, setquest14a] = useState("");
	const [quest14aOui, setquest14aOui] = useState("");
	const [quest14b, setquest14b] = useState("");
	const [quest14bOui, setquest14bOui] = useState([]);
	const [quest14c, setquest14c] = useState("");
	const [quest15, setquest15] = useState("");
	const [quest15Oui, setquest15Oui] = useState("");
	const [quest15autre, setquest15autre] = useState("");
	const [quest16, setquest16] = useState("");
	const [quest16oui, setquest16oui] = useState("");
	const [quest17, setquest17] = useState("");
	const [quest18, setquest18] = useState("");
	const [quest18a, setquest18a] = useState([]);
	const [quest18autre, setquest18autre] = useState("");
	const [quest18b, setquest18b] = useState("");
	const [quest18c, setquest18c] = useState("");
	const [quest19, setquest19] = useState("");
	const [quest19Oui, setquest19Oui] = useState([]);
	const [quest19autre, setquest19autre] = useState("");
	const [quest20, setquest20] = useState([]);
	const [quest20autre, setquest20autre] = useState("");
	const [quest21, setquest21] = useState([]);
	const [qust21autre, setqust21autre] = useState("");
	const [quest22, setquest22] = useState([]);
	const [qust22autre, setqust22autre] = useState("");
	const [quest23, setquest23] = useState("");
	const [quest24, setquest24] = useState("");
	const [quest24a, setquest24a] = useState("");
	const [quest25, setquest25] = useState("");
	const [quest26, setquest26] = useState("");
	const [quest27, setquest27] = useState("");
	const [approve, setapprove] = useState(false);
	const [lat, setlat] = useState();
	const [long, setlong] = useState();
	const [q12province, setq12province] = useState();
	const [errmsg, seterrmsg] = useState("");
	const [openSnackBar, setOpenSnackbar] = useState(false);
	const [open, setOpen] = useState(false);
	const [isSubmitting, setisSubmitting] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};
	const handleSnackBar = () => {
		setOpenSnackbar(true);
	};

	const handleCloseSnackBar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpenSnackbar(false);
	};

	const history = useHistory();

	//q10 and q13b controlleer
	const [q10value, setq10value] = useState(true);
	const [q10valueb, setq10valueb] = useState(true);
	const [q10valuec, setq10valuec] = useState(true);
	const [q10valued, setq10valued] = useState(true);
	const q10Boxa = () => {
		//  setq10value(true)
		if (q10value === true) {
			setq10value(false);
		} else if (q10value === false) {
			setq10value(true);
		}
	};
	const q10Boxb = () => {
		//  setq10value(true)
		if (q10valueb === true) {
			setq10valueb(false);
		} else if (q10valueb === false) {
			setq10valueb(true);
		}
	};
	const q10Boxc = () => {
		//  setq10value(true)
		if (q10valuec === true) {
			setq10valuec(false);
		} else if (q10valuec === false) {
			setq10valuec(true);
		}
	};

	const q10Boxd = () => {
		//  setq10value(true)
		if (q10valued === true) {
			setq10valued(false);
		} else if (q10valued === false) {
			setq10valued(true);
		}
	};

	const getId = () => {
		const id = sessionStorage.getItem("id");
		return setid(id);
	};

	const getQ6b = () => {
		const value = profile.map((e) => e.q6b);
		//  //console.log(value);
		const a = "0-5 ans";
		const b = "6-17 ans";
		const c = "18-29 ans";
		const d = "30 - 49 ans";
		const e = "50-64 ans";
		const f = "65 ans et plus";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else if (value === "C") {
			return c;
		} else if (value === "D") {
			return d;
		} else if (value === "E") {
			return e;
		} else if (value === "F") {
			return f;
		} else {
			return "o data";
		}
	};

	const getQ7 = () => {
		const value = profile.map((e) => e.q7);
		////console.log(value);
		const a = "Féminin";
		const b = "Masculin";
		const c = "Autre";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else if (value === "C") {
			return c;
		} else {
			return "no data";
		}
	};

	const getq8a = () => {
		const value = profile.map((e) => e.q8a);
		// //console.log(value);
		const a = "Célibataire";
		const b = "Union de fait (vivre ensemble sans être mariés)";
		const c = "Marié(e)";
		const d = "Séparé(e)";
		const e = "Divorcé(e)";
		const f = "Veuve / Veuf";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else if (value === "C") {
			return c;
		} else if (value === "D") {
			return d;
		} else if (value === "E") {
			return e;
		} else if (value === "F") {
			return f;
		} else {
			return "no data";
		}
	};

	const getQ8b = () => {
		const value = profile.map((e) => e.q8b);
		// //console.log(value);
		const a = "Oui";
		const b = "Non";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else {
			return "o data";
		}
	};

	const getQ8c = () => {
		const value = profile.map((e) => e.q8c);
		// //console.log(value);
		const a = "Oui";
		const b = "Non";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else {
			return "o data";
		}
	};

	const getQ8d = () => {
		const value = profile.map((e) => e.q8d);
		//  //console.log(value);
		const a = "Oui";
		const b = "Non";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else {
			return "o data";
		}
	};

	const getQ8e = () => {
		const value = profile.map((e) => e.q8e);
		// //console.log(value);
		const a = "Oui";
		const b = "Non";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else {
			return "o data";
		}
	};

	const getQ8f = () => {
		const value = profile.map((e) => e.q8f);
		////console.log(value);
		const a = "Oui";
		const b = "Non";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else {
			return "No data";
		}
	};

	const getQ12b = () => {
		const value = info.map((e) => e.q12b);
		// //console.log(value);
		const a = "Milieu urbain";
		const b = "Milieu semi-urbain";
		const c = "Milieu rural";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else if (value === "C") {
			return c;
		} else {
			return "o data";
		}
	};

	const getQ16oui = () => {
		const value = needAndCare.map((e) => e.q16a);
		// //console.log(value);
		const a = "Oui";
		const b = "Non";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else {
			return "no data";
		}
	};

	const getQ16 = () => {
		const value = needAndCare.map((e) => e.q16);
		// //console.log(value);
		const a = "Oui";
		const b = "Non";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else {
			return "no data";
		}
	};
	const getQ17 = () => {
		const value = needAndCare.map((e) => e.q17);
		// //console.log(value);
		const a = "Oui";
		const b = "Non";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else {
			return "no data";
		}
	};

	const getQ18d = () => {
		const value = needAndCare.map((e) => e.q18d);
		// //console.log(value);
		const a = "Oui";
		const b = "Non";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else {
			return "o data";
		}
	};

	const getQ19 = () => {
		const value = needAndCare.map((e) => e.q19);
		// //console.log(value);
		const a = "Oui";
		const b = "Non";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else {
			return "no data";
		}
	};

	const getQ23 = () => {
		const value = needAndCare.map((e) => e.q23);
		// //console.log(value);
		const a = "Oui";
		const b = "Non";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else {
			return "no data";
		}
	};

	const getQ24 = () => {
		const value = needAndCare.map((e) => e.q24);
		// //console.log(value);
		const a = "Non, la victime cherche à rester chez elle";
		const b = "Oui, la victime cherche à quitter son foyer de façon temporaire";
		const c = "Oui, la victime cherche à quitter son foyer de façon permanente";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else if (value === "C") {
			return c;
		} else {
			return "o data";
		}
	};

	const getQ24a = () => {
		const value = needAndCare.map((e) => e.q24);
		// //console.log(value);
		const a = "Oui";
		const b = "Non";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else {
			return "no data";
		}
	};

	const getQ25 = () => {
		const value = needAndCare.map((e) => e.q25);
		// //console.log(value);
		const a = "Oui";
		const b = "Non";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else {
			return "no data";
		}
	};

	const getQ27 = () => {
		const value = needAndCare.map((e) => e.q27);
		// //console.log(value);
		const a =
			"Avec localisation (province et territoire ou ville provinciale) et âge";
		const b = "Complétement anonyme";

		if (value === "A") {
			return a;
		} else if (value === "B") {
			return b;
		} else {
			return "o data";
		}
	};

	const getOrganization = () => {
		const tkn = sessionStorage.getItem("org");
		return setorganization(tkn);
	};

	const getOrg = () => {
		const data = sessionStorage.getItem("organization");
		// //console.log('data from local storage',JSON.parse(data));
		let org = JSON.parse(data);
		return setorg(org);
		////console.log(org.q3a);
	};

	const getPofile = () => {
		const data = sessionStorage.getItem("profile");
		////console.log('data from local storage',JSON.parse(data));
		return setprofile(JSON.parse(data));
	};

	const getInfo = () => {
		const data = sessionStorage.getItem("info");
		////console.log('data from local storage',JSON.parse(data));
		return setinfo(JSON.parse(data));
	};

	const getPerpetor = () => {
		const data = sessionStorage.getItem("perpetor");
		// //console.log('data from local storage',JSON.parse(data));
		return setperpetor(JSON.parse(data));
	};

	const getNeedAndCare = () => {
		const data = sessionStorage.getItem("need");
		//  //console.log('data from local storage',JSON.parse(data));
		return setneedAndCare(JSON.parse(data));
	};

	const getToken = () => {
		const tkn = sessionStorage.getItem("token");
		//const id=sessionStorage.getItem('id')

		settoken(tkn);
	};

	const submitRevoir = () => {
		setisSubmitting(true);

		axiosInstance
			.post(
				"addcase/",
				// url,
				{
					question_1: id,
					question_2: quest2.toString(),
					question_3: quest3.toString(),
					question_4: quest4.toString(),
					question_5: quest5.toString().toUpperCase(),
					question_6_a: quest6a.toString(),
					question_6_b: quest6b.toString(),
					question_7: quest7.toString(),
					question_8_a: quest8a.toString(),
					question_8_b: quest8b.toString(),
					question_8_c: quest8c.toString(),
					question_8_d: quest8d.toString(),
					question_8_e: quest8e.toString(),
					question_8_f: quest8f.toString(),
					question_9: quest9.toString(),
					question_10_a: quest10a,
					question_10_b: quest10b,
					question_10_c: quest10c,
					question_10_d: quest10d,
					question_11_a: quest11a.toString(),
					question_11_a_oui: quest11aOui.toString(),
					question_11_a_oui_month: quest11aOuiMonth.toString(),
					question_11_b: quest11b.toString(),
					question_11_b_oui: quest11boui.toString(),
					question_11_b_oui_month: quest11bouiMonth.toString(),
					question_12_a: quest12a.toString(),
					question_12_b: quest12b.toString(),
					question_13_a: quest13a.toString(),
					question_13_b_a: quest13bA,
					question_13_b_b: quest13bB,
					question_13_b_c: quest13bC,
					question_13_b_d: quest13bD,
					question_14_a: quest14a.toString(),
					question_14_a_oui: quest14aOui,
					question_14_b: quest14b.toString(),
					question_14_b_oui: quest14bOui,
					question_14_c: quest14c,
					question_15: quest15.toString(),
					question_15_oui: quest15Oui,
					question_15_oui_autre: quest15autre.toString(),
					question_16: quest16.toString(),
					question_16_oui: quest16oui.toString(),
					question_17: quest17.toString(),
					question_18: quest18.toString(),
					question_18_a: quest18a,
					question_18_a_autre: quest18autre.toString(),
					question_18_b: quest18b.toString(),
					question_18_c: quest18c.toString(),
					question_19: quest19.toString(),
					question_19_oui: quest19Oui,
					question_19_autre: quest19autre.toString(),
					question_20: quest20,
					question_20_autre: quest20autre.toString(),
					question_21: quest21,
					question_21_autre: qust21autre.toString(),
					question_22: quest22,
					question_22_autre: qust22autre.toString(),
					question_23: quest23.toString(),
					question_24: quest24.toString(),
					question_24_a: quest24a.toString(),
					question_25: quest25.toString(),
					question_26: quest26.toString(),
					question_27: quest27.toString(),
					approve: approve,
					city: quest12a.toString(),
					province: q12province.toString(),
					lat: parseFloat(lat),
					lon: parseFloat(long),
					organization: organization,
				}
			)
			.then((res) => {
				//const data = res.data
				//console.log(data.success);
				history.push(
					`/${process.env.REACT_APP_COLLECT_LINK}/collect/main/submit`
				);
			})
			.catch((err) => {
				history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/main`);
				setisSubmitting(false);

				if (err.response) {
					seterrmsg(
						"Vous n'êtes pas autorisé à effectuer cette action, veuillez contacter l'administrateur!"
					);
					handleSnackBar();
					//console.log(err.response);
				} else if (err.request) {
					seterrmsg(
						"Votre demande n'a pas pu atteindre le serveur, veuillez vérifier votre connexion Internet!"
					);
					handleSnackBar();

					//console.log("non response");
				} else {
					//console.log("no response");
				}
			});
	};

	useEffect(() => {
		getToken();
		getOrganization();
		getOrg();
		getId();
		getPofile();
		getInfo();
		getPerpetor();
		getNeedAndCare();
		setq11avalue(info.map((e) => e.q11a));
		setq11bvalue(info.map((e) => e.q11b));
		setq14avalue(perpetor.map((e) => e.q14a));
		setq14bvalue(perpetor.map((e) => e.q14b));
		setq15value(perpetor.map((e) => e.q15));
		setq16value(needAndCare.map((e) => e.q16));
		setq18value(needAndCare.map((e) => e.q18));
		setq19value(needAndCare.map((e) => e.q19));
		setq20value(needAndCare.map((e) => e.q20));
		setq21value(needAndCare.map((e) => e.q21));
		setq22value(needAndCare.map((e) => e.q22oui));
		setq25value(needAndCare.map((e) => e.q25));
		setq24value(needAndCare.map((e) => e.q24));
		setquest2(org.map((e) => e.q2));
		setquest3(org.map((e) => e.q3ab));
		setquest4(org.map((e) => e.q4));
		setquest5(profile.map((e) => e.q5));
		setquest6a(profile.map((e) => e.q6a));
		setquest6b(profile.map((e) => e.q6b));
		setquest7(profile.map((e) => e.q7));
		setquest8a(profile.map((e) => e.q8a));
		setquest8b(profile.map((e) => e.q8b));
		setquest8c(profile.map((e) => e.q8c));
		setquest8d(profile.map((e) => e.q8d));
		setquest8e(profile.map((e) => e.q8e));
		setquest8f(profile.map((e) => e.q8f));
		setquest9(info.map((e) => e.q9));
		setquest10a(info.map((e) => e.q10ap));
		setquest10b(info.map((e) => e.q10bp));
		setquest10c(info.map((e) => e.q10cp));
		setquest10d(info.map((e) => e.q10dp));
		setquest11a(info.map((e) => e.q11a));
		setquest11aOui(info.map((e) => e.q11aYear));
		setquest11aOuiMonth(info.map((e) => e.q11aMonth));
		setquest11b(info.map((e) => e.q11b));
		setquest11boui(info.map((e) => e.q11bYear));
		setquest11bouiMonth(info.map((e) => e.q11bMonth));
		setquest12a(info.map((e) => e.q12a));
		setquest12b(info.map((e) => e.q12b));
		setlat(info.map((e) => e.lat));
		setlong(info.map((e) => e.long));
		setq12province(info.map((e) => e.q12province));
		setquest13a(info.map((e) => e.q13a));
		setquest13bA(info.map((e) => e.q13bAp));
		setquest13bB(info.map((e) => e.q13bBp));
		setquest13bC(info.map((e) => e.q13bCp));
		setquest13bD(info.map((e) => e.q13bDp));
		setquest14a(perpetor.map((e) => e.q14a));
		setquest14aOui(perpetor.map((e) => e.q14aOuip));
		setquest14b(perpetor.map((e) => e.q14b));
		setquest14bOui(perpetor.map((e) => e.q14bOuip));
		setquest14c(perpetor.map((e) => e.q14cp));
		setquest15(perpetor.map((e) => e.q15));
		setquest15Oui(perpetor.map((e) => e.q15Ouip));
		setquest15autre(perpetor.map((e) => e.q15autre));
		setquest16(needAndCare.map((e) => e.q16));
		setquest16oui(needAndCare.map((e) => e.q16a));
		setquest17(needAndCare.map((e) => e.q17));
		setquest18(needAndCare.map((e) => e.q18));
		setquest18a(needAndCare.map((e) => e.q18ap));
		setquest18autre(needAndCare.map((e) => e.q18autre));
		setquest18b(needAndCare.map((e) => e.q18b));
		setquest18c(needAndCare.map((e) => e.q18c));
		setquest19Oui(needAndCare.map((e) => e.q19ouip));
		setquest19(needAndCare.map((e) => e.q19));
		setquest19autre(needAndCare.map((e) => e.q19autre));
		setquest20(needAndCare.map((e) => e.q20p));
		setquest20autre(needAndCare.map((e) => e.q20autre));
		setquest21(needAndCare.map((e) => e.q21p));
		setqust21autre(needAndCare.map((e) => e.q21autre));
		setquest22(needAndCare.map((e) => e.q22ouip));
		setqust22autre(needAndCare.map((e) => e.q22autre));
		setquest23(needAndCare.map((e) => e.q23));
		setquest24(needAndCare.map((e) => e.q24));
		setquest24a(needAndCare.map((e) => e.q24a));
		setquest25(needAndCare.map((e) => e.q25));
		setquest26(needAndCare.map((e) => e.q26));
		setquest27(needAndCare.map((e) => e.q27));

		// //console.log(perpetor.map(e=>e.q14aOui[1]));
		//setq14aOui(perpetor.map(e=>e.q14aOui))
	}, [organization, id, token, info, needAndCare, org, perpetor, profile]); // Just added info, needAndCare, org, perpetor, profile and removed axiosInstance #Joe
	//console.log('Organisation',org);
	//console.log('profile',profile);
	//console.log('info',info);
	//console.log('perpetor',perpetor);
	//console.log('need and care',needAndCare);
	// console.log(q18value);
	// //console.log(token);
	return (
		<div>
			<Grid container justifyContent="center" spacing={3}>
				<Grid item xs={11} sm={8}>
					<Typography xs={11} sm={8} className={classes.bigtitle} align="left">
						{reviewbefore.toUpperCase()}
					</Typography>
				</Grid>
			</Grid>
			<Grid container justifyContent="center" spacing={3}>
				<Grid item className={classes.content} xs={11} sm={8}>
					<Grid container direction="row">
						<Grid item sm={1} xs={0}></Grid>
						<Grid item sm={8} xs={11} className={classes.margin_Top_30}>
							{/* from question 1 up to 25 */}
							<Grid container spacing={3}>
								<Grid item sm={11} xs={12}>
									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>1. {q1}</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer}>
												{organization}
											</Typography>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q2}</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer}>
												{org.map((e) => e.q2)}
											</Typography>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q3}</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer}>
												{org.map((e) => e.q3a + "/\t\t" + e.q3ab)}
											</Typography>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q4}</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer}>
												{org.map((e) => e.q4)}
											</Typography>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q5}</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer}>
												{profile.map((e) => e.q5)}
											</Typography>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q6}</Typography>
										</Grid>
										{/* <Grid item sm= {12} xs={12}><Typography className={classes.answer}>Lorem</Typography></Grid> */}
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.sublabel}>
												{q6a}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer}>
												{profile.map((e) => e.q6a)}{" "}
											</Typography>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.sublabel}>
												{q6b}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer}>
												{getQ6b()}
											</Typography>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q7}</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer}>
												{getQ7()}
											</Typography>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q8}</Typography>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.sublabel}>
												{q8a}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer}>
												{getq8a()}
											</Typography>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.sublabel}>
												{q8b}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.answer}
												>
													<FormControlLabel
														control={<Radio size="small" checked />}
														label={
															<Box component="div" fontSize={13}>
																{getQ8b()}
															</Box>
														}
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.sublabel}>
												{q8c}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.answer}
												>
													<FormControlLabel
														control={<Radio size="small" checked />}
														label={
															<Box component="div" fontSize={13}>
																{getQ8c()}
															</Box>
														}
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.sublabel}>
												{q8d}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.answer}
												>
													<FormControlLabel
														control={<Radio size="small" checked />}
														label={
															<Box component="div" fontSize={13}>
																{getQ8d()}
															</Box>
														}
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.sublabel}>
												{q8e}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.answer}
												>
													<FormControlLabel
														control={<Radio size="small" checked />}
														label={
															<Box component="div" fontSize={13}>
																{getQ8e()}
															</Box>
														}
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.sublabel}>
												{q8f}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.answer}
												>
													<FormControlLabel
														control={<Radio size="small" checked />}
														label={
															<Box component="div" fontSize={13}>
																{getQ8f()}
															</Box>
														}
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q9}</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<TextareaAutosize
												defaultValue={info.map((e) => e.q9)}
												// multiline
												variant="outlined"
												disabled
												rows={14}
												className={classes.textarea}
												aria-label="maximum height"
												//placeholder="Il s’agit d’une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé."
											/>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q10}</Typography>
										</Grid>
										<Grid container>
											<Grid item xs={12} sm={12}>
												<Button
													onClick={q10Boxa}
													variant="text"
													color="default"
													className={classes.button}
													startIcon={
														q10value === false ? (
															<ArrowRightIcon style={{ fontSize: 30 }} />
														) : (
															<ArrowDropDownIcon style={{ fontSize: 30 }} />
														)
													}
												>
													Incidents de violence sexuelle
												</Button>
												{q10value === true ? (
													<List>
														<ListItem>
															<FormGroup>
																{info.map((e) => e.q10a[0]) === "A" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Viol"
																	/>
																) : null}
																{info.map((e) => e.q10a[1]) === "B" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Viol conjugal entre couples mariés et non mariés"
																	/>
																) : null}
																{info.map((e) => e.q10a[2]) === "C" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Agression sexuelle sur mineur"
																	/>
																) : null}
																{info.map((e) => e.q10a[3]) === "D" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Coercition sexuelle"
																	/>
																) : null}
																{info.map((e) => e.q10a[4]) === "E" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Harcèlement sexuel"
																	/>
																) : null}
																{info.map((e) => e.q10a[5]) === "F" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Attouchements inappropriés"
																	/>
																) : null}
																{info.map((e) => e.q10a[6]) === "G" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Etre forcé(e) à avoir des relations sexuelles sans préservatif"
																	/>
																) : null}
																{info.map((e) => e.q10a[7]) === "H" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Etre empêché(e) de faire ses propres choix quant à la décision d'avoir ou non un bébé"
																	/>
																) : null}
																{info.map((e) => e.q10a[8]) === "I" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Autres"
																	/>
																) : null}
															</FormGroup>
														</ListItem>
													</List>
												) : null}
											</Grid>
											<Grid item xs={12} sm={12}>
												<Button
													onClick={q10Boxb}
													variant="text"
													color="default"
													className={classes.button}
													startIcon={
														q10valueb === false ? (
															<ArrowRightIcon style={{ fontSize: 30 }} />
														) : (
															<ArrowDropDownIcon style={{ fontSize: 30 }} />
														)
													}
												>
													Incidents de violence physique (autre que violence
													sexuelle)
												</Button>
												{q10valueb === true ? (
													<List>
														<ListItem>
															<FormGroup>
																{info.map((e) => e.q10b[0]) === "A" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Meurtre / féminicide"
																	/>
																) : null}
																{info.map((e) => e.q10b[1]) === "B" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Coups de poing"
																	/>
																) : null}
																{info.map((e) => e.q10b[2]) === "C" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Coups de pied"
																	/>
																) : null}
																{info.map((e) => e.q10b[3]) === "D" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Attaque à l’acide"
																	/>
																) : null}
																{info.map((e) => e.q10b[4]) === "E" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Attaque au couteau ou à la machette"
																	/>
																) : null}
																{info.map((e) => e.q10b[5]) === "F" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Attaque à l’eau ou l’huile chaud"
																	/>
																) : null}
																{info.map((e) => e.q10b[6]) === "G" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Privation de la liberté physique"
																	/>
																) : null}
																{info.map((e) => e.q10b[7]) === "H" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Privation de nourriture"
																	/>
																) : null}
																{info.map((e) => e.q10b[8]) === "I" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Négligence physique d’enfant"
																	/>
																) : null}
																{info.map((e) => e.q10b[9]) === "J" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Négligence physique de personnes âgées"
																	/>
																) : null}
																{info.map((e) => e.q10b[10]) === "K" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Destruction d’objets dans la maison"
																	/>
																) : null}
																{info.map((e) => e.q10b[11]) === "L" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Rites de veuvage affectant la santé physique et mentale de la victime"
																	/>
																) : null}
																{info.map((e) => e.q10b[12]) === "M" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Autres"
																	/>
																) : null}
															</FormGroup>
														</ListItem>
													</List>
												) : null}
											</Grid>
											<Grid item xs={12} sm={12}>
												<Button
													onClick={q10Boxc}
													variant="text"
													color="default"
													className={classes.button}
													startIcon={
														q10valuec === false ? (
															<ArrowRightIcon style={{ fontSize: 30 }} />
														) : (
															<ArrowDropDownIcon style={{ fontSize: 30 }} />
														)
													}
												>
													Incidents de violence psychologique
												</Button>
												{q10valuec === true ? (
													<List>
														<ListItem>
															<FormGroup>
																{info.map((e) => e.q10c[0]) === "A" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Menaces"
																	/>
																) : null}
																{info.map((e) => e.q10c[1]) === "B" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Injures"
																	/>
																) : null}
																{info.map((e) => e.q10c[2]) === "C" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Négligence émotionnelle"
																	/>
																) : null}
																{info.map((e) => e.q10c[3]) === "D" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Humiliation face aux autres"
																	/>
																) : null}
																{info.map((e) => e.q10c[4]) === "E" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Attaques visant à nuire à la réputation de la victime"
																	/>
																) : null}
																{info.map((e) => e.q10c[5]) === "F" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Isolement de la famille ou des amis"
																	/>
																) : null}
																{info.map((e) => e.q10c[6]) === "G" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Contrôle des mouvements de la victime"
																	/>
																) : null}
																{info.map((e) => e.q10c[7]) === "H" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Contrôle de la communication de la victime"
																	/>
																) : null}
																{info.map((e) => e.q10c[8]) === "I" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Refus de divorce"
																	/>
																) : null}
																{info.map((e) => e.q10c[9]) === "J" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Abus spirituel / abus religieux"
																	/>
																) : null}
																{info.map((e) => e.q10c[10]) === "K" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Autres"
																	/>
																) : null}
															</FormGroup>
														</ListItem>
													</List>
												) : null}
											</Grid>
											<Grid item xs={12} sm={12}>
												<Button
													onClick={q10Boxd}
													variant="text"
													color="default"
													className={classes.button}
													startIcon={
														q10valued === false ? (
															<ArrowRightIcon style={{ fontSize: 30 }} />
														) : (
															<ArrowDropDownIcon style={{ fontSize: 30 }} />
														)
													}
												>
													Incidents de violence économique et de déni
													d’opportunités
												</Button>
												{q10valued === true ? (
													<List>
														<ListItem>
															<FormGroup>
																{info.map((e) => e.q10d[0]) === "A" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Abandon de la famille"
																	/>
																) : null}
																{info.map((e) => e.q10d[1]) === "B" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Confiscation des revenus de la victime"
																	/>
																) : null}
																{info.map((e) => e.q10d[2]) === "C" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Interdiction de participation à la gestion des revenus du ménage"
																	/>
																) : null}
																{info.map((e) => e.q10d[3]) === "D" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Restriction de l’accès à l'éducation"
																	/>
																) : null}
																{info.map((e) => e.q10d[4]) === "E" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Restriction de l’accès à l'emploi"
																	/>
																) : null}
																{info.map((e) => e.q10d[5]) === "F" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Restriction de l’accès aux ressources économiques"
																	/>
																) : null}
																{info.map((e) => e.q10d[6]) === "G" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Privation d’une prise en charge médicale, de médicaments ou d'appareils d’assistance"
																	/>
																) : null}
																{info.map((e) => e.q10d[7]) === "H" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Lévirat après le mort du conjoint"
																	/>
																) : null}
																{info.map((e) => e.q10d[8]) === "I" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Perte des propriétés et des biens / non accès à l’héritage après le mort du conjoint ou d’un parent"
																	/>
																) : null}
																{info.map((e) => e.q10d[9]) === "J" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Perte des enfants après la mort du conjoint"
																	/>
																) : null}
																{info.map((e) => e.q10d[10]) === "K" ? (
																	<FormControlLabel
																		control={<Checkbox checked={true} />}
																		label="Autres"
																	/>
																) : null}
															</FormGroup>
														</ListItem>
													</List>
												) : null}
											</Grid>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid
											item
											sm={12}
											xs={12}
											className={classes.margin_Bottom_10}
										>
											<Typography className={classes.label}>{q11}</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Grid container spacing={1}>
												<Grid item sm={12} xs={12}>
													<Typography className={classes.sublabel}>
														{q11a}
													</Typography>
												</Grid>
												<Grid item sm={12} xs={12}>
													<FormControl component="fieldset">
														<RadioGroup
															name="occupation"
															className={classes.answer}
														>
															{q11avalue !== "B" ? (
																<div>
																	<FormControlLabel
																		control={<Radio size="small" />}
																		label={
																			<Box component="div" fontSize={13}>
																				Oui
																			</Box>
																		}
																	/>
																	<Typography className={classes.subradio}>
																		Si oui : Quand cette situation a-t-elle
																		commencé ?
																	</Typography>
																	<small>Le mois</small>
																	<Typography>
																		{info.map((e) => e.q11aMonth)}
																	</Typography>
																	<small>L'année</small>
																	<Typography>
																		{info.map((e) => e.q11aYear)}
																	</Typography>
																</div>
															) : (
																<FormControlLabel
																	control={<Radio size="small" />}
																	label={
																		<Box component="div" fontSize={13}>
																			Non
																		</Box>
																	}
																/>
															)}
														</RadioGroup>
													</FormControl>
												</Grid>
											</Grid>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.sublabel}>
												{q11b}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.answer}
												>
													{q11bvalue !== "B" ? (
														<div>
															<FormControlLabel
																control={<Radio size="small" />}
																label={
																	<Box component="div" fontSize={13}>
																		Oui
																	</Box>
																}
															/>
															<Typography className={classes.labelsubradio}>
																Si oui : Quand cette situation a-t-elle commencé
																?
															</Typography>
															<small>Le mois</small>
															<Typography>
																{info.map((e) => e.q11bMonth)}
															</Typography>
															<small>L'année</small>
															<Typography>
																{info.map((e) => e.q11bYear)}
															</Typography>
														</div>
													) : (
														<FormControlLabel
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Non
																</Box>
															}
														/>
													)}
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q12}</Typography>
										</Grid>
										{/* <Grid item sm= {12} xs={12}><Typography className={classes.answer}>Lorem</Typography></Grid> */}
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.sublabel}>
												{q12a}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer}>
												{info.map((e) => e.q12province)} /{" "}
												{info.map((e) => e.q12a)}
											</Typography>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.sublabel}>
												{q12b}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.answer}>
												{getQ12b()}
											</Typography>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q13}</Typography>
										</Grid>
										<Grid container spacing={1}>
											<Grid item sm={12} xs={12}>
												<Typography className={classes.sublabel}>
													{q13a}
												</Typography>
											</Grid>
											<Grid item sm={12} xs={12}>
												<TextareaAutosize
													defaultValue={info.map((e) => e.q13a)}
													// multiline
													variant="outlined"
													disabled
													rows={14}
													className={classes.textarea}
													aria-label="maximum height"
													//placeholder="Il s’agit d’une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé."
												/>
											</Grid>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.sublabel}>
												{q13b}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Button
												onClick={q10Boxa}
												variant="text"
												color="default"
												className={classes.button}
												startIcon={
													q10value === false ? (
														<ArrowRightIcon style={{ fontSize: 30 }} />
													) : (
														<ArrowDropDownIcon style={{ fontSize: 30 }} />
													)
												}
											>
												Préjudice physique
											</Button>
											{q10value === true ? (
												<List>
													<ListItem>
														<FormGroup>
															{info.map((e) => e.q13bA[0]) === "A" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Décès"
																/>
															) : null}
															{info.map((e) => e.q13bA[1]) === "B" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Maladies (par exemple hypertension)"
																/>
															) : null}
															{info.map((e) => e.q13bA[2]) === "C" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Blessures"
																/>
															) : null}
															{info.map((e) => e.q13bA[3]) === "D" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Douleurs"
																/>
															) : null}
															{info.map((e) => e.q13bA[4]) === "E" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Troubles gynécologiques et reproductifs"
																/>
															) : null}
															{info.map((e) => e.q13bA[5]) === "F" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Invalidité / Handicap"
																/>
															) : null}
															{info.map((e) => e.q13bA[6]) === "G" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Grossesse non voulue"
																/>
															) : null}
															{info.map((e) => e.q13bA[7]) === "H" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Perte de l'enfant à naître"
																/>
															) : null}
															{info.map((e) => e.q13bA[8]) === "I" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Abus d'alcool"
																/>
															) : null}
															{info.map((e) => e.q13bA[9]) === "J" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Abus de drogues"
																/>
															) : null}
															{info.map((e) => e.q13bA[10]) === "K" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Abus de médicaments"
																/>
															) : null}
															{info.map((e) => e.q13bA[11]) === "L" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Autres"
																/>
															) : null}
														</FormGroup>
													</ListItem>
												</List>
											) : null}
										</Grid>
										<Grid item xs={12} sm={12}>
											<Button
												onClick={q10Boxb}
												variant="text"
												color="default"
												className={classes.button}
												startIcon={
													q10valueb === false ? (
														<ArrowRightIcon style={{ fontSize: 30 }} />
													) : (
														<ArrowDropDownIcon style={{ fontSize: 30 }} />
													)
												}
											>
												Préjudice émotionnel
											</Button>
											{q10valueb === true ? (
												<List>
													<ListItem>
														<FormGroup>
															{info.map((e) => e.q13bB[0]) === "A" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Risque de suicide"
																/>
															) : null}
															{info.map((e) => e.q13bB[1]) === "B" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Peur et anxiété"
																/>
															) : null}
															{info.map((e) => e.q13bB[2]) === "C" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Dépression"
																/>
															) : null}
															{info.map((e) => e.q13bB[3]) === "D" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Détresse émotionnelle"
																/>
															) : null}
															{info.map((e) => e.q13bB[4]) === "E" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Sentiment de honte"
																/>
															) : null}
															{info.map((e) => e.q13bB[5]) === "F" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Sentiment de colère"
																/>
															) : null}
															{info.map((e) => e.q13bB[6]) === "G" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Troubles de sommeil"
																/>
															) : null}
															{info.map((e) => e.q13bB[7]) === "H" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Troubles alimentaires"
																/>
															) : null}
															{info.map((e) => e.q13bB[8]) === "I" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Perte d’espoir"
																/>
															) : null}
															{info.map((e) => e.q13bB[9]) === "J" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Manque de confiance en soi"
																/>
															) : null}
															{info.map((e) => e.q13bB[10]) === "K" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Autres"
																/>
															) : null}
														</FormGroup>
													</ListItem>
												</List>
											) : null}
										</Grid>
										<Grid item xs={12} sm={12}>
											<Button
												onClick={q10Boxc}
												variant="text"
												color="default"
												className={classes.button}
												startIcon={
													q10valuec === false ? (
														<ArrowRightIcon style={{ fontSize: 30 }} />
													) : (
														<ArrowDropDownIcon style={{ fontSize: 30 }} />
													)
												}
											>
												Préjudice économique
											</Button>
											{q10valuec === true ? (
												<List>
													<ListItem>
														<FormGroup>
															{info.map((e) => e.q13bC[0]) === "A" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Perte des moyens de subsistance"
																/>
															) : null}
															{info.map((e) => e.q13bC[1]) === "B" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Perte de revenues"
																/>
															) : null}
															{info.map((e) => e.q13bC[2]) === "C" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Sexe de survie"
																/>
															) : null}
															{info.map((e) => e.q13bC[3]) === "D" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Perte de maison"
																/>
															) : null}
															{info.map((e) => e.q13bC[4]) === "E" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Déménagements fréquents"
																/>
															) : null}
															{info.map((e) => e.q13bC[5]) === "F" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Situation de sans-abri"
																/>
															) : null}
															{info.map((e) => e.q13bC[6]) === "G" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Pas ou peu d'éducation ou de formation"
																/>
															) : null}
															{info.map((e) => e.q13bC[7]) === "H" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Autres"
																/>
															) : null}
														</FormGroup>
													</ListItem>
												</List>
											) : null}
										</Grid>
										<Grid item xs={12} sm={12}>
											<Button
												onClick={q10Boxd}
												variant="text"
												color="default"
												className={classes.button}
												startIcon={
													q10valued === false ? (
														<ArrowRightIcon style={{ fontSize: 30 }} />
													) : (
														<ArrowDropDownIcon style={{ fontSize: 30 }} />
													)
												}
											>
												Impact sur les relations
											</Button>
											{q10valued === true ? (
												<List>
													<ListItem>
														<FormGroup>
															{info.map((e) => e.q13bD[0]) === "A" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Rupture de la famille"
																/>
															) : null}
															{info.map((e) => e.q13bD[1]) === "B" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Conflits domestiques réguliers"
																/>
															) : null}
															{info.map((e) => e.q13bD[2]) === "C" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Abandon par la famille"
																/>
															) : null}
															{info.map((e) => e.q13bD[3]) === "D" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Stigmatisation par la communauté"
																/>
															) : null}
															{info.map((e) => e.q13bD[4]) === "E" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Perte de la garde ou des contacts avec les enfants"
																/>
															) : null}
															{info.map((e) => e.q13bD[5]) === "F" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Isolement de la victime"
																/>
															) : null}
															{info.map((e) => e.q13bD[6]) === "H" ? (
																<FormControlLabel
																	control={<Checkbox checked={true} />}
																	label="Autres"
																/>
															) : null}
														</FormGroup>
													</ListItem>
												</List>
											) : null}
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_10}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q14}</Typography>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.sublabel}>
												{q14a}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.answer}
												>
													{q14avalue === "A" ? (
														<FormControlLabel
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Oui
																</Box>
															}
														/>
													) : (
														<FormControlLabel
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Non
																</Box>
															}
														/>
													)}
												</RadioGroup>
											</FormControl>
											{q14avalue === "A" ? (
												<FormGroup className={classes.answer}>
													{perpetor.map((e) => e.q14aOui[0]) === "A" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Mère"
														/>
													) : null}
													{perpetor.map((e) => e.q14aOui[1]) === "B" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Père"
														/>
													) : null}
													{perpetor.map((e) => e.q14aOui[2]) === "C" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Tuteur / gardien (homme)"
														/>
													) : null}

													{perpetor.map((e) => e.q14aOui[3]) === "D" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Tuteur / gardienne (femme)"
														/>
													) : null}

													{perpetor.map((e) => e.q14aOui[4]) === "E" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Propres frères"
														/>
													) : null}
													{perpetor.map((e) => e.q14aOui[5]) === "F" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Propres sœurs"
														/>
													) : null}
													{perpetor.map((e) => e.q14aOui[6]) === "G" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Propres enfants adultes"
														/>
													) : null}
													{perpetor.map((e) => e.q14aOui[7]) === "H" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name=" " />}
															label="Grand-mere"
														/>
													) : null}
													{perpetor.map((e) => e.q14aOui[8]) === "I" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name=" " />}
															label="Grand-père"
														/>
													) : null}
													{perpetor.map((e) => e.q14aOui[9]) === "J" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name=" " />}
															label="Belle-mère"
														/>
													) : null}
													{perpetor.map((e) => e.q14aOui[10]) === "K" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name=" " />}
															label="Bel-père"
														/>
													) : null}
													{perpetor.map((e) => e.q14aOui[11]) === "L" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name=" " />}
															label="Beaux-frères"
														/>
													) : null}
													{perpetor.map((e) => e.q14aOui[12]) === "M" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name=" " />}
															label="Belles-sœurs"
														/>
													) : null}
													{perpetor.map((e) => e.q14aOui[13]) === "N" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name=" " />}
															label="Oncles"
														/>
													) : null}
													{perpetor.map((e) => e.q14aOui[14]) === "O" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name=" " />}
															label="Tantes"
														/>
													) : null}
													{perpetor.map((e) => e.q14aOui[15]) === "P" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name=" " />}
															label="Autre(s) membre(s) de la famille de la victime"
														/>
													) : null}
												</FormGroup>
											) : null}
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.sublabel}>
												{q14b}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.answer}
												>
													{q14bvalue === "A" ? (
														<FormControlLabel
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	{" "}
																	Oui
																</Box>
															}
														/>
													) : (
														<FormControlLabel
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Non
																</Box>
															}
														/>
													)}
												</RadioGroup>
											</FormControl>
											{q14bvalue === "A" ? (
												<FormGroup className={classes.answer}>
													<Typography className={classes.sublabel}>
														Si oui, l’auteur de la violence domestique est …
													</Typography>

													{perpetor.map((e) => e.q14bOui[0]) === "A" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Mari"
														/>
													) : null}
													{perpetor.map((e) => e.q14bOui[1]) === "B" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Ex-mari"
														/>
													) : null}
													{perpetor.map((e) => e.q14bOui[2]) === "C" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Epouse"
														/>
													) : null}
													{perpetor.map((e) => e.q14bOui[3]) === "D" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Ex-épouse"
														/>
													) : null}
													{perpetor.map((e) => e.q14bOui[4]) === "E" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Partenaire intime actuel qui est homme"
														/>
													) : null}
													{perpetor.map((e) => e.q14bOui[5]) === "F" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Partenaire intime actuel qui est femme"
														/>
													) : null}
													{perpetor.map((e) => e.q14bOui[6]) === "G" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Ex-partenaire intime qui est homme"
														/>
													) : null}
													{perpetor.map((e) => e.q14bOui[7]) === "H" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name=" " />}
															label="Ex-partenaire intime qui est femme"
														/>
													) : null}
												</FormGroup>
											) : null}
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.sublabel}>
												{q14c}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormGroup className={classes.answer}>
												{perpetor.map((e) => e.q14c[0]) === "A" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Patron (homme) de personnel domestique"
													/>
												) : null}
												{perpetor.map((e) => e.q14c[1]) === "B" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Ex-Patronne (femme) de personnel domestique"
													/>
												) : null}
												{perpetor.map((e) => e.q14c[2]) === "C" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Personnel domestique (homme)"
													/>
												) : null}
												{perpetor.map((e) => e.q14c[3]) === "D" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Personnel domestique (femme)"
													/>
												) : null}
												{perpetor.map((e) => e.q14c[4]) === "E" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Bailleur / propriétaire de la maison (homme)"
													/>
												) : null}
												{perpetor.map((e) => e.q14c[5]) === "F" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Partenaire intime actuel qui est femme"
													/>
												) : null}
												{perpetor.map((e) => e.q14c[6]) === "G" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Bailleresse / propriétaire de la maison (femme)"
													/>
												) : null}
											</FormGroup>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q15}</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.answer}
												>
													{q15value === "A" ? (
														<FormControlLabel
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Oui
																</Box>
															}
														/>
													) : (
														<FormControlLabel
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Non
																</Box>
															}
														/>
													)}
												</RadioGroup>
											</FormControl>
											{q15value === "A" ? (
												<FormGroup className={classes.answer}>
													<Typography className={classes.subradio}>
														Si oui,
													</Typography>
													{perpetor.map((e) => e.q15Oui[0]) === "A" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Même ménage"
														/>
													) : null}
													{perpetor.map((e) => e.q15Oui[1]) === "B" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Même parcell"
														/>
													) : null}
													{perpetor.map((e) => e.q15Oui[2]) === "C" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Maison d’enfants"
														/>
													) : null}
													{perpetor.map((e) => e.q15Oui[3]) === "D" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Camp de réfugiés"
														/>
													) : null}
													{perpetor.map((e) => e.q15autre) === "" ? null : (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label={
																<TextField
																	required
																	disabled
																	defaultValue={perpetor.map((e) => e.q15autre)}
																	//label="Autre situation de vie  "
																	variant="outlined"
																	style={{ width: "100%" }}
																	size="small"
																	fullWidth
																/>
															}
														/>
													)}
												</FormGroup>
											) : null}
										</Grid>
									</Grid>
									{/* q16 and 17 */}
									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q16}</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormControl
												component="fieldset"
												className={classes.marginBottom_30}
											>
												<RadioGroup
													name="occupation"
													className={classes.answer}
												>
													<FormControlLabel
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																{getQ16()}
															</Box>
														}
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>

									{q16value === "A" ? (
										<Grid
											container
											spacing={1}
											className={classes.margin_Bottom_30}
										>
											<Grid item sm={12} xs={12}>
												<Typography className={classes.sublabel}>
													{q16oui}
												</Typography>
											</Grid>
											<Grid item sm={12} xs={12}>
												<FormControl component="fieldset">
													<RadioGroup
														name="occupation"
														className={classes.answer}
													>
														<FormControlLabel
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	{getQ16oui}
																</Box>
															}
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
									) : null}
									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.sublabel}>
												{q17}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.answer}
												>
													<FormControlLabel
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																{getQ17()}
															</Box>
														}
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q18}</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.answer}
												>
													{q18value === "A" ? (
														<FormControlLabel
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Oui
																</Box>
															}
														/>
													) : (
														<FormControlLabel
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Non
																</Box>
															}
														/>
													)}
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>

									{q18value === "A" ? (
										<div>
											<Grid
												container
												spacing={1}
												className={classes.margin_Bottom_30}
											>
												<Grid item sm={12} xs={12}>
													<Typography className={classes.sublabel}>
														{q18a}
													</Typography>
												</Grid>
												<Grid item sm={12} xs={12}>
													<FormGroup className={classes.answer}>
														{needAndCare.map((e) => e.q18a[0]) === "A" ? (
															<FormControlLabel
																control={<Checkbox checked={true} name="" />}
																label="Soins médicaux"
															/>
														) : null}
														{needAndCare.map((e) => e.q18a[1]) === "B" ? (
															<FormControlLabel
																control={<Checkbox checked={true} />}
																label="Assistance psychosociale"
															/>
														) : null}
														{needAndCare.map((e) => e.q18a[2]) === "C" ? (
															<FormControlLabel
																control={<Checkbox checked={true} name="" />}
																label="Assistance en médiation familial"
															/>
														) : null}
														{needAndCare.map((e) => e.q18a[3]) === "D" ? (
															<FormControlLabel
																control={<Checkbox checked={true} name="" />}
																label="Assistance juridique"
															/>
														) : null}
														{needAndCare.map((e) => e.q18a[4]) === "E" ? (
															<FormControlLabel
																control={<Checkbox checked={true} name="" />}
																label="Dénonciation du cas auprès une autorité judiciaire avec suite"
															/>
														) : null}
														{needAndCare.map((e) => e.q18a[5]) === "F" ? (
															<FormControlLabel
																control={<Checkbox checked={true} name="" />}
																label="Dénonciation du cas auprès une autorité judiciaire sans suite"
															/>
														) : null}
														{needAndCare.map((e) => e.q18a[6]) === "G" ? (
															<FormControlLabel
																control={<Checkbox checked={true} name="" />}
																label="Assistance socio-économique"
															/>
														) : null}
														{needAndCare.map((e) => e.q18a[7]) === "H" ? (
															<FormControlLabel
																control={<Checkbox checked={true} name=" " />}
																label="Développement d’un plan de sécurité pour la victime de VD"
															/>
														) : null}
														{needAndCare.map((e) => e.q18autre) === "" ? null : (
															<FormControlLabel
																control={<Checkbox checked={true} name=" " />}
																label={
																	<TextField
																		required
																		disabled
																		defaultValue={needAndCare.map(
																			(e) => e.q18autre
																		)}
																		//label="Autre, notamment "
																		variant="outlined"
																		style={{ width: "100%" }}
																		size="small"
																		fullWidth
																	/>
																}
															/>
														)}
													</FormGroup>
												</Grid>
											</Grid>

											<Grid
												container
												spacing={1}
												className={classes.margin_Bottom_30}
											>
												<Grid item sm={12} xs={12}>
													<Typography className={classes.sublabel}>
														{q18b}
													</Typography>
												</Grid>
												<Grid item sm={12} xs={12}>
													<TextareaAutosize
														rows={14}
														disabled
														className={classes.textarea}
														defaultValue={needAndCare.map((e) => e.q18b)}
														aria-label="maximum height"
														// placeholder="Il s’agit d’une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé."
													/>
												</Grid>
											</Grid>

											<Grid
												container
												spacing={1}
												className={classes.margin_Bottom_30}
											>
												<Grid item sm={12} xs={12}>
													<Typography className={classes.sublabel}>
														{q18c}
													</Typography>
												</Grid>
												<Grid item sm={12} xs={12}>
													<TextareaAutosize
														rows={14}
														disabled
														className={classes.textarea}
														defaultValue={needAndCare.map((e) => e.q18c)}
														aria-label="maximum height"
														// placeholder="Il s’agit d’une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé."
													/>
												</Grid>
											</Grid>
										</div>
									) : null}
									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q19}</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.answer}
												>
													{q19value === "A" ? (
														<FormControlLabel
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Oui
																</Box>
															}
														/>
													) : (
														<FormControlLabel
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Non
																</Box>
															}
														/>
													)}
												</RadioGroup>
											</FormControl>
											{q19value === "A" ? (
												<FormGroup className={classes.answer}>
													{needAndCare.map((e) => e.q19oui[0]) === "A" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Soins médicaux"
														/>
													) : null}
													{needAndCare.map((e) => e.q19oui[1]) === "B" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)"
														/>
													) : null}
													{needAndCare.map((e) => e.q19oui[2]) === "C" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Assistance psychosociale"
														/>
													) : null}
													{needAndCare.map((e) => e.q19oui[3]) === "D" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Assistance en médiation familial"
														/>
													) : null}
													{needAndCare.map((e) => e.q19oui[4]) === "E" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Assistance juridique"
														/>
													) : null}
													{needAndCare.map((e) => e.q19oui[5]) === "F" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire pénale"
														/>
													) : null}
													{needAndCare.map((e) => e.q19oui[6]) === "G" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile"
														/>
													) : null}
													{needAndCare.map((e) => e.q19oui[7]) === "H" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Assistance socio-économique"
														/>
													) : null}
													{needAndCare.map((e) => e.q19oui[8]) === "I" ? (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label="Développement d’un plan de sécurité pour la victime de VD"
														/>
													) : null}
													{needAndCare.map((e) => e.q19ouiAutre) ===
													"" ? null : (
														<FormControlLabel
															control={<Checkbox checked={true} name="" />}
															label={
																<TextField
																	required
																	disabled
																	//label="Autre, notamment "
																	defaultValue={needAndCare.map(
																		(e) => e.q19ouiAutre
																	)}
																	variant="outlined"
																	style={{ width: "100%" }}
																	size="small"
																	fullWidth
																/>
															}
														/>
													)}
												</FormGroup>
											) : null}
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>
												{q20}
												(Veuillez cocher les volets qui sont couverts par les
												domaines d’intervention de votre organisation et dans
												lesquels vous pouvez ainsi directement assurer la prise
												en charge de la victime.)
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormGroup className={classes.answer}>
												{needAndCare.map((e) => e.q20[0]) === "A" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Soins médicaux"
													/>
												) : null}
												{needAndCare.map((e) => e.q20[1]) === "B" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)"
													/>
												) : null}
												{needAndCare.map((e) => e.q20[2]) === "C" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Assistance psychosociale"
													/>
												) : null}
												{needAndCare.map((e) => e.q20[3]) === "D" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Assistance en médiation familial"
													/>
												) : null}
												{needAndCare.map((e) => e.q20[4]) === "E" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Assistance juridique"
													/>
												) : null}
												{needAndCare.map((e) => e.q20[5]) === "F" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire"
													/>
												) : null}
												{needAndCare.map((e) => e.q20[6]) === "G" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile"
													/>
												) : null}
												{needAndCare.map((e) => e.q20[7]) === "H" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Assistance socio-économique"
													/>
												) : null}
												{needAndCare.map((e) => e.q20[8]) === "I" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Développement d’un plan de sécurité pour la victime de VD"
													/>
												) : null}
												{needAndCare.map((e) => e.q20autre) === "" ? null : (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label={
															<TextField
																required
																disabled
																defaultValue={needAndCare.map(
																	(e) => e.q20autre
																)}
																//label="Autre, notamment "
																variant="outlined"
																style={{ width: "100%" }}
																size="small"
																fullWidth
															/>
														}
													/>
												)}
											</FormGroup>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>
												{q21}
												<small>
													(Veuillez cocher les volets qui ne sont pas couverts
													par les domaines d’intervention de votre organisation
													(cf. question 18) mais pour lesquels vous pouvez
													référer facilement la victime auprès des structures de
													référence fiables.)
												</small>
											</Typography>
											<FormGroup className={classes.answer}>
												{needAndCare.map((e) => e.q21[0]) === "A" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Soins médicaux"
													/>
												) : null}
												{needAndCare.map((e) => e.q21[1]) === "B" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)"
													/>
												) : null}
												{needAndCare.map((e) => e.q21[2]) === "C" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Assistance psychosociale"
													/>
												) : null}
												{needAndCare.map((e) => e.q21[3]) === "D" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Assistance en médiation familial"
													/>
												) : null}
												{needAndCare.map((e) => e.q21[4]) === "E" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Assistance juridique"
													/>
												) : null}
												{needAndCare.map((e) => e.q21[5]) === "F" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire"
													/>
												) : null}
												{needAndCare.map((e) => e.q21[6]) === "G" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile"
													/>
												) : null}
												{needAndCare.map((e) => e.q21[7]) === "H" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Assistance socio-économique"
													/>
												) : null}
												{needAndCare.map((e) => e.q21[8]) === "I" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Développement d’un plan de sécurité pour la victime de VD"
													/>
												) : null}
												{needAndCare.map((e) => e.q21[9]) === "J" ? (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label="Cette situation ne s’applique pas au cas documenté comme notre organisation peut fournir tous les services demandés"
													/>
												) : null}
												{needAndCare.map((e) => e.q21autre) === "" ? null : (
													<FormControlLabel
														control={<Checkbox checked={true} name="" />}
														label={
															<TextField
																required
																defaultValue={needAndCare.map(
																	(e) => e.q21autre
																)}
																disabled
																//label="Autre, notamment "
																variant="outlined"
																style={{ width: "100%" }}
																size="small"
																fullWidth
															/>
														}
													/>
												)}
											</FormGroup>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>
												{q22}
												<small>
													(Veuillez cocher les volets qui ne sont pas couverts
													par les domaines d’intervention de votre organisation
													(cf. question 18) et pour lesquels il n’existe pas des
													structures de référence (cf. question 18) pour offrir
													ces services à la victime.)
												</small>
											</Typography>
										</Grid>

										<FormGroup className={classes.answer}>
											{needAndCare.map((e) => e.q22oui[0]) === "A" ? (
												<FormControlLabel
													control={<Checkbox checked={true} name="" />}
													label="Soins médicaux"
												/>
											) : null}
											{needAndCare.map((e) => e.q22oui[1]) === "B" ? (
												<FormControlLabel
													control={<Checkbox checked={true} name="" />}
													label="Soins médicaux dans les 72 heures en cas de violence sexuelle (PepKit)"
												/>
											) : null}
											{needAndCare.map((e) => e.q22oui[2]) === "C" ? (
												<FormControlLabel
													control={<Checkbox checked={true} name="" />}
													label="Assistance psychosociale"
												/>
											) : null}
											{needAndCare.map((e) => e.q22oui[3]) === "D" ? (
												<FormControlLabel
													control={<Checkbox checked={true} name="" />}
													label="Assistance en médiation familial"
												/>
											) : null}
											{needAndCare.map((e) => e.q22oui[4]) === "E" ? (
												<FormControlLabel
													control={<Checkbox checked={true} name="" />}
													label="Assistance juridique"
												/>
											) : null}
											{needAndCare.map((e) => e.q22oui[5]) === "F" ? (
												<FormControlLabel
													control={<Checkbox checked={true} name="" />}
													label="Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire"
												/>
											) : null}
											{needAndCare.map((e) => e.q22oui[6]) === "G" ? (
												<FormControlLabel
													control={<Checkbox checked={true} name="" />}
													label="Assistance judiciaire pour dénoncer le cas auprès une autorité judiciaire civile"
												/>
											) : null}
											{needAndCare.map((e) => e.q22oui[7]) === "H" ? (
												<FormControlLabel
													control={<Checkbox checked={true} name="" />}
													label="Assistance socio-économique"
												/>
											) : null}
											{needAndCare.map((e) => e.q22oui[8]) === "I" ? (
												<FormControlLabel
													control={<Checkbox checked={true} name="" />}
													label="Développement d’un plan de sécurité pour la victime de VD"
												/>
											) : null}
											{needAndCare.map((e) => e.q22oui[9]) === "J" ? (
												<FormControlLabel
													control={<Checkbox checked={true} name="" />}
													label="Cette situation ne s’applique pas au cas documenté comme tous les services demandés peuvent être fournis par notre organisation et/ou nos partenaires de référence. "
												/>
											) : null}
											{needAndCare.map((e) => e.q22autre) === "" ? null : (
												<FormControlLabel
													control={<Checkbox checked={true} name="" />}
													label={
														<TextField
															required
															defaultValue={needAndCare.map((e) => e.q22autre)}
															disabled
															//label="Autre, notamment "
															variant="outlined"
															style={{ width: "100%" }}
															size="small"
															fullWidth
														/>
													}
												/>
											)}
										</FormGroup>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q23}</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.answer}
												>
													<FormControlLabel
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																{getQ23()}
															</Box>
														}
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q24}</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormControl
												component="fieldset"
												className={classes.marginBottom_30}
											>
												<RadioGroup
													name="occupation"
													className={classes.answer}
												>
													<FormControlLabel
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																{getQ24()}
															</Box>
														}
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>

									{q24value !== "A" ? (
										<Grid
											container
											spacing={1}
											className={classes.margin_Bottom_30}
										>
											<Grid item sm={12} xs={12}>
												<Typography className={classes.sublabel}>
													{q24a}
												</Typography>
											</Grid>
											<Grid item sm={12} xs={12}>
												<FormControl component="fieldset">
													<RadioGroup
														name="occupation"
														className={classes.answer}
													>
														<FormControlLabel
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	{getQ24a()}
																</Box>
															}
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
									) : null}

									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.label}>{q25}</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.answer}
												>
													<FormControlLabel
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																{getQ25()}
															</Box>
														}
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>

									{q25value === "A" ? (
										<div>
											<Grid
												container
												spacing={1}
												className={classes.margin_Bottom_30}
											>
												<Grid item sm={12} xs={12}>
													<Typography className={classes.label}>
														{q26}
													</Typography>
												</Grid>
												<Grid item sm={12} xs={12}>
													<TextareaAutosize
														rows={14}
														disabled
														defaultValue={needAndCare.map((e) => e.q26)}
														className={classes.textarea}
														aria-label="maximum height"
														// placeholder="Il s’agit d’une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé."
													/>
												</Grid>
											</Grid>

											<Grid
												container
												spacing={1}
												className={classes.margin_Bottom_30}
											>
												<Grid item sm={12} xs={12}>
													<Typography className={classes.label}>
														{q27}
													</Typography>
												</Grid>
												<Grid item sm={12} xs={12}>
													<FormControl component="fieldset">
														<RadioGroup
															name="occupation"
															className={classes.answer}
														>
															<FormControlLabel
																control={<Radio size="small" />}
																label={
																	<Box component="div" fontSize={13}>
																		{getQ27()}
																	</Box>
																}
															/>
														</RadioGroup>
													</FormControl>
												</Grid>
											</Grid>
										</div>
									) : null}
								</Grid>
							</Grid>
							<Grid
								container
								spacing={1}
								direction="row"
								justifyContent="flex-end"
								className={classes.spacedown}
							>
								<Grid item className={classes.marginButton}>
									<BtnOutline
										component={Link}
										to={
											"/" +
											process.env.REACT_APP_COLLECT_LINK +
											"/collect/main/changes"
										}
										content={modifier}
										onClick={() => {
											// //console.log('hello elijah');
										}}
									/>
									{isSubmitting === true ? (
										<CircularProgress color="secondary" />
									) : (
										<BtnContained onClick={submitRevoir} content={soumettre} />
									)}
								</Grid>
							</Grid>

							<Snackbar
								open={openSnackBar}
								autoHideDuration={10}
								onClose={handleClose}
							>
								<Alert onClose={handleCloseSnackBar} severity="error">
									<Typography>{errmsg}</Typography>
								</Alert>
							</Snackbar>
						</Grid>
					</Grid>
					<Grid item sm={1} xs={0}></Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export default Revoir;

import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../../website/widgets/style";
import {
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const PostMedia = ({
	image,
	imagetitle,
	category,
	title,
	description,
	posteddate,
	readmoreClicked,
	editClicked,
	deleteClicked,
	color,
	spinner,
}) => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const theme = useTheme();
	//dialog

	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		//setAnchorEl(null);
		setOpen(false);
	};

	return (
		<Card className={classes.posts}>
			<CardActionArea>
				<CardMedia
					className={classes.posts_media}
					image={image}
					title={imagetitle}
				/>
				<CardContent>
					<Typography
						style={{ color: `${color}`, fontWeight: 300 }}
						gutterBottom
						variant="caption"
						component="h2"
					>
						{category}
					</Typography>
					<Typography
						gutterBottom
						variant="h5"
						component="h2"
						style={{ fontWeight: 300 }}
					>
						{title}
					</Typography>
					<Typography
						variant="body2"
						color="textSecondary"
						style={{ height: 40, fontWeight: 300 }}
					>
						{description}
					</Typography>
				</CardContent>
			</CardActionArea>
			<CardActions>
				<Grid container direction="column">
					<Grid item xs={12} sm={12}>
						<Grid container>
							<Grid item md={6} xs={6} justifyContent="center">
								<Typography variant="caption" style={{ fontWeight: 300 }}>
									Posted: {posteddate}
								</Typography>
							</Grid>
							<Grid item md={6} xs={6}>
								<Grid
									container
									direction="column"
									justifyContent="center"
									alignItems="flex-end"
								>
									<Button
										endIcon={<KeyboardArrowRightIcon />}
										size="small"
										color="secondary"
										style={{ fontWeight: 300 }}
										onClick={readmoreClicked}
									>
										Read More
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Grid container direction="row">
								<Grid item xs={6} sm={6}>
									<Grid
										container
										justifyContent="flex-start"
										alignItems="flex-start"
									>
										<Grid item>
											<Button
												className={classes.btnContained}
												onClick={editClicked}
											>
												Edit
											</Button>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={6} sm={6}>
									<Grid
										container
										justifyContent="flex-end"
										alignItems="flex-end"
									>
										<Grid item>
											<Button
												className={classes.btndrop2}
												onClick={handleClickOpen}
											>
												Delete
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Dialog
						fullScreen={fullScreen}
						open={open}
						onClose={handleClose}
						aria-labelledby="responsive-dialog-title"
					>
						<DialogTitle id="responsive-dialog-title">
							{"Are you sure you want to delete this post?"}
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Once deleted, this post will be permanently lost.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button style={{ color: "#009688" }} onClick={handleClose}>
								Non
							</Button>
							{/* <Button autoFocus onClick={handleClose} color="primary">
                    ANNULER
                </Button> */}
							<Button
								style={{ backgroundColor: "#D12028", color: "white" }}
								onClick={() => {
									deleteClicked();
									setOpen(false);
									window.location.reload();
								}}
							>
								OUI
							</Button>
						</DialogActions>
					</Dialog>
				</Grid>
			</CardActions>
		</Card>
	);
};
export default PostMedia;

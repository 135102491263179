import {makeStyles} from '@material-ui/core/styles'

export default makeStyles((theme)=>({

    content: {
        backgroundColor:'#FFFFFF',
        width: '940px',
        padding: '70px',
        marginBottom: 100,
        [theme.breakpoints.down("xs")]:{
            paddding:0
        }
    },
    bigtitle:{
        marginTop: 30,
        marginBottom: 30,
        marginLeft: 0,
        color: '#1D384C',
        fontSize: 30
    },
    textarea:{
        width:'100%',
        backgroundColor:"#E0F3F5",
        border:"0",
        padding:"10px"
    },
    title:{
        marginTop:'60px',
        color: '#4F2970',
        fontSize: '36px'
    },
    subtitle:{
        color: '#4F2970',
        fontSize: '20px'
    },

    label:{
        fontSize: '15px',
        color: '#2D2C2F',
        fontWeight:'bold',
        marginBottom:"10px"
    },
    sublabel:{
        color: '#4F2970',
        fontSize: '14px',
        fontWeight:'regular',
        marginBottom:"10px"
    },
    subradio:{
        color: '#4F2970',
        fontSize: '14px',
        fontWeight:'regular'
    },
    radiolabel:{
        "& .MuiInputBase-root": {
            padding: 10
          }
    },
    
    grid:{
        marginLeft:10,
        marginRight:10, 
        marginBottom:50,
        borderBottom:0,
        boxShadow: "none"
           
    },
    tab:{
        fontSize:14
        
    },
    table:{
        marginTop: 20
    },
  
    tbPanel:{
        fontSize: 11
    },
    margin_Bottom_30:{
        marginBottom:30
    },
    margin_Bottom_10:{
        marginBottom:10
    },
    margin_Bottom_20:{
        marginTop:20,
        marginBottom:20
    },
    margin_20:{
        margin:20
    },
    margin_Top_30:{
        marginTop:30

    },
    answer:{
        color: '#00A1AF',
        fontSize:15
    },
    answer_view:{
        marginTop:20,
        marginRight:20,
        marginLeft:20,
        marginBottom:20,
    },

    indicatedColor:{
        backgroundColor:'#00A1AF',
       
    },

    inputSelector:{
        backgroundColor:'#F0F0F0',
        border: '1px solid #F0F0F0',
        '&:after':{
            border: '1px solid #F0F0F0',
        },

        
       
    },

    leftAndRight:{
        marginTop:30,
        marginRight:20,
        marginLeft:20
    },

    paperBig:{
        width:700,
        height: 300
        
    }
    
    
   
}))
import { Grid } from "@material-ui/core";
import useStyles from "../../component/website/campain/style";
import React, { useEffect } from "react";
import Section1 from "../../component/website/CaseStat/section1";
import CaseStatistc from "../../component/website/CaseStat/case_statistic";
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

export default function CaseStat() {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
       <Helmet>
              <title>{t('case-statistic')}</title>
              <meta name='description' content={t('case-statistic-title')}/>
            </Helmet>

      <Grid container className={classes.section_1} alignItems="center">
        <Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={8}>
          <Section1 />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
      </Grid>

      <Grid container className={classes.marginTop_10}>
        <Grid item xs={1} sm={1} md={2} lg={2} xl={2}></Grid>
        <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
          <CaseStatistc />
        </Grid>
        <Grid item xs={1} sm={1} md={2} lg={2} xl={2}></Grid>
      </Grid>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import {
	AppBar,
	Toolbar,
	IconButton,
	Grid,
	Typography,
	Box,
	TextField,
	RadioGroup,
	Radio,
	FormControlLabel,
	FormControl,
	Select,
	MenuItem,
} from "@material-ui/core";
import useStyles from "./style";
import {
	q5,
	q5sub1,
	q5sub2,
	q5sub3,
	q5sub4,
	q6,
	q7,
	q8,
	q8a,
	q8b,
	q8c,
	q8d,
	q8f,
	q8e,
	precedent,
	suivant,
	grp2,
} from "../../widget/i1e";
import { BtnContained, BtnOutline } from "../../widget/util";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Profile_de_la_survivant = () => {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const [orgData, setorgData] = useState([]);
	const [q6bValue, setq6bValue] = useState();
	const [q7Value, setq7Value] = useState();
	const [q8aValue, setq8aValue] = useState();
	const [q8bValue, setq8bValue] = useState();
	const [q8cValue, setq8cValue] = useState();
	const [q8dValue, setq8dValue] = useState();
	const [q8fValue, setq8fValue] = useState();
	const [info, setinfo] = useState([]);
	const [q8eValue, setq8eValue] = useState();
	const [openSnackBar, setOpenSnackbar] = useState(false);
	const [open, setOpen] = useState(false);
	const [msg, setmsg] = useState();
	const [grp, setgrp] = useState("");
	const [gnre, setgnre] = useState("");
	const [etat, setetat] = useState("");
	const [q6bval, setq6bval] = useState();
	const [q7val, setq7val] = useState();
	const [q8val, setq8val] = useState();

	// snackbar
	const handleClose = () => {
		setOpen(false);
	};

	const handleSnackBar = () => {
		setOpenSnackbar(true);
	};

	const handleCloseSnackBar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpenSnackbar(false);
	};

	const handleq6b = (e) => {
		const data = e.target.value;
		setq6bValue(data);
		if (data === "A") {
			setq6bval("0-5 ans");
		} else if (data === "B") {
			setq6bval("6-17 ans");
		} else if (data === "C") {
			setq6bval("18-29 ans");
		} else if (data === "D") {
			setq6bval("30 - 49 ans");
		} else if (data === "E") {
			setq6bval("50-64 ans");
		} else if (data === "F") {
			setq6bval("65 ans et plus");
		}
	};

	const handleq7 = (e) => {
		const data = e.target.value;
		setq7Value(data);
		if (data === "A") {
			setq7val("Féminin");
		} else if (data === "B") {
			setq7val("Masculin");
		} else if (data === "C") {
			setq7val("Autre");
		}
	};

	const handleq8a = (e) => {
		const data = e.target.value;
		setq8aValue(data);
		if (data === "A") {
			setq8val("Célibataire");
		} else if (data === "B") {
			setq8val("Union de fait (vivre ensemble sans être mariés)");
		} else if (data === "C") {
			setq8val("Marié(e)");
		} else if (data === "D") {
			setq8val("Séparé(e)</");
		} else if (data === "E") {
			setq8val("Divorcé(e)</");
		} else if (data === "F") {
			setq8val("Veuve / Veuf");
		}
	};

	//controler for the navbar
	const [select, setSelect] = useState(3);
	const handleChange = (event) => {
		setSelect(event.target.value);
	};
	//

	const { register, handleSubmit, reset } = useForm({
		mode: "onBlur",
	});
	const onSubmit = (data, e) => {
		e.preventDefault();

		if (
			q8bbool !== false &&
			q8cbool !== false &&
			q8dbool !== false &&
			q8ebool !== false &&
			q8fdbool !== false
		) {
			const profileList = [
				{
					q5: data.q5a + data.q5b + data.q5c + data.q5d,
					q5a: data.q5a,
					q5b: data.q5b,
					q5c: data.q5c,
					q5d: data.q5d,
					q6a: data.q6a,
					q6b: q6bValue,
					q7: q7Value,
					q8a: q8aValue,
					q8b: q8bValue,
					q8c: q8cValue,
					q8d: q8dValue,
					q8e: q8eValue,
					q8f: q8fValue,
					q6bp: q6bval,
					q7p: q7val,
					q8p: q8val,
					q8bbool: q8bbool,
					q8cbool: q8cbool,
					q8dbool: q8dbool,
					q8ebool: q8ebool,
					q8fdbool: q8fdbool,
				},
			];
			//console.log("profile List",profileList);
			//sessionStorage.setItem('profile',JSON.stringify(profileList))
			if (orgData !== null) {
				sessionStorage.setItem("profile", JSON.stringify(profileList));

				handleSauvegarde();
			} else {
				sessionStorage.setItem("profile", JSON.stringify(profileList));

				handleSuivant();
			}
			//console.log(data);
		} else {
			setmsg(
				"Question sans réponse détectée. Veuillez vérifier que vous avez répondu à toutes les questions avant de continuer."
			);
			handleSnackBar();
		}
	};

	const handleSuivant = () => {
		history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/form/info`);
	};
	const handleRetour = () => {
		history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/main/changes`);
	};

	const handleSauvegarde = () => {
		history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/main/changes`);
	};

	var [q8bbool, setq8bbool] = useState(false);
	const q8bSelector = (e) => {
		const value = e.target.value;
		setq8bbool(true);
		//console.log("q8b",value)

		setq8bValue(value);
	};
	const [q8cbool, setq8cbool] = useState(false);

	const q8cSelector = (e) => {
		const value = e.target.value;
		setq8cbool(true);
		//console.log("q8c",value)

		setq8cValue(value);
	};
	const [q8dbool, setq8dbool] = useState(false);

	const q8dSelector = (e) => {
		const value = e.target.value;
		//console.log("q8d",value)
		setq8dbool(true);
		setq8dValue(value);
	};
	const [q8ebool, setq8ebool] = useState(false);
	const q8eBox = (e) => {
		const value = e.target.value;
		setq8eValue(value);
		setq8ebool(true);
		if (value === "A") {
			setq8fValue("B");
			setqfdbool(true);
			// console.log(q8fValue);
		} else {
			setq8fValue("");
			setqfdbool(false);
		}

		//console.log('value',value);
	};
	const [q8fdbool, setqfdbool] = useState(false);

	const q8fSelector = (e) => {
		const value = e.target.value;
		//console.log("q8f",value)
		setqfdbool(true);
		setq8fValue(value);
	};

	const getPofile = () => {
		const data = sessionStorage.getItem("profile");
		// //console.log('data from local storage',JSON.parse(data));
		return setinfo(JSON.parse(data));
	};

	useEffect(() => {
		getPofile();
		setorgData(location.data);
		//to initialize the radio if has the data from local storage

		if (info !== null) {
			setq8bValue(info.map((e) => e.q8b));
			setq8cValue(info.map((e) => e.q8c));
			setq8dValue(info.map((e) => e.q8d));
			setq8eValue(info.map((e) => e.q8e));
			setq8fValue(info.map((e) => e.q8f));
			setq8bbool(info.map((e) => e.q8bbool));
			setq8cbool(info.map((e) => e.q8cbool));
			setq8dbool(info.map((e) => e.q8dbool));
			setq8ebool(info.map((e) => e.q8ebool));
			setqfdbool(info.map((e) => e.q8fdbool));
			setgrp(info.map((e) => e.q6bp));
			setgnre(info.map((e) => e.q7p));
			setetat(info.map((e) => e.q8p));
			setq6bValue(info.map((e) => e.q6b));
			setq7Value(info.map((e) => e.q7));
			setq8aValue(info.map((e) => e.q8a));
			setq6bval(info.map((e) => e.q6bp));
			setq7val(info.map((e) => e.q7p));
			setq8val(info.map((e) => e.q8p));

			reset({
				q5a: info.map((e) => e.q5a),
				q5b: info.map((e) => e.q5b),
				q5c: info.map((e) => e.q5c),
				q5d: info.map((e) => e.q5d),
				q6a: info.map((e) => e.q6a),
				q6b: info.map((e) => e.q6bp),
				q7: info.map((e) => e.q7p),
				q8a: info.map((e) => e.q8p),
			});
		}
	}, [orgData, info, location.data, reset]); // Just added info, location.data, reset
	//console.log(info);
	//console.log(q8fdbool);

	////console.log(q8bValue);

	return (
		<div>
			<div className={classes.root}>
				<AppBar position="absolute" className={classes.appbar}>
					<Toolbar>
						{orgData !== null ? null : (
							<IconButton
								edge="start"
								className={classes.menuButton}
								color="inherit"
								aria-label="open drawer"
								component={Link}
								to="/collect"
							>
								{/* <ArrowBack/> */}
							</IconButton>
						)}
						<Typography
							className={classes.titleAppBar}
							variant="h6"
							noWrap
						></Typography>
						<div className={classes.search}>
							<div className={classes.searchIcon}></div>
							{orgData !== null ? (
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={select}
									fullWidth
									className={classes.select}
									onChange={handleChange}
								>
									<MenuItem value={3}>PROFIL DE LA / DU SURVIVANT(E)</MenuItem>
								</Select>
							) : (
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={select}
									fullWidth
									className={classes.select}
									onChange={handleChange}
								>
									<MenuItem
										value={2}
										component={Link}
										to={
											"/" + process.env.REACT_APP_COLLECT_LINK + "/collect/form"
										}
									>
										IDENTIFICATION DE L'ORGANISATION
									</MenuItem>
									<MenuItem
										value={3}
										component={Link}
										to={
											"/" +
											process.env.REACT_APP_COLLECT_LINK +
											"/collect/form/profile"
										}
									>
										PROFIL DE LA / DU SURVIVANT(E)
									</MenuItem>
								</Select>
							)}
						</div>
					</Toolbar>
				</AppBar>
			</div>
			<div>
				<Grid container justifyContent="center" spacing={3}>
					<Grid item xs={11} sm={8}>
						<Typography
							xs={11}
							sm={8}
							className={classes.bigtitle}
							align="left"
						>
							{grp2.toUpperCase()}
						</Typography>
					</Grid>
				</Grid>
				<Grid container justifyContent="center" spacing={3}>
					<Grid item className={classes.content} xs={11} sm={8}>
						<Grid container direction="row">
							<Grid item sm={1} xs={0}></Grid>
							<Grid item sm={10} xs="12" className={classes.marginTop_40}>
								<form onSubmit={handleSubmit(onSubmit)}>
									{/* Question Five */}
									<Grid
										container
										spacing={4}
										direction="row"
										className={classes.marginBottom_30}
									>
										<Grid item xs={11} sm={11}>
											<Grid container direction="row">
												<Grid item xs={12} sm={12}>
													<Typography className={classes.label}>
														{q5}
													</Typography>
													<Typography className={classes.sublabel}>
														<small>{q5sub1}</small>
													</Typography>
													<Typography className={classes.sublabel}>
														<small>{q5sub2}</small>
													</Typography>
													<Typography className={classes.sublabel}>
														<small>{q5sub3}</small>
													</Typography>
													<Typography className={classes.sublabel}>
														<small>{q5sub4}</small>
													</Typography>
												</Grid>
												<Grid item x={12} sm={12}>
													<Grid container direction="row" spacing={2}>
														<Grid item xs={3} sm={2}>
															<TextField
																required
																type="text"
																// defaultValue={info.map(e=>e.q5)}
																inputProps={{ maxLength: 1 }}
																label="___"
																variant="outlined"
																size="small"
																name="q5a"
																inputRef={register}
															/>
														</Grid>
														<Grid item xs={3} sm={2}>
															<TextField
																required
																inputProps={{ maxLength: 1 }}
																label="___"
																variant="outlined"
																size="small"
																name="q5b"
																inputRef={register}
															/>
														</Grid>
														<Grid item xs={4} sm={3}>
															<TextField
																required
																label="______"
																variant="outlined"
																size="small"
																name="q5c"
																// helperText="take only number"

																inputProps={{
																	maxLength: 2,
																}}
																inputRef={register}
															/>
														</Grid>
														<Grid item xs={3} sm={2}>
															<TextField
																required
																label="___"
																variant="outlined"
																size="small"
																name="q5d"
																type="text"
																inputProps={{ maxLength: 1 }}
																inputRef={register}
															/>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>

									<Grid
										item
										xs={12}
										sm={12}
										className={classes.marginBottom_30}
									>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={12}>
												<Grid container direction="row" spacing={1}>
													<Grid item xs={12} sm={6}>
														<Grid item xs={12} sm={12}>
															<Typography className={classes.label}>
																{q6}
															</Typography>
														</Grid>
														<Grid
															container
															xs={12}
															sm={12}
															justifyContent="space-between"
															spacing={2}
														>
															<Grid item sm={6} xs={12}>
																<Typography className={classes.sublabel}>
																	Age précis*
																</Typography>
																<TextField
																	required
																	// label="Age précis"
																	variant="outlined"
																	size="small"
																	fullWidth
																	type="number"
																	name="q6a"
																	inputRef={register}
																/>
															</Grid>
															<Grid item sm={6} xs={12}>
																<Typography className={classes.sublabel}>
																	Groupe d’âge*
																</Typography>
																{grp.length > 0 ? (
																	<TextField
																		variant="outlined"
																		size="small"
																		fullWidth
																		type="text"
																		name="q6b"
																		onClick={() => {
																			setgrp("");
																		}}
																		inputRef={register}
																	/>
																) : (
																	<Select
																		required
																		labelId="demo-simple-select-helper-label"
																		variant="outlined"
																		id="demo-simple-select-helper"
																		size="small"
																		fullWidth
																		style={{ height: 40 }}
																		value={q6bValue}
																		onChange={handleq6b}

																		//onChange={e=>register({name:'q6b',value:e.target.value})}
																	>
																		<MenuItem value={"A"}>0-5 ans</MenuItem>
																		<MenuItem value={"B"}>6-17 ans</MenuItem>
																		<MenuItem value={"C"}>18-29 ans</MenuItem>
																		<MenuItem value={"D"}>30 - 49 ans</MenuItem>
																		<MenuItem value={"E"}>50-64 ans</MenuItem>
																		<MenuItem value={"F"}>
																			65 ans et plus
																		</MenuItem>
																	</Select>
																)}
															</Grid>
														</Grid>
													</Grid>
													<Grid item sm={6} xs={12}>
														<Grid item xs={12} sm={12}>
															<Typography className={classes.label}>
																{q7}
															</Typography>
														</Grid>
														<Typography className={classes.sublabel}>
															Genre*
														</Typography>

														{gnre.length > 0 ? (
															<TextField
																// required
																name="q7"
																inputRef={register}
																//label="Nom"
																onClick={() => {
																	setgnre("");
																}}
																variant="outlined"
																size="small"
																fullWidth
															/>
														) : (
															<Select
																required
																variant="outlined"
																size="small"
																fullWidth
																style={{ height: 40 }}
																value={q7Value}
																onChange={handleq7}
																// defaultValue={location.state.role}

																// value={age}
																//onChange={e=>register({name:'q7',value:e.target.value})}
															>
																<MenuItem value={"A"}>Féminin</MenuItem>
																<MenuItem value={"B"}> Masculin</MenuItem>
																<MenuItem value={"C"}>Autre</MenuItem>
															</Select>
														)}
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={12} sm={12}>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={12}>
												<Grid container spacing={1} direction="row">
													<Grid item xs={12} sm={12}>
														<Grid container spacing={1}>
															<Grid item xs={12} sm={12}>
																<Grid container>
																	<Grid item xs={12} sm={12}>
																		<Typography className={classes.label}>
																			{q8}
																		</Typography>
																	</Grid>
																</Grid>
																<Grid container spacing={2}>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		className={classes.marginBottom_30}
																	>
																		<Grid item xs={12} sm={12}>
																			<Typography className={classes.sublabel}>
																				{q8a}
																			</Typography>
																		</Grid>
																		{etat.length > 0 ? (
																			<TextField
																				required
																				value={q8val}
																				name="q8a"
																				onClick={() => {
																					setetat("");
																				}}
																				inputRef={register}
																				variant="outlined"
																				size="small"
																				fullWidth
																			/>
																		) : (
																			<Select
																				required
																				variant="outlined"
																				size="small"
																				fullWidth
																				style={{ height: 40 }}
																				value={q8aValue}
																				onChange={handleq8a}
																				// defaultValue={location.state.role}
																				// value={age}
																				// onChange={e=>register({name:'q8a',value:e.target.value})}
																			>
																				<MenuItem value={"A"}>
																					Célibataire
																				</MenuItem>
																				<MenuItem value={"B"}>
																					Union de fait (vivre ensemble sans
																					être mariés)
																				</MenuItem>
																				<MenuItem value={"C"}>
																					Marié(e)
																				</MenuItem>
																				<MenuItem value={"D"}>
																					Séparé(e)
																				</MenuItem>
																				<MenuItem value={"E"}>
																					Divorcé(e)
																				</MenuItem>
																				<MenuItem value={"F"}>
																					{" "}
																					Veuve / Veuf
																				</MenuItem>
																			</Select>
																		)}
																	</Grid>

																	<Grid item sm={6}>
																		<Grid item xs={12} sm={12}>
																			<Typography className={classes.sublabel}>
																				{q8b}
																			</Typography>
																		</Grid>
																		<FormControl component="fieldset">
																			<RadioGroup
																				name="occupation"
																				className={classes.radiolabel}
																			>
																				<FormControlLabel
																					checked={
																						q8bValue === "A" ? true : false
																					}
																					value="A"
																					onClick={q8bSelector}
																					control={<Radio size="small" />}
																					label={
																						<Box component="div" fontSize={13}>
																							Oui
																						</Box>
																					}
																				/>
																				<FormControlLabel
																					checked={
																						q8bValue === "B" ? true : false
																					}
																					value="B"
																					onClick={q8bSelector}
																					control={<Radio size="small" />}
																					label={
																						<Box component="div" fontSize={13}>
																							Non
																						</Box>
																					}
																				/>
																			</RadioGroup>
																		</FormControl>
																	</Grid>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={12} sm={12}>
														<Grid container spacing={1}>
															<Grid item xs={12} sm={12}>
																<Grid
																	container
																	spacing={2}
																	style={{ marginTop: 20 }}
																>
																	<Grid item sm={6}>
																		<Grid item xs={12} sm={12}>
																			<Typography className={classes.sublabel}>
																				{q8c}
																			</Typography>
																		</Grid>
																		<FormControl component="fieldset">
																			<RadioGroup
																				name="occupation"
																				className={classes.radiolabel}
																			>
																				<FormControlLabel
																					checked={
																						q8cValue === "A" ? true : false
																					}
																					value="A"
																					onClick={q8cSelector}
																					control={<Radio size="small" />}
																					label={
																						<Box component="div" fontSize={13}>
																							Oui
																						</Box>
																					}
																				/>
																				<FormControlLabel
																					checked={
																						q8cValue === "B" ? true : false
																					}
																					value="B"
																					onClick={q8cSelector}
																					control={<Radio size="small" />}
																					label={
																						<Box component="div" fontSize={13}>
																							Non
																						</Box>
																					}
																				/>
																			</RadioGroup>
																		</FormControl>
																	</Grid>
																	<Grid item sm={6}>
																		<Grid item xs={12} sm={12}>
																			<Typography className={classes.sublabel}>
																				{q8d}
																			</Typography>
																		</Grid>
																		<FormControl component="fieldset">
																			<RadioGroup
																				name="occupation"
																				className={classes.radiolabel}
																			>
																				<FormControlLabel
																					checked={
																						q8dValue === "A" ? true : false
																					}
																					value="A"
																					onClick={q8dSelector}
																					control={<Radio size="small" />}
																					label={
																						<Box component="div" fontSize={13}>
																							Oui
																						</Box>
																					}
																				/>
																				<FormControlLabel
																					checked={
																						q8dValue === "B" ? true : false
																					}
																					value="B"
																					onClick={q8dSelector}
																					control={<Radio size="small" />}
																					label={
																						<Box component="div" fontSize={13}>
																							Non
																						</Box>
																					}
																				/>
																			</RadioGroup>
																		</FormControl>
																	</Grid>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={12} sm={12}>
														<Grid
															container
															spacing={1}
															style={{ marginTop: 20 }}
														>
															<Grid item xs={12} sm={12}>
																<Grid container spacing={2}>
																	<Grid item xs={12} sm={6}>
																		<Grid item xs={12} sm={12}>
																			<Typography className={classes.sublabel}>
																				{q8e}
																			</Typography>
																		</Grid>
																		<FormControl component="fieldset">
																			<RadioGroup
																				name="occupation"
																				className={classes.radiolabel}
																			>
																				<FormControlLabel
																					checked={
																						q8eValue === "A" ? true : false
																					}
																					value="A"
																					onClick={q8eBox}
																					control={<Radio size="small" />}
																					label={
																						<Box component="div" fontSize={13}>
																							Oui
																						</Box>
																					}
																				/>
																				<FormControlLabel
																					checked={
																						q8eValue === "B" ? true : false
																					}
																					value="B"
																					onClick={q8eBox}
																					control={<Radio size="small" />}
																					label={
																						<Box component="div" fontSize={13}>
																							Non
																						</Box>
																					}
																				/>
																			</RadioGroup>
																		</FormControl>
																	</Grid>

																	<Grid item sm={6}>
																		<Grid item xs={12} sm={12}>
																			<Typography className={classes.sublabel}>
																				{q8f}
																			</Typography>
																		</Grid>
																		<FormControl component="fieldset">
																			<RadioGroup
																				name="occupation"
																				className={classes.radiolabel}
																			>
																				<FormControlLabel
																					checked={
																						q8fValue === "A" ? true : false
																					}
																					value="A"
																					onClick={q8fSelector}
																					disabled={
																						q8eValue === "A" ? true : false
																					}
																					control={<Radio size="small" />}
																					label={
																						<Box component="div" fontSize={13}>
																							Oui
																						</Box>
																					}
																				/>
																				<FormControlLabel
																					checked={
																						q8fValue === "B" ? true : false
																					}
																					value="B"
																					onClick={q8fSelector}
																					control={<Radio size="small" />}
																					label={
																						<Box component="div" fontSize={13}>
																							Non
																						</Box>
																					}
																				/>
																			</RadioGroup>
																		</FormControl>
																	</Grid>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</Grid>

												<Snackbar
													open={openSnackBar}
													autoHideDuration={10}
													onClose={handleClose}
												>
													<Alert onClose={handleCloseSnackBar} severity="error">
														<Typography>{msg}</Typography>
													</Alert>
												</Snackbar>
											</Grid>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										direction="row"
										justifyContent="flex-end"
									>
										<Grid item className={classes.marginButton}>
											{orgData !== null ? (
												<div>
													<BtnOutline onClick={handleRetour} content="RETOUR" />
													<BtnContained
														type="submit"
														//onClick={handleSauvegarde}
														content="SAUVEGARDER"
													/>
												</div>
											) : (
												<div>
													<BtnOutline
														component={Link}
														to={
															"/" +
															process.env.REACT_APP_COLLECT_LINK +
															"/collect/form"
														}
														content={precedent}
														onClick={() => {
															//console.log('hello elijah');
														}}
													/>
													<BtnContained
														type="submit"
														// component={Link}
														// to='/collect/form/info'
														content={suivant}
													/>
												</div>
											)}
										</Grid>
									</Grid>
								</form>
								{/*  */}
							</Grid>
							<Grid item sm={1} xs={0}></Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default Profile_de_la_survivant;

import React, { useState, useEffect } from "react";
import { Grid, Typography, IconButton } from "@material-ui/core";
import PostMedia from "../widget/postmedia";
import axiosInstance from "../../../../service/dashboardAxiosApi";
import ReactHtmlParser from "react-html-parser";
import Pagination from "@material-ui/lab/Pagination";
import PostMediaSkeleton from "../widget/postmediaSkeleton";
import CloudOffTwoToneIcon from "@material-ui/icons/CloudOffTwoTone";
import RestoreIcon from "@material-ui/icons/Restore";
import StorageIcon from "@material-ui/icons/Storage";

const Campaign = (props) => {
	const [postdata, setpostdata] = useState([]);
	const [spinner, setspinner] = useState(true);
	const [errmsg, seterrmsg] = useState("");
	const [statecode, setstatecode] = useState(false);

	const getPost = async () => {
		setspinner(true);
		axiosInstance
			.get(`posts/`)
			.then((res) => {
				if (res.status === 200) {
					const data = res.data.posts;
					setspinner(false);
					setpostdata(data);
					setstatecode(true);
					seterrmsg("Posts successfull fetched");
				}
				//// console.log(res);
			})
			.catch((err) => {
				setspinner(false);
				if (err.response) {
					//console.log(err.response);
					seterrmsg("Please check your internet connexion");
				} else if (err.request) {
					//console.log(err.request);
					seterrmsg("Please check your internet connexion");
				}
			});
	};

	useEffect(() => {
		getPost();
	}, [axiosInstance, postdata.id]);
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(6);
	// Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const all = postdata.filter((e) => e.category === "Campaign news");
	const alltotal = all.slice(indexOfFirstPost, indexOfLastPost);
	//  console.log(alltotal);
	const handleAll = (e, value) => {
		setCurrentPage(value);
		//console.log(value);
	};
	const handletotal = (num) => {
		const value = Math.ceil(num.length / postsPerPage);
		return value;
	};
	if (statecode === false) {
		return (
			<div>
				<Grid
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
					//  className={classes.loading}
				>
					<Grid
						item
						xs={12}
						sm={12}
						//  className={classes.progbar}
					>
						{spinner === true ? (
							<Grid container direction="row" spacing={2}>
								<Grid item xs={12} sm={4}>
									<PostMediaSkeleton />
								</Grid>
								<Grid item xs={12} sm={4}>
									<PostMediaSkeleton />
								</Grid>
								<Grid item xs={12} sm={4}>
									<PostMediaSkeleton />
								</Grid>
								<Grid item xs={12} sm={4}>
									<PostMediaSkeleton />
								</Grid>
								<Grid item xs={12} sm={4}>
									<PostMediaSkeleton />
								</Grid>
								<Grid item xs={12} sm={4}>
									<PostMediaSkeleton />
								</Grid>
								<Grid item xs={12} sm={4}>
									<PostMediaSkeleton />
								</Grid>
								<Grid item xs={12} sm={4}>
									<PostMediaSkeleton />
								</Grid>
								<Grid item xs={12} sm={4}>
									<PostMediaSkeleton />
								</Grid>
							</Grid>
						) : (
							<Grid container direction="row" justifyContent="center">
								<Grid item xs={12} sm={4}>
									<CloudOffTwoToneIcon size={40} />
								</Grid>
								<Grid item xs={12} sm={8}>
									<Typography>{errmsg}</Typography>
								</Grid>
								<Grid item xs={12} sm={8}>
									<IconButton
										color="primary"
										aria-label="Restart"
										component="span"
										onClick={getPost}
									>
										<RestoreIcon />
									</IconButton>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			</div>
		);
	} else if (statecode === true && postdata === "") {
		return (
			<Grid container direction="row" justifyContent="center">
				<Grid xs={12} sm={4}></Grid>
				<Grid xs={12} sm={4}>
					<Grid container direction="row">
						<Grid item xs={12} sm={4}>
							<StorageIcon size={60} />
						</Grid>
						<Grid item xs={12} sm={8}>
							<Typography>No Campaign news found</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid xs={12} sm={4}></Grid>
			</Grid>
		);
	} else if (statecode === true && postdata !== []) {
		return (
			<div>
				<Grid
					container
					direction="row"
					spacing={2}
					style={{ marginBottom: 50 }}
				>
					{alltotal.map((post) => (
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<PostMedia
								// image='http://localhost:8000/media/images/2021/05/epos.jpg'
								category={post.category}
								title={post.title}
								image={post.image}
								color="#208BD1"
								description={ReactHtmlParser(
									post.content.substr(0, 100) + " ...."
								)}
								posteddate={post.created.substr(0, 10)}
								deleteClicked={() => {
									props.handleDelete(post.id);
								}}
								readmoreClicked={() => {
									props.handlePostDetailLink();
									localStorage.setItem("pstId", post.id);
								}}
								editClicked={() => {
									props.handleUpdate();
									localStorage.setItem("pstId", post.id);
								}}
							/>
						</Grid>
					))}
				</Grid>
				<Grid container justifyContent="flex-end" alignItems="flex-end">
					<Grid>
						<Pagination
							count={handletotal(all)}
							page={currentPage}
							onChange={handleAll}
							defaultPage={currentPage}
							variant="outlined"
							color="secondary"
						/>
					</Grid>
				</Grid>
			</div>
		);
	}
};

export default Campaign;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import useStyles from '../../../dashboard/styles'
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Typography } from '@material-ui/core';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function createData3(
  name,
   total,
   tpn,
   vl,
   tpn2,
   vl2,
   tpn3,
   vl3,
   tpn4,
   vl4,
   tpn5,
   vl5,
   tpn6,
   vl6,
   tpn7,
   vl7,
   ) {
  return {
    name,
    total,
    history: [
      { contentID: `${tpn}`, amount: `${vl}`},
      { contentID: `${tpn2}`, amount: `${vl2}`},
      { contentID: `${tpn3}`, amount: `${vl3}`},
      { contentID: `${tpn4}`, amount: `${vl4}`},
      { contentID: `${tpn5}`, amount: `${vl5}`},
      { contentID: `${tpn6}`, amount: `${vl6}`},
      { contentID: `${tpn7}`, amount: `${vl7}`},     
    ],
  };
}


function createData2(
  name,
   total,
   tpn,
   vl,
   tpn2,
   vl2,
   tpn3,
   vl3,
   tpn4,
   vl4,
   tpn5,
   vl5,
   tpn6,
   vl6,
   tpn7,
   vl7,
   tpn8,
   vl8,
   ) {
  return {
    name,
    total,
    history: [
      { contentID: `${tpn}`, amount: `${vl}`},
      { contentID: `${tpn2}`, amount: `${vl2}`},
      { contentID: `${tpn3}`, amount: `${vl3}`},
      { contentID: `${tpn4}`, amount: `${vl4}`},
      { contentID: `${tpn5}`, amount: `${vl5}`},
      { contentID: `${tpn6}`, amount: `${vl6}`},
      { contentID: `${tpn7}`, amount: `${vl7}`},
      { contentID: `${tpn8}`, amount: `${vl8}`},
      
    ],
  };
}

function createData(
  name,
   total,
   tpn,
   vl,
   tpn2,
   vl2,
   tpn3,
   vl3,
   tpn4,
   vl4,
   tpn5,
   vl5,
   tpn6,
   vl6,
   tpn7,
   vl7,
   tpn8,
   vl8,
   tpn9,
   vl9,
   tpn10,
   vl10,
   tpn11,
   vl11,
   tpn12,
   vl12,
   tpn13,
   vl13,
   tpn14,
   vl14,
   tpn15,
   vl15,
   tpn16,
   vl16,
   ) {
  return {
    name,
    total,
    history: [
      { contentID: `${tpn}`, amount: `${vl}`},
      { contentID: `${tpn2}`, amount: `${vl2}`},
      { contentID: `${tpn3}`, amount: `${vl3}`},
      { contentID: `${tpn4}`, amount: `${vl4}`},
      { contentID: `${tpn5}`, amount: `${vl5}`},
      { contentID: `${tpn6}`, amount: `${vl6}`},
      { contentID: `${tpn7}`, amount: `${vl7}`},
      { contentID: `${tpn8}`, amount: `${vl8}`},
      { contentID: `${tpn9}`, amount: `${vl9}`},
      { contentID: `${tpn10}`, amount: `${vl10}`},
      { contentID: `${tpn11}`, amount: `${vl11}`},
      { contentID: `${tpn12}`, amount: `${vl12}`},
      { contentID: `${tpn13}`, amount: `${vl13}`},
      { contentID: `${tpn14}`, amount: `${vl14}`},
      { contentID: `${tpn15}`, amount: `${vl15}`},
      { contentID: `${tpn16}`, amount: `${vl16}`},
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const { t } = useTranslation();


  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
        <Typography variant='button' style={{fontWeight:330,textTransform:'unset', textAlign: "left", cursor: "pointer"}} onClick={() => setOpen(!open)}>
            {row.name}
          </Typography>
        </TableCell>
        <TableCell align="right" style={{fontWeight:330,}}>{row.total}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {/* <Typography variant="h6" gutterBottom component="div">
                Type
              </Typography> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{fontWeight:300,fontSize:18}} >{t('case-statistic-perp')}</TableCell>
                    <TableCell align="right" style={{fontWeight:300,fontSize:18}} >{t('cases')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {row.history.map((historyRow) => (
                    <TableRow key={historyRow.contentID}>
                      <TableCell style={{fontWeight:300}}>{historyRow.contentID}</TableCell>
                      <TableCell align="right" style={{fontWeight:300}}>{historyRow.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}




Row.propTypes = {
  row: PropTypes.shape({
    // calories: PropTypes.number.isRequired,
    // carbs: PropTypes.number.isRequired,
    // fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        contentID: PropTypes.any.isRequired,
        // contentID: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
};



const Auteurtbl=({
  title1,
  title2,
  mememberTotal,
  mere,
  pere,
  tuthom,
  tutfem,
  proFrer,
  propSoeur,
  propDenfa,
  gdmere,
  gdpere,
  belmere,
  beaupere,
  beauFere,
  belSoeur,
  oncle,
  tante,
  autrmembre,
  conjointeTotal,
  mari,
  exmari,
  epouse,
  exepouse,
  partHomme,
  partFemme,
  expartHomme,
  expartFemme,
  autreRelationTotal,
  patronHomDom,
  patronFemDom,
  personelHomDomestique,
  personelFemDomestique,
  bailDemezon,
  bailleDemezon,
  autremember2,
  
})=> {
  const { t } = useTranslation();

  const rowsx = [
    createData(
      `${t('case-statistic-perpetrator-categ-fam')}`, `${mememberTotal}`,
      `${t('case-statistic-perpetrator-categ-fam-mo')}`,`${mere}`,
      `${t('case-statistic-perpetrator-categ-fam-fa')}`,`${pere}`,
      `${t('case-statistic-perpetrator-categ-fam-gua-male')} `,`${tuthom}`,
      `${t('case-statistic-perpetrator-categ-fam-gual-fem')}`,`${tutfem}`,
      `${t('case-statistic-perpetrator-categ-fam-bro')}`,`${proFrer}`,
      `${t('case-statistic-perpetrator-categ-fam-sist')}`,`${propSoeur}`,
      `${t('case-statistic-perpetrator-categ-fam-adult')}`,`${propDenfa}`,
      `${t('case-statistic-perpetrator-categ-fam-grdma')}`,`${gdmere}`,
      `${t('case-statistic-perpetrator-categ-fam-grdfa')}`,`${gdpere}`,
      `${t('case-statistic-perpetrator-categ-fam-mo-law')}`,`${belmere}`,
      `${t('case-statistic-perpetrator-categ-fam-fa-law')}`,`${beaupere}`,
      `${t('case-statistic-perpetrator-categ-fam-bro-law')}`,`${beauFere}`,
      `${t('case-statistic-perpetrator-categ-fam-sist-law')}`,`${belSoeur}`,
      `${t('case-statistic-perpetrator-categ-fam-uncle')}`,`${oncle}`,
      `${t('case-statistic-perpetrator-categ-fam-aunt')}`,`${tante}`,
      `${t('case-statistic-perpetrator-categ-fam-other')}`,`${autrmembre}`
      ),

    createData2(
   `${t('case-statistic-perpetrator-categ-spouse')}`,`${conjointeTotal}`,
   `${t('case-statistic-perpetrator-categ-spouse-husb')}`,`${mari}`,       
    `${t('case-statistic-perpetrator-categ-spouse-exhusb')}`,`${exmari}`, 
    `${t('case-statistic-perpetrator-categ-spouse-wife')}`,`${epouse}`,
    `${t('case-statistic-perpetrator-categ-spouse-exwife')}`,`${exepouse}`,
    `${t('case-statistic-perpetrator-categ-spouse-current-male')}`,`${partHomme}`,
    `${t('case-statistic-perpetrator-categ-spouse-current-female')}`,`${partFemme}`,
    `${t('case-statistic-perpetrator-categ-spouse-ex-intim-male')}`,`${expartHomme}`,
    `${t('case-statistic-perpetrator-categ-spouse-ex-intim-female')}`,`${expartFemme}`,
    
    
    ),
    createData3(
     `${t('case-statistic-perpetrator-categ-other')}`,`${autreRelationTotal}`,
     `${t('case-statistic-perpetrator-categ-other-boss-male')}`,`${patronHomDom}`,
      `${t('case-statistic-perpetrator-categ-other-boss-female')}`,`${patronFemDom}`,
      `${t('case-statistic-perpetrator-categ-other-dom-male')}`,`${personelHomDomestique}`,
      `${t('case-statistic-perpetrator-categ-other-dom-female')}`,`${personelFemDomestique}`,
      `${t('case-statistic-perpetrator-categ-other-landlord')}`,`${bailDemezon}`,
      `${t('case-statistic-perpetrator-categ-other-landlady')}`,`${bailleDemezon}`,
      `${t('case-statistic-perpetrator-categ-other-othermember')}`,`${autremember2}`,
      
    ),
  ];
  const classes =useStyles()

    return (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={classes.typochart}>{title1}</TableCell>
              <TableCell align="right" className={classes.typochart}>{title2}</TableCell>
            </TableRow>
          </TableHead>
            {rowsx.map((row) => (
          <TableBody key={row.name}>
              <Row row={row} />
          </TableBody>
            ))}
        </Table>
      </TableContainer>
    );
  }
  export { 
    Auteurtbl
  }
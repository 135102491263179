import {
	Grid,
	Button,
	Typography,
	List,
	ListItem,
	ListItemText,
} from "@material-ui/core";
import useStyles from "../../component/website/campain/style";
import React, { useState, useEffect } from "react";
import Section1 from "../../component/website/campain/section1";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import axiosInstance from "../../service/axiosPub";
import PostMedia from "../../component/website/widgets/postMedia";
import { useTranslation } from "react-i18next";
import CloudOffTwoToneIcon from "@material-ui/icons/CloudOffTwoTone";
import RestoreIcon from "@material-ui/icons/Restore";
import StorageIcon from "@material-ui/icons/Storage";
import CompainSkeleton from "../../component/website/Article/compainSkeleton";
import { Helmet } from "react-helmet";

export default function Campain() {
	const classes = useStyles();
	const [posts, setposts] = useState([]);
	const history = useHistory();
	const { t, i18n } = useTranslation();
	const [spinner2, setspinner2] = useState(true);
	const [errmsg2, seterrmsg2] = useState("");
	const [statecode2, setstatecode2] = useState(false);

	const handleReadmore = () => {
		history.push(`/${i18n.language}${t("link-post-detail")}`);
	};

	const getPostpub = async () => {
		setspinner2(true);

		axiosInstance
			.get(`pubposts/`)
			.then((res) => {
				if (res.status === 200) {
					const data = res.data.posts;
					setposts(data);
					setspinner2(false);
					setstatecode2(true);
					seterrmsg2("Posts successfull fetched");
				}
			})
			.catch((err) => {
				setspinner2(false);
				if (err.response) {
					seterrmsg2("Please check your internet connexion");
				} else if (err.request) {
					seterrmsg2("Please check your internet connexion");
				}
			});
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		getPostpub();
	}, []); //axiosInstance

	const listPostCompain = () => {
		if (statecode2 === false) {
			return (
				<Grid container style={{ marginTop: 100, marginBottom: 100 }}>
					<Grid item xs={12} sm={12}>
						{spinner2 === true ? (
							<CompainSkeleton />
						) : (
							<Grid container justifyContent="center" alignItems="center">
								<Grid item>
									<Grid container justifyContent="center" spacing={2}>
										<Grid item>
											<CloudOffTwoToneIcon size={40} />
										</Grid>
										<Grid item>
											<Typography>{errmsg2}</Typography>
										</Grid>
									</Grid>
									<Grid container justifyContent="center" alignItems="center">
										<Grid item>
											<Button startIcon={<RestoreIcon />} onClick={getPostpub}>
												Reload
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			);
		} else if (statecode2 === true && posts === "") {
			return (
				<Grid container direction="row" justifyContent="center">
					<Grid xs={12} sm={4}></Grid>
					<Grid xs={12} sm={4}>
						<Grid container direction="row">
							<Grid item xs={12} sm={4}>
								<StorageIcon size={60} />
							</Grid>
							<Grid item xs={12} sm={8}>
								<Typography>No Campaign news found</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid xs={12} sm={4}></Grid>
				</Grid>
			);
		} else if (statecode2 === true && posts !== []) {
			return (
				<Grid container spacing={2} style={{ marginBottom: 50 }}>
					{posts
						.filter((e) => e.category === "Campaign news")
						.map((post) => (
							<Grid item xs={12} sm={4} md={4} lg={4}>
								<PostMedia
									category={
										post.category === "Campaign news" ? t("campaigntab") : null
									}
									title={post.title}
									image={post.image}
									color={
										post.category === "Campaign news" ? "#208BD1" : "#D12028"
									}
									description={ReactHtmlParser(
										post.content.length > 180
											? post.content.substr(0, 180) + " ...."
											: post.content
									)}
									posteddate={post.created.substr(0, 10)}
									readmoreClicked={() => {
										handleReadmore();
										localStorage.setItem("pstId", post.id);
									}}
								/>
							</Grid>
						))
						.slice(0, 3)}
				</Grid>
			);
		}
	};
	return (
		<div>
			<Helmet>
				<title>{t("campain")}</title>
				<meta name="description" content={t("campain-section1")} />
			</Helmet>

			<Grid container className={classes.section_1} alignItems="center">
				<Grid xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
				<Grid xs={10} sm={10} md={10} lg={10} xl={8}>
					<Section1 />
				</Grid>
				<Grid xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
			</Grid>
			{/* <Grid  > */}
			<Grid container alignItems="center" style={{ marginTop: 40 }}>
				<Grid item xs={1} sm={1} md={1} lg={2} xl={2}></Grid>
				<Grid
					item
					xs={10}
					sm={10}
					md={10}
					lg={8}
					xl={8}
					className={classes.marginBottom_5}
				>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Typography className={classes.pg_header}>
								{t("campain-title")}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Typography className={classes.dark_txt}>
								{t("campain-section1")}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Typography className={classes.dark_txt}>
								{t("campain-section2")}
							</Typography>
							<List>
								<ListItem>
									<ListItemText>
										<Typography className={classes.dark_txt}>
											{t("campain-section2-1")}
										</Typography>
									</ListItemText>
								</ListItem>
							</List>
							<List>
								<ListItem>
									<ListItemText>
										<Typography className={classes.dark_txt}>
											{t("campain-section2-2")}
										</Typography>
									</ListItemText>
								</ListItem>
							</List>
							<List>
								<ListItem>
									<ListItemText>
										<Typography className={classes.dark_txt}>
											{t("campain-section2-3")}
										</Typography>
									</ListItemText>
								</ListItem>
							</List>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Typography className={classes.dark_txt}>
								{t("campain-section3")}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={1} sm={1} md={1} lg={2} xl={2}></Grid>
			</Grid>

			{/* </Grid> */}
			<Grid>
				{/* <Grid container alignItems="center">
          <Grid item xs={1} sm={2} md={2} lg={2} xl={2}></Grid>
          <Grid item xs={10} sm={8} md={8} lg={8} xl={8}>
            
              <Grid container className={classes.marginBottom_5}>
                <Grid item>
                  <Typography className={classes.pg_header}>
                    Campaign News
                  </Typography>
                </Grid>
              </Grid>
              {listPostCompain()}
    
            </Grid>
          
          <Grid item xs={1} sm={2} md={2} lg={2} xl={2}></Grid>
        </Grid> */}
			</Grid>
		</div>
	);
}

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
//import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import useStyles from "../../../styles";
import { Typography } from "@material-ui/core";

const useRowStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},
});

function createData(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8,
	tpn9,
	vl9,
	tpn10,
	vl10,
	tpn11,
	vl11,
	tpn12,
	vl12
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
			{ contentID: `${tpn9}`, amount: `${vl9}` },
			{ contentID: `${tpn10}`, amount: `${vl10}` },
			{ contentID: `${tpn11}`, amount: `${vl11}` },
			{ contentID: `${tpn12}`, amount: `${vl12}` },
		],
	};
}

function createData2(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8,
	tpn9,
	vl9,
	tpn10,
	vl10,
	tpn11,
	vl11
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
			{ contentID: `${tpn9}`, amount: `${vl9}` },
			{ contentID: `${tpn10}`, amount: `${vl10}` },
			{ contentID: `${tpn11}`, amount: `${vl11}` },
		],
	};
}

function createData3(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
		],
	};
}

function createData4(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
		],
	};
}
function Row(props) {
	const { row } = props;
	const [open, setOpen] = React.useState(false);
	const classes = useRowStyles();

	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row">
				<Typography
						variant="button"
						style={{ fontWeight: 330, textTransform: "unset", textAlign: "left", cursor: "pointer" }}
						onClick={() => setOpen(!open)}
					>
					{row.name}
					</Typography>
				</TableCell>
				<TableCell align="right" style={{ fontWeight: 330 }}>{row.total}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							{/* <Typography variant="h6" gutterBottom component="div">
                Type
              </Typography> */}
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell style={{ fontWeight: 300, fontSize: 18 }}>Préjudice</TableCell>
										<TableCell align="right" style={{ fontWeight: 300, fontSize: 18 }}>Cas</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{row.history.map((historyRow) => (
										<TableRow key={historyRow.contentID}>
											<TableCell style={{ fontWeight: 300 }}>{historyRow.contentID}</TableCell>
											<TableCell align="right" style={{ fontWeight: 300 }}>{historyRow.amount}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

Row.propTypes = {
	row: PropTypes.shape({
		// calories: PropTypes.number.isRequired,
		// carbs: PropTypes.number.isRequired,
		// fat: PropTypes.number.isRequired,
		history: PropTypes.arrayOf(
			PropTypes.shape({
				amount: PropTypes.number.isRequired,
				contentID: PropTypes.any.isRequired,
				// contentID: PropTypes.string.isRequired,
			})
		).isRequired,
		name: PropTypes.string.isRequired,
		total: PropTypes.number.isRequired,
	}).isRequired,
};

const PrejudiceTbl = ({
	title1,
	title2,
	prephysTotal,
	dec,
	mal,
	bles,
	dou,
	troublgyn,
	handicap,
	gros,
	ptDenfa,
	abusDalc,
	abusDedrog,
	abusDemed,
	autr5,
	preEmolTotal,
	risDeSui,
	peur,
	depr,
	detr,
	sent,
	sentDecol,
	troublDesom,
	troublAlim,
	peterDespoir,
	manqueDeconf,
	autr6,
	preEcomTotal,
	perterDeSu,
	perterDeRev,
	sexDesurvie,
	peterDemaison,
	demenagement,
	situation,
	pasoupeu,
	autr7,
	impTotal,
	rupture,
	conflits,
	abndon,
	stigm,
	perterDeGarde,
	isolDelaVictime,
	autr8,
}) => {
	const rowsx = [
		createData(
			"Préjudice physique",
			`${prephysTotal}`,
			"Décès",
			`${dec}`,
			"Maladies (par exemple hypertension)",
			`${mal}`,
			"Blessures",
			`${bles}`,
			"Douleurs",
			`${dou}`,
			"Troubles gynécologiques et reproductifs",
			`${troublgyn}`,
			"Invalidité / Handicap",
			`${handicap}`,
			"Grossesse non voulue",
			`${gros}`,
			"Perte de l'enfant à naître",
			`${ptDenfa}`,
			"Abus d'alcool",
			`${abusDalc}`,
			"Abus de drogues",
			`${abusDedrog}`,
			"Abus de médicaments",
			`${abusDemed}`,
			"Autres",
			`${autr5}`
		),
		createData2(
			"Préjudice émotionnel",
			`${preEmolTotal}`,
			"Risque de suicide",
			`${risDeSui}`,
			"Peur et anxiété",
			`${peur}`,
			"Dépression",
			`${depr}`,
			"Détresse émotionnelle",
			`${detr}`,
			"Sentiment de honte",
			`${sent}`,
			"Sentiment de colère",
			`${sentDecol}`,
			"Troubles de sommeil",
			`${troublDesom}`,
			"Troubles alimentaires",
			`${troublAlim}`,
			"Perte d’espoir",
			`${peterDespoir}`,
			"Manque de confiance en soi",
			`${manqueDeconf}`,
			"Autres",
			`${autr6}`
		),
		createData3(
			"Préjudice économique",
			`${preEcomTotal}`,
			"Perte des moyens de subsistance",
			`${perterDeSu}`,
			"Perte de revenues",
			`${perterDeRev}`,
			"Sexe de survie",
			`${sexDesurvie}`,
			"Perte de maison",
			`${peterDemaison}`,
			"Déménagements fréquents",
			`${demenagement}`,
			"Situation de sans-abri",
			`${situation}`,
			"Pas ou peu d'éducation ou de formation",
			`${pasoupeu}`,
			"Autres",
			`${autr7}`
		),
		createData4(
			"Impact sur les relations",
			`${impTotal}`,
			"Rupture de la famille",
			`${rupture}`,
			"Conflits domestiques réguliers",
			`${conflits}`,
			"Abandon par la famille",
			`${abndon}`,
			"Stigmatisation par la communauté",
			`${stigm}`,
			"Perte de la garde ou des contacts avec les enfants",
			`${perterDeGarde}`,
			"Isolement de la victime",
			`${isolDelaVictime}`,
			"Autres",
			`${autr8}`
		),
	];
	const classes = useStyles();

	return (
		<TableContainer component={Paper}>
			<Table aria-label="collapsible table">
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell className={classes.typochart}>{title1}</TableCell>
						<TableCell align="right" className={classes.typochart}>
							{title2}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rowsx.map((row) => (
						<Row key={row.name} row={row} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}; 
export { PrejudiceTbl };

import { alpha, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  marginTopBot_10: {
    marginTop: "10vh",
    marginBottom: "10vh",
  },

  hr:{
    color: '#FFFF',
    backgroundColor: '#FFFF',
    
    width: "1px",
    borderColor : '#FFFF',
    [theme.breakpoints.down("xs")]: {
       display:'none'
    },
    [theme.breakpoints.only("sm")]: {
      height: 250,
   },
   [theme.breakpoints.only("md")]: {
    height: 220,
 },
 [theme.breakpoints.only("lg")]: {
  height: 200,
},
[theme.breakpoints.up("xl")]: {
  height: 200,
},
  },

  section_4: {
    background: "#4BBDC7",
    height: "auto",
  },
  width_80: {
    width: "80vw",
  },
  width_70: {
    width: "70vw",
  },

  big_white_txt: {
    color: "white",
    fontWeight: 700,
    fontSize: "100px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "40px",
    },
  },
  big_dark_txt: {
    color: "#1D384C",
    fontWeight: 700,
    fontSize: "40px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "23px",
    },
  },

  sub_big_white_txt: {
    color: "white",
    fontWeight: 700,
    fontSize: "40px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "16px",
    },
  },

  white_txt: {
    color: "white",
    fontSize: "16px",
  },

  white_color: {
    color: "white",
    fontWeight:300,
    fontSize:15
    
  },
  white_color_title: {
    
    color: "white",
    fontWeight:400,
    fontSize:23,

    [theme.breakpoints.down('sm')]:{
      fontSize:20
    },
    
  },
  white_color_footer_title: {
    
    color: "white",
    fontWeight:400,
    fontSize:40,

    [theme.breakpoints.down('sm')]:{
      fontSize:23
    },
    
  },
  white_color_footer_subtitle: {
    
    color: "white",
    fontWeight:300,
    fontSize:18,

    [theme.breakpoints.down('sm')]:{
      fontSize:16
    },
    
  },
  white_color_footer_partner: {
    
    color: "white",
    fontWeight:400,
    fontSize:24,

    [theme.breakpoints.down('sm')]:{
      fontSize:18
    },
    
  },
  readmore:{
    fontSize:15,
    textTransform:'lowercase',
    fontWeight:300
  },

  foot_link_hr:{
    color: "#FFFF",
    backgroundColor: "#FFFF",
    height: 0.5,
    borderColor: "#FFF",
    [theme.breakpoints.down('sm')]:{
      marginBottom:30
    },
    [theme.breakpoints.between('md','lg')]:{
      width:500
    }
  },
  

  green_color: {
    color: "#00A1AF",
    fontWeight:300,
    "&:hover": {
      color: "#4F2970",
    },
    [theme.breakpoints.up('md')]:{
      marginRight:7,
      marginLeft:7
    }

  },

  overview_ttl: {
    color: "#1D384C",
    fontWeight: 700,
    textAlign: "center",
    fontSize: "40px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "23px",
    },
    [theme.breakpoints.between('md','lg')]: {
      // marginLeft:"12vh",
      marginLeft:100,
      marginRight:100
    },
  },

  card_txt1: {
    color: "white",
    fontWeight: 400,
    fontSize: "40px",
  },

  tile_txt1: {
    color: "white",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "12px",
    },
    "&:hover": {
      color: "#00A1AF",
    },

  },

  tile_txt2: {
    color: "white",
    fontSize: "14px",
    width:200,
    "&:hover": {
      color: "#00A1AF",
    },
    fontWeight:300,
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "10px",
    },
  },

  about_ttl: {
    color: "#1D384C",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "40px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "23px",
      textAlign: "center",
      marginTop: 30
    },
   

  },
  goal_ttl: {
    color: "#FFF",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "35px",
    [theme.breakpoints.down('md')]: {
  
      fontSize: "20px",
    },
    [theme.breakpoints.between('lg','xl')]: {
  
      fontSize: "26px",
    },
    

  },

  abt_txt: {
    color: "#1D384C",
    textAlign: "left",
    fontWeight: 300,
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
      textAlign: "center",
      
    },
  },

  about_link: {
    color: "#00A1AF",
    fontWeight: 700,
    
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "15px",
      textAlign: "center",
    },
  },

  dark_txt: {
    color: "#1D384C",
    textAlign: "center",
    fontWeight: 300,
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
      
    },
  },
  dark_color: {
    color: "#1D384C",
  },
  oxide_color: {
    color: "#EB9B29",
  },
  oxide_color1: {
    color: '#4BBDC7',
  },
  oxide_text: {
    fontSize: "14px",
    color: "#fff",
    fontWeight:300,
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "12px",
      
    },
  },

  copyright1:{

    [theme.breakpoints.up('md')]:{
      justifyContent:'flex-start',
      alignItems:'flex-start'
    }

  },
  copyright2:{
    [theme.breakpoints.up('sm')]:{
      justifyContent:'flex-end',
      alignItems:'flex-end',
      marginLeft:20
    } 

  },

  overview_link: {
    color: "#00A1AF",
    fontWeight: 700,
    textAlign: "center",
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      fontSize: "15px",
    },
  },

  card_circle: {
    height: "230px",
    width: "230px",
    borderRadius: "50%",
    backgroundColor: "white",
   // justifyContent:'center',

     paddingTop:"35px",

    [theme.breakpoints.down("sm")]: {
       marginLeft:"3vh",
       marginRight:"3vh",
      // display: "center",
      // justifyContent: 'center'
    },
    [theme.breakpoints.only("md")]: {
      marginLeft:"3vh",
      marginRight:"3vh",
     // display: "center",
     // justifyContent: 'center'
   },
   
    [theme.breakpoints.up("xl")]: {
      height: "250px",
    width: "250px",
    },
  },
  card_pic: {
    height: "480px",
    width: "480px",
    backgroundColor: "#00A1AF",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      height: "280px",
      width: "320px",
    },
  },
  card_stl: {
    backgroundColor: "#00A1AF",
   
    [theme.breakpoints.up('md')]:{
      height:200
    }
    //width:200
  },

  // goals_stl: {},
  red_color: {
    color: "#D12028",
  },

  posts: {
    maxWidth: 345,
  },
  posts_media: {
   height: 140,
  },

  marginBottom_5: {
    marginBottom: "5vh",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      marginBottom: "2.5vh",
    },
  },
  marginBot_5: {
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      marginBottom: "2.5vh",
    },
  },
  marginLeft_2: {
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      marginLeft: "2.5vh",
    },
  },
  marginTop_2: {
    marginTop: "2.5vh",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      marginTop: "2.5vh",
    },
  },
  right_align: {
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  table_Color1: {
    color: "#323643",
    fontWeight:300,
  },
  table_Color2: {
    color: "#606470",
    fontWeight:300,
    fontSize:15
  },

  marginBottom_15: {
    marginBottom: "15vh",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      marginBottom: "5vh",
    },
  },
  marginBottom_10: {
    marginBottom: "10vh",
    [theme.breakpoints.down('sm')]:{
      marginBottom: "7vh",
    }
  },

  overview_ttl2: {
    color: "#1D384C",
    fontWeight: 700,
    marginBottom: 35,
    textAlign: "center",
    fontSize: "1vw",
  },

  marginLeft_10: {
    marginLeft: "10vw",
  },

  marginTop_10: {
    marginTop: "10vh",
  },

  marginTop_13: {
    marginTop: "15vh",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      marginTop: "7vh",
    },
  },

  margin_section: {
    marginTop: "15vh",
    marginBottom: "15vh",
    [theme.breakpoints.down("sm")]: {
      marginTop: "7vh",
      marginBottom: "7vh",
    },
},
  
  margin_20:{
   
    [theme.breakpoints.down("xs")]: {
      marginTop:30
    },
  },
  marginTopBot_13: {
    marginTop: "13vh",
    marginBottom: "13vh",
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"12vh",
      marginTop: "7vh",
      marginBottom: "7vh",
    },
  },

  big_dark_tittle: {
    color: "#1D384C",
    fontWeight: 700,
    textAlign: "center",
  },

  card: {
    marginTop: "100px",
    height: "539px",
    widht: "400px",
  },

  divider_stl: {
    widht: "5px",
    color: "white",
  },

  area: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  cardHome: {
    marginTop: "80px",
    height: "539px",
    widht: "431px",
  },
  notchedOutline: {
    borderWidth: "1px solid",
    borderColor: "#979797 !important",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `#00A1AF !important`,
    },
  },
  cssFocused: {},

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },

  bigtitle: {
    marginTop: "140px",
    marginBottom: "60px",
  },
  disconnect: {
    color: "red",
    marginTop: "30px",
    textDecoration: "underline",
  },
  continue: {
    color: "white",
    backgroundColor: "#00A1AF",
    fontSize: 10,
    width: 100,
    "&:hover": {
      backgroundColor: "#00A1AF",
    },
  },
  marginRightLeft: {
    marginLeft: "10vw",
    marginRight: "10vw",
  },

  btn_stl: {
    textAlign: "center",
  },
  btn_txt: {
    textTransform: "unset",
    width:'auto',
    fontWeight:300,
    fontSize:15
   
  },

  dialogTypo: {
    fontWeight: "bold",
  },
  marginBottom_2: {
    marginBottom: "2vh",
  },
  marginBottom_1: {
    marginBottom: "1vh",
  },
  marginBottom_10px: {
    marginBottom: "2vh",
  },
  marginBottom_05: {
    marginBottom: "0.5vh",
  },
  Link_stl: {
    "&:hover": {
      color: "#00A1AF",
    },
    textDecoration: "none",
    color: "white",
    marginTop: "8px",
  },
  Link_stl2: {
    "&:hover": {
      color: "#FFF",
    },
    //textDecoration: "none",
    color: "#00A1AF",
    marginTop: "8px",
    
  },
  loading: {
    color: "white",
    height: "100vh",
  },
  progbar: {
    marginTop: "30vh",
  },
  loadingtext: {
    fontSize: 100,
  },
  marginTopbot_10: {
    marginTop: "10vh",
    marginBottom: "10vh",
  },
  link:{
    textDecoration:'none',
  }
}));

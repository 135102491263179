import {makeStyles} from '@material-ui/core/styles'




export default  makeStyles((theme)=>({

    root:{
        [theme.breakpoints.down('sm')]:{
            marginTop: 50
        },
        [theme.breakpoints.up('md')]:{
            marginTop: 0
        }
    },


    btnColored: {
        backgroundColor: 'transparent',
        color:'#00A1AF',
        border: '1px solid #00A1AF',
        fontSize:'10px',
        fontWeight:300,
        '&:hover':{
            backgroundColor:'transparent'
        },
        marginRight:'20px',
        height:'40px',
        width:  '121px',
             
    },

    btntext:{
        backgroundColor: 'transparent',
        color: '#FF0000',
        fontSize:'10px',
        fontWeight:300,
        '&:hover':{
            backgroundColor:'transparent'
        },
    },

    btntext1:{
        backgroundColor: 'transparent',
        color: '#00A1AF',
        fontSize:'10px',
        fontWeight:300,
        '&:hover':{
            backgroundColor:'transparent'
        },
    },
    

    btnContained:{
        backgroundColor:'#00A1AF',
        color: '#FFFFFF',
        fontSize:'10px',
        fontWeight:300,
        '&:hover':{
            backgroundColor:'#00A1AF'
        },
        height:'40px',
        width:  '121px',
        marginRight:10,
        [theme.breakpoints.down('md')]:{
            width:90,
            marginRight:10
        },
        [theme.breakpoints.down('sm')]:{
            width:80,
            fontSize:10,
            marginRight:10

        }

    },
    btnLogin:{
        backgroundColor:'#00A1AF',
        color: '#FFFFFF',
        fontSize:'10px',
        fontWeight:300,
        '&:hover':{
            backgroundColor:'#00A1AF'
        },
        height:40,
        width:  121,
        // fontWeight:300,
       
        [theme.breakpoints.up('md')]:{
            width:90,
            fontSize:10,
            fontWeight:300
           
        },
        [theme.breakpoints.down('sm')]:{
            width:70,
            fontSize:10,
            fontWeight:400
            
           
        }

    },
    btnContainedSearch:{
        backgroundColor:'#4F2970',
        color: '#FFFFFF',
        fontSize:'10px',
        fontWeight:300,
        '&:hover':{
            backgroundColor:'#4F2970'
        },
        height: 30,
        marginTop:15,

        width:  '121px',
        marginRight:10,
        [theme.breakpoints.down('md')]:{
            width:100,
            marginRight:10
        },
        [theme.breakpoints.down('sm')]:{
            width:100,
            fontSize:10,
            marginRight:10

        }

    },

    title:{
        marginTop:'60px',
        color: '#4F2970',
        fontSize: '36px'
    },
    subtitle:{
        color: '#4F2970',
        fontSize: '20px'
    },

    label:{
        fontSize: '15px',
        color: '#2D2C2F',
        fontWeight:'bold',
        marginBottom:"10px"
    },
    answer:{
        fontSize: '15px',
        color: '#00A1AF',
        fontWeight:'regular',
        marginBottom:"10px"
    },
    sublabel:{
        color: '#4F2970',
        fontSize: '15px',
        fontWeight:'regular',
        marginBottom:"10px"
    },
    subradio:{
        color: '#4F2970',
        fontSize: '14px',
        fontWeight:'regular'
    },
    radiolabel:{
        "& .MuiInputBase-root": {
            padding: 10
          }
    },

    btndrop:{
        [theme.breakpoints.down('sm')]:{
            width:90,
            
        },
        [theme.breakpoints.up('sm')]:{
            width : 100
        },
         marginTop:15,
        //paddingRight:10,
        // marginLeft:10,
        height: 30,
      //  backgroundColor:"#D12028",
      marginRight:10,

        fontSize: 10,
        color: ' #000',
        '&:hover':{
            backgroundColor:'#0000'
        },

    },
    btndrop1:{
        [theme.breakpoints.down('sm')]:{
            width:90,
            
        },
        [theme.breakpoints.up('sm')]:{
            width : 118
        },
        marginTop:15,
        // paddingRight:10,
        // marginLeft:10,
        height: 30,
        backgroundColor:'#00A1AF',
        color: 'white',

        fontSize: 10,
        '&:hover':{
            backgroundColor:'#00A1AF'
        },

    },

    main:{
        [theme.breakpoints.down('xs')]:{
            height: '100vh'
        },
        [theme.breakpoints.up('xs')]:{
            height: '106vh'
        },
        [theme.breakpoints.down('md')]:{
            height: '110vh'
        },
        [theme.breakpoints.up('md')]:{
            height: '100vh'
        }
    },

    btndrop2:{
        [theme.breakpoints.down('sm')]:{
            width:90,
            
        },
        [theme.breakpoints.up('sm')]:{
            width : 100
        },
       //  marginTop:15,
         paddingRight:10,
        marginLeft:10,
        height: 30,
        color:"white",
        backgroundColor:"#D12028",

        fontSize: 10,
        // color: ' #FFF',
        '&:hover':{
            backgroundColor:'#D12028'
        },

    },



  

   
    
})) 







const prod = {
	url: {
		API_URL: `${process.env.REACT_APP_MADRE_API}`,
	},
};

const dev = {
	url: {
		API_URL: `${process.env.REACT_APP_OXIDE_API}`,

		//API_URL: `${process.env.REACT_APP_LOCALHOST_API}`
	},
};
// console.log(process.env.NODE_ENV);
export const config = process.env.NODE_ENV === "development" ? dev : prod;
 
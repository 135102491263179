import React, { useState, useEffect } from "react";
import {
	Grid,
	Typography,
	Box,
	AppBar,
	Toolbar,
	TextField,
	IconButton,
	RadioGroup,
	Radio,
	FormControlLabel,
	FormGroup,
	Checkbox,
	FormControl,
	//TextareaAutosize,
	Button,
	List,
	ListItem,
	Select,
	MenuItem,
} from "@material-ui/core";
import {} from "@material-ui/icons";
import useStyles from "./style";
import {
	q9,
	q10,
	q11,
	q11a,
	q11b,
	q12,
	q12a,
	q12b,
	q13,
	q13a,
	q13b,
	precedent,
	suivant,
	grp3,
} from "../../widget/i1e";
import { BtnContained, BtnOutline } from "../../widget/util";
import { Link, useHistory, useLocation } from "react-router-dom";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useForm } from "react-hook-form";
import regions from "../../../assets/regions.json";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Incedent_info = () => {
	const classes = useStyles();
	//controler for the navbar
	const location = useLocation();
	const [orgData, setorgData] = useState();
	const [select, setSelect] = useState(4);
	const handleChange = (event) => {
		setSelect(event.target.value);
	};
	//
	// const [selectedDate, setSelectedDate] = React.useState(new Date('2019-05-24'));
	const [selectedDateb, setSelectedDateb] = React.useState(
		new Date("2019-05-24")
	);

	const [q10value, setq10value] = useState(false);
	const [q10valueb, setq10valueb] = useState(false);
	const [q10valuec, setq10valuec] = useState(false);
	const [q10valued, setq10valued] = useState(false);
	const [q11avalue, setq11avalue] = useState();
	const [q11bvalue, setq11bvalue] = useState();
	const [q12bvalue, setq12bvalue] = useState();
	const [regionMap, setregionMap] = useState([]);
	const [provinceName, setprovinceName] = useState();
	const [cities, setcities] = useState([]);
	const [incidentDeViolenceSexuel, setincidentDeViolenceSexuel] =
		useState(false);
	const [incidentDeViolencePhysique, setincidentDeViolencePhysique] =
		useState(false);
	const [incidentDeViolencePsychologique, setincidentDeViolencePsychologique] =
		useState(false);
	const [incidentDeViolenceEconomique, setincidentDeViolenceEconomique] =
		useState(false);
	const [prejudicePhysique, setprejudicePhysique] = useState(false);
	const [prejudiceEmotionel, setprejudiceEmotionel] = useState(false);
	const [prejudiceEconomique, setprejudiceEconomique] = useState(false);
	const [impartSurLesRelations, setimpartSurLesRelations] = useState(false);
	const [getinfo, setgetinfo] = useState([]);
	const [openSnackBar, setOpenSnackbar] = useState(false);
	const [open, setOpen] = useState(false);
	const [msg, setmsg] = useState();
	const [prov, setprov] = useState("");
	const [cty, setcty] = useState("");
	const [q11aOui, setq11aOui] = useState("");
	const [q11bOui, setq11bOui] = useState("");
	const [q11aval, setq11aval] = useState("");
	const [q11bval, setq11bval] = useState("");
	const [q11al, setq11al] = useState("");
	const [q11bl, setq11bl] = useState("");

	const history = useHistory();

	// snackbar
	const handleClose = () => {
		setOpen(false);
	};

	const handleSnackBar = () => {
		setOpenSnackbar(true);
	};

	const handleCloseSnackBar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpenSnackbar(false);
	};
	// q10

	//incident de violence sexuelle
	const [viol, setviol] = useState();
	const [violChecked, setviolChecked] = useState(false);
	const q10Viol = (e) => {
		const value = e.target.value;

		if (!violChecked) {
			setviolChecked(true);
			setviol(value);
			//console.log(viol);
			setincidentDeViolenceSexuel(true);
			//console.log(violChecked);
		} else {
			setviolChecked(false);
			setviol();
			//console.log(violChecked);
			setincidentDeViolenceSexuel(false);
			//console.log(viol);
		}
	};
	const [conj, setconj] = useState();
	const [conjChecked, setconjChecked] = useState(false);
	const q10Conjugal = (e) => {
		const value = e.target.value;

		if (!conjChecked) {
			setconjChecked(true);
			setconj(value);
			//console.log(conj);
			setincidentDeViolenceSexuel(true);
			//console.log(conjChecked);
		} else {
			setconjChecked(false);
			setconj();
			//console.log(conjChecked);
			setincidentDeViolenceSexuel(false);
			//console.log(conj);
		}
	};
	const [agressio, setagressio] = useState();
	const [agressioChecked, setagressioChecked] = useState(false);
	const q10Agression = (e) => {
		const value = e.target.value;

		if (!agressioChecked) {
			setagressioChecked(true);
			setagressio(value);
			//console.log(agressioChecked);
			setincidentDeViolenceSexuel(true);
			//console.log(agressio);
		} else {
			setagressio();
			setagressioChecked(false);
			//console.log(agressioChecked);
			setincidentDeViolenceSexuel(false);
			//console.log(agressio);
		}
	};

	const [coercition, setcoercition] = useState();
	const [coerChecked, setcoerChecked] = useState(false);

	const q10Coercition = (e) => {
		const value = e.target.value;

		if (!coerChecked) {
			setcoerChecked(true);
			setcoercition(value);
			//console.log(coerChecked);
			//console.log(coercition);
			setincidentDeViolenceSexuel(true);
		} else {
			setcoercition();
			setcoerChecked(false);
			//console.log(coerChecked);
			setincidentDeViolenceSexuel(false);
			//console.log(coercition);
		}
	};
	const [harcelement, setHarcelement] = useState();
	const [harcChecked, setharcChecked] = useState(false);

	const q10Harcelement = (e) => {
		const value = e.target.value;

		if (!harcChecked) {
			setHarcelement(value);
			setharcChecked(true);
			//console.log(harcChecked);
			//console.log(harcelement);
			setincidentDeViolenceSexuel(true);
		} else {
			setHarcelement();
			setharcChecked(false);
			//console.log(harcChecked);
			//console.log(harcelement);
			setincidentDeViolenceSexuel(false);
		}
	};
	const [attouchement, setAttouchements] = useState();
	const [attouChecked, setattoucChecked] = useState(false);

	const q10Attouchements = (e) => {
		const value = e.target.value;

		if (!attouChecked) {
			setAttouchements(value);
			setattoucChecked(true);
			//console.log(attouChecked);
			//console.log(attouchement);
			setincidentDeViolenceSexuel(true);
		} else {
			setAttouchements();
			setattoucChecked(false);
			//console.log(attouChecked);
			setincidentDeViolenceSexuel(false);
			//console.log(attouchement);
		}
	};
	const [etreForce, setetreForces] = useState();
	const [etrForChecked, setetrForChecked] = useState(false);

	const q10etreForces = (e) => {
		const value = e.target.value;

		if (!etrForChecked) {
			setetreForces(value);
			setetrForChecked(true);
			//console.log(etrForChecked);
			//console.log(etreForce);
			setincidentDeViolenceSexuel(true);
		} else {
			setetreForces();
			setetrForChecked(false);
			//console.log(etrForChecked);
			//console.log(etreForce);
			setincidentDeViolenceSexuel(false);
		}
	};
	const [etreEmpeche, setetreEmpeches] = useState();
	const [etrEmpChecked, setetrEmpChecked] = useState(false);

	const q10etreEmpeches = (e) => {
		const value = e.target.value;

		if (!etrEmpChecked) {
			setetreEmpeches(value);
			setetrEmpChecked(true);
			//console.log(etrEmpChecked);
			//console.log(etreEmpeche);
			setincidentDeViolenceSexuel(true);
		} else {
			setetreEmpeches();
			setetrEmpChecked(false);
			//console.log(etrEmpChecked);
			setincidentDeViolenceSexuel(false);
			//console.log(etreEmpeche);
		}
	};
	const [autreViolSexuel, setautreViolSexuels] = useState();
	const [autreViolSexuelChecked, setautreViolSexuelChecked] = useState(false);

	const q10autreViolSexuels = (e) => {
		const value = e.target.value;

		if (!autreViolSexuelChecked) {
			setautreViolSexuels(value);
			setautreViolSexuelChecked(true);
			//console.log(autreViolSexuelChecked);
			//console.log(autreViolSexuel);
			setincidentDeViolenceSexuel(true);
		} else {
			setautreViolSexuels();
			setautreViolSexuelChecked(false);
			//console.log(autreViolSexuelChecked);
			//console.log(autreViolSexuel);
			setincidentDeViolenceSexuel(false);
		}
	};
	//
	//incident de violence physique
	const [meutre, setmeutre] = useState();
	const [meutrChecked, setmeutrChecked] = useState(false);

	const q10Meutre = (e) => {
		const value = e.target.value;

		if (!meutrChecked) {
			setmeutre(value);
			setmeutrChecked(true);
			//console.log(meutrChecked);
			//console.log(meutre);
			setincidentDeViolencePhysique(true);
		} else {
			setmeutre();
			setmeutrChecked(false);
			//console.log(meutrChecked);
			setincidentDeViolencePhysique(false);
			//console.log(meutre);
		}
	};

	const [coupDePoing, setcoupDePoing] = useState();
	const [coupDePoingChecked, setcoupDePoingChecked] = useState(false);

	const q10coupDePoing = (e) => {
		const value = e.target.value;

		if (!coupDePoingChecked) {
			setcoupDePoing(value);
			setcoupDePoingChecked(true);
			//console.log(coupDePoingChecked);
			setincidentDeViolencePhysique(true);
			//console.log(coupDePoing);
		} else {
			setcoupDePoing();
			setcoupDePoingChecked(false);
			setincidentDeViolencePhysique(false);
			//console.log(coupDePoingChecked);
			//console.log(coupDePoing);
		}
	};

	const [coupDePied, setcoupDePied] = useState();
	const [coupDePiedChecked, setcoupDePiedChecked] = useState(false);

	const q10coupDePied = (e) => {
		const value = e.target.value;

		if (!coupDePiedChecked) {
			setcoupDePied(value);
			setcoupDePiedChecked(true);
			setincidentDeViolencePhysique(true);
			//console.log(coupDePiedChecked);
			//console.log(coupDePied);
		} else {
			setcoupDePied();
			setcoupDePiedChecked(false);
			//console.log(coupDePiedChecked);
			setincidentDeViolencePhysique(false);
			//console.log(coupDePied);
		}
	};

	const [attaque, setattaque] = useState();
	const [attaqueChecked, setattaqueChecked] = useState(false);

	const q10attaque = (e) => {
		const value = e.target.value;

		if (!attaqueChecked) {
			setattaque(value);
			setattaqueChecked(true);
			//console.log(attaqueChecked);
			//console.log(attaque);
			setincidentDeViolencePhysique(true);
		} else {
			setattaque();
			setattaqueChecked(false);
			//console.log(attaqueChecked);
			setincidentDeViolencePhysique(false);
			//console.log(attaque);
		}
	};

	const [attaqueACouteau, setattaqueACouteau] = useState();
	const [attaqueACouteauChecked, setattaqueACouteauChecked] = useState(false);

	const q10attaqueACouteau = (e) => {
		const value = e.target.value;

		if (!attaqueACouteauChecked) {
			setattaqueACouteau(value);
			setattaqueACouteauChecked(true);
			setincidentDeViolencePhysique(true);
			//console.log(attaqueACouteauChecked);
			//console.log(attaqueACouteau);
		} else {
			setattaqueACouteau();
			setattaqueACouteauChecked(false);
			setincidentDeViolencePhysique(false);
			//console.log(attaqueACouteauChecked);
			//console.log(attaqueACouteau);
		}
	};

	const [attaqueAEaux, setattaqueAEaux] = useState();
	const [attaqueAEauxChecked, setattaqueAEauxChecked] = useState(false);

	const q10attaqueAEaux = (e) => {
		const value = e.target.value;

		if (!attaqueAEauxChecked) {
			setattaqueAEaux(value);
			setincidentDeViolencePhysique(true);
			setattaqueAEauxChecked(true);
			//console.log(attaqueAEauxChecked);
			//console.log(attaqueAEaux);
		} else {
			setattaqueAEaux();
			setattaqueAEauxChecked(false);
			setincidentDeViolencePhysique(false);
			//console.log(attaqueAEauxChecked);
			//console.log(attaqueAEaux);
		}
	};

	const [privation, setprivation] = useState();
	const [privationChecked, setprivationChecked] = useState(false);

	const q10privation = (e) => {
		const value = e.target.value;

		if (!privationChecked) {
			setprivation(value);
			setprivationChecked(true);
			setincidentDeViolencePhysique(true);
			//console.log(privationChecked);
			//console.log(privation);
		} else {
			setprivation();
			setprivationChecked(false);
			setincidentDeViolencePhysique(false);
			//console.log(privationChecked);
			//console.log(privation);
		}
	};

	const [privationDeNouriture, setprivationDeNouriture] = useState();
	const [privationDeNouritureChecked, setprivationDeNouritureChecked] =
		useState(false);

	const q10privationDeNouriture = (e) => {
		const value = e.target.value;

		if (!privationDeNouritureChecked) {
			setprivationDeNouriture(value);
			setprivationDeNouritureChecked(true);
			setincidentDeViolencePhysique(true);
			//console.log(privationDeNouritureChecked);
			//console.log(privationDeNouriture);
		} else {
			setprivationDeNouriture();
			setprivationDeNouritureChecked(false);
			setincidentDeViolencePhysique(false);
			//console.log(privationDeNouritureChecked);
			//console.log(privationDeNouriture);
		}
	};

	const [negligencePhyDenfant, setnegligencePhyDenfant] = useState();
	const [negligencePhyDenfantChecked, setnegligencePhyDenfantChecked] =
		useState(false);

	const q10negligencePhyDenfant = (e) => {
		const value = e.target.value;

		if (!negligencePhyDenfantChecked) {
			setnegligencePhyDenfant(value);
			setnegligencePhyDenfantChecked(true);
			setincidentDeViolencePhysique(true);
			//console.log(negligencePhyDenfantChecked);
			//console.log(negligencePhyDenfant);
		} else {
			setnegligencePhyDenfant();
			setnegligencePhyDenfantChecked(false);
			setincidentDeViolencePhysique(false);
			//console.log(negligencePhyDenfantChecked);
			//console.log(negligencePhyDenfant);
		}
	};

	const [negligencePhyPerson, setnegligencePhyPerson] = useState();
	const [negligencePhyPersonChecked, setnegligencePhyPersonChecked] =
		useState(false);

	const q10negligencePhyPerson = (e) => {
		const value = e.target.value;

		if (!negligencePhyPersonChecked) {
			setnegligencePhyPerson(value);
			setnegligencePhyPersonChecked(true);
			setincidentDeViolencePhysique(true);
			//console.log(negligencePhyPersonChecked);
			//console.log(negligencePhyPerson);
		} else {
			setnegligencePhyPerson();
			setnegligencePhyPersonChecked(false);
			setincidentDeViolencePhysique(false);
			//console.log(negligencePhyPersonChecked);
			//console.log(negligencePhyPerson);
		}
	};
	const [destructionDobject, setdestructionDobject] = useState();
	const [destructionDobjectChecked, setdestructionDobjectChecked] =
		useState(false);

	const q10destructionDobject = (e) => {
		const value = e.target.value;

		if (!destructionDobjectChecked) {
			setdestructionDobject(value);
			setincidentDeViolencePhysique(true);
			setdestructionDobjectChecked(true);
			//console.log(destructionDobjectChecked);
			//console.log(destructionDobject);
		} else {
			setdestructionDobject();
			setincidentDeViolencePhysique(false);
			setdestructionDobjectChecked(false);
			//console.log(destructionDobjectChecked);
			//console.log(destructionDobject);
		}
	};

	const [ritesDeVeuvage, setritesDeVeuvage] = useState();
	const [ritesDeVeuvageChecked, setritesDeVeuvageChecked] = useState(false);

	const q10ritesDeVeuvage = (e) => {
		const value = e.target.value;

		if (!ritesDeVeuvageChecked) {
			setritesDeVeuvage(value);
			setincidentDeViolencePhysique(true);
			setritesDeVeuvageChecked(true);
			//console.log(ritesDeVeuvageChecked);
			//console.log(ritesDeVeuvage);
		} else {
			setritesDeVeuvage();
			setritesDeVeuvageChecked(false);
			setincidentDeViolencePhysique(false);
			//console.log(ritesDeVeuvageChecked);
			//console.log(ritesDeVeuvage);
		}
	};

	const [autreViolPhysique, setAutresViolPhysique] = useState();
	const [autreViolPhysiqueChecked, setAutresViolPhysiqueChecked] =
		useState(false);

	const q10autreViolPhysique = (e) => {
		const value = e.target.value;

		if (!autreViolPhysiqueChecked) {
			setAutresViolPhysique(value);
			setincidentDeViolencePhysique(true);
			setAutresViolPhysiqueChecked(true);
			//console.log(autreViolPhysiqueChecked);
			//console.log(autreViolPhysique);
		} else {
			setAutresViolPhysique();
			setAutresViolPhysiqueChecked(false);
			setincidentDeViolencePhysique(false);
			//console.log(autreViolPhysiqueChecked);
			//console.log(autreViolPhysique);
		}
	};

	//
	//incident de violence psychologique
	const [menace, setmenace] = useState();
	const [menaceChecked, setmenaceChecked] = useState(false);

	const q10menace = (e) => {
		const value = e.target.value;

		if (!menaceChecked) {
			setmenace(value);
			setmenaceChecked(true);
			//console.log(menaceChecked);
			setincidentDeViolencePsychologique(true);
			//console.log(menace);
		} else {
			setmenace();
			setmenaceChecked(false);
			//console.log(menaceChecked);
			setincidentDeViolencePsychologique(false);
			//console.log(menace);
		}
	};

	const [injure, setinjure] = useState();
	const [injureChecked, setinjureChecked] = useState(false);

	const q10injure = (e) => {
		const value = e.target.value;

		if (!injureChecked) {
			setinjure(value);
			setinjureChecked(true);
			//console.log(injureChecked);
			setincidentDeViolencePsychologique(true);
			//console.log(injure);
		} else {
			setinjure();
			setinjureChecked(false);
			//console.log(injureChecked);
			setincidentDeViolencePsychologique(false);
			//console.log(injure);
		}
	};
	const [negligenceEmotionnel, setnegligenceEmotionnel] = useState();
	const [negligenceEmotionnelChecked, setnegligenceEmotionnelChecked] =
		useState(false);

	const q10negligenceEmotionnel = (e) => {
		const value = e.target.value;

		if (!negligenceEmotionnelChecked) {
			setnegligenceEmotionnel(value);
			setnegligenceEmotionnelChecked(true);
			//console.log(negligenceEmotionnelChecked);
			//console.log(negligenceEmotionnel);
			setincidentDeViolencePsychologique(true);
		} else {
			setnegligenceEmotionnel();
			setnegligenceEmotionnelChecked(false);
			setincidentDeViolencePsychologique(false);
			//console.log(negligenceEmotionnelChecked);
			//console.log(negligenceEmotionnel);
		}
	};

	const [humiliationFace, sethumiliationFace] = useState();
	const [humiliationFaceChecked, sethumiliationFaceChecked] = useState(false);

	const q10humiliationFace = (e) => {
		const value = e.target.value;

		if (!humiliationFaceChecked) {
			sethumiliationFace(value);
			sethumiliationFaceChecked(true);
			//console.log(humiliationFaceChecked);
			setincidentDeViolencePsychologique(true);
			//console.log(humiliationFace);
		} else {
			sethumiliationFace();
			sethumiliationFaceChecked(false);
			setincidentDeViolencePsychologique(false);
			//console.log(humiliationFaceChecked);
			//console.log(humiliationFace);
		}
	};

	const [attaqueVisant, setattaqueVisant] = useState();
	const [attaqueVisantChecked, setattaqueVisantChecked] = useState(false);

	const q10attaqueVisant = (e) => {
		const value = e.target.value;

		if (!attaqueVisantChecked) {
			setattaqueVisant(value);
			setattaqueVisantChecked(true);
			//console.log(attaqueVisantChecked);
			setincidentDeViolencePsychologique(true);
			//console.log(attaqueVisant);
		} else {
			setattaqueVisant();
			setattaqueVisantChecked(false);
			//console.log(attaqueVisantChecked);
			setincidentDeViolencePsychologique(false);
			//console.log(attaqueVisant);
		}
	};
	const [isolementDeLaFamille, setisolementDeLaFamille] = useState();
	const [isolementDeLaFamilleChecked, setisolementDeLaFamilleChecked] =
		useState(false);

	const q10isolementDeLaFamille = (e) => {
		const value = e.target.value;

		if (!isolementDeLaFamilleChecked) {
			setisolementDeLaFamille(value);
			setisolementDeLaFamilleChecked(true);
			setincidentDeViolencePsychologique(true);
			//console.log(isolementDeLaFamilleChecked);
			//console.log(isolementDeLaFamille);
		} else {
			setisolementDeLaFamille();
			setisolementDeLaFamilleChecked(false);
			setincidentDeViolencePsychologique(false);
			//console.log(isolementDeLaFamilleChecked);
			//console.log(isolementDeLaFamille);
		}
	};
	const [controleDeMouvement, setcontroleDeMouvement] = useState();
	const [controleDeMouvementChecked, setcontroleDeMouvementChecked] =
		useState(false);

	const q10controleDeMouvement = (e) => {
		const value = e.target.value;

		if (!controleDeMouvementChecked) {
			setcontroleDeMouvement(value);
			setcontroleDeMouvementChecked(true);
			setincidentDeViolencePsychologique(true);
			//console.log(controleDeMouvementChecked);
			//console.log(controleDeMouvement);
		} else {
			setcontroleDeMouvement();
			setcontroleDeMouvementChecked(false);
			setincidentDeViolencePsychologique(false);
			//console.log(controleDeMouvementChecked);
			//console.log(controleDeMouvement);
		}
	};

	const [controleDeLaCommunication, setcontroleDeLaCommunication] = useState();
	const [
		controleDeLaCommunicationChecked,
		setcontroleDeLaCommunicationChecked,
	] = useState(false);

	const q10controleDeLaCommunication = (e) => {
		const value = e.target.value;

		if (!controleDeLaCommunicationChecked) {
			setcontroleDeLaCommunication(value);
			setcontroleDeLaCommunicationChecked(true);
			setincidentDeViolencePsychologique(true);
			//console.log(controleDeLaCommunicationChecked);
			//console.log(controleDeLaCommunication);
		} else {
			setcontroleDeLaCommunication();
			setcontroleDeLaCommunicationChecked(false);
			setincidentDeViolencePsychologique(false);
			//console.log(controleDeLaCommunicationChecked);
			//console.log(controleDeLaCommunication);
		}
	};

	const [refusDeDivorce, setrefusDeDivorce] = useState();
	const [refusDeDivorceChecked, setrefusDeDivorceChecked] = useState(false);

	const q10refusDeDivorce = (e) => {
		const value = e.target.value;

		if (!refusDeDivorceChecked) {
			setrefusDeDivorce(value);
			setrefusDeDivorceChecked(true);
			//console.log(refusDeDivorceChecked);
			//console.log(refusDeDivorce);
			setincidentDeViolencePsychologique(true);
		} else {
			setrefusDeDivorce();
			setrefusDeDivorceChecked(false);
			setincidentDeViolencePsychologique(false);
			//console.log(refusDeDivorceChecked);
			//console.log(refusDeDivorce);
		}
	};
	const [abusSpirituel, setabusSpirituel] = useState();
	const [abusSpirituelChecked, setabusSpirituelChecked] = useState(false);

	const q10abusSpirituel = (e) => {
		const value = e.target.value;

		if (!abusSpirituelChecked) {
			setabusSpirituel(value);
			setabusSpirituelChecked(true);
			setincidentDeViolencePsychologique(true);
			//console.log(abusSpirituelChecked);
			//console.log(abusSpirituel);
		} else {
			setabusSpirituel();
			setabusSpirituelChecked(false);
			setincidentDeViolencePsychologique(false);
			//console.log(abusSpirituelChecked);
			//console.log(abusSpirituel);
		}
	};
	const [autreViolPsychologique, setautreViolPsychologique] = useState();
	const [autreViolPsychologiqueChecked, setautreViolPsychologiqueChecked] =
		useState(false);

	const q10autreViolPsychologique = (e) => {
		const value = e.target.value;

		if (!autreViolPsychologiqueChecked) {
			setautreViolPsychologique(value);
			setincidentDeViolencePsychologique(true);
			setautreViolPsychologiqueChecked(true);
			//console.log(autreViolPsychologiqueChecked);
			//console.log(autreViolPsychologique);
		} else {
			setautreViolPsychologique();
			setautreViolPsychologiqueChecked(false);
			setincidentDeViolencePsychologique(false);
			//console.log(autreViolPsychologiqueChecked);
			//console.log(autreViolPsychologique);
		}
	};
	//
	//incident de violence economique
	const [abondonDeLaFamille, setabondonDeLaFamille] = useState();
	const [abondonDeLaFamilleChecked, setabondonDeLaFamilleChecked] =
		useState(false);

	const q10abondonDeLaFamille = (e) => {
		const value = e.target.value;

		if (!abondonDeLaFamilleChecked) {
			setabondonDeLaFamille(value);
			setabondonDeLaFamilleChecked(true);
			setincidentDeViolenceEconomique(true);
			//console.log(abondonDeLaFamilleChecked);
			//console.log(abondonDeLaFamille);
		} else {
			setabondonDeLaFamille();
			setabondonDeLaFamilleChecked(false);
			setincidentDeViolenceEconomique(false);
			//console.log(abondonDeLaFamilleChecked);
			//console.log(abondonDeLaFamille);
		}
	};
	const [confiscationDeRevenue, setconfiscationDeRevenue] = useState();
	const [confiscationDeRevenueChecked, setconfiscationDeRevenueChecked] =
		useState(false);

	const q10confiscationDeRevenue = (e) => {
		const value = e.target.value;

		if (!confiscationDeRevenueChecked) {
			setconfiscationDeRevenue(value);
			setconfiscationDeRevenueChecked(true);
			setincidentDeViolenceEconomique(true);
			//console.log(confiscationDeRevenueChecked);
			//console.log(confiscationDeRevenue);
		} else {
			setconfiscationDeRevenue();
			setconfiscationDeRevenueChecked(false);
			setincidentDeViolenceEconomique(false);
			//console.log(confiscationDeRevenueChecked);
			//console.log(confiscationDeRevenue);
		}
	};
	const [interdictionDeParticipation, setinterdictionDeParticipation] =
		useState();
	const [
		interdictionDeParticipationChecked,
		setinterdictionDeParticipationChecked,
	] = useState(false);

	const q10interdictionDeParticipation = (e) => {
		const value = e.target.value;

		if (!interdictionDeParticipationChecked) {
			setinterdictionDeParticipation(value);
			setinterdictionDeParticipationChecked(true);
			setincidentDeViolenceEconomique(true);
			//console.log(interdictionDeParticipationChecked);
			//console.log(interdictionDeParticipation);
		} else {
			setinterdictionDeParticipation();
			setinterdictionDeParticipationChecked(false);
			setincidentDeViolenceEconomique(false);
			//console.log(interdictionDeParticipationChecked);
			//console.log(interdictionDeParticipation);
		}
	};
	const [restrictionDeLAccessDEducation, setrestrictionDeLAccessDEducation] =
		useState();
	const [
		restrictionDeLAccessDEducationChecked,
		setrestrictionDeLAccessDEducationChecked,
	] = useState(false);

	const q10restrictionDeLAccessDEducation = (e) => {
		const value = e.target.value;

		if (!restrictionDeLAccessDEducationChecked) {
			setrestrictionDeLAccessDEducation(value);
			setrestrictionDeLAccessDEducationChecked(true);
			setincidentDeViolenceEconomique(true);
			//console.log(restrictionDeLAccessDEducationChecked);
			//console.log(restrictionDeLAccessDEducation);
		} else {
			setrestrictionDeLAccessDEducation();
			setrestrictionDeLAccessDEducationChecked(false);
			setincidentDeViolenceEconomique(false);
			//console.log(restrictionDeLAccessDEducationChecked);
			//console.log(restrictionDeLAccessDEducation);
		}
	};
	const [restrictionDeLaccessDeLemploi, setrestrictionDeLaccessDeLemploi] =
		useState();
	const [
		restrictionDeLaccessDeLemploiChecked,
		setrestrictionDeLaccessDeLemploiChecked,
	] = useState(false);

	const q10restrictionDeLaccessDeLemploi = (e) => {
		const value = e.target.value;

		if (!restrictionDeLaccessDeLemploiChecked) {
			setrestrictionDeLaccessDeLemploi(value);
			setrestrictionDeLaccessDeLemploiChecked(true);
			setincidentDeViolenceEconomique(true);
			//console.log(restrictionDeLaccessDeLemploiChecked);
			//console.log(restrictionDeLaccessDeLemploi);
		} else {
			setrestrictionDeLaccessDeLemploi();
			setrestrictionDeLaccessDeLemploiChecked(false);
			setincidentDeViolenceEconomique(false);
			//console.log(restrictionDeLaccessDeLemploiChecked);
			//console.log(restrictionDeLaccessDeLemploi);
		}
	};
	const [
		restrictionDeLAccessAuxRessource,
		setrestrictionDeLAccessAuxRessource,
	] = useState();
	const [
		restrictionDeLAccessAuxRessourceChecked,
		setrestrictionDeLAccessAuxRessourceChecked,
	] = useState(false);

	const q10restrictionDeLAccessAuxRessource = (e) => {
		const value = e.target.value;

		if (!restrictionDeLAccessAuxRessourceChecked) {
			setrestrictionDeLAccessAuxRessource(value);
			setrestrictionDeLAccessAuxRessourceChecked(true);
			setincidentDeViolenceEconomique(true);
			//console.log(restrictionDeLAccessAuxRessourceChecked);
			//console.log(restrictionDeLAccessAuxRessource);
		} else {
			setrestrictionDeLAccessAuxRessource();
			setrestrictionDeLAccessAuxRessourceChecked(false);
			setincidentDeViolenceEconomique(false);
			//console.log(restrictionDeLAccessAuxRessourceChecked);
			//console.log(restrictionDeLAccessAuxRessource);
		}
	};
	const [privationDePriseChange, setprivationDePriseChange] = useState();
	const [privationDePriseChangeChecked, setprivationDePriseChangeChecked] =
		useState(false);

	const q10privationDePriseChange = (e) => {
		const value = e.target.value;

		if (!privationDePriseChangeChecked) {
			setprivationDePriseChange(value);
			setprivationDePriseChangeChecked(true);
			setincidentDeViolenceEconomique(true);
			//console.log(privationDePriseChangeChecked);
			//console.log(privationDePriseChange);
		} else {
			setprivationDePriseChange();
			setprivationDePriseChangeChecked(false);
			setincidentDeViolenceEconomique(false);
			//console.log(privationDePriseChangeChecked);
			//console.log(privationDePriseChange);
		}
	};
	const [levirat, setlevirat] = useState();
	const [leviratChecked, setleviratChecked] = useState(false);

	const q10levirat = (e) => {
		const value = e.target.value;

		if (!leviratChecked) {
			setlevirat(value);
			setleviratChecked(true);
			setincidentDeViolenceEconomique(true);
			//console.log(leviratChecked);
			//console.log(levirat);
		} else {
			setlevirat();
			setleviratChecked(false);
			setincidentDeViolenceEconomique(false);
			//console.log(leviratChecked);
			//console.log(levirat);
		}
	};
	const [perterDePropriete, setperterDePropriete] = useState();
	const [perterDeProprieteChecked, setperterDeProprieteChecked] =
		useState(false);

	const q10perterDePropriete = (e) => {
		const value = e.target.value;

		if (!perterDeProprieteChecked) {
			setperterDePropriete(value);
			setperterDeProprieteChecked(true);
			setincidentDeViolenceEconomique(true);
			//console.log(perterDeProprieteChecked);
			//console.log(perterDePropriete);
		} else {
			setperterDePropriete();
			setperterDeProprieteChecked(false);
			setincidentDeViolenceEconomique(false);
			//console.log(perterDeProprieteChecked);
			//console.log(perterDePropriete);
		}
	};
	const [perterDeLenfant, setperterDeLenfant] = useState();
	const [perterDeLenfantChecked, setperterDeLenfantChecked] = useState(false);

	const q10perterDeLenfant = (e) => {
		const value = e.target.value;

		if (!perterDeLenfantChecked) {
			setperterDeLenfant(value);
			setperterDeLenfantChecked(true);
			setincidentDeViolenceEconomique(true);
			//console.log(perterDeLenfantChecked);
			//console.log(perterDeLenfant);
		} else {
			setperterDeLenfant();
			setperterDeLenfantChecked(false);
			setincidentDeViolenceEconomique(false);
			//console.log(perterDeLenfantChecked);
			//console.log(perterDeLenfant);
		}
	};
	const [autreViolEconomique, setautreViolEconomique] = useState();
	const [autreViolEconomiqueChecked, setautreViolEconomiqueChecked] =
		useState(false);

	const q10autreViolEconomique = (e) => {
		const value = e.target.value;

		if (!autreViolEconomiqueChecked) {
			setautreViolEconomique(value);
			setautreViolEconomiqueChecked(true);
			setincidentDeViolenceEconomique(true);
			//console.log(autreViolEconomiqueChecked);
			//console.log(autreViolEconomique);
		} else {
			setautreViolEconomique();
			setautreViolEconomiqueChecked(false);
			setincidentDeViolenceEconomique(false);
			//console.log(autreViolEconomiqueChecked);
			//console.log(autreViolEconomique);
		}
	};
	//

	//q13b

	//Prejudice physique
	const [deces, setdeces] = useState();
	const [decesChecked, setdecesChecked] = useState(false);

	const q13deces = (e) => {
		const value = e.target.value;

		if (!decesChecked) {
			setdeces(value);
			setdecesChecked(true);
			//console.log(decesChecked);
			setprejudicePhysique(true);
			//console.log(deces);
		} else {
			setdeces();
			setdecesChecked(false);
			//console.log(decesChecked);
			setprejudicePhysique(false);
			//console.log(deces);
		}
	};
	const [maladie, setmaladie] = useState();
	const [maladieChecked, setmaladieChecked] = useState(false);

	const q13maladie = (e) => {
		const value = e.target.value;

		if (!maladieChecked) {
			setmaladie(value);
			setmaladieChecked(true);
			//console.log(maladieChecked);
			setprejudicePhysique(true);
			//console.log(maladie);
		} else {
			setmaladie();
			setmaladieChecked(false);
			setprejudicePhysique(false);
			//console.log(maladieChecked);
			//console.log(maladie);
		}
	};

	const [blessure, setblessure] = useState();
	const [blessureChecked, setblessureChecked] = useState(false);

	const q13blessure = (e) => {
		const value = e.target.value;

		if (!blessureChecked) {
			setblessure(value);
			setblessureChecked(true);
			setprejudicePhysique(true);
			//console.log(blessureChecked);
			//console.log(blessure);
		} else {
			setblessure();
			setblessureChecked(false);
			setprejudicePhysique(false);
			//console.log(blessureChecked);
			//console.log(blessure);
		}
	};

	const [douleur, setdouleur] = useState();
	const [douleurChecked, setdouleurChecked] = useState(false);

	const q13douleur = (e) => {
		const value = e.target.value;

		if (!douleurChecked) {
			setdouleur(value);
			setdouleurChecked(true);
			setprejudicePhysique(true);
			//console.log(douleurChecked);
			//console.log(douleur);
		} else {
			setdouleur();
			setdouleurChecked(false);
			setprejudicePhysique(false);
			//console.log(douleurChecked);
			//console.log(douleur);
		}
	};
	const [troubleGyn, settroubleGyn] = useState();
	const [troubleGynChecked, settroubleGynChecked] = useState(false);

	const q13troubleGyn = (e) => {
		const value = e.target.value;

		if (!troubleGynChecked) {
			settroubleGyn(value);
			settroubleGynChecked(true);
			setprejudicePhysique(true);
			//console.log(troubleGynChecked);
			//console.log(troubleGyn);
		} else {
			settroubleGyn();
			settroubleGynChecked(false);
			setprejudicePhysique(false);
			//console.log(troubleGynChecked);
			//console.log(troubleGyn);
		}
	};
	const [handicap, sethandicap] = useState();
	const [handicapChecked, sethandicapChecked] = useState(false);

	const q13handicap = (e) => {
		const value = e.target.value;

		if (!handicapChecked) {
			sethandicap(value);
			sethandicapChecked(true);
			setprejudicePhysique(true);
			//console.log(handicapChecked);
			//console.log(handicap);
		} else {
			sethandicap();
			sethandicapChecked(false);
			//console.log(handicapChecked);
			setprejudicePhysique(false);
			//console.log(handicap);
		}
	};
	const [grossesse, setgrossesse] = useState();
	const [grossesseChecked, setgrossesseChecked] = useState(false);

	const q13grossesse = (e) => {
		const value = e.target.value;

		if (!grossesseChecked) {
			setgrossesse(value);
			setgrossesseChecked(true);
			setprejudicePhysique(true);
			//console.log(grossesseChecked);
			//console.log(grossesse);
		} else {
			setgrossesse();
			setgrossesseChecked(false);
			setprejudicePhysique(false);
			//console.log(grossesseChecked);
			//console.log(grossesse);
		}
	};
	const [perteDeLenfantANaitre, setperteDeLenfantANaitre] = useState();
	const [perteDeLenfantANaitreChecked, setperteDeLenfantANaitreChecked] =
		useState(false);

	const q13perteDeLenfantANaitre = (e) => {
		const value = e.target.value;

		if (!perteDeLenfantANaitreChecked) {
			setperteDeLenfantANaitre(value);
			setperteDeLenfantANaitreChecked(true);
			setprejudicePhysique(true);
			//console.log(perteDeLenfantANaitreChecked);
			//console.log(perteDeLenfantANaitre);
		} else {
			setperteDeLenfantANaitre();
			setperteDeLenfantANaitreChecked(false);
			setprejudicePhysique(false);
			//console.log(perteDeLenfantANaitreChecked);
			//console.log(perteDeLenfantANaitre);
		}
	};
	const [abusDAlcools, setabusDAlcools] = useState();
	const [abusDAlcoolsChecked, setabusDAlcoolsChecked] = useState(false);

	const q13abusDAlcools = (e) => {
		const value = e.target.value;

		if (!abusDAlcoolsChecked) {
			setabusDAlcools(value);
			setabusDAlcoolsChecked(true);
			setprejudicePhysique(true);
			//console.log(abusDAlcoolsChecked);
			//console.log(abusDAlcools);
		} else {
			setabusDAlcools();
			setabusDAlcoolsChecked(false);
			setprejudicePhysique(false);
			//console.log(abusDAlcoolsChecked);
			//console.log(abusDAlcools);
		}
	};
	const [abusDeDrogues, setabusDeDrogues] = useState();
	const [abusDeDroguesChecked, setabusDeDroguesChecked] = useState(false);

	const q13abusDeDrogues = (e) => {
		const value = e.target.value;

		if (!abusDeDroguesChecked) {
			setabusDeDrogues(value);
			setabusDeDroguesChecked(true);
			setprejudicePhysique(true);
			//console.log(abusDeDroguesChecked);
			//console.log(abusDeDrogues);
		} else {
			setabusDeDrogues();
			setabusDeDroguesChecked(false);
			setprejudicePhysique(false);
			//console.log(abusDeDroguesChecked);
			//console.log(abusDeDrogues);
		}
	};
	const [abusDeMedicaments, setabusDeMedicaments] = useState();
	const [abusDeMedicamentsChecked, setabusDeMedicamentsChecked] =
		useState(false);

	const q13abusDeMedicaments = (e) => {
		const value = e.target.value;

		if (!abusDeMedicamentsChecked) {
			setabusDeMedicaments(value);
			setabusDeMedicamentsChecked(true);
			setprejudicePhysique(true);
			//console.log(abusDeMedicamentsChecked);
			//console.log(abusDeMedicaments);
		} else {
			setabusDeMedicaments();
			setabusDeMedicamentsChecked(false);
			setprejudicePhysique(false);
			//console.log(abusDeMedicamentsChecked);
			//console.log(abusDeMedicaments);
		}
	};
	const [autrePrejudicePhysique, setautrePrejudicePhysique] = useState();
	const [autrePrejudicePhysiqueChecked, setautrePrejudicePhysiqueChecked] =
		useState(false);

	const q13autrePrejudicePhysique = (e) => {
		const value = e.target.value;

		if (!autrePrejudicePhysiqueChecked) {
			setautrePrejudicePhysique(value);
			setautrePrejudicePhysiqueChecked(true);
			setprejudicePhysique(true);
			//console.log(autrePrejudicePhysiqueChecked);
			//console.log(autrePrejudicePhysique);
		} else {
			setautrePrejudicePhysique();
			setautrePrejudicePhysiqueChecked(false);
			setprejudicePhysique(false);
			//console.log(autrePrejudicePhysiqueChecked);
			//console.log(autrePrejudicePhysique);
		}
	};

	//
	//Prejudice emotionel
	const [risqueDeSuicide, setrisqueDeSuicide] = useState();
	const [risqueDeSuicideChecked, setrisqueDeSuicideChecked] = useState(false);

	const q13risqueDeSuicide = (e) => {
		const value = e.target.value;

		if (!risqueDeSuicideChecked) {
			setrisqueDeSuicide(value);
			setrisqueDeSuicideChecked(true);
			setprejudiceEmotionel(true);
			//console.log(risqueDeSuicideChecked);
			//console.log(risqueDeSuicide);
		} else {
			setrisqueDeSuicide();
			setrisqueDeSuicideChecked(false);
			setprejudiceEmotionel(false);
			//console.log(risqueDeSuicideChecked);
			//console.log(risqueDeSuicide);
		}
	};
	const [peurEtAnxiete, setpeurEtAnxiete] = useState();
	const [peurEtAnxieteChecked, setpeurEtAnxieteChecked] = useState(false);

	const q13peurEtAnxiete = (e) => {
		const value = e.target.value;

		if (!peurEtAnxieteChecked) {
			setpeurEtAnxiete(value);
			setpeurEtAnxieteChecked(true);
			setprejudiceEmotionel(true);
			//console.log(peurEtAnxieteChecked);
			//console.log(peurEtAnxiete);
		} else {
			setpeurEtAnxiete();
			setpeurEtAnxieteChecked(false);
			setprejudiceEmotionel(false);
			//console.log(peurEtAnxieteChecked);
			//console.log(peurEtAnxiete);
		}
	};
	const [depression, setdepression] = useState();
	const [depressionChecked, setdepressionChecked] = useState(false);

	const q13depression = (e) => {
		const value = e.target.value;

		if (!depressionChecked) {
			setdepression(value);
			setdepressionChecked(true);
			setprejudiceEmotionel(true);
			//console.log(depressionChecked);
			//console.log(depression);
		} else {
			setdepression();
			setdepressionChecked(false);
			setprejudiceEmotionel(false);
			//console.log(depressionChecked);
			//console.log(depression);
		}
	};
	const [detresse, setdetresse] = useState();
	const [detresseChecked, setdetresseChecked] = useState(false);

	const q13detresse = (e) => {
		const value = e.target.value;

		if (!detresseChecked) {
			setdetresse(value);
			setdetresseChecked(true);
			setprejudiceEmotionel(true);
			//console.log(detresseChecked);
			//console.log(detresse);
		} else {
			setdetresse();
			setdetresseChecked(false);
			setprejudiceEmotionel(false);
			//console.log(detresseChecked);
			//console.log(detresse);
		}
	};
	const [sentimentDeHonte, setsentimentDeHonte] = useState();
	const [sentimentDeHonteChecked, setsentimentDeHonteChecked] = useState(false);

	const q13sentimentDeHonte = (e) => {
		const value = e.target.value;

		if (!sentimentDeHonteChecked) {
			setsentimentDeHonte(value);
			setsentimentDeHonteChecked(true);
			setprejudiceEmotionel(true);
			//console.log(sentimentDeHonteChecked);
			//console.log(sentimentDeHonte);
		} else {
			setsentimentDeHonte();
			setsentimentDeHonteChecked(false);
			setprejudiceEmotionel(false);
			//console.log(sentimentDeHonteChecked);
			//console.log(sentimentDeHonte);
		}
	};
	const [sentimentDeColere, setsentimentDeColere] = useState();
	const [sentimentDeColereChecked, setsentimentDeColereChecked] =
		useState(false);

	const q13sentimentDeColere = (e) => {
		const value = e.target.value;

		if (!sentimentDeColereChecked) {
			setsentimentDeColere(value);
			setsentimentDeColereChecked(true);
			setprejudiceEmotionel(true);
			//console.log(sentimentDeColereChecked);
			//console.log(sentimentDeColere);
		} else {
			setsentimentDeColere();
			setsentimentDeColereChecked(false);
			setprejudiceEmotionel(false);
			//console.log(sentimentDeColereChecked);
			//console.log(sentimentDeColere);
		}
	};
	const [troubleDeSommeil, settroubleDeSommeil] = useState();
	const [troubleDeSommeilChecked, settroubleDeSommeilChecked] = useState(false);

	const q13troubleDeSommeil = (e) => {
		const value = e.target.value;

		if (!troubleDeSommeilChecked) {
			settroubleDeSommeil(value);
			settroubleDeSommeilChecked(true);
			setprejudiceEmotionel(true);
			//console.log(troubleDeSommeilChecked);
			//console.log(troubleDeSommeil);
		} else {
			settroubleDeSommeil();
			settroubleDeSommeilChecked(false);
			setprejudiceEmotionel(false);
			//console.log(troubleDeSommeilChecked);
			//console.log(troubleDeSommeil);
		}
	};

	const [troubleAlimentaires, settroubleAlimentaires] = useState();
	const [troubleAlimentairesChecked, settroubleAlimentairesChecked] =
		useState(false);

	const q13troubleAlimentaires = (e) => {
		const value = e.target.value;

		if (!troubleAlimentairesChecked) {
			settroubleAlimentaires(value);
			settroubleAlimentairesChecked(true);
			setprejudiceEmotionel(true);
			//console.log(troubleAlimentairesChecked);
			//console.log(troubleAlimentaires);
		} else {
			settroubleAlimentaires();
			settroubleAlimentairesChecked(false);
			setprejudiceEmotionel(false);
			//console.log(troubleAlimentairesChecked);
			//console.log(troubleAlimentaires);
		}
	};
	const [peeteDEspoire, setpeeteDEspoire] = useState();
	const [peeteDEspoireChecked, setpeeteDEspoireChecked] = useState(false);

	const q13peeteDEspoire = (e) => {
		const value = e.target.value;

		if (!peeteDEspoireChecked) {
			setpeeteDEspoire(value);
			setpeeteDEspoireChecked(true);
			setprejudiceEmotionel(true);
			//console.log(peeteDEspoireChecked);
			//console.log(peeteDEspoire);
		} else {
			setpeeteDEspoire();
			setpeeteDEspoireChecked(false);
			setprejudiceEmotionel(false);
			//console.log(peeteDEspoireChecked);
			//console.log(peeteDEspoire);
		}
	};
	const [manqueDeConfiance, setmanqueDeConfiance] = useState();
	const [manqueDeConfianceChecked, setmanqueDeConfianceChecked] =
		useState(false);

	const q13manqueDeConfiance = (e) => {
		const value = e.target.value;

		if (!manqueDeConfianceChecked) {
			setmanqueDeConfiance(value);
			setmanqueDeConfianceChecked(true);
			setprejudiceEmotionel(true);
			//console.log(manqueDeConfianceChecked);
			//console.log(manqueDeConfiance);
		} else {
			setmanqueDeConfiance();
			setmanqueDeConfianceChecked(false);
			setprejudiceEmotionel(false);
			//console.log(manqueDeConfianceChecked);
			//console.log(manqueDeConfiance);
		}
	};
	const [autrePrejudiceEmotionnel, setautrePrejudiceEmotionnel] = useState();
	const [autrePrejudiceEmotionnelChecked, setautrePrejudiceEmotionnelChecked] =
		useState(false);

	const q13autrePrejudiceEmotionnel = (e) => {
		const value = e.target.value;

		if (!autrePrejudiceEmotionnelChecked) {
			setautrePrejudiceEmotionnel(value);
			setautrePrejudiceEmotionnelChecked(true);
			setprejudiceEmotionel(true);
			//console.log(autrePrejudiceEmotionnelChecked);
			//console.log(autrePrejudiceEmotionnel);
		} else {
			setautrePrejudiceEmotionnel();
			setprejudiceEmotionel(false);
			setautrePrejudiceEmotionnelChecked(false);
			//console.log(autrePrejudiceEmotionnelChecked);
			//console.log(autrePrejudiceEmotionnel);
		}
	};

	//Prejudice economique
	const [perterDeMoyens, setperterDeMoyens] = useState();
	const [perterDeMoyensChecked, setperterDeMoyensChecked] = useState(false);

	const q13perterDeMoyens = (e) => {
		const value = e.target.value;

		if (!perterDeMoyensChecked) {
			setperterDeMoyens(value);
			setperterDeMoyensChecked(true);
			setprejudiceEconomique(true);
			//console.log(perterDeMoyensChecked);
			//console.log(perterDeMoyens);
		} else {
			setperterDeMoyens();
			setperterDeMoyensChecked(false);
			setprejudiceEconomique(false);
			//console.log(perterDeMoyensChecked);
			//console.log(perterDeMoyens);
		}
	};
	const [perterDeRevenues, setperterDeRevenues] = useState();
	const [perterDeRevenuesChecked, setperterDeRevenuesChecked] = useState(false);

	const q13perterDeRevenues = (e) => {
		const value = e.target.value;

		if (!perterDeRevenuesChecked) {
			setperterDeRevenues(value);
			setperterDeRevenuesChecked(true);
			setprejudiceEconomique(true);
			//console.log(perterDeRevenuesChecked);
			//console.log(perterDeRevenues);
		} else {
			setperterDeRevenues();
			setperterDeRevenuesChecked(false);
			setprejudiceEconomique(false);
			//console.log(perterDeRevenuesChecked);
			//console.log(perterDeRevenues);
		}
	};
	const [sexeDeSurvie, setsexeDeSurvie] = useState();
	const [sexeDeSurvieChecked, setsexeDeSurvieChecked] = useState(false);

	const q13sexeDeSurvie = (e) => {
		const value = e.target.value;

		if (!sexeDeSurvieChecked) {
			setsexeDeSurvie(value);
			setsexeDeSurvieChecked(true);
			setprejudiceEconomique(true);
			//console.log(sexeDeSurvieChecked);
			//console.log(sexeDeSurvie);
		} else {
			setsexeDeSurvie();
			setsexeDeSurvieChecked(false);
			setprejudiceEconomique(false);
			//console.log(sexeDeSurvieChecked);
			//console.log(sexeDeSurvie);
		}
	};
	const [perteDeMaisons, setperteDeMaisons] = useState();
	const [perteDeMaisonsChecked, setperteDeMaisonsChecked] = useState(false);

	const q13perteDeMaisons = (e) => {
		const value = e.target.value;

		if (!perteDeMaisonsChecked) {
			setperteDeMaisons(value);
			setperteDeMaisonsChecked(true);
			setprejudiceEconomique(true);
			//console.log(perteDeMaisonsChecked);
			//console.log(perteDeMaisons);
		} else {
			setperteDeMaisons();
			setperteDeMaisonsChecked(false);
			setprejudiceEconomique(false);
			//console.log(perteDeMaisonsChecked);
			//console.log(perteDeMaisons);
		}
	};
	const [demanegementFrequent, setdemanegementFrequent] = useState();
	const [demanegementFrequentChecked, setdemanegementFrequentChecked] =
		useState(false);

	const q13demanegementFrequent = (e) => {
		const value = e.target.value;

		if (!demanegementFrequentChecked) {
			setdemanegementFrequent(value);
			setdemanegementFrequentChecked(true);
			setprejudiceEconomique(true);
			//console.log(demanegementFrequentChecked);
			//console.log(demanegementFrequent);
		} else {
			setdemanegementFrequent();
			setdemanegementFrequentChecked(false);
			setprejudiceEconomique(false);
			//console.log(demanegementFrequentChecked);
			//console.log(demanegementFrequent);
		}
	};
	const [situationDeSanSAbri, setsituationDeSanSAbri] = useState();
	const [situationDeSanSAbriChecked, setsituationDeSanSAbriChecked] =
		useState(false);

	const q13situationDeSanSAbri = (e) => {
		const value = e.target.value;

		if (!situationDeSanSAbriChecked) {
			setsituationDeSanSAbri(value);
			setsituationDeSanSAbriChecked(true);
			setprejudiceEconomique(true);
			//console.log(situationDeSanSAbriChecked);
			//console.log(situationDeSanSAbri);
		} else {
			setsituationDeSanSAbri();
			setsituationDeSanSAbriChecked(false);
			setprejudiceEconomique(false);
			//console.log(situationDeSanSAbriChecked);
			//console.log(situationDeSanSAbri);
		}
	};
	const [pasOuPeu, setpasOuPeu] = useState();
	const [pasOuPeuChecked, setpasOuPeuChecked] = useState(false);

	const q13pasOuPeu = (e) => {
		const value = e.target.value;

		if (!pasOuPeuChecked) {
			setpasOuPeu(value);
			setpasOuPeuChecked(true);
			setprejudiceEconomique(true);
			//console.log(pasOuPeuChecked);
			//console.log(pasOuPeu);
		} else {
			setpasOuPeu();
			setprejudiceEconomique(false);
			setpasOuPeuChecked(false);
			//console.log(pasOuPeuChecked);
			//console.log(pasOuPeu);
		}
	};
	const [autrePrejudiceEconomique, setautrePrejudiceEconomique] = useState();
	const [autrePrejudiceEconomiqueChecked, setautrePrejudiceEconomiqueChecked] =
		useState(false);

	const q13autrePrejudiceEconomique = (e) => {
		const value = e.target.value;

		if (!autrePrejudiceEconomiqueChecked) {
			setautrePrejudiceEconomique(value);
			setautrePrejudiceEconomiqueChecked(true);
			setprejudiceEconomique(true);
			//console.log(autrePrejudiceEconomiqueChecked);
			//console.log(autrePrejudiceEconomique);
		} else {
			setautrePrejudiceEconomique();
			setautrePrejudiceEconomiqueChecked(false);
			setprejudiceEconomique(false);
			//console.log(autrePrejudiceEconomiqueChecked);
			//console.log(autrePrejudiceEconomique);
		}
	};
	//

	//impart sur les relations
	const [ruptureDeLaFamile, setruptureDeLaFamile] = useState();
	const [ruptureDeLaFamileChecked, setruptureDeLaFamileChecked] =
		useState(false);

	const q13ruptureDeLaFamile = (e) => {
		const value = e.target.value;

		if (!ruptureDeLaFamileChecked) {
			setruptureDeLaFamile(value);
			setruptureDeLaFamileChecked(true);
			setimpartSurLesRelations(true);
			//console.log(ruptureDeLaFamileChecked);
			//console.log(ruptureDeLaFamile);
		} else {
			setruptureDeLaFamile();
			setruptureDeLaFamileChecked(false);
			setimpartSurLesRelations(false);
			//console.log(ruptureDeLaFamileChecked);
			//console.log(ruptureDeLaFamile);
		}
	};
	const [conflitDomestique, setconflitDomestique] = useState();
	const [conflitDomestiqueChecked, setconflitDomestiqueChecked] =
		useState(false);

	const q13conflitDomestique = (e) => {
		const value = e.target.value;

		if (!conflitDomestiqueChecked) {
			setconflitDomestique(value);
			setconflitDomestiqueChecked(true);
			setimpartSurLesRelations(true);
			//console.log(conflitDomestiqueChecked);
			//console.log(conflitDomestique);
		} else {
			setconflitDomestique();
			setconflitDomestiqueChecked(false);
			setimpartSurLesRelations(false);
			//console.log(conflitDomestiqueChecked);
			//console.log(conflitDomestique);
		}
	};
	const [abondanParLaFamille, setabondanParLaFamille] = useState();
	const [abondanParLaFamilleChecked, setabondanParLaFamilleChecked] =
		useState(false);

	const q13abondanParLaFamille = (e) => {
		const value = e.target.value;

		if (!abondanParLaFamilleChecked) {
			setabondanParLaFamille(value);
			setabondanParLaFamilleChecked(true);
			setimpartSurLesRelations(true);
			//console.log(abondanParLaFamilleChecked);
			//console.log(abondanParLaFamille);
		} else {
			setabondanParLaFamille();
			setabondanParLaFamilleChecked(false);
			setimpartSurLesRelations(false);
			//console.log(abondanParLaFamilleChecked);
			//console.log(abondanParLaFamille);
		}
	};
	const [stigmatisation, setstigmatisation] = useState();
	const [stigmatisationChecked, setstigmatisationChecked] = useState(false);

	const q13stigmatisation = (e) => {
		const value = e.target.value;

		if (!stigmatisationChecked) {
			setstigmatisation(value);
			setstigmatisationChecked(true);
			setimpartSurLesRelations(true);
			//console.log(stigmatisationChecked);
			//console.log(stigmatisation);
		} else {
			setstigmatisation();
			setstigmatisationChecked(false);
			setimpartSurLesRelations(false);
			//console.log(stigmatisationChecked);
			//console.log(stigmatisation);
		}
	};
	const [perteDeLaGarde, setperteDeLaGarde] = useState();
	const [perteDeLaGardeChecked, setperteDeLaGardeChecked] = useState(false);

	const q13perteDeLaGarde = (e) => {
		const value = e.target.value;

		if (!perteDeLaGardeChecked) {
			setperteDeLaGarde(value);
			setperteDeLaGardeChecked(true);
			setimpartSurLesRelations(true);
			//console.log(perteDeLaGardeChecked);
			//console.log(perteDeLaGarde);
		} else {
			setperteDeLaGarde();
			setperteDeLaGardeChecked(false);
			setimpartSurLesRelations(false);
			//console.log(perteDeLaGardeChecked);
			//console.log(perteDeLaGarde);
		}
	};
	const [isolementDeLaVictime, setisolementDeLaVictime] = useState();
	const [isolementDeLaVictimeChecked, setisolementDeLaVictimeChecked] =
		useState(false);

	const q13isolementDeLaVictime = (e) => {
		const value = e.target.value;

		if (!isolementDeLaVictimeChecked) {
			setisolementDeLaVictime(value);
			setisolementDeLaVictimeChecked(true);
			setimpartSurLesRelations(true);
			//console.log(isolementDeLaVictimeChecked);
			//console.log(isolementDeLaVictime);
		} else {
			setisolementDeLaVictime();
			setisolementDeLaVictimeChecked(false);
			setimpartSurLesRelations(false);
			//console.log(isolementDeLaVictimeChecked);
			//console.log(isolementDeLaVictime);
		}
	};
	const [autreImpactSurLesRelation, setautreImpactSurLesRelation] = useState();
	const [
		autreImpactSurLesRelationChecked,
		setautreImpactSurLesRelationChecked,
	] = useState(false);

	const q13autreImpactSurLesRelation = (e) => {
		const value = e.target.value;

		if (!autreImpactSurLesRelationChecked) {
			setautreImpactSurLesRelation(value);
			setautreImpactSurLesRelationChecked(true);
			setimpartSurLesRelations(true);
			//console.log(autreImpactSurLesRelationChecked);
			//console.log(autreImpactSurLesRelation);
		} else {
			setautreImpactSurLesRelation();
			setautreImpactSurLesRelationChecked(false);
			setimpartSurLesRelations(false);
			//console.log(autreImpactSurLesRelationChecked);
			//console.log(autreImpactSurLesRelation);
		}
	};
	//

	const { register, handleSubmit, reset } = useForm({
		mode: "onBlur",
	});

	const onSubmit = (data, e) => {
		e.preventDefault();

		if (
			// incidentDeViolenceSexuel!==false &&
			// incidentDeViolencePhysique!==false &&
			// incidentDeViolencePsychologique!==false &&
			// incidentDeViolenceEconomique!==false &&

			q11abool !== false &&
			q11bbool !== false &&
			q12bbool !== false
			//&&
			// prejudicePhysique!==false &&
			// prejudiceEmotionel!==false &&
			// prejudiceEconomique!==false &&
			// impartSurLesRelations!==false
		) {
			const info = [
				{
					q9: data.q9,
					q10a: [
						`${viol !== null ? viol : ""}`,
						`${conj !== null ? conj : ""}`,
						`${agressio !== null ? agressio : ""}`,
						`${coercition !== null ? coercition : ""}`,
						`${harcelement !== null ? harcelement : ""}`,
						`${attouchement !== null ? attouchement : ""}`,
						`${etreForce !== null ? etreForce : ""}`,
						`${etreEmpeche !== null ? etreEmpeche : ""}`,
						`${autreViolSexuel !== null ? autreViolSexuel : ""}`,
					],
					//
					q10abool: incidentDeViolenceSexuel,
					q10ap: [
						`${viol !== null ? viol : ""}`,
						`${conj !== null ? conj : ""}`,
						`${agressio !== null ? agressio : ""}`,
						`${coercition !== null ? coercition : ""}`,
						`${harcelement !== null ? harcelement : ""}`,
						`${attouchement !== null ? attouchement : ""}`,
						`${etreForce !== null ? etreForce : ""}`,
						`${etreEmpeche !== null ? etreEmpeche : ""}`,
						`${autreViolSexuel !== null ? autreViolSexuel : ""}`,
					]
						.toString()
						.replace(/['"]+/g, ""),
					q10b: [
						`${meutre !== null ? meutre : ""}`,
						`${coupDePoing !== null ? coupDePoing : ""}`,
						`${coupDePied !== null ? coupDePied : ""}`,
						`${attaque !== null ? attaque : ""}`,
						`${attaqueACouteau !== null ? attaqueACouteau : ""}`,
						`${attaqueAEaux !== null ? attaqueAEaux : ""}`,
						`${privation !== null ? privation : ""}`,
						`${privationDeNouriture !== null ? privationDeNouriture : ""}`,
						`${negligencePhyDenfant !== null ? negligencePhyDenfant : ""}`,
						`${negligencePhyPerson !== null ? negligencePhyPerson : ""}`,
						`${destructionDobject !== null ? destructionDobject : ""}`,
						`${ritesDeVeuvage !== null ? ritesDeVeuvage : ""}`,
						`${autreViolPhysique !== null ? autreViolPhysique : ""}`,
					],
					//

					q10bbool: incidentDeViolencePhysique,

					q10bp: [
						`${meutre !== null ? meutre : ""}`,
						`${coupDePoing !== null ? coupDePoing : ""}`,
						`${coupDePied !== null ? coupDePied : ""}`,
						`${attaque !== null ? attaque : ""}`,
						`${attaqueACouteau !== null ? attaqueACouteau : ""}`,
						`${attaqueAEaux !== null ? attaqueAEaux : ""}`,
						`${privation !== null ? privation : ""}`,
						`${privationDeNouriture !== null ? privationDeNouriture : ""}`,
						`${negligencePhyDenfant !== null ? negligencePhyDenfant : ""}`,
						`${negligencePhyPerson !== null ? negligencePhyPerson : ""}`,
						`${destructionDobject !== null ? destructionDobject : ""}`,
						`${ritesDeVeuvage !== null ? ritesDeVeuvage : ""}`,
						`${autreViolPhysique !== null ? autreViolPhysique : ""}`,
					]
						.toString()
						.replace(/['"]+/g, ""),
					q10c: [
						`${menace !== null ? menace : ""}`,
						`${injure !== null ? injure : ""}`,
						`${negligenceEmotionnel !== null ? negligenceEmotionnel : ""}`,
						`${humiliationFace !== null ? humiliationFace : ""}`,
						`${attaqueVisant !== null ? attaqueVisant : ""}`,
						`${isolementDeLaFamille !== null ? isolementDeLaFamille : ""}`,
						`${controleDeMouvement !== null ? controleDeMouvement : ""}`,
						`${
							controleDeLaCommunication !== null ? controleDeLaCommunication : ""
						}`,
						`${refusDeDivorce !== null ? refusDeDivorce : ""}`,
						`${abusSpirituel !== null ? abusSpirituel : ""}`,
						`${autreViolPsychologique !== null ? autreViolPsychologique : ""}`,
					],
					//
					q10cbool: incidentDeViolencePsychologique,
					q10cp: [
						`${menace !== null ? menace : ""}`,
						`${injure !== null ? injure : ""}`,
						`${negligenceEmotionnel !== null ? negligenceEmotionnel : ""}`,
						`${humiliationFace !== null ? humiliationFace : ""}`,
						`${attaqueVisant !== null ? attaqueVisant : ""}`,
						`${isolementDeLaFamille !== null ? isolementDeLaFamille : ""}`,
						`${controleDeMouvement !== null ? controleDeMouvement : ""}`,
						`${
							controleDeLaCommunication !== null ? controleDeLaCommunication : ""
						}`,
						`${refusDeDivorce !== null ? refusDeDivorce : ""}`,
						`${abusSpirituel !== null ? abusSpirituel : ""}`,
						`${autreViolPsychologique !== null ? autreViolPsychologique : ""}`,
					]
						.toString()
						.replace(/['"]+/g, ""),
					q10d: [
						`${abondonDeLaFamille !== null ? abondonDeLaFamille : ""}`,
						`${confiscationDeRevenue !== null ? confiscationDeRevenue : ""}`,
						`${
							interdictionDeParticipation !== null
								? interdictionDeParticipation
								: ""
						}`,
						`${
							restrictionDeLAccessDEducation !== null
								? restrictionDeLAccessDEducation
								: ""
						}`,
						`${
							restrictionDeLaccessDeLemploi !== null
								? restrictionDeLaccessDeLemploi
								: ""
						}`,
						`${
							restrictionDeLAccessAuxRessource !== null
								? restrictionDeLAccessAuxRessource
								: ""
						}`,
						`${privationDePriseChange !== null ? privationDePriseChange : ""}`,
						`${levirat !== null ? levirat : ""}`,
						`${perterDePropriete !== null ? perterDePropriete : ""}`,
						`${perterDeLenfant !== null ? perterDeLenfant : ""}`,
						`${autreViolEconomique !== null ? autreViolEconomique : ""}`,
					],
					//
					q10dbool: incidentDeViolenceEconomique,
					q10dp: [
						`${abondonDeLaFamille !== null ? abondonDeLaFamille : ""}`,
						`${confiscationDeRevenue !== null ? confiscationDeRevenue : ""}`,
						`${
							interdictionDeParticipation !== null
								? interdictionDeParticipation
								: ""
						}`,
						`${
							restrictionDeLAccessDEducation !== null
								? restrictionDeLAccessDEducation
								: ""
						}`,
						`${
							restrictionDeLaccessDeLemploi !== null
								? restrictionDeLaccessDeLemploi
								: ""
						}`,
						`${
							restrictionDeLAccessAuxRessource !== null
								? restrictionDeLAccessAuxRessource
								: ""
						}`,
						`${privationDePriseChange !== null ? privationDePriseChange : ""}`,
						`${levirat !== null ? levirat : ""}`,
						`${perterDePropriete !== null ? perterDePropriete : ""}`,
						`${perterDeLenfant !== null ? perterDeLenfant : ""}`,
						`${autreViolEconomique !== null ? autreViolEconomique : ""}`,
					]
						.toString()
						.replace(/['"]+/g, ""),
					q11a: q11avalue !== null ? q11avalue : "",
					q11abool: q11abool,
					q11aMonth: q11bOui !== null ? q11aOui : "",
					q11amois: q11aval !== null ? q11aval : "",
					q11aYear: data.q11aDate !== null ? data.q11aDate : "",
					q11b: q11bvalue !== null ? q11bvalue : "",
					q11bbool: q11bbool,
					q11bMonth: q11bOui !== null ? q11bOui : "",
					q11bmois: q11bval !== null ? q11bval : "",
					q11bYear: data.q11bDate !== null ? data.q11bDate : "",
					q12a: cityname,
					q12province: provinceName,

					lat: parseFloat(lat),
					long: parseFloat(long),
					q12b: q12bvalue,
					q12bbool: q12bbool,
					q13a: data.q13a,
					q13bA: [
						`${deces !== null ? deces : ""}`,
						`${maladie !== null ? maladie : ""}`,
						`${blessure !== null ? blessure : ""}`,
						`${douleur !== null ? douleur : ""}`,
						`${troubleGyn !== null ? troubleGyn : ""}`,
						`${handicap !== null ? handicap : ""}`,
						`${grossesse !== null ? grossesse : ""}`,
						`${perteDeLenfantANaitre !== null ? perteDeLenfantANaitre : ""}`,
						`${abusDAlcools !== null ? abusDAlcools : ""}`,
						`${abusDeDrogues !== null ? abusDeDrogues : ""}`,
						`${abusDeMedicaments !== null ? abusDeMedicaments : ""}`,
						`${autrePrejudicePhysique !== null ? autrePrejudicePhysique : ""}`,
					],
					//
					q13babool: prejudicePhysique,
					q13bAp: [
						`${deces !== null ? deces : ""}`,
						`${maladie !== null ? maladie : ""}`,
						`${blessure !== null ? blessure : ""}`,
						`${douleur !== null ? douleur : ""}`,
						`${troubleGyn !== null ? troubleGyn : ""}`,
						`${handicap !== null ? handicap : ""}`,
						`${grossesse !== null ? grossesse : ""}`,
						`${perteDeLenfantANaitre !== null ? perteDeLenfantANaitre : ""}`,
						`${abusDAlcools !== null ? abusDAlcools : ""}`,
						`${abusDeDrogues !== null ? abusDeDrogues : ""}`,
						`${abusDeMedicaments !== null ? abusDeMedicaments : ""}`,
						`${autrePrejudicePhysique !== null ? autrePrejudicePhysique : ""}`,
					]
						.toString()
						.replace(/['"]+/g, ""),
					q13bB: [
						`${risqueDeSuicide !== null ? risqueDeSuicide : ""}`,
						`${peurEtAnxiete !== null ? peurEtAnxiete : ""}`,
						`${depression !== null ? depression : ""}`,
						`${detresse !== null ? detresse : ""}`,
						`${sentimentDeHonte !== null ? sentimentDeHonte : ""}`,
						`${sentimentDeColere !== null ? sentimentDeColere : ""}`,
						`${troubleDeSommeil !== null ? troubleDeSommeil : ""}`,
						`${troubleAlimentaires !== null ? troubleAlimentaires : ""}`,
						`${peeteDEspoire !== null ? peeteDEspoire : ""}`,
						`${manqueDeConfiance !== null ? manqueDeConfiance : ""}`,
						`${
							autrePrejudiceEmotionnel !== null ? autrePrejudiceEmotionnel : ""
						}`,
					],

					//
					q13bbbool: prejudiceEmotionel,
					q13bBp: [
						`${risqueDeSuicide !== null ? risqueDeSuicide : ""}`,
						`${peurEtAnxiete !== null ? peurEtAnxiete : ""}`,
						`${depression !== null ? depression : ""}`,
						`${detresse !== null ? detresse : ""}`,
						`${sentimentDeHonte !== null ? sentimentDeHonte : ""}`,
						`${sentimentDeColere !== null ? sentimentDeColere : ""}`,
						`${troubleDeSommeil !== null ? troubleDeSommeil : ""}`,
						`${troubleAlimentaires !== null ? troubleAlimentaires : ""}`,
						`${peeteDEspoire !== null ? peeteDEspoire : ""}`,
						`${manqueDeConfiance !== null ? manqueDeConfiance : ""}`,
						`${
							autrePrejudiceEmotionnel !== null ? autrePrejudiceEmotionnel : ""
						}`,
					]
						.toString()
						.replace(/['"]+/g, ""),
					q13bcbool: prejudiceEconomique,
					q13bC: [
						`${perterDeMoyens !== null ? perterDeMoyens : ""}`,
						`${perterDeRevenues !== null ? perterDeRevenues : ""}`,
						`${sexeDeSurvie !== null ? sexeDeSurvie : ""}`,
						`${perteDeMaisons !== null ? perteDeMaisons : ""}`,
						`${demanegementFrequent !== null ? demanegementFrequent : ""}`,
						`${situationDeSanSAbri !== null ? situationDeSanSAbri : ""}`,
						`${pasOuPeu !== null ? pasOuPeu : ""}`,
						`${
							autrePrejudiceEconomique !== null ? autrePrejudiceEconomique : ""
						}`,
					],
					//
					q13bCp: [
						`${perterDeMoyens !== null ? perterDeMoyens : ""}`,
						`${perterDeRevenues !== null ? perterDeRevenues : ""}`,
						`${sexeDeSurvie !== null ? sexeDeSurvie : ""}`,
						`${perteDeMaisons !== null ? perteDeMaisons : ""}`,
						`${demanegementFrequent !== null ? demanegementFrequent : ""}`,
						`${situationDeSanSAbri !== null ? situationDeSanSAbri : ""}`,
						`${pasOuPeu !== null ? pasOuPeu : ""}`,
						`${
							autrePrejudiceEconomique !== null ? autrePrejudiceEconomique : ""
						}`,
					]
						.toString()
						.replace(/['"]+/g, ""),
					q13bD: [
						`${ruptureDeLaFamile !== null ? ruptureDeLaFamile : ""}`,
						`${conflitDomestique !== null ? conflitDomestique : ""}`,
						`${abondanParLaFamille !== null ? abondanParLaFamille : ""}`,
						`${stigmatisation !== null ? stigmatisation : ""}`,
						`${perteDeLaGarde !== null ? perteDeLaGarde : ""}`,
						`${isolementDeLaVictime !== null ? isolementDeLaVictime : ""}`,
						`${
							autreImpactSurLesRelation !== null ? autreImpactSurLesRelation : ""
						}`,
					],
					//
					q13bdbool: impartSurLesRelations,
					q13bDp: [
						`${ruptureDeLaFamile !== null ? ruptureDeLaFamile : ""}`,
						`${conflitDomestique !== null ? conflitDomestique : ""}`,
						`${abondanParLaFamille !== null ? abondanParLaFamille : ""}`,
						`${stigmatisation !== null ? stigmatisation : ""}`,
						`${perteDeLaGarde !== null ? perteDeLaGarde : ""}`,
						`${isolementDeLaVictime !== null ? isolementDeLaVictime : ""}`,
						`${
							autreImpactSurLesRelation !== null ? autreImpactSurLesRelation : ""
						}`,
					]
						.toString()
						.replace(/['"]+/g, ""),
				},
			];

			////console.log(data);

			//console.log('incident info',info);
			sessionStorage.setItem("info", JSON.stringify(info));
			if (orgData !== null) {
				handleSauvegarde();
			} else {
				handleSuivant();
			}
		} else {
			setmsg(
				"Question sans réponse détectée. Veuillez vérifier que vous avez répondu à toutes les questions avant de continuer."
			);
			handleSnackBar();
			//console.log('data no validate');
		}
	};

	const handleSuivant = () => {
		history.push(
			`/${process.env.REACT_APP_COLLECT_LINK}/collect/form/perpretor`
		);
	};
	const handleRetour = () => {
		history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/main/changes`);
	};

	const handleSauvegarde = () => {
		history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/main/changes`);
	};

	const getProvince = (event) => {
		// event.preventDefault()

		setprovinceName(event.target.value);
		setcities(regionMap.filter((e) => e.name === event.target.value));
	};

	const [cityname, setcityname] = useState();
	const [long, setlong] = useState();
	const [lat, setlat] = useState();

	const getCity = (event) => {
		setcityname(event.target.value);
		setlong(
			cities.map((e) =>
				e.cities
					.filter((e) => e.name === event.target.value)
					.map((e) => e.coordinates[0])
			)
		);
		setlat(
			cities.map((e) =>
				e.cities
					.filter((e) => e.name === event.target.value)
					.map((e) => e.coordinates[1])
			)
		);
	};

	const q10Boxa = () => {
		//  setq10value(true)
		if (q10value === true) {
			setq10value(false);
		} else if (q10value === false) {
			setq10value(true);
		}
	};
	const q10Boxb = () => {
		//  setq10value(true)
		if (q10valueb === true) {
			setq10valueb(false);
		} else if (q10valueb === false) {
			setq10valueb(true);
		}
	};
	const q10Boxc = () => {
		//  setq10value(true)
		if (q10valuec === true) {
			setq10valuec(false);
		} else if (q10valuec === false) {
			setq10valuec(true);
		}
	};

	const q10Boxd = () => {
		//  setq10value(true)
		if (q10valued === true) {
			setq10valued(false);
		} else if (q10valued === false) {
			setq10valued(true);
		}
	};

	const [q11abool, setq11bool] = useState(false);
	const q11aCheck = (e) => {
		const value = e.target.value;
		//console.log('q11a',value);

		if (value === "B") {
			setq11aOui("");
			setq11bool(true);
			setq11bbool(true);
			setq11avalue(value);
		} else if (value === "A") {
			setq11bvalue("B");
			setq11aOui("");
			setq11bool(true);
			setq11bbool(true);
			setq11avalue(value);
		}
	};
	const [q11bbool, setq11bbool] = useState(false);

	const q11bCheck = (e) => {
		const value = e.target.value;
		if (value === "B") {
			setq11bvalue(value);
			setq11bOui("");
			setq11bool(true);
			setq11bbool(true);
		} else if (value === "A") {
			setq11avalue("B");
			setq11bOui("");
			setq11bbool(true);
			setq11bool(true);
			setq11bvalue(value);
		}
		//console.log('q11b',value);
	};
	const [q12bbool, setq12bbool] = useState(false);

	const q12bCheck = (e) => {
		setq12bbool(true);
		const value = e.target.value;
		//console.log('q12b',value);
		setq12bvalue(value);
	};

	//   const handleDateChange = (date) => {
	//       setSelectedDate(date);
	//   };

	const handleYearChangeb = (e) => {
		setSelectedDateb(e.target.value);
	};

	const getInfo = () => {
		const data = sessionStorage.getItem("info");
		////console.log('data from local storage',JSON.parse(data));
		return setgetinfo(JSON.parse(data));
	};

	const handleq11aOui = (e) => {
		const data = e.target.value;
		setq11aOui(data);
		if (data === "1") {
			setq11aval("January");
		} else if (data === "2") {
			setq11aval("February");
		} else if (data === "3") {
			setq11aval("March");
		} else if (data === "4") {
			setq11aval("April");
		} else if (data === "5") {
			setq11aval("May");
		} else if (data === "6") {
			setq11aval("June");
		} else if (data === "7") {
			setq11aval("July");
		} else if (data === "8") {
			setq11aval("August");
		} else if (data === "9") {
			setq11aval("September");
		} else if (data === "10") {
			setq11aval("October");
		} else if (data === "11") {
			setq11aval("November");
		} else if (data === "12") {
			setq11aval("December");
		}
	};

	const handleq11bOui = (e) => {
		const data = e.target.value;
		setq11bOui(data);
		if (data === "1") {
			setq11bval("January");
		} else if (data === "2") {
			setq11bval("February");
		} else if (data === "3") {
			setq11bval("March");
		} else if (data === "4") {
			setq11bval("April");
		} else if (data === "5") {
			setq11bval("May");
		} else if (data === "6") {
			setq11bval("June");
		} else if (data === "7") {
			setq11bval("July");
		} else if (data === "8") {
			setq11bval("August");
		} else if (data === "9") {
			setq11bval("September");
		} else if (data === "10") {
			setq11bval("October");
		} else if (data === "11") {
			setq11bval("November");
		} else if (data === "12") {
			setq11bval("December");
		}
	};

	useEffect(() => {
		setorgData(location.data);
		getInfo();

		// setcities(regionMap.filter((e)=>e.name===provinceName));

		setregionMap(regions.province);
		if (getinfo !== null) {
			//bool variables

			// setincidentDeViolenceSexuel(getinfo.map(e=>e.q10abool))
			// setincidentDeViolencePhysique(getinfo.map(e=>e.q10bbool))
			// setincidentDeViolencePsychologique(getinfo.map(e=>e.q10cbool))
			// setincidentDeViolenceEconomique(getinfo.map(e=>e.q10dbool))

			setq11bool(getinfo.map((e) => e.q11abool));
			setq11bbool(getinfo.map((e) => e.q11bbool));

			setq12bbool(getinfo.map((e) => e.q12bbool));

			// setprejudicePhysique(getinfo.map(e=>e.q13babool))
			// setprejudiceEmotionel(getinfo.map(e=>e.q13bbbool))
			// setprejudiceEconomique(getinfo.map(e=>e.q13bcbool))
			// setimpartSurLesRelations(getinfo.map(e=>e.q13bdbool))

			//
			setq11avalue(getinfo.map((e) => e.q11a));
			setq11bvalue(getinfo.map((e) => e.q11b));
			setlong(getinfo.map((e) => e.long));
			setlat(getinfo.map((e) => e.lat));
			setq12bvalue(getinfo.map((e) => e.q12b));
			setprovinceName(getinfo.map((e) => e.q12province));
			setcityname(getinfo.map((e) => e.q12a));
			setprov(getinfo.map((e) => e.q12province));
			setcty(getinfo.map((e) => e.q12a));
			setq11aOui(getinfo.map((e) => e.q11aMonth));
			setq11al(getinfo.map((e) => e.q11amois));
			setq11bOui(getinfo.map((e) => e.q11bMonth));
			setq11bl(getinfo.map((e) => e.q11bmois));

			reset({
				q9: getinfo.map((e) => e.q9),
				q13a: getinfo.map((e) => e.q13a),
				q11aval: getinfo.map((e) => e.q11amois),
				q11bval: getinfo.map((e) => e.q11bmois),
				q11aDate: getinfo.map((e) => e.q11aYear),
				q11bDate: getinfo.map((e) => e.q11bYear),
				q12prov: getinfo.map((e) => e.q12province),
				q12city: getinfo.map((e) => e.q12a),
			});
			//incidents de violence sexuelle initial value from local storage
			const vi = getinfo.map((e) => e.q10a[0]);
			if (vi === "A") {
				setviolChecked(true);
				setq10value(true);
				setviol(vi);
				//setincidentDeViolenceSexuel(true)
			} else {
				setviolChecked(false);
				setq10value(true);
				setviol();
				//setincidentDeViolenceSexuel(true)
			}

			const con = getinfo.map((e) => e.q10a[1]);
			if (con === "B") {
				setconjChecked(true);
				setconj(con);
				setq10value(true);
				//setincidentDeViolenceSexuel(true)
			} else {
				setconjChecked(false);
				setconj();
				setq10value(true);
				//setincidentDeViolenceSexuel(true)
			}

			const agr = getinfo.map((e) => e.q10a[2]);
			if (agr === "C") {
				setagressioChecked(true);
				setagressio(agr);
				setq10value(true);
				//setincidentDeViolenceSexuel(true)
			} else {
				setagressioChecked(false);
				setagressio();
				setq10value(true);
				//setincidentDeViolenceSexuel(true)
			}
			const coe = getinfo.map((e) => e.q10a[3]);
			if (coe === "D") {
				setcoerChecked(true);
				setcoercition(coe);
				setq10value(true);
				//setincidentDeViolenceSexuel(true)
			} else {
				setcoerChecked(false);
				setcoercition();
				setq10value(true);
				//setincidentDeViolenceSexuel(true)
			}
			const har = getinfo.map((e) => e.q10a[4]);
			if (har === "E") {
				setharcChecked(true);
				setHarcelement(har);
				setq10value(true);
				//setincidentDeViolenceSexuel(true)
			} else {
				setharcChecked(false);
				setHarcelement();
				setq10value(true);
				//setincidentDeViolenceSexuel(true)
			}
			const att = getinfo.map((e) => e.q10a[5]);
			if (att === "F") {
				setattoucChecked(true);
				setAttouchements(att);
				setq10value(true);
				//setincidentDeViolenceSexuel(true)
			} else {
				setattoucChecked(false);
				setAttouchements();
				setq10value(true);
				//setincidentDeViolenceSexuel(true)
			}
			const etrF = getinfo.map((e) => e.q10a[6]);
			if (etrF === "G") {
				setetrForChecked(true);
				setetreForces(etrF);
				setq10value(true);
				//setincidentDeViolenceSexuel(true)
			} else {
				setetrForChecked(false);
				setetreForces();
				setq10value(true);
				//setincidentDeViolenceSexuel(true)
			}
			const etrEm = getinfo.map((e) => e.q10a[7]);
			if (etrEm === "H") {
				setetrEmpChecked(true);
				setetreEmpeches(etrEm);
				setq10value(true);
				//setincidentDeViolenceSexuel(true)
			} else {
				setetrEmpChecked(false);
				setetreEmpeches();
				setq10value(true);
				//setincidentDeViolenceSexuel(true)
			}
			const autrVio = getinfo.map((e) => e.q10a[8]);
			if (autrVio === "I") {
				setautreViolSexuelChecked(true);
				setautreViolSexuels(autrVio);
				setq10value(true);
				//setincidentDeViolenceSexuel(true)
			} else {
				setautreViolSexuelChecked(false);
				setautreViolSexuels();
				setq10value(true);
				//setincidentDeViolenceSexuel(true)
			}
			//incidents de violence physique initial value from local storage

			const meu = getinfo.map((e) => e.q10b[0]);
			if (meu === "A") {
				setmeutrChecked(true);
				setmeutre(meu);
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			} else {
				setmeutrChecked(false);
				setmeutre();
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			}

			const coupDePoin = getinfo.map((e) => e.q10b[1]);
			if (coupDePoin === "B") {
				setcoupDePoingChecked(true);
				setcoupDePoing(coupDePoin);
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			} else {
				setcoupDePoingChecked(false);
				setcoupDePoing();
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			}

			const coupDePie = getinfo.map((e) => e.q10b[2]);
			if (coupDePie === "C") {
				setcoupDePiedChecked(true);
				setcoupDePied(coupDePie);
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			} else {
				setcoupDePiedChecked(false);
				setcoupDePied();
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			}

			const attaqAcide = getinfo.map((e) => e.q10b[3]);
			if (attaqAcide === "D") {
				setattaqueChecked(true);
				setattaque(attaqAcide);
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			} else {
				setattaqueChecked(false);
				setattaque();
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			}

			const attaqAcouto = getinfo.map((e) => e.q10b[4]);
			if (attaqAcouto === "E") {
				setattaqueACouteauChecked(true);
				setattaqueACouteau(attaqAcouto);
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			} else {
				setattaqueACouteauChecked(false);
				setattaqueACouteau();
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			}
			const attaqAeau = getinfo.map((e) => e.q10b[5]);
			if (attaqAeau === "F") {
				setattaqueAEauxChecked(true);
				setattaqueAEaux(attaqAeau);
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			} else {
				setattaqueAEauxChecked(false);
				setattaqueAEaux();
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			}

			const priv = getinfo.map((e) => e.q10b[6]);
			if (priv === "G") {
				setprivationChecked(true);
				setprivation(priv);
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			} else {
				setprivationChecked(false);
				setprivation();
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			}

			const privDeNouri = getinfo.map((e) => e.q10b[7]);
			if (privDeNouri === "H") {
				setprivationDeNouritureChecked(true);
				setprivationDeNouriture(privDeNouri);
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			} else {
				setprivationDeNouritureChecked(false);
				setprivationDeNouriture();
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			}

			const neglPhyDenFa = getinfo.map((e) => e.q10b[8]);
			if (neglPhyDenFa === "I") {
				setnegligencePhyDenfantChecked(true);
				setnegligencePhyDenfant(neglPhyDenFa);
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			} else {
				setnegligencePhyDenfantChecked(false);
				setnegligencePhyDenfant();
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			}

			const neglPhyPer = getinfo.map((e) => e.q10b[9]);
			if (neglPhyPer === "J") {
				setnegligencePhyPersonChecked(true);
				setnegligencePhyPerson(neglPhyPer);
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			} else {
				setnegligencePhyPersonChecked(false);
				setnegligencePhyPerson();
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			}

			const descObj = getinfo.map((e) => e.q10b[10]);
			if (descObj === "K") {
				setdestructionDobjectChecked(true);
				setdestructionDobject(descObj);
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			} else {
				setdestructionDobjectChecked(false);
				setdestructionDobject();
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			}

			const riteDeveuv = getinfo.map((e) => e.q10b[11]);
			if (riteDeveuv === "L") {
				setritesDeVeuvageChecked(true);
				setritesDeVeuvage(riteDeveuv);
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			} else {
				setritesDeVeuvageChecked(false);
				setritesDeVeuvage();
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			}

			const autrevioPhy = getinfo.map((e) => e.q10b[12]);
			if (autrevioPhy === "M") {
				setAutresViolPhysiqueChecked(true);
				setAutresViolPhysique(autrevioPhy);
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			} else {
				setAutresViolPhysiqueChecked(false);
				setAutresViolPhysique();
				setq10valueb(true);
				//setincidentDeViolencePhysique(true)
			}

			//incidents de violence psychologique initial value from local storage

			const menac = getinfo.map((e) => e.q10c[0]);
			if (menac === "A") {
				setmenaceChecked(true);
				setmenace(menac);
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			} else {
				setmenaceChecked(false);
				setmenace();
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			}

			const inj = getinfo.map((e) => e.q10c[1]);
			if (inj === "B") {
				setinjureChecked(true);
				setinjure(inj);
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			} else {
				setinjureChecked(false);
				setinjure();
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			}

			const neglEmo = getinfo.map((e) => e.q10c[2]);
			if (neglEmo === "C") {
				setnegligenceEmotionnelChecked(true);
				setnegligenceEmotionnel(neglEmo);
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			} else {
				setnegligenceEmotionnelChecked(false);
				setnegligenceEmotionnel();
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			}
			const humila = getinfo.map((e) => e.q10c[3]);
			if (humila === "D") {
				sethumiliationFaceChecked(true);
				sethumiliationFace(humila);
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			} else {
				sethumiliationFaceChecked(false);
				sethumiliationFace();
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			}

			const attVisant = getinfo.map((e) => e.q10c[4]);
			if (attVisant === "E") {
				setattaqueVisantChecked(true);
				setattaqueVisant(attVisant);
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			} else {
				setattaqueVisantChecked(false);
				setattaqueVisant();
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			}

			const isolment = getinfo.map((e) => e.q10c[5]);
			if (isolment === "F") {
				setisolementDeLaFamilleChecked(true);
				setisolementDeLaFamille(isolment);
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			} else {
				setisolementDeLaFamilleChecked(false);
				setisolementDeLaFamille();
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			}

			const contrDeMouv = getinfo.map((e) => e.q10c[6]);
			if (contrDeMouv === "G") {
				setcontroleDeMouvementChecked(true);
				setcontroleDeMouvement(contrDeMouv);
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			} else {
				setcontroleDeMouvementChecked(false);
				setcontroleDeMouvement();
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			}

			const contrDeLaCom = getinfo.map((e) => e.q10c[7]);
			if (contrDeLaCom === "H") {
				setcontroleDeLaCommunicationChecked(true);
				setcontroleDeLaCommunication(contrDeLaCom);
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			} else {
				setcontroleDeLaCommunicationChecked(false);
				setcontroleDeLaCommunication();
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			}

			const refusDeDiv = getinfo.map((e) => e.q10c[8]);
			if (refusDeDiv === "I") {
				setrefusDeDivorceChecked(true);
				setrefusDeDivorce(refusDeDiv);
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			} else {
				setrefusDeDivorceChecked(false);
				setrefusDeDivorce();
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			}

			const abSpiri = getinfo.map((e) => e.q10c[9]);
			if (abSpiri === "J") {
				setabusSpirituelChecked(true);
				setabusSpirituel(abSpiri);
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			} else {
				setabusSpirituelChecked(false);
				setabusSpirituel();
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			}

			const autreVioPsy = getinfo.map((e) => e.q10c[10]);
			if (autreVioPsy === "K") {
				setautreViolPsychologiqueChecked(true);
				setautreViolPsychologique(autreVioPsy);
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			} else {
				setautreViolPsychologiqueChecked(false);
				setautreViolPsychologique();
				setq10valuec(true);
				//setincidentDeViolencePsychologique(true)
			}

			//incidents de violence economique initial value from local storage

			const abon = getinfo.map((e) => e.q10d[0]);
			if (abon === "A") {
				setabondonDeLaFamilleChecked(true);
				setabondonDeLaFamille(abon);
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			} else {
				setabondonDeLaFamilleChecked(false);
				setabondonDeLaFamille();
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			}
			const confsc = getinfo.map((e) => e.q10d[1]);
			if (confsc === "B") {
				setconfiscationDeRevenueChecked(true);
				setconfiscationDeRevenue(confsc);
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			} else {
				setconfiscationDeRevenueChecked(false);
				setconfiscationDeRevenue();
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			}

			const intDePart = getinfo.map((e) => e.q10d[2]);
			if (intDePart === "C") {
				setinterdictionDeParticipationChecked(true);
				setinterdictionDeParticipation(intDePart);
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			} else {
				setinterdictionDeParticipationChecked(false);
				setinterdictionDeParticipation();
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			}
			const restDeLAccDEduc = getinfo.map((e) => e.q10d[3]);
			if (restDeLAccDEduc === "D") {
				setrestrictionDeLAccessDEducationChecked(true);
				setrestrictionDeLAccessDEducation(restDeLAccDEduc);
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			} else {
				setrestrictionDeLAccessDEducationChecked(false);
				setrestrictionDeLAccessDEducation();
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			}

			const restDeLAccDeLEmpl = getinfo.map((e) => e.q10d[4]);
			if (restDeLAccDeLEmpl === "E") {
				setrestrictionDeLaccessDeLemploiChecked(true);
				setrestrictionDeLaccessDeLemploi(restDeLAccDeLEmpl);
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			} else {
				setrestrictionDeLaccessDeLemploiChecked(false);
				setrestrictionDeLaccessDeLemploi();
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			}

			const restDeLAccDeAuxRess = getinfo.map((e) => e.q10d[5]);
			if (restDeLAccDeAuxRess === "F") {
				setrestrictionDeLAccessAuxRessourceChecked(true);
				setrestrictionDeLAccessAuxRessource(restDeLAccDeAuxRess);
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			} else {
				setrestrictionDeLAccessAuxRessourceChecked(false);
				setrestrictionDeLAccessAuxRessource();
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			}

			const privDePriEnCharg = getinfo.map((e) => e.q10d[6]);
			if (privDePriEnCharg === "G") {
				setprivationDePriseChangeChecked(true);
				setprivationDePriseChange(privDePriEnCharg);
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			} else {
				setprivationDePriseChangeChecked(false);
				setprivationDePriseChange();
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			}

			const lev = getinfo.map((e) => e.q10d[7]);
			if (lev === "H") {
				setleviratChecked(true);
				setlevirat(lev);
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			} else {
				setleviratChecked(false);
				setlevirat();
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			}

			const perteProp = getinfo.map((e) => e.q10d[8]);
			if (perteProp === "I") {
				setperterDeProprieteChecked(true);
				setperterDePropriete(perteProp);
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			} else {
				setperterDeProprieteChecked(false);
				setperterDePropriete();
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			}

			const perteDeLenfa = getinfo.map((e) => e.q10d[9]);
			if (perteDeLenfa === "J") {
				setperterDeLenfantChecked(true);
				setperterDeLenfant(perteDeLenfa);
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			} else {
				setperterDeLenfantChecked(false);
				setperterDeLenfant();
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			}

			const autrViolEco = getinfo.map((e) => e.q10d[10]);
			if (autrViolEco === "K") {
				setautreViolEconomiqueChecked(true);
				setautreViolEconomique(autrViolEco);
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			} else {
				setautreViolEconomiqueChecked(false);
				setautreViolEconomique();
				setq10valued(true);
				//setincidentDeViolenceEconomique(true)
			}

			//Prejudice physique

			const dec = getinfo.map((e) => e.q13bA[0]);
			if (dec === "A") {
				setdecesChecked(true);
				setdeces(dec);
				setq10value(true);
				//setprejudicePhysique(true)
			} else {
				setdecesChecked(false);
				setdeces();
				setq10value(true);
				//setprejudicePhysique(true)
			}
			const mal = getinfo.map((e) => e.q13bA[1]);
			if (mal === "B") {
				setmaladieChecked(true);
				setmaladie(mal);
				setq10value(true);
				//setprejudicePhysique(true)
			} else {
				setmaladieChecked(false);
				setmaladie();
				setq10value(true);
				//setprejudicePhysique(true)
			}

			const bles = getinfo.map((e) => e.q13bA[2]);
			if (bles === "C") {
				setblessureChecked(true);
				setblessure(bles);
				setq10value(true);
				//setprejudicePhysique(true)
			} else {
				setblessureChecked(false);
				setblessure();
				setq10value(true);
				//setprejudicePhysique(true)
			}
			const doul = getinfo.map((e) => e.q13bA[3]);
			if (doul === "D") {
				setdouleurChecked(true);
				setdouleur(doul);
				setq10value(true);
				//setprejudicePhysique(true)
			} else {
				setdouleurChecked(false);
				setdouleur();
				setq10value(true);
				//setprejudicePhysique(true)
			}
			const trouGyn = getinfo.map((e) => e.q13bA[4]);
			if (trouGyn === "E") {
				settroubleGynChecked(true);
				settroubleGyn(trouGyn);
				setq10value(true);
				//setprejudicePhysique(true)
			} else {
				settroubleGynChecked(false);
				settroubleGyn();
				setq10value(true);
				//setprejudicePhysique(true)
			}

			const hand = getinfo.map((e) => e.q13bA[5]);
			if (hand === "F") {
				sethandicapChecked(true);
				sethandicap(hand);
				setq10value(true);
				//setprejudicePhysique(true)
			} else {
				sethandicapChecked(false);
				sethandicap();
				setq10value(true);
				//setprejudicePhysique(true)
			}

			const gross = getinfo.map((e) => e.q13bA[6]);
			if (gross === "G") {
				setgrossesseChecked(true);
				setgrossesse(gross);
				setq10value(true);
				//setprejudicePhysique(true)
			} else {
				setgrossesseChecked(false);
				setgrossesse();
				setq10value(true);
				//setprejudicePhysique(true)
			}

			const pertLenfaNaitr = getinfo.map((e) => e.q13bA[7]);
			if (pertLenfaNaitr === "H") {
				setperteDeLenfantANaitreChecked(true);
				setperteDeLenfantANaitre(pertLenfaNaitr);
				setq10value(true);
				//setprejudicePhysique(true)
			} else {
				setperteDeLenfantANaitreChecked(false);
				setperteDeLenfantANaitre();
				setq10value(true);
				//setprejudicePhysique(true)
			}

			const abDacool = getinfo.map((e) => e.q13bA[8]);
			if (abDacool === "I") {
				setabusDAlcoolsChecked(true);
				setabusDAlcools(abDacool);
				setq10value(true);
				//setprejudicePhysique(true)
			} else {
				setabusDAlcoolsChecked(false);
				setabusDAlcools();
				setq10value(true);
				//setprejudicePhysique(true)
			}
			const abDrog = getinfo.map((e) => e.q13bA[9]);
			if (abDrog === "J") {
				setabusDeDroguesChecked(true);
				setabusDeDrogues(abDrog);
				setq10value(true);
				//setprejudicePhysique(true)
			} else {
				setabusDeDroguesChecked(false);
				setabusDeDrogues();
				setq10value(true);
				//setprejudicePhysique(true)
			}
			const abDeMedic = getinfo.map((e) => e.q13bA[10]);
			if (abDeMedic === "K") {
				setabusDeMedicamentsChecked(true);
				setabusDeMedicaments(abDeMedic);
				setq10value(true);
				//setprejudicePhysique(true)
			} else {
				setabusDeMedicamentsChecked(false);
				setabusDeMedicaments();
				setq10value(true);
				//setprejudicePhysique(true)
			}
			const autrePrejPhys = getinfo.map((e) => e.q13bA[11]);
			if (autrePrejPhys === "L") {
				setautrePrejudicePhysiqueChecked(true);
				setautrePrejudicePhysique(autrePrejPhys);
				setq10value(true);
				//setprejudicePhysique(true)
			} else {
				setautrePrejudicePhysiqueChecked(false);
				setautrePrejudicePhysique();
				setq10value(true);
				//setprejudicePhysique(true)
			}
			//Prejudice emotionel

			const risDeSuic = getinfo.map((e) => e.q13bB[0]);
			if (risDeSuic === "A") {
				setrisqueDeSuicideChecked(true);
				setrisqueDeSuicide(risDeSuic);
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			} else {
				setrisqueDeSuicideChecked(false);
				setrisqueDeSuicide();
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			}

			const peurEtAn = getinfo.map((e) => e.q13bB[1]);
			if (peurEtAn === "B") {
				setpeurEtAnxieteChecked(true);
				setpeurEtAnxiete(peurEtAn);
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			} else {
				setpeurEtAnxieteChecked(false);
				setpeurEtAnxiete();
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			}

			const depr = getinfo.map((e) => e.q13bB[2]);
			if (depr === "C") {
				setdepressionChecked(true);
				setdepression(depr);
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			} else {
				setdepressionChecked(false);
				setdepression();
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			}

			const detr = getinfo.map((e) => e.q13bB[3]);
			if (detr === "D") {
				setdetresseChecked(true);
				setdetresse(detr);
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			} else {
				setdetresseChecked(false);
				setdetresse();
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			}

			const sentDeHonte = getinfo.map((e) => e.q13bB[4]);
			if (sentDeHonte === "E") {
				setsentimentDeHonteChecked(true);
				setsentimentDeHonte(sentDeHonte);
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			} else {
				setsentimentDeHonteChecked(false);
				setsentimentDeHonte();
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			}

			const sentDeColer = getinfo.map((e) => e.q13bB[5]);
			if (sentDeColer === "F") {
				setsentimentDeColereChecked(true);
				setsentimentDeColere(sentDeColer);
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			} else {
				setsentimentDeColereChecked(false);
				setsentimentDeColere();
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			}

			const trouDeSom = getinfo.map((e) => e.q13bB[6]);
			if (trouDeSom === "G") {
				settroubleDeSommeilChecked(true);
				settroubleDeSommeil(trouDeSom);
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			} else {
				settroubleDeSommeilChecked(false);
				settroubleDeSommeil();
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			}

			const trouDeAlim = getinfo.map((e) => e.q13bB[7]);
			if (trouDeAlim === "H") {
				settroubleAlimentairesChecked(true);
				settroubleAlimentaires(trouDeAlim);
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			} else {
				settroubleAlimentairesChecked(false);
				settroubleAlimentaires();
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			}

			const pertEpoir = getinfo.map((e) => e.q13bB[8]);
			if (pertEpoir === "I") {
				setpeeteDEspoireChecked(true);
				setpeeteDEspoire(pertEpoir);
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			} else {
				setpeeteDEspoireChecked(false);
				setpeeteDEspoire();
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			}

			const mnqDeConf = getinfo.map((e) => e.q13bB[9]);
			if (mnqDeConf === "J") {
				setmanqueDeConfianceChecked(true);
				setmanqueDeConfiance(mnqDeConf);
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			} else {
				setmanqueDeConfianceChecked(false);
				setmanqueDeConfiance();
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			}

			const autrePrejEmo = getinfo.map((e) => e.q13bB[10]);
			if (autrePrejEmo === "K") {
				setautrePrejudiceEmotionnelChecked(true);
				setautrePrejudiceEmotionnel(autrePrejEmo);
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			} else {
				setautrePrejudiceEmotionnelChecked(false);
				setautrePrejudiceEmotionnel();
				setq10valueb(true);
				//setprejudiceEmotionel(true)
			}

			//Prejudice economique

			const pertDeMoy = getinfo.map((e) => e.q13bC[0]);
			if (pertDeMoy === "A") {
				setperterDeMoyensChecked(true);
				setperterDeMoyens(pertDeMoy);
				setq10valuec(true);
				//setprejudiceEconomique(true)
			} else {
				setperterDeMoyensChecked(false);
				setperterDeMoyens();
				setq10valuec(true);
				//setprejudiceEconomique(true)
			}

			const pertDeRev = getinfo.map((e) => e.q13bC[1]);
			if (pertDeRev === "B") {
				setperterDeRevenuesChecked(true);
				setperterDeRevenues(pertDeRev);
				setq10valuec(true);
				//setprejudiceEconomique(true)
			} else {
				setperterDeRevenuesChecked(false);
				setperterDeRevenues();
				setq10valuec(true);
				//setprejudiceEconomique(true)
			}

			const sexDeSurv = getinfo.map((e) => e.q13bC[2]);
			if (sexDeSurv === "C") {
				setsexeDeSurvieChecked(true);
				setsexeDeSurvie(sexDeSurv);
				setq10valuec(true);
				//setprejudiceEconomique(true)
			} else {
				setsexeDeSurvieChecked(false);
				setsexeDeSurvie();
				setq10valuec(true);
				//setprejudiceEconomique(true)
			}

			const pertMaiso = getinfo.map((e) => e.q13bC[3]);
			if (pertMaiso === "D") {
				setperteDeMaisonsChecked(true);
				setperteDeMaisons(pertMaiso);
				setq10valuec(true);
				//setprejudiceEconomique(true)
			} else {
				setperteDeMaisonsChecked(false);
				setperteDeMaisons();
				setq10valuec(true);
				//setprejudiceEconomique(true)
			}

			const demFreq = getinfo.map((e) => e.q13bC[4]);
			if (demFreq === "E") {
				setdemanegementFrequentChecked(true);
				setdemanegementFrequent(demFreq);
				setq10valuec(true);
				//setprejudiceEconomique(true)
			} else {
				setdemanegementFrequentChecked(false);
				setdemanegementFrequent();
				setq10valuec(true);
				//setprejudiceEconomique(true)
			}

			const situaDeSansAbr = getinfo.map((e) => e.q13bC[5]);
			if (situaDeSansAbr === "F") {
				setsituationDeSanSAbriChecked(true);
				setsituationDeSanSAbri(situaDeSansAbr);
				setq10valuec(true);
				//setprejudiceEconomique(true)
			} else {
				setsituationDeSanSAbriChecked(false);
				setsituationDeSanSAbri();
				setq10valuec(true);
				//setprejudiceEconomique(true)
			}

			const pasOupe = getinfo.map((e) => e.q13bC[6]);
			if (pasOupe === "G") {
				setpasOuPeuChecked(true);
				setpasOuPeu(pasOupe);
				setq10valuec(true);
				//setprejudiceEconomique(true)
			} else {
				setpasOuPeuChecked(false);
				setpasOuPeu();
				setq10valuec(true);
				//setprejudiceEconomique(true)
			}

			const autrePrejEcon = getinfo.map((e) => e.q13bC[7]);
			if (autrePrejEcon === "H") {
				setautrePrejudiceEconomiqueChecked(true);
				setautrePrejudiceEconomique(autrePrejEcon);
				setq10valuec(true);
				//setprejudiceEconomique(true)
			} else {
				setautrePrejudiceEconomiqueChecked(false);
				setautrePrejudiceEconomique();
				setq10valuec(true);
				//setprejudiceEconomique(true)
			}

			//impart sur les relations

			const ruptDeLaFam = getinfo.map((e) => e.q13bD[0]);
			if (ruptDeLaFam === "A") {
				setruptureDeLaFamileChecked(true);
				setruptureDeLaFamile(ruptDeLaFam);
				setq10valuec(true);
				//setimpartSurLesRelations(true)
			} else {
				setruptureDeLaFamileChecked(false);
				setruptureDeLaFamile();
				setq10valuec(true);
				//setimpartSurLesRelations(true)
			}

			const conflitDom = getinfo.map((e) => e.q13bD[1]);

			if (conflitDom === "B") {
				setconflitDomestiqueChecked(true);
				setconflitDomestique(conflitDom);
				setq10valuec(true);
				//setimpartSurLesRelations(true)
			} else {
				setconflitDomestiqueChecked(false);
				setconflitDomestique();
				setq10valuec(true);
				//setimpartSurLesRelations(true)
			}

			const abnParFam = getinfo.map((e) => e.q13bD[2]);

			if (abnParFam === "C") {
				setabondanParLaFamilleChecked(true);
				setabondanParLaFamille(abnParFam);
				setq10valuec(true);
				//setimpartSurLesRelations(true)
			} else {
				setabondanParLaFamilleChecked(false);
				setabondanParLaFamille();
				setq10valuec(true);
				//setimpartSurLesRelations(true)
			}

			const stigm = getinfo.map((e) => e.q13bD[3]);

			if (stigm === "D") {
				setstigmatisationChecked(true);
				setstigmatisation(stigm);
				setq10valuec(true);
				//setimpartSurLesRelations(true)
			} else {
				setstigmatisationChecked(false);
				setstigmatisation();
				setq10valuec(true);
				//setimpartSurLesRelations(true)
			}

			const pertDeLaGar = getinfo.map((e) => e.q13bD[4]);

			if (pertDeLaGar === "E") {
				setperteDeLaGardeChecked(true);
				setperteDeLaGarde(pertDeLaGar);
				setq10valuec(true);
				//setimpartSurLesRelations(true)
			} else {
				setperteDeLaGardeChecked(false);
				setperteDeLaGarde();
				setq10valuec(true);
				//setimpartSurLesRelations(true)
			}

			const isolmentDeLaVic = getinfo.map((e) => e.q13bD[5]);

			if (isolmentDeLaVic === "F") {
				setisolementDeLaVictimeChecked(true);
				setisolementDeLaVictime(isolmentDeLaVic);
				setq10valuec(true);
				//setimpartSurLesRelations(true)
			} else {
				setisolementDeLaVictimeChecked(false);
				setisolementDeLaVictime();
				setq10valuec(true);
				//setimpartSurLesRelations(true)
			}

			const autreImpactSurRelatio = getinfo.map((e) => e.q13bD[6]);

			if (autreImpactSurRelatio === "G") {
				setautreImpactSurLesRelationChecked(true);
				setautreImpactSurLesRelation(autreImpactSurRelatio);
				setq10valuec(true);
				//setimpartSurLesRelations(true)
			} else {
				setautreImpactSurLesRelationChecked(false);
				setautreImpactSurLesRelation();
				setq10valuec(true);
				//setimpartSurLesRelations(true)
			}
		}
	}, [orgData, regionMap, setcities.name, setq11aOui, setq11bOui, getinfo, location.data, reset]); // Just added getinfo, location.data, reset and removed regions
	//console.log(q11aOui);

	return (
		<div>
			<div className={classes.root}>
				<AppBar position="absolute" className={classes.appbar}>
					<Toolbar>
						{orgData !== null ? null : (
							<IconButton
								edge="start"
								className={classes.menuButton}
								color="inherit"
								aria-label="open drawer"
								component={Link}
								to={"/" + process.env.REACT_APP_COLLECT_LINK + "/collect"}
							>
								{/* <ArrowBack/> */}
							</IconButton>
						)}
						<Typography
							className={classes.titleAppBar}
							variant="h6"
							noWrap
						></Typography>
						<div className={classes.search}>
							<div className={classes.searchIcon}></div>
							{orgData !== null ? (
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={select}
									fullWidth
									className={classes.select}
									onChange={handleChange}
								>
									<MenuItem value={4}>
										INFORMATION SUR L'INCIDENT DE VIOLENCE DOMESTIQUE DOCUMENTÉ
									</MenuItem>
								</Select>
							) : (
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={select}
									fullWidth
									className={classes.select}
									onChange={handleChange}
								>
									<MenuItem
										value={2}
										component={Link}
										to={
											"/" + process.env.REACT_APP_COLLECT_LINK + "/collect/form"
										}
									>
										IDENTIFICATION DE L'ORGANISATION
									</MenuItem>
									<MenuItem
										value={3}
										component={Link}
										to={
											"/" +
											process.env.REACT_APP_COLLECT_LINK +
											"/collect/profile"
										}
									>
										PROFIL DE LA / DU SURVIVANT(E)
									</MenuItem>
									<MenuItem
										value={4}
										component={Link}
										to={
											"/" + process.env.REACT_APP_COLLECT_LINK + "/collect/info"
										}
									>
										INFORMATION SUR L'INCIDENT DE VIOLENCE DOMESTIQUE DOCUMENTÉ
									</MenuItem>
								</Select>
							)}
						</div>
					</Toolbar>
				</AppBar>
			</div>
			<div>
				<Grid container justifyContent="center" spacing={3}>
					<Grid item xs={11} sm={8}>
						<Typography
							xs={11}
							sm={8}
							className={classes.bigtitle}
							align="left"
						>
							{grp3.toUpperCase()}
						</Typography>
					</Grid>
				</Grid>
				<Grid container justifyContent="center" spacing={3}>
					<Grid item className={classes.content} xs={11} sm={8}>
						<Grid container direction="row">
							<Grid item sm={1} xs={0}></Grid>
							<Grid item sm={10} xs={12} className={classes.marginTop_40}>
								<form onSubmit={handleSubmit(onSubmit)}>
									<Grid container spacing={1} direction="row">
										<Grid item xs={11} sm={12}>
											<Typography className={classes.label}>{q9}</Typography>
											{/* <TextField
                                    rows={14}
                                    multiline
                                    variant="outline"
                                    required
                                    className={classes.textarea}
                                    placeholder="Il s'agit d'une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé. Veuillez fournir ici un bref aperçu du cas en français."
                                    name='q9'
                                    inputRef={register}
                                /> */}
											<TextField
												id="outlined-multiline-static"
												// label="Il s'agit d'une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé. Veuillez fournir ici un bref aperçu du cas en français."
												placeholder="Il s'agit d'une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé. Veuillez fournir ici un bref aperçu du cas en français."
												required
												multiline
												inputProps={{ maxLength: 600 }}
												helperText={`La longueur maximale est de 600 caractères.`}
												className={classes.textarea}
												//value={values.}
												rows={6}
												name="q9"
												inputRef={register}
												//defaultValue="Default Value"
												variant="outlined"
											/>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										direction="row"
										className={classes.marginBottom_30}
									>
										<Grid item xs={12} sm={12}>
											<Typography className={classes.label}>
												{q10}
												<small style={{ fontWeight: 200 }}>
													{" "}
													(choix multiple est possible)
												</small>
											</Typography>

											<Grid container>
												<Grid item xs={12} sm={12}>
													<Button
														onClick={q10Boxa}
														variant="text"
														color="default"
														className={classes.button}
														startIcon={
															q10value === false ? (
																<ArrowRightIcon style={{ fontSize: 30 }} />
															) : (
																<ArrowDropDownIcon style={{ fontSize: 30 }} />
															)
														}
													>
														Incidents de violence sexuelle
													</Button>
													{q10value === true ? (
														<List>
															<ListItem>
																<FormGroup>
																	<FormControlLabel
																		//={q10avalue==="A"?true:false}
																		control={
																			<Checkbox
																				checked={violChecked}
																				value={"A"}
																				onChange={q10Viol}
																			/>
																		}
																		label="Viol"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={conjChecked}
																				value={"B"}
																				onChange={q10Conjugal}
																			/>
																		}
																		label="Viol conjugal entre couples mariés et non mariés"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={agressioChecked}
																				value={"C"}
																				onClick={q10Agression}
																			/>
																		}
																		label="Agression sexuelle sur mineur"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={coerChecked}
																				value={"D"}
																				onChange={q10Coercition}
																			/>
																		}
																		label="Coercition sexuelle"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={harcChecked}
																				value={"E"}
																				onChange={q10Harcelement}
																			/>
																		}
																		label="Harcèlement sexuel"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={attouChecked}
																				value={"F"}
																				onChange={q10Attouchements}
																			/>
																		}
																		label="Attouchements inappropriés"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={etrForChecked}
																				value={"G"}
																				onChange={q10etreForces}
																			/>
																		}
																		label="Forcer la victime à avoir des relations sexuelles sans préservatif"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={etrEmpChecked}
																				value={"H"}
																				onChange={q10etreEmpeches}
																			/>
																		}
																		label="Empêcher la victime de faire ses propres choix quant à la décision d'avoir ou non un bébé"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={autreViolSexuelChecked}
																				value={"I"}
																				onChange={q10autreViolSexuels}
																			/>
																		}
																		label="Autres"
																	/>
																</FormGroup>
															</ListItem>
														</List>
													) : null}
												</Grid>
												<Grid item xs={12} sm={12}>
													<Button
														onClick={q10Boxb}
														variant="text"
														color="default"
														className={classes.button}
														startIcon={
															q10valueb === false ? (
																<ArrowRightIcon style={{ fontSize: 30 }} />
															) : (
																<ArrowDropDownIcon style={{ fontSize: 30 }} />
															)
														}
													>
														Incidents de violence physique (autre que violence
														sexuelle)
													</Button>
													{q10valueb === true ? (
														<List>
															<ListItem>
																<FormGroup>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={meutrChecked}
																				value={"A"}
																				onChange={q10Meutre}
																			/>
																		}
																		label="Meurtre / féminicide"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={coupDePoingChecked}
																				value={"B"}
																				onChange={q10coupDePoing}
																			/>
																		}
																		label="Coups de poing et/ou coups de pieds"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={coupDePiedChecked}
																				value={"C"}
																				onChange={q10coupDePied}
																			/>
																		}
																		label="Coups portés avec des objets"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={attaqueChecked}
																				value={"D"}
																				onChange={q10attaque}
																			/>
																		}
																		label="Attaque à l'acide"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={attaqueACouteauChecked}
																				value={"E"}
																				onChange={q10attaqueACouteau}
																			/>
																		}
																		label="Attaque au couteau ou à la machette"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={attaqueAEauxChecked}
																				value={"F"}
																				onChange={q10attaqueAEaux}
																			/>
																		}
																		label="Attaque à l'eau chaude, à l'huile chaude ou autre objet chaud"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={privationChecked}
																				value={"G"}
																				onChange={q10privation}
																			/>
																		}
																		label="Privation de la liberté physique de la victime"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={privationDeNouritureChecked}
																				value={"H"}
																				onChange={q10privationDeNouriture}
																			/>
																		}
																		label="Privation de nourriture de la victime"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={negligencePhyDenfantChecked}
																				value={"I"}
																				onChange={q10negligencePhyDenfant}
																			/>
																		}
																		label="Négligence physique d'enfant (si la victime est un enfant)"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={negligencePhyPersonChecked}
																				value={"J"}
																				onChange={q10negligencePhyPerson}
																			/>
																		}
																		label="Négligence physique de personnes âgées (si la victime est une personne âgée)"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={destructionDobjectChecked}
																				value={"K"}
																				onChange={q10destructionDobject}
																			/>
																		}
																		label="Destruction d'objets dans la maison de la victime"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={ritesDeVeuvageChecked}
																				value={"L"}
																				onChange={q10ritesDeVeuvage}
																			/>
																		}
																		label="Rites de veuvage affectant la santé physique et mentale de la victime"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={autreViolPhysiqueChecked}
																				value={"M"}
																				onChange={q10autreViolPhysique}
																			/>
																		}
																		label="Autres"
																	/>
																</FormGroup>
															</ListItem>
														</List>
													) : null}
												</Grid>
												<Grid item xs={12} sm={12}>
													<Button
														onClick={q10Boxc}
														variant="text"
														color="default"
														className={classes.button}
														startIcon={
															q10valuec === false ? (
																<ArrowRightIcon style={{ fontSize: 30 }} />
															) : (
																<ArrowDropDownIcon style={{ fontSize: 30 }} />
															)
														}
													>
														Incidents de violence psychologique
													</Button>
													{q10valuec === true ? (
														<List>
															<ListItem>
																<FormGroup>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={menaceChecked}
																				value={"A"}
																				onChange={q10menace}
																			/>
																		}
																		label="Menaces"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={injureChecked}
																				value={"B"}
																				onChange={q10injure}
																			/>
																		}
																		label="Injures"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={negligenceEmotionnelChecked}
																				value={"C"}
																				onChange={q10negligenceEmotionnel}
																			/>
																		}
																		label="Négligence émotionnelle"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={humiliationFaceChecked}
																				value={"D"}
																				onChange={q10humiliationFace}
																			/>
																		}
																		label="Humiliation de la victime face aux autres"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={attaqueVisantChecked}
																				value={"E"}
																				onChange={q10attaqueVisant}
																			/>
																		}
																		label="Attaques visant à nuire à la réputation de la victime"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={isolementDeLaFamilleChecked}
																				value={"F"}
																				onChange={q10isolementDeLaFamille}
																			/>
																		}
																		label="Isolement de la victime de sa famille d'origine ou ses amis"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={controleDeMouvementChecked}
																				value={"G"}
																				onChange={q10controleDeMouvement}
																			/>
																		}
																		label="Contrôle des mouvements de la victime"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={
																					controleDeLaCommunicationChecked
																				}
																				value={"H"}
																				onChange={q10controleDeLaCommunication}
																			/>
																		}
																		label="Contrôle de la communication de la victime"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={refusDeDivorceChecked}
																				value={"I"}
																				onChange={q10refusDeDivorce}
																			/>
																		}
																		label="Refus de divorce malgré le souhait de la victime de se divorcer"
																	/>{" "}
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={abusSpirituelChecked}
																				value={"J"}
																				onChange={q10abusSpirituel}
																			/>
																		}
																		label="Abus spirituel / abus religieux de la victime"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={autreViolPsychologiqueChecked}
																				value={"K"}
																				onChange={q10autreViolPsychologique}
																			/>
																		}
																		label="Autres"
																	/>
																</FormGroup>
															</ListItem>
														</List>
													) : null}
												</Grid>
												<Grid item xs={12} sm={12}>
													<Button
														onClick={q10Boxd}
														variant="text"
														color="default"
														className={classes.button}
														startIcon={
															q10valued === false ? (
																<ArrowRightIcon style={{ fontSize: 30 }} />
															) : (
																<ArrowDropDownIcon style={{ fontSize: 30 }} />
															)
														}
													>
														Incidents de violence économique et de déni
														d'opportunités
													</Button>
													{q10valued === true ? (
														<List>
															<ListItem>
																<FormGroup>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={abondonDeLaFamilleChecked}
																				value={"A"}
																				onChange={q10abondonDeLaFamille}
																			/>
																		}
																		label="L'auteur abandonne ou chasse la victime"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={confiscationDeRevenueChecked}
																				value={"B"}
																				onChange={q10confiscationDeRevenue}
																			/>
																		}
																		label="Confiscation des revenus de la victime"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={
																					interdictionDeParticipationChecked
																				}
																				value={"C"}
																				onChange={
																					q10interdictionDeParticipation
																				}
																			/>
																		}
																		label="Interdiction de participation à la gestion des revenus du ménage"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={
																					restrictionDeLAccessDEducationChecked
																				}
																				value={"D"}
																				onChange={
																					q10restrictionDeLAccessDEducation
																				}
																			/>
																		}
																		label="Restriction de l'accès à l'éducation"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={
																					restrictionDeLaccessDeLemploiChecked
																				}
																				value={"E"}
																				onChange={
																					q10restrictionDeLaccessDeLemploi
																				}
																			/>
																		}
																		label="Restriction de l'accès à l'emploi"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={
																					restrictionDeLAccessAuxRessourceChecked
																				}
																				value={"F"}
																				onChange={
																					q10restrictionDeLAccessAuxRessource
																				}
																			/>
																		}
																		label="Restriction de l'accès aux ressources économiques"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={privationDePriseChange}
																				value={"G"}
																				onChange={q10privationDePriseChange}
																			/>
																		}
																		label="Privation d'une prise en charge médicale, de médicaments ou d'appareils d'assistance"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={leviratChecked}
																				value={"H"}
																				onChange={q10levirat}
																			/>
																		}
																		label="Lévirat après la mort du conjoint"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={perterDeProprieteChecked}
																				value={"I"}
																				onChange={q10perterDePropriete}
																			/>
																		}
																		label="Perte des propriétés et des biens / non accès à l'héritage après la mort du conjoint ou d'un parent"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={perterDeLenfantChecked}
																				value={"J"}
																				onChange={q10perterDeLenfant}
																			/>
																		}
																		label="Perte des enfants après la mort du conjoint"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={autreViolEconomiqueChecked}
																				value={"K"}
																				onChange={q10autreViolEconomique}
																			/>
																		}
																		label="Autres"
																	/>
																</FormGroup>
															</ListItem>
														</List>
													) : null}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										direction="row"
										xs={12}
										sm={12}
										className={classes.marginBottom_30}
									>
										<Grid container>
											<Grid item xs={12} sm={12}>
												<Typography className={classes.label}>{q11}</Typography>
											</Grid>
										</Grid>
										<Grid
											item
											xs={12}
											sm={6}
											className={classes.marginBottom_30}
										>
											<Typography className={classes.sublabel}>
												{q11a}
											</Typography>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.radiolabel}
												>
													<FormControlLabel
														checked={q11avalue === "B" ? true : false}
														value="B"
														onClick={q11aCheck}
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
													<FormControlLabel
														checked={q11avalue === "A" ? true : false}
														value="A"
														onClick={q11aCheck}
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
													{q11avalue === "A" ? (
														<div>
															<Typography className={classes.subradio}>
																Si oui : Quand cette situation a-t-elle commencé
																?
															</Typography>
															<small
																style={{ fontWeight: 100, marginTop: "20px" }}
															>
																Choisissez le mois*
															</small>
															{q11al === "" ? (
																<TextField
																	name="q11aval"
																	inputRef={register}
																	onClick={() => {
																		setq11al("");
																	}}
																	variant="outlined"
																	size="small"
																	fullWidth
																/>
															) : (
																<Select
																	// required
																	labelId="demo-simple-select-helper-label"
																	variant="outlined"
																	id="demo-simple-select-helper"
																	size="small"
																	fullWidth
																	style={{ height: 40 }}
																	value={q11aOui}
																	onChange={handleq11aOui}
																	//onChange={e=>register({name:'mois',value:e.target.value})}
																>
																	<MenuItem value={"1"}>Janvier</MenuItem>
																	<MenuItem value={"2"}>Février</MenuItem>
																	<MenuItem value={"3"}>Mars</MenuItem>
																	<MenuItem value={"4"}>Avril</MenuItem>
																	<MenuItem value={"5"}>Mai</MenuItem>
																	<MenuItem value={"6"}>Juin</MenuItem>
																	<MenuItem value={"7"}>Juillet</MenuItem>
																	<MenuItem value={"8"}>Août</MenuItem>
																	<MenuItem value={"9"}>Septembre</MenuItem>
																	<MenuItem value={"10"}>Octobre</MenuItem>
																	<MenuItem value={"11"}>Novembre</MenuItem>
																	<MenuItem value={"12"}>Decembre</MenuItem>
																</Select>
															)}
															:
															<TextField
																required
																name="q11aDate"
																inputRef={register}
																label="Entrez l' année"
																variant="outlined"
																size="small"
																className={classes.marginTop_20s}
																fullWidth
															/>
														</div>
													) : null}
												</RadioGroup>
											</FormControl>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Grid container className={classes.marginBottom_30}>
												<Grid item xs={12} sm={12}>
													<Typography className={classes.sublabel}>
														{q11b}
													</Typography>
													<FormControl component="fieldset">
														<RadioGroup
															name="occupation"
															className={classes.radiolabel}
														>
															<FormControlLabel
																checked={q11bvalue === "B" ? true : false}
																value="B"
																onClick={q11bCheck}
																control={<Radio size="small" />}
																label={
																	<Box component="div" fontSize={13}>
																		Non
																	</Box>
																}
															/>
															<FormControlLabel
																checked={q11bvalue === "A" ? true : false}
																value="A"
																onClick={q11bCheck}
																control={<Radio size="small" />}
																label={
																	<Box component="div" fontSize={13}>
																		Oui
																	</Box>
																}
															/>
															{q11bvalue === "A" ? (
																<div>
																	<Typography className={classes.subradio}>
																		Si oui : quand l'incident a-t-il eu lieu ?
																	</Typography>
																	<small
																		style={{
																			fontWeight: 100,
																			marginTop: "20px",
																		}}
																	>
																		Choisissez le mois*
																	</small>
																	{q11bl === "" ? (
																		<TextField
																			name="q11bval"
																			inputRef={register}
																			//label="Nom"
																			onClick={() => {
																				setq11bl("");
																			}}
																			variant="outlined"
																			size="small"
																			fullWidth
																		/>
																	) : (
																		<Select
																			//required
																			labelId="demo-simple-select-helper-label"
																			variant="outlined"
																			id="demo-simple-select-helper"
																			size="small"
																			// value={getinfo!==null?getinfo.map(e=>e.q11bMonth):null}
																			fullWidth
																			style={{ height: 40 }}
																			value={q11bOui}
																			onChange={handleq11bOui}
																			//onChange={e=>register({name:'mois2',value:e.target.value})}
																		>
																			<MenuItem value={"1"}>Janvier</MenuItem>
																			<MenuItem value={"2"}>Février</MenuItem>
																			<MenuItem value={"3"}>Mars</MenuItem>
																			<MenuItem value={"4"}>Avril</MenuItem>
																			<MenuItem value={"5"}>Mai</MenuItem>
																			<MenuItem value={"6"}>Juin</MenuItem>
																			<MenuItem value={"7"}>Juillet</MenuItem>
																			<MenuItem value={"8"}>Août</MenuItem>
																			<MenuItem value={"9"}>Septembre</MenuItem>
																			<MenuItem value={"10"}>Octobre</MenuItem>
																			<MenuItem value={"11"}>Novembre</MenuItem>
																			<MenuItem value={"12"}>Decembre</MenuItem>
																		</Select>
																	)}{" "}
																	:
																	<TextField
																		required
																		name="q11bDate"
																		inputRef={register}
																		label="Entrez l' année"
																		variant="outlined"
																		size="small"
																		className={classes.marginTop_20s}
																		fullWidth
																	/>
																</div>
															) : null}
														</RadioGroup>
													</FormControl>
												</Grid>
											</Grid>
										</Grid>
										<Grid
											container
											spacing={1}
											direction="row"
											className={classes.spacedown}
										>
											<Grid item xs={12} sm={12}>
												<Grid container spacing={1}>
													<Grid item xs={12} sm={12}>
														<Grid container>
															<Grid item xs={12} sm={12}>
																<Typography className={classes.label}>
																	{q12}
																</Typography>
															</Grid>
														</Grid>
														<Grid container spacing={1}>
															<Typography className={classes.sublabel}>
																{q12a}
															</Typography>
															<Grid item xs={12} sm={6}>
																<Typography className={classes.sublabel}>
																	Province*
																</Typography>
																{prov.length > 0 ? (
																	<TextField
																		required
																		onClick={() => {
																			setprov("");
																			setcty("");
																			setprovinceName("");
																		}}
																		name="q12prov"
																		inputRef={register}
																		// label="Nom"
																		variant="outlined"
																		size="small"
																		fullWidth
																	/>
																) : (
																	<Select
																		required
																		variant="outlined"
																		size="small"
																		placeholder="Province"
																		fullWidth
																		value={provinceName}
																		style={{ height: 40 }}
																		onChange={getProvince}
																	>
																		{regionMap !== null
																			? regionMap.map((e) => (
																					<MenuItem value={e.name}>
																						{e.name}
																					</MenuItem>
																			  ))
																			: null}
																	</Select>
																)}
															</Grid>

															<Grid item sm={6} xs={12}>
																<Typography className={classes.sublabel}>
																	Ville/Territoire*
																</Typography>
																{cty.length > 0 ? (
																	<TextField
																		required
																		onClick={() => {
																			setprov("");
																			setcty("");
																			setprovinceName("");
																		}}
																		name="q12city"
																		inputRef={register}
																		// label="Nom"
																		variant="outlined"
																		size="small"
																		fullWidth
																	/>
																) : (
																	<Select
																		required
																		variant="outlined"
																		size="small"
																		fullWidth
																		style={{ height: 40 }}
																		value={cityname}
																		onChange={getCity}
																	>
																		{cities !== null
																			? cities.map((e) =>
																					e.cities.map((e) => (
																						<MenuItem value={e.name}>
																							{e.name}
																						</MenuItem>
																					))
																			  )
																			: null}
																	</Select>
																)}
															</Grid>

															<Grid
																item
																xs={12}
																sm={12}
																className={classes.marginTop_20}
															>
																<Typography className={classes.sublabel}>
																	{q12b}
																</Typography>
																<FormControl
																	component="fieldset"
																	className={classes.marginBottom_30}
																>
																	<RadioGroup
																		name="occupation"
																		className={classes.radiolabel}
																	>
																		<FormControlLabel
																			checked={q12bvalue === "A" ? true : false}
																			value="A"
																			onClick={q12bCheck}
																			control={<Radio size="small" />}
																			label={
																				<Box component="div" fontSize={13}>
																					Milieu urbain
																				</Box>
																			}
																		/>
																		<FormControlLabel
																			checked={q12bvalue === "B" ? true : false}
																			value="B"
																			onClick={q12bCheck}
																			control={<Radio size="small" />}
																			label={
																				<Box component="div" fontSize={13}>
																					Milieu semi-urbain
																				</Box>
																			}
																		/>
																		<FormControlLabel
																			checked={q12bvalue === "C" ? true : false}
																			value="C"
																			onClick={q12bCheck}
																			control={<Radio size="small" />}
																			label={
																				<Box component="div" fontSize={13}>
																					Milieu rural
																				</Box>
																			}
																		/>
																	</RadioGroup>
																</FormControl>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid container spacing={1} direction="row">
											<Grid
												item
												xs={11}
												sm={12}
												className={classes.marginBottom_30}
											>
												<Typography className={classes.label}>{q13}</Typography>
												<Typography className={classes.sublabel}>
													{q13a}
												</Typography>
												{/* <TextareaAutosize
                                        required
                                        rows={14}
                                        className={classes.textarea}
                                        aria-label="maximum height"
                                        placeholder="Il s'agit d'une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui étaient les conséquences et impacts de l'acte de violence domestique. Veuillez fournir ici un bref aperçu des conséquences en français."
                                        name='q13a'
                                        inputRef={register}
                                    /> */}
												<TextField
													required
													id="outlined-multiline-static"
													placeholder="Il s'agit d'une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui étaient les conséquences et impacts de l'acte de violence domestique. Veuillez fournir ici un bref aperçu des conséquences en français."
													multiline
													className={classes.textarea}
													rows={6}
													inputProps={{ maxLength: 600 }}
													helperText={`La longueur maximale est de 600 caractères.`}
													name="q13a"
													inputRef={register}
													//defaultValue="Default Value"
													variant="outlined"
												/>

												<Typography className={classes.sublabel}>
													{q13b}{" "}
													<small style={{ fontWeight: 200 }}>
														(choix multiple est possible)
													</small>
												</Typography>
												<Grid container>
													<Grid item xs={12} sm={12}>
														<Button
															onClick={q10Boxa}
															variant="text"
															color="default"
															className={classes.button}
															startIcon={
																q10value === false ? (
																	<ArrowRightIcon style={{ fontSize: 30 }} />
																) : (
																	<ArrowDropDownIcon style={{ fontSize: 30 }} />
																)
															}
														>
															Préjudice physique
														</Button>
														{q10value === true ? (
															<List>
																<ListItem>
																	<FormGroup>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={decesChecked}
																					value={"A"}
																					onChange={q13deces}
																				/>
																			}
																			label="Décès"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={maladieChecked}
																					value={"B"}
																					onChange={q13maladie}
																				/>
																			}
																			label="Maladies (par exemple hypertension)"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={blessureChecked}
																					value={"C"}
																					onChange={q13blessure}
																				/>
																			}
																			label="Blessures"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={douleurChecked}
																					value={"D"}
																					onChange={q13douleur}
																				/>
																			}
																			label="Douleurs"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={troubleGynChecked}
																					value={"E"}
																					onChange={q13troubleGyn}
																				/>
																			}
																			label="Troubles gynécologiques et reproductifs"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={handicapChecked}
																					value={"F"}
																					onChange={q13handicap}
																				/>
																			}
																			label="Invalidité / Handicap"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={grossesseChecked}
																					value={"G"}
																					onChange={q13grossesse}
																				/>
																			}
																			label="Grossesse non voulue"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={perteDeLenfantANaitreChecked}
																					value={"H"}
																					onChange={q13perteDeLenfantANaitre}
																				/>
																			}
																			label="Perte de l'enfant à naître"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={abusDAlcoolsChecked}
																					value={"I"}
																					onChange={q13abusDAlcools}
																				/>
																			}
																			label="Abus d'alcool"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={abusDeDroguesChecked}
																					value={"J"}
																					onChange={q13abusDeDrogues}
																				/>
																			}
																			label="Abus de drogues"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={abusDeMedicamentsChecked}
																					value={"K"}
																					onChange={q13abusDeMedicaments}
																				/>
																			}
																			label="Abus de médicaments"
																		/>{" "}
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						autrePrejudicePhysiqueChecked
																					}
																					value={"L"}
																					onChange={q13autrePrejudicePhysique}
																				/>
																			}
																			label="Autres"
																		/>
																	</FormGroup>
																</ListItem>
															</List>
														) : null}
													</Grid>
													<Grid item xs={12} sm={12}>
														<Button
															onClick={q10Boxb}
															variant="text"
															color="default"
															className={classes.button}
															startIcon={
																q10valueb === false ? (
																	<ArrowRightIcon style={{ fontSize: 30 }} />
																) : (
																	<ArrowDropDownIcon style={{ fontSize: 30 }} />
																)
															}
														>
															Préjudice émotionnel
														</Button>
														{q10valueb === true ? (
															<List>
																<ListItem>
																	<FormGroup>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={risqueDeSuicideChecked}
																					value={"A"}
																					onChange={q13risqueDeSuicide}
																				/>
																			}
																			label="Risque de suicide"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={peurEtAnxieteChecked}
																					value={"B"}
																					onChange={q13peurEtAnxiete}
																				/>
																			}
																			label="Peur et anxiété"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={depressionChecked}
																					value={"C"}
																					onChange={q13depression}
																				/>
																			}
																			label="Dépression"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={detresseChecked}
																					value={"D"}
																					onChange={q13detresse}
																				/>
																			}
																			label="Détresse émotionnelle"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={sentimentDeHonteChecked}
																					value={"E"}
																					onChange={q13sentimentDeHonte}
																				/>
																			}
																			label="Sentiment de honte"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={sentimentDeColereChecked}
																					value={"F"}
																					onChange={q13sentimentDeColere}
																				/>
																			}
																			label="Sentiment de colère"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={troubleDeSommeilChecked}
																					value={"G"}
																					onChange={q13troubleDeSommeil}
																				/>
																			}
																			label="Troubles de sommeil"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={troubleAlimentairesChecked}
																					value={"H"}
																					onChange={q13troubleAlimentaires}
																				/>
																			}
																			label="Troubles alimentaires"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={peeteDEspoireChecked}
																					value={"I"}
																					onChange={q13peeteDEspoire}
																				/>
																			}
																			label="Perte d'espoir"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={manqueDeConfianceChecked}
																					value={"J"}
																					onChange={q13manqueDeConfiance}
																				/>
																			}
																			label="Manque de confiance en soi"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						autrePrejudiceEmotionnelChecked
																					}
																					value={"K"}
																					onChange={q13autrePrejudiceEmotionnel}
																				/>
																			}
																			label="Autres"
																		/>
																	</FormGroup>
																</ListItem>
															</List>
														) : null}
													</Grid>
													<Grid item xs={12} sm={12}>
														<Button
															onClick={q10Boxc}
															variant="text"
															color="default"
															className={classes.button}
															startIcon={
																q10valuec === false ? (
																	<ArrowRightIcon style={{ fontSize: 30 }} />
																) : (
																	<ArrowDropDownIcon style={{ fontSize: 30 }} />
																)
															}
														>
															Préjudice économique
														</Button>
														{q10valuec === true ? (
															<List>
																<ListItem>
																	<FormGroup>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={perterDeMoyensChecked}
																					value={"A"}
																					onChange={q13perterDeMoyens}
																				/>
																			}
																			label="Perte ou manque des moyens de subsistance"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={perterDeRevenuesChecked}
																					value={"B"}
																					onChange={q13perterDeRevenues}
																				/>
																			}
																			label="Perte ou manque de revenus"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={sexeDeSurvieChecked}
																					value={"C"}
																					onChange={q13sexeDeSurvie}
																				/>
																			}
																			label="Sexe de survie"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={perteDeMaisonsChecked}
																					value={"D"}
																					onChange={q13perteDeMaisons}
																				/>
																			}
																			label="Perte de maison"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={demanegementFrequentChecked}
																					value={"E"}
																					onChange={q13demanegementFrequent}
																				/>
																			}
																			label="Déménagements fréquents"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={situationDeSanSAbriChecked}
																					value={"F"}
																					onChange={q13situationDeSanSAbri}
																				/>
																			}
																			label="Situation de sans-abri"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={pasOuPeuChecked}
																					value={"G"}
																					onChange={q13pasOuPeu}
																				/>
																			}
																			label="Pas ou peu d'éducation ou de formation"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						autrePrejudiceEconomiqueChecked
																					}
																					value={"H"}
																					onChange={q13autrePrejudiceEconomique}
																				/>
																			}
																			label="Autres"
																		/>
																	</FormGroup>
																</ListItem>
															</List>
														) : null}
													</Grid>
													<Grid item xs={12} sm={12}>
														<Button
															onClick={q10Boxd}
															variant="text"
															color="default"
															className={classes.button}
															startIcon={
																q10valued === false ? (
																	<ArrowRightIcon style={{ fontSize: 30 }} />
																) : (
																	<ArrowDropDownIcon style={{ fontSize: 30 }} />
																)
															}
														>
															Impact sur les relations
														</Button>
														{q10valued === true ? (
															<List>
																<ListItem>
																	<FormGroup>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={ruptureDeLaFamileChecked}
																					value={"A"}
																					onChange={q13ruptureDeLaFamile}
																				/>
																			}
																			label="Rupture de la famille (famille créée par la victime et l'auteur)"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={conflitDomestiqueChecked}
																					value={"B"}
																					onChange={q13conflitDomestique}
																				/>
																			}
																			label="Conflits domestiques réguliers"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={abondanParLaFamilleChecked}
																					value={"C"}
																					onChange={q13abondanParLaFamille}
																				/>
																			}
																			label="Abandon de la victime par sa famille d'origine (parents)"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={stigmatisationChecked}
																					value={"D"}
																					onChange={q13stigmatisation}
																				/>
																			}
																			label="Stigmatisation de la victime par la communauté"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={perteDeLaGardeChecked}
																					value={"E"}
																					onChange={q13perteDeLaGarde}
																				/>
																			}
																			label="Perte de la garde ou de contact avec propres enfants"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={isolementDeLaVictimeChecked}
																					value={"F"}
																					onChange={q13isolementDeLaVictime}
																				/>
																			}
																			label="Isolement social de la victime"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						autreImpactSurLesRelationChecked
																					}
																					value={"G"}
																					onChange={
																						q13autreImpactSurLesRelation
																					}
																				/>
																			}
																			label="Autres"
																		/>
																	</FormGroup>
																</ListItem>
															</List>
														) : null}
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Snackbar
											open={openSnackBar}
											autoHideDuration={10}
											onClose={handleClose}
										>
											<Alert onClose={handleCloseSnackBar} severity="error">
												<Typography>{msg}</Typography>
											</Alert>
										</Snackbar>
									</Grid>
									<Grid
										container
										spacing={1}
										direction="row"
										justifyContent="flex-end"
									>
										<Grid item className={classes.marginButton}>
											{orgData !== null ? (
												<div>
													<BtnOutline onClick={handleRetour} content="RETOUR" />
													<BtnContained
														type="submit"
														//onClick={handleSauvegarde}
														content="SAUVEGARDER"
													/>
												</div>
											) : (
												<div>
													<BtnOutline
														component={Link}
														to={
															"/" +
															process.env.REACT_APP_COLLECT_LINK +
															"/collect/form/profile"
														}
														content={precedent}
														onClick={() => {
															//console.log('hello elijah');
														}}
													/>
													<BtnContained
														type="submit"
														// component={Link}
														// to='/collect/form/info'
														content={suivant}
													/>
												</div>
											)}
										</Grid>
									</Grid>
								</form>
							</Grid>
							<Grid item sm={1} xs={0}></Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default Incedent_info;

import React, { useState, useEffect } from "react";
import {
	Grid,
	Typography,
	Button,
	Link,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@material-ui/core";
import useStyles from "../../../styles";
import BackPaper from "../../../../../widget/backpaper";
import axiosInstance from "../../../../../service/dashboardAxiosApi";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import { useHistory } from "react-router-dom";
import InsertDriveFileSharpIcon from "@material-ui/icons/InsertDriveFileSharp";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactHtmlParser from "react-html-parser";

const Detail_post = () => {
	const classes = useStyles();
	const [postdata, setpostdata] = useState([]);
	const history = useHistory();
	const [open, setOpen] = React.useState(false);
	const theme = useTheme();

	//dialog

	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		//setAnchorEl(null);
		setOpen(false);
	};

	const handleUpdate = async () => {
		history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/post/edit`);
	};
	const handleDelete = async (id) => {
		axiosInstance
			.delete(`posts/${id}/`)
			.then((res) => {
				// console.log(res.data.message);
				history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/posts`);
			})
			.catch((err) => {
				if (err.response) {
					// console.log(err.response);
				} else if (err.request) {
					// console.log(err.request);
				}
			});
	};

	const handleBack = () => {
		history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/posts`);
	};
	useEffect(() => {
		//  console.log(localStorage.getItem('pstId'));

		const getPost = async () => {
			axiosInstance
				.get(`post/${localStorage.getItem("pstId")}/`)
				.then((res) => res.data.post)
				.then((res) => {
					//const data =res.data.post
					setpostdata(res);
					// console.log(res);
				});
		};
		getPost();
	}, [axiosInstance]);
	// console.log(postdata);

	return (
		<BackPaper
			content={
				postdata.length === 0 ? (
					<div>
						<Grid
							container
							spacing={0}
							direction="column"
							alignItems="center"
							justifyContent="center"
							className={classes.loading}
						>
							<Grid item xs={12} sm={12} className={classes.progbar}>
								<CircularProgress color="secondary" size={100} />
							</Grid>
						</Grid>
					</div>
				) : (
					<div>
						<Grid container direction="row">
							<Grid item xs={1} sm={1}></Grid>
							<Grid item xs={10} sm={10}>
								{postdata.image === null ? null : (
									<Grid container className={classes.img}>
										<Grid item xs={12} sm={12}>
											<Card>
												<CardActionArea>
													<CardMedia
														className={classes.posts_media}
														image={postdata.image}
													/>
												</CardActionArea>
											</Card>
										</Grid>
									</Grid>
								)}
								<Grid
									container
									alignItems="flex-start"
									className={classes.margin_Bottom_20}
								>
									<Grid item>
										<Typography
											style={{
												color: `${
													postdata.category === "Campaign news"
														? "#208BD1"
														: "#D12028"
												}`,
												fontWeight: "bold",
											}}
										>
											{postdata.category}
										</Typography>
										<Typography variant="caption" style={{ color: "#9e9e9e" }}>
											Posted: {postdata.created}
										</Typography>
									</Grid>
								</Grid>
								<Grid
									container
									direction="row"
									alignItems="flex-start"
									spacing={4}
									className={classes.margin_Bottom_10}
								>
									<Grid item>
										<Typography variant="h6">{postdata.title}</Typography>
									</Grid>
								</Grid>

								<Grid container className={classes.margin_Bottom_30}>
									<Grid item xs={12} sm={12}>
										{ReactHtmlParser(postdata.content)}
										{/* <textarea>{postdata.content}</textarea> */}
										{/* {sanitizeHtml(postdata.content,{
                                   allowedTags: [
                                    "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
                                    "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
                                    "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
                                    "ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
                                    "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
                                    "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
                                    "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr"
                                  ],
                                  allowedAttributes: {
                                    'a': [ 'href' ]
                                  },
                                  allowedIframeHostnames: ['www.youtube.com']
                               })} */}
									</Grid>
								</Grid>
								{postdata.link === null ? null : (
									<Grid container className={classes.margin_Bottom_10}>
										<Grid item>
											<Link href={postdata.link} target="_blank" rel="noopener">
												{" "}
												{postdata.link}
											</Link>
										</Grid>
									</Grid>
								)}
								{postdata.document === null ? null : (
									<Grid container className={classes.margin_Bottom_10}>
										<Grid item>
											<Link
												href={postdata.document}
												target="_blank"
												rel="noopener"
											>
												<InsertDriveFileSharpIcon style={{ fontSize: 12 }} />{" "}
												Document
											</Link>
										</Grid>
									</Grid>
								)}
								<Grid container direction="row">
									<Grid item xs={4} sm={4}>
										<Grid
											container
											justifyContent="flex-start"
											alignItems="flex-start"
										>
											<Grid item>
												<Button
													className={classes.btndrop2}
													onClick={handleClickOpen}
												>
													Delete
												</Button>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={8} sm={8}>
										<Grid
											container
											justifyContent="flex-end"
											alignItems="flex-end"
											direction="row"
										>
											<Grid item>
												<Button
													className={classes.btnback}
													variant="outlined"
													onClick={handleBack}
												>
													Back
												</Button>
											</Grid>

											<Grid item>
												<Button
													className={classes.btnContained}
													onClick={handleUpdate}
												>
													Edit
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Dialog
									fullScreen={fullScreen}
									open={open}
									onClose={handleClose}
									aria-labelledby="responsive-dialog-title"
								>
									<DialogTitle id="responsive-dialog-title">
										{"Are you sure you want to delete this post?"}
									</DialogTitle>
									<DialogContent>
										<DialogContentText>
											Once deleted, this post will be permanently lost.
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										<Button onClick={handleClose} style={{ color: "#009688" }}>
											Non
										</Button>
										{/* <Button autoFocus onClick={handleClose} color="primary">
                                ANNULER
                            </Button> */}
										<Button
											style={{ backgroundColor: "#D12028", color: "white" }}
											onClick={() => {
												handleDelete(postdata.id);
											}}
										>
											OUI
										</Button>
									</DialogActions>
								</Dialog>
							</Grid>
							<Grid item xs={1} sm={1}></Grid>
						</Grid>
					</div>
				)
			}
		/>
	);
};

export default Detail_post;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import {Grid } from '@material-ui/core'
// import Card from "@material-ui/core/Card";
// import CardActionArea from "@material-ui/core/CardActionArea";
// import CardMedia from "@material-ui/core/CardMedia";
import Image from "../assets/images/Bitm.png";
//import useStyles from "./style";

const useStyles = makeStyles((theme) => ({
  media: {
    [theme.breakpoints.up("sm")]: {
      height: '100%',
      width: "90%",
      // backgroundSize: "cover",
      //justify: "center",
    },

    [theme.breakpoints.down("xs")]: {
      height: '100%',
      width: "100%",
    },
  },
}));

export default function GalleryPic() {
  const classes = useStyles();

  return (
   <center><img className={classes.media} src={Image} alt="Figure" title="" /></center>
  );
}

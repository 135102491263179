import {makeStyles} from '@material-ui/core/styles'

export default makeStyles((theme)=>({


    fullWidthCard: {
        width: '100%',
      },
    
    cardContentMargin:{
        marginTop:30,
        marginRight:20,
        marginLeft:20,
        marginBottom: 30,
    },

    cardContentMarginRight:{
        marginRight:30,
    },
    filterHeightWebMobile:{
        height:'900px',
        overflow: 'auto',
        [theme.breakpoints.only('xl')]:{
            height:'800px',
            },
        [theme.breakpoints.only('lg')]:{
            height:'700px',
            },
        [theme.breakpoints.only('md')]:{
        height:1200,
        },
        [theme.breakpoints.only('sm')]:{
            height:'900px',
        },
        [theme.breakpoints.down('xs')]:{
            height:'620px',
        }


    },
    filterHeight:{
        overflow: 'auto',

        height:'100%',
    },


    widthChartLine:{
        width:150,
        minWidth:150,
        maxWidth:200,
        height:147,
        // [theme.breakpoints.up('xl')]:{
        //     width:350, 
        //     height:250,
        // }
    },
    innerAndoutRaduis:{
        innerRaduis:'60%',
        outerRadius:'120%'

    },
    

    loading:{
        color:"white",
        height:'70vh'
    },
    progbar:{
        marginTop:'20vh'
    },  
    loadingtext:{
        fontSize:100
    },  
    bigtitle:{
        marginTop: 30,
        marginBottom: 30,
        marginLeft: 25,
        color: '#1D384C',
        fontSize: 60,
        fontWeight:300,
        [theme.breakpoints.only('xs')]:{
            fontSize: 20,  
        }
    },
    textarea:{
        width:'100%',
        backgroundColor:"#E0F3F5",
        border:"0",
        padding:"10px"
    },
    dougth:{
        //fontWeight:300,
        fontSize:35,
        [theme.breakpoints.down('sm')]:{
            fontSize:25
        }
    },
    title:{
        marginTop:'60px',
        color: '#4F2970',
        fontSize: '36px'
    },
    subtitle:{
        color: '#4F2970',
        fontSize: '20px'
    },

    label:{
        fontSize: '15px',
        color: '#2D2C2F',
        fontWeight:300,
        marginBottom:"10px"
    },
    sublabel:{
        color: '#4F2970',
        fontSize: '14px',
        fontWeight:'regular',
        marginBottom:"10px",
        // fontWeight:300
    },
    subradio:{
        color: '#4F2970',
        fontSize: '14px',
        fontWeight:'regular'
    },
    radiolabel:{
        "& .MuiInputBase-root": {
            padding: 10
          }
    },
    
    grid:{
        marginLeft:10,
        marginRight:10, 
        marginBottom:50,
        borderBottom:0,
        boxShadow: "none"
           
    },
    tab:{
        fontSize:14,
        fontWeight:300
        
    },
    table:{
        marginTop: 20
    },
  
    tbPanel:{
        fontSize: 11,
        fontWeight:300
    },
    margin_Bottom_30:{
        marginBottom:30
    },
    margin_Bottom_10:{
        marginBottom:10
    },
    margin_Bottom_20:{
        marginTop:20,
        marginBottom:20
    },
    margin_Bottom_20s:{
       
       
        [theme.breakpoints.only('sm')]:{
           
            //marginBottom:20,
            marginTop:20
        },
        [theme.breakpoints.only('md')]:{
           
            //marginBottom:20,
            marginTop:20
        },
    },
    milieu:{
        [theme.breakpoints.between('lg','xl')]:{
          
            marginTop:30
        }
       ,
       [theme.breakpoints.only('md')]:{
          
        marginTop:30
    }
   ,
   [theme.breakpoints.only('sm')]:{
          
    marginTop:30
}
    },
    margin_10:{
        margin:10
    },
    margin_20:{
        [theme.breakpoints.only('xs')]:{
            marginBottom:20
        },
        
    },
    margin_Top_30:{
        marginTop:30

    },
    answer:{
        color: '#00A1AF',
        fontSize:15
    },
    answer_view:{
        marginTop:10,
        marginRight:5,
        marginLeft:5,
        color: '#00A1AF',
        fontWeight:300
       
    },
    titleanswer:{
        marginTop:10,
        marginRight:5,
        marginLeft:5,
        fontWeight:400
    },

    footerDate:{
        fontSize:10,
        marginTop:10,
        marginRight:5,
        marginLeft:5,
        marginBottom:10
    },

    indicatedColor:{
        backgroundColor:'#00A1AF',
       
    },

    inputSelector:{
        backgroundColor:'#F0F0F0',
        border: '1px solid #F0F0F0',
        fontWeight:300
        // '&:after':{
        //     border: '1px solid #F0F0F0',
        // }  
    },

    leftAndRight:{
        marginTop:30,
        marginRight:20,
        marginLeft:20
    },


    leftAndRightMiddle:{
        marginRight:20,
        marginLeft:20
    },

    paperBig:{
        width:700,
        height: 300
        
    },

    //
    leftAndRightAndTopAndBottom:{
        [theme.breakpoints.down('sm')]:{
            paddingRight:10,
            paddingLeft:10,
            paddingBottom:20,
            paddingTop:10,
            marginBottom:30
            
        },
        [theme.breakpoints.down('md')]:{
            paddingRight:15,
            paddingLeft:15,
            paddingBottom:25,
            paddingTop:15,
            marginBottom:30
        },
        [theme.breakpoints.down('lg')]:{
            paddingRight:20,
            paddingLeft:20,
            paddingBottom:30,
            paddingTop:20,
            marginBottom:30
        },
        [theme.breakpoints.up('xl')]:{
            paddingRight:20,
            paddingLeft:20,
            paddingBottom:30,
            paddingTop:20,
            marginBottom:30
        },
       
    },
    typochart:{
        [theme.breakpoints.only('xs')]:{
            fontSize:12,
            // marginLeft:20,
            fontWeight:350,
          //  marginTop:20,
            marginBottom:20
        },
        [theme.breakpoints.only('md')]:{
            fontSize:14,
            // marginLeft:20,
            fontWeight:350,
          //  marginTop:20,
            marginBottom:20
        },
        [theme.breakpoints.only('lg')]:{
            fontSize:18,
             //marginLeft:20,
            fontWeight:350,
            //marginTop:20,
            marginBottom:20

        },
        [theme.breakpoints.up('lg')]:{
            fontSize:18,
             marginLeft:20,
            fontWeight:350,
            marginTop:20,
            marginBottom:20

        }
    },

    typosubchart:{
        fontSize:12,
        fontWeight:300,
        [theme.breakpoints.only('xs')]:{
            fontSize:12
            
        },
        [theme.breakpoints.only('sm')]:{
            fontSize:11
        },
        [theme.breakpoints.only('md')]:{
            fontSize:11
        }

        
    },

    q8card:{
        height:112,
        
    },
    q8cardDashBoard:{
        height:116,
        
    },
    q8typo:{
        fontSize:15,
        fontWeight:300,
        [theme.breakpoints.only('xs')]:{
            fontSize:13
        },
        // marginLeft:10,
        // marginRight:10
    },
    q8num:{
        fontSize:35,
        fontWeight:300,
        [theme.breakpoints.only('xs')]:{
            fontSize:25
        },
    },
    ckeditor:{
      //  height:300,
        marginBottom:30
    },
    img:{
        marginTop:100,
        borderBottom:30,
        height:500,
        [theme.breakpoints.only('xs')]:{
            height:320,
        }
     //   marginBottom:30
    },
    btnContained:{
        backgroundColor:'#00A1AF',
        color: '#FFFFFF',
        fontSize:'10px',
        marginTop:30,
        '&:hover':{
            backgroundColor:'#00A1AF'
        },
        height:'30px',
        width:  '100px',
        marginRight:10,
        [theme.breakpoints.down('md')]:{
            width:90,
            marginRight:10
        },
        [theme.breakpoints.down('sm')]:{
            width:80,
            fontSize:10,
            marginRight:10

        },

        fontWeight:300

    },
    btnback:{
       // backgroundColor:'#00A1AF',
        // color: '#0000',
        fontSize:'10px',
        marginTop:30,
        '&:hover':{
            backgroundColor:'transparent'
        },
        height:'30px',
        width:  '100px',
        marginRight:10,
        [theme.breakpoints.down('md')]:{
            width:90,
            marginRight:10
        },
        [theme.breakpoints.down('sm')]:{
            width:80,
            fontSize:10,
            marginRight:10

        }

    },
    btndrop2:{
      fontWeight:300,  
      [theme.breakpoints.down('sm')]:{
          width:90,
          
      },
      [theme.breakpoints.up('sm')]:{
          width : 100
      },
     //  marginTop:15,
      //  paddingRight:10,
      // marginLeft:10,
      marginTop:30,

      height: 30,
      color:"#D12028",
    //  backgroundColor:"#D12028",

      fontSize: 10,
      // color: ' #FFF',
      '&:hover':{
          backgroundColor:'#D12028',
          color: ' #FFF',
      },

  },
  posts:{
    maxWidth: 345,
   // padding:10,
  },
  posts_media:{
    height: 500,
    [theme.breakpoints.only('xs')]:{
        height:320,
    }
  },

  margin_Bottom_20:{
      marginBottom:20
  },
  marginLeft:{},
  marginLefts:{},

   list: {
    width: "25vw",
    [theme.breakpoints.down('sm')]:{
        width: 300
    }
  },
  fullList: {
    width: "auto",
    [theme.breakpoints.down('sm')]:{
        width: 300
    }
  },
  btn:{
    marginTop:40,
    marginBottom:40
  } ,

  detailTypo:{
      fontWeight:300,

  },
  detailTypoGrow:{
    fontWeight:300,
    
},
editDetailTypo:{
    fontWeight:300,
    marginBottom: 10     
},


button:{
    fontWeight:300
},

bottom_margin :{
    marginBottom:20,
   
},


tabDataCount0: {
    backgroundColor: "#FA6400", 
    borderRadius: '5px',
    padding: '0 5px', 
    marginLeft: '5px', 
    color: 'white', 
    fontWeight: 300,
},
tabDataCount1: {
    backgroundColor: '#00A1AF', 
    borderRadius: '5px', 
    padding: '0 5px', 
    marginLeft: '5px', 
    color: 'white', 
    ontWeight: 300,
},
tabDataCount2: {
    backgroundColor: 'red', 
    borderRadius: '5px', 
    padding: '0 5px', 
    marginLeft: '5px', 
    color: 'white', 
},


// disabledTextField: { 
//     "& .Mui-disabled": {
//         color: '#00A1AF',
//         fontWeight:300,
//         maxWidth: "1000px",
//         width: "fit-content"
//     },
//   },

  disabledTextField: {
        color: '#00A1AF',
        fontWeight:300,
        border: '1px solid #ccc', 
        borderRadius: 4, 
        padding: '5px 10px', 
        maxWidth: '1000px', 
        whiteSpace: 'nowrap', 
        overflow: 'hidden', 
        textOverflow: 'ellipsis'

  },
   
    
      
}))
import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import useStyles from "./style";
import Section1 from "../../component/website/index/section1";
import Section2 from "../../component/website/index/section2";
import Section3 from "../../component/website/index/section3";
import Section4 from "../../component/website/index/section4";
import Section5 from "../../component/website/index/section5";
// import Section6 from "../../component/website/index/section6";
import Section7 from "../../component/website/index/section7";

const Website = () => {
	const classes = useStyles();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div>
			<Grid container direction="column">
				{/* section 1 */}
				<Grid container className={classes.section_1} alignItems="center">
					<Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
					<Grid item xs={10} sm={10} md={10} lg={10} xl={8}>
						<Section1 />
					</Grid>
					<Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
				</Grid>
				{/* section 2 */}
				<Grid container>
					<Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
					<Grid item xs={10} sm={10} md={10} lg={10} xl={8}>
						<Section2 />
					</Grid>
					<Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
				</Grid>
				{/* section 3 */}

				<Grid container className={classes.section_3} alignItems="center">
					<Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
					<Grid item xs={10} sm={10} md={10} lg={10} xl={8}>
						<Section3 />
					</Grid>
					<Grid xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
				</Grid>

				{/* section 4 */}
				<Grid
					container
					direction="row"
					justifyContent="center"
					alignItems="flex-start"
					className={classes.section_4}
				>
					<Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
					<Grid item xs={10} sm={10} md={10} lg={10} xl={8}>
						<Section4 />
					</Grid>
					<Grid xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
				</Grid>
				{/* section 5 */}
				<Grid container className={classes.section_3} alignItems="center">
					<Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
					<Grid item xs={10} sm={10} md={10} lg={10} xl={8}>
						<Section5 />
					</Grid>
					<Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
				</Grid>
				{/* section 6 */}
				{/* <Grid container direction="row" alignItems="flex-start">
          <Grid md={1} lg={1} xl={2}></Grid>
          <Grid md={10} lg={10} xl={8}>
            <Section6 />
          </Grid>
          <Grid md={1} lg={1} xl={2}></Grid>
        </Grid> */}
				{/* section 7 */}

				<Grid container alignItems="center">
					<Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
					<Grid item xs={10} sm={10} md={10} lg={10} xl={8}>
						<Section7 />
					</Grid>
					<Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export default Website;

import React, { useState, useEffect } from "react";
import {
	Grid,
	Typography,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import "./../../../../App.css";

import Map from "./Map";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import CasedatabankTable from "./Table";
import useStyles from "../../dashboard/styles";
import axiosInstance from "../../../service/axiosPub";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// import CloudOffTwoToneIcon from '@material-ui/icons/CloudOffTwoTone';
// import RestoreIcon from '@material-ui/icons/Restore';
// import StorageIcon from '@material-ui/icons/Storage';
// import Skeleton from '@material-ui/lab/Skeleton';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";

const CaseMap = () => {
	const classes = useStyles();
	const [casemap, setcasemap] = useState([]);
	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};
	const [q10value, setq10value] = useState(false);
	const [q10bvalue, setq10bvalue] = useState(false);
	const [q10cvalue, setq10vcalue] = useState(false);
	const [q10dvalue, setq10vadlue] = useState(false);
	const [q6bvalue, setq6bvalue] = useState(false);
	const [q7value, setq7value] = useState(false);
	const [q8avalue, setq8avalue] = useState(false);
	const [q8bvalue, setq8bvalue] = useState(false);
	const [q8cvalue, setq8cvalue] = useState(false);
	const [q8dvalue, setq8dvalue] = useState(false);
	const [q11value, setq11value] = useState(false);
	const [q12avalue, setq12avalue] = useState(false);
	const [ituri, setituri] = useState(false);
	const [kasaicentral, setkasaicentral] = useState(false);
	const [kasaioriental, setkasaioriental] = useState(false);
	const [maniema, setmaniema] = useState(false);
	const [nordkivu, setnordkivu] = useState(false);
	const [sudkivu, setsudkivu] = useState(false);
	const [tshopo, settshopo] = useState(false);
	const [q13bvalue, setq13bvalue] = useState(false);
	const [q13bbvalue, setq13bbvalue] = useState(false);
	const [q13bcvalue, setq13bcvalue] = useState(false);
	const [q13bdvalue, setq13bdvalue] = useState(false);
	const [q14avalue, setq14avalue] = useState(false);
	const [q14bvalue, setq14bvalue] = useState(false);
	const [q14cvalue, setq14cvalue] = useState(false);
	const [q15value, setq15value] = useState(false);
	const [q16value, setq16value] = useState(false);
	const [q18value, setq18value] = useState(false);
	const [casefilter, setcasefilter] = useState([]);
	const [filterChecked, setfilterChecked] = useState(false);
	const [active, setactive] = useState(false);
	const [spinner, setspinner] = useState(true);
	const [errmsg, seterrmsg] = useState("");
	const [statecode, setstatecode] = useState(false);
	const { t, i18n } = useTranslation();
	const [filtedDataFrench, setfiltedDataFrench] = useState([]);

	const getCase = async () => {
		setspinner(true);
		await axiosInstance
			.get(`pubcases/`)
			.then((res) => {
				if (res.status === 200) {
					const data = res.data.approved;

					setcasemap(data);
					setactive(true);
					setstatecode(true);
					seterrmsg("Approve cases successfull fetched");
				}
			})
			.catch((err) => {
				setspinner(false);
				if (err.response) {
					seterrmsg("Please check your internet connexion");
				} else if (err.request) {
					//  console.log(err.request.timeout);
					seterrmsg("Please check your internet connexion");
				}
			});
	};
	//from filter
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});
	const q10Boxa = () => {
		//  setq10value(true)
		if (q10value === true) {
			setq10value(false);
		} else if (q10value === false) {
			setq10value(true);
		}
	};
	const q10bBoxa = () => {
		//  setq10value(true)
		if (q10bvalue === true) {
			setq10bvalue(false);
		} else if (q10bvalue === false) {
			setq10bvalue(true);
		}
	};
	const q10cBoxa = () => {
		//  setq10value(true)
		if (q10cvalue === true) {
			setq10vcalue(false);
		} else if (q10cvalue === false) {
			setq10vcalue(true);
		}
	};
	const q10dBoxa = () => {
		//  setq10value(true)
		if (q10dvalue === true) {
			setq10vadlue(false);
		} else if (q10dvalue === false) {
			setq10vadlue(true);
		}
	};
	const q6bBox = () => {
		//  setq10value(true)
		if (q6bvalue === true) {
			setq6bvalue(false);
		} else if (q6bvalue === false) {
			setq6bvalue(true);
		}
	};
	const q7Box = () => {
		//  setq10value(true)
		if (q7value === true) {
			setq7value(false);
		} else if (q7value === false) {
			setq7value(true);
		}
	};
	const q8aBox = () => {
		//  setq10value(true)
		if (q8avalue === true) {
			setq8avalue(false);
		} else if (q8avalue === false) {
			setq8avalue(true);
		}
	};
	const q8bBox = () => {
		//  setq10value(true)
		if (q8bvalue === true) {
			setq8bvalue(false);
		} else if (q8bvalue === false) {
			setq8bvalue(true);
		}
	};

	const q8cBox = () => {
		//  setq10value(true)
		if (q8cvalue === true) {
			setq8cvalue(false);
		} else if (q8cvalue === false) {
			setq8cvalue(true);
		}
	};

	const q8dBox = () => {
		//  setq10value(true)
		if (q8dvalue === true) {
			setq8dvalue(false);
		} else if (q8dvalue === false) {
			setq8dvalue(true);
		}
	};
	const q11Box = () => {
		//  setq10value(true)
		if (q11value === true) {
			setq11value(false);
		} else if (q11value === false) {
			setq11value(true);
		}
	};
	const q12aBox = () => {
		//  setq10value(true)
		if (q12avalue === true) {
			setq12avalue(false);
		} else if (q12avalue === false) {
			setq12avalue(true);
		}
	};
	const ituriBox = () => {
		//  setq10value(true)
		if (ituri === true) {
			setituri(false);
		} else if (ituri === false) {
			setituri(true);
		}
	};
	const kasaicentralBox = () => {
		//  setq10value(true)
		if (kasaicentral === true) {
			setkasaicentral(false);
		} else if (kasaicentral === false) {
			setkasaicentral(true);
		}
	};
	const kasaioirientalBox = () => {
		//  setq10value(true)
		if (kasaioriental === true) {
			setkasaioriental(false);
		} else if (kasaioriental === false) {
			setkasaioriental(true);
		}
	};
	const maniemaBox = () => {
		//  setq10value(true)
		if (maniema === true) {
			setmaniema(false);
		} else if (maniema === false) {
			setmaniema(true);
		}
	};
	const nordkivuBox = () => {
		//  setq10value(true)
		if (nordkivu === true) {
			setnordkivu(false);
		} else if (nordkivu === false) {
			setnordkivu(true);
		}
	};
	const sudkivuBox = () => {
		//  setq10value(true)
		if (sudkivu === true) {
			setsudkivu(false);
		} else if (sudkivu === false) {
			setsudkivu(true);
		}
	};
	const tshopoBox = () => {
		//  setq10value(true)
		if (tshopo === true) {
			settshopo(false);
		} else if (tshopo === false) {
			settshopo(true);
		}
	};

	const q13bBox = () => {
		//  setq10value(true)
		if (q13bvalue === true) {
			setq13bvalue(false);
		} else if (q13bvalue === false) {
			setq13bvalue(true);
		}
	};
	const q13bbBox = () => {
		//  setq10value(true)
		if (q13bbvalue === true) {
			setq13bbvalue(false);
		} else if (q13bbvalue === false) {
			setq13bbvalue(true);
		}
	};
	const q13bcBox = () => {
		//  setq10value(true)
		if (q13bcvalue === true) {
			setq13bcvalue(false);
		} else if (q13bcvalue === false) {
			setq13bcvalue(true);
		}
	};
	const q13bdBox = () => {
		//  setq10value(true)
		if (q13bdvalue === true) {
			setq13bdvalue(false);
		} else if (q13bdvalue === false) {
			setq13bdvalue(true);
		}
	};

	const q14aBox = () => {
		//  setq10value(true)
		if (q14avalue === true) {
			setq14avalue(false);
		} else if (q14avalue === false) {
			setq14avalue(true);
		}
	};

	const q14bBox = () => {
		//  setq10value(true)
		if (q14bvalue === true) {
			setq14bvalue(false);
		} else if (q14bvalue === false) {
			setq14bvalue(true);
		}
	};
	const q14cBox = () => {
		//  setq10value(true)
		if (q14cvalue === true) {
			setq14cvalue(false);
		} else if (q14cvalue === false) {
			setq14cvalue(true);
		}
	};

	const q15Box = () => {
		//  setq10value(true)
		if (q15value === true) {
			setq15value(false);
		} else if (q15value === false) {
			setq15value(true);
		}
	};
	const q16Box = () => {
		//  setq10value(true)
		if (q16value === true) {
			setq16value(false);
		} else if (q16value === false) {
			setq16value(true);
		}
	};
	const q18Box = () => {
		//  setq10value(true)
		if (q18value === true) {
			setq18value(false);
		} else if (q18value === false) {
			setq18value(true);
		}
	};
	//q6
	const [q6a, setq6a] = useState();
	const [q6achecked, setq6achecked] = useState(false);

	const handleq6a = (e) => {
		const value = e.target.value;
		if (!q6achecked) {
			setq6a(value);
			setq6achecked(true);
			setfilterChecked(true);
		} else {
			setq6a();
			setq6achecked(false);
			//setfilterChecked(false)
		}
	};
	const [q6b, setq6b] = useState();
	const [q6bchecked, setq6bchecked] = useState(false);

	const handleq6b = (e) => {
		const value = e.target.value;
		if (!q6bchecked) {
			setq6b(value);
			setq6bchecked(true);
			setfilterChecked(true);
		} else {
			setq6b();
			setq6bchecked(false);
			// setfilterChecked(false)
		}
	};
	const [q6c, setq6c] = useState();
	const [q6cchecked, setq6cchecked] = useState(false);

	const handleq6c = (e) => {
		const value = e.target.value;
		if (!q6cchecked) {
			setq6c(value);
			setq6cchecked(true);
			setfilterChecked(true);
		} else {
			setq6c();
			setq6cchecked(false);
		}
	};
	const [q6d, setq6d] = useState();
	const [q6dchecked, setq6dchecked] = useState(false);

	const handleq6d = (e) => {
		const value = e.target.value;
		if (!q6dchecked) {
			setq6d(value);
			setq6dchecked(true);
			setfilterChecked(true);
		} else {
			setq6d();
			setq6dchecked(false);
		}
	};
	const [q6e, setq6e] = useState();
	const [q6echecked, setq6echecked] = useState(false);

	const handleq6e = (e) => {
		const value = e.target.value;
		if (!q6echecked) {
			setq6e(value);
			setq6echecked(true);
			setfilterChecked(true);
		} else {
			setq6e();
			setq6echecked(false);
		}
	};
	const [q6f, setq6f] = useState();
	const [q6fchecked, setq6fchecked] = useState(false);

	const handleq6f = (e) => {
		const value = e.target.value;
		if (!q6fchecked) {
			setq6f(value);
			setq6fchecked(true);
			setfilterChecked(true);
		} else {
			setq6f();
			setq6fchecked(false);
		}
	};
	//q7
	const [q7fem, setq7fem] = useState();
	const [q7femchecked, setq7femchecked] = useState(false);

	const handleq7fem = (e) => {
		const value = e.target.value;
		if (!q7femchecked) {
			setq7fem(value);
			setq7femchecked(true);
			setfilterChecked(true);
		} else {
			setq7fem();
			setq7femchecked(false);
		}
	};
	const [q7masc, setq7masc] = useState();
	const [q7mascchecked, setq7mascchecked] = useState(false);

	const handleq7masc = (e) => {
		const value = e.target.value;
		if (!q7mascchecked) {
			setq7masc(value);
			setq7mascchecked(true);
			setfilterChecked(true);
		} else {
			setq7masc();
			setq7mascchecked(false);
		}
	};
	const [q7autr, setq7autr] = useState();
	const [q7autrchecked, setq7autrchecked] = useState(false);

	const handleq7autr = (e) => {
		const value = e.target.value;
		if (!q7autrchecked) {
			setq7autr(value);
			setq7autrchecked(true);
			setfilterChecked(true);
		} else {
			setq7autr();
			setq7autrchecked(false);
		}
	};

	//q8a
	const [q8aCel, setq8aCel] = useState();
	const [q8aCelchecked, setq8aCelchecked] = useState(false);

	const handleq8aCel = (e) => {
		const value = e.target.value;
		if (!q8aCelchecked) {
			setq8aCel(value);
			setq8aCelchecked(true);
			setfilterChecked(true);
		} else {
			setq8aCel();
			setq8aCelchecked(false);
		}
	};
	const [q8aUnion, setq8aUnion] = useState();
	const [q8aUnionchecked, setq8aUnionchecked] = useState(false);

	const handleq8aUnion = (e) => {
		const value = e.target.value;
		if (!q8aUnionchecked) {
			setq8aUnion(value);
			setq8aUnionchecked(true);
			setfilterChecked(true);
		} else {
			setq8aUnion();
			setq8aUnionchecked(false);
		}
	};
	const [q8aMarie, setq8aMarie] = useState();
	const [q8aMariechecked, setq8aMariechecked] = useState(false);

	const handleq8aMarie = (e) => {
		const value = e.target.value;
		if (!q8aMariechecked) {
			setq8aMarie(value);
			setq8aMariechecked(true);
			setfilterChecked(true);
		} else {
			setq8aMarie();
			setq8aMariechecked(false);
		}
	};
	const [q8aSepare, setq8aSepare] = useState();
	const [q8aSeparechecked, setq8aSeparechecked] = useState(false);

	const handleq8aSepare = (e) => {
		const value = e.target.value;
		if (!q8aSeparechecked) {
			setq8aSepare(value);
			setq8aSeparechecked(true);
			setfilterChecked(true);
		} else {
			setq8aSepare();
			setq8aSeparechecked(false);
		}
	};
	const [q8aDivorce, setq8aDivorce] = useState();
	const [q8aDivorcechecked, setq8aDivorcechecked] = useState(false);

	const handleq8aDivorce = (e) => {
		const value = e.target.value;
		if (!q8aDivorcechecked) {
			setq8aDivorce(value);
			setq8aDivorcechecked(true);
			setfilterChecked(true);
		} else {
			setq8aDivorce();
			setq8aDivorcechecked(false);
		}
	};
	const [q8aVeuf, setq8aVeuf] = useState();
	const [q8aVeufchecked, setq8aVeufchecked] = useState(false);

	const handleq8aVeuf = (e) => {
		const value = e.target.value;
		if (!q8aVeufchecked) {
			setq8aVeuf(value);
			setq8aVeufchecked(true);
			setfilterChecked(true);
		} else {
			setq8aVeuf();
			setq8aVeufchecked(false);
		}
	};
	//q8b
	const [q8blgbti, setq8blgbti] = useState();
	const [q8blgbtichecked, setq8blgbtichecked] = useState(false);

	const handleq8blgbti = (e) => {
		const value = e.target.value;
		if (!q8blgbtichecked) {
			setq8blgbti(value);
			setq8blgbtichecked(true);
			setfilterChecked(true);
		} else {
			setq8blgbti();
			setq8blgbtichecked(false);
		}
	};

	//q8c
	const [q8cHandicap, setq8cHandicap] = useState();
	const [q8cHandicapchecked, setq8cHandicapchecked] = useState(false);

	const handleq8cHandicap = (e) => {
		const value = e.target.value;
		if (!q8cHandicapchecked) {
			setq8cHandicap(value);
			setq8cHandicapchecked(true);
			setfilterChecked(true);
		} else {
			setq8cHandicap();
			setq8cHandicapchecked(false);
		}
	};
	//q8d
	const [q8dVictime, setq8dVictime] = useState();
	const [q8dVictimechecked, setq8dVictimechecked] = useState(false);

	const handleq8dVictime = (e) => {
		const value = e.target.value;
		if (!q8dVictimechecked) {
			setq8dVictime(value);
			setq8dVictimechecked(true);
			setfilterChecked(true);
		} else {
			setq8dVictime();
			setq8dVictimechecked(false);
		}
	};

	const [q8dVictimeSans, setq8dVictimeSans] = useState();
	const [q8dVictimeSanschecked, setq8dVictimeSanschecked] = useState(false);

	const handleq8dVictimeSans = (e) => {
		const value = e.target.value;
		if (!q8dVictimeSanschecked) {
			setq8dVictimeSans(value);
			setq8dVictimeSanschecked(true);
			setfilterChecked(true);
		} else {
			setq8dVictimeSans();
			setq8dVictimeSanschecked(false);
		}
	};

	// q10

	//incident de violence sexuelle
	const [viol, setviol] = useState();
	const [violChecked, setviolChecked] = useState(false);
	const q10Viol = (e) => {
		const value = e.target.value;
		if (!violChecked) {
			setviol(value);
			setviolChecked(true);
			setfilterChecked(true);
		} else {
			setviol();
			setviolChecked(false);
		}
	};
	const [conj, setconj] = useState();
	const [conjChecked, setconjChecked] = useState(false);
	const q10Conjugal = (e) => {
		const value = e.target.value;

		if (!conjChecked) {
			setconjChecked(true);
			setconj(value);
			setfilterChecked(true);
		} else {
			setconjChecked(false);
			setconj();
		}
	};
	const [agressio, setagressio] = useState();
	const [agressioChecked, setagressioChecked] = useState(false);
	const q10Agression = (e) => {
		const value = e.target.value;

		if (!agressioChecked) {
			setagressioChecked(true);
			setagressio(value);
			setfilterChecked(true);
		} else {
			setagressio();
			setagressioChecked(false);
		}
	};

	const [coercition, setcoercition] = useState();
	const [coerChecked, setcoerChecked] = useState(false);

	const q10Coercition = (e) => {
		const value = e.target.value;

		if (!coerChecked) {
			setcoerChecked(true);
			setcoercition(value);
			setfilterChecked(true);
		} else {
			setcoercition();
			setcoerChecked(false);
		}
	};
	const [harcelement, setHarcelement] = useState();
	const [harcChecked, setharcChecked] = useState(false);

	const q10Harcelement = (e) => {
		const value = e.target.value;

		if (!harcChecked) {
			setHarcelement(value);
			setharcChecked(true);
			setfilterChecked(true);
		} else {
			setHarcelement();
			setharcChecked(false);
		}
	};
	const [attouchement, setAttouchements] = useState();
	const [attouChecked, setattoucChecked] = useState(false);

	const q10Attouchements = (e) => {
		const value = e.target.value;

		if (!attouChecked) {
			setAttouchements(value);
			setattoucChecked(true);
			setfilterChecked(true);
		} else {
			setAttouchements();
			setattoucChecked(false);
		}
	};
	const [etreForce, setetreForces] = useState();
	const [etrForChecked, setetrForChecked] = useState(false);

	const q10etreForces = (e) => {
		const value = e.target.value;

		if (!etrForChecked) {
			setetreForces(value);
			setetrForChecked(true);
			setfilterChecked(true);
		} else {
			setetreForces();
			setetrForChecked(false);
		}
	};
	const [etreEmpeche, setetreEmpeches] = useState();
	const [etrEmpChecked, setetrEmpChecked] = useState(false);

	const q10etreEmpeches = (e) => {
		const value = e.target.value;

		if (!etrEmpChecked) {
			setetreEmpeches(value);
			setetrEmpChecked(true);
			setfilterChecked(true);
		} else {
			setetreEmpeches();
			setetrEmpChecked(false);
		}
	};
	const [autreViolSexuel, setautreViolSexuels] = useState();
	const [autreViolSexuelChecked, setautreViolSexuelChecked] = useState(false);

	const q10autreViolSexuels = (e) => {
		const value = e.target.value;

		if (!autreViolSexuelChecked) {
			setautreViolSexuels(value);
			setautreViolSexuelChecked(true);
			setfilterChecked(true);
		} else {
			setautreViolSexuels();
			setautreViolSexuelChecked(false);
		}
	};
	//
	//incident de violence physique
	const [meutre, setmeutre] = useState();
	const [meutrChecked, setmeutrChecked] = useState(false);

	const q10Meutre = (e) => {
		const value = e.target.value;

		if (!meutrChecked) {
			setmeutre(value);
			setmeutrChecked(true);
			setfilterChecked(true);
		} else {
			setmeutre();
			setmeutrChecked(false);
		}
	};

	const [coupDePoing, setcoupDePoing] = useState();
	const [coupDePoingChecked, setcoupDePoingChecked] = useState(false);

	const q10coupDePoing = (e) => {
		const value = e.target.value;

		if (!coupDePoingChecked) {
			setcoupDePoing(value);
			setcoupDePoingChecked(true);
			setfilterChecked(true);
		} else {
			setcoupDePoing();
			setcoupDePoingChecked(false);
		}
	};

	const [coupDePied, setcoupDePied] = useState();
	const [coupDePiedChecked, setcoupDePiedChecked] = useState(false);

	const q10coupDePied = (e) => {
		const value = e.target.value;

		if (!coupDePiedChecked) {
			setcoupDePied(value);
			setcoupDePiedChecked(true);
			setfilterChecked(true);
		} else {
			setcoupDePied();
			setcoupDePiedChecked(false);
		}
	};

	const [attaque, setattaque] = useState();
	const [attaqueChecked, setattaqueChecked] = useState(false);

	const q10attaque = (e) => {
		const value = e.target.value;

		if (!attaqueChecked) {
			setattaque(value);
			setattaqueChecked(true);
			setfilterChecked(true);
		} else {
			setattaque();
			setattaqueChecked(false);
		}
	};

	const [attaqueACouteau, setattaqueACouteau] = useState();
	const [attaqueACouteauChecked, setattaqueACouteauChecked] = useState(false);

	const q10attaqueACouteau = (e) => {
		const value = e.target.value;

		if (!attaqueACouteauChecked) {
			setattaqueACouteau(value);
			setattaqueACouteauChecked(true);
			setfilterChecked(true);
		} else {
			setattaqueACouteau();
			setattaqueACouteauChecked(false);
		}
	};

	const [attaqueAEaux, setattaqueAEaux] = useState();
	const [attaqueAEauxChecked, setattaqueAEauxChecked] = useState(false);

	const q10attaqueAEaux = (e) => {
		const value = e.target.value;

		if (!attaqueAEauxChecked) {
			setattaqueAEaux(value);
			setattaqueAEauxChecked(true);
			setfilterChecked(true);
		} else {
			setattaqueAEaux();
			setattaqueAEauxChecked(false);
		}
	};

	const [privation, setprivation] = useState();
	const [privationChecked, setprivationChecked] = useState(false);

	const q10privation = (e) => {
		const value = e.target.value;

		if (!privationChecked) {
			setprivation(value);
			setprivationChecked(true);
			setfilterChecked(true);
		} else {
			setprivation();
			setprivationChecked(false);
		}
	};

	const [privationDeNouriture, setprivationDeNouriture] = useState();
	const [privationDeNouritureChecked, setprivationDeNouritureChecked] =
		useState(false);

	const q10privationDeNouriture = (e) => {
		const value = e.target.value;

		if (!privationDeNouritureChecked) {
			setprivationDeNouriture(value);
			setprivationDeNouritureChecked(true);
			setfilterChecked(true);
		} else {
			setprivationDeNouriture();
			setprivationDeNouritureChecked(false);
		}
	};

	const [negligencePhyDenfant, setnegligencePhyDenfant] = useState();
	const [negligencePhyDenfantChecked, setnegligencePhyDenfantChecked] =
		useState(false);

	const q10negligencePhyDenfant = (e) => {
		const value = e.target.value;

		if (!negligencePhyDenfantChecked) {
			setnegligencePhyDenfant(value);
			setnegligencePhyDenfantChecked(true);
			setfilterChecked(true);
		} else {
			setnegligencePhyDenfant();
			setnegligencePhyDenfantChecked(false);
		}
	};

	const [negligencePhyPerson, setnegligencePhyPerson] = useState();
	const [negligencePhyPersonChecked, setnegligencePhyPersonChecked] =
		useState(false);

	const q10negligencePhyPerson = (e) => {
		const value = e.target.value;

		if (!negligencePhyPersonChecked) {
			setnegligencePhyPerson(value);
			setnegligencePhyPersonChecked(true);
			setfilterChecked(true);
		} else {
			setnegligencePhyPerson();
			setnegligencePhyPersonChecked(false);
		}
	};
	const [destructionDobject, setdestructionDobject] = useState();
	const [destructionDobjectChecked, setdestructionDobjectChecked] =
		useState(false);

	const q10destructionDobject = (e) => {
		const value = e.target.value;

		if (!destructionDobjectChecked) {
			setdestructionDobject(value);
			setdestructionDobjectChecked(true);
			setfilterChecked(true);
		} else {
			setdestructionDobject();
			setdestructionDobjectChecked(false);
		}
	};

	const [ritesDeVeuvage, setritesDeVeuvage] = useState();
	const [ritesDeVeuvageChecked, setritesDeVeuvageChecked] = useState(false);

	const q10ritesDeVeuvage = (e) => {
		const value = e.target.value;

		if (!ritesDeVeuvageChecked) {
			setritesDeVeuvage(value);
			setritesDeVeuvageChecked(true);
			setfilterChecked(true);
		} else {
			setritesDeVeuvage();
			setritesDeVeuvageChecked(false);
		}
	};

	const [autreViolPhysique, setAutresViolPhysique] = useState();
	const [autreViolPhysiqueChecked, setAutresViolPhysiqueChecked] =
		useState(false);

	const q10autreViolPhysique = (e) => {
		const value = e.target.value;

		if (!autreViolPhysiqueChecked) {
			setAutresViolPhysique(value);
			setAutresViolPhysiqueChecked(true);
			setfilterChecked(true);
		} else {
			setAutresViolPhysique();
			setAutresViolPhysiqueChecked(false);
		}
	};

	//
	//incident de violence psychologique
	const [menace, setmenace] = useState();
	const [menaceChecked, setmenaceChecked] = useState(false);

	const q10menace = (e) => {
		const value = e.target.value;

		if (!menaceChecked) {
			setmenace(value);
			setmenaceChecked(true);
			setfilterChecked(true);
		} else {
			setmenace();
			setmenaceChecked(false);
		}
	};

	const [injure, setinjure] = useState();
	const [injureChecked, setinjureChecked] = useState(false);

	const q10injure = (e) => {
		const value = e.target.value;

		if (!injureChecked) {
			setinjure(value);
			setinjureChecked(true);
			setfilterChecked(true);
		} else {
			setinjure();
			setinjureChecked(false);
		}
	};
	const [negligenceEmotionnel, setnegligenceEmotionnel] = useState();
	const [negligenceEmotionnelChecked, setnegligenceEmotionnelChecked] =
		useState(false);

	const q10negligenceEmotionnel = (e) => {
		const value = e.target.value;

		if (!negligenceEmotionnelChecked) {
			setnegligenceEmotionnel(value);
			setnegligenceEmotionnelChecked(true);
			setfilterChecked(true);
		} else {
			setnegligenceEmotionnel();
			setnegligenceEmotionnelChecked(false);
		}
	};

	const [humiliationFace, sethumiliationFace] = useState();
	const [humiliationFaceChecked, sethumiliationFaceChecked] = useState(false);

	const q10humiliationFace = (e) => {
		const value = e.target.value;

		if (!humiliationFaceChecked) {
			sethumiliationFace(value);
			sethumiliationFaceChecked(true);
			setfilterChecked(true);
		} else {
			sethumiliationFace();
			sethumiliationFaceChecked(false);
		}
	};

	const [attaqueVisant, setattaqueVisant] = useState();
	const [attaqueVisantChecked, setattaqueVisantChecked] = useState(false);

	const q10attaqueVisant = (e) => {
		const value = e.target.value;

		if (!attaqueVisantChecked) {
			setattaqueVisant(value);
			setattaqueVisantChecked(true);
			setfilterChecked(true);
		} else {
			setattaqueVisant();
			setattaqueVisantChecked(false);
		}
	};
	const [isolementDeLaFamille, setisolementDeLaFamille] = useState();
	const [isolementDeLaFamilleChecked, setisolementDeLaFamilleChecked] =
		useState(false);

	const q10isolementDeLaFamille = (e) => {
		const value = e.target.value;

		if (!isolementDeLaFamilleChecked) {
			setisolementDeLaFamille(value);
			setisolementDeLaFamilleChecked(true);
			setfilterChecked(true);
		} else {
			setisolementDeLaFamille();
			setisolementDeLaFamilleChecked(false);
		}
	};
	const [controleDeMouvement, setcontroleDeMouvement] = useState();
	const [controleDeMouvementChecked, setcontroleDeMouvementChecked] =
		useState(false);

	const q10controleDeMouvement = (e) => {
		const value = e.target.value;

		if (!controleDeMouvementChecked) {
			setcontroleDeMouvement(value);
			setcontroleDeMouvementChecked(true);
			setfilterChecked(true);
		} else {
			setcontroleDeMouvement();
			setcontroleDeMouvementChecked(false);
		}
	};

	const [controleDeLaCommunication, setcontroleDeLaCommunication] = useState();
	const [
		controleDeLaCommunicationChecked,
		setcontroleDeLaCommunicationChecked,
	] = useState(false);

	const q10controleDeLaCommunication = (e) => {
		const value = e.target.value;

		if (!controleDeLaCommunicationChecked) {
			setcontroleDeLaCommunication(value);
			setcontroleDeLaCommunicationChecked(true);
			setfilterChecked(true);
		} else {
			setcontroleDeLaCommunication();
			setcontroleDeLaCommunicationChecked(false);
		}
	};

	const [refusDeDivorce, setrefusDeDivorce] = useState();
	const [refusDeDivorceChecked, setrefusDeDivorceChecked] = useState(false);

	const q10refusDeDivorce = (e) => {
		const value = e.target.value;

		if (!refusDeDivorceChecked) {
			setrefusDeDivorce(value);
			setrefusDeDivorceChecked(true);
			setfilterChecked(true);
		} else {
			setrefusDeDivorce();
			setrefusDeDivorceChecked(false);
		}
	};
	const [abusSpirituel, setabusSpirituel] = useState();
	const [abusSpirituelChecked, setabusSpirituelChecked] = useState(false);

	const q10abusSpirituel = (e) => {
		const value = e.target.value;

		if (!abusSpirituelChecked) {
			setabusSpirituel(value);
			setabusSpirituelChecked(true);
			setfilterChecked(true);
		} else {
			setabusSpirituel();
			setabusSpirituelChecked(false);
		}
	};
	const [autreViolPsychologique, setautreViolPsychologique] = useState();
	const [autreViolPsychologiqueChecked, setautreViolPsychologiqueChecked] =
		useState(false);

	const q10autreViolPsychologique = (e) => {
		const value = e.target.value;

		if (!autreViolPsychologiqueChecked) {
			setautreViolPsychologique(value);
			setautreViolPsychologiqueChecked(true);
			setfilterChecked(true);
		} else {
			setautreViolPsychologique();
			setautreViolPsychologiqueChecked(false);
		}
	};
	//
	//incident de violence economique
	const [abondonDeLaFamille, setabondonDeLaFamille] = useState();
	const [abondonDeLaFamilleChecked, setabondonDeLaFamilleChecked] =
		useState(false);

	const q10abondonDeLaFamille = (e) => {
		const value = e.target.value;

		if (!abondonDeLaFamilleChecked) {
			setabondonDeLaFamille(value);
			setabondonDeLaFamilleChecked(true);
			setfilterChecked(true);
		} else {
			setabondonDeLaFamille();
			setabondonDeLaFamilleChecked(false);
		}
	};
	const [confiscationDeRevenue, setconfiscationDeRevenue] = useState();
	const [confiscationDeRevenueChecked, setconfiscationDeRevenueChecked] =
		useState(false);

	const q10confiscationDeRevenue = (e) => {
		const value = e.target.value;

		if (!confiscationDeRevenueChecked) {
			setconfiscationDeRevenue(value);
			setconfiscationDeRevenueChecked(true);
			setfilterChecked(true);
		} else {
			setconfiscationDeRevenue();
			setconfiscationDeRevenueChecked(false);
		}
	};
	const [interdictionDeParticipation, setinterdictionDeParticipation] =
		useState();
	const [
		interdictionDeParticipationChecked,
		setinterdictionDeParticipationChecked,
	] = useState(false);

	const q10interdictionDeParticipation = (e) => {
		const value = e.target.value;

		if (!interdictionDeParticipationChecked) {
			setinterdictionDeParticipation(value);
			setinterdictionDeParticipationChecked(true);
			setfilterChecked(true);
		} else {
			setinterdictionDeParticipation();
			setinterdictionDeParticipationChecked(false);
		}
	};
	const [restrictionDeLAccessDEducation, setrestrictionDeLAccessDEducation] =
		useState();
	const [
		restrictionDeLAccessDEducationChecked,
		setrestrictionDeLAccessDEducationChecked,
	] = useState(false);

	const q10restrictionDeLAccessDEducation = (e) => {
		const value = e.target.value;

		if (!restrictionDeLAccessDEducationChecked) {
			setrestrictionDeLAccessDEducation(value);
			setrestrictionDeLAccessDEducationChecked(true);
			setfilterChecked(true);
		} else {
			setrestrictionDeLAccessDEducation();
			setrestrictionDeLAccessDEducationChecked(false);
		}
	};
	const [restrictionDeLaccessDeLemploi, setrestrictionDeLaccessDeLemploi] =
		useState();
	const [
		restrictionDeLaccessDeLemploiChecked,
		setrestrictionDeLaccessDeLemploiChecked,
	] = useState(false);

	const q10restrictionDeLaccessDeLemploi = (e) => {
		const value = e.target.value;

		if (!restrictionDeLaccessDeLemploiChecked) {
			setrestrictionDeLaccessDeLemploi(value);
			setrestrictionDeLaccessDeLemploiChecked(true);
			setfilterChecked(true);
		} else {
			setrestrictionDeLaccessDeLemploi();
			setrestrictionDeLaccessDeLemploiChecked(false);
		}
	};
	const [
		restrictionDeLAccessAuxRessource,
		setrestrictionDeLAccessAuxRessource,
	] = useState();
	const [
		restrictionDeLAccessAuxRessourceChecked,
		setrestrictionDeLAccessAuxRessourceChecked,
	] = useState(false);

	const q10restrictionDeLAccessAuxRessource = (e) => {
		const value = e.target.value;

		if (!restrictionDeLAccessAuxRessourceChecked) {
			setrestrictionDeLAccessAuxRessource(value);
			setrestrictionDeLAccessAuxRessourceChecked(true);
			setfilterChecked(true);
		} else {
			setrestrictionDeLAccessAuxRessource();
			setrestrictionDeLAccessAuxRessourceChecked(false);
		}
	};
	const [privationDePriseChange, setprivationDePriseChange] = useState();
	const [privationDePriseChangeChecked, setprivationDePriseChangeChecked] =
		useState(false);

	const q10privationDePriseChange = (e) => {
		const value = e.target.value;

		if (!privationDePriseChangeChecked) {
			setprivationDePriseChange(value);
			setprivationDePriseChangeChecked(true);
			setfilterChecked(true);
		} else {
			setprivationDePriseChange();
			setprivationDePriseChangeChecked(false);
		}
	};
	const [levirat, setlevirat] = useState();
	const [leviratChecked, setleviratChecked] = useState(false);

	const q10levirat = (e) => {
		const value = e.target.value;

		if (!leviratChecked) {
			setlevirat(value);
			setleviratChecked(true);
			setfilterChecked(true);
		} else {
			setlevirat();
			setleviratChecked(false);
		}
	};
	const [perterDePropriete, setperterDePropriete] = useState();
	const [perterDeProprieteChecked, setperterDeProprieteChecked] =
		useState(false);

	const q10perterDePropriete = (e) => {
		const value = e.target.value;

		if (!perterDeProprieteChecked) {
			setperterDePropriete(value);
			setperterDeProprieteChecked(true);
			setfilterChecked(true);
		} else {
			setperterDePropriete();
			setperterDeProprieteChecked(false);
		}
	};
	const [perterDeLenfant, setperterDeLenfant] = useState();
	const [perterDeLenfantChecked, setperterDeLenfantChecked] = useState(false);

	const q10perterDeLenfant = (e) => {
		const value = e.target.value;

		if (!perterDeLenfantChecked) {
			setperterDeLenfant(value);
			setperterDeLenfantChecked(true);
			setfilterChecked(true);
		} else {
			setperterDeLenfant();
			setperterDeLenfantChecked(false);
		}
	};
	const [autreViolEconomique, setautreViolEconomique] = useState();
	const [autreViolEconomiqueChecked, setautreViolEconomiqueChecked] =
		useState(false);

	const q10autreViolEconomique = (e) => {
		const value = e.target.value;

		if (!autreViolEconomiqueChecked) {
			setautreViolEconomique(value);
			setautreViolEconomiqueChecked(true);
			setfilterChecked(true);
		} else {
			setautreViolEconomique();
			setautreViolEconomiqueChecked(false);
		}
	};
	//q12
	// kinshasa

	const [q12banda, setq12banda] = useState();
	const [q12bandachecked, setq12bandachecked] = useState(false);

	const handleq12banda = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12bandachecked) {
			setq12banda(value);
			setq12bandachecked(true);
			setfilterChecked(true);
		} else {
			setq12banda();
			setq12bandachecked(false);
		}
	};

	const [q12barumbu, setq12barumbu] = useState();
	const [q12barumbuchecked, setq12barumbuchecked] = useState(false);

	const handleq12barumbus = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12barumbuchecked) {
			setq12barumbu(value);
			setq12barumbuchecked(true);
			setfilterChecked(true);
		} else {
			setq12barumbu();
			setq12barumbuchecked(false);
		}
	};
	const [q12bumbu, setq12bumbu] = useState();
	const [q12bumbuchecked, setq12bumbuchecked] = useState(false);

	const handleq12bumbu = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12bumbuchecked) {
			setq12bumbu(value);
			setq12bumbuchecked(true);
			setfilterChecked(true);
		} else {
			setq12bumbu();
			setq12bumbuchecked(false);
		}
	};

	const [q12Gombe, setq12Gombe] = useState();
	const [q12Gombechecked, setq12Gombechecked] = useState(false);

	const handleq12Gombe = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12Gombechecked) {
			setq12Gombe(value);
			setq12Gombechecked(true);
			setfilterChecked(true);
		} else {
			setq12Gombe();
			setq12Gombechecked(false);
		}
	};
	const [q12Kalamu, setq12Kalamu] = useState();
	const [q12Kalamuchecked, setq12Kalamuchecked] = useState(false);

	const handleq12Kalamu = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12Kalamuchecked) {
			setq12Kalamu(value);
			setq12Kalamuchecked(true);
			setfilterChecked(true);
		} else {
			setq12Kalamu();
			setq12Kalamuchecked(false);
		}
	};
	const [q12kasa, setq12kasa] = useState();
	const [q12kasachecked, setq12kasachecked] = useState(false);

	const handleq12kasa = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12kasachecked) {
			setq12kasa(value);
			setq12kasachecked(true);
			setfilterChecked(true);
		} else {
			setq12kasa();
			setq12kasachecked(false);
		}
	};
	const [q12Kimba, setq12Kimba] = useState();
	const [q12Kimbachecked, setq12Kimbachecked] = useState(false);

	const handleq12Kimba = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12Kimbachecked) {
			setq12Kimba(value);
			setq12Kimbachecked(true);
			setfilterChecked(true);
		} else {
			setq12Kimba();
			setq12Kimbachecked(false);
		}
	};
	const [q12kinshasa, setq12kinshasa] = useState();
	const [q12kinshasachecked, setq12kinshasachecked] = useState(false);

	const handleq12kinshasa = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12kinshasachecked) {
			setq12kinshasa(value);
			setq12kinshasachecked(true);
			setfilterChecked(true);
		} else {
			setq12kinshasa();
			setq12kinshasachecked(false);
		}
	};
	const [q12kintambo, setq12kintambo] = useState();
	const [q12kintambochecked, setq12kintambochecked] = useState(false);

	const handleq12kintambo = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12kintambochecked) {
			setq12kintambo(value);
			setq12kintambochecked(true);
			setfilterChecked(true);
		} else {
			setq12kintambo();
			setq12kintambochecked(false);
		}
	};
	const [q12kisenso, setq12kisenso] = useState();
	const [q12kisensochecked, setq12kisensochecked] = useState(false);

	const handleq12kisenso = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12kisensochecked) {
			setq12kisenso(value);
			setq12kisensochecked(true);
			setfilterChecked(true);
		} else {
			setq12kisenso();
			setq12kisensochecked(false);
		}
	};
	const [q12lemba, setq12lemba] = useState();
	const [q12lembachecked, setq12lembachecked] = useState(false);

	const handleq12lemba = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12lembachecked) {
			setq12lemba(value);
			setq12lembachecked(true);
			setfilterChecked(true);
		} else {
			setq12lemba();
			setq12lembachecked(false);
		}
	};
	const [q12limete, setq12limete] = useState();
	const [q12limetechecked, setq12limetechecked] = useState(false);

	const handleq12limete = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12limetechecked) {
			setq12limete(value);
			setq12limetechecked(true);
			setfilterChecked(true);
		} else {
			setq12limete();
			setq12limetechecked(false);
		}
	};
	const [q12Lingwala, setq12Lingwala] = useState();
	const [q12Lingwalachecked, setq12Lingwalachecked] = useState(false);

	const handleq12Lingwala = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12Lingwalachecked) {
			setq12Lingwala(value);
			setq12Lingwalachecked(true);
			setfilterChecked(true);
		} else {
			setq12Lingwala();
			setq12Lingwalachecked(false);
		}
	};
	const [q12Makala, setq12Makala] = useState();
	const [q12Makalachecked, setq12Makalachecked] = useState(false);

	const handleq12Makala = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12Makalachecked) {
			setq12Makala(value);
			setq12Makalachecked(true);
			setfilterChecked(true);
		} else {
			setq12Makala();
			setq12Makalachecked(false);
		}
	};
	const [q12Maluku, setq12Maluku] = useState();
	const [q12Malukuchecked, setq12Malukuchecked] = useState(false);

	const handleq12Maluku = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12Malukuchecked) {
			setq12Maluku(value);
			setq12Malukuchecked(true);
			setfilterChecked(true);
		} else {
			setq12Maluku();
			setq12Malukuchecked(false);
		}
	};
	const [q12masina, setq12masina] = useState();
	const [q12masinachecked, setq12masinachecked] = useState(false);

	const handleq12masina = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12masinachecked) {
			setq12masina(value);
			setq12masinachecked(true);
			setfilterChecked(true);
		} else {
			setq12masina();
			setq12masinachecked(false);
		}
	};
	const [q12matete, setq12matete] = useState();
	const [q12matetechecked, setq12matetechecked] = useState(false);

	const handleq12matete = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12matetechecked) {
			setq12matete(value);
			setq12matetechecked(true);
			setfilterChecked(true);
		} else {
			setq12matete();
			setq12matetechecked(false);
		}
	};
	const [q12montNgafula, setq12montNgafula] = useState();
	const [q12montNgafulachecked, setq12montNgafulachecked] = useState(false);

	const handleq12montNgafula = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12montNgafulachecked) {
			setq12montNgafula(value);
			setq12montNgafulachecked(true);
			setfilterChecked(true);
		} else {
			setq12montNgafula();
			setq12montNgafulachecked(false);
		}
	};
	const [q12ndjili, setq12ndjili] = useState();
	const [q12ndjilichecked, setq12ndjilichecked] = useState(false);

	const handleq12ndjili = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12ndjilichecked) {
			setq12ndjili(value);
			setq12ndjilichecked(true);
			setfilterChecked(true);
		} else {
			setq12ndjili();
			setq12ndjilichecked(false);
		}
	};
	const [q12ngaba, setq12ngaba] = useState();
	const [q12ngabachecked, setq12ngabachecked] = useState(false);

	const handleq12ngaba = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12ngabachecked) {
			setq12ngaba(value);
			setq12ngabachecked(true);
			setfilterChecked(true);
		} else {
			setq12ngaba();
			setq12ngabachecked(false);
		}
	};
	const [q12ngaliema, setq12ngaliema] = useState();
	const [q12ngaliemachecked, setq12ngaliemachecked] = useState(false);

	const handleq12ngaliema = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12ngaliemachecked) {
			setq12ngaliema(value);
			setq12ngaliemachecked(true);
			setfilterChecked(true);
		} else {
			setq12ngaliema();
			setq12ngaliemachecked(false);
		}
	};

	const [q12ngiri, setq12ngiri] = useState();
	const [q12ngirichecked, setq12ngirichecked] = useState(false);

	const handleq12ngiri = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12ngirichecked) {
			setq12ngiri(value);
			setq12ngirichecked(true);
			setfilterChecked(true);
		} else {
			setq12ngiri();
			setq12ngirichecked(false);
		}
	};
	const [q12nsele, setq12nsele] = useState();
	const [q12nselechecked, setq12nselechecked] = useState(false);

	const handleq12nsele = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12nselechecked) {
			setq12nsele(value);
			setq12nselechecked(true);
			setfilterChecked(true);
		} else {
			setq12nsele();
			setq12nselechecked(false);
		}
	};
	const [q12selembao, setq12selembao] = useState();
	const [q12selembaochecked, setq12selembaochecked] = useState(false);

	const handleq12selembao = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12selembaochecked) {
			setq12selembao(value);
			setq12selembaochecked(true);
			setfilterChecked(true);
		} else {
			setq12selembao();
			setq12selembaochecked(false);
		}
	};
	//Ituri
	const [q12bunia, setq12bunia] = useState();
	const [q12buniachecked, setq12buniachecked] = useState(false);

	const handleq12bunia = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12buniachecked) {
			setq12bunia(value);
			setq12buniachecked(true);
			setfilterChecked(true);
		} else {
			setq12bunia();
			setq12buniachecked(false);
		}
	};
	const [q12aru, setq12aru] = useState();
	const [q12aruchecked, setq12aruchecked] = useState(false);

	const handleq12aru = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12aruchecked) {
			setq12aru(value);
			setq12aruchecked(true);
			setfilterChecked(true);
		} else {
			setq12aru();
			setq12aruchecked(false);
		}
	};
	const [q12djugu, setq12djugu] = useState();
	const [q12djuguchecked, setq12djuguchecked] = useState(false);

	const handleq12djugu = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12djuguchecked) {
			setq12djugu(value);
			setq12djuguchecked(true);
			setfilterChecked(true);
		} else {
			setq12djugu();
			setq12djuguchecked(false);
		}
	};

	const [q12irumu, setq12irumu] = useState();
	const [q12irumuchecked, setq12irumuchecked] = useState(false);

	const handleq12irumu = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12irumuchecked) {
			setq12irumu(value);
			setq12irumuchecked(true);
			setfilterChecked(true);
		} else {
			setq12irumu();
			setq12irumuchecked(false);
		}
	};
	const [q12mahagi, setq12mahagi] = useState();
	const [q12mahagichecked, setq12mahagichecked] = useState(false);

	const handleq12mahagi = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12mahagichecked) {
			setq12mahagi(value);
			setq12mahagichecked(true);
			setfilterChecked(true);
		} else {
			setq12mahagi();
			setq12mahagichecked(false);
		}
	};
	const [q12mambasa, setq12mambasa] = useState();
	const [q12mambasachecked, setq12mambasachecked] = useState(false);

	const handleq12mambasa = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12mambasachecked) {
			setq12mambasa(value);
			setq12mambasachecked(true);
			setfilterChecked(true);
		} else {
			setq12mambasa();
			setq12mambasachecked(false);
		}
	};
	// kasai-central
	const [q12kananga, setq12kananga] = useState();
	const [q12kanangachecked, setq12kanangachecked] = useState(false);

	const handleq12kananga = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12kanangachecked) {
			setq12kananga(value);
			setq12kanangachecked(true);
			setfilterChecked(true);
		} else {
			setq12kananga();
			setq12kanangachecked(false);
		}
	};
	const [q12demba, setq12demba] = useState();
	const [q12dembachecked, setq12dembachecked] = useState(false);

	const handleq12demba = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12dembachecked) {
			setq12demba(value);
			setq12dembachecked(true);
			setfilterChecked(true);
		} else {
			setq12demba();
			setq12dembachecked(false);
		}
	};
	const [q12dibaya, setq12dibaya] = useState();
	const [q12dibayachecked, setq12dibayachecked] = useState(false);

	const handleq12dibaya = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12dibayachecked) {
			setq12dibaya(value);
			setq12dibayachecked(true);
			setfilterChecked(true);
		} else {
			setq12dibaya();
			setq12dibayachecked(false);
		}
	};
	const [q12dimbelenge, setq12dimbelenge] = useState();
	const [q12dimbelengechecked, setq12dimbelengechecked] = useState(false);

	const handleq12dimbelenge = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12dimbelengechecked) {
			setq12dimbelenge(value);
			setq12dimbelengechecked(true);
			setfilterChecked(true);
		} else {
			setq12dimbelenge();
			setq12dimbelengechecked(false);
		}
	};
	const [q12kazumba, setq12kazumba] = useState();
	const [q12kazumbachecked, setq12kazumbachecked] = useState(false);

	const handleq12kazumba = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12kazumbachecked) {
			setq12kazumba(value);
			setq12kazumbachecked(true);
			setfilterChecked(true);
		} else {
			setq12kazumba();
			setq12kazumbachecked(false);
		}
	};

	const [q12luiza, setq12luiza] = useState();
	const [q12luizachecked, setq12luizachecked] = useState(false);

	const handleq12luiza = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12luizachecked) {
			setq12luiza(value);
			setq12luizachecked(true);
			setfilterChecked(true);
		} else {
			setq12luiza();
			setq12luizachecked(false);
		}
	};

	//kasai-oriental
	const [q12mbuji, setq12mbuji] = useState();
	const [q12mbujichecked, setq12mbujichecked] = useState(false);

	const handleq12mbuji = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12mbujichecked) {
			setq12mbuji(value);
			setq12mbujichecked(true);
			setfilterChecked(true);
		} else {
			setq12mbuji();
			setq12mbujichecked(false);
		}
	};
	const [q12kabeya, setq12kabeya] = useState();
	const [q12kabeyachecked, setq12kabeyachecked] = useState(false);

	const handleq12kabeya = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12kabeyachecked) {
			setq12kabeya(value);
			setq12kabeyachecked(true);
			setfilterChecked(true);
		} else {
			setq12kabeya();
			setq12kabeyachecked(false);
		}
	};
	const [q12katanda, setq12katanda] = useState();
	const [q12katandachecked, setq12katandachecked] = useState(false);

	const handleq12katanda = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12katandachecked) {
			setq12katanda(value);
			setq12katandachecked(true);
			setfilterChecked(true);
		} else {
			setq12katanda();
			setq12katandachecked(false);
		}
	};
	const [q12lupatapata, setq12lupatapata] = useState();
	const [q12lupatapatachecked, setq12lupatapatachecked] = useState(false);

	const handleq12lupatapata = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12lupatapatachecked) {
			setq12lupatapata(value);
			setq12lupatapatachecked(true);
			setfilterChecked(true);
		} else {
			setq12lupatapata();
			setq12lupatapatachecked(false);
		}
	};
	const [q12miabi, setq12miabi] = useState();
	const [q12miabichecked, setq12miabichecked] = useState(false);

	const handleq12miabi = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12miabichecked) {
			setq12miabi(value);
			setq12miabichecked(true);
			setfilterChecked(true);
		} else {
			setq12miabi();
			setq12miabichecked(false);
		}
	};
	const [q12tshilenge, setq12tshilenge] = useState();
	const [q12tshilengechecked, setq12tshilengechecked] = useState(false);

	const handleq12tshilenge = (e) => {
		const value = e.target.value;
		//console.log(value);
		if (!q12tshilengechecked) {
			setq12tshilenge(value);
			setq12tshilengechecked(true);
			setfilterChecked(true);
		} else {
			setq12tshilenge();
			setq12tshilengechecked(false);
		}
	};
	//maniema
	const [q12kindu, setq12kindu] = useState();
	const [q12kinduchecked, setq12kinduchecked] = useState(false);

	const handleq12kindu = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12kinduchecked) {
			setq12kindu(value);
			setq12kinduchecked(true);
			setfilterChecked(true);
		} else {
			setq12kindu();
			setq12kinduchecked(false);
		}
	};
	const [q12kambare, setq12kambare] = useState();
	const [q12kambarechecked, setq12kambarechecked] = useState(false);

	const handleq12kambare = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12kambarechecked) {
			setq12kambare(value);
			setq12kambarechecked(true);
			setfilterChecked(true);
		} else {
			setq12kambare();
			setq12kambarechecked(false);
		}
	};

	const [q12kailo, setq12kailo] = useState();
	const [q12kailochecked, setq12kailochecked] = useState(false);

	const handleq12kailo = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12kailochecked) {
			setq12kailo(value);
			setq12kailochecked(true);
			setfilterChecked(true);
		} else {
			setq12kailo();
			setq12kailochecked(false);
		}
	};
	const [q12kasongo, setq12kasongo] = useState();
	const [q12kasongochecked, setq12kasongochecked] = useState(false);

	const handleq12kasongo = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12kasongochecked) {
			setq12kasongo(value);
			setq12kasongochecked(true);
			setfilterChecked(true);
		} else {
			setq12kasongo();
			setq12kasongochecked(false);
		}
	};
	const [q12kibombo, setq12kibombo] = useState();
	const [q12kibombochecked, setq12kibombochecked] = useState(false);

	const handleq12kibombo = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12kibombochecked) {
			setq12kibombo(value);
			setq12kibombochecked(true);
			setfilterChecked(true);
		} else {
			setq12kibombo();
			setq12kibombochecked(false);
		}
	};
	const [q12lubutu, setq12lubutu] = useState();
	const [q12lubutuchecked, setq12lubutuchecked] = useState(false);

	const handleq12lubutu = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12lubutuchecked) {
			setq12lubutu(value);
			setq12lubutuchecked(true);
			setfilterChecked(true);
		} else {
			setq12lubutu();
			setq12lubutuchecked(false);
		}
	};
	const [q12pangi, setq12pangi] = useState();
	const [q12pangichecked, setq12pangichecked] = useState(false);

	const handleq12pangi = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12pangichecked) {
			setq12pangi(value);
			setq12pangichecked(true);
			setfilterChecked(true);
		} else {
			setq12pangi();
			setq12pangichecked(false);
		}
	};

	const [q12punia, setq12punia] = useState();
	const [q12puniachecked, setq12puniachecked] = useState(false);

	const handleq12punia = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12puniachecked) {
			setq12punia(value);
			setq12puniachecked(true);
			setfilterChecked(true);
		} else {
			setq12punia();
			setq12puniachecked(false);
		}
	};

	//nord-kivu
	const [q12benivile, setq12benivile] = useState();
	const [q12benivilechecked, setq12benivilechecked] = useState(false);

	const handleq12benivile = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12benivilechecked) {
			setq12benivile(value);
			setq12benivilechecked(true);
			setfilterChecked(true);
		} else {
			setq12benivile();
			setq12benivilechecked(false);
		}
	};

	const [q12butembo, setq12butembo] = useState();
	const [q12butembochecked, setq12butembochecked] = useState(false);

	const handleq12butembo = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12butembochecked) {
			setq12butembo(value);
			setq12butembochecked(true);
			setfilterChecked(true);
		} else {
			setq12butembo();
			setq12butembochecked(false);
		}
	};

	const [q12goma, setq12goma] = useState();
	const [q12gomachecked, setq12gomachecked] = useState(false);

	const handleq12goma = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12gomachecked) {
			setq12goma(value);
			setq12gomachecked(true);
			setfilterChecked(true);
		} else {
			setq12goma();
			setq12gomachecked(false);
		}
	};
	const [q12beni, setq12beni] = useState();
	const [q12benichecked, setq12benichecked] = useState(false);

	const handleq12beni = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12benichecked) {
			setq12beni(value);
			setq12benichecked(true);
			setfilterChecked(true);
		} else {
			setq12beni();
			setq12benichecked(false);
		}
	};
	const [q12lubero, setq12lubero] = useState();
	const [q12luberochecked, setq12luberochecked] = useState(false);

	const handleq12lubero = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12luberochecked) {
			setq12lubero(value);
			setq12luberochecked(true);
			setfilterChecked(true);
		} else {
			setq12lubero();
			setq12luberochecked(false);
		}
	};
	const [q12masisi, setq12masisi] = useState();
	const [q12masisichecked, setq12masisichecked] = useState(false);

	const handleq12masisi = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12masisichecked) {
			setq12masisi(value);
			setq12masisichecked(true);
			setfilterChecked(true);
		} else {
			setq12masisi();
			setq12masisichecked(false);
		}
	};

	const [q12nyiragongo, setq12nyiragongo] = useState();
	const [q12nyiragongochecked, setq12nyiragongochecked] = useState(false);

	const handleq12nyiragongo = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12nyiragongochecked) {
			setq12nyiragongo(value);
			setq12nyiragongochecked(true);
			setfilterChecked(true);
		} else {
			setq12nyiragongo();
			setq12nyiragongochecked(false);
		}
	};
	const [q12rutshuru, setq12rutshuru] = useState();
	const [q12rutshuruchecked, setq12rutshuruchecked] = useState(false);

	const handleq12rutshuru = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12rutshuruchecked) {
			setq12rutshuru(value);
			setq12rutshuruchecked(true);
			setfilterChecked(true);
		} else {
			setq12rutshuru();
			setq12rutshuruchecked(false);
		}
	};
	const [q12walikale, setq12walikale] = useState();
	const [q12walikalechecked, setq12walikalechecked] = useState(false);

	const handleq12walikale = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12walikalechecked) {
			setq12walikale(value);
			setq12walikalechecked(true);
			setfilterChecked(true);
		} else {
			setq12walikale();
			setq12walikalechecked(false);
		}
	};
	//sud-kivu
	const [q12bukavu, setq12bukavu] = useState();
	const [q12bukavuchecked, setq12bukavuchecked] = useState(false);

	const handleq12bukavu = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12bukavuchecked) {
			setq12bukavu(value);
			setq12bukavuchecked(true);
			setfilterChecked(true);
		} else {
			setq12bukavu();
			setq12bukavuchecked(false);
		}
	};
	const [q12fizi, setq12fizi] = useState();
	const [q12fizichecked, setq12fizichecked] = useState(false);

	const handleq12fizi = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12fizichecked) {
			setq12fizi(value);
			setq12fizichecked(true);
			setfilterChecked(true);
		} else {
			setq12fizi();
			setq12fizichecked(false);
		}
	};
	const [q12idjwi, setq12idjwi] = useState();
	const [q12idjwichecked, setq12idjwichecked] = useState(false);

	const handleq12idjwi = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12idjwichecked) {
			setq12idjwi(value);
			setq12idjwichecked(true);
			setfilterChecked(true);
		} else {
			setq12idjwi();
			setq12idjwichecked(false);
		}
	};
	const [q12kabare, setq12kabare] = useState();
	const [q12kabarechecked, setq12kabarechecked] = useState(false);

	const handleq12kabare = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12kabarechecked) {
			setq12kabare(value);
			setq12kabarechecked(true);
			setfilterChecked(true);
		} else {
			setq12kabare();
			setq12kabarechecked(false);
		}
	};
	const [q12kalehe, setq12kalehe] = useState();
	const [q12kalehechecked, setq12kalehechecked] = useState(false);

	const handleq12kalehe = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12kalehechecked) {
			setq12kalehe(value);
			setq12kalehechecked(true);
			setfilterChecked(true);
		} else {
			setq12kalehe();
			setq12kalehechecked(false);
		}
	};

	const [q12mwenga, setq12mwenga] = useState();
	const [q12mwengachecked, setq12mwengachecked] = useState(false);

	const handleq12mwenga = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12mwengachecked) {
			setq12mwenga(value);
			setq12mwengachecked(true);
			setfilterChecked(true);
		} else {
			setq12mwenga();
			setq12mwengachecked(false);
		}
	};
	const [q12shabunda, setq12shabunda] = useState();
	const [q12shabundachecked, setq12shabundachecked] = useState(false);

	const handleq12shabunda = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12shabundachecked) {
			setq12shabunda(value);
			setq12shabundachecked(true);
			setfilterChecked(true);
		} else {
			setq12shabunda();
			setq12shabundachecked(false);
		}
	};
	const [q12uvira, setq12uvira] = useState();
	const [q12uvirachecked, setq12uvirachecked] = useState(false);

	const handleq12uvira = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12uvirachecked) {
			setq12uvira(value);
			setq12uvirachecked(true);
			setfilterChecked(true);
		} else {
			setq12uvira();
			setq12uvirachecked(false);
		}
	};

	const [q12walungu, setq12walungu] = useState();
	const [q12walunguchecked, setq12walunguchecked] = useState(false);

	const handleq12walungu = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12walunguchecked) {
			setq12walungu(value);
			setq12walunguchecked(true);
			setfilterChecked(true);
		} else {
			setq12walungu();
			setq12walunguchecked(false);
		}
	};
	//tshopo
	const [q12kisangani, setq12kisangani] = useState();
	const [q12kisanganichecked, setq12kisanganichecked] = useState(false);

	const handleq12kisangani = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12kisanganichecked) {
			setq12kisangani(value);
			setq12kisanganichecked(true);
			setfilterChecked(true);
		} else {
			setq12kisangani();
			setq12kisanganichecked(false);
		}
	};

	const [q12bafwasende, setq12bafwasende] = useState();
	const [q12bafwasendechecked, setq12bafwasendechecked] = useState(false);

	const handleq12bafwasende = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12bafwasendechecked) {
			setq12bafwasende(value);
			setq12bafwasendechecked(true);
			setfilterChecked(true);
		} else {
			setq12bafwasende();
			setq12bafwasendechecked(false);
		}
	};

	const [q12banalia, setq12banalia] = useState();
	const [q12banaliachecked, setq12banaliachecked] = useState(false);

	const handleq12banalia = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12banaliachecked) {
			setq12banalia(value);
			setq12banaliachecked(true);
			setfilterChecked(true);
		} else {
			setq12banalia();
			setq12banaliachecked(false);
		}
	};

	const [q12basoko, setq12basoko] = useState();
	const [q12basokochecked, setq12basokochecked] = useState(false);

	const handleq12basoko = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12basokochecked) {
			setq12basoko(value);
			setq12basokochecked(true);
			setfilterChecked(true);
		} else {
			setq12basoko();
			setq12basokochecked(false);
		}
	};

	const [q12isangi, setq12isangi] = useState();
	const [q12isangichecked, setq12isangichecked] = useState(false);

	const handleq12isangi = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12isangichecked) {
			setq12isangi(value);
			setq12isangichecked(true);
			setfilterChecked(true);
		} else {
			setq12isangi();
			setq12isangichecked(false);
		}
	};

	const [q12opala, setq12opala] = useState();
	const [q12opalachecked, setq12opalachecked] = useState(false);

	const handleq12opala = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12opalachecked) {
			setq12opala(value);
			setq12opalachecked(true);
			setfilterChecked(true);
		} else {
			setq12opala();
			setq12opalachecked(false);
		}
	};
	const [q12ubundu, setq12ubundu] = useState();
	const [q12ubunduchecked, setq12ubunduchecked] = useState(false);

	const handleq12ubundu = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12ubunduchecked) {
			setq12ubundu(value);
			setq12ubunduchecked(true);
			setfilterChecked(true);
		} else {
			setq12ubundu();
			setq12ubunduchecked(false);
		}
	};

	const [q12yahuma, setq12yahuma] = useState();
	const [q12yahumachecked, setq12yahumachecked] = useState(false);

	const handleq12yahuma = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q12yahumachecked) {
			setq12yahuma(value);
			setq12yahumachecked(true);
			setfilterChecked(true);
		} else {
			setq12yahuma();
			setq12yahumachecked(false);
		}
	};
	//q13b

	//Prejudice physique
	const [deces, setdeces] = useState();
	const [decesChecked, setdecesChecked] = useState(false);

	const q13deces = (e) => {
		const value = e.target.value;

		if (!decesChecked) {
			setdeces(value);
			setdecesChecked(true);
			setfilterChecked(true);
		} else {
			setdeces();
			setdecesChecked(false);
		}
	};
	const [maladie, setmaladie] = useState();
	const [maladieChecked, setmaladieChecked] = useState(false);

	const q13maladie = (e) => {
		const value = e.target.value;

		if (!maladieChecked) {
			setmaladie(value);
			setmaladieChecked(true);
			setfilterChecked(true);
		} else {
			setmaladie();
			setmaladieChecked(false);
		}
	};

	const [blessure, setblessure] = useState();
	const [blessureChecked, setblessureChecked] = useState(false);

	const q13blessure = (e) => {
		const value = e.target.value;

		if (!blessureChecked) {
			setblessure(value);
			setblessureChecked(true);
			setfilterChecked(true);
		} else {
			setblessure();
			setblessureChecked(false);
		}
	};

	const [douleur, setdouleur] = useState();
	const [douleurChecked, setdouleurChecked] = useState(false);

	const q13douleur = (e) => {
		const value = e.target.value;

		if (!douleurChecked) {
			setdouleur(value);
			setdouleurChecked(true);
			setfilterChecked(true);
		} else {
			setdouleur();
			setdouleurChecked(false);
		}
	};
	const [troubleGyn, settroubleGyn] = useState();
	const [troubleGynChecked, settroubleGynChecked] = useState(false);

	const q13troubleGyn = (e) => {
		const value = e.target.value;

		if (!troubleGynChecked) {
			settroubleGyn(value);
			settroubleGynChecked(true);
			setfilterChecked(true);
		} else {
			settroubleGyn();
			settroubleGynChecked(false);
		}
	};
	const [handicap, sethandicap] = useState();
	const [handicapChecked, sethandicapChecked] = useState(false);

	const q13handicap = (e) => {
		const value = e.target.value;

		if (!handicapChecked) {
			sethandicap(value);
			sethandicapChecked(true);
			setfilterChecked(true);
		} else {
			sethandicap();
			sethandicapChecked(false);
		}
	};
	const [grossesse, setgrossesse] = useState();
	const [grossesseChecked, setgrossesseChecked] = useState(false);

	const q13grossesse = (e) => {
		const value = e.target.value;

		if (!grossesseChecked) {
			setgrossesse(value);
			setgrossesseChecked(true);
			setfilterChecked(true);
		} else {
			setgrossesse();
			setgrossesseChecked(false);
		}
	};
	const [perteDeLenfantANaitre, setperteDeLenfantANaitre] = useState();
	const [perteDeLenfantANaitreChecked, setperteDeLenfantANaitreChecked] =
		useState(false);

	const q13perteDeLenfantANaitre = (e) => {
		const value = e.target.value;

		if (!perteDeLenfantANaitreChecked) {
			setperteDeLenfantANaitre(value);
			setperteDeLenfantANaitreChecked(true);
			setfilterChecked(true);
		} else {
			setperteDeLenfantANaitre();
			setperteDeLenfantANaitreChecked(false);
		}
	};
	const [abusDAlcools, setabusDAlcools] = useState();
	const [abusDAlcoolsChecked, setabusDAlcoolsChecked] = useState(false);

	const q13abusDAlcools = (e) => {
		const value = e.target.value;

		if (!abusDAlcoolsChecked) {
			setabusDAlcools(value);
			setabusDAlcoolsChecked(true);
			setfilterChecked(true);
		} else {
			setabusDAlcools();
			setabusDAlcoolsChecked(false);
		}
	};
	const [abusDeDrogues, setabusDeDrogues] = useState();
	const [abusDeDroguesChecked, setabusDeDroguesChecked] = useState(false);

	const q13abusDeDrogues = (e) => {
		const value = e.target.value;

		if (!abusDeDroguesChecked) {
			setabusDeDrogues(value);
			setabusDeDroguesChecked(true);
			setfilterChecked(true);
		} else {
			setabusDeDrogues();
			setabusDeDroguesChecked(false);
		}
	};
	const [abusDeMedicaments, setabusDeMedicaments] = useState();
	const [abusDeMedicamentsChecked, setabusDeMedicamentsChecked] =
		useState(false);

	const q13abusDeMedicaments = (e) => {
		const value = e.target.value;

		if (!abusDeMedicamentsChecked) {
			setabusDeMedicaments(value);
			setabusDeMedicamentsChecked(true);
			setfilterChecked(true);
		} else {
			setabusDeMedicaments();
			setabusDeMedicamentsChecked(false);
		}
	};
	const [autrePrejudicePhysique, setautrePrejudicePhysique] = useState();
	const [autrePrejudicePhysiqueChecked, setautrePrejudicePhysiqueChecked] =
		useState(false);

	const q13autrePrejudicePhysique = (e) => {
		const value = e.target.value;

		if (!autrePrejudicePhysiqueChecked) {
			setautrePrejudicePhysique(value);
			setautrePrejudicePhysiqueChecked(true);
			setfilterChecked(true);
		} else {
			setautrePrejudicePhysique();
			setautrePrejudicePhysiqueChecked(false);
		}
	};

	//
	//Prejudice emotionel
	const [risqueDeSuicide, setrisqueDeSuicide] = useState();
	const [risqueDeSuicideChecked, setrisqueDeSuicideChecked] = useState(false);

	const q13risqueDeSuicide = (e) => {
		const value = e.target.value;

		if (!risqueDeSuicideChecked) {
			setrisqueDeSuicide(value);
			setrisqueDeSuicideChecked(true);
			setfilterChecked(true);
		} else {
			setrisqueDeSuicide();
			setrisqueDeSuicideChecked(false);
		}
	};
	const [peurEtAnxiete, setpeurEtAnxiete] = useState();
	const [peurEtAnxieteChecked, setpeurEtAnxieteChecked] = useState(false);

	const q13peurEtAnxiete = (e) => {
		const value = e.target.value;

		if (!peurEtAnxieteChecked) {
			setpeurEtAnxiete(value);
			setpeurEtAnxieteChecked(true);
			setfilterChecked(true);
		} else {
			setpeurEtAnxiete();
			setpeurEtAnxieteChecked(false);
		}
	};
	const [depression, setdepression] = useState();
	const [depressionChecked, setdepressionChecked] = useState(false);

	const q13depression = (e) => {
		const value = e.target.value;

		if (!depressionChecked) {
			setdepression(value);
			setdepressionChecked(true);
			setfilterChecked(true);
		} else {
			setdepression();
			setdepressionChecked(false);
		}
	};
	const [detresse, setdetresse] = useState();
	const [detresseChecked, setdetresseChecked] = useState(false);

	const q13detresse = (e) => {
		const value = e.target.value;

		if (!detresseChecked) {
			setdetresse(value);
			setdetresseChecked(true);
			setfilterChecked(true);
		} else {
			setdetresse();
			setdetresseChecked(false);
		}
	};
	const [sentimentDeHonte, setsentimentDeHonte] = useState();
	const [sentimentDeHonteChecked, setsentimentDeHonteChecked] = useState(false);

	const q13sentimentDeHonte = (e) => {
		const value = e.target.value;

		if (!sentimentDeHonteChecked) {
			setsentimentDeHonte(value);
			setsentimentDeHonteChecked(true);
			setfilterChecked(true);
		} else {
			setsentimentDeHonte();
			setsentimentDeHonteChecked(false);
		}
	};
	const [sentimentDeColere, setsentimentDeColere] = useState();
	const [sentimentDeColereChecked, setsentimentDeColereChecked] =
		useState(false);

	const q13sentimentDeColere = (e) => {
		const value = e.target.value;

		if (!sentimentDeColereChecked) {
			setsentimentDeColere(value);
			setsentimentDeColereChecked(true);
			setfilterChecked(true);
		} else {
			setsentimentDeColere();
			setsentimentDeColereChecked(false);
		}
	};
	const [troubleDeSommeil, settroubleDeSommeil] = useState();
	const [troubleDeSommeilChecked, settroubleDeSommeilChecked] = useState(false);

	const q13troubleDeSommeil = (e) => {
		const value = e.target.value;

		if (!troubleDeSommeilChecked) {
			settroubleDeSommeil(value);
			settroubleDeSommeilChecked(true);
			setfilterChecked(true);
		} else {
			settroubleDeSommeil();
			settroubleDeSommeilChecked(false);
		}
	};

	const [troubleAlimentaires, settroubleAlimentaires] = useState();
	const [troubleAlimentairesChecked, settroubleAlimentairesChecked] =
		useState(false);

	const q13troubleAlimentaires = (e) => {
		const value = e.target.value;

		if (!troubleAlimentairesChecked) {
			settroubleAlimentaires(value);
			settroubleAlimentairesChecked(true);
			setfilterChecked(true);
		} else {
			settroubleAlimentaires();
			settroubleAlimentairesChecked(false);
		}
	};
	const [peeteDEspoire, setpeeteDEspoire] = useState();
	const [peeteDEspoireChecked, setpeeteDEspoireChecked] = useState(false);

	const q13peeteDEspoire = (e) => {
		const value = e.target.value;

		if (!peeteDEspoireChecked) {
			setpeeteDEspoire(value);
			setpeeteDEspoireChecked(true);
			setfilterChecked(true);
		} else {
			setpeeteDEspoire();
			setpeeteDEspoireChecked(false);
		}
	};
	const [manqueDeConfiance, setmanqueDeConfiance] = useState();
	const [manqueDeConfianceChecked, setmanqueDeConfianceChecked] =
		useState(false);

	const q13manqueDeConfiance = (e) => {
		const value = e.target.value;

		if (!manqueDeConfianceChecked) {
			setmanqueDeConfiance(value);
			setmanqueDeConfianceChecked(true);
			setfilterChecked(true);
		} else {
			setmanqueDeConfiance();
			setmanqueDeConfianceChecked(false);
		}
	};
	const [autrePrejudiceEmotionnel, setautrePrejudiceEmotionnel] = useState();
	const [autrePrejudiceEmotionnelChecked, setautrePrejudiceEmotionnelChecked] =
		useState(false);

	const q13autrePrejudiceEmotionnel = (e) => {
		const value = e.target.value;

		if (!autrePrejudiceEmotionnelChecked) {
			setautrePrejudiceEmotionnel(value);
			setautrePrejudiceEmotionnelChecked(true);
			setfilterChecked(true);
		} else {
			setautrePrejudiceEmotionnel();
			setautrePrejudiceEmotionnelChecked(false);
		}
	};

	//Prejudice economique
	const [perterDeMoyens, setperterDeMoyens] = useState();
	const [perterDeMoyensChecked, setperterDeMoyensChecked] = useState(false);

	const q13perterDeMoyens = (e) => {
		const value = e.target.value;

		if (!perterDeMoyensChecked) {
			setperterDeMoyens(value);
			setperterDeMoyensChecked(true);
			setfilterChecked(true);
		} else {
			setperterDeMoyens();
			setperterDeMoyensChecked(false);
		}
	};
	const [perterDeRevenues, setperterDeRevenues] = useState();
	const [perterDeRevenuesChecked, setperterDeRevenuesChecked] = useState(false);

	const q13perterDeRevenues = (e) => {
		const value = e.target.value;

		if (!perterDeRevenuesChecked) {
			setperterDeRevenues(value);
			setperterDeRevenuesChecked(true);
			setfilterChecked(true);
		} else {
			setperterDeRevenues();
			setperterDeRevenuesChecked(false);
		}
	};
	const [sexeDeSurvie, setsexeDeSurvie] = useState();
	const [sexeDeSurvieChecked, setsexeDeSurvieChecked] = useState(false);

	const q13sexeDeSurvie = (e) => {
		const value = e.target.value;

		if (!sexeDeSurvieChecked) {
			setsexeDeSurvie(value);
			setsexeDeSurvieChecked(true);
			setfilterChecked(true);
		} else {
			setsexeDeSurvie();
			setsexeDeSurvieChecked(false);
		}
	};
	const [perteDeMaisons, setperteDeMaisons] = useState();
	const [perteDeMaisonsChecked, setperteDeMaisonsChecked] = useState(false);

	const q13perteDeMaisons = (e) => {
		const value = e.target.value;

		if (!perteDeMaisonsChecked) {
			setperteDeMaisons(value);
			setperteDeMaisonsChecked(true);
			setfilterChecked(true);
		} else {
			setperteDeMaisons();
			setperteDeMaisonsChecked(false);
		}
	};
	const [demanegementFrequent, setdemanegementFrequent] = useState();
	const [demanegementFrequentChecked, setdemanegementFrequentChecked] =
		useState(false);

	const q13demanegementFrequent = (e) => {
		const value = e.target.value;

		if (!demanegementFrequentChecked) {
			setdemanegementFrequent(value);
			setdemanegementFrequentChecked(true);
			setfilterChecked(true);
		} else {
			setdemanegementFrequent();
			setdemanegementFrequentChecked(false);
		}
	};
	const [situationDeSanSAbri, setsituationDeSanSAbri] = useState();
	const [situationDeSanSAbriChecked, setsituationDeSanSAbriChecked] =
		useState(false);

	const q13situationDeSanSAbri = (e) => {
		const value = e.target.value;

		if (!situationDeSanSAbriChecked) {
			setsituationDeSanSAbri(value);
			setsituationDeSanSAbriChecked(true);
			setfilterChecked(true);
		} else {
			setsituationDeSanSAbri();
			setsituationDeSanSAbriChecked(false);
		}
	};
	const [pasOuPeu, setpasOuPeu] = useState();
	const [pasOuPeuChecked, setpasOuPeuChecked] = useState(false);

	const q13pasOuPeu = (e) => {
		const value = e.target.value;

		if (!pasOuPeuChecked) {
			setpasOuPeu(value);
			setpasOuPeuChecked(true);
			setfilterChecked(true);
		} else {
			setpasOuPeu();
			setpasOuPeuChecked(false);
		}
	};
	const [autrePrejudiceEconomique, setautrePrejudiceEconomique] = useState();
	const [autrePrejudiceEconomiqueChecked, setautrePrejudiceEconomiqueChecked] =
		useState(false);

	const q13autrePrejudiceEconomique = (e) => {
		const value = e.target.value;

		if (!autrePrejudiceEconomiqueChecked) {
			setautrePrejudiceEconomique(value);
			setautrePrejudiceEconomiqueChecked(true);
			setfilterChecked(true);
		} else {
			setautrePrejudiceEconomique();
			setautrePrejudiceEconomiqueChecked(false);
		}
	};
	//

	//impart sur les relations
	const [ruptureDeLaFamile, setruptureDeLaFamile] = useState();
	const [ruptureDeLaFamileChecked, setruptureDeLaFamileChecked] =
		useState(false);

	const q13ruptureDeLaFamile = (e) => {
		const value = e.target.value;

		if (!ruptureDeLaFamileChecked) {
			setruptureDeLaFamile(value);
			setruptureDeLaFamileChecked(true);
			setfilterChecked(true);
		} else {
			setruptureDeLaFamile();
			setruptureDeLaFamileChecked(false);
		}
	};
	const [conflitDomestique, setconflitDomestique] = useState();
	const [conflitDomestiqueChecked, setconflitDomestiqueChecked] =
		useState(false);

	const q13conflitDomestique = (e) => {
		const value = e.target.value;

		if (!conflitDomestiqueChecked) {
			setconflitDomestique(value);
			setconflitDomestiqueChecked(true);
			setfilterChecked(true);
		} else {
			setconflitDomestique();
			setconflitDomestiqueChecked(false);
		}
	};
	const [abondanParLaFamille, setabondanParLaFamille] = useState();
	const [abondanParLaFamilleChecked, setabondanParLaFamilleChecked] =
		useState(false);

	const q13abondanParLaFamille = (e) => {
		const value = e.target.value;

		if (!abondanParLaFamilleChecked) {
			setabondanParLaFamille(value);
			setabondanParLaFamilleChecked(true);
			setfilterChecked(true);
		} else {
			setabondanParLaFamille();
			setabondanParLaFamilleChecked(false);
		}
	};
	const [stigmatisation, setstigmatisation] = useState();
	const [stigmatisationChecked, setstigmatisationChecked] = useState(false);

	const q13stigmatisation = (e) => {
		const value = e.target.value;

		if (!stigmatisationChecked) {
			setstigmatisation(value);
			setstigmatisationChecked(true);
			setfilterChecked(true);
		} else {
			setstigmatisation();
			setstigmatisationChecked(false);
		}
	};
	const [perteDeLaGarde, setperteDeLaGarde] = useState();
	const [perteDeLaGardeChecked, setperteDeLaGardeChecked] = useState(false);

	const q13perteDeLaGarde = (e) => {
		const value = e.target.value;

		if (!perteDeLaGardeChecked) {
			setperteDeLaGarde(value);
			setperteDeLaGardeChecked(true);
			setfilterChecked(true);
		} else {
			setperteDeLaGarde();
			setperteDeLaGardeChecked(false);
		}
	};
	const [isolementDeLaVictime, setisolementDeLaVictime] = useState();
	const [isolementDeLaVictimeChecked, setisolementDeLaVictimeChecked] =
		useState(false);

	const q13isolementDeLaVictime = (e) => {
		const value = e.target.value;

		if (!isolementDeLaVictimeChecked) {
			setisolementDeLaVictime(value);
			setisolementDeLaVictimeChecked(true);
			setfilterChecked(true);
		} else {
			setisolementDeLaVictime();
			setisolementDeLaVictimeChecked(false);
		}
	};
	const [autreImpactSurLesRelation, setautreImpactSurLesRelation] = useState();
	const [
		autreImpactSurLesRelationChecked,
		setautreImpactSurLesRelationChecked,
	] = useState(false);

	const q13autreImpactSurLesRelation = (e) => {
		const value = e.target.value;

		if (!autreImpactSurLesRelationChecked) {
			setautreImpactSurLesRelation(value);
			setautreImpactSurLesRelationChecked(true);
			setfilterChecked(true);
		} else {
			setautreImpactSurLesRelation();
			setautreImpactSurLesRelationChecked(false);
		}
	};
	//

	//q14a
	const [mere, setmere] = useState();
	const [mereChecked, setmereChecked] = useState(false);
	const q14amere = (e) => {
		const value = e.target.value;

		if (!mereChecked) {
			setmere(value);
			setmereChecked(true);
			setfilterChecked(true);
		} else {
			setmere();
			setmereChecked(false);
		}
	};
	const [pere, setpere] = useState();
	const [pereChecked, setpereChecked] = useState(false);
	const q14apere = (e) => {
		const value = e.target.value;

		if (!pereChecked) {
			setpere(value);
			setpereChecked(true);
			setfilterChecked(true);
		} else {
			setpere();
			setpereChecked(false);
		}
	};
	const [gardienHomme, setgardienHomme] = useState();
	const [gardienHommeChecked, setgardienHommeChecked] = useState(false);
	const q14agardienHomme = (e) => {
		const value = e.target.value;

		if (!gardienHommeChecked) {
			setgardienHomme(value);
			setgardienHommeChecked(true);
			setfilterChecked(true);
		} else {
			setgardienHomme();
			setgardienHommeChecked(false);
		}
	};

	const [gardienneFemme, setgardienneFemme] = useState();
	const [gardienneFemmeChecked, setgardienneFemmeChecked] = useState(false);
	const q14agardienneFemme = (e) => {
		const value = e.target.value;

		if (!gardienneFemmeChecked) {
			setgardienneFemme(value);
			setgardienneFemmeChecked(true);
			setfilterChecked(true);
		} else {
			setgardienneFemme();
			setgardienneFemmeChecked(false);
		}
	};
	const [propreFrere, setpropreFrere] = useState();
	const [propreFrereChecked, setpropreFrereChecked] = useState(false);
	const q14apropreFrere = (e) => {
		const value = e.target.value;

		if (!propreFrereChecked) {
			setpropreFrere(value);
			setpropreFrereChecked(true);
			setfilterChecked(true);
		} else {
			setpropreFrere();
			setpropreFrereChecked(false);
		}
	};

	const [propreSoeur, setpropreSoeur] = useState();
	const [propreSoeurChecked, setpropreSoeurChecked] = useState(false);
	const q14apropreSoeur = (e) => {
		const value = e.target.value;

		if (!propreSoeurChecked) {
			setpropreSoeur(value);
			setpropreSoeurChecked(true);
			setfilterChecked(true);
		} else {
			setpropreSoeur();
			setpropreSoeurChecked(false);
		}
	};
	const [propreEnfantAdulte, setpropreEnfantAdulte] = useState();
	const [propreEnfantAdulteChecked, setpropreEnfantAdulteChecked] =
		useState(false);
	const q14apropreEnfantAdulte = (e) => {
		const value = e.target.value;

		if (!propreEnfantAdulteChecked) {
			setpropreEnfantAdulte(value);
			setpropreEnfantAdulteChecked(true);
			setfilterChecked(true);
		} else {
			setpropreEnfantAdulte();
			setpropreEnfantAdulteChecked(false);
		}
	};

	const [gdMere, setgdMere] = useState();
	const [gdMereChecked, setgdMereChecked] = useState(false);
	const q14agdMere = (e) => {
		const value = e.target.value;

		if (!gdMereChecked) {
			setgdMere(value);
			setgdMereChecked(true);
			setfilterChecked(true);
		} else {
			setgdMere();
			setgdMereChecked(false);
		}
	};
	const [gdPere, setgdPere] = useState();
	const [gdPereChecked, setgdPereChecked] = useState(false);
	const q14agdPere = (e) => {
		const value = e.target.value;

		if (!gdPereChecked) {
			setgdPere(value);
			setgdPereChecked(true);
			setfilterChecked(true);
		} else {
			setgdPere();
			setgdPereChecked(false);
		}
	};

	const [belleMere, setbelleMere] = useState();
	const [belleMereChecked, setbelleMereChecked] = useState(false);
	const q14abelleMere = (e) => {
		const value = e.target.value;

		if (!belleMereChecked) {
			setbelleMere(value);
			setbelleMereChecked(true);
			setfilterChecked(true);
		} else {
			setbelleMere();
			setbelleMereChecked(false);
		}
	};

	const [beauPere, setbeauPere] = useState();
	const [beauPereChecked, setbeauPereChecked] = useState(false);
	const q14abeauPere = (e) => {
		const value = e.target.value;

		if (!beauPereChecked) {
			setbeauPere(value);
			setbeauPereChecked(true);
			setfilterChecked(true);
		} else {
			setbeauPere();
			setbeauPereChecked(false);
		}
	};

	const [beauxFere, setbeauxFere] = useState();
	const [beauxFereChecked, setbeauxFereChecked] = useState(false);
	const q14abeauxFere = (e) => {
		const value = e.target.value;

		if (!beauxFereChecked) {
			setbeauxFere(value);
			setbeauxFereChecked(true);
			setfilterChecked(true);
		} else {
			setbeauxFere();
			setbeauxFereChecked(false);
		}
	};

	const [belleSoeur, setbelleSoeur] = useState();
	const [belleSoeurChecked, setbelleSoeurChecked] = useState(false);
	const q14abelleSoeur = (e) => {
		const value = e.target.value;

		if (!belleSoeurChecked) {
			setbelleSoeur(value);
			setbelleSoeurChecked(true);
			setfilterChecked(true);
		} else {
			setbelleSoeur();
			setbelleSoeurChecked(false);
		}
	};

	const [oncle, setoncle] = useState();
	const [oncleChecked, setoncleChecked] = useState(false);
	const q14aoncle = (e) => {
		const value = e.target.value;

		if (!oncleChecked) {
			setoncle(value);
			setoncleChecked(true);
			setfilterChecked(true);
		} else {
			setoncle();
			setoncleChecked(false);
		}
	};

	const [tante, settante] = useState();
	const [tanteChecked, settanteChecked] = useState(false);
	const q14atante = (e) => {
		const value = e.target.value;

		if (!tanteChecked) {
			settante(value);
			settanteChecked(true);
			setfilterChecked(true);
		} else {
			settante();
			settanteChecked(false);
		}
	};

	const [autreMember, setautreMember] = useState();
	const [autreMemberChecked, setautreMemberChecked] = useState(false);
	const q14aautreMember = (e) => {
		const value = e.target.value;

		if (!autreMemberChecked) {
			setautreMember(value);
			setautreMemberChecked(true);
			setfilterChecked(true);
		} else {
			setautreMember();
			setautreMemberChecked(false);
		}
	};

	//q14b
	const [mari, setmari] = useState();
	const [mariChecked, setmariChecked] = useState(false);
	const q14bmari = (e) => {
		const value = e.target.value;

		if (!mariChecked) {
			setmari(value);
			setmariChecked(true);
			setfilterChecked(true);
		} else {
			setmari();
			setmariChecked(false);
		}
	};

	const [exmari, setexmari] = useState();
	const [exmariChecked, setexmariChecked] = useState(false);
	const q14bexmari = (e) => {
		const value = e.target.value;

		if (!exmariChecked) {
			setexmari(value);
			setexmariChecked(true);
			setfilterChecked(true);
		} else {
			setexmari();
			setexmariChecked(false);
		}
	};
	const [epouse, setepouse] = useState();
	const [epouseChecked, setepouseChecked] = useState(false);
	const q14bepouse = (e) => {
		const value = e.target.value;

		if (!epouseChecked) {
			setepouse(value);
			setepouseChecked(true);
			setfilterChecked(true);
		} else {
			setepouse();
			setepouseChecked(false);
		}
	};
	const [exEpouse, setexEpouse] = useState();
	const [exEpouseChecked, setexEpouseChecked] = useState(false);
	const q14bexEpouse = (e) => {
		const value = e.target.value;

		if (!exEpouseChecked) {
			setexEpouse(value);
			setexEpouseChecked(true);
			setfilterChecked(true);
		} else {
			setexEpouse();
			setexEpouseChecked(false);
		}
	};

	const [partenaireHomme, setpartenaireHomme] = useState();
	const [partenaireHommeChecked, setpartenaireHommeChecked] = useState(false);
	const q14bpartenaireHomme = (e) => {
		const value = e.target.value;

		if (!partenaireHommeChecked) {
			setpartenaireHomme(value);
			setpartenaireHommeChecked(true);
			setfilterChecked(true);
		} else {
			setpartenaireHomme();
			setpartenaireHommeChecked(false);
		}
	};

	const [partenaireFemme, setpartenaireFemme] = useState();
	const [partenaireFemmeChecked, setpartenaireFemmeChecked] = useState(false);
	const q14bpartenaireFemme = (e) => {
		const value = e.target.value;

		if (!partenaireFemmeChecked) {
			setpartenaireFemme(value);
			setpartenaireFemmeChecked(true);
			setfilterChecked(true);
		} else {
			setpartenaireFemme();
			setpartenaireFemmeChecked(false);
		}
	};

	const [exPaternaireHomme, setexPaternaireHomme] = useState();
	const [exPaternaireHommeChecked, setexPaternaireHommeChecked] =
		useState(false);
	const q14bexPaternaireHomme = (e) => {
		const value = e.target.value;

		if (!exPaternaireHommeChecked) {
			setexPaternaireHomme(value);
			setexPaternaireHommeChecked(true);
			setfilterChecked(true);
		} else {
			setexPaternaireHomme();
			setexPaternaireHommeChecked(false);
		}
	};

	const [exPaternaireFemme, setexPaternaireFemme] = useState();
	const [exPaternaireFemmeChecked, setexPaternaireFemmeChecked] =
		useState(false);
	const q14bexPaternaireFemme = (e) => {
		const value = e.target.value;

		if (!exPaternaireFemmeChecked) {
			setexPaternaireFemme(value);
			setexPaternaireFemmeChecked(true);
			setfilterChecked(true);
		} else {
			setexPaternaireFemme();
			setexPaternaireFemmeChecked(false);
		}
	};

	//q14c

	const [patron, setpatron] = useState();
	const [patronChecked, setpatronChecked] = useState(false);
	const q14cpatron = (e) => {
		const value = e.target.value;

		if (!patronChecked) {
			setpatron(value);
			setpatronChecked(true);
			setfilterChecked(true);
		} else {
			setpatron();
			setpatronChecked(false);
		}
	};

	const [patronne, setpatronne] = useState();
	const [patronneChecked, setpatronneChecked] = useState(false);
	const q14cpatronne = (e) => {
		const value = e.target.value;

		if (!patronneChecked) {
			setpatronne(value);
			setpatronneChecked(true);
			setfilterChecked(true);
		} else {
			setpatronne();
			setpatronneChecked(false);
		}
	};
	const [personnelHome, setpersonnelHome] = useState();
	const [personnelHomeChecked, setpersonnelHomeChecked] = useState(false);
	const q14cpersonnelHome = (e) => {
		const value = e.target.value;

		if (!personnelHomeChecked) {
			setpersonnelHome(value);
			setpersonnelHomeChecked(true);
			setfilterChecked(true);
		} else {
			setpersonnelHome();
			setpersonnelHomeChecked(false);
		}
	};

	const [personnelFemme, setpersonnelFemme] = useState();
	const [personnelFemmeChecked, setpersonnelFemmeChecked] = useState(false);
	const q14cpersonnelFemme = (e) => {
		const value = e.target.value;

		if (!personnelFemmeChecked) {
			setpersonnelFemme(value);
			setpersonnelFemmeChecked(true);
			setfilterChecked(true);
		} else {
			setpersonnelFemme();
			setpersonnelFemmeChecked(false);
		}
	};

	const [bailleur, setbailleur] = useState();
	const [bailleurChecked, setbailleurChecked] = useState(false);
	const q14cbailleur = (e) => {
		const value = e.target.value;

		if (!bailleurChecked) {
			setbailleur(value);
			setbailleurChecked(true);
			setfilterChecked(true);
		} else {
			setbailleur();
			setbailleurChecked(false);
		}
	};

	const [bailleresse, setbailleresse] = useState();
	const [bailleresseChecked, setbailleresseChecked] = useState(false);
	const q14cbailleresse = (e) => {
		const value = e.target.value;

		if (!bailleresseChecked) {
			setbailleresse(value);
			setbailleresseChecked(true);
			setfilterChecked(true);
		} else {
			setbailleresse();
			setbailleresseChecked(false);
		}
	};

	const [autreMemberDuCercle, setautreMemberDuCercle] = useState();
	const [autreMemberDuCercleChecked, setautreMemberDuCercleChecked] =
		useState(false);
	const q14cautreMemberDuCercle = (e) => {
		const value = e.target.value;

		if (!autreMemberDuCercleChecked) {
			setautreMemberDuCercle(value);
			setautreMemberDuCercleChecked(true);
			setfilterChecked(true);
		} else {
			setautreMemberDuCercle();
			setautreMemberDuCercleChecked(false);
		}
	};

	//q15

	const [q15a, setq15a] = useState();
	const [q15achecked, setq15achecked] = useState(false);

	const handleq15a = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q15achecked) {
			setq15a(value);
			setq15achecked(true);
			setfilterChecked(true);
		} else {
			setq15a();
			setq15achecked(false);
		}
	};
	const [q15b, setq15b] = useState();
	const [q15bchecked, setq15bchecked] = useState(false);

	const handleq15b = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q15bchecked) {
			setq15b(value);
			setq15bchecked(true);
			setfilterChecked(true);
		} else {
			setq15b();
			setq15bchecked(false);
		}
	};

	//q16
	const [q16a, setq16a] = useState();
	const [q16achecked, setq16achecked] = useState(false);

	const handleq16a = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q16achecked) {
			setq16a(value);
			setq16achecked(true);
			setfilterChecked(true);
		} else {
			setq16a();
			setq16achecked(false);
		}
	};
	const [q16b, setq16b] = useState();
	const [q16bschecked, setq16bschecked] = useState(false);

	const handleq16b = (e) => {
		const value = e.target.value;
		// console.log(value);
		if (!q16bschecked) {
			setq16b(value);
			setq16bschecked(true);
			setfilterChecked(true);
		} else {
			setq16b();
			setq16bschecked(false);
		}
	};
	//q18
	const [q18a, setq18a] = useState();
	const [q18achecked, setq18achecked] = useState(false);

	const handleq18a = (e) => {
		const value = e.target.value;
		if (!q18achecked) {
			setq18a(value);
			setq18achecked(true);
			setfilterChecked(true);
		} else {
			setq18a();
			setq18achecked(false);
		}
	};
	const [q18b, setq18b] = useState();
	const [q18bchecked, setq18bchecked] = useState(false);

	const handleq18b = (e) => {
		const value = e.target.value;
		if (!q18bchecked) {
			setq18b(value);
			setq18bchecked(true);
			setfilterChecked(true);
		} else {
			setq18b();
			setq18bchecked(false);
		}
	};

	//console.log(casemap);
	const [casestate, setcasestate] = useState(false);
	//
	const rsltFilters = () => {
		const filters = {
			question_6_b: [
				`${q6a}`,
				`${q6b}`,
				`${q6c}`,
				`${q6d}`,
				`${q6e}`,
				`${q6f}`,
			],
			question_7: [`${q7fem}`, `${q7masc}`, `${q7autr}`],
			question_8_a: [
				`${q8aCel}`,
				`${q8aUnion}`,
				`${q8aMarie}`,
				`${q8aSepare}`,
				`${q8aDivorce}`,
				`${q8aVeuf}`,
			],
		};

		//  console.log(filters.question_6_b);

		const data = casemap.filter(
			(item) =>
				//console.log(item.question_6_b===filters.question_6_b)
				item.question_6_b === q6a ||
				item.question_6_b === q6b ||
				item.question_6_b === q6c ||
				item.question_6_b === q6d ||
				item.question_6_b === q6e ||
				item.question_6_b === q6f ||
				item.question_7 === q7fem ||
				item.question_7 === q7masc ||
				item.question_7 === q7autr ||
				item.question_8_a === q8aCel ||
				item.question_8_a === q8aUnion ||
				item.question_8_a === q8aMarie ||
				item.question_8_a === q8aSepare ||
				item.question_8_a === q8aDivorce ||
				item.question_8_a === q8aVeuf ||
				item.question_8_b === q8blgbti ||
				item.question_8_c === q8cHandicap ||
				item.question_8_d === q8dVictime ||
				item.question_8_d === q8dVictimeSans ||
				item.question_10_a.indexOf(viol) > -1 ||
				item.question_10_a.indexOf(conj) > -1 ||
				item.question_10_a.indexOf(agressio) > -1 ||
				item.question_10_a.indexOf(coercition) > -1 ||
				item.question_10_a.indexOf(harcelement) > -1 ||
				item.question_10_a.indexOf(attouchement) > -1 ||
				item.question_10_a.indexOf(etreForce) > -1 ||
				item.question_10_a.indexOf(etreEmpeche) > -1 ||
				item.question_10_a.indexOf(autreViolSexuel) > -1 ||
				item.question_10_b.indexOf(meutre) > -1 ||
				item.question_10_b.indexOf(coupDePoing) > -1 ||
				item.question_10_b.indexOf(coupDePied) > -1 ||
				item.question_10_b.indexOf(attaque) > -1 ||
				item.question_10_b.indexOf(attaqueACouteau) > -1 ||
				item.question_10_b.indexOf(attaqueAEaux) > -1 ||
				item.question_10_b.indexOf(privationDeNouriture) > -1 ||
				item.question_10_b.indexOf(negligencePhyDenfant) > -1 ||
				item.question_10_b.indexOf(negligencePhyPerson) > -1 ||
				item.question_10_b.indexOf(destructionDobject) > -1 ||
				item.question_10_b.indexOf(ritesDeVeuvage) > -1 ||
				item.question_10_b.indexOf(autreViolPhysique) > -1 ||
				item.question_10_c.indexOf(menace) > -1 ||
				item.question_10_c.indexOf(injure) > -1 ||
				item.question_10_c.indexOf(negligenceEmotionnel) > -1 ||
				item.question_10_c.indexOf(humiliationFace) > -1 ||
				item.question_10_c.indexOf(attaqueVisant) > -1 ||
				item.question_10_c.indexOf(isolementDeLaFamille) > -1 ||
				item.question_10_c.indexOf(controleDeMouvement) > -1 ||
				item.question_10_c.indexOf(controleDeLaCommunication) > -1 ||
				item.question_10_c.indexOf(refusDeDivorce) > -1 ||
				item.question_10_c.indexOf(abusSpirituel) > -1 ||
				item.question_10_c.indexOf(autreViolPsychologique) > -1 ||
				item.question_10_d.indexOf(abondonDeLaFamille) > -1 ||
				item.question_10_d.indexOf(confiscationDeRevenue) > -1 ||
				item.question_10_d.indexOf(interdictionDeParticipation) > -1 ||
				item.question_10_d.indexOf(restrictionDeLAccessDEducation) > -1 ||
				item.question_10_d.indexOf(restrictionDeLaccessDeLemploi) > -1 ||
				item.question_10_d.indexOf(restrictionDeLAccessAuxRessource) > -1 ||
				item.question_10_d.indexOf(privationDePriseChange) > -1 ||
				item.question_10_d.indexOf(levirat) > -1 ||
				item.question_10_d.indexOf(perterDePropriete) > -1 ||
				item.question_10_d.indexOf(perterDeLenfant) > -1 ||
				item.question_10_d.indexOf(autreViolEconomique) > -1 ||
				item.question_12_a === q12banda ||
				item.question_12_a === q12barumbu ||
				item.question_12_a === q12bumbu ||
				item.question_12_a === q12Gombe ||
				item.question_12_a === q12Kalamu ||
				item.question_12_a === q12kasa ||
				item.question_12_a === q12Kimba ||
				item.question_12_a === q12kinshasa ||
				item.question_12_a === q12kintambo ||
				item.question_12_a === q12kisenso ||
				item.question_12_a === q12lemba ||
				item.question_12_a === q12limete ||
				item.question_12_a === q12Lingwala ||
				item.question_12_a === q12Makala ||
				item.question_12_a === q12Maluku ||
				item.question_12_a === q12masina ||
				item.question_12_a === q12matete ||
				item.question_12_a === q12montNgafula ||
				item.question_12_a === q12ndjili ||
				item.question_12_a === q12ngaba ||
				item.question_12_a === q12ngaliema ||
				item.question_12_a === q12ngiri ||
				item.question_12_a === q12nsele ||
				item.question_12_a === q12selembao ||
				item.question_12_a === q12bunia ||
				item.question_12_a === q12aru ||
				item.question_12_a === q12djugu ||
				item.question_12_a === q12irumu ||
				item.question_12_a === q12mahagi ||
				item.question_12_a === q12mambasa ||
				item.question_12_a === q12kananga ||
				item.question_12_a === q12demba ||
				item.question_12_a === q12dibaya ||
				item.question_12_a === q12dimbelenge ||
				item.question_12_a === q12kazumba ||
				item.question_12_a === q12luiza ||
				item.question_12_a === q12mbuji ||
				item.question_12_a === q12kabeya ||
				item.question_12_a === q12katanda ||
				item.question_12_a === q12lupatapata ||
				item.question_12_a === q12miabi ||
				item.question_12_a === q12tshilenge ||
				item.question_12_a === q12kindu ||
				item.question_12_a === q12kambare ||
				item.question_12_a === q12kailo ||
				item.question_12_a === q12kasongo ||
				item.question_12_a === q12kibombo ||
				item.question_12_a === q12lubutu ||
				item.question_12_a === q12pangi ||
				item.question_12_a === q12punia ||
				item.question_12_a === q12benivile ||
				item.question_12_a === q12butembo ||
				item.question_12_a === q12goma ||
				item.question_12_a === q12beni ||
				item.question_12_a === q12lubero ||
				item.question_12_a === q12masisi ||
				item.question_12_a === q12nyiragongo ||
				item.question_12_a === q12rutshuru ||
				item.question_12_a === q12walikale ||
				item.question_12_a === q12bukavu ||
				item.question_12_a === q12fizi ||
				item.question_12_a === q12idjwi ||
				item.question_12_a === q12kabare ||
				item.question_12_a === q12kalehe ||
				item.question_12_a === q12mwenga ||
				item.question_12_a === q12shabunda ||
				item.question_12_a === q12uvira ||
				item.question_12_a === q12walungu ||
				item.question_12_a === q12kisangani ||
				item.question_12_a === q12bafwasende ||
				item.question_12_a === q12banalia ||
				item.question_12_a === q12basoko ||
				item.question_12_a === q12isangi ||
				item.question_12_a === q12opala ||
				item.question_12_a === q12ubundu ||
				item.question_12_a === q12yahuma ||
				item.question_13_b_a.indexOf(deces) > -1 ||
				item.question_13_b_a.indexOf(maladie) > -1 ||
				item.question_13_b_a.indexOf(blessure) > -1 ||
				item.question_13_b_a.indexOf(douleur) > -1 ||
				item.question_13_b_a.indexOf(troubleGyn) > -1 ||
				item.question_13_b_a.indexOf(handicap) > -1 ||
				item.question_13_b_a.indexOf(grossesse) > -1 ||
				item.question_13_b_a.indexOf(perteDeLenfantANaitre) > -1 ||
				item.question_13_b_a.indexOf(abusDAlcools) > -1 ||
				item.question_13_b_a.indexOf(abusDeDrogues) > -1 ||
				item.question_13_b_a.indexOf(abusDeMedicaments) > -1 ||
				item.question_13_b_a.indexOf(autrePrejudicePhysique) > -1 ||
				item.question_13_b_b.indexOf(risqueDeSuicide) > -1 ||
				item.question_13_b_b.indexOf(peurEtAnxiete) > -1 ||
				item.question_13_b_b.indexOf(depression) > -1 ||
				item.question_13_b_b.indexOf(detresse) > -1 ||
				item.question_13_b_b.indexOf(sentimentDeHonte) > -1 ||
				item.question_13_b_b.indexOf(sentimentDeColere) > -1 ||
				item.question_13_b_b.indexOf(troubleDeSommeil) > -1 ||
				item.question_13_b_b.indexOf(troubleAlimentaires) > -1 ||
				item.question_13_b_b.indexOf(peeteDEspoire) > -1 ||
				item.question_13_b_b.indexOf(manqueDeConfiance) > -1 ||
				item.question_13_b_b.indexOf(autrePrejudiceEmotionnel) > -1 ||
				item.question_13_b_c.indexOf(perterDeMoyens) > -1 ||
				item.question_13_b_c.indexOf(perterDeRevenues) > -1 ||
				item.question_13_b_c.indexOf(sexeDeSurvie) > -1 ||
				item.question_13_b_c.indexOf(perteDeMaisons) > -1 ||
				item.question_13_b_c.indexOf(demanegementFrequent) > -1 ||
				item.question_13_b_c.indexOf(situationDeSanSAbri) > -1 ||
				item.question_13_b_c.indexOf(pasOuPeu) > -1 ||
				item.question_13_b_c.indexOf(autrePrejudiceEconomique) > -1 ||
				item.question_13_b_d.indexOf(ruptureDeLaFamile) > -1 ||
				item.question_13_b_d.indexOf(conflitDomestique) > -1 ||
				item.question_13_b_d.indexOf(abondanParLaFamille) > -1 ||
				item.question_13_b_d.indexOf(stigmatisation) > -1 ||
				item.question_13_b_d.indexOf(perteDeLaGarde) > -1 ||
				item.question_13_b_d.indexOf(isolementDeLaVictime) > -1 ||
				item.question_13_b_d.indexOf(autreImpactSurLesRelation) > -1 ||
				item.question_14_a_oui.indexOf(mere) > -1 ||
				item.question_14_a_oui.indexOf(pere) > -1 ||
				item.question_14_a_oui.indexOf(gardienHomme) > -1 ||
				item.question_14_a_oui.indexOf(gardienneFemme) > -1 ||
				item.question_14_a_oui.indexOf(propreFrere) > -1 ||
				item.question_14_a_oui.indexOf(propreSoeur) > -1 ||
				item.question_14_a_oui.indexOf(propreEnfantAdulte) > -1 ||
				item.question_14_a_oui.indexOf(gdMere) > -1 ||
				item.question_14_a_oui.indexOf(gdPere) > -1 ||
				item.question_14_a_oui.indexOf(belleMere) > -1 ||
				item.question_14_a_oui.indexOf(beauPere) > -1 ||
				item.question_14_a_oui.indexOf(beauxFere) > -1 ||
				item.question_14_a_oui.indexOf(belleSoeur) > -1 ||
				item.question_14_a_oui.indexOf(oncle) > -1 ||
				item.question_14_a_oui.indexOf(tante) > -1 ||
				item.question_14_a_oui.indexOf(autreMember) > -1 ||
				item.question_14_b_oui.indexOf(mari) > -1 ||
				item.question_14_b_oui.indexOf(exmari) > -1 ||
				item.question_14_b_oui.indexOf(epouse) > -1 ||
				item.question_14_b_oui.indexOf(exEpouse) > -1 ||
				item.question_14_b_oui.indexOf(partenaireHomme) > -1 ||
				item.question_14_b_oui.indexOf(partenaireFemme) > -1 ||
				item.question_14_b_oui.indexOf(exPaternaireHomme) > -1 ||
				item.question_14_b_oui.indexOf(exPaternaireFemme) > -1 ||
				item.question_14_c.indexOf(patron) > -1 ||
				item.question_14_c.indexOf(patronne) > -1 ||
				item.question_14_c.indexOf(personnelHome) > -1 ||
				item.question_14_c.indexOf(personnelFemme) > -1 ||
				item.question_14_c.indexOf(bailleur) > -1 ||
				item.question_14_c.indexOf(bailleresse) > -1 ||
				item.question_14_c.indexOf(autreMemberDuCercle) > -1 ||
				item.question_15 === q15a ||
				item.question_15 === q15b ||
				item.question_16 === q16a ||
				item.question_16 === q16b ||
				item.question_18 === q18a ||
				item.question_18 === q18b
		);
		const translate = data.map((e) => ({
			id: e.id,
			caseid: e.caseid,
			question_4: e.question_4,
			question_6_a: e.question_6_a,
			question_6_b:
				(e.question_6_b === "0-5 ans" ? "0 to 5 years old" : null) ||
				(e.question_6_b === "6-17 ans" ? "6 to 17 years old" : null) ||
				(e.question_6_b === "18-29 ans" ? "18 to 29 years old" : null) ||
				(e.question_6_b === "30-49 ans" ? "30 to 49 years old" : null) ||
				(e.question_6_b === "50-64 ans" ? "50 to 64 years old" : null) ||
				(e.question_6_b === "65 ans et plus" ? "65 years and older" : null),
			question_7:
				(e.question_7 === "Féminin" ? "Female" : null) ||
				(e.question_7 === "Masculin" ? "Male" : null) ||
				(e.question_7 === "Autre" ? "Other" : null),
			question_8_a:
				(e.question_8_a === "Célibataire" ? "Single" : null) ||
				(e.question_8_a === "Union de fait (vivre ensemble sans être mariés)"
					? "Common law (living together without being married)"
					: null) ||
				(e.question_8_a === "Marié(e)" ? "Married" : null) ||
				(e.question_8_a === "Séparé(e)" ? "Separated" : null) ||
				(e.question_8_a === "Divorcé(e)" ? "Divorced" : null) ||
				(e.question_8_a === "Veuve / Veuf" ? "Widow / Widower" : null),
			question_8_b:
				(e.question_8_b === "Oui" ? "Yes" : null) ||
				(e.question_8_b === "Non" ? "No" : null),
			question_8_c:
				(e.question_8_c === "Oui" ? "Yes" : null) ||
				(e.question_8_c === "Non" ? "No" : null),
			question_8_d:
				(e.question_8_d === "Oui" ? "Yes" : null) ||
				(e.question_8_d === "Non" ? "No" : null),
			question_8_e:
				(e.question_8_e === "Oui" ? "Yes" : null) ||
				(e.question_8_e === "Non" ? "No" : null),
			question_8_f:
				(e.question_8_f === "Oui" ? "Yes" : null) ||
				(e.question_8_f === "Non" ? "No" : null),
			question_10_a: e.question_10_a,
			question_10_b: e.question_10_b,
			question_10_c: e.question_10_c,
			question_10_d: e.question_10_d,
			question_11_a:
				(e.question_11_a === "Oui" ? "Yes" : null) ||
				(e.question_11_a === "Non" ? "No" : null),
			question_11_a_oui: e.question_11_a_oui,
			question_11_a_oui_month: e.question_11_a_oui_month,
			question_11_b:
				(e.question_11_b === "Oui" ? "Yes" : null) ||
				(e.question_11_b === "Non" ? "No" : null),
			question_11_b_oui: e.question_11_b_oui,
			question_11_b_oui_month: e.question_11_b_oui_month,
			question_12_a: e.question_12_a,
			question_12_b:
				(e.question_12_b === "Milieu urbain"
					? `${t("case-statistic-place-urb")}`
					: null) ||
				(e.question_12_b === "Milieu semi-urbain"
					? `${t("case-statistic-place-semi-urb")}`
					: null) ||
				(e.question_12_b === "Milieu rural"
					? `${t("case-statistic-place-rural")}`
					: null),
			question_13_a: e.question_13_a,
			question_13_b_a: e.question_13_b_a,
			question_13_b_b: e.question_13_b_b,
			question_13_b_c: e.question_13_b_c,
			question_13_b_d: e.question_13_b_d,
			question_14_a:
				(e.question_14_a === "Oui" ? "Yes" : null) ||
				(e.question_14_a === "Non" ? "No" : null),
			question_14_a_oui: e.question_14_a_oui,
			question_14_b:
				(e.question_14_b === "Oui" ? "Yes" : null) ||
				(e.question_14_b === "Non" ? "No" : null),
			question_14_b_oui: e.question_14_b_oui,
			question_14_c: e.question_14_c,
			question_15:
				(e.question_15 === "Oui" ? "Yes" : null) ||
				(e.question_15 === "Non" ? "No" : null),
			question_15_oui: e.question_15_oui,
			question_15_oui_autre: e.question_15_oui_autre,
			question_16:
				(e.question_16 === "Oui" ? "Yes" : null) ||
				(e.question_16 === "Non" ? "No" : null),
			question_16_oui:
				(e.question_16_oui === "Oui" ? "Yes" : null) ||
				(e.question_16_oui === "Non" ? "No" : null),
			question_17:
				(e.question_17 === "Oui" ? "Yes" : null) ||
				(e.question_17 === "Non" ? "No" : null),
			question_18:
				(e.question_18 === "Oui" ? "Yes" : null) ||
				(e.question_18 === "Non" ? "No" : null),
			question_18_a: e.question_18_a,
			question_18_a_autre: e.question_18_a_autre,
			question_19:
				(e.question_19 === "Oui" ? "Yes" : null) ||
				(e.question_19 === "Non" ? "No" : null),
			question_19_oui: e.question_19_oui,
			question_19_autre: e.question_19_autre,
			question_23:
				(e.question_11_a === "Oui" ? "Yes" : null) ||
				(e.question_11_a === "Non" ? "No" : null),
			question_24:
				(e.question_24 === "Non, la victime cherche à rester chez elle"
					? "No, the victim seeks to remain in her home"
					: null) ||
				(e.question_24 ===
				"Oui, la victime cherche à quitter son foyer de façon temporaire"
					? "Yes, the victim is seeking to leave home temporarily"
					: null) ||
				(e.question_24 ===
				"Oui, la victime cherche à quitter son foyer de façon permanente"
					? "Yes, the victim seeks to leave home permanently"
					: null),
			question_24_a:
				(e.question_24_a === "Oui" ? "Yes" : null) ||
				(e.question_24_a === "Non" ? "No" : null),
			city: e.city,
			province: e.province,
			lat: e.lat,
			lon: e.lon,
		}));
		// console.log(data);

		// console.log(translate);
		setcasefilter(translate);
		setfiltedDataFrench(data);

		setcasestate(true);

		return data;
	};
	//
	useEffect(() => {
		getCase();
	}, [casemap.id]); //axiosInstance

	// console.log(casefilter);
	// console.log(casemap);

	const filted = casemap.map((e) => ({
		id: e.id,
		caseid: e.caseid,
		question_4: e.question_4,
		question_6_a: e.question_6_a,
		question_6_b:
			(e.question_6_b === "0-5 ans" ? "0 to 5 years old" : null) ||
			(e.question_6_b === "6-17 ans" ? "6 to 17 years old" : null) ||
			(e.question_6_b === "18-29 ans" ? "18 to 29 years old" : null) ||
			(e.question_6_b === "30-49 ans" ? "30 to 49 years old" : null) ||
			(e.question_6_b === "50-64 ans" ? "50 to 64 years old" : null) ||
			(e.question_6_b === "65 ans et plus" ? "65 years and older" : null),
		question_7:
			(e.question_7 === "Féminin" ? "Female" : null) ||
			(e.question_7 === "Masculin" ? "Male" : null) ||
			(e.question_7 === "Autre" ? "Other" : null),
		question_8_a:
			(e.question_8_a === "Célibataire" ? "Single" : null) ||
			(e.question_8_a === "Union de fait (vivre ensemble sans être mariés)"
				? "Common law (living together without being married)"
				: null) ||
			(e.question_8_a === "Marié(e)" ? "Married" : null) ||
			(e.question_8_a === "Séparé(e)" ? "Separated" : null) ||
			(e.question_8_a === "Divorcé(e)" ? "Divorced" : null) ||
			(e.question_8_a === "Veuve / Veuf" ? "Widow / Widower" : null),
		question_8_b:
			(e.question_8_b === "Oui" ? "Yes" : null) ||
			(e.question_8_b === "Non" ? "No" : null),
		question_8_c:
			(e.question_8_c === "Oui" ? "Yes" : null) ||
			(e.question_8_c === "Non" ? "No" : null),
		question_8_d:
			(e.question_8_d === "Oui" ? "Yes" : null) ||
			(e.question_8_d === "Non" ? "No" : null),
		question_8_e:
			(e.question_8_e === "Oui" ? "Yes" : null) ||
			(e.question_8_e === "Non" ? "No" : null),
		question_8_f:
			(e.question_8_f === "Oui" ? "Yes" : null) ||
			(e.question_8_f === "Non" ? "No" : null),
		question_10_a: e.question_10_a,
		question_10_b: e.question_10_b,
		question_10_c: e.question_10_c,
		question_10_d: e.question_10_d,
		question_11_a:
			(e.question_11_a === "Oui" ? "Yes" : null) ||
			(e.question_11_a === "Non" ? "No" : null),
		question_11_a_oui: e.question_11_a_oui,
		question_11_a_oui_month: e.question_11_a_oui_month,
		question_11_b:
			(e.question_11_b === "Oui" ? "Yes" : null) ||
			(e.question_11_b === "Non" ? "No" : null),
		question_11_b_oui: e.question_11_b_oui,
		question_11_b_oui_month: e.question_11_b_oui_month,
		question_12_a: e.question_12_a,
		question_12_b:
			(e.question_12_b === "Milieu urbain"
				? `${t("case-statistic-place-urb")}`
				: null) ||
			(e.question_12_b === "Milieu semi-urbain"
				? `${t("case-statistic-place-semi-urb")}`
				: null) ||
			(e.question_12_b === "Milieu rural"
				? `${t("case-statistic-place-rural")}`
				: null),
		question_13_a: e.question_13_a,
		question_13_b_a: e.question_13_b_a,
		question_13_b_b: e.question_13_b_b,
		question_13_b_c: e.question_13_b_c,
		question_13_b_d: e.question_13_b_d,
		question_14_a:
			(e.question_14_a === "Oui" ? "Yes" : null) ||
			(e.question_14_a === "Non" ? "No" : null),
		question_14_a_oui: e.question_14_a_oui,
		question_14_b:
			(e.question_14_b === "Oui" ? "Yes" : null) ||
			(e.question_14_b === "Non" ? "No" : null),
		question_14_b_oui: e.question_14_b_oui,
		question_14_c: e.question_14_c,
		question_15:
			(e.question_15 === "Oui" ? "Yes" : null) ||
			(e.question_15 === "Non" ? "No" : null),
		question_15_oui: e.question_15_oui,
		question_15_oui_autre: e.question_15_oui_autre,
		question_16:
			(e.question_16 === "Oui" ? "Yes" : null) ||
			(e.question_16 === "Non" ? "No" : null),
		question_16_oui:
			(e.question_16_oui === "Oui" ? "Yes" : null) ||
			(e.question_16_oui === "Non" ? "No" : null),
		question_17:
			(e.question_17 === "Oui" ? "Yes" : null) ||
			(e.question_17 === "Non" ? "No" : null),
		question_18:
			(e.question_18 === "Oui" ? "Yes" : null) ||
			(e.question_18 === "Non" ? "No" : null),
		question_18_a: e.question_18_a,
		question_18_a_autre: e.question_18_a_autre,
		question_19:
			(e.question_19 === "Oui" ? "Yes" : null) ||
			(e.question_19 === "Non" ? "No" : null),
		question_19_oui: e.question_19_oui,
		question_19_autre: e.question_19_autre,
		question_23:
			(e.question_11_a === "Oui" ? "Yes" : null) ||
			(e.question_11_a === "Non" ? "No" : null),
		question_24:
			(e.question_24 === "Non, la victime cherche à rester chez elle"
				? "No, the victim seeks to stay at home"
				: null) ||
			(e.question_24 ===
			"Oui, la victime cherche à quitter son foyer de façon temporaire"
				? "Yes, the victim seeks to leave home temporarily"
				: null) ||
			(e.question_24 ===
			"Oui, la victime cherche à quitter son foyer de façon permanente"
				? "Yes, the victim seeks to leave home permanently"
				: null),
		question_24_a:
			(e.question_24_a === "Oui" ? "Yes" : null) ||
			(e.question_24_a === "Non" ? "No" : null),
		city: e.city,
		province: e.province,
	}));
	// console.log(filted);

	const clearFilter = () => {
		setfilterChecked(false);
		setcasestate(false);
		setq6a();
		setq6achecked(false);
		setq6b();
		setq6bchecked(false);
		setq6c();
		setq6cchecked(false);
		setq6d();
		setq6dchecked(false);
		setq6e();
		setq6echecked(false);
		setq6f();
		setq6fchecked(false);
		setq7fem();
		setq7femchecked(false);
		setq7masc();
		setq7mascchecked(false);
		setq7autr();
		setq7autrchecked(false);
		setq8aCel();
		setq8aCelchecked(false);
		setq8aUnion();
		setq8aUnionchecked(false);
		setq8aMarie();
		setq8aMariechecked(false);
		setq8aSepare();
		setq8aSeparechecked(false);
		setq8aDivorce();
		setq8aDivorcechecked(false);
		setq8aVeuf();
		setq8aVeufchecked(false);
		setq8blgbti();
		setq8blgbtichecked(false);
		setq8cHandicap();
		setq8cHandicapchecked(false);
		setq8dVictime();
		setq8dVictimechecked(false);
		setq8dVictimeSans();
		setq8dVictimeSanschecked(false);
		setviol();
		setviolChecked(false);
		setconjChecked(false);
		setconj();
		setagressio();
		setagressioChecked(false);
		setcoercition();
		setcoerChecked(false);
		setHarcelement();
		setharcChecked(false);
		setAttouchements();
		setattoucChecked(false);
		setetreForces();
		setetrForChecked(false);
		setetreEmpeches();
		setetrEmpChecked(false);
		setautreViolSexuels();
		setautreViolSexuelChecked(false);
		setmeutre();
		setmeutrChecked(false);
		setcoupDePoing();
		setcoupDePoingChecked(false);
		setcoupDePied();
		setcoupDePiedChecked(false);
		setattaque();
		setattaqueChecked(false);
		setattaqueACouteau();
		setattaqueACouteauChecked(false);
		setattaqueAEaux();
		setattaqueAEauxChecked(false);
		setprivation();
		setprivationChecked(false);
		setprivationDeNouriture();
		setprivationDeNouritureChecked(false);
		setnegligencePhyDenfant();
		setnegligencePhyDenfantChecked(false);
		setnegligencePhyPerson();
		setnegligencePhyPersonChecked(false);
		setdestructionDobject();
		setdestructionDobjectChecked(false);
		setritesDeVeuvage();
		setritesDeVeuvageChecked(false);
		setAutresViolPhysique();
		setAutresViolPhysiqueChecked(false);
		setmenace();
		setmenaceChecked(false);
		setinjure();
		setinjureChecked(false);
		setnegligenceEmotionnel();
		setnegligenceEmotionnelChecked(false);
		sethumiliationFace();
		sethumiliationFaceChecked(false);
		setattaqueVisant();
		setattaqueVisantChecked(false);
		setisolementDeLaFamille();
		setisolementDeLaFamilleChecked(false);
		setcontroleDeMouvement();
		setcontroleDeMouvementChecked(false);
		setcontroleDeLaCommunication();
		setcontroleDeLaCommunicationChecked(false);
		setrefusDeDivorce();
		setrefusDeDivorceChecked(false);
		setabusSpirituel();
		setabusSpirituelChecked(false);
		setautreViolPsychologique();
		setautreViolPsychologiqueChecked(false);
		setabondonDeLaFamille();
		setabondonDeLaFamilleChecked(false);
		setconfiscationDeRevenue();
		setconfiscationDeRevenueChecked(false);
		setinterdictionDeParticipation();
		setinterdictionDeParticipationChecked(false);
		setrestrictionDeLAccessDEducation();
		setrestrictionDeLAccessDEducationChecked(false);
		setrestrictionDeLaccessDeLemploi();
		setrestrictionDeLaccessDeLemploiChecked(false);
		setrestrictionDeLAccessAuxRessource();
		setrestrictionDeLAccessAuxRessourceChecked(false);
		setprivationDePriseChange();
		setprivationDePriseChangeChecked(false);
		setlevirat();
		setleviratChecked(false);
		setperterDePropriete();
		setperterDeProprieteChecked(false);
		setperterDeLenfant();
		setperterDeLenfantChecked(false);
		setautreViolEconomique();
		setautreViolEconomiqueChecked(false);
		setq12banda();
		setq12bandachecked(false);
		setq12barumbu();
		setq12barumbuchecked(false);
		setq12bumbu();
		setq12bumbuchecked(false);
		setq12Gombe();
		setq12Gombechecked(false);
		setq12Kalamu();
		setq12Kalamuchecked(false);
		setq12kasa();
		setq12kasachecked(false);
		setq12Kimba();
		setq12Kimbachecked(false);
		setq12kinshasa();
		setq12kinshasachecked(false);
		setq12kintambo();
		setq12kintambochecked(false);
		setq12kisenso();
		setq12kisensochecked(false);
		setq12lemba();
		setq12lembachecked(false);
		setq12limete();
		setq12limetechecked(false);
		setq12Lingwala();
		setq12Lingwalachecked(false);
		setq12Makala();
		setq12Makalachecked(false);
		setq12Maluku();
		setq12Malukuchecked(false);
		setq12masina();
		setq12masinachecked(false);
		setq12matete();
		setq12matetechecked(false);
		setq12montNgafula();
		setq12montNgafulachecked(false);
		setq12ndjili();
		setq12ndjilichecked(false);
		setq12ngaba();
		setq12ngabachecked(false);
		setq12ngaliema();
		setq12ngaliemachecked(false);
		setq12ngiri();
		setq12ngirichecked(false);
		setq12nsele();
		setq12nselechecked(false);
		setq12selembao();
		setq12selembaochecked(false);
		setq12bunia();
		setq12buniachecked(false);
		setq12aru();
		setq12aruchecked(false);
		setq12djugu();
		setq12djuguchecked(false);
		setq12irumu();
		setq12irumuchecked(false);
		setq12mahagi();
		setq12mahagichecked(false);
		setq12mambasa();
		setq12mambasachecked(false);
		setq12kananga();
		setq12kanangachecked(false);
		setq12demba();
		setq12dembachecked(false);
		setq12dibaya();
		setq12dibayachecked(false);
		setq12dimbelenge();
		setq12dimbelengechecked(false);
		setq12kazumba();
		setq12kazumbachecked(false);
		setq12luiza();
		setq12luizachecked(false);
		setq12mbuji();
		setq12mbujichecked(false);
		setq12kabeya();
		setq12kabeyachecked(false);
		setq12katanda();
		setq12katandachecked(false);
		setq12lupatapata();
		setq12lupatapatachecked(false);
		setq12miabi();
		setq12miabichecked(false);
		setq12tshilenge();
		setq12tshilengechecked(false);
		setq12kindu();
		setq12kinduchecked(false);
		setq12kambare();
		setq12kambarechecked(false);
		setq12kailo();
		setq12kailochecked(false);
		setq12kasongo();
		setq12kasongochecked(false);
		setq12kibombo();
		setq12kibombochecked(false);
		setq12lubutu();
		setq12lubutuchecked(false);
		setq12pangi();
		setq12pangichecked(false);
		setq12punia();
		setq12puniachecked(false);
		setq12benivile();
		setq12benivilechecked(false);
		setq12butembo();
		setq12butembochecked(false);
		setq12goma();
		setq12gomachecked(false);
		setq12beni();
		setq12benichecked(false);
		setq12lubero();
		setq12luberochecked(false);
		setq12masisi();
		setq12masisichecked(false);
		setq12nyiragongo();
		setq12nyiragongochecked(false);
		setq12rutshuru();
		setq12rutshuruchecked(false);
		setq12walikale();
		setq12walikalechecked(false);
		setq12bukavu();
		setq12bukavuchecked(false);
		setq12fizi();
		setq12fizichecked(false);
		setq12idjwi();
		setq12idjwichecked(false);
		setq12kabare();
		setq12kabarechecked(false);
		setq12kalehe();
		setq12kalehechecked(false);
		setq12mwenga();
		setq12mwengachecked(false);
		setq12shabunda();
		setq12shabundachecked(false);
		setq12uvira();
		setq12uvirachecked(false);
		setq12walungu();
		setq12walunguchecked(false);
		setq12kisangani();
		setq12kisanganichecked(false);
		setq12bafwasende();
		setq12bafwasendechecked(false);
		setq12banalia();
		setq12banaliachecked(false);
		setq12basoko();
		setq12basokochecked(false);
		setq12isangi();
		setq12isangichecked(false);
		setq12opala();
		setq12opalachecked(false);
		setq12ubundu();
		setq12ubunduchecked(false);
		setq12yahuma();
		setq12yahumachecked(false);
		setdeces();
		setdecesChecked(false);
		setmaladie();
		setmaladieChecked(false);
		setblessure();
		setblessureChecked(false);
		setdouleur();
		setdouleurChecked(false);
		settroubleGyn();
		settroubleGynChecked(false);
		sethandicap();
		sethandicapChecked(false);
		setgrossesse();
		setgrossesseChecked(false);
		setperteDeLenfantANaitre();
		setperteDeLenfantANaitreChecked(false);
		setabusDAlcools();
		setabusDAlcoolsChecked(false);
		setabusDeDrogues();
		setabusDeDroguesChecked(false);
		setabusDeMedicaments();
		setabusDeMedicamentsChecked(false);
		setautrePrejudicePhysique();
		setautrePrejudicePhysiqueChecked(false);
		setrisqueDeSuicide();
		setrisqueDeSuicideChecked(false);
		setpeurEtAnxiete();
		setpeurEtAnxieteChecked(false);
		setdepression();
		setdepressionChecked(false);
		setdetresse();
		setdetresseChecked(false);
		setsentimentDeHonte();
		setsentimentDeHonteChecked(false);
		setsentimentDeColere();
		setsentimentDeColereChecked(false);
		settroubleDeSommeil();
		settroubleDeSommeilChecked(false);
		settroubleAlimentaires();
		settroubleAlimentairesChecked(false);
		setpeeteDEspoire();
		setpeeteDEspoireChecked(false);
		setmanqueDeConfiance();
		setmanqueDeConfianceChecked(false);
		setautrePrejudiceEmotionnel();
		setautrePrejudiceEmotionnelChecked(false);
		setperterDeMoyens();
		setperterDeMoyensChecked(false);
		setperterDeRevenues();
		setperterDeRevenuesChecked(false);
		setsexeDeSurvie();
		setsexeDeSurvieChecked(false);
		setperteDeMaisons();
		setperteDeMaisonsChecked(false);
		setdemanegementFrequent();
		setdemanegementFrequentChecked(false);
		setsituationDeSanSAbri();
		setsituationDeSanSAbriChecked(false);
		setpasOuPeu();
		setpasOuPeuChecked(false);
		setautrePrejudiceEconomique();
		setautrePrejudiceEconomiqueChecked(false);
		setruptureDeLaFamile();
		setruptureDeLaFamileChecked(false);
		setconflitDomestique();
		setconflitDomestiqueChecked(false);
		setabondanParLaFamille();
		setabondanParLaFamilleChecked(false);
		setstigmatisation();
		setstigmatisationChecked(false);
		setperteDeLaGarde();
		setperteDeLaGardeChecked(false);
		setisolementDeLaVictime();
		setisolementDeLaVictimeChecked(false);
		setautreImpactSurLesRelation();
		setautreImpactSurLesRelationChecked(false);
		setmere();
		setmereChecked(false);
		setpere();
		setpereChecked(false);
		setgardienHomme();
		setgardienHommeChecked(false);
		setgardienneFemme();
		setgardienneFemmeChecked(false);
		setpropreFrere();
		setpropreFrereChecked(false);
		setpropreSoeur();
		setpropreSoeurChecked(false);
		setpropreEnfantAdulte();
		setpropreEnfantAdulteChecked(false);
		setgdMere();
		setgdMereChecked(false);
		setbelleMere();
		setbelleMereChecked(false);
		setbeauPere();
		setbeauPereChecked(false);
		setbeauxFere();
		setbeauxFereChecked(false);
		setbelleSoeur();
		setbelleSoeurChecked(false);
		setoncle();
		setoncleChecked(false);
		settante();
		settanteChecked(false);
		setautreMember();
		setautreMemberChecked(false);
		setmari();
		setmariChecked(false);
		setexmari();
		setexmariChecked(false);
		setepouse();
		setepouseChecked(false);
		setexEpouse();
		setexEpouseChecked(false);
		setpartenaireHomme();
		setpartenaireHommeChecked(false);
		setpartenaireFemme();
		setpartenaireFemmeChecked(false);
		setexPaternaireHomme();
		setexPaternaireHommeChecked(false);
		setexPaternaireFemme();
		setexPaternaireFemmeChecked(false);
		setpatron();
		setpatronChecked(false);
		setpatronne();
		setpatronneChecked(false);
		setpersonnelHome();
		setpersonnelHomeChecked(false);
		setpersonnelFemme();
		setpersonnelFemmeChecked(false);
		setbailleur();
		setbailleurChecked(false);
		setbailleresse();
		setbailleresseChecked(false);
		setautreMemberDuCercle();
		setautreMemberDuCercleChecked(false);
		setq15a();
		setq15achecked(false);
		setq15b();
		setq15bchecked(false);
		setq16a();
		setq16achecked(false);
		setq16b();
		setq16bschecked(false);
		setq18a();
		setq18achecked(false);
		setq18b();
		setq18bchecked(false);
	};
	//
	const list = (anchor) => (
		<div
			className={clsx(classes.list, {
				[classes.fullList]: anchor === "top" || anchor === "bottom",
			})}
			role="presentation"
			//   onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<Grid Container spacing={2}>
				<List
					className={
						(q6achecked === true ||
							q6bchecked === true ||
							q6cchecked === true ||
							q6dchecked === true ||
							q6echecked === true ||
							q6fchecked === true ||
							q7femchecked === true ||
							q7mascchecked === true ||
							q7autrchecked === true ||
							q8aCelchecked === true ||
							q8aUnionchecked === true ||
							q8aMariechecked === true ||
							q8aSeparechecked === true ||
							q8aDivorcechecked === true ||
							q8aVeufchecked === true ||
							q8blgbtichecked === true ||
							q8cHandicapchecked === true ||
							q8dVictimechecked === true ||
							q8dVictimeSanschecked === true ||
							violChecked === true ||
							conjChecked === true ||
							agressioChecked === true ||
							coerChecked === true ||
							harcChecked === true ||
							attouChecked === true ||
							etrForChecked === true ||
							etrEmpChecked === true ||
							autreViolSexuelChecked === true ||
							meutrChecked === true ||
							coupDePoingChecked === true ||
							coupDePiedChecked === true ||
							attaqueChecked === true ||
							attaqueACouteauChecked === true ||
							attaqueAEauxChecked === true ||
							privationChecked === true ||
							privationDeNouritureChecked === true ||
							negligencePhyDenfantChecked === true ||
							negligencePhyPersonChecked === true ||
							destructionDobjectChecked === true ||
							ritesDeVeuvageChecked === true ||
							autreViolPhysiqueChecked === true ||
							menaceChecked === true ||
							injureChecked === true ||
							negligenceEmotionnelChecked === true ||
							humiliationFaceChecked === true ||
							attaqueVisantChecked === true ||
							isolementDeLaFamilleChecked === true ||
							controleDeMouvementChecked === true ||
							controleDeLaCommunicationChecked === true ||
							refusDeDivorceChecked === true ||
							abusSpirituelChecked === true ||
							autreViolPsychologiqueChecked === true ||
							abondonDeLaFamilleChecked === true ||
							confiscationDeRevenueChecked === true ||
							interdictionDeParticipationChecked === true ||
							restrictionDeLAccessDEducationChecked === true ||
							restrictionDeLaccessDeLemploiChecked === true ||
							restrictionDeLAccessAuxRessourceChecked === true ||
							privationDePriseChangeChecked === true ||
							leviratChecked === true ||
							perterDeProprieteChecked === true ||
							perterDeLenfantChecked === true ||
							autreViolEconomiqueChecked === true ||
							q12bandachecked === true ||
							q12barumbuchecked === true ||
							q12bumbuchecked === true ||
							q12Gombechecked === true ||
							q12Kalamuchecked ||
							q12kasachecked === true ||
							q12Kimbachecked === true ||
							q12kinshasachecked === true ||
							q12kintambochecked === true ||
							q12kisensochecked === true ||
							q12lembachecked === true ||
							q12limetechecked === true ||
							q12Lingwalachecked === true ||
							q12Makalachecked === true ||
							q12Malukuchecked === true ||
							q12masinachecked === true ||
							q12matetechecked === true ||
							q12montNgafulachecked === true ||
							q12ndjilichecked === true ||
							q12ngabachecked === true ||
							q12ngaliemachecked === true ||
							q12ngirichecked === true ||
							q12nselechecked === true ||
							q12selembaochecked === true ||
							q12buniachecked === true ||
							q12aruchecked === true ||
							q12djuguchecked === true ||
							q12irumuchecked === true ||
							q12mahagichecked === true ||
							q12mambasachecked === true ||
							q12kanangachecked === true ||
							q12dembachecked === true ||
							q12dibayachecked === true ||
							q12dimbelengechecked === true ||
							q12kazumbachecked === true ||
							q12luizachecked === true ||
							q12mbujichecked === true ||
							q12kabeyachecked === true ||
							q12katandachecked === true ||
							q12lupatapatachecked === true ||
							q12miabichecked === true ||
							q12tshilengechecked === true ||
							q12kinduchecked === true ||
							q12kambarechecked === true ||
							q12kailochecked === true ||
							q12kasongochecked === true ||
							q12kibombochecked === true ||
							q12lubutuchecked === true ||
							q12pangichecked === true ||
							q12puniachecked === true ||
							q12benivilechecked === true ||
							q12butembochecked === true ||
							q12gomachecked === true ||
							q12benichecked === true ||
							q12luberochecked === true ||
							q12masisichecked === true ||
							q12nyiragongochecked === true ||
							q12rutshuruchecked === true ||
							q12walikalechecked === true ||
							q12bukavuchecked === true ||
							q12fizichecked === true ||
							q12idjwichecked === true ||
							q12kabarechecked === true ||
							q12kalehechecked === true ||
							q12mwengachecked === true ||
							q12shabundachecked === true ||
							q12uvirachecked === true ||
							q12walunguchecked === true ||
							q12kisanganichecked === true ||
							q12bafwasendechecked === true ||
							q12banaliachecked === true ||
							q12basokochecked === true ||
							q12isangichecked === true ||
							q12opalachecked === true ||
							q12ubunduchecked === true ||
							q12yahumachecked === true ||
							decesChecked === true ||
							maladieChecked === true ||
							blessureChecked === true ||
							douleurChecked === true ||
							troubleGynChecked === true ||
							handicapChecked === true ||
							grossesseChecked === true ||
							perteDeLenfantANaitreChecked === true ||
							abusDAlcoolsChecked === true ||
							abusDeDroguesChecked === true ||
							abusDeMedicamentsChecked === true ||
							autrePrejudicePhysiqueChecked === true ||
							risqueDeSuicideChecked === true ||
							peurEtAnxieteChecked === true ||
							depressionChecked === true ||
							detresseChecked === true ||
							sentimentDeHonteChecked === true ||
							sentimentDeColereChecked === true ||
							troubleDeSommeilChecked === true ||
							troubleAlimentairesChecked === true ||
							peeteDEspoireChecked === true ||
							manqueDeConfianceChecked === true ||
							autrePrejudiceEmotionnelChecked === true ||
							perterDeMoyensChecked === true ||
							perterDeRevenuesChecked === true ||
							sexeDeSurvieChecked === true ||
							perteDeMaisonsChecked === true ||
							demanegementFrequentChecked === true ||
							situationDeSanSAbriChecked === true ||
							pasOuPeuChecked === true ||
							autrePrejudiceEconomiqueChecked === true ||
							ruptureDeLaFamileChecked === true ||
							abondanParLaFamilleChecked === true ||
							stigmatisationChecked === true ||
							perteDeLaGardeChecked === true ||
							isolementDeLaVictimeChecked === true ||
							autreImpactSurLesRelationChecked === true ||
							mereChecked === true ||
							pereChecked === true ||
							gardienHommeChecked === true ||
							gardienneFemmeChecked === true ||
							propreFrereChecked === true ||
							propreSoeurChecked === true ||
							propreEnfantAdulteChecked === true ||
							gdMereChecked === true ||
							gdPereChecked === true ||
							belleMereChecked === true ||
							beauPereChecked === true ||
							beauxFereChecked === true ||
							belleSoeurChecked === true ||
							oncleChecked === true ||
							tanteChecked === true ||
							autreMemberChecked === true ||
							mariChecked === true ||
							exmariChecked === true ||
							epouseChecked === true ||
							exEpouseChecked === true ||
							partenaireHommeChecked === true ||
							partenaireFemmeChecked === true ||
							exPaternaireHommeChecked === true ||
							exPaternaireFemmeChecked === true ||
							patronChecked === true ||
							patronneChecked === true ||
							personnelHomeChecked === true ||
							personnelFemmeChecked === true ||
							bailleurChecked === true ||
							bailleresseChecked === true ||
							autreMemberDuCercleChecked === true ||
							q15achecked === true ||
							q15bchecked === true ||
							q16achecked === true ||
							q16bschecked === true ||
							q18achecked === true ||
							q18bchecked === true) &&
						filterChecked === true
							? classes.filterHeightWebMobile
							: classes.filterHeight
					}
				>
					<Grid item xs={12} sm={12} style={{padding: 10}}>
						<Grid
							container
							direction="row"
							justifycontent="center"
							alignitems="center"
							// style={{
							//   marginTop: "20px",
							// }}
						>
							<Grid item>
								<Typography
									style={{ fontWeight: 400, textTransform: "uppercase", paddingLeft: 10 }}
								>
									{t("filter")}
								</Typography>
							</Grid>
						</Grid>
						<List>
							{/*  */}
							<ListItem>
								<Grid
									container
									style={{
										marginTop: "20px",
									}}
								>
									<Typography
										style={{
											fontWeight: 400,
											textTransform: "uppercase",
											backgroundColor: "#E0F3F5",
											width: "auto",
											padding: 10,
										}}
									>
										{t("filter-case-group")}
									</Typography>
								</Grid>
							</ListItem>
							{/*  */}
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={q6bBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											q6bvalue === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("filter-q6-b")}
									</Button>
								</Grid>
								{q6bvalue === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={q6achecked}
															value={"0-5 ans"}
															onClick={handleq6a}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q6-b-5")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q6bchecked}
															onClick={handleq6b}
															value={"6-17 ans"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q6-b-17")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q6cchecked}
															onClick={handleq6c}
															value={"18-29 ans"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q6-b-29")}
														</span>
													}
												/>
												<FormControlLabel
													size="small"
													control={
														<Checkbox
															checked={q6dchecked}
															onClick={handleq6d}
															value={"30-49 ans"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q6-b-49")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q6echecked}
															onClick={handleq6e}
															value={"50-64 ans"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q6-b-64")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q6fchecked}
															onClick={handleq6f}
															value={"65 ans et plus"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q6-b-65")}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={q7Box}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											q7value === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("filter-q7")}
									</Button>
								</Grid>
								{q7value === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={q7femchecked}
															onClick={handleq7fem}
															value={"Féminin"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q7-fem")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q7mascchecked}
															onClick={handleq7masc}
															value={"Masculin"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q7-masc")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q7autrchecked}
															onClick={handleq7autr}
															value={"Autre"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q7-autre")}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							{/*  */}
							<ListItem>
								<Grid
									container
									style={{
										marginTop: "20px",
									}}
								>
									<Typography
										style={{
											fontWeight: 400,
											textTransform: "uppercase",
											backgroundColor: "#E0F3F5",
											width: "auto",
											padding: 1,
										}}
									>
										{t("filter-q8")}
									</Typography>
								</Grid>
							</ListItem>
							{/*  */}
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={q8aBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											q8avalue === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("filter-q8-a")}
									</Button>
								</Grid>
								{q8avalue === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={q8aCelchecked}
															onClick={handleq8aCel}
															value={"Célibataire"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q8-a-sing")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q8aUnionchecked}
															onClick={handleq8aUnion}
															value={
																"Union de fait (vivre ensemble sans être mariés)"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q8-a-com")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q8aMariechecked}
															onClick={handleq8aMarie}
															value={"Marié(e)"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q8-a-mar")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q8aSeparechecked}
															onClick={handleq8aSepare}
															value={"Séparé(e)"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q8-a-sep")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q8aDivorcechecked}
															onClick={handleq8aDivorce}
															value={"Divorcé(e)"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q8-a-div")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q8aVeufchecked}
															onClick={handleq8aVeuf}
															value={"Veuve / Veuf"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q8-a-widow")}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={q8bBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											q8bvalue === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("filter-q8-b")}
									</Button>
								</Grid>
								{q8bvalue === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={q8blgbtichecked}
															onClick={handleq8blgbti}
															value={"Oui"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q8-b")}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={q8cBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											q8cvalue === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("filter-q8-c")}
									</Button>
								</Grid>
								{q8cvalue === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={q8cHandicapchecked}
															onClick={handleq8cHandicap}
															value={"Oui"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q8-c")}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={q8dBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											q8dvalue === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("filter-q8-d")}
									</Button>
								</Grid>
								{q8dvalue === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={q8dVictimechecked}
															onClick={handleq8dVictime}
															value={"Oui"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q8-d-a")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q8dVictimeSanschecked}
															onClick={handleq8dVictimeSans}
															value={"Non"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q8-d-b")}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							{/*  */}
							<ListItem>
								<Grid
									container
									style={{
										marginTop: "20px",
									}}
								>
									<Typography
										style={{
											fontWeight: 400,
											textTransform: "uppercase",
											backgroundColor: "#E0F3F5",
											width: "auto",
											padding: 10,
										}}
									>
										{t("case-statistic-type-violence")}
									</Typography>
								</Grid>
							</ListItem>
							{/*  */}
							<Grid
								container
								style={{
									marginTop: "20px", 
								}}
							>
								<Button
									onClick={q10Boxa}
									variant="text"
									size="small"
									color="default"
									style={{
										fontWeight: 300,
										fontSize: "14px",
										textTransform: "unset",
										textAlign: "left",
									}}
									className={classes.button}
									startIcon={
										q10value === false ? (
											<ArrowRightIcon style={{ fontSize: 24 }} />
										) : (
											<ArrowDropDownIcon style={{ fontSize: 24 }} />
										)
									}
								>
									{t("case-statistic-violence-categorie1")}
								</Button>
								{q10value === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={violChecked}
															onClick={q10Viol}
															value={"Viol"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-sex-rape")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={conjChecked}
															onClick={q10Conjugal}
															value={
																"Viol conjugal entre couples mariés et non mariés"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-sex-mar")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={agressioChecked}
															onClick={q10Agression}
															value={"Agression sexuelle sur mineur"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-sex-sexual")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={coerChecked}
															onClick={q10Coercition}
															value={"Coercition sexuelle"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-sex-coe")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={harcChecked}
															onClick={q10Harcelement}
															value={"Harcèlement sexuel"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-sex-har")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={attouChecked}
															onClick={q10Attouchements}
															value={"Attouchements inappropriés"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-incident-of-sex-inappropriete"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={etrForChecked}
															onClick={q10etreForces}
															value={
																"Forcer la victime à avoir des relations sexuelles sans préservatif"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-sex-forcing")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={etrEmpChecked}
															onClick={q10etreEmpeches}
															value={
																"Empêcher la victime de faire ses propres choix quant à la décision d'avoir ou non un bébé"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-sex-preventing")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={autreViolSexuelChecked}
															onClick={q10autreViolSexuels}
															value={"Autres"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-sex-other")}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid>
									<Button
										onClick={q10bBoxa}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											q10bvalue === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("case-statistic-violence-categorie2")}
									</Button>
								</Grid>
								{q10bvalue === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={meutrChecked}
															onClick={q10Meutre}
															value={"Meurtre / féminicide"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-phys-mur")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={coupDePoingChecked}
															onClick={q10coupDePoing}
															value={"Coups de poing et/ou coups de pieds"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-phys-punch")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={coupDePiedChecked}
															onClick={q10coupDePied}
															value={"Coups portés avec des objets"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-incident-of-phys-punchwithobj"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={attaqueChecked}
															onClick={q10attaque}
															value={"Attaque à l’acide"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-phys-acidattack")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={attaqueACouteauChecked}
															onClick={q10attaqueACouteau}
															value={"Attaque au couteau ou à la machette"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-incident-of-phys-attackwithknife"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={attaqueAEauxChecked}
															onClick={q10attaqueAEaux}
															value={
																"Attaque à l’eau chaude, à l’huile chaude ou autre objet chaud"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-incident-of-phys-attackwithhotwater"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={privationChecked}
															onClick={q10privation}
															value={
																"Privation de la liberté physique de la victime"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-phys-deprivation")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={privationDeNouritureChecked}
															onClick={q10privationDeNouriture}
															value={"Privation de nourriture de la victime"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-incident-of-phys-fooddeprivation"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={negligencePhyDenfantChecked}
															onClick={q10negligencePhyDenfant}
															value={
																"Négligence physique d’enfant (si la victime est un enfant)"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-incident-of-phys-physneglofchild"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={negligencePhyPersonChecked}
															onClick={q10negligencePhyPerson}
															value={
																"Négligence physique de personnes âgées (si la victime est une personne âgée)"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-incident-of-phys-physneglofelder"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={destructionDobjectChecked}
															onClick={q10destructionDobject}
															value={
																"Destruction d’objets dans la maison de la victime"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-phys-destr")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={ritesDeVeuvageChecked}
															onClick={q10ritesDeVeuvage}
															value={
																"Rites de veuvage affectant la santé physique et mentale de la victime"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-phys-risk")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={autreViolPhysiqueChecked}
															onClick={q10autreViolPhysique}
															value={"Autres"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-phys-other")}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Button
									onClick={q10cBoxa}
									variant="text"
									size="small"
									color="default"
									style={{
										fontWeight: 300,
										fontSize: "14px",
										textTransform: "unset",
										textAlign: "left",
									}}
									className={classes.button}
									startIcon={
										q10cvalue === false ? (
											<ArrowRightIcon style={{ fontSize: 24 }} />
										) : (
											<ArrowDropDownIcon style={{ fontSize: 24 }} />
										)
									}
								>
									{t("case-statistic-violence-categorie3")}
								</Button>
								{q10cvalue === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={menaceChecked}
															onClick={q10menace}
															value={"Menaces"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-emot-threat")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={injureChecked}
															onClick={q10injure}
															value={"Injures"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-emot-insult")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={negligenceEmotionnelChecked}
															onClick={q10negligenceEmotionnel}
															value={"Négligence émotionnelle"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-emot-emot")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={humiliationFaceChecked}
															onClick={q10humiliationFace}
															value={
																"Humiliation de la victime face aux autres"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-emot-hum")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={attaqueVisantChecked}
															onClick={q10attaqueVisant}
															value={
																"Attaques visant à nuire à la réputation de la victime"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-incident-of-emot-victimreputatio"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={isolementDeLaFamilleChecked}
															onClick={q10isolementDeLaFamille}
															value={
																"Isolement de la victime de sa famille d’origine ou ses amis"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-emot-isol")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={controleDeMouvementChecked}
															onClick={q10controleDeMouvement}
															value={"Contrôle des mouvements de la victime"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-emot-ctrmov")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={controleDeLaCommunicationChecked}
															onClick={q10controleDeLaCommunication}
															value={
																"Contrôle de la communication de la victime"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-emot-ctrcomm")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={refusDeDivorceChecked}
															onClick={q10refusDeDivorce}
															value={
																"Refus de divorce malgré le souhait de la victime de se divorcer"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-emot-refus")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={abusSpirituelChecked}
															onClick={q10abusSpirituel}
															value={
																"Abus spirituel / abus religieux de la victime"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-emot-spiritual")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={autreViolPsychologiqueChecked}
															onClick={q10autreViolPsychologique}
															value={"Autres"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-emot-other")}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Button
									onClick={q10dBoxa}
									variant="text"
									size="small"
									color="default"
									style={{
										fontWeight: 300,
										fontSize: "14px",
										textTransform: "unset",
										textAlign: "left",
									}}
									className={classes.button}
									startIcon={
										q10dvalue === false ? (
											<ArrowRightIcon style={{ fontSize: 24 }} />
										) : (
											<ArrowDropDownIcon style={{ fontSize: 24 }} />
										)
									}
								>
									{t("case-statistic-violence-categorie4")}
								</Button>
								{q10dvalue === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={abondonDeLaFamilleChecked}
															onClick={q10abondonDeLaFamille}
															value={"L’auteur abandonne ou chasse la victime"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-ecom-perp")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={confiscationDeRevenueChecked}
															onClick={q10confiscationDeRevenue}
															value={"Confiscation des revenus de la victime"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-ecom-forf")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={interdictionDeParticipationChecked}
															onClick={q10interdictionDeParticipation}
															value={
																"Interdiction de participation à la gestion des revenus du ménage"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-ecom-prohibitio")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={restrictionDeLAccessDEducationChecked}
															onClick={q10restrictionDeLAccessDEducation}
															value={"Restriction de l’accès à l'éducation"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-incident-of-ecom-restreducatio"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={restrictionDeLaccessDeLemploiChecked}
															onClick={q10restrictionDeLaccessDeLemploi}
															value={"Restriction de l’accès à l'emploi"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-ecom-restremploy")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={restrictionDeLAccessAuxRessourceChecked}
															onClick={q10restrictionDeLAccessAuxRessource}
															value={
																"Restriction de l’accès aux ressources économiques"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-ecom-restrecom")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={privationDePriseChangeChecked}
															onClick={q10privationDePriseChange}
															value={
																"Privation d’une prise en charge médicale, de médicaments ou d'appareils d’assistance"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-ecom-depriv")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={leviratChecked}
															onClick={q10levirat}
															value={"Lévirat après la mort du conjoint"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-ecom-levirat")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={perterDeProprieteChecked}
															onClick={q10perterDePropriete}
															value={
																"Perte des propriétés et des biens / non accès à l’héritage après la mort du conjoint ou d’un parent"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-incident-of-ecom-lossofproperty"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={perterDeLenfantChecked}
															onClick={q10perterDeLenfant}
															value={
																"Perte des enfants après la mort du conjoint"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-incident-of-ecom-lossofchildren"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={autreViolEconomiqueChecked}
															onClick={q10autreViolEconomique}
															value={"Autres"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-incident-of-ecom-other")}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<ListItem>
								<Grid
									container
									style={{
										marginTop: "20px",
									}}
								>
									<Typography
										style={{
											fontWeight: 400,
											textTransform: "uppercase",
											backgroundColor: "#E0F3F5",
											width: "auto",
											padding: 10,
										}}
									>
										{t("case-statistic-place-of-the-act-viol")}
									</Typography>
								</Grid>
							</ListItem>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={q12aBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											q12avalue === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("case-statistic-city-kin")}
									</Button>
								</Grid>
								{q12avalue === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12bandachecked}
															onClick={handleq12banda}
															value={"Bandalungwa"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Bandalungwa
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12barumbuchecked}
															onClick={handleq12barumbus}
															value={"Barumbu"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Barumbu
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12bumbuchecked}
															onClick={handleq12bumbu}
															value={"Bumbu"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Bumbu
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12Gombechecked}
															onClick={handleq12Gombe}
															value={"Gombe"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Gombe
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12Kalamuchecked}
															onClick={handleq12Kalamu}
															value={"Kalamu"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Kalamu
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12kasachecked}
															onClick={handleq12kasa}
															value={"Kasa-Vubu"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Kasa-Vubu
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12Kimbachecked}
															onClick={handleq12Kimba}
															value={"Kimbanseke"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Kimbanseke
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12kinshasachecked}
															onClick={handleq12kinshasa}
															value={"Kinshasa"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Kinshasa
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12kintambochecked}
															onClick={handleq12kintambo}
															value={"Kintambo"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Kintambo
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12kisensochecked}
															onClick={handleq12kisenso}
															value={"Kisenso"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Kisenso
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12lembachecked}
															onClick={handleq12lemba}
															value={"Lemba"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Lemba
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12limetechecked}
															onClick={handleq12limete}
															value={"Limete"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Limete
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12Lingwalachecked}
															onClick={handleq12Lingwala}
															value={"Lingwala"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Lingwala
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12Makalachecked}
															onClick={handleq12Makala}
															value={"Makala"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Makala
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12Malukuchecked}
															onClick={handleq12Maluku}
															value={"Maluku"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Maluku
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12masinachecked}
															onClick={handleq12masina}
															value={"Masina"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Masina
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12matetechecked}
															onClick={handleq12matete}
															value={"Matete"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Matete
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12montNgafulachecked}
															onClick={handleq12montNgafula}
															value={"Mont Ngafula"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Mont Ngafula
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12ndjilichecked}
															onClick={handleq12ndjili}
															value={"Ndjili"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Ndjili
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12ngabachecked}
															onClick={handleq12ngaba}
															value={"Ngaba"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Ngaba
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12ngaliemachecked}
															onClick={handleq12ngaliema}
															value={"Ngaliema"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Ngaliema
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12ngirichecked}
															onClick={handleq12ngiri}
															value={"Ngiri-Ngiri"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Ngiri-Ngiri
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12nselechecked}
															onClick={handleq12nsele}
															value={"Nsele"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Nsele
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12selembaochecked}
															onClick={handleq12selembao}
															value={"Selembao"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Selembao
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={ituriBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											ituri === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										Ituri
									</Button>
								</Grid>
								{ituri === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12buniachecked}
															onClick={handleq12bunia}
															value={"Bunia"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Bunia
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12aruchecked}
															onClick={handleq12aru}
															value={"Aru"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Aru
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12djuguchecked}
															onClick={handleq12djugu}
															value={"Djugu"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Djugu
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12irumuchecked}
															onClick={handleq12irumu}
															value={"Irumu"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Irumu
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12mahagichecked}
															onClick={handleq12mahagi}
															value={"Mahagi"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Mahagi
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12mambasachecked}
															onClick={handleq12mambasa}
															value={"Mambasa"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Mambasa
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={kasaicentralBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											kasaicentral === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										Kasaï-Central
									</Button>
								</Grid>
								{kasaicentral === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12kanangachecked}
															onClick={handleq12kananga}
															value={"Kananga"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Kananga
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12dembachecked}
															onClick={handleq12demba}
															value={"Demba"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Demba
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12dibayachecked}
															onClick={handleq12dibaya}
															value={"Dibaya"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Dibaya
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12dimbelengechecked}
															onClick={handleq12dimbelenge}
															value={"Dimbelenge"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Dimbelenge
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12kazumbachecked}
															onClick={handleq12kazumba}
															value={"Kazumba"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Kazumba
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12luizachecked}
															onClick={handleq12luiza}
															value={"Luiza"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Luiza
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={kasaioirientalBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											kasaioriental === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										Kasaï Oriental
									</Button>
								</Grid>
								{kasaioriental === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12mbujichecked}
															onClick={handleq12mbuji}
															value={"Mbuji-Mayi"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Mbuji-Mayi
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12kabeyachecked}
															onClick={handleq12kabeya}
															value={"Kabeya-Kamwanga"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Kabeya-Kamwanga
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12katandachecked}
															onClick={handleq12katanda}
															value={"Katanda"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Katanda
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12lupatapatachecked}
															onClick={handleq12lupatapata}
															value={"Lupatapata"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Lupatapata
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12miabichecked}
															onClick={handleq12miabi}
															value={"Miabi"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Miabi
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12tshilengechecked}
															onClick={handleq12tshilenge}
															value={"Tshilenge"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Tshilenge
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={maniemaBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											maniema === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										Maniema
									</Button>
								</Grid>
								{maniema === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12kinduchecked}
															onClick={handleq12kindu}
															value={"Kindu"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Kindu
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12kambarechecked}
															onClick={handleq12kambare}
															value={"Kabambare"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Kabambare
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12kailochecked}
															onClick={handleq12kailo}
															value={"Kailo"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Kailo
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12kasongochecked}
															onClick={handleq12kasongo}
															value={"Kasongo"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Kasongo
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12kibombochecked}
															onClick={handleq12kibombo}
															value={"Kibombo"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Kibombo
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12lubutuchecked}
															onClick={handleq12lubutu}
															value={"Lubutu"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Lubutu
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12pangichecked}
															onClick={handleq12pangi}
															value={"Pangi"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Pangi
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12puniachecked}
															onClick={handleq12punia}
															value={"Punia"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Punia
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={nordkivuBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											nordkivu === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("North Kivu")}
									</Button>
								</Grid>
								{nordkivu === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12benivilechecked}
															onClick={handleq12benivile}
															value={"Beni (Ville)"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Beni{t("case-city")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12butembochecked}
															onClick={handleq12butembo}
															value={"Butembo"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Butembo
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12gomachecked}
															onClick={handleq12goma}
															value={"Goma"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Goma
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12benichecked}
															onClick={handleq12beni}
															value={"Beni (Territoire)"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Beni{t("case-territory")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12luberochecked}
															onClick={handleq12lubero}
															value={"Lubero"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Lubero
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12masisichecked}
															onClick={handleq12masisi}
															value={"Masisi"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Masisi
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12nyiragongochecked}
															onClick={handleq12nyiragongo}
															value={"Nyiragongo"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Nyiragongo
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12rutshuruchecked}
															onClick={handleq12rutshuru}
															value={"Rutshuru"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Rutshuru
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12walikalechecked}
															onClick={handleq12walikale}
															value={"Walikale"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Walikale
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={sudkivuBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											sudkivu === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("sud-kivu")}
									</Button>
								</Grid>
								{sudkivu === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12bukavuchecked}
															onClick={handleq12bukavu}
															value={"Bukavu"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Bukavu
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12fizichecked}
															onClick={handleq12fizi}
															value={"Fizi"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Fizi
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12idjwichecked}
															onClick={handleq12idjwi}
															value={"Idjwi"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Idjwi
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12kabarechecked}
															onClick={handleq12kabare}
															value={"Kabare"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Kabare
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12kalehechecked}
															onClick={handleq12kalehe}
															value={"Kalehe"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Kalehe
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12mwengachecked}
															onClick={handleq12mwenga}
															value={"Mwenga"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Mwenga
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12shabundachecked}
															onClick={handleq12shabunda}
															value={"Shabunda"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Shabunda
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12uvirachecked}
															onClick={handleq12uvira}
															value={"Uvira"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Uvira
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12walunguchecked}
															onClick={handleq12walungu}
															value={"Walungu"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Walungu
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={tshopoBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											tshopo === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										Tshopo
									</Button>
								</Grid>
								{tshopo === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12kisanganichecked}
															onClick={handleq12kisangani}
															value={"Kisangani"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Kisangani
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12bafwasendechecked}
															onClick={handleq12bafwasende}
															value={"Bafwasende"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Bafwasende
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12banaliachecked}
															onClick={handleq12banalia}
															value={"Banalia"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Banalia
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12basokochecked}
															onClick={handleq12basoko}
															value={"Basoko"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Basoko
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12isangichecked}
															onClick={handleq12isangi}
															value={"Isangi"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Isangi
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12opalachecked}
															onClick={handleq12opala}
															value={"Opala"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Opala
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12ubunduchecked}
															onClick={handleq12ubundu}
															value={"Ubundu"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Ubundu
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q12yahumachecked}
															onClick={handleq12yahuma}
															value={"Yahuma"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															Yahuma
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							{/* <Grid
              container
              style={{
                marginTop: "20px",
              }}
            >
              <Grid container>
                <Button
                  onClick={q12aBox}
                  variant="text"
                  size="small"
                  color="default"
                  style={{
                    fontWeight:300,
                    fontSize: "14px",
                    textTransform: "unset",
                    textAlign: "left",
                  }}
                  className={classes.button}
                  startIcon={
                    q12avalue === false ? (
                      <ArrowRightIcon style={{ fontSize: 24 }} />
                    ) : (
                      <ArrowDropDownIcon style={{ fontSize: 24 }} />
                    )
                  }
                >
                  Milieu où l’acte de violence domestique a eu lieu
                </Button>
              </Grid>
              {q12avalue === true ? (
                <List>
                  <ListItem>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox value={"I"} />}
                        label={
                          <span style={{ fontSize: "13px",fontWeight:300 }}>Milieu urbain</span>
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox value={"I"} />}
                        label={
                          <span style={{ fontSize: "13px",fontWeight:300 }}>
                            Milieu semi-urbain
                          </span>
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox value={"I"} />}
                        label={
                          <span style={{ fontSize: "13px",fontWeight:300 }}>Milieu rural</span>
                        }
                      />
                    </FormGroup>
                  </ListItem>
                </List>
              ) : null}
            </Grid> */}
							<ListItem>
								<Grid
									container
									style={{
										marginTop: "20px",
									}}
								>
									<Typography
										style={{
											fontWeight: 400,
											textTransform: "uppercase",
											backgroundColor: "#E0F3F5",
											width: "auto",
											padding: 10,
										}}
									>
										{t("case-statistic-categ-of-harm-caused")}
									</Typography>
								</Grid>
							</ListItem>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={q13bBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											q13bvalue === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("case-statistic-categ-of-harm-phys")}
									</Button>
								</Grid>
								{q13bvalue === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={decesChecked}
															onClick={q13deces}
															value={"Décès"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-phys-death")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={maladieChecked}
															onClick={q13maladie}
															value={"Maladies (par exemple hypertension)"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-phys-ill")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={blessureChecked}
															onClick={q13blessure}
															value={"Blessures"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-phys-inj")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={douleurChecked}
															onClick={q13douleur}
															value={"Douleurs"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-phys-pain")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={troubleGynChecked}
															onClick={q13troubleGyn}
															value={"Troubles gynécologiques et reproductifs"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-phys-gyn")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={handicapChecked}
															onClick={q13handicap}
															value={"Invalidité / Handicap"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-phys-disa")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={grossesseChecked}
															onClick={q13grossesse}
															value={"Grossesse non voulue"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-phys-uni")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={perteDeLenfantANaitreChecked}
															onClick={q13perteDeLenfantANaitre}
															value={"Perte de l'enfant à naître"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-phys-loss")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={abusDAlcoolsChecked}
															onClick={q13abusDAlcools}
															value={"Abus d'alcool"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-phys-alcool")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={abusDeDroguesChecked}
															onClick={q13abusDeDrogues}
															value={"Abus de drogues"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-phys-drug")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={abusDeMedicamentsChecked}
															onClick={q13abusDeMedicaments}
															value={"Abus de médicaments"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-phys-med")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={autrePrejudicePhysiqueChecked}
															onClick={q13autrePrejudicePhysique}
															value={"Autres"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-phys-oth")}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={q13bbBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											q13bbvalue === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("case-statistic-categ-of-harm-em")}
									</Button>
								</Grid>
								{q13bbvalue === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={risqueDeSuicideChecked}
															onClick={q13risqueDeSuicide}
															value={"Risque de suicide"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-em-risk")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={peurEtAnxieteChecked}
															onClick={q13peurEtAnxiete}
															value={"Peur et anxiété"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-em-fear")}{" "}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={depressionChecked}
															onClick={q13depression}
															value={"Dépression"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-em-depr")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={detresseChecked}
															onClick={q13detresse}
															value={"Détresse émotionnelle"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-em-em")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={sentimentDeHonteChecked}
															onClick={q13sentimentDeHonte}
															value={"Sentiment de honte'"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-categ-of-harm-em-feelings-shame"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={sentimentDeColereChecked}
															onClick={q13sentimentDeColere}
															value={"Sentiment de colère"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-categ-of-harm-em-feelings-anger"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={troubleDeSommeilChecked}
															onClick={q13troubleDeSommeil}
															value={"Troubles de sommeil"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-em-sleep-dis")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={troubleAlimentairesChecked}
															onClick={q13troubleAlimentaires}
															value={"Troubles alimentaires"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-em-eat")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={peeteDEspoireChecked}
															onClick={q13peeteDEspoire}
															value={"Perte d’espoir"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-em-loss")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={manqueDeConfianceChecked}
															onClick={q13manqueDeConfiance}
															value={"Manque de confiance en soi"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-em-lack")}
														</span>
													}
												/>

												<FormControlLabel
													control={
														<Checkbox
															checked={autrePrejudiceEmotionnelChecked}
															onClick={q13autrePrejudiceEmotionnel}
															value={"Autres"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-em-other")}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={q13bcBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											q13bcvalue === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("case-statistic-categ-of-harm-econ")}
									</Button>
								</Grid>
								{q13bcvalue === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={perterDeMoyensChecked}
															onClick={q13perterDeMoyens}
															value={
																"Perte ou manque des moyens de subsistance"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-econ-loss")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={perterDeRevenuesChecked}
															onClick={q13perterDeRevenues}
															value={"Perte ou manque de revenus"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-econ-incom")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={sexeDeSurvieChecked}
															onClick={q13sexeDeSurvie}
															value={"Sexe de survie"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-econ-surv")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={perteDeMaisonsChecked}
															onClick={q13perteDeMaisons}
															value={"Perte de maison"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-econ-home")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={demanegementFrequentChecked}
															onClick={q13demanegementFrequent}
															value={"Déménagements fréquents"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-econ-freq")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={situationDeSanSAbriChecked}
															onClick={q13situationDeSanSAbri}
															value={"Situation de sans-abri"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-econ-homeless")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={pasOuPeuChecked}
															onClick={q13pasOuPeu}
															value={"Pas ou peu d'éducation ou de formation"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-econ-no")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={autrePrejudiceEconomiqueChecked}
															onClick={q13autrePrejudiceEconomique}
															value={"Autres"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-econ-other")}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={q13bdBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											q13bdvalue === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("case-statistic-categ-of-harm-impact")}
									</Button>
								</Grid>
								{q13bdvalue === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={ruptureDeLaFamileChecked}
															onClick={q13ruptureDeLaFamile}
															value={
																"Rupture de la famille (famille créée par la victime et l’auteur)"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-impact-fam")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={conflitDomestiqueChecked}
															onClick={q13conflitDomestique}
															value={"Conflits domestiques réguliers"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-impact-regul")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={abondanParLaFamilleChecked}
															onClick={q13abondanParLaFamille}
															value={
																"Abandon de la victime par sa famille d’origine (parents)"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-impact-abn")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={stigmatisationChecked}
															onClick={q13stigmatisation}
															value={
																"Stigmatisation de la victime par la communauté"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-impact-stig")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={perteDeLaGardeChecked}
															onClick={q13perteDeLaGarde}
															value={
																"Perte de la garde ou de contact avec propres enfants"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-impact-loss")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={isolementDeLaVictimeChecked}
															onClick={q13isolementDeLaVictime}
															value={"Isolement social de la victime"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-impact-soc")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={autreImpactSurLesRelationChecked}
															onClick={q13autreImpactSurLesRelation}
															value={"Autres"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-categ-of-harm-impact-other")}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<ListItem>
								<Grid
									container
									style={{
										marginTop: "20px",
									}}
								>
									<Typography
										style={{
											fontWeight: 400,
											textTransform: "uppercase",
											backgroundColor: "#E0F3F5",
											width: "auto",
											padding: 10,
										}}
									>
										{t("case-statistic-perpetrator-categ")} 
									</Typography>
								</Grid>
							</ListItem>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={q14aBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											q14avalue === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("case-statistic-perpetrator-categ-fam")}
									</Button>
								</Grid>
								{q14avalue === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={mereChecked}
															onClick={q14amere}
															value={"Mère"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-perpetrator-categ-fam-mo")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={pereChecked}
															onClick={q14apere}
															value={"Père"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-perpetrator-categ-fam-fa")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={gardienHommeChecked}
															onClick={q14agardienHomme}
															value={"Tuteur / gardien (homme)"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-fam-gua-male"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={gardienneFemmeChecked}
															onClick={q14agardienneFemme}
															value={"Tuteur / gardienne (femme)"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-fam-gual-fem"
															)}
														</span>
													}
												/>

												<FormControlLabel
													control={
														<Checkbox
															checked={propreFrereChecked}
															onClick={q14apropreFrere}
															value={"Propres frères"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-perpetrator-categ-fam-bro")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={propreSoeurChecked}
															onClick={q14apropreSoeur}
															value={"Propres sœurs"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-perpetrator-categ-fam-sist")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={propreEnfantAdulteChecked}
															onClick={q14apropreEnfantAdulte}
															value={"Propres enfants adultes"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-perpetrator-categ-fam-adult")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={gdMereChecked}
															onClick={q14agdMere}
															value={"Grand-mère"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-perpetrator-categ-fam-grdma")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={gdPereChecked}
															onClick={q14agdPere}
															value={"Grand-père"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-perpetrator-categ-fam-grdfa")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={belleMereChecked}
															onClick={q14abelleMere}
															value={"Belle-mère"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-perpetrator-categ-fam-mo-law")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={beauPereChecked}
															onClick={q14abeauPere}
															value={"Beau-père"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-perpetrator-categ-fam-fa-law")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={beauxFereChecked}
															onClick={q14abeauxFere}
															value={"Beaux-frères"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-fam-bro-law"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={belleSoeurChecked}
															onClick={q14abelleSoeur}
															value={"Belles-sœurs"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-fam-sist-law"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={oncleChecked}
															onClick={q14aoncle}
															value={"Oncles"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-perpetrator-categ-fam-uncle")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={tanteChecked}
															onClick={q14atante}
															value={"Tantes"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-perpetrator-categ-fam-aunt")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={autreMemberChecked}
															onClick={q14aautreMember}
															value={
																"Autre(s) membre(s) de la famille de la victime"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("case-statistic-perpetrator-categ-fam-other")}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={q14bBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											q14bvalue === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("case-statistic-perpetrator-categ-spouse")}
									</Button>
								</Grid>
								{q14bvalue === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={mariChecked}
															onClick={q14bmari}
															value={"Mari"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-spouse-husb"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={exmariChecked}
															onClick={q14bexmari}
															value={"Ex-mari"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-spouse-exhusb"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={epouseChecked}
															onClick={q14bepouse}
															value={"Epouse"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-spouse-wife"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={exEpouseChecked}
															onClick={q14bexEpouse}
															value={"Ex-épouse"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-spouse-exwife"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={partenaireHommeChecked}
															onClick={q14bpartenaireHomme}
															value={"Partenaire intime actuel qui est homme"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-spouse-current-male"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={partenaireFemmeChecked}
															onClick={q14bpartenaireFemme}
															value={"Partenaire intime actuel qui est femme"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-spouse-current-female"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={exPaternaireHommeChecked}
															onClick={q14bexPaternaireHomme}
															value={"Ex-partenaire intime qui est homme"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-spouse-ex-intim-male"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={exPaternaireFemmeChecked}
															onClick={q14bexPaternaireFemme}
															value={"Ex-partenaire intime qui est femme"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-spouse-ex-intim-female"
															)}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={q14cBox}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											q14cvalue === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("case-statistic-perpetrator-categ-other")}
									</Button>
								</Grid>
								{q14cvalue === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={patronChecked}
															onClick={q14cpatron}
															value={"Patron (homme) de personnel domestique"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-other-boss-male"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={patronneChecked}
															onClick={q14cpatronne}
															value={"Patronne (femme) de personnel domestique"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-other-boss-female"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={personnelHomeChecked}
															onClick={q14cpersonnelHome}
															value={"Personnel domestique (homme)"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-other-dom-male"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={personnelFemmeChecked}
															onClick={q14cpersonnelFemme}
															value={"Personnel domestique (femme)"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-other-dom-female"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={bailleurChecked}
															onClick={q14cbailleur}
															value={
																"Bailleur / propriétaire de la maison (homme)"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-other-landlord"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={bailleresseChecked}
															onClick={q14cbailleresse}
															value={
																"Bailleresse / propriétaire de la maison (femme)"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-other-landlady"
															)}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={autreMemberDuCercleChecked}
															onClick={q14cautreMemberDuCercle}
															value={
																"Autre(s) membre(s) du cercle restreint de la victime"
															}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t(
																"case-statistic-perpetrator-categ-other-othermember"
															)}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={q15Box}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											q15value === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("filter-q15")}
									</Button>
								</Grid>
								{q15value === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={q15achecked}
															onClick={handleq15a}
															value={"Oui"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q15-a")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q15bchecked}
															onClick={handleq15b}
															value={"Non"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q15-b")}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={q16Box}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											q16value === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("filter-q16")}
									</Button>
								</Grid>
								{q16value === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={q16achecked}
															onClick={handleq16a}
															value={"Oui"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q16-a")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q16bschecked}
															onClick={handleq16b}
															value={"Non"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q16-b")}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
							<Grid
								container
								style={{
									marginTop: "20px",
								}}
							>
								<Grid container>
									<Button
										onClick={q18Box}
										variant="text"
										size="small"
										color="default"
										style={{
											fontWeight: 300,
											fontSize: "14px",
											textTransform: "unset",
											textAlign: "left",
										}}
										className={classes.button}
										startIcon={
											q18value === false ? (
												<ArrowRightIcon style={{ fontSize: 24 }} />
											) : (
												<ArrowDropDownIcon style={{ fontSize: 24 }} />
											)
										}
									>
										{t("filter-q18")}
									</Button>
								</Grid>
								{q18value === true ? (
									<List>
										<ListItem>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={q18achecked}
															onClick={handleq18a}
															value={"Oui"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q18-a")}
														</span>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={q18bchecked}
															onClick={handleq18b}
															value={"Non"}
														/>
													}
													label={
														<span style={{ fontSize: "13px", fontWeight: 300 }}>
															{t("filter-q18-b")}
														</span>
													}
												/>
											</FormGroup>
										</ListItem>
									</List>
								) : null}
							</Grid>
						</List>
					</Grid>
				</List>
				{(q6achecked === true ||
					q6bchecked === true ||
					q6cchecked === true ||
					q6dchecked === true ||
					q6echecked === true ||
					q6fchecked === true ||
					q7femchecked === true ||
					q7mascchecked === true ||
					q7autrchecked === true ||
					q8aCelchecked === true ||
					q8aUnionchecked === true ||
					q8aMariechecked === true ||
					q8aSeparechecked === true ||
					q8aDivorcechecked === true ||
					q8aVeufchecked === true ||
					q8blgbtichecked === true ||
					q8cHandicapchecked === true ||
					q8dVictimechecked === true ||
					q8dVictimeSanschecked === true ||
					violChecked === true ||
					conjChecked === true ||
					agressioChecked === true ||
					coerChecked === true ||
					harcChecked === true ||
					attouChecked === true ||
					etrForChecked === true ||
					etrEmpChecked === true ||
					autreViolSexuelChecked === true ||
					meutrChecked === true ||
					coupDePoingChecked === true ||
					coupDePiedChecked === true ||
					attaqueChecked === true ||
					attaqueACouteauChecked === true ||
					attaqueAEauxChecked === true ||
					privationChecked === true ||
					privationDeNouritureChecked === true ||
					negligencePhyDenfantChecked === true ||
					negligencePhyPersonChecked === true ||
					destructionDobjectChecked === true ||
					ritesDeVeuvageChecked === true ||
					autreViolPhysiqueChecked === true ||
					menaceChecked === true ||
					injureChecked === true ||
					negligenceEmotionnelChecked === true ||
					humiliationFaceChecked === true ||
					attaqueVisantChecked === true ||
					isolementDeLaFamilleChecked === true ||
					controleDeMouvementChecked === true ||
					controleDeLaCommunicationChecked === true ||
					refusDeDivorceChecked === true ||
					abusSpirituelChecked === true ||
					autreViolPsychologiqueChecked === true ||
					abondonDeLaFamilleChecked === true ||
					confiscationDeRevenueChecked === true ||
					interdictionDeParticipationChecked === true ||
					restrictionDeLAccessDEducationChecked === true ||
					restrictionDeLaccessDeLemploiChecked === true ||
					restrictionDeLAccessAuxRessourceChecked === true ||
					privationDePriseChangeChecked === true ||
					leviratChecked === true ||
					perterDeProprieteChecked === true ||
					perterDeLenfantChecked === true ||
					autreViolEconomiqueChecked === true ||
					q12bandachecked === true ||
					q12barumbuchecked === true ||
					q12bumbuchecked === true ||
					q12Gombechecked === true ||
					q12Kalamuchecked ||
					q12kasachecked === true ||
					q12Kimbachecked === true ||
					q12kinshasachecked === true ||
					q12kintambochecked === true ||
					q12kisensochecked === true ||
					q12lembachecked === true ||
					q12limetechecked === true ||
					q12Lingwalachecked === true ||
					q12Makalachecked === true ||
					q12Malukuchecked === true ||
					q12masinachecked === true ||
					q12matetechecked === true ||
					q12montNgafulachecked === true ||
					q12ndjilichecked === true ||
					q12ngabachecked === true ||
					q12ngaliemachecked === true ||
					q12ngirichecked === true ||
					q12nselechecked === true ||
					q12selembaochecked === true ||
					q12buniachecked === true ||
					q12aruchecked === true ||
					q12djuguchecked === true ||
					q12irumuchecked === true ||
					q12mahagichecked === true ||
					q12mambasachecked === true ||
					q12kanangachecked === true ||
					q12dembachecked === true ||
					q12dibayachecked === true ||
					q12dimbelengechecked === true ||
					q12kazumbachecked === true ||
					q12luizachecked === true ||
					q12mbujichecked === true ||
					q12kabeyachecked === true ||
					q12katandachecked === true ||
					q12lupatapatachecked === true ||
					q12miabichecked === true ||
					q12tshilengechecked === true ||
					q12kinduchecked === true ||
					q12kambarechecked === true ||
					q12kailochecked === true ||
					q12kasongochecked === true ||
					q12kibombochecked === true ||
					q12lubutuchecked === true ||
					q12pangichecked === true ||
					q12puniachecked === true ||
					q12benivilechecked === true ||
					q12butembochecked === true ||
					q12gomachecked === true ||
					q12benichecked === true ||
					q12luberochecked === true ||
					q12masisichecked === true ||
					q12nyiragongochecked === true ||
					q12rutshuruchecked === true ||
					q12walikalechecked === true ||
					q12bukavuchecked === true ||
					q12fizichecked === true ||
					q12idjwichecked === true ||
					q12kabarechecked === true ||
					q12kalehechecked === true ||
					q12mwengachecked === true ||
					q12shabundachecked === true ||
					q12uvirachecked === true ||
					q12walunguchecked === true ||
					q12kisanganichecked === true ||
					q12bafwasendechecked === true ||
					q12banaliachecked === true ||
					q12basokochecked === true ||
					q12isangichecked === true ||
					q12opalachecked === true ||
					q12ubunduchecked === true ||
					q12yahumachecked === true ||
					decesChecked === true ||
					maladieChecked === true ||
					blessureChecked === true ||
					douleurChecked === true ||
					troubleGynChecked === true ||
					handicapChecked === true ||
					grossesseChecked === true ||
					perteDeLenfantANaitreChecked === true ||
					abusDAlcoolsChecked === true ||
					abusDeDroguesChecked === true ||
					abusDeMedicamentsChecked === true ||
					autrePrejudicePhysiqueChecked === true ||
					risqueDeSuicideChecked === true ||
					peurEtAnxieteChecked === true ||
					depressionChecked === true ||
					detresseChecked === true ||
					sentimentDeHonteChecked === true ||
					sentimentDeColereChecked === true ||
					troubleDeSommeilChecked === true ||
					troubleAlimentairesChecked === true ||
					peeteDEspoireChecked === true ||
					manqueDeConfianceChecked === true ||
					autrePrejudiceEmotionnelChecked === true ||
					perterDeMoyensChecked === true ||
					perterDeRevenuesChecked === true ||
					sexeDeSurvieChecked === true ||
					perteDeMaisonsChecked === true ||
					demanegementFrequentChecked === true ||
					situationDeSanSAbriChecked === true ||
					pasOuPeuChecked === true ||
					autrePrejudiceEconomiqueChecked === true ||
					ruptureDeLaFamileChecked === true ||
					abondanParLaFamilleChecked === true ||
					stigmatisationChecked === true ||
					perteDeLaGardeChecked === true ||
					isolementDeLaVictimeChecked === true ||
					autreImpactSurLesRelationChecked === true ||
					mereChecked === true ||
					pereChecked === true ||
					gardienHommeChecked === true ||
					gardienneFemmeChecked === true ||
					propreFrereChecked === true ||
					propreSoeurChecked === true ||
					propreEnfantAdulteChecked === true ||
					gdMereChecked === true ||
					gdPereChecked === true ||
					belleMereChecked === true ||
					beauPereChecked === true ||
					beauxFereChecked === true ||
					belleSoeurChecked === true ||
					oncleChecked === true ||
					tanteChecked === true ||
					autreMemberChecked === true ||
					mariChecked === true ||
					exmariChecked === true ||
					epouseChecked === true ||
					exEpouseChecked === true ||
					partenaireHommeChecked === true ||
					partenaireFemmeChecked === true ||
					exPaternaireHommeChecked === true ||
					exPaternaireFemmeChecked === true ||
					patronChecked === true ||
					patronneChecked === true ||
					personnelHomeChecked === true ||
					personnelFemmeChecked === true ||
					bailleurChecked === true ||
					bailleresseChecked === true ||
					autreMemberDuCercleChecked === true ||
					q15achecked === true ||
					q15bchecked === true ||
					q16achecked === true ||
					q16bschecked === true ||
					q18achecked === true ||
					q18bchecked === true) &&
				filterChecked === true ? (
					<Grid item xs={12} sm={12}>
						<Grid
							container
							justifycontent="center"
							alignitems="center"
							spacing={3}
						>
							<Grid item>
								<Button
									variant="outlined"
									endIcon={<SearchIcon />}
									className={classes.btn}
									onClick={() => {
										rsltFilters();
										setState({ right: false, [anchor]: false });
									}}
								>
									Apply
								</Button>
							</Grid>
							{casestate === true ? (
								<Grid item>
									<Button
										endIcon={<ClearOutlinedIcon />}
										variant="outlined"
										className={classes.btn}
										onClick={() => {
											setcasefilter("");
											clearFilter();
											setState({ right: false, [anchor]: false });
										}}
									>
										Clean
									</Button>
								</Grid>
							) : null}
						</Grid>
					</Grid>
				) : null}
			</Grid>
		</div>
	);
	//

	//   console.log(pushData);

	const displayData = () => {
		// if(statecode===false){

		//     if(spinner===true){
		//         return (
		//             <Grid container spacing={4}>
		//                  <Grid item xs={12} sm ={12}>
		//                      <Skeleton variant="rect" height={700}/>
		//               </Grid>
		//               <Grid item xs={12} sm ={12}>
		//                      <Skeleton variant="text"/>
		//               </Grid>
		//               <Grid item xs={12} sm ={12}>
		//               <Skeleton variant="rect" height={400}/>
		//               </Grid>

		//          </Grid>
		//      )
		//     }
		//     else{
		//         return (
		//             <Grid container direction='row' justifycontent="center">
		//             <Grid item xs={12} sm={4}></Grid>
		//             <Grid item xs={12} sm={4}>
		//                 <Grid container justifycontent='center'>
		//                     <Grid item ><CloudOffTwoToneIcon size={40}/></Grid>
		//                     <Grid item ><Typography>{errmsg}</Typography></Grid>

		//                 </Grid>
		//                 <Grid container justifycontent='center'>
		//                     <Grid item>
		//                         <IconButton color="primary" aria-label="Restart" component="span" onClick={()=>getCase()}>
		//                         <RestoreIcon />
		//                         </IconButton>
		//                     </Grid>
		//                     </Grid>
		//             </Grid>
		//             <Grid item xs={12} sm={4}></Grid>
		//         </Grid>
		//         )
		//     }

		// }
		// else
		//  if(statecode===true && casemap==="")  {
		//     return(
		//         <Grid container direction='row' justifycontent="center">
		//                      <Grid xs={12} sm={4}></Grid>
		//                      <Grid xs={12} sm={4}>
		//                          <Grid container direction='row'>
		//                          <Grid item xs={12} sm={4}><StorageIcon size={60}/></Grid>
		//                           <Grid item xs={12} sm={8}><Typography>No data found</Typography></Grid>
		//                          </Grid>
		//                      </Grid>
		//                      <Grid xs={12} sm={4}></Grid>
		//                  </Grid>
		//     )
		// }

		// else if(statecode===true && casemap!==""){

		return (
			<Grid container className={classes.margin_Bottom_20} spacing={2}>
				<Grid item xs={12} sm={12}>
					<Grid container justifycontent="flex-end" alignitems="flex-end">
						<Grid item>
							{["right"].map((anchor) => (
								<React.Fragment key={anchor}>
									<Button
										onClick={toggleDrawer(anchor, true)}
										endIcon={<FilterListIcon />}
										style={{
											marginTop: 30,
											marginBottom: 30,
											color: "#00A1AF",
											fontWeight: 300,
										}}
									>
										{t("filter-name")}
									</Button>
									<SwipeableDrawer
										anchor={anchor}
										open={state[anchor]}
										onClose={toggleDrawer(anchor, false)}
										onOpen={toggleDrawer(anchor, true)}
									>
										{list(anchor)}
									</SwipeableDrawer>
								</React.Fragment>
							))}
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					// direction="row"
					justifycontent="center"
					alignitems="center"
					className={classes.margin_20}
				>
					{casestate === false ? (
						<Map casemap={casemap} />
					) : (
						<Map casemap={casefilter} />
					)}

					{/* <Map casemap={casemap} />   */}
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					justifycontent="center"
					alignitems="center"
					className={classes.margin_20}
				>
					{casestate === false ? (
						<CasedatabankTable
							active={active}
							casemap={i18n.language.startsWith("en") ? filted : casemap}
						/>
					) : (
						<CasedatabankTable
							active={active}
							casemap={
								i18n.language.startsWith("en") ? casefilter : filtedDataFrench
							}
						/>
					)}
				</Grid>
			</Grid>
		);
		// }
	};
	return (
		<Grid container>
			<Grid item xs={12} sm={12}>
				{displayData()}
			</Grid>
		</Grid>
	);
};

export default CaseMap;

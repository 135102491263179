 


 export const grp1 = "Identification de l'Organisation";
 export const q1 = "Nom de l'organisation qui documente ce cas ";
 export const q2 = "2. Nom du membre du staff qui documente ce cas";
 export const q3 = "3. Lieu de documentation du cas ";
 export const q4= "4. Date de documentation du cas";



 export const grp2 = "Profil de la / du Survivant(e)";
 export const q5 = "5. Code de la / du survivant(e) ";
 export const q5sub1 = "1 = Dernière lettre du nom de famille de la / du survivant(e)";
 export const q5sub2 = "2 = Dernière lettre du post-nom de la / du survivant(e)";
 export const q5sub3 = "3 = Somme totale du nombre de frères et de sœurs (format 01 – 99) de la / du survivant(e)";
 export const q5sub4 = "4 = Dernière lettre du lieu de naissance de la / du survivant(e)";
 export const q6 = "6. Age de la / du survivant(e) ";
 export const q6a = "a. Age précis";
 export const q6b = "b. Groupe d'âge concerné";
 export const q7 = "7. Genre de la / du survivant(e)";
 export const q8 = "8. Profil de vulnérabilité de la / du survivant(e) ";
 export const q8a = "a. Etat civil ";
 export const q8b = "b. Identification en tant que personne LGBTI";
 export const q8c = "c. Situation de handicap (des handicaps physiques, psychiques, intellectuels ou sensoriels de longue durée)";
 export const q8d = "d. Occupation";
 export const q8e = "e. Mineur (moins de 18 ans)";
 export const q8f = "f. Troisième âge (plus de 65 ans) ";



 export const grp3 = "Information sur l'incident de violence domestique documenté";
 export const q9 = "9. Description de l'acte de VD – Que s'est-il passé ?";
 export const q10 = "10. Classification de l'acte de violence domestique commis";
 export const q11 = "11. S'agit-il d'une situation de violence continue ou d'un incident ponctuel ?";
 export const q11a = "a. S'agit-il d'une situation de violence continue";
 export const q11b = "b. S'agit-il d'un incident ponctuel";
 export const q12 = "12. Où est-ce que cet acte de violence domestique a eu lieu ?";
 export const q12a = "a. Veuillez déterminer dans quelle province et ville ou territoire l'acte de violence a eu lieu";
 export const q12b = "b. Veuillez déterminer le type de milieu dont il s'agit exactement";
 export const q13 = "13. Impact de l'acte de VD – Quelles ont été les conséquences de l'acte de violence dans la vie de la victime ?";
 export const q13a = "a. Demandez la victime ce qui a changé dans sa vie à cause des violences domestiques subies, donc les conséquences causées par l'acte de violence dans sa vie et ses relation.";
 export const q13b = "b. Sur base des explications de la victime, veuillez catégoriser les conséquences ayant affectées la victime avec l'aide de la grille suivante";




 export const grp4 = "Profil de l'auteur de l'acte de violence domestique";
 export const q14 = "14. Quelle est la relation entre l'agresseur et la victime ?";
 export const q14sub = "(choix multiple est possible ; cependant il n'est pas nécessaire de présenter chaque option à la victime mais la personne qui remplit la fiche coche les cases adéquates selon les descriptions de la victime.)";
 export const q14a = "a. Est-ce que l'auteur de l'acte de violence domestique est un membre de la famille de la victime ?";
 export const q14asub= "(Sans conjoint, ceux-ci font l'objet de la question suivante)";
 export const q14b = "b. Est-ce que l'auteur de l'acte de violence domestique est le/la conjoint(e) de la victime ou son/sa partenaire intime actuel ou ancien ?";
 export const q14c = "c. Si l'auteur de l'acte de violence domestique est ni membre de famille ni conjoint ou partenaire intime actuel ou ancien de la victime, quelle autre relation existe entre victime et auteur ? L'auteur est …";
 export const q15 = "15. Est-ce que l'agresseur et la victime habitent ensemble ?";



 export const grp5 = "Situation et besoins de prise en charge de la / du Survivant(e)";
 export const q16 = "16. Est-ce que la victime a dénoncé son cas de violence domestique auprès d'une autorité judiciaire ?";
 export const q16oui = "Si oui, les autorités ont-elles donné suite à la plainte :";
 export const q17 = "17. Est-ce que la victime bénéficie d'un soutien amical, familial, ou d'une autre personne de son environnement social proche ?";
 export const q18 = "18. Est-ce que la victime a déjà bénéficié de services de prise en charge comme réponse à l'acte de violence domestique documenté ici ?";
 export const q18a = "a. De quel(s) volet(s) de prise en charge la victime a-t-elle bénéficié ?";
 export const q18b = "b. Quand est-ce que la victime a bénéficié des formes de prise en charge mentionnés ci-dessus ?";
 export const q18c = "c. Quelle(s) structure(s), organisation(s) ou institution(s) ont appuyé la victime en assurant une prise en charge ?";
 export const q18d = "d. Est-ce que la victime profite d'un soutien amical ou familial ou par une autre personne de son environnement social proche ?";
 export const q19 = "19. Est-ce que la victime pense avoir encore besoin de certains volets de prise en charge pour répondre à l'acte de violence domestique documenté ici ?";
 export const q20 = "20. En vue des services de prise en charge dont la victime a encore besoin (cf. question 19), pour quel(s) volet(s) l'organisation qui documente ce cas est-elle capable de fournir ces services elle-même ?";
 export const q20sub = "  (Cette question ne s'adresse pas à la victime, mais à votre organisation ! Regardez encore une fois les réponses pour la question 19 afin de vous rappeler des services de prise en charge dont la victime a encore besoin. Veuillez cocher ici alors les volets qui sont couverts par les domaines d'intervention de votre organisation et dans lesquels vous pouvez ainsi directement assurer la prise en charge de la victime. Le choix multiple est possible.)";
 export const q21 = "21. En vue des services de prise en charge dont la victime a encore besoin (cf. question 19), pour quel(s) volet(s) l’organisation qui documente ce cas est-elle capable de référer la victime ? ";
 export const q21sub = "(Cette question ne s'adresse pas à la victime, mais à votre organisation ! Regardez encore une fois les réponses pour la question 19, afin de vous rappeler des services de prise en charge dont la victime a encore besoin, et pour la question 20, afin de vous rappeler des services de prise en charge que votre organisation peut assurer. Veuillez cocher ici alors les volets qui ne sont pas couverts par les domaines d'intervention de votre organisation (cf. question 20) mais pour lesquels vous pouvez référer facilement la victime auprès des structures de référence fiables. Le choix multiple est possible.)";
 export const q22 = "22. En vue des services de prise en charge dont la victime a encore besoin (cf. question 19) pour quels volets est-il impossible de trouver une prise en charge dans l'immédiat ? ";
 export const q22sub = "(Cette question ne s'adresse pas à la victime, mais à votre organisation ! Regardez encore une fois les réponses pour la question 19, afin de vous rappeler des services de prise en charge dont la victime a encore besoin, pour la question 20, afin de vous rappeler des services de prise en charge que votre organisation peut assurer, et pour la question 21, afin de vous rappeler des services auxquels votre organisation est en mesure de référer la victime. Veuillez cocher ici les volets de prise en charge pour lesquels il est impossible d'assister la victime dans l'immédiat. Le choix multiple est possible.)";
 export const q23 = "23. La victime se sent-elle suffisamment en sécurité pour rentrer chez elle ?";
 export const q24 = "24. La victime cherche-elle à quitter son foyer de façon temporaire ou de façon permanente à cause des violences domestiques, afin d'établir une distance physique avec l'agresseur ?";
 export const q24a= "a. La victime sait-elle où elle peut loger ou être hébergée une fois qu'elle décide de quitter l'agresseur dans son foyer ?";
 export const q25= "25.	Est-ce que la victime souhaite de partager un message direct avec le public au travers le site web de l'Observatoire ?";
 export const q26 = "26. Quel est le message direct que la victime souhaite de partager ?";
 export const q27 = "27. Est-ce que la victime souhaite de partager son message direct avec sa localisation et son âge ou rester complètement anonyme ?";
 export const nouveacas="NOUVEAU CAS";
 export const annule= "ANNULER";
 export const precedent= "PRECEDENT";
 export const suivant = "SUIVANT";
 export const revoir = "REVOIR";
 export const reviewbefore = "REVOIR AVANT LA SOUMISSION";
 export const soumettre = "SOUMETTRE";
 export const modifier = "MODIFIER"


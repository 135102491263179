import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import useStyles from "../../../dashboard/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

const useRowStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},
});

function createData(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8,
	tpn9,
	vl9,
	tpn10,
	vl10,
	tpn11,
	vl11,
	tpn12,
	vl12
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
			{ contentID: `${tpn9}`, amount: `${vl9}` },
			{ contentID: `${tpn10}`, amount: `${vl10}` },
			{ contentID: `${tpn11}`, amount: `${vl11}` },
			{ contentID: `${tpn12}`, amount: `${vl12}` },
		],
	};
}

function createData2(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8,
	tpn9,
	vl9,
	tpn10,
	vl10,
	tpn11,
	vl11
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
			{ contentID: `${tpn9}`, amount: `${vl9}` },
			{ contentID: `${tpn10}`, amount: `${vl10}` },
			{ contentID: `${tpn11}`, amount: `${vl11}` },
		],
	};
}

function createData3(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
		],
	};
}

function createData4(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
		],
	};
}
function Row(props) {
	const { row } = props;
	const [open, setOpen] = React.useState(false);
	const classes = useRowStyles();
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row">
					<Typography
						variant="button"
						style={{ fontWeight: 330, textTransform: "unset", textAlign: "left", cursor: "pointer" }}
						onClick={() => setOpen(!open)}
					>
						{row.name}
					</Typography>
				</TableCell>
				<TableCell align="right" style={{ fontWeight: 330 }}>
					{row.total}
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							{/* <Typography variant="h6" gutterBottom component="div">
                Type
              </Typography> */}
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell style={{ fontWeight: 300, fontSize: 18 }}>
											{t("case-statistic-harm")}
										</TableCell>
										<TableCell
											align="right"
											style={{ fontWeight: 300, fontSize: 18 }}
										>
											{t("cases")}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{row.history.map((historyRow) => (
										<TableRow key={historyRow.contentID}>
											<TableCell style={{ fontWeight: 300 }}>
												{historyRow.contentID}
											</TableCell>
											<TableCell align="right" style={{ fontWeight: 300 }}>
												{historyRow.amount}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

Row.propTypes = {
	row: PropTypes.shape({
		// calories: PropTypes.number.isRequired,
		// carbs: PropTypes.number.isRequired,
		// fat: PropTypes.number.isRequired,
		history: PropTypes.arrayOf(
			PropTypes.shape({
				amount: PropTypes.number.isRequired,
				contentID: PropTypes.any.isRequired,
				// contentID: PropTypes.string.isRequired,
			})
		).isRequired,
		name: PropTypes.string.isRequired,
		total: PropTypes.number.isRequired,
	}).isRequired,
};

const PrejudiceTbl = ({
	title1,
	title2,
	prephysTotal,
	dec,
	mal,
	bles,
	dou,
	troublgyn,
	handicap,
	gros,
	ptDenfa,
	abusDalc,
	abusDedrog,
	abusDemed,
	autr5,
	preEmolTotal,
	risDeSui,
	peur,
	depr,
	detr,
	sent,
	sentDecol,
	troublDesom,
	troublAlim,
	peterDespoir,
	manqueDeconf,
	autr6,
	preEcomTotal,
	perterDeSu,
	perterDeRev,
	sexDesurvie,
	peterDemaison,
	demenagement,
	situation,
	pasoupeu,
	autr7,
	impTotal,
	rupture,
	conflits,
	abndon,
	stigm,
	perterDeGarde,
	isolDelaVictime,
	autr8,
}) => {
	const { t } = useTranslation();
	const rowsx = [
		createData(
			`${t("case-statistic-categ-of-harm-phys")}`,
			`${prephysTotal}`,
			`${t("case-statistic-categ-of-harm-phys-death")}`,
			`${dec}`,
			`${t("case-statistic-categ-of-harm-phys-ill")} `,
			`${mal}`,
			`${t("case-statistic-categ-of-harm-phys-inj")}`,
			`${bles}`,
			`${t("case-statistic-categ-of-harm-phys-pain")}`,
			`${dou}`,
			`${t("case-statistic-categ-of-harm-phys-gyn")}`,
			`${troublgyn}`,
			`${t("case-statistic-categ-of-harm-phys-disa")}`,
			`${handicap}`,
			`${t("case-statistic-categ-of-harm-phys-uni")}`,
			`${gros}`,
			`${t("case-statistic-categ-of-harm-phys-loss")}`,
			`${ptDenfa}`,
			`${t("case-statistic-categ-of-harm-phys-alcool")}`,
			`${abusDalc}`,
			`${t("case-statistic-categ-of-harm-phys-drug")}`,
			`${abusDedrog}`,
			`${t("case-statistic-categ-of-harm-phys-med")}`,
			`${abusDemed}`,
			`${t("case-statistic-categ-of-harm-phys-oth")}`,
			`${autr5}`
		),
		createData2(
			`${t("case-statistic-categ-of-harm-em")}`,
			`${preEmolTotal}`,
			`${t("case-statistic-categ-of-harm-em-risk")}`,
			`${risDeSui}`,
			`${t("case-statistic-categ-of-harm-em-fear")}`,
			`${peur}`,
			`${t("case-statistic-categ-of-harm-em-depr")}`,
			`${depr}`,
			`${t("case-statistic-categ-of-harm-em-em")}`,
			`${detr}`,
			`${t("case-statistic-categ-of-harm-em-feelings-shame")}`,
			`${sent}`,
			`${t("case-statistic-categ-of-harm-em-feelings-anger")}`,
			`${sentDecol}`,
			`${t("case-statistic-categ-of-harm-em-sleep-dis")}`,
			`${troublDesom}`,
			`${t("case-statistic-categ-of-harm-em-eat")}`,
			`${troublAlim}`,
			`${t("case-statistic-categ-of-harm-em-loss")}`,
			`${peterDespoir}`,
			`${t("case-statistic-categ-of-harm-em-lack")}`,
			`${manqueDeconf}`,
			`${t("case-statistic-categ-of-harm-em-other")}`,
			`${autr6}`
		),
		createData3(
			`${t("case-statistic-categ-of-harm-econ")}`,
			`${preEcomTotal}`,
			`${t("case-statistic-categ-of-harm-econ-loss")}`,
			`${perterDeSu}`,
			`${t("case-statistic-categ-of-harm-econ-incom")}`,
			`${perterDeRev}`,
			`${t("case-statistic-categ-of-harm-econ-surv")}`,
			`${sexDesurvie}`,
			`${t("case-statistic-categ-of-harm-econ-home")}`,
			`${peterDemaison}`,
			`${t("case-statistic-categ-of-harm-econ-freq")}`,
			`${demenagement}`,
			`${t("case-statistic-categ-of-harm-econ-homeless")}`,
			`${situation}`,
			`${t("case-statistic-categ-of-harm-econ-no")}`,
			`${pasoupeu}`,
			`${t("case-statistic-categ-of-harm-econ-other")}`,
			`${autr7}`
		),
		createData4(
			`${t("case-statistic-categ-of-harm-impact")}`,
			`${impTotal}`,
			`${t("case-statistic-categ-of-harm-impact-fam")}`,
			`${rupture}`,
			`${t("case-statistic-categ-of-harm-impact-regul")}`,
			`${conflits}`,
			`${t("case-statistic-categ-of-harm-impact-abn")}`,
			`${abndon}`,
			`${t("case-statistic-categ-of-harm-impact-stig")}`,
			`${stigm}`,
			`${t("case-statistic-categ-of-harm-impact-loss")}`,
			`${perterDeGarde}`,
			`${t("case-statistic-categ-of-harm-impact-soc")}`,
			`${isolDelaVictime}`,
			`${t("case-statistic-categ-of-harm-impact-other")}`,
			`${autr8}`
		),
	];
	const classes = useStyles();

	return (
		<TableContainer component={Paper}>
			<Table aria-label="collapsible table">
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell className={classes.typochart}>{title1}</TableCell>
						<TableCell align="right" className={classes.typochart}>
							{title2}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rowsx.map((row) => (
						<Row key={row.name} row={row} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
export { PrejudiceTbl };

import React, { useState, useEffect } from "react";
import useStyles from "../styles";
import BackPaper from "../../../widget/backpaper";
import {
	Grid,
	Typography,
	Card,
	CardContent,
	Select,
	MenuItem,
	Button,
	Divider,
	Link,
} from "@material-ui/core";
import { TextForm, BtnContained, BtnOutline } from "../../../widget/util";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import axiosInstance from "../../../service/dashboardAxiosApi";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import ReactQuill from "react-quill";
// import 'react-quill/dist/quill.snow.css';
// import 'react-quill/dist/quill.bubble.css';

const Editpost = () => {
	const classes = useStyles();
	// const location = useLocation();
	const history = useHistory();
	const [isupdate, setisupdate] = useState(false);
	const [ermsg, setermsg] = useState("");
	const [addData, setaddData] = useState("");
	const [doc, setdoc] = useState("");
	const [img, setimg] = useState("");
	const [docs, setdocs] = useState("");
	const [imgs, setimgs] = useState("");
	const [user, setuser] = useState("");
	const [postdata, setpostdata] = useState([]);
	// const [title, settitle] = useState()
	// const [link, setlink] = useState();
	const [setlink] = useState();
	const [category, setcategory] = useState();
	const [catg, setcatg] = useState();

	const toolbars = [
		[{ font: [] }],
		["bold", "italic", "underline"],
		[{ header: [1, 2, 3, 4, 5, 6, false] }],
		[{ list: "ordered" }, { list: "bullet" }],
		[{ script: "sub" }, { script: "super" }],
		[{ indent: "-1" }, { indent: "+1" }],
		[{ direction: "rtl" }],
		[{ align: [] }],
		[{ color: [] }, { background: [] }],
		["link", "image", "video"],
		["blockquote"],
		["clean"],
	];

	var formats = [
		"font",
		"size",
		"bold",
		"italic",
		"underline",
		"header",
		"list",
		"bullet",
		"align",
		"color",
		"background",
		"link",
		"image",
		"video",
		"blockquote",
		"script",
		"indent",
	];

	const handleChange = (editor) => {
		const data = editor;
		// console.log(data);
		setaddData(data);
	};

	const handleCategory = (e) => {
		const value = e.target.value;
		// console.log(value);
		setcategory(value);
	};

	const handleDoc = (e) => {
		const value = e.target.files[0];
		setdoc(value);
		//  console.log(value);
	};
	const handleImage = (e) => {
		const value = e.target.files[0];
		setimg(value);
		//console.log(value);
	};
	const { register, handleSubmit, reset } = useForm({ mode: "onBlur" });
	const onSubmit = (data, e) => {
		e.preventDefault();
		// console.log(data);

		addpost(data.title, category, data.link, img, doc, user, addData);
	};

	const addpost = async (title, category, link, img, doc, author, content) => {
		setisupdate(true);
		// console.log(category);
		var formData = new FormData();

		if (imgs !== null && docs !== null) {
			formData.append("title", title);
			formData.append("category", category);
			//formData.append('image',img)
			//formData.append('document',doc)
			formData.append("link", link);
			formData.append("content", content);
			formData.append("author", author);
		} else if (imgs !== null) {
			formData.append("title", title);
			formData.append("category", category);
			formData.append("image", img);
			// formData.append('document',doc)
			formData.append("link", link);
			formData.append("content", content);
			formData.append("author", author);
		} else if (docs !== null) {
			formData.append("title", title);
			formData.append("category", category);
			//formData.append('image',img)
			formData.append("document", doc);
			formData.append("link", link);
			formData.append("content", content);
			formData.append("author", author);
		} else if (imgs === null && docs === null) {
			formData.append("title", title);
			formData.append("category", category);
			formData.append("image", img);
			formData.append("document", doc);
			formData.append("link", link);
			formData.append("content", content);
			formData.append("author", author);
			// console.log(category);
		}
		await axiosInstance
			.patch(`posts/${localStorage.getItem("pstId")}/`, formData)
			.then((res) => {
				setermsg(res.message);
				// console.log(res.data);
				history.push(
					`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/post/detail`
				);
				localStorage.setItem("pstId", res.data.post.id);
			})
			.catch((err) => {
				setisupdate(false);

				if (err.request) {
					if (err.request.responseText) {
						setermsg("Enter a valid URL.");
					} else {
						setermsg(
							"Your request could not reach the server, check your internet connexion"
						);
					}

					// console.log(err.request);
				} else if (err.response) {
					setermsg("Please contact the Admin");
					// console.log(err.response);
				}
			});
	};

	const backBtn = () => {
		history.push({
			pathname: `/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/posts`,
		});
	};
	const getUser = () => {
		const user = localStorage.getItem("users");
		setuser(user);
		//console.log("my token \t\t\t"+user);
	};
	useEffect(() => {
		getUser();

		const getPost = async () => {
			axiosInstance
				.get(`post/${localStorage.getItem("pstId")}/`)
				.then((res) => res.data.post)
				.then((res) => {
					//const data =res.data.post
					setpostdata(res);
					// console.log(res);
				});
		};
		getPost();
		reset({
			title: postdata.title,
			link: postdata.link,
			content: postdata.content,
			docs: postdata.document,
			imgs: postdata.image,
			cat: postdata.category,
		});
		setaddData(postdata.content);
		setimgs(postdata.image);
		setdocs(postdata.document);
		// settitle(postdata.title)
		setlink(postdata.link);
		setcatg(postdata.category);
		setcategory(postdata.category);
		if (postdata.category === "Reports & articles") {
			setcategory("ARTICLE");
		} else if (postdata.category === "Campaign news") {
			setcategory("NEWS");
		}
	}, [axiosInstance, user, postdata.id]);
	//  console.log(addData);
	//  console.log(category);
	return (
		<BackPaper
			content={
				<div>
					<Grid container direction="row">
						<Grid item xs={12} sm={12}>
							<Typography variant="h4" className={classes.bigtitle}>
								{" "}
								Update post
							</Typography>
						</Grid>
					</Grid>
					<Divider />
					<Grid container direction="row">
						<Grid item xs={12} sm={12}>
							<Card className={classes.grid}>
								<CardContent>
									<form onSubmit={handleSubmit(onSubmit)}>
										<Grid container>
											<Grid item sm={1}></Grid>
											<Grid item sm={10}>
												{/* left side */}
												<Grid
													container
													spacing={4}
													className={classes.margin_Top_30}
												>
													<Grid item xs={12} sm={6}>
														<Grid
															container
															className={classes.margin_Bottom_30}
															spacing={2}
														>
															<Grid item sm={12} xs={12}>
																<Typography className={classes.editDetailTypo}>
																	Title
																</Typography>
																<TextForm
																	//defaultValue={location.state.last_name}
																	name="title"
																	required
																	inputRef={register}
																	placeholder="Title"
																/>
															</Grid>
														</Grid>
														<Grid
															container
															className={classes.margin_Bottom_30}
															spacing={2}
														>
															<Grid item sm={12} xs={12}>
																<Typography className={classes.editDetailTypo}>
																	Link
																</Typography>
																<TextForm
																	//defaultValue={location.state.organization}
																	name="link"
																	inputRef={register}
																	type="link"
																	placeholder="e.g. https://oxide-digital.com/contact"
																/>
															</Grid>
															<Grid item sm={12} xs={12}>
																<Grid container>
																	<Grid item sm={12} xs={12}>
																		<Grid container direction="row">
																			<Grid item>
																				<Typography
																					className={classes.editDetailTypo}
																				>
																					Cover image
																					{imgs !== null ? (
																						<IconButton
																							onClick={() => {
																								setimgs();
																							}}
																							style={{ marginTop: 0 }}
																						>
																							<AddAPhotoIcon fontSize="small" />
																						</IconButton>
																					) : (
																						<Button
																							onClick={() => {
																								setimgs(postdata.image);
																							}}
																						>
																							Cancel
																						</Button>
																					)}
																				</Typography>
																			</Grid>
																		</Grid>
																	</Grid>
																	<Grid item sm={12} xs={12}>
																		{imgs !== null ? (
																			<img
																				src={postdata.image}
																				width="200px"
																				height="100px"
																				alt="Post data"

																				//defaultValue={location.state.last_name}
																				//   name='imgs'
																				//   required
																				//   inputRef={register}
																			/>
																		) : (
																			<TextForm
																				//defaultValue={location.state.organization}
																				name="img"
																				type="file"
																				//inputRef={register}
																				onchange={handleImage}
																				//onChange={e=>register({name:'img',value:e.target.value})}
																			/>
																		)}
																	</Grid>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
													{/* right side */}
													<Grid item xs={12} sm={6}>
														<Grid
															container
															className={classes.margin_Bottom_30}
															spacing={2}
														>
															<Grid item sm={12} xs={12}>
																<Typography className={classes.editDetailTypo}>
																	Categories
																</Typography>
																{catg !== null ? (
																	<TextForm
																		//defaultValue={location.state.last_name}
																		name="cat"
																		required
																		inputRef={register}
																		onClick={() => {
																			setcatg();
																			setcategory();
																		}}
																	/>
																) : (
																	<Select
																		required
																		variant="outlined"
																		size="small"
																		fullWidth
																		style={{ height: 40 }}
																		// //defaultValue={location.state.role}

																		value={category}
																		onChange={handleCategory}
																	>
																		<MenuItem value={"ARTICLE"}>
																			Reports & articles
																		</MenuItem>
																		<MenuItem value={"NEWS"}>
																			Campaign news
																		</MenuItem>
																	</Select>
																)}
															</Grid>
														</Grid>
														<Grid
															container
															className={classes.margin_Bottom_30}
															spacing={2}
														>
															<Grid item sm={12} xs={12}>
																<Grid container>
																	<Grid item sm={12} xs={12}>
																		<Grid container direction="row">
																			<Grid item>
																				<Typography
																					className={classes.editDetailTypo}
																				>
																					Document
																					{docs !== null ? (
																						<IconButton
																							onClick={() => {
																								setdocs();
																							}}
																							style={{ marginTop: 0 }}
																						>
																							<EditIcon fontSize="small" />
																						</IconButton>
																					) : (
																						<Button
																							onClick={() => {
																								setdocs(postdata.document);
																							}}
																						>
																							Cancel
																						</Button>
																					)}
																				</Typography>
																			</Grid>
																		</Grid>
																	</Grid>
																</Grid>

																{docs !== null ? (
																	<Grid container direction="row">
																		<Grid item>
																			<Link href={postdata.document}>
																				Open your document
																			</Link>
																		</Grid>
																	</Grid>
																) : (
																	<TextForm
																		placeholder="document"
																		//defaultValue={location.state.first_name}
																		type="file"
																		name="doc"
																		//onChange={e=>register({name:'doc',value:e.target.value})}

																		onchange={handleDoc}
																		// inputRef={register}
																	/>
																)}
															</Grid>
														</Grid>
													</Grid>
												</Grid>
												<Grid item sm={12} xs={12} className={classes.ckeditor}>
													{/* <CKEditor 
                                                onReady={editor => {
                                                    // You can store the "editor" and use when it is needed.
                                                    // console.log("Editor is ready to use!", editor);
                                                    editor.editing.view.change(writer => {
                                                    writer.setStyle(
                                                        "height",
                                                        "300px",
                                                        editor.editing.view.document.getRoot()
                                                    );
                                                    });
                                                }}
                                            disabled={true}
                                            
                                            editor={ClassicEditor}
                                            data={addData}
                                            onChange={handleChange}
                                            config={{  
                                                
                                                toolbar: ["undo", "redo", "bold", "italic", "blockQuote", "imageTextAlternative",
                                                  "imageUpload",
                                                  "heading", "imageStyle:full", "imageStyle:side", "link", "numberedList", "bulletedList", "mediaEmbed", "insertTable", "tableColumn", "tableRow", "mergeTableCells","embed","paragraph","alignment"],      
                                          
                                              }} 

                                            
                                              
                                            /> */}
													<ReactQuill
														// ref={ref => this.quillRef = ref}
														// theme="snow"
														value={addData || ""}
														theme="snow"
														onChange={handleChange}
														modules={{
															toolbar: {
																container: toolbars,
																// handlers: {
																//     image: (val)=>imageHandler({val})
																// }
															},
														}}
														formats={formats}
														placeholder="Write content here"
														// style={{height:300}}
													/>
												</Grid>
												{/* button */}
												<Grid item xs={12} sm={12}>
													<Grid
														container
														className={classes.margin_Bottom_30}
														justifyContent="flex-end"
													>
														<Grid
															container
															justifyContent="flex-end"
															className={classes.margin_Bottom_30}
														>
															<Grid item>
																<Typography style={{ color: "red" }}>
																	{" "}
																	{ermsg}{" "}
																</Typography>
															</Grid>
														</Grid>
														<Grid item>
															<BtnOutline content="Back" onClick={backBtn} />

															{isupdate === true ? (
																<CircularProgress color="secondary" />
															) : (
																<BtnContained content="Apply" type="submit" />
															)}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											<Grid item sm={1}></Grid>
										</Grid>
									</form>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</div>
			}
		/>
	);
};

export default Editpost;

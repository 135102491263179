import React, { useState, useEffect } from 'react';
import useStyles from "../styles";
import { useHistory } from 'react-router-dom';
import { 
	Grid,
	Button, 
	TextField,
	Typography,
	Card,
	CardContent,
	Divider,
	Select,
	MenuItem, 
	InputAdornment, 
	IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import axiosInstance from "../../../service/dashboardAxiosApi";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import InputLabel from '@material-ui/core/InputLabel';



const AddUser = () => {
	const history = useHistory();
	const classes = useStyles();
	const [role, setRole] = useState("");
	const roleLocal = sessionStorage.getItem("role");
	const [organization, setOrganization] = useState("");
	const [abbreviation, setAbbreviation] = useState("");
	const [address, setAddress] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [location, setLocation] = useState("");
	const [password, setPassword] = useState("");
	const [username, setUsername] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [activated, setActivated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isFormComplete, setIsFormComplete] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [successResponse, setSuccessResponse] = useState(false);
	const [responseMessage, setResponseMessage] = useState("");
	const [internetError, setInternetError] = useState(false);
	const [isComponentMounted, setIsComponentMounted] = useState(true);


	useEffect(() => {
        return () => {
            // executed when the component is unmounted
            setIsComponentMounted(false);
        }
    }, []);

	const checkFormCompletion = () => {
		if (
			organization &&
			abbreviation &&
			location &&
			address &&
			firstName &&
			lastName &&
			email &&
			phoneNumber &&
			username &&
			role &&
			password &&
			confirmPassword &&
			password === confirmPassword // if you want to ensure the passwords match
		) {
			setIsFormComplete(true);
		} else {
			setIsFormComplete(false);
		}
	};

  const handleAddUser = async () => {
    setIsLoading(true);

	const userData = {
		role: role, 
		organization: organization, 
		abbreviation: abbreviation, 
		address: address, 
		location: location,
		first_name: firstName, 
		last_name: lastName, 
		phone_number: phoneNumber, 
		username: username, 
		password: password,
		activated: activated,
		is_active: activated
	  };

	if (!navigator.onLine) {
		setResponseMessage("No internet connection.")
		setInternetError(true);
		return;
	}

	try {
		const response = await axiosInstance.post('register/', userData);

		if (isComponentMounted) {
			// Only proceed if the component is still mounted
			setActivated(false);
			setResponseMessage(`${response.data.user.first_name} added successfully!`);
			setInternetError(false);
			setSuccessResponse(true);
			setTimeout(() => {
				setIsLoading(false);
				history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/user/detail/${response.data.user.id}`);
			}, 3000); 
		}
	} catch (error) {
		if (isComponentMounted) {
			// Only proceed if the component is still mounted
			if (error.code === 'ECONNABORTED') {
				setResponseMessage("The request took too long - please try again.")
				setInternetError(true);
			} else {
				if (error.response && error.response.data) {
					// Convert the error data into a string
					let errorMessage = '';
					for (let key in error.response.data) {
						errorMessage += `${key}: ${error.response.data[key].join(', ')}. `;
					}
					setResponseMessage(errorMessage);
				} else {
					setResponseMessage("Error adding a user. Check your internet or contact admin");
				}
			}
			setSuccessResponse(false);
			setIsLoading(false);
			setTimeout(() => {
				history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/users`);
			}, 4000); 
		}
	}

	if (isComponentMounted) {
		handleClickSnack();
	}
}; 

	useEffect(() => {
		checkFormCompletion();
	}, [organization, abbreviation, location, address, firstName, lastName, email, phoneNumber, username, role, password, confirmPassword]);


	if (internetError) {
		return (
		<div>
			<p>An error occurred. Please check your internet connection and try again.</p>
			<Button variant="contained" color="primary"  onClick={handleAddUser}>Retry</Button>
		</div>
		)
	}

	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});

	const handleClickSnack = () => {
		setOpenSnackBar(true);
	};

	const handleCloseScnackBar = (event, reason) => {
		if (reason === 'clickaway') {
		return;
		}

		setOpenSnackBar(false);
	};

	

	return (
		<Grid container spacing={3} >
				<Grid container direction="row">
					<Grid item xs={12} sm={12}>
						<Typography variant="h4" className={classes.bigtitle}>
							Add a new User
						</Typography>
					</Grid>
					{/* RESPONSE MESSAGE SNACKBAR */}
					<Grid item xs={12} sm={4}>
						<Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseScnackBar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
						{ successResponse ? <Alert onClose={handleCloseScnackBar} severity="success" sx={{ width: '100%' }}>
								{responseMessage} 
							</Alert>
							:
							<Alert onClose={handleCloseScnackBar} severity="error" sx={{ width: '100%' }}>
							{responseMessage}
							</Alert> }
						</Snackbar>
					</Grid>
				</Grid>

				{/* <Divider /> */}

				<Grid container direction="row">
					<Grid item xs={12} sm={12}>
						<Card className={classes.fullWidthCard}>
							<CardContent>
								<Grid container className={classes.cardContentMargin}>
									<Grid item sm={10}>
									<Grid item className={classes.margin_Bottom_30}>
									<Typography className={classes.detailTypoGrow}>
												<strong >USER'S INFORMATION</strong> 
									</Typography>
									</Grid>
										{/* LEFT SIDE */}
										<Grid container spacing={4} className={classes.cardContentMarginRight}>
											<Grid item xs={12} sm={6}>
											
												<Grid
													container
													spacing={2}
													className={classes.margin_Bottom_30}
												>
													<Grid item sm={12} xs={12}>
													<TextField 
														required
														label="Organisation" 
														fullWidth
														value={organization} 
														onChange={e => {setOrganization(e.target.value)}} />
													</Grid>
												</Grid>
												<Grid
													container
													spacing={2}
													className={classes.margin_Bottom_30}
												>
													<Grid item sm={12} xs={12}>
														<TextField 
															required
															label="Abbreviation Of The Organisation" 
															fullWidth
															value={abbreviation} 
															onChange={e => {setAbbreviation(e.target.value)}} />
													</Grid>
												</Grid>
												<Grid
													container
													spacing={2}
													className={classes.margin_Bottom_30}
												>
													<Grid item sm={12} xs={12}>
														<TextField
															required
															label="City/Country" 
															fullWidth
															value={location} 
															onChange={e => {setLocation(e.target.value)}} />
													</Grid>
												</Grid>
												<Grid
													container
													spacing={2}
													className={classes.margin_Bottom_30}
												>
													<Grid item sm={12} xs={12}>
														<TextField 
															required
															label="Address" 
															fullWidth
															value={address} 
															onChange={e => {setAddress(e.target.value)}} />
													</Grid>
												</Grid>
											</Grid>

											{/* RIGHT SIDE */}
											<Grid item xs={12} sm={6}>
												<Grid
													container
													spacing={2}
													className={classes.margin_Bottom_30}
												>
													<Grid item sm={12} xs={12}>
														<TextField 
															required
															label="First Name" 
															fullWidth
															value={firstName} 
															onChange={e => {setFirstName(e.target.value)}} />
													</Grid>
												</Grid>
												<Grid
													container
													spacing={2}
													className={classes.margin_Bottom_30}
												>
													<Grid item sm={12} xs={12}>
														<TextField 
															required
															label="Last Name" 
															fullWidth
															value={lastName} 
															onChange={e => {setLastName(e.target.value)}} />
													</Grid>
												</Grid>
												<Grid
													container
													spacing={2}
													className={classes.margin_Bottom_30}
												>
													<Grid item sm={12} xs={12}>
														<TextField 
															required
															type="email"
															label="E-mail" 
															fullWidth
															value={email} 
															onChange={e => {setEmail(e.target.value)}} />
													</Grid>
												</Grid>
												<Grid
													container
													spacing={2}
													className={classes.margin_Bottom_30}
												>
													<Grid item sm={12} xs={12}>
													<TextField
														required
														label="Phone" 
														fullWidth
														value={phoneNumber} 
														placeholder="e.g +243970000001"
														onChange={e => {setPhoneNumber(e.target.value)}} />
													</Grid>
												</Grid>
											</Grid>
										</Grid>

										<Divider
											component="hr"
											className={classes.margin_Bottom_30}
										/>

										{/* BOTTOM LEFT SIDE */}
										<Grid item className={classes.margin_Bottom_30}>
											<Typography className={classes.detailTypoGrow}>
													<strong>LOGIN CREDENTIALS</strong> 
											</Typography>
										</Grid>
										<Grid container spacing={4} className={classes.cardContentMarginRight}>
											<Grid item xs={12} sm={6}>
												<Grid
													container
													spacing={2}
													className={classes.margin_Bottom_30}
												>
												<Grid
													container
													spacing={2}
													className={classes.margin_Bottom_30}
												>

													<Grid item sm={12} xs={12}>
														<TextField
															required
															label="User name" 
															fullWidth
															value={username} 
															onChange={e => {setUsername(e.target.value.toLowerCase())}} />
													</Grid>
													<Grid item>
														<span style={{ color: "#4F2970" }}>
															 Please note that you will need this username to login 
														</span>
													</Grid>
												</Grid>
													<Grid item sm={12} xs={12}>
														<InputLabel id="demo-simple-select-label">Role *</InputLabel>
														{roleLocal === 2 ? (
															<Select
																required
																// variant="outlined"
																label="Select"
																size="small"
																fullWidth
																placeholder="Placeholder"
																value={role}
																style={{ height: 40 }}
																onChange={(e) => setRole(Number(e.target.value))}
															>
																<MenuItem value={3}>Author</MenuItem>
																<MenuItem value={4}>Data collecor</MenuItem>
															</Select>
														) : (
															<Select
																required
																label="Select"
																size="small"
																fullWidth
																placeholder="Placeholder"
																value={role}
																style={{ height: 40 }}
																onChange={(e) => {setRole(e.target.value)}}
															>
																<MenuItem value={1}>Super admin</MenuItem>
																<MenuItem value={2}>Admin</MenuItem>
																<MenuItem value={3}>Author</MenuItem>
																<MenuItem value={4}>Data collecor</MenuItem>
															</Select>
															
														)}
													</Grid>
												</Grid>
											</Grid>

											{/* BOTTOM RIGHT SIDE */}
											<Grid item xs={12} sm={6}>
												<Grid
													container
													spacing={2}
													className={classes.margin_Bottom_30}
												>
													<Grid item sm={12} xs={12}>
														<TextField
															required
															label="Password" 
															type={showPassword ? "text" : "password"}
															fullWidth
															value={password} 
															error={password !== confirmPassword && confirmPassword !== ''}
															helperText={password !== confirmPassword && confirmPassword !== '' ? 'Passwords do not match!' : ''}
															InputProps={{
																endAdornment: (
																<InputAdornment position="end">
																	<IconButton
																	onClick={() => setShowPassword(!showPassword)}
																	>
																	{showPassword ? <Visibility /> : <VisibilityOff />}
																	</IconButton>
																</InputAdornment>
																)
															}}
															onChange={e => {setPassword(e.target.value)}} 
															/>
													</Grid>
													<Grid item>
														<span style={{ color: "#4F2970" }}>
															 Please note that you need to write down the
															password seperately
														</span>
													</Grid>
												</Grid>

												<Grid
													container
													spacing={2}
													className={classes.margin_Bottom_30}
												>
													<Grid item sm={12} xs={12}>
														<TextField
															required
															label="Confirm assword" 
															type={showPassword ? "text" : "password"}
															fullWidth
															value={confirmPassword} 
															error={password !== confirmPassword && confirmPassword !== ''}
															helperText={password !== confirmPassword && confirmPassword !== '' ? 'Passwords do not match!' : ''}
															onChange={e => {setConfirmPassword(e.target.value)}}
															InputProps={{
																endAdornment: (
																<InputAdornment position="end">
																	<IconButton
																	onClick={() => setShowPassword(!showPassword)}
																	>
																	{showPassword ? <Visibility /> : <VisibilityOff />}
																	</IconButton>
																</InputAdornment>
																)
															}}
															/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										{/* BUTTON */}
										<Grid item xs={12} sm={12} className={classes.margin_Bottom_30}>
											<Button 
												variant="contained" 
												color="secondary"
												onClick={handleAddUser}
												disabled={isLoading || !isFormComplete}
												>
												{isLoading ? "SAVING..." : "SAVE"}
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
		</Grid>
	);
};

export default AddUser;
import React, { useState, useEffect } from "react";
import {
	Grid,
	Typography,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogContentText,
	DialogActions,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { ArrowRight } from "@material-ui/icons";
import useStyles from "./style";
//import { grp4, grp3, grp5, grp2, grp1 } from "../../widget/i1e";
import {
	BtnContained,
	BtnOutline,
	BtnText1,
	BtnLogout2,
} from "../../widget/util";
import { Link, useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const Modifiercase = () => {
	const classes = useStyles();
	const [org, setorg] = useState([]);
	const [profiles, setprofiles] = useState([]);
	const [infos, setinfos] = useState([]);
	const [perpetor, setperpetor] = useState([]);
	const [needAndCares, setneedAndCares] = useState([]);

	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const history = useHistory();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const annuler = () => {
		sessionStorage.removeItem("organization");
		sessionStorage.removeItem("profile");
		sessionStorage.removeItem("info");
		sessionStorage.removeItem("perpetor");
		sessionStorage.removeItem("need");

		history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect`);
	};

	const organisation = () => {
		history.push({
			pathname: `/${process.env.REACT_APP_COLLECT_LINK}/collect/form`,
			data: "org",
		});
	};
	const profile = () => {
		history.push({
			pathname: `/${process.env.REACT_APP_COLLECT_LINK}/collect/form/profile`,

			data: "profile",
		});
	};
	const info = () => {
		history.push({
			pathname: `/${process.env.REACT_APP_COLLECT_LINK}/collect/form/info`,

			data: "info",
		});
	};
	const perpretor = () => {
		history.push({
			pathname: `/${process.env.REACT_APP_COLLECT_LINK}/collect/form/perpretor`,

			data: "perpretor",
		});
	};
	const needAndCare = () => {
		history.push({
			pathname: `/${process.env.REACT_APP_COLLECT_LINK}/collect/form/besion`,
			data: "besion",
		});
	};
	const getOrg = () => {
		const data = sessionStorage.getItem("organization");
		// //console.log('data from local storage',JSON.parse(data));
		let org = JSON.parse(data);
		return setorg(org);
		////console.log(org.q3a);
	};

	const getPofile = () => {
		const data = sessionStorage.getItem("profile");
		////console.log('data from local storage',JSON.parse(data));
		return setprofiles(JSON.parse(data));
	};

	const getInfo = () => {
		const data = sessionStorage.getItem("info");
		////console.log('data from local storage',JSON.parse(data));
		return setinfos(JSON.parse(data));
	};

	const getPerpetor = () => {
		const data = sessionStorage.getItem("perpetor");
		// //console.log('data from local storage',JSON.parse(data));
		return setperpetor(JSON.parse(data));
	};

	const getNeedAndCares = () => {
		const data = sessionStorage.getItem("need");
		//  //console.log('data from local storage',JSON.parse(data));
		return setneedAndCares(JSON.parse(data));
	};

	useEffect(() => {
		getOrg();
		getPofile();
		getInfo();
		getPerpetor();
		getNeedAndCares();
	}, []);

	//console.log('Organisation',org);
	//console.log('profile',profiles);
	//console.log('info',infos);
	//console.log('perpetor',perpetor);
	//console.log('need and care',needAndCares);

	return (
		<div className={classes.root}>
			<Grid
				container
				justifyContent="center"
				spacing={1}
				style={{ marginBottom: 20 }}
			>
				<Grid
					item
					xs={11}
					className={classes.container}
					style={{ paddingLeft: 20 }}
				>
					<Typography align="left" className={classes.title}>
						MODIFIER
					</Typography>
					<Typography align="left" className={classes.subtitle}>
						Veuillez sélectionner une des sections ci-dessous
					</Typography>
					<Typography align="left" className={classes.subtitle}>
						pour les modifier.
					</Typography>
				</Grid>
			</Grid>
			<Grid container justifyContent="center" spacing={2}>
				<Grid item className={classes.content} xs={11}>
					<Grid container direction="row">
						<Grid item sm={1} xs={1}></Grid>
						<Grid item sm={10} xs={10} className={classes.marginTop_40}>
							<Grid container>
								<Grid item xs={11} sm={11}>
									<Grid container direction="column" spacing={2}>
										<Grid item xs={12} className={classes.edit}>
											<Grid
												container
												justifyContent="space-between"
												direction="row"
											>
												<Grid item className={classes.styleEdit}>
													{" "}
													{"I.\t\t\t"}IDENTIFICATION DE L'ORGANISATION
												</Grid>
												<Grid item>
													{" "}
													<Button variant="text" onClick={organisation}>
														<ArrowRight />
													</Button>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12} className={classes.edit}>
											<Grid
												container
												justifyContent="space-between"
												direction="row"
											>
												<Grid item className={classes.styleEdit}>
													{" "}
													{"II.\t\t\t"}PROFIL DE LA / DU SURVIVANT(E)
												</Grid>
												<Grid item>
													{" "}
													<Button onClick={profile}>
														<ArrowRight />
													</Button>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12} className={classes.edit}>
											<Grid
												container
												justifyContent="space-between"
												direction="row"
											>
												<Grid item className={classes.styleEdit}>
													{" "}
													{"III.\t\t\t"}INFORMATION SUR L'INCIDENT DE VIOLENCE
													DOMESTIQUE DOCUMENTE
												</Grid>
												<Grid item>
													{" "}
													<Button onClick={info}>
														<ArrowRight />
													</Button>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12} className={classes.edit}>
											<Grid
												container
												justifyContent="space-between"
												direction="row"
											>
												<Grid item className={classes.styleEdit}>
													{" "}
													{"IV.\t\t\t"}PROFIL DE L'AUTEUR DE L'ACTE DE VIOLENCE
													DOMESTIQUE
												</Grid>
												<Grid item>
													{" "}
													<Button onClick={perpretor}>
														<ArrowRight />
													</Button>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12} className={classes.edit}>
											<Grid
												container
												justifyContent="space-between"
												direction="row"
											>
												<Grid item className={classes.styleEdit}>
													{"V.\t\t\t"}SITUATION ET BESOINS DE PRISE EN CHARGE DE
													LA / DU SURVIVANT(E)
												</Grid>
												<Grid item>
													{" "}
													<Button onClick={needAndCare}>
														<ArrowRight />
													</Button>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								container
								spacing={1}
								direction="row"
								justifyContent="flex-end"
							>
								<Grid item className={classes.marginButton}>
									<BtnOutline
										content="ANNULER"
										onClick={() => {
											handleClickOpen();
											////console.log('hello elijah');
										}}
									/>
									<Dialog
										fullScreen={fullScreen}
										open={open}
										onClose={handleClose}
										aria-labelledby="responsive-dialog-title"
									>
										<DialogTitle id="responsive-dialog-title">
											{"Vous êtes sûr de vouloir annuler ?"}
										</DialogTitle>
										<DialogContent>
											<DialogContentText>
												Vous êtes sûr de vouloir annuler ? Toutes vos
												modifications et toutes vos données seront perdues et ne
												seront ni restaurées ni récupérées
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<BtnText1 content="NON" onClick={handleClose} />
											{/* <Button autoFocus onClick={handleClose} color="primary">
                                ANNULER
                            </Button> */}
											<BtnLogout2 content="OUI" onClick={annuler} />
										</DialogActions>
									</Dialog>

									<BtnContained
										component={Link}
										to={
											"/" + process.env.REACT_APP_COLLECT_LINK + "/collect/main"
										}
										content="REVOIR"
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item sm={1} xs={1}></Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};
export default Modifiercase;

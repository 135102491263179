import {alpha,makeStyles} from '@material-ui/core/styles'



export default makeStyles((theme)=>({

    //
    root:{ 
        backgroundColor: '#F1F2F5',
        width: '100vv',
        height: '100%',
        

        
   },
   appbar:{
      
       width:'100%',
       backgroundColor:'#4F2970'

   },

   select:{

       width:'400px',
       [theme.breakpoints.down('xs')]:{
         width: '300px'
       },
       paddingLeft:10,
       color:'white',
       border:'1px solid #FFF',
       borderRadius:4,
       "& .MuiSvgIcon-root": {
         color: "white",
       },

   },

     
     menuButton: {
       marginRight: theme.spacing(2),
     },
     titleAppBar: {
       flexGrow: 1,
       display: 'none',
       [theme.breakpoints.up('sm')]: {
         display: 'block',
       },
     },
     search: {
       position: 'relative',
       borderRadius: theme.shape.borderRadius,
       backgroundColor: alpha(theme.palette.common.white, 0.15),
       '&:hover': {
         backgroundColor: alpha(theme.palette.common.white, 0.25),
       },
       marginLeft: 0,
       width: '100%',
       [theme.breakpoints.up('sm')]: {
         marginLeft: theme.spacing(1),
         width: 'auto',
       },
     },
     searchIcon: {
       padding: theme.spacing(0, 2),
       height: '100%',
       position: 'absolute',
       pointerEvents: 'none',
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'center',
     },
     inputRoot: {
       color: 'inherit',
     },
     inputInput: {
       padding: theme.spacing(1, 1, 1, 0),
       // vertical padding + font size from searchIcon
       paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
       transition: theme.transitions.create('width'),
       width: '100%',
       [theme.breakpoints.up('sm')]: {
         width: '12ch',
         '&:focus': {
           width: '20ch',
         },
       },
     },

    //
  
    
    
    login:{
        backgroundColor: '#F1F2F5',
        width: '101vv',
        height: "100vh"
    },
    // appbar:{
    //     width:'100%',
    //     backgroundColor:'#4F2970'
    // },
    container:{
        marginTop: '70px',
        marginBottom: '20px',
         width: '940px',
         height: '220px',
        backgroundColor: 'rgba(0,161,175,0.12)',
    },
    title:{
        marginTop:'60px',
        color: '#4F2970',
        fontSize: '36px'
    },
    subtitle:{
        color: '#4F2970',
        fontSize: '20px'
    },
    bigtitle:{
        color: '#4F2970',
        fontSize: '26px' ,
        marginTop:'80px',
        marginBottom:'20px',
        marginLeft: '0px' ,
        paddingLeft:'0px',
        fontWeight:'bold',
    },
  
    bigtitles:{
        color: '#4F2970',
        fontSize: '26px' ,
        marginTop:'20px',
        marginBottom:'20px',
        marginLeft: '0px' ,
        paddingLeft:'0px',
        fontWeight:'bold',
    },
    content: {
        backgroundColor:'#FFFFFF',
        width: '940px',
        padding: '70px',
        marginBottom: '100px',
        [theme.breakpoints.down("xs")]:{
            paddding:0
        }
    },
    
  
    input:{
        width: '320px',
        backgroundColor: '#D3D3D3',
        color: 'rgba(0,0,0,0.4)'
        // height: '100px'
    },
    label:{
        fontSize: '15px',
        color: '#2D2C2F',
        fontWeight:'bold',
        marginBottom:"10px"
    },
    sublabel:{
        color: '#4F2970',
        fontSize: '14px',
        fontWeight:'regular',
        marginBottom:"10px"
    },
    subradio:{
        color: '#4F2970',
        fontSize: '14px',
        fontWeight:'regular'
    },
    radiolabel:{
        "& .MuiInputBase-root": {
            padding: 10
          }
    },

    textarea:{
        width:'100%',
        marginBottom: "30px"
    },


    edit:{
        backgroundColor:'rgba(209,32,40,0.12)',
        marginBottom: "30px",
        
    },
   
    spacedown: {
        marginBottom: "30px"
    },
    marginTop_10: {
        marginTop: "10px"
    },
    marginTop_20: {
        marginTop: "20px"
    },
    marginTop_30: {
        marginTop: "30px"
    },
    marginTop_40: {
        marginTop: "40px"
    },
    marginTop_50: {
        marginTop: "50px"
    },
    marginBottom_10: {
        marginBottom: "10px"
    },
    marginBottom_20: {
        marginBottom: "20px"
    },
    marginBottom_30: {
        marginBottom: "30px"
    },
    marginBottom_40: {
        marginBottom: "40px"
    },
    marginBottom_50: {
        marginBottom: "50px"
    },
    marginButton: {
        marginBottom: "30px",
        marginTop: "30px"
    },
    foot:{
        marginTop:250,
        fontSize:12,
        fontWeight:400,
        marginBottom:10
      ,
      },

    styleEdit:{
        paddingTop:10,
        color: '#4F2970',

      },

      marginTop_20s: {
        marginTop: "20px",
        height:40
    },


}))
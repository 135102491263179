import React from 'react';
import {
     Grid,
     Typography,
    } from '@material-ui/core'
import useStyles from './style'
import { useTranslation } from 'react-i18next';



const Section1=()=>{
    const classes = useStyles();
    const { t } = useTranslation();

    return(
        <div>
           
                <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography className={classes.big_white_txt} >
                         {t('case-databank')}
                        </Typography>
                        
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography className={classes.white_color}>
                        {t('case-databank-text')}
                        </Typography>
                    </Grid>
                </Grid>
            
        </div>
    )
}

export default Section1;
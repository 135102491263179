import React, { useState, useEffect } from "react";
import useStyles from "../styles";
import {
	Grid,
	Typography,
	TextField,
	Card,
	CardContent,
	Tab,
	Tabs,
	Box,
	InputAdornment,
	Divider,
	CircularProgress,
	Button,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { withRouter, useHistory } from "react-router-dom";
import axiosInstance from "../../../service/dashboardAxiosApi";
import SearchIcon from '@material-ui/icons/Search';

 
const columns = [
	{ field: "id", headerName: "No", width: 80 },
	{ field: "first_name", headerName: "FIRSTNAME", width: 140 },
	{ field: "last_name", headerName: "LASTNAME", width: 170 },
	{ field: "username", headerName: "USERNAME", width: 120 },
	{ field: "email", headerName: "EMAIL ", width: 310 },
	{ field: "role", headerName: "ROLE", width: 230 },
	{ field: "organization", headerName: "ORGANISATION", width: 190 },
	{ field: "abbreviation", headerName: "ABBREVIATION", width: 100 },
	{ field: "phone_number", headerName: "PHONE", width: 140 },
	{ field: "address", headerName: "ADDRESS", width: 140 },
	{ field: "location", headerName: "LOCATION", width: 140 },
	{ field: "created_by", headerName: "CREATED BY ", width: 140 },
];

// TabPanel component
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
}


const Users = () => {
	const classes = useStyles();
	const history = useHistory();
	const roleLocal = sessionStorage.getItem("role");
	const [users, setUsers] = useState([]);
	const [value, setValue] = useState(0);
	const [loading, setLoading] = useState(false);
 	const [networkError, setNetworkError] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		const fetchData = async () => {
		  setLoading(true);
		  try {
			const response = await axiosInstance.get('users/');
			setUsers(response.data.users);
			setLoading(false);
		  } catch (error) {
			if (error.message === "Network Error") {
			  setNetworkError(true);
			}
			// console.error('Something went wrong!', error);
			setLoading(false);
		  }
		};
	
		fetchData();
	  }, []);


	const handleChange = (event, newValue) => {
		setValue(newValue);
	};


	const handleSearchChange = (e) => {
		setSearchTerm(e.target.value);
	  }
	
	const handleRowClick = (params) => {
		history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/user/detail/${params.row.id}`);
	  };

	const handleAddUser = () => {
		history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/user/add/`); 
	  };

	const adminUsers = users.filter(user => (user.role === 'Admin' || user.role === 'Super Admin' || user.role === 'Author') && user.is_active && user.username.toLowerCase().includes(searchTerm.toLowerCase()));
	const deactivatedUsers = users.filter(user => !user.is_active && user.username.toLowerCase().includes(searchTerm.toLowerCase()));
	const dataCollectors = users.filter(user => user.role === 'Data Collector' && user.is_active && user.username.toLowerCase().includes(searchTerm.toLowerCase()));


	return (
		<Card className={classes.fullWidthCard}>
			<CardContent className={classes.leftAndRightMiddle}>
		{/* TITLE */}
			<Grid container direction="row">
				<Grid item xs={12} sm={12}>
					<Typography variant="h4" className={classes.bigtitle}>
						Users
					</Typography>
				</Grid>
			</Grid>
			<Divider className={classes.bottom_margin} />
			<Grid container spacing={2} className={classes.bottom_margin}>
				{/* SEARCH FIELD */}
				<Grid item>
					<TextField 
						label="Search.." 
						id="filled-size-small"
						size="small"
						// fullWidth 
						variant="outlined" 
						value={searchTerm} 
						onChange={handleSearchChange} 
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<SearchIcon />
								</InputAdornment>
							),
						}}/>
				</Grid>
				{/* ADD A NEW USER BUTTON */}
				{roleLocal <= 2 ? <Grid item>
				<Button variant="contained" color="primary" onClick={handleAddUser}>Add New User</Button>
				</Grid> : null}
			</Grid>

			{/* TABS */}
			<Grid container direction="row">
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Tabs 
					value={value} 
					onChange={handleChange} 
					variant="fullWidth"
					TabIndicatorProps={{
						className: classes.indicatedColor,
						}}>
						<Tab label={<div>
							Admin Users 
							<span className={classes.tabDataCount0}>
								{adminUsers.length}
							</span>
						</div>}  />
						<Tab label={<div>
							Data Collectors
							<span className={classes.tabDataCount1}>
								{dataCollectors.length}
							</span>
						</div>}  />
						<Tab label={<div>
							Deactivated Users  
							<span className={classes.tabDataCount2}>
								{deactivatedUsers.length}
							</span>
						</div>}  />
					</Tabs>
				</Grid>	
			</Grid>
			
			{networkError ? 
				<div>
				Network error occurred. Please reload the page.
				<button onClick={() => window.location.reload()}>Reload</button>
				</div> :

				// TAB PANNELS
				<Grid container justifyContent="flex-start" className={classes.table}>
						<Grid item xs={12} sm={12}>
							{/* value={value} */}
							<TabPanel value={value} index={0}>
								{loading ? <CircularProgress /> :
								<div style={{ height: 600, width: '100%' }}>
									<DataGrid 
										rows={adminUsers} 
										columns={columns} 
										pageSize={10} 
										onRowClick={(event) => {
											if(roleLocal <= 2) {
												handleRowClick(event)
											}
										}} />
								</div>
								}
							</TabPanel>

							<TabPanel value={value} index={1}>
								{loading ? <CircularProgress /> :
								<div style={{ height: 600, width: '100%' }}>
									<DataGrid 
										rows={dataCollectors}  
										columns={columns} 
										pageSize={10} 
										onRowClick={(event) => {
											if(roleLocal <= 2) {
												handleRowClick(event)
											}
										}} 
									/>
								</div>
								}
							</TabPanel> 

							<TabPanel value={value} index={2}>
								{loading ? <CircularProgress /> :
								<div style={{ height: 600, width: '100%' }}>
									<DataGrid 
										rows={deactivatedUsers}
										columns={columns} 
										pageSize={10} 
										onRowClick={(event) => {
											if(roleLocal <= 2) {
												handleRowClick(event)
											}
										}}
									/>
								</div>
								}
							</TabPanel>
						</Grid>
					</Grid>

			}
		  </CardContent>
		</Card>
	);
};

export default withRouter(Users);
 
import React, { useState, useEffect } from "react";
import {
	AppBar,
	Toolbar,
	IconButton,
	Select,
	MenuItem,
	Grid,
	Typography,
	Box,
	TextField,
	RadioGroup,
	Radio,
	FormControlLabel,
	FormGroup,
	Checkbox,
	FormControl,
} from "@material-ui/core";
import useStyles from "./style";
import {
	q14,
	q14a,
	q14b,
	q14c,
	q15,
	precedent,
	suivant,
	grp4,
} from "../../widget/i1e";
import { BtnContained, BtnOutline } from "../../widget/util";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Perpretor = () => {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const [orgData, setorgData] = useState([]);
	const [getAuteur, setgetAuteur] = useState([]);
	const [q14avalue, setq14avalue] = useState();
	const [q14bvalue, setq14bvalue] = useState();
	const [q15value, setq15value] = useState();
	const [openSnackBar, setOpenSnackbar] = useState(false);
	const [open, setOpen] = useState(false);
	const [msg, setmsg] = useState();
	//controler for the navbar

	const [select, setSelect] = useState(5);
	const handleChange = (event) => {
		setSelect(event.target.value);
	};
	//

	// snackbar
	const handleClose = () => {
		setOpen(false);
	};

	const handleSnackBar = () => {
		setOpenSnackbar(true);
	};

	const handleCloseSnackBar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpenSnackbar(false);
	};
	//q14a
	const [q14aActivator, setq14aActivator] = useState(false);
	const [mere, setmere] = useState();
	const [mereChecked, setmereChecked] = useState(false);
	const q14amere = (e) => {
		const value = e.target.value;

		if (!mereChecked) {
			setmere(value);
			setmereChecked(true);
			setq14aActivator(true);
			// console.log(mereChecked);
			// console.log(mere);
		} else {
			setmere();
			setmereChecked(false);
			setq14aActivator(false);
			// console.log(mereChecked);
			// console.log(mere);
		}
	};
	const [pere, setpere] = useState();
	const [pereChecked, setpereChecked] = useState(false);
	const q14apere = (e) => {
		const value = e.target.value;

		if (!pereChecked) {
			setpere(value);
			setpereChecked(true);
			setq14aActivator(true);
			// console.log(pereChecked);
			// console.log(pere);
		} else {
			setpere();
			setpereChecked(false);
			setq14aActivator(false);
			// console.log(pereChecked);
			// console.log(pere);
		}
	};
	const [gardienHomme, setgardienHomme] = useState();
	const [gardienHommeChecked, setgardienHommeChecked] = useState(false);
	const q14agardienHomme = (e) => {
		const value = e.target.value;

		if (!gardienHommeChecked) {
			setgardienHomme(value);
			setgardienHommeChecked(true);
			setq14aActivator(true);
			// console.log(gardienHommeChecked);
			// console.log(gardienHomme);
		} else {
			setgardienHomme();
			setgardienHommeChecked(false);
			setq14aActivator(false);
			// console.log(gardienHommeChecked);
			// console.log(gardienHomme);
		}
	};

	const [gardienneFemme, setgardienneFemme] = useState();
	const [gardienneFemmeChecked, setgardienneFemmeChecked] = useState(false);
	const q14agardienneFemme = (e) => {
		const value = e.target.value;

		if (!gardienneFemmeChecked) {
			setgardienneFemme(value);
			setgardienneFemmeChecked(true);
			setq14aActivator(true);
			// console.log(gardienneFemmeChecked);
			// console.log(gardienneFemme);
		} else {
			setgardienneFemme();
			setgardienneFemmeChecked(false);
			setq14aActivator(false);
			// console.log(gardienneFemmeChecked);
			// console.log(gardienneFemme);
		}
	};
	const [propreFrere, setpropreFrere] = useState();
	const [propreFrereChecked, setpropreFrereChecked] = useState(false);
	const q14apropreFrere = (e) => {
		const value = e.target.value;

		if (!propreFrereChecked) {
			setpropreFrere(value);
			setpropreFrereChecked(true);
			setq14aActivator(true);
			// console.log(propreFrereChecked);
			// console.log(propreFrere);
		} else {
			setpropreFrere();
			setpropreFrereChecked(false);
			setq14aActivator(false);
			// console.log(propreFrereChecked);
			// console.log(propreFrere);
		}
	};

	const [propreSoeur, setpropreSoeur] = useState();
	const [propreSoeurChecked, setpropreSoeurChecked] = useState(false);
	const q14apropreSoeur = (e) => {
		const value = e.target.value;

		if (!propreSoeurChecked) {
			setpropreSoeur(value);
			setpropreSoeurChecked(true);
			setq14aActivator(true);
			// console.log(propreSoeurChecked);
			// console.log(propreSoeur);
		} else {
			setpropreSoeur();
			setpropreSoeurChecked(false);
			setq14aActivator(false);
			// console.log(propreSoeurChecked);
			// console.log(propreSoeur);
		}
	};
	const [propreEnfantAdulte, setpropreEnfantAdulte] = useState();
	const [propreEnfantAdulteChecked, setpropreEnfantAdulteChecked] =
		useState(false);
	const q14apropreEnfantAdulte = (e) => {
		const value = e.target.value;

		if (!propreEnfantAdulteChecked) {
			setpropreEnfantAdulte(value);
			setpropreEnfantAdulteChecked(true);
			setq14aActivator(true);
			// console.log(propreEnfantAdulteChecked);
			// console.log(propreEnfantAdulte);
		} else {
			setpropreEnfantAdulte();
			setpropreEnfantAdulteChecked(false);
			setq14aActivator(false);
			// console.log(propreEnfantAdulteChecked);
			// console.log(propreEnfantAdulte);
		}
	};

	const [gdMere, setgdMere] = useState();
	const [gdMereChecked, setgdMereChecked] = useState(false);
	const q14agdMere = (e) => {
		const value = e.target.value;

		if (!gdMereChecked) {
			setgdMere(value);
			setgdMereChecked(true);
			setq14aActivator(true);
			// console.log(gdMereChecked);
			// console.log(gdMere);
		} else {
			setgdMere();
			setgdMereChecked(false);
			setq14aActivator(false);
			// console.log(gdMereChecked);
			// console.log(gdMere);
		}
	};
	const [gdPere, setgdPere] = useState();
	const [gdPereChecked, setgdPereChecked] = useState(false);
	const q14agdPere = (e) => {
		const value = e.target.value;

		if (!gdPereChecked) {
			setgdPere(value);
			setgdPereChecked(true);
			setq14aActivator(true);
			// console.log(gdPereChecked);
			// console.log(gdPere);
		} else {
			setgdPere();
			setgdPereChecked(false);
			setq14aActivator(false);
			// console.log(gdPereChecked);
			// console.log(gdPere);
		}
	};

	const [belleMere, setbelleMere] = useState();
	const [belleMereChecked, setbelleMereChecked] = useState(false);
	const q14abelleMere = (e) => {
		const value = e.target.value;

		if (!belleMereChecked) {
			setbelleMere(value);
			setbelleMereChecked(true);
			setq14aActivator(true);
			// console.log(belleMereChecked);
			// console.log(belleMere);
		} else {
			setbelleMere();
			setbelleMereChecked(false);
			setq14aActivator(false);
			// console.log(belleMereChecked);
			// console.log(belleMere);
		}
	};

	const [beauPere, setbeauPere] = useState();
	const [beauPereChecked, setbeauPereChecked] = useState(false);
	const q14abeauPere = (e) => {
		const value = e.target.value;

		if (!beauPereChecked) {
			setbeauPere(value);
			setbeauPereChecked(true);
			setq14aActivator(true);
			// console.log(beauPereChecked);
			// console.log(beauPere);
		} else {
			setbeauPere();
			setbeauPereChecked(false);
			setq14aActivator(false);
			// console.log(beauPereChecked);
			// console.log(beauPere);
		}
	};

	const [beauxFere, setbeauxFere] = useState();
	const [beauxFereChecked, setbeauxFereChecked] = useState(false);
	const q14abeauxFere = (e) => {
		const value = e.target.value;

		if (!beauxFereChecked) {
			setbeauxFere(value);
			setbeauxFereChecked(true);
			setq14aActivator(true);
			// console.log(beauxFereChecked);
			// console.log(beauxFere);
		} else {
			setbeauxFere();
			setbeauxFereChecked(false);
			setq14aActivator(false);
			// console.log(beauxFereChecked);
			// console.log(beauxFere);
		}
	};

	const [belleSoeur, setbelleSoeur] = useState();
	const [belleSoeurChecked, setbelleSoeurChecked] = useState(false);
	const q14abelleSoeur = (e) => {
		const value = e.target.value;

		if (!belleSoeurChecked) {
			setbelleSoeur(value);
			setbelleSoeurChecked(true);
			setq14aActivator(true);
			// console.log(belleSoeurChecked);
			// console.log(belleSoeur);
		} else {
			setbelleSoeur();
			setbelleSoeurChecked(false);
			setq14aActivator(false);
			// console.log(belleSoeurChecked);
			// console.log(belleSoeur);
		}
	};

	const [oncle, setoncle] = useState();
	const [oncleChecked, setoncleChecked] = useState(false);
	const q14aoncle = (e) => {
		const value = e.target.value;

		if (!oncleChecked) {
			setoncle(value);
			setoncleChecked(true);
			setq14aActivator(true);
			// console.log(oncleChecked);
			// console.log(oncle);
		} else {
			setoncle();
			setoncleChecked(false);
			setq14aActivator(false);
			// console.log(oncleChecked);
			// console.log(oncle);
		}
	};

	const [tante, settante] = useState();
	const [tanteChecked, settanteChecked] = useState(false);
	const q14atante = (e) => {
		const value = e.target.value;

		if (!tanteChecked) {
			settante(value);
			settanteChecked(true);
			setq14aActivator(true);
			// console.log(tanteChecked);
			// console.log(tante);
		} else {
			settante();
			settanteChecked(false);
			setq14aActivator(false);
			// console.log(tanteChecked);
			// console.log(tante);
		}
	};

	const [autreMember, setautreMember] = useState();
	const [autreMemberChecked, setautreMemberChecked] = useState(false);
	const q14aautreMember = (e) => {
		const value = e.target.value;

		if (!autreMemberChecked) {
			setautreMember(value);
			setautreMemberChecked(true);
			setq14aActivator(true);
			// console.log(autreMemberChecked);
			// console.log(autreMember);
		} else {
			setautreMember();
			setautreMemberChecked(false);
			setq14aActivator(false);
			// console.log(autreMemberChecked);
			// console.log(autreMember);
		}
	};

	//q14b
	const [q14bActivator, setq14bActivator] = useState(false);
	const [mari, setmari] = useState();
	const [mariChecked, setmariChecked] = useState(false);
	const q14bmari = (e) => {
		const value = e.target.value;

		if (!mariChecked) {
			setmari(value);
			setmariChecked(true);
			setq14bActivator(true);
			// console.log(mariChecked);
			// console.log(mari);
		} else {
			setmari();
			setmariChecked(false);
			setq14bActivator(false);
			// console.log(mariChecked);
			// console.log(mari);
		}
	};

	const [exmari, setexmari] = useState();
	const [exmariChecked, setexmariChecked] = useState(false);
	const q14bexmari = (e) => {
		const value = e.target.value;

		if (!exmariChecked) {
			setexmari(value);
			setexmariChecked(true);
			setq14bActivator(true);
			// console.log(exmariChecked);
			// console.log(exmari);
		} else {
			setexmari();
			setexmariChecked(false);
			setq14bActivator(false);
			// console.log(exmariChecked);
			// console.log(exmari);
		}
	};
	const [epouse, setepouse] = useState();
	const [epouseChecked, setepouseChecked] = useState(false);
	const q14bepouse = (e) => {
		const value = e.target.value;

		if (!epouseChecked) {
			setepouse(value);
			setepouseChecked(true);
			setq14bActivator(true);
			// console.log(epouseChecked);
			// console.log(epouse);
		} else {
			setepouse();
			setepouseChecked(false);
			setq14bActivator(false);
			// console.log(epouseChecked);
			// console.log(epouse);
		}
	};
	const [exEpouse, setexEpouse] = useState();
	const [exEpouseChecked, setexEpouseChecked] = useState(false);
	const q14bexEpouse = (e) => {
		const value = e.target.value;

		if (!exEpouseChecked) {
			setexEpouse(value);
			setexEpouseChecked(true);
			setq14bActivator(true);
			// console.log(exEpouseChecked);
			// console.log(exEpouse);
		} else {
			setexEpouse();
			setexEpouseChecked(false);
			setq14bActivator(false);
			// console.log(exEpouseChecked);
			// console.log(exEpouse);
		}
	};

	const [partenaireHomme, setpartenaireHomme] = useState();
	const [partenaireHommeChecked, setpartenaireHommeChecked] = useState(false);
	const q14bpartenaireHomme = (e) => {
		const value = e.target.value;

		if (!partenaireHommeChecked) {
			setpartenaireHomme(value);
			setpartenaireHommeChecked(true);
			setq14bActivator(true);
			// console.log(partenaireHommeChecked);
			// console.log(partenaireHomme);
		} else {
			setpartenaireHomme();
			setpartenaireHommeChecked(false);
			setq14bActivator(false);
			// console.log(partenaireHommeChecked);
			// console.log(partenaireHomme);
		}
	};

	const [partenaireFemme, setpartenaireFemme] = useState();
	const [partenaireFemmeChecked, setpartenaireFemmeChecked] = useState(false);
	const q14bpartenaireFemme = (e) => {
		const value = e.target.value;

		if (!partenaireFemmeChecked) {
			setpartenaireFemme(value);
			setpartenaireFemmeChecked(true);
			setq14bActivator(true);
			// console.log(partenaireFemmeChecked);
			// console.log(partenaireFemme);
		} else {
			setpartenaireFemme();
			setpartenaireFemmeChecked(false);
			setq14bActivator(false);
			// console.log(partenaireFemmeChecked);
			// console.log(partenaireFemme);
		}
	};

	const [exPaternaireHomme, setexPaternaireHomme] = useState();
	const [exPaternaireHommeChecked, setexPaternaireHommeChecked] =
		useState(false);
	const q14bexPaternaireHomme = (e) => {
		const value = e.target.value;

		if (!exPaternaireHommeChecked) {
			setexPaternaireHomme(value);
			setexPaternaireHommeChecked(true);
			setq14bActivator(true);
			// console.log(exPaternaireHommeChecked);
			// console.log(exPaternaireHomme);
		} else {
			setexPaternaireHomme();
			setexPaternaireHommeChecked(false);
			setq14bActivator(false);
			// console.log(exPaternaireHommeChecked);
			// console.log(exPaternaireHomme);
		}
	};

	const [exPaternaireFemme, setexPaternaireFemme] = useState();
	const [exPaternaireFemmeChecked, setexPaternaireFemmeChecked] =
		useState(false);
	const q14bexPaternaireFemme = (e) => {
		const value = e.target.value;

		if (!exPaternaireFemmeChecked) {
			setexPaternaireFemme(value);
			setexPaternaireFemmeChecked(true);
			setq14bActivator(true);
			// console.log(exPaternaireFemmeChecked);
			// console.log(exPaternaireFemme);
		} else {
			setexPaternaireFemme();
			setexPaternaireFemmeChecked(false);
			setq14bActivator(false);
			// console.log(exPaternaireFemmeChecked);
			// console.log(exPaternaireFemme);
		}
	};

	//q14c

	const [q14cActivator, setq14cActivator] = useState(false);

	const [patron, setpatron] = useState();
	const [patronChecked, setpatronChecked] = useState(false);
	const q14cpatron = (e) => {
		const value = e.target.value;

		if (!patronChecked) {
			setpatron(value);
			setpatronChecked(true);
			setq14cActivator(true);
			// console.log(patronChecked);
			// console.log(patron);
		} else {
			setpatron();
			setpatronChecked(false);
			setq14cActivator(false);
			// console.log(patronChecked);
			// console.log(patron);
		}
	};

	const [patronne, setpatronne] = useState();
	const [patronneChecked, setpatronneChecked] = useState(false);
	const q14cpatronne = (e) => {
		const value = e.target.value;

		if (!patronneChecked) {
			setpatronne(value);
			setpatronneChecked(true);
			setq14cActivator(true);
			// console.log(patronneChecked);
			// console.log(patronne);
		} else {
			setpatronne();
			setpatronneChecked(false);
			setq14cActivator(false);
			// console.log(patronneChecked);
			// console.log(patronne);
		}
	};
	const [personnelHome, setpersonnelHome] = useState();
	const [personnelHomeChecked, setpersonnelHomeChecked] = useState(false);
	const q14cpersonnelHome = (e) => {
		const value = e.target.value;

		if (!personnelHomeChecked) {
			setpersonnelHome(value);
			setpersonnelHomeChecked(true);
			setq14cActivator(true);
			// console.log(personnelHomeChecked);
			// console.log(personnelHome);
		} else {
			setpersonnelHome();
			setpersonnelHomeChecked(false);
			setq14cActivator(false);
			// console.log(personnelHomeChecked);
			// console.log(personnelHome);
		}
	};

	const [personnelFemme, setpersonnelFemme] = useState();
	const [personnelFemmeChecked, setpersonnelFemmeChecked] = useState(false);
	const q14cpersonnelFemme = (e) => {
		const value = e.target.value;

		if (!personnelFemmeChecked) {
			setpersonnelFemme(value);
			setpersonnelFemmeChecked(true);
			setq14cActivator(true);
			// console.log(personnelFemmeChecked);
			// console.log(personnelFemme);
		} else {
			setpersonnelFemme();
			setpersonnelFemmeChecked(false);
			setq14cActivator(false);
			// console.log(personnelFemmeChecked);
			// console.log(personnelFemme);
		}
	};

	const [bailleur, setbailleur] = useState();
	const [bailleurChecked, setbailleurChecked] = useState(false);
	const q14cbailleur = (e) => {
		const value = e.target.value;

		if (!bailleurChecked) {
			setbailleur(value);
			setbailleurChecked(true);
			setq14cActivator(true);
			// console.log(bailleurChecked);
			// console.log(bailleur);
		} else {
			setbailleur();
			setbailleurChecked(false);
			setq14cActivator(false);
			// console.log(bailleurChecked);
			// console.log(bailleur);
		}
	};

	const [bailleresse, setbailleresse] = useState();
	const [bailleresseChecked, setbailleresseChecked] = useState(false);
	const q14cbailleresse = (e) => {
		const value = e.target.value;

		if (!bailleresseChecked) {
			setbailleresse(value);
			setbailleresseChecked(true);
			setq14cActivator(true);
			// console.log(bailleresseChecked);
			// console.log(bailleresse);
		} else {
			setbailleresse();
			setbailleresseChecked(false);
			setq14cActivator(false);
			// console.log(bailleresseChecked);
			// console.log(bailleresse);
		}
	};

	const [autreMemberDuCercle, setautreMemberDuCercle] = useState();
	const [autreMemberDuCercleChecked, setautreMemberDuCercleChecked] =
		useState(false);
	const q14cautreMemberDuCercle = (e) => {
		const value = e.target.value;

		if (!autreMemberDuCercleChecked) {
			setautreMemberDuCercle(value);
			setautreMemberDuCercleChecked(true);
			setq14cActivator(true);
			// console.log(autreMemberDuCercleChecked);
			// console.log(autreMemberDuCercle);
		} else {
			setautreMemberDuCercle();
			setautreMemberDuCercleChecked(false);
			setq14cActivator(false);
			// console.log(autreMemberDuCercleChecked);
			// console.log(autreMemberDuCercle);
		}
	};

	//q15

	const [q15Activator, setq15Activator] = useState(false);

	const [memeMenage, setmemeMenage] = useState();
	const [memeMenageChecked, setmemeMenageChecked] = useState(false);
	const q15memeMenage = (e) => {
		const value = e.target.value;

		if (!memeMenageChecked) {
			setmemeMenage(value);
			setmemeMenageChecked(true);
			setq15Activator(true);
			// console.log(memeMenageChecked);
			// console.log(memeMenage);
		} else {
			setmemeMenage();
			setmemeMenageChecked(false);
			setq15Activator(false);
			// console.log(memeMenageChecked);
			// console.log(memeMenage);
		}
	};

	const [memeparcel, setmemeparcel] = useState();
	const [memeparcelChecked, setmemeparcelChecked] = useState(false);
	const q15memeparcel = (e) => {
		const value = e.target.value;

		if (!memeparcelChecked) {
			setmemeparcel(value);
			setmemeparcelChecked(true);
			setq15Activator(true);
			// console.log(memeparcelChecked);
			// console.log(memeparcel);
		} else {
			setmemeparcel();
			setmemeparcelChecked(false);
			setq15Activator(false);
			// console.log(memeparcelChecked);
			// console.log(memeparcel);
		}
	};

	const [maisonDEnfant, setmaisonDEnfant] = useState();
	const [maisonDEnfantChecked, setmaisonDEnfantChecked] = useState(false);
	const q15maisonDEnfant = (e) => {
		const value = e.target.value;

		if (!maisonDEnfantChecked) {
			setmaisonDEnfant(value);
			setmaisonDEnfantChecked(true);
			setq15Activator(true);
			// console.log(maisonDEnfantChecked);
			// console.log(maisonDEnfant);
		} else {
			setmaisonDEnfant();
			setmaisonDEnfantChecked(false);
			setq15Activator(false);
			// console.log(maisonDEnfantChecked);
			// console.log(maisonDEnfant);
		}
	};

	const [campDeRefugie, setcampDeRefugie] = useState();
	const [campDeRefugieChecked, setcampDeRefugieChecked] = useState(false);
	const q15campDeRefugie = (e) => {
		const value = e.target.value;

		if (!campDeRefugieChecked) {
			setcampDeRefugie(value);
			setcampDeRefugieChecked(true);
			setq15Activator(true);
			// console.log(campDeRefugieChecked);
			// console.log(campDeRefugie);
		} else {
			setcampDeRefugie();
			setcampDeRefugieChecked(false);
			setq15Activator(false);
			// console.log(campDeRefugieChecked);
			// console.log(campDeRefugie);
		}
	};
	const [q14bool, setq14bool] = useState(false);
	const q14aCheck = (e) => {
		const value = e.target.value;
		if (value === "A") {
			// console.log('q14a',value);
			setq14avalue(value);
			setq14bool(true);
			setq14aActivator(false);
		} else {
			// console.log('q14a',value);
			setq14aActivator(true);
			setq14avalue(value);
			setq14bool(true);
			setmere();
			setmereChecked(false);
			setpere();
			setpereChecked(false);
			setgardienHomme();
			setgardienHommeChecked(false);
			setgardienneFemme();
			setgardienneFemmeChecked(false);
			setpropreFrere();
			setpropreFrereChecked(false);
			setpropreSoeur();
			setpropreSoeurChecked(false);
			setpropreEnfantAdulte();
			setpropreEnfantAdulteChecked(false);
			setgdMere();
			setgdMereChecked(false);
			setgdPere();
			setgdPereChecked(false);
			setbelleMere();
			setbelleMereChecked(false);
			setbeauPere();
			setbeauPereChecked(false);
			setbeauxFere();
			setbeauxFereChecked(false);
			setbelleSoeur();
			setbelleSoeurChecked(false);
			setoncle();
			setoncleChecked(false);
			settante();
			settanteChecked(false);
			setautreMember();
			setautreMemberChecked(false);
		}
		//// console.log('q14a',value);
		// setq14avalue(value)
	};
	const [q14bbool, setq14bbool] = useState(false);
	const q14bCheck = (e) => {
		const value = e.target.value;
		if (value === "A") {
			// console.log('q14b',value);
			setq14bvalue(value);
			setq14bbool(true);
			setq14bActivator(false);
		} else {
			// console.log('q14b',value);
			setq14bActivator(true);
			setq14bvalue(value);
			setq14bbool(true);
			setmari();
			setmariChecked(false);
			setexmari();
			setexmariChecked(false);
			setepouse();
			setepouseChecked(false);
			setexEpouse();
			setexEpouseChecked(false);
			setpartenaireHomme();
			setpartenaireHommeChecked(false);
			setpartenaireFemme();
			setpartenaireFemmeChecked(false);
			setexPaternaireHomme();
			setexPaternaireHommeChecked(false);
			setexPaternaireFemme();
			setexPaternaireFemmeChecked(false);
		}
	};
	const [q15bool, setq15bool] = useState(false);
	const q15Check = (e) => {
		const value = e.target.value;
		if (value === "A") {
			// console.log('q15',value);
			setq15value(value);
			setq15bool(true);
			setq15Activator(false);
		} else {
			// console.log('q15',value);
			setq15bool(true);
			setq15Activator(true);
			setq15value(value);
			setmemeMenage();
			setmemeMenageChecked(false);
			setmemeparcel();
			setmemeparcelChecked(false);
			setmaisonDEnfant();
			setmaisonDEnfantChecked(false);
			setcampDeRefugie();
			setcampDeRefugieChecked(false);
			setq15autr();
			setq15Act(false);
		}
	};

	const [q15autr, setq15autr] = useState();
	const [q15Act, setq15Act] = useState(false);
	const q15autreCheck = (e) => {
		const value = e.target.value;
		if (!q15Act) {
			// console.log("q15 autre",value);
			setq15autr(value);
			setq15Act(true);
			setq15Activator(true);
		} else {
			// console.log("q15 autre",value);
			setq15autr();
			setq15Act(false);
			setq15Activator(false);
		}
	};

	const { register, handleSubmit, reset } = useForm({
		mode: "onBlur",
	});

	const onSubmit = (data, e) => {
		e.preventDefault();
		//// console.log(data);

		if (
			q14bool !== false &&
			q14aActivator !== false &&
			q14bbool !== false &&
			q14bActivator !== false &&
			q15bool !== false &&
			q15Activator !== false
		) {
			const perpetor = [
				{
					q14a: q14avalue,
					q14abool: q14bool,
					q14aActivator: q14aActivator,
					q14aOui: [
						`${mere !== null ? mere : ""}`,
						`${pere !== null ? pere : ""}`,
						`${gardienHomme !== null ? gardienHomme : ""}`,
						`${gardienneFemme !== null ? gardienneFemme : ""}`,
						`${propreFrere !== null ? propreFrere : ""}`,
						`${propreSoeur !== null ? propreSoeur : ""}`,
						`${propreEnfantAdulte !== null ? propreEnfantAdulte : ""}`,
						`${gdMere !== null ? gdMere : ""}`,
						`${gdPere !== null ? gdPere : ""}`,
						`${belleMere !== null ? belleMere : ""}`,
						`${beauPere !== null ? beauPere : ""}`,
						`${beauxFere !== null ? beauxFere : ""}`,
						`${belleSoeur !== null ? belleSoeur : ""}`,
						`${oncle !== null ? oncle : ""}`,
						`${tante !== null ? tante : ""}`,
						`${autreMember !== null ? autreMember : ""}`,
					],
					//
					q14aOuip: [
						`${mere !== null ? mere : ""}`,
						`${pere !== null ? pere : ""}`,
						`${gardienHomme !== null ? gardienHomme : ""}`,
						`${gardienneFemme !== null ? gardienneFemme : ""}`,
						`${propreFrere !== null ? propreFrere : ""}`,
						`${propreSoeur !== null ? propreSoeur : ""}`,
						`${propreEnfantAdulte !== null ? propreEnfantAdulte : ""}`,
						`${gdMere !== null ? gdMere : ""}`,
						`${gdPere !== null ? gdPere : ""}`,
						`${belleMere !== null ? belleMere : ""}`,
						`${beauPere !== null ? beauPere : ""}`,
						`${beauxFere !== null ? beauxFere : ""}`,
						`${belleSoeur !== null ? belleSoeur : ""}`,
						`${oncle !== null ? oncle : ""}`,
						`${tante !== null ? tante : ""}`,
						`${autreMember !== null ? autreMember : ""}`,
					]
						.toString()
						.replace(/['"]+/g, ""),
					q14b: q14bvalue,
					q14bbool: q14bbool,
					q14bActivator: q14bActivator,
					q14bOui: [
						`${mari !== null ? mari : ""}`,
						`${exmari !== null ? exmari : ""}`,
						`${epouse !== null ? epouse : ""}`,
						`${exEpouse !== null ? exEpouse : ""}`,
						`${partenaireHomme !== null ? partenaireHomme : ""}`,
						`${partenaireFemme !== null ? partenaireFemme : ""}`,
						`${exPaternaireHomme !== null ? exPaternaireHomme : ""}`,
						`${exPaternaireFemme !== null ? exPaternaireFemme : ""}`,
					],
					//
					q14bOuip: [
						`${mari !== null ? mari : ""}`,
						`${exmari !== null ? exmari : ""}`,
						`${epouse !== null ? epouse : ""}`,
						`${exEpouse !== null ? exEpouse : ""}`,
						`${partenaireHomme !== null ? partenaireHomme : ""}`,
						`${partenaireFemme !== null ? partenaireFemme : ""}`,
						`${exPaternaireHomme !== null ? exPaternaireHomme : ""}`,
						`${exPaternaireFemme !== null ? exPaternaireFemme : ""}`,
					]
						.toString()
						.replace(/['"]+/g, ""),
					q14c: [
						`${patron !== null ? patron : ""}`,
						`${patronne !== null ? patronne : ""}`,
						`${personnelHome !== null ? personnelHome : ""}`,
						`${personnelFemme !== null ? personnelFemme : ""}`,
						`${bailleur !== null ? bailleur : ""}`,
						`${bailleresse !== null ? bailleresse : ""}`,
						`${autreMemberDuCercle !== null ? autreMemberDuCercle : ""}`,
					],
					//
					q14cp: [
						`${patron !== null ? patron : ""}`,
						`${patronne !== null ? patronne : ""}`,
						`${personnelHome !== null ? personnelHome : ""}`,
						`${personnelFemme !== null ? personnelFemme : ""}`,
						`${bailleur !== null ? bailleur : ""}`,
						`${bailleresse !== null ? bailleresse : ""}`,
						`${autreMemberDuCercle !== null ? autreMemberDuCercle : ""}`,
					]
						.toString()
						.replace(/['"]+/g, ""),
					q15: q15value,
					q15bool: q15bool,
					q15Oui: [
						`${memeMenage !== null ? memeMenage : ""}`,
						`${memeparcel !== null ? memeparcel : ""}`,
						`${maisonDEnfant !== null ? maisonDEnfant : ""}`,
						`${campDeRefugie !== null ? campDeRefugie : ""}`,
					],
					//
					q15Ouip: [
						`${memeMenage !== null ? memeMenage : ""}`,
						`${memeparcel !== null ? memeparcel : ""}`,
						`${maisonDEnfant !== null ? maisonDEnfant : ""}`,
						`${campDeRefugie !== null ? campDeRefugie : ""}`,
					]
						.toString()
						.replace(/['"]+/g, ""),
					q15Activator: q15Activator,
					q15autre: data.q15autre !== null ? data.q15autre : "",
					q15Act: q15Act,
				},
			];

			// console.log('perpetor',perpetor);
			sessionStorage.setItem("perpetor", JSON.stringify(perpetor));
			if (orgData !== null) {
				handleSauvegarde();
			} else {
				handleSuivant();
			}
		} else {
			setmsg(
				"Question sans réponse détectée. Veuillez vérifier que vous avez répondu à toutes les questions avant de continuer."
			);
			handleSnackBar();
			// console.log('validate data');
		}
	};

	const handleSuivant = () => {
		history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/form/besion`);
	};

	const handleRetour = () => {
		history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/main/changes`);
	};

	const handleSauvegarde = () => {
		history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/main/changes`);
	};

	const getPerpetor = () => {
		const data = sessionStorage.getItem("perpetor");
		//onsole.log('data from local storage',JSON.parse(data));
		return setgetAuteur(JSON.parse(data));
	};

	useEffect(() => {
		setorgData(location.data);
		getPerpetor();

		if (getAuteur !== null) {
			//
			setq14bool(getAuteur.map((e) => e.q14abool));
			setq14bbool(getAuteur.map((e) => e.q14bbool));
			setq15bool(getAuteur.map((e) => e.q15bool));
			const q15autre = getAuteur.map((e) => e.q15autre);

			if (q15autre === "") {
				setq15Act(false);
			} else {
				setq15Act(true);
			}
			setq15Activator(getAuteur.map((e) => e.q15Activator));
			// console.log(q15Activator);

			//
			setq14avalue(getAuteur.map((e) => e.q14a));
			setq14bvalue(getAuteur.map((e) => e.q14b));
			setq15value(getAuteur.map((e) => e.q15));
			setq14aActivator(getAuteur.map((e) => e.q14aActivator));
			setq14bActivator(getAuteur.map((e) => e.q14bActivator));
			reset({ q15autre: getAuteur.map((e) => e.q15autre) });

			//q14a
			const mer = getAuteur.map((e) => e.q14aOui[0]);
			if (mer === "A") {
				setmereChecked(true);
				setmere(mer);
				// setq10value(true)
				//setq14aActivator(true)
			} else {
				setmereChecked(false);
				setmere();
				// setq10value(true)
				//setq14aActivator(true)
			}

			const per = getAuteur.map((e) => e.q14aOui[1]);
			if (per === "B") {
				setpereChecked(true);
				setpere(per);
				// setq10value(true)
				//setq14aActivator(true)
			} else {
				setpereChecked(false);
				setpere();
				// setq10value(true)
				//setq14aActivator(true)
			}

			const gardHom = getAuteur.map((e) => e.q14aOui[2]);
			if (gardHom === "C") {
				setgardienHommeChecked(true);
				setgardienHomme(gardHom);
				// setq10value(true)
				//setq14aActivator(true)
			} else {
				setgardienHommeChecked(false);
				setgardienHomme();
				// setq10value(true)
				//setq14aActivator(true)
			}

			const gardFem = getAuteur.map((e) => e.q14aOui[3]);
			if (gardFem === "D") {
				setgardienneFemmeChecked(true);
				setgardienneFemme(gardFem);
				// setq10value(true)
				//setq14aActivator(true)
			} else {
				setgardienneFemmeChecked(false);
				setgardienneFemme();
				// setq10value(true)
				//setq14aActivator(true)
			}

			const propFrer = getAuteur.map((e) => e.q14aOui[4]);
			if (propFrer === "E") {
				setpropreFrereChecked(true);
				setpropreFrere(propFrer);
				// setq10value(true)
				//setq14aActivator(true)
			} else {
				setpropreFrereChecked(false);
				setpropreFrere();
				// setq10value(true)
				//setq14aActivator(true)
			}
			const propSoe = getAuteur.map((e) => e.q14aOui[5]);
			if (propSoe === "F") {
				setpropreSoeurChecked(true);
				setpropreSoeur(propSoe);
				// setq10value(true)
				//setq14aActivator(true)
			} else {
				setpropreSoeurChecked(false);
				setpropreSoeur();
				// setq10value(true)
				//setq14aActivator(true)
			}

			const propEnfaAdulte = getAuteur.map((e) => e.q14aOui[6]);
			if (propEnfaAdulte === "G") {
				setpropreEnfantAdulteChecked(true);
				setpropreEnfantAdulte(propEnfaAdulte);
				// setq10value(true)
				//setq14aActivator(true)
			} else {
				setpropreEnfantAdulteChecked(false);
				setpropreEnfantAdulte();
				// setq10value(true)
				//setq14aActivator(true)
			}

			const gMer = getAuteur.map((e) => e.q14aOui[7]);
			if (gMer === "H") {
				setgdMereChecked(true);
				setgdMere(gMer);
				// setq10value(true)
				//setq14aActivator(true)
			} else {
				setgdMereChecked(false);
				setgdMere();
				// setq10value(true)
				//setq14aActivator(true)
			}

			const gPer = getAuteur.map((e) => e.q14aOui[8]);
			if (gPer === "I") {
				setgdPereChecked(true);
				setgdPere(gPer);
				// setq10value(true)
				//setq14aActivator(true)
			} else {
				setgdPereChecked(false);
				setgdPere();
				// setq10value(true)
				//setq14aActivator(true)
			}

			const belMer = getAuteur.map((e) => e.q14aOui[9]);
			if (belMer === "J") {
				setbelleMereChecked(true);
				setbelleMere(belMer);
				// setq10value(true)
				//setq14aActivator(true)
			} else {
				setbelleMereChecked(false);
				setbelleMere();
				// setq10value(true)
				//setq14aActivator(true)
			}

			const bPer = getAuteur.map((e) => e.q14aOui[10]);
			if (bPer === "K") {
				setbeauPereChecked(true);
				setbeauPere(bPer);
				// setq10value(true)
				//setq14aActivator(true)
			} else {
				setbeauPereChecked(false);
				setbeauPere();
				// setq10value(true)
				//setq14aActivator(true)
			}

			const bFer = getAuteur.map((e) => e.q14aOui[11]);
			if (bFer === "L") {
				setbeauxFereChecked(true);
				setbeauxFere(bFer);
				// setq10value(true)
				//setq14aActivator(true)
			} else {
				setbeauxFereChecked(false);
				setbeauxFere();
				// setq10value(true)
				//setq14aActivator(true)
			}

			const bSoeur = getAuteur.map((e) => e.q14aOui[12]);
			if (bSoeur === "M") {
				setbelleSoeurChecked(true);
				setbelleSoeur(bSoeur);
				// setq10value(true)
				//setq14aActivator(true)
			} else {
				setbelleSoeurChecked(false);
				setbelleSoeur();
				// setq10value(true)
				//setq14aActivator(true)
			}

			const oncl = getAuteur.map((e) => e.q14aOui[13]);
			if (oncl === "N") {
				setoncleChecked(true);
				setoncle(oncl);
				// setq10value(true)
				//setq14aActivator(true)
			} else {
				setoncleChecked(false);
				setoncle();
				// setq10value(true)
				//setq14aActivator(true)
			}

			const tant = getAuteur.map((e) => e.q14aOui[14]);
			if (tant === "O") {
				settanteChecked(true);
				settante(tant);
				// setq10value(true)
				//setq14aActivator(true)
			} else {
				settanteChecked(false);
				settante();
				// setq10value(true)
				//setq14aActivator(true)
			}

			const autrMem = getAuteur.map((e) => e.q14aOui[15]);
			if (autrMem === "P") {
				setautreMemberChecked(true);
				setautreMember(autrMem);
				// setq10value(true)
				//setq14aActivator(true)
			} else {
				setautreMemberChecked(false);
				setautreMember();
				// setq10value(true)
				//setq14aActivator(true)
			}

			//14b initial value

			const mariee = getAuteur.map((e) => e.q14bOui[0]);
			if (mariee === "A") {
				setmariChecked(true);
				setmari(mariee);
				// setq10value(true)
				//setq14bActivator(true)
			} else {
				setmariChecked(false);
				setmari();
				// setq10value(true)
				//setq14bActivator(true)
			}

			const exmar = getAuteur.map((e) => e.q14bOui[1]);
			if (exmar === "B") {
				setexmariChecked(true);
				setexmari(exmar);
				// setq10value(true)
				//setq14bActivator(true)
			} else {
				setexmariChecked(false);
				setexmari();
				// setq10value(true)
				//setq14bActivator(true)
			}

			const epou = getAuteur.map((e) => e.q14bOui[2]);
			if (epou === "C") {
				setepouseChecked(true);
				setepouse(epou);
				// setq10value(true)
				//setq14bActivator(true)
			} else {
				setepouseChecked(false);
				setepouse();
				// setq10value(true)
				//setq14bActivator(true)
			}
			const exepou = getAuteur.map((e) => e.q14bOui[3]);
			if (exepou === "D") {
				setexEpouseChecked(true);
				setexEpouse(exepou);
				// setq10value(true)
				//setq14bActivator(true)
			} else {
				setexEpouseChecked(false);
				setexEpouse();
				// setq10value(true)
				//setq14bActivator(true)
			}

			const partHom = getAuteur.map((e) => e.q14bOui[4]);
			if (partHom === "E") {
				setpartenaireHommeChecked(true);
				setpartenaireHomme(partHom);
				// setq10value(true)
				//setq14bActivator(true)
			} else {
				setpartenaireHommeChecked(false);
				setpartenaireHomme();
				// setq10value(true)
				//setq14bActivator(true)
			}

			const partFem = getAuteur.map((e) => e.q14bOui[5]);
			if (partFem === "F") {
				setpartenaireFemmeChecked(true);
				setpartenaireFemme(partFem);
				// setq10value(true)
				//setq14bActivator(true)
			} else {
				setpartenaireFemmeChecked(false);
				setpartenaireFemme();
				// setq10value(true)
				//setq14bActivator(true)
			}

			const expartHom = getAuteur.map((e) => e.q14bOui[6]);
			if (expartHom === "G") {
				setexPaternaireHommeChecked(true);
				setexPaternaireHomme(expartHom);
				// setq10value(true)
				//setq14bActivator(true)
			} else {
				setexPaternaireHommeChecked(false);
				setexPaternaireHomme();
				// setq10value(true)
				//setq14bActivator(true)
			}

			const expartFemm = getAuteur.map((e) => e.q14bOui[7]);
			if (expartFemm === "H") {
				setexPaternaireHommeChecked(true);
				setexPaternaireHomme(expartFemm);
				// setq10value(true)
				//setq14bActivator(true)
			} else {
				setexPaternaireHommeChecked(false);
				setexPaternaireHomme();
				// setq10value(true)
				//setq14bActivator(true)
			}

			//q14c

			const patr = getAuteur.map((e) => e.q14c[0]);
			if (patr === "A") {
				setpatronChecked(true);
				setpatron(patr);
				// setq10value(true)
				//setq14cActivator(true)
			} else {
				setpatronChecked(false);
				setpatron();
				// setq10value(true)
				//setq14cActivator(true)
			}

			const patrn = getAuteur.map((e) => e.q14c[1]);
			if (patrn === "B") {
				setpatronneChecked(true);
				setpatronne(patrn);
				// setq10value(true)
				//setq14cActivator(true)
			} else {
				setpatronneChecked(false);
				setpatronne();
				// setq10value(true)
				//setq14cActivator(true)
			}

			const persHom = getAuteur.map((e) => e.q14c[2]);
			if (persHom === "C") {
				setpersonnelHomeChecked(true);
				setpersonnelHome(persHom);
				// setq10value(true)
				//setq14cActivator(true)
			} else {
				setpersonnelHomeChecked(false);
				setpersonnelHome();
				// setq10value(true)
				//setq14cActivator(true)
			}

			const perFem = getAuteur.map((e) => e.q14c[3]);
			if (perFem === "D") {
				setpersonnelFemmeChecked(true);
				setpersonnelFemme(perFem);
				// setq10value(true)
				//setq14cActivator(true)
			} else {
				setpersonnelFemmeChecked(false);
				setpersonnelFemme();
				// setq10value(true)
				//setq14cActivator(true)
			}

			const bail = getAuteur.map((e) => e.q14c[4]);
			if (bail === "E") {
				setbailleurChecked(true);
				setbailleur(bail);
				// setq10value(true)
				//setq14cActivator(true)
			} else {
				setbailleurChecked(false);
				setbailleur();
				// setq10value(true)
				//setq14cActivator(true)
			}
			const bailrs = getAuteur.map((e) => e.q14c[5]);
			if (bailrs === "F") {
				setbailleresseChecked(true);
				setbailleresse(bailrs);
				// setq10value(true)
				//setq14cActivator(true)
			} else {
				setbailleresseChecked(false);
				setbailleresse();
				// setq10value(true)
				//setq14cActivator(true)
			}

			const autrDuCercle = getAuteur.map((e) => e.q14c[6]);
			if (autrDuCercle === "G") {
				setautreMemberDuCercleChecked(true);
				setautreMemberDuCercle(autrDuCercle);
				// setq10value(true)
				//setq14cActivator(true)
			} else {
				setautreMemberDuCercleChecked(false);
				setautreMemberDuCercle();
				// setq10value(true)
				//setq14cActivator(true)
			}

			//q15 initial values

			const memeMan = getAuteur.map((e) => e.q15Oui[0]);
			if (memeMan === "A") {
				setmemeMenageChecked(true);
				setmemeMenage(memeMan);
				// setq10value(true)
				//setq15Activator(true)
			} else {
				setmemeMenageChecked(false);
				setmemeMenage();
				// setq10value(true)
				//setq15Activator(true)
			}

			const memePar = getAuteur.map((e) => e.q15Oui[1]);
			if (memePar === "B") {
				setmemeparcelChecked(true);
				setmemeparcel(memePar);
				// setq10value(true)
				//setq15Activator(true)
			} else {
				setmemeparcelChecked(false);
				setmemeparcel();
				// setq10value(true)
				//setq15Activator(true)
			}

			const maisDEnfa = getAuteur.map((e) => e.q15Oui[2]);
			if (maisDEnfa === "C") {
				setmaisonDEnfantChecked(true);
				setmaisonDEnfant(maisDEnfa);
				// setq10value(true)
				//setq15Activator(true)
			} else {
				setmaisonDEnfantChecked(false);
				setmaisonDEnfant();
				// setq10value(true)
				//setq15Activator(true)
			}

			const campDeRef = getAuteur.map((e) => e.q15Oui[3]);
			if (campDeRef === "D") {
				setcampDeRefugieChecked(true);
				setcampDeRefugie(campDeRef);
				// setq10value(true)
				//setq15Activator(true)
			} else {
				setcampDeRefugieChecked(false);
				setcampDeRefugie();
				// setq10value(true)
				//setq15Activator(true)
			}
		}
	}, [orgData, getAuteur, location.data, reset]); //Just added getAuteur, location.data, reset

	//console.log(getAuteur);
	//// console.log(orgData);
	//console.log(q15Activator);

	return (
		<div>
			<div className={classes.root}>
				<AppBar position="absolute" className={classes.appbar}>
					<Toolbar>
						{orgData !== null ? null : (
							<IconButton
								edge="start"
								className={classes.menuButton}
								color="inherit"
								aria-label="open drawer"
								component={Link}
								to="/collect"
							>
								{/* <ArrowBack/> */}
							</IconButton>
						)}
						<Typography
							className={classes.titleAppBar}
							variant="h6"
							noWrap
						></Typography>
						<div className={classes.search}>
							<div className={classes.searchIcon}></div>
							{orgData !== null ? (
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={select}
									fullWidth
									className={classes.select}
									onChange={handleChange}
								>
									<MenuItem value={5}>
										PROFIL DE L'AUTEUR DE L'ACTE DE VIOLENCE DOMESTIQUE
									</MenuItem>
								</Select>
							) : (
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={select}
									fullWidth
									className={classes.select}
									onChange={handleChange}
								>
									<MenuItem
										value={2}
										component={Link}
										to={
											"/" + process.env.REACT_APP_COLLECT_LINK + "/collect/form"
										}
									>
										IDENTIFICATION DE L'ORGANISATION
									</MenuItem>
									<MenuItem
										value={3}
										component={Link}
										to={
											"/" +
											process.env.REACT_APP_COLLECT_LINK +
											"/collect/form/profile"
										}
									>
										PROFIL DE LA / DU SURVIVANT(E)
									</MenuItem>
									<MenuItem
										value={4}
										component={Link}
										to={
											"/" +
											process.env.REACT_APP_COLLECT_LINK +
											"/collect/form/info"
										}
									>
										INFORMATION SUR L'INCIDENT DE VIOLENCE DOMESTIQUE DOCUMENTÉ
									</MenuItem>
									<MenuItem
										value={5}
										component={Link}
										to={
											"/" +
											process.env.REACT_APP_COLLECT_LINK +
											"/collect/form.perpretor"
										}
									>
										PROFIL DE L'AUTEUR DE L'ACTE DE VIOLENCE DOMESTIQUE
									</MenuItem>
								</Select>
							)}
						</div>
					</Toolbar>
				</AppBar>
			</div>

			<div>
				<Grid container justifyContent="center" spacing={3}>
					<Grid item xs={11} sm={8}>
						<Typography
							xs={11}
							sm={8}
							className={classes.bigtitle}
							align="left"
						>
							{grp4.toUpperCase()}
						</Typography>
					</Grid>
				</Grid>
				<Grid container justifyContent="center" spacing={3}>
					<Grid item className={classes.content} xs={11} sm={8}>
						<Grid container direction="row">
							<Grid item sm={1} xs={0}></Grid>
							<Grid item sm={10} xs="12" className={classes.marginTop_40}>
								<form onSubmit={handleSubmit(onSubmit)}>
									<Grid container spacing={1} direction="row">
										<Grid item xs={10} sm={10}>
											<Typography className={classes.label}>
												{q14}
												<small style={{ fontWeight: 200 }}>
													{" "}
													(choix multiple est possible ; cependant il n’est pas
													nécessaire de présenter chaque option à la victime
													mais la personne qui remplit la fiche coche les cases
													adéquates selon les descriptions de la victime.)
												</small>
											</Typography>
											<Typography className={classes.sublabel}>
												{q14a}
												<small style={{ fontWeight: 200 }}>
													{" "}
													(Sans conjoint, ceux-ci font l'objet de la question
													suivante)
												</small>
											</Typography>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.radiolabel}
												>
													<FormControlLabel
														checked={q14avalue === "B" ? true : false}
														value="B"
														onClick={q14aCheck}
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
													<FormControlLabel
														checked={q14avalue === "A" ? true : false}
														value="A"
														onClick={q14aCheck}
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
												</RadioGroup>
											</FormControl>
											{q14avalue === "A" ? (
												<FormGroup className={classes.marginBottom_30}>
													<Typography className={classes.sublabel}>
														<small style={{ fontWeight: 200 }}>
															{" "}
															Si oui (choix multiple est possible)
														</small>
													</Typography>

													<FormControlLabel
														control={
															<Checkbox
																checked={mereChecked}
																value={"A"}
																onChange={q14amere}
															/>
														}
														label="Mère"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={pereChecked}
																value={"B"}
																onChange={q14apere}
															/>
														}
														label="Père"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={gardienHommeChecked}
																value={"C"}
																onChange={q14agardienHomme}
															/>
														}
														label="Tuteur / gardien (homme)"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={gardienneFemmeChecked}
																value={"D"}
																onChange={q14agardienneFemme}
															/>
														}
														label="Tuteur / gardienne (femme)"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={propreFrereChecked}
																value={"E"}
																onChange={q14apropreFrere}
															/>
														}
														label="Propres frères"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={propreSoeurChecked}
																value={"F"}
																onChange={q14apropreSoeur}
															/>
														}
														label="Propres sœurs"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={propreEnfantAdulteChecked}
																value={"G"}
																onChange={q14apropreEnfantAdulte}
															/>
														}
														label="Propres enfants adultes"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={gdMereChecked}
																value={"H"}
																onChange={q14agdMere}
															/>
														}
														label="Grand-mere"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={gdPereChecked}
																value={"I"}
																onChange={q14agdPere}
															/>
														}
														label="Grand-père"
													/>

													<FormControlLabel
														control={
															<Checkbox
																checked={belleMereChecked}
																value={"J"}
																onChange={q14abelleMere}
															/>
														}
														label="Belle-mère"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={beauPereChecked}
																value={"K"}
																onChange={q14abeauPere}
															/>
														}
														label="Beau-père"
													/>

													<FormControlLabel
														control={
															<Checkbox
																checked={beauxFereChecked}
																value={"L"}
																onChange={q14abeauxFere}
															/>
														}
														label="Beaux-frères"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={belleSoeurChecked}
																value={"M"}
																onChange={q14abelleSoeur}
															/>
														}
														label="Belles-sœurs"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={oncleChecked}
																value={"N"}
																onChange={q14aoncle}
															/>
														}
														label="Oncles"
													/>

													<FormControlLabel
														control={
															<Checkbox
																checked={tanteChecked}
																value={"O"}
																onChange={q14atante}
															/>
														}
														label="Tantes"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={autreMemberChecked}
																value={"P"}
																onChange={q14aautreMember}
															/>
														}
														label="Autre(s) membre(s) de la famille de la victime"
													/>
												</FormGroup>
											) : null}
											<Typography className={classes.sublabel}>
												{q14b}
											</Typography>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.radiolabel}
												>
													<FormControlLabel
														checked={q14bvalue === "B" ? true : false}
														value="B"
														onClick={q14bCheck}
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
													<FormControlLabel
														checked={q14bvalue === "A" ? true : false}
														value="A"
														onClick={q14bCheck}
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
												</RadioGroup>
											</FormControl>
											{q14bvalue === "A" ? (
												<FormGroup className={classes.marginBottom_30}>
													<Typography className={classes.sublabel}>
														<small style={{ fontWeight: 200 }}>
															Si oui, l’auteur de la violence domestique est …
														</small>
													</Typography>
													<FormControlLabel
														control={
															<Checkbox
																checked={mariChecked}
																value={"A"}
																onChange={q14bmari}
															/>
														}
														label="Mari"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={exmariChecked}
																value={"B"}
																onChange={q14bexmari}
															/>
														}
														label="Ex-mari"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={epouseChecked}
																value={"C"}
																onChange={q14bepouse}
															/>
														}
														label="Epouse"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={exEpouseChecked}
																value={"D"}
																onChange={q14bexEpouse}
															/>
														}
														label="Ex-épouse"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={partenaireHommeChecked}
																value={"E"}
																onChange={q14bpartenaireHomme}
															/>
														}
														label="Partenaire intime actuel qui est homme"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={partenaireFemmeChecked}
																value={"F"}
																onChange={q14bpartenaireFemme}
															/>
														}
														label="Partenaire intime actuel qui est femme"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={exPaternaireHommeChecked}
																value={"G"}
																onChange={q14bexPaternaireHomme}
															/>
														}
														label="Ex-partenaire intime qui est homme"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={exPaternaireFemmeChecked}
																value={"H"}
																onChange={q14bexPaternaireFemme}
															/>
														}
														label="Ex-partenaire intime qui est femme"
													/>
												</FormGroup>
											) : null}

											<Typography className={classes.sublabel}>
												{q14c}
											</Typography>
											<FormGroup className={classes.marginBottom_30}>
												<FormControlLabel
													control={
														<Checkbox
															checked={patronChecked}
															value={"A"}
															onChange={q14cpatron}
														/>
													}
													label="Patron (homme) de personnel domestique"
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={patronneChecked}
															value={"B"}
															onChange={q14cpatronne}
														/>
													}
													label="Patronne (femme) de personnel domestique"
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={personnelHomeChecked}
															value={"C"}
															onChange={q14cpersonnelHome}
														/>
													}
													label="Personnel domestique (homme)"
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={personnelFemmeChecked}
															value={"D"}
															onChange={q14cpersonnelFemme}
														/>
													}
													label="Personnel domestique (femme)"
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={bailleurChecked}
															value={"E"}
															onChange={q14cbailleur}
														/>
													}
													label="Bailleur / propriétaire de la maison (homme)"
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={bailleresseChecked}
															value={"F"}
															onChange={q14cbailleresse}
														/>
													}
													label="Bailleresse / propriétaire de la maison (femme)"
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={autreMemberDuCercleChecked}
															value={"G"}
															onChange={q14cautreMemberDuCercle}
														/>
													}
													label="Autre(s) membre(s) du cercle restreint de la victime"
												/>
											</FormGroup>
										</Grid>
									</Grid>
									<Grid container spacing={1} direction="row">
										<Grid container spacing={1} direction="row">
											<Grid item xs={11} sm={10}>
												<Typography className={classes.label}>{q15}</Typography>
												<FormControl component="fieldset">
													<RadioGroup
														name="occupation"
														className={classes.radiolabel}
													>
														<FormControlLabel
															checked={q15value === "B" ? true : false}
															value="B"
															onClick={q15Check}
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Non
																</Box>
															}
														/>
														<FormControlLabel
															checked={q15value === "A" ? true : false}
															value="A"
															onClick={q15Check}
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Oui
																</Box>
															}
														/>
													</RadioGroup>
												</FormControl>
												{q15value === "A" ? (
													<FormGroup className={classes.marginBottom_30}>
														<Typography className={classes.sublabel}>
															<small style={{ fontWeight: 200 }}>
																Si oui:{" "}
															</small>
														</Typography>
														<FormControlLabel
															control={
																<Checkbox
																	checked={memeMenageChecked}
																	value={"A"}
																	onChange={q15memeMenage}
																/>
															}
															label="Même ménage"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={memeparcelChecked}
																	value={"B"}
																	onChange={q15memeparcel}
																/>
															}
															label="Même parcelle"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={maisonDEnfantChecked}
																	value={"C"}
																	onChange={q15maisonDEnfant}
																/>
															}
															label="Maison d’enfants"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={campDeRefugieChecked}
																	value={"D"}
																	onChange={q15campDeRefugie}
																/>
															}
															label="Camp de réfugiés"
														/>
														<FormControlLabel
															control={
																<Checkbox
																	checked={q15Act}
																	onChange={q15autreCheck}
																	value={"oui"}
																/>
															}
															label={
																<TextField
																	required
																	disabled={q15Act === true ? false : true}
																	label="Autre situation de vie en proximité"
																	variant="outlined"
																	style={{ width: "150%" }}
																	size="small"
																	fullWidth
																	name="q15autre"
																	inputRef={register}
																/>
															}
														/>
													</FormGroup>
												) : null}
											</Grid>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										direction="row"
										justifyContent="flex-end"
									>
										<Grid item className={classes.marginButton}>
											{orgData !== null ? (
												<div>
													<BtnOutline onClick={handleRetour} content="RETOUR" />
													<BtnContained
														type="submit"
														//onClick={handleSauvegarde}
														content="SAUVEGARDER"
													/>
												</div>
											) : (
												<div>
													<BtnOutline
														component={Link}
														to={
															"/" +
															process.env.REACT_APP_COLLECT_LINK +
															"/collect/form/info"
														}
														content={precedent}
														onClick={() => {
															// console.log('hello elijah');
														}}
													/>
													<BtnContained
														type="submit"
														// component={Link}
														// to='/collect/form/info'
														content={suivant}
													/>
												</div>
											)}
										</Grid>
									</Grid>
								</form>
							</Grid>
							<Grid item sm={1} xs={0}></Grid>
						</Grid>
					</Grid>
					<Snackbar
						open={openSnackBar}
						autoHideDuration={10}
						onClose={handleClose}
					>
						<Alert onClose={handleCloseSnackBar} severity="error">
							<Typography>{msg}</Typography>
						</Alert>
					</Snackbar>
				</Grid>
			</div>
		</div>
	);
};

export default Perpretor;

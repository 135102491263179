import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
	Grid,
	List,
	// ListItem,
	// ListItemIcon,
	// ListItemText,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import LanguageIcon from "@material-ui/icons/Language";
import Collapse from "@material-ui/core/Collapse";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	root: {
		//width: 345,
		// height:300
	},
	media: {
		height: 200,
		width: "90%",
		//maxWidth:'100%%',
		//margin:20
	},
	white_color: {
		color: "#00A1AF",
		[theme.breakpoints.down("xs")]: {
			fontSize: 12,
			fontWeight: 300,
		},
		[theme.breakpoints.up("sm")]: {
			fontSize: 12,
			fontWeight: 300,
		},
	},
	margin_left: {
		marginLeft: 15,
	},
	marginTop: {
		marginTop: 10,
	},
	title: {
		color: "#1D384C",
		fontWeight: 300,
	},
	nameImg: {
		marginTop: 40,
		height: 160,
		width: 160,
		// paddingTop:50,
		borderRadius: "50%",
		backgroundColor: "#C6DCE1",
	},
	name: {
		marginTop: 60,
		color: "#1D384C",
		fontWeight: 300,
		// marginBottom:0
	},
}));

export default function Partner({
	name,
	desc,
	content,
	content2,
	org,
	org2,
	org3,
	org4,
	org5,
	org6,
	org7,
	org8,
	link,
	img,
}) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const { t, i18n } = useTranslation();

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<Card
			className={classes.root}
			style={{ height: `${!open && content !== null ? 500 : 345}` }}
		>
			<CardActionArea>
				<Grid container justifyContent="center">
					{img !== null ? (
						<CardMedia className={classes.media} image={img} title="Partner" />
					) : (
						<Grid className={classes.nameImg}>
							<Typography
								gutterBottom
								variant="h6"
								component="h2"
								align="center"
								className={classes.name}
							>
								{name.substr(0, 2)}
							</Typography>
						</Grid>
					)}
				</Grid>
				<CardContent>
					<Grid container>
						<Grid item xs={12} sm={12}>
							<Grid container>
								<Grid item xs={12} sm={12}>
									<Typography
										gutterBottom
										variant="h6"
										component="h2"
										align="center"
										className={classes.title}
									>
										<center>{name}</center>
									</Typography>
								</Grid>
								<Grid item>
									{!open ? (
										<Typography
											variant="body2"
											color="textSecondary"
											component="p"
											align="center"
										>
											{desc}
										</Typography>
									) : (
										<Collapse in={open} timeout="auto" unmountOnExit>
											<Typography
												variant="body2"
												color="textSecondary"
												component="p"
												align="left"
											>
												{content}
											</Typography>
											{content2 !== "" ? (
												<Typography
													variant="body2"
													color="textSecondary"
													component="p"
													align="left"
													className={classes.marginTop}
												>
													{content2}
												</Typography>
											) : null}
											{org !== "" &&
											org2 !== "" &&
											org3 !== "" &&
											org4 !== "" &&
											org5 !== "" &&
											org6 !== "" &&
											org7 !== "" &&
											org8 !== "" ? (
												<List>
													<Typography
														variant="body2"
														color="textSecondary"
														component="p"
														align="left"
														className={classes.margin_left}
													>
														- {org}
													</Typography>

													<Typography
														variant="body2"
														color="textSecondary"
														component="p"
														align="left"
														className={classes.margin_left}
													>
														- {org2}
													</Typography>

													<Typography
														variant="body2"
														color="textSecondary"
														component="p"
														align="left"
														className={classes.margin_left}
													>
														- {org3}
													</Typography>

													<Typography
														variant="body2"
														color="textSecondary"
														component="p"
														align="left"
														className={classes.margin_left}
													>
														- {org4}
													</Typography>

													<Typography
														variant="body2"
														color="textSecondary"
														component="p"
														align="left"
														className={classes.margin_left}
													>
														- {org5}
													</Typography>

													<Typography
														variant="body2"
														color="textSecondary"
														component="p"
														align="left"
														className={classes.margin_left}
													>
														- {org6}
													</Typography>

													<Typography
														variant="body2"
														color="textSecondary"
														component="p"
														align="left"
														className={classes.margin_left}
													>
														- {org7}
													</Typography>

													<Typography
														variant="body2"
														color="textSecondary"
														component="p"
														align="left"
														className={classes.margin_left}
													>
														- {org8}
													</Typography>
												</List>
											) : null}
										</Collapse>
									)}
								</Grid>
								{content !== "" ? (
									<Grid item xs={12} sm={12}>
										<Grid container justifyContent="center">
											<Grid item>
												<Button
													className={classes.white_color}
													size="small"
													color="secondary"
													endIcon={open ? <ExpandLess /> : <ExpandMore />}
													onClick={handleClick}
												>
													{open ? `${t("read-less")}` : `${t("read-more")}`}
												</Button>
											</Grid>
										</Grid>
									</Grid>
								) : null}
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
			</CardActionArea>
			<CardActions>
				{link !== "" ? (
					<Grid container justifyContent="center">
						<Grid item>
							<a
								href={link}
								style={{
									textDecoration: "none",
								}}
								target="_blank" rel="noopener noreferrer"
							>
								<Button
									className={classes.white_color}
									size="small"
									color="secondary"
									endIcon={<LanguageIcon />}
								>
									{t("web-link")}
								</Button>
							</a>
						</Grid>
					</Grid>
				) : null}
			</CardActions>
		</Card>
	);
}

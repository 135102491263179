import React from 'react';
import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';


const CompainSkeleton =()=>{
    return (
        <Grid container direction='row' spacing={1} style={{marginBottom:50}} >
        <Grid item xs={12} sm={4}><Skeleton variant="rect" height={200}  /></Grid>
        <Grid item xs={12} sm={4}><Skeleton variant="rect" height={200}  /></Grid>
        <Grid item xs={12} sm={4}><Skeleton variant="rect" height={200}  /></Grid>
        </Grid>
    
                     
    )
}

export default CompainSkeleton;
import React, { useState, useEffect } from "react";
import {
	Grid,
	Card,
	Typography,
	CardContent,
	Button,
	Box,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	DialogTitle,
	FormControl,
	Radio,
	FormControlLabel,
	RadioGroup,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import useStyles from "./style";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { BtnText1, BtnText, BtnLogout2 } from "../../widget/util";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../service/axiosApi";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Home = () => {
	const classes = useStyles();
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const [open1, setOpen1] = useState(false);
	const [radio, setradio] = useState("");
	const [organization, setorganization] = useState("");
	const [openSnackBar, setOpenSnackbar] = useState(false);
	const [radioBool, setradioBool] = useState(false);
	const history = useHistory();

	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleClickOpen1 = () => {
		setOpen1(true);
	};

	const handleClose1 = () => {
		setOpen1(false);
	};

	const handleSnackBar = () => {
		setOpenSnackbar(true);
	};

	const handleCloseSnackBar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpenSnackbar(false);
	};

	// Function to get the Radio button data
	const handRadio = (e) => {
		const value = e.target.value;
		//console.log(value);
		setradioBool(true);
		setradio(value);

		// if(value ==="Oui"){
		//     //console.log("hello from Oui");
		// }else if(value==="Non"){
		//     //console.log("hello from Non");
		// }else{
		//     //console.log("non data");
		// }
	};

	const handContinue = () => {
		if (radio === "Oui") {
			//console.log("hello from Oui");
			history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/form`);
		} else if (radio === "Non") {
			//console.log("hello from Non");
			setOpen1(false);
			setradioBool(false);
			handleSnackBar();
		} else {
			//console.log("non data");
		}
	};

	const btnNewCase = () => {
		handleClickOpen1();
		handleCloseSnackBar();
	};

	const deconnexion = async () => {
		try {
			const response = await axiosInstance.post("blacklist/", {
				refresh: sessionStorage.getItem("refresh"),
			});
			sessionStorage.removeItem("token");
			sessionStorage.removeItem("id");
			sessionStorage.removeItem("user");
			sessionStorage.removeItem("org");
			axiosInstance.defaults.headers["Authorization"] = null;
			history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/login`);

			return response;
		} catch (e) {
			sessionStorage.removeItem("token");
			sessionStorage.removeItem("id");
			sessionStorage.removeItem("user");
			sessionStorage.removeItem("org");
			//console.log(e);
			history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/login`);
		}
	};
	const getOrganization = () => {
		const tkn = sessionStorage.getItem("org");
		return setorganization(tkn);
	};

	useEffect(() => {
		getOrganization();
		//console.log(organization);
	}, [organization]);

	////console.log("org:\t\t\t",organization);

	return (
		<div className={classes.root} style={{ height: "100vh" }}>
			<Grid container justifyContent="center">
				<Grid item xs={11} sm={6} md={4} lg={4}>
					<Card className={classes.cardHome}>
						<CardContent>
							<Typography
								align="center"
								className={classes.bigtitle}
								varient="h2"
							>
								{" "}
								<strong style={{ fontSize: "30px", color: "#4F2970" }}>
									{" "}
									{organization}
								</strong>
							</Typography>
							{/* <Typography align='center' style={{marginRight:'30px',marginLeft:'30px',color :'#4F2970'}}> Location</Typography> */}

							<Box textAlign="center">
								<Button
									onClick={btnNewCase}
									style={{
										background: "#00A1AF",
										width: "100px",
										height: "40px",
									}}
									type="button"
								>
									<Typography
										align="center"
										style={{ color: "white", fontSize: "10px" }}
									>
										NOUVEAU CAS
									</Typography>
								</Button>
							</Box>

							<Dialog
								fullScreen={fullScreen}
								open={open1}
								onClose={handleClose1}
								aria-labelledby="responsive-dialog-title"
							>
								<DialogTitle id="responsive-dialog-title">
									{"CONSENTEMENT ÉCLAIRÉ"}
								</DialogTitle>
								<DialogContent>
									<DialogContentText style={{ width: "100%" }}>
										<Typography className={classes.dialogTypo}>
											Le consentement éclairé de la victime doit être obtenu
											avant de démarrer l’entretien :
										</Typography>
										<List>
											<ListItem>
												- L’objectif de la récolte d’information doit être
												clairement expliqué ;
											</ListItem>
											<ListItem>
												- La nature confidentielle de l’entretien doit être
												soulignée, en même temps que les utilisations
												anonymisées des informations fournies par la victime
												doivent être clarifiées ;
											</ListItem>
											<ListItem>
												- La victime doit être informée de la possibilité
												d’accepter ou non de parler, et que ce choix peut être
												effectué avant, pendant, ou après l’entretien ;
											</ListItem>
											<ListItem>
												- Il est essentiel de demander à la victime si elle a
												des questions ou des préoccupations, et d’y répondre.
											</ListItem>
										</List>
										<Typography className={classes.dialogTypo}>
											Les attentes de la victime doivent être gérées :
										</Typography>
										<List>
											<ListItem>
												- Avant de démarrer l’entretien, il est important de
												clarifier les limites des services qui peuvent être
												fournis par l’organisation partenaire ;
											</ListItem>
											<ListItem>
												- Ces limites peuvent être réitérées en fin d’entretien
												si besoin.
											</ListItem>
										</List>
										<Typography className={classes.dialogTypo}>
											Est-ce que vous avez bien abordé le consentement éclairé
											et obtenu le consentement éclairé par la/le survivant(e)
											de manière orale ?
										</Typography>
										<List>
											<FormControl component="fieldset">
												<RadioGroup
													name="occupation"
													className={classes.radiolabel}
												>
													<ListItem>
														<FormControlLabel
															value="Oui"
															onClick={handRadio}
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Oui, j’ai bien abordé le consentement éclairé
																	et j’ai obtenu le consentement éclairé par
																	la/le survivant(e) de manière orale.
																</Box>
															}
														/>
													</ListItem>
													<ListItem>
														{" "}
														<FormControlLabel
															value="Non"
															onClick={handRadio}
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Non, je n’ai pas obtenu le consentement
																	éclairé par la/le survivant(e) de manière
																	orale.
																</Box>
															}
														/>
													</ListItem>
												</RadioGroup>
											</FormControl>
										</List>
									</DialogContentText>
								</DialogContent>
								<DialogActions>
									{/* <BtnText1 content="ANNULER" onClick={handleClose}/>
                                    {/* <Button autoFocus onClick={handleClose} color="primary">
                                        ANNULER
                                    </Button> */}
									<Grid container justifyContent="center">
										<Button
											className={classes.continue}
											disabled={radioBool === true ? false : true}
											onClick={handContinue}
										>
											CONTINUE
										</Button>
									</Grid>
									{/* <Button>CONTINUE</Button> */}
									{/* <BtnContained content=" DÉCONNEXION" component={Link} to=`/${+process.env.REACT_APP_COLLECT_LINK}/collect/login` /> */}
								</DialogActions>
							</Dialog>

							<Snackbar
								open={openSnackBar}
								autoHideDuration={10}
								onClose={handleClose}
							>
								<Alert onClose={handleCloseSnackBar} severity="error">
									<Typography>
										{" "}
										Veuillez remercier la/le survivant(e) pour son temps, et
										évaluer comment vous pouvez soutenir la prise en charge de
										la victime en dehors cette activité de documentation de
										l’Observatoire.
									</Typography>
								</Alert>
							</Snackbar>
						</CardContent>
					</Card>

					<Typography align="center" className={classes.disconnect}>
						{" "}
						<BtnText content="Se déonnecter" onClick={handleClickOpen} />
					</Typography>
					{/* dialog box */}
					<Dialog
						fullScreen={fullScreen}
						open={open}
						onClose={handleClose}
						aria-labelledby="responsive-dialog-title"
					>
						<DialogTitle id="responsive-dialog-title">
							{"Êtes-vous sûr ?"}
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Si vous vous déconnectez, vous n'aurez pas accès à cette
								demande. Veuillez vous assurer que vous disposez des
								identifiants de connexion fournis par les utilisateurs de
								l'administration avant de vous déconnecter.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<BtnText1 content="ANNULER" onClick={handleClose} />
							{/* <Button autoFocus onClick={handleClose} color="primary">
                                ANNULER
                            </Button> */}
							<BtnLogout2 content=" DÉCONNEXION" onClick={deconnexion} />
						</DialogActions>
					</Dialog>
				</Grid>
			</Grid>
		</div>
	);
};

export default Home;

// this.state = { username: "", password:"", authflag:1 };
// this.handleChange = this.handleChange.bind(this);
// this.handleSubmit = this.handleSubmit.bind(this);
// }
// handleChange(event) {
// this.setState({ username: event.state.username, password: event.state.password });
// }
// handleSubmit(event) {
// event.preventDefault();
// if (this.state.username === 'admin@littech.in' && this.state.password === 'secret') {
// this.props.history.push("/home");
// } else {
// alert('Incorrect Credntials!');
// }
// }

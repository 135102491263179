import { Grid } from "@material-ui/core";
import useStyles from "../../component/website/ContactUs/style";
import React,{useEffect} from "react";
import Section1 from "../../component/website/ContactUs/section1";
import Content from "../../component/website/ContactUs/Content";
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

export default function ContactUs() {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
   
  }, [])
  return (
    <div>
      <Helmet>
              <title>{t('contact')}</title>
            </Helmet>
      <Grid container className={classes.section_1} alignItems="center">
        <Grid item xs={1} sm={1}  md={1} lg={1} xl={2}></Grid>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={8}>
          <Section1 />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={1} sm={1} md={1} lg={2} xl={3}></Grid>
        <Grid item xs={10} sm={10} md={10} lg={8} xl={6}>
          <Content />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2} xl={3}></Grid>
      </Grid>
    </div>
  );
}

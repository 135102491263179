import React, { useState, useEffect } from "react";
import useStyles from "../styles";
import {
	Grid,
	Typography,
	DividerTypeMap,
	Card,
	CardContent,
	TextField,
	InputAdornment,
	Tab,
	Tabs,
	Menu,
	MenuItem,
	Divider,
} from "@material-ui/core";
import { DataGrid, getIdFromRowElem } from "@material-ui/data-grid";
import BackPaper from "../../../widget/backpaper";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PropTypes from "prop-types";
import { Btndrop, Btndrop1 } from "../../../widget/util";
import { withRouter, useHistory } from "react-router-dom";
//import MUIDataTable from "mui-datatables";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AllPost from "./pages/all";
import ReportAndArticles from "./pages/report_article";
import axiosInstance from "../../../service/dashboardAxiosApi";
import Campaign from "./pages/compaign";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-prevent-tabpanel-${index}`}
			aria-labelledby={`scrollable-prevent-tab-${index}`}
			{...other}
		>
			{value === index && <Typography component={"span"}>{children}</Typography>}
		</div>
	);
}

// function a11yProps(index) {
//   return {
//     id: `scrollable-prevent-tab-${index}`,
//     'aria-controls': `scrollable-prevent-tabpanel-${index}`,
//   };
// }

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

const Post = (props) => {
	const { history } = props;
	const classes = useStyles();
	const [value, setValue] = React.useState(0);
	const [getAll, setgetAll] = useState([]);

	const handlePost = () => {
		history.push(
			`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/post/create`
		);
	};
	const handleChange = (event, newValue) => {
		setValue(newValue);
		localStorage.setItem("value", 0);
	};
	const handleUpdate = async () => {
		history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/post/edit`);
	};

	const handlePostDetailLink = () => {
		history.push({
			pathname: `/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/post/detail`,
		});
	};
	const handleDelete = async (id) => {
		axiosInstance
			.delete(`posts/${id}/`)
			.then((res) => {
				//  console.log(res.data.message);
				history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/posts`);
			})
			.catch((err) => {
				if (err.response) {
					// console.log(err.response);
				} else if (err.request) {
					// console.log(err.request);
				}
			});
	};

	useEffect(async () => {
		const getPost = async () => {
			axiosInstance.get(`posts/`).then((res) => {
				const data = res.data.posts;
				// console.log(data);
				setgetAll(data);
			});
		};
		getPost();
	}, [axiosInstance]);

	// console.log(getAll);

	const all = getAll.length;
	const campaigns = getAll.filter((e) => e.category === "Campaign news").length;
	const report = getAll.filter(
		(e) => e.category === "Reports & articles"
	).length;

	const tablabel = [
		{
			title: "All",
			num: all,
			css: {
				backgroundColor: "#F2D006",
				color: "white",
				padding: "3px",
				margin: "5px",
			},
			id: "0",
		},
		{
			title: "Campaign news",
			num: campaigns,
			css: {
				backgroundColor: "#208BD1",
				color: "white",
				padding: "3px",
				margin: "5px",
			},
			id: "1",
		},
		{
			title: "Observatory Reports & Articles",
			num: report,
			css: {
				backgroundColor: "#F1545B",
				color: "white",
				padding: "3px",
				margin: "5px",
			},
			id: "2",
		},
	];

	return (
		<BackPaper
			content={
				<div>
					{/* title */}
					<Grid container direction="row">
						<Grid item xs={12} sm={12}>
							<Typography variant="h4" className={classes.bigtitle}>
								{" "}
								Campaign & Observatory News{" "}
							</Typography>
						</Grid>
					</Grid>
					{/* next paper */}
					<Divider />
					<Grid container direction="row">
						<Grid item xs={12} sm={12}>
							<Card className={classes.grid}>
								<CardContent>
									<Grid
										container
										direction="row"
										justifyContent="flex-end"
										className={classes.margin_Bottom_20}
									>
										<Grid item>
											<Btndrop1
												content="new post"
												variant="contained"
												onClick={handlePost}
											/>
										</Grid>
									</Grid>
									<Grid container direction="row" justifyContent="center">
										<Grid item xs={12} sm={12} md={12} lg={7}>
											<Tabs
												disableRipple
												value={value}
												onChange={handleChange}
												TabIndicatorProps={{
													className: classes.indicatedColor,
												}}
												variant="fullWidth"
												style={{ color: "black" }}
												scrollButtons="on"

												// className={clsx(classes.tabs, {[classes.tabsShift]: editMode})}
											>
												{tablabel.map((tab) => (
													<Tab
														key={tab.id}
														label={
															<Typography className={classes.tab}>
																{tab.title}
																<span style={tab.css}>{tab.num}</span>
															</Typography>
														}
													/>
												))}
											</Tabs>
										</Grid>
										{/* left bar */}
									</Grid>

									<Grid
										container
										justifyContent="flex-start"
										className={classes.table}
									>
										<Grid item xs={12} sm={12}>
											<TabPanel value={value} index={0}>
												{/* <div style={{ height: 600, width: '100%' }}> */}
												<AllPost
													handlePostDetailLink={handlePostDetailLink}
													handleDelete={handleDelete}
													handleUpdate={handleUpdate}
												/>
												{/* </div> */}
											</TabPanel>
											<TabPanel value={value} index={1}>
												{/* <div style={{ height: 600, width: '100%' }}> */}
												<Campaign
													handlePostDetailLink={handlePostDetailLink}
													handleDelete={handleDelete}
													handleUpdate={handleUpdate}
												/>
												{/* </div> */}
											</TabPanel>
											<TabPanel value={value} index={2}>
												{/* <div style={{ height: 600, width: '100%' }}> */}
												<ReportAndArticles
													handlePostDetailLink={handlePostDetailLink}
													handleDelete={handleDelete}
													handleUpdate={handleUpdate}
												/>
												{/* </div> */}
											</TabPanel>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</div>
			}
		/>
	);
};

export default withRouter(Post);

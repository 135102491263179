import React, { useState, useEffect } from "react";
import BackPaper from "../../../widget/backpaper";
import { Grid, Typography, Paper, Divider, Button } from "@material-ui/core";
import useStyles from "../styles";
import LineChart from "../case_statistic/sub/chart/line";
import DoughnutCharts from "../case_statistic/sub/chart/doughtnut";
import BarCharts from "../case_statistic/sub/chart/bar";
import { Tabls } from "./sub/tables/table";
import { Auteurtbl } from "./sub/tables/auteur_tbl";
import { PrejudiceTbl } from "./sub/tables/prejudice_tbl";
import { ProvinceTbl } from "./sub/tables/province_tbl";
import axiosInstance from "../../../service/dashboardAxiosApi";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { Cell } from "recharts";
import StopIcon from "@material-ui/icons/Stop";
import CloudOffTwoToneIcon from "@material-ui/icons/CloudOffTwoTone";
import RestoreIcon from "@material-ui/icons/Restore";
import Skeleton from "@material-ui/lab/Skeleton";

const Statistic = () => {
	const classes = useStyles();
	const totalcases = [{ name: "Total des cas ", value: 100 }];

	const [cases, setcases] = useState([]);
	const [q10a, setq10a] = useState([]);
	const [q10b, setq10b] = useState([]);
	const [q10c, setq10c] = useState([]);
	const [q10d, setq10d] = useState([]);
	const [q13ba, setq13ba] = useState([]);
	const [q13bb, setq13bb] = useState([]);
	const [q13bc, setq13bc] = useState([]);
	const [q13bd, setq13bd] = useState([]);
	const [q14a, setq14a] = useState([]);
	const [q14b, setq14b] = useState([]);
	const [q14c, setq14c] = useState([]);
	const [spinner, setspinner] = useState(true);
	const [spinnerq10a, setspinnerq10a] = useState(true);
	const [spinnerq10b, setspinnerq10b] = useState(true);
	const [spinnerq10c, setspinnerq10c] = useState(true);
	const [spinnerq10d, setspinnerq10d] = useState(true);
	const [spinner13ba, setspinner13ba] = useState(true);
	const [spinnerq13bb, setspinnerq13bb] = useState(true);
	const [spinnerq13bc, setspinnerq13bc] = useState(true);
	const [spinnerq13bd, setspinnerq13bd] = useState(true);
	const [spinnerq14a, setspinnerq14a] = useState(true);
	const [spinnerq14b, setspinnerq14b] = useState(true);
	const [spinnerq14c, setspinnerq14c] = useState(true);
	const [errmsg, seterrmsg] = useState("");
	const [statecode, setstatecode] = useState(false);
	const [errmsgq10a, seterrmsgq10a] = useState("");
	const [statecodeq10a, setstatecodeq10a] = useState(false);
	const [errmsgq10b, seterrmsgq10b] = useState("");
	const [statecodeq10b, setstatecodeq10b] = useState(false);
	const [errmsgq10c, seterrmsgq10c] = useState("");
	const [statecodeq10c, setstatecodeq10c] = useState(false);
	const [errmsgq10d, seterrmsgq10d] = useState("");
	const [statecodeq10d, setstatecodeq10d] = useState(false);
	const [errmsgq13ba, seterrmsgq13ba] = useState("");
	const [statecodeq13ba, setstatecodeq13ba] = useState(false);
	const [errmsgq13bb, seterrmsgq13bb] = useState("");
	const [statecodeq13bb, setstatecodeq13bb] = useState(false);
	const [statecodeq13bc, setstatecodeq13bc] = useState(false);
	const [errmsgq13bc, seterrmsgq13bc] = useState("");
	const [statecodeq13bd, setstatecodeq13bd] = useState(false);
	const [errmsgq13bd, seterrmsgq13bd] = useState("");
	const [errmsgq14a, seterrmsgq14a] = useState("");
	const [statecodeq14a, setstatecodeq14a] = useState(false);
	const [errmsgq14b, seterrmsgq14b] = useState("");
	const [statecode14b, setstatecode14b] = useState(false);
	const [errmsgq14c, seterrmsgq14c] = useState("");
	const [statecodeq14c, setstatecodeq14c] = useState(false);

	const getCase = async () => {
		setspinner(true);
		await axiosInstance
			.get(`approvedcases/`)
			.then((res) => {
				if (res.status === 200) {
					const data = res.data.approved;
					const dt = data.map((e) => ({
						id: e.id,
						caseid: e.caseid,
						org: e.organization,
						staff: e.question_2,
						doc: e.question_3,
						dofdoc: e.question_4,
						grp: e.question_6_b,
						gen: e.question_7,
						etat: e.question_8_a,
						q8b: e.question_8_b,
						q8c: e.question_8_c,
						q8d: e.question_8_d,
						q8e: e.question_8_e,
						q8f: e.question_8_f,
						q10a: e.question_10_a,
						q10b: e.question_10_b,
						q10c: e.question_10_c,
						q10d: e.question_10_d,
						q12a: e.city,
						// q12a: e.question_12_a,
						q12b: e.question_12_b,
						q13ba: e.question_13_b_a,
						q13bb: e.question_13_b_b,
						q13bc: e.question_13_b_c,
						q13bd: e.question_13_b_d,
						q14aoui: e.question_14_a_oui,
						q14boui: e.question_14_b_oui,
						q14c: e.question_14_c,
					}));
					setcases(dt);
					setstatecode(true);
					setspinner(false);
				}
			})
			.catch((err) => {
				setspinner(false);
				if (err.request) {
					seterrmsg("Please check your internet connexion");
				} else if (err.response) {
					seterrmsg("Please check your internet connexion");
				}
			});
	};
	const getq10a = async () => {
		setspinnerq10a(true);
		await axiosInstance
			.get(`q10a/`)
			.then((res) => {
				if (res.status === 200) {
					setstatecodeq10a(true);
					const data = res.data;
					const dt = data.map((e) => ({
						q10a: e.question_10_a,
					}));
					// console.log(dt);
					setq10a(dt);
				}
			})
			.catch((err) => {
				setspinnerq10a(false);
				if (err.request) {
					seterrmsgq10a("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq10a("Please check your internet connexion");
				}
			});
	};
	const getq10b = async () => {
		setspinnerq10b(true);
		await axiosInstance
			.get(`q10b/`)
			.then((res) => {
				if (res.status === 200) {
					setstatecodeq10b(true);
					const data = res.data;
					const dt = data.map((e) => ({
						q10b: e.question_10_b,
					}));
					// console.log(dt);
					setq10b(dt);
				}
			})
			.catch((err) => {
				setstatecodeq10b(false);
				if (err.request) {
					seterrmsgq10b("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq10b("Please check your internet connexion");
				}
			});
	};
	const getq10c = async () => {
		setspinnerq10c(true);
		await axiosInstance
			.get(`q10c/`)
			.then((res) => {
				if (res.status === 200) {
					setstatecodeq10c(true);
					const data = res.data;
					const dt = data.map((e) => ({
						q10c: e.question_10_c,
					}));
					// console.log(dt);
					setq10c(dt);
				}
			})
			.catch((err) => {
				setspinnerq10c(false);
				if (err.request) {
					seterrmsgq10c("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq10c("Please check your internet connexion");
				}
			});
	};
	const getq10d = async () => {
		setspinnerq10d(true);
		await axiosInstance
			.get(`q10d/`)
			.then((res) => {
				if (res.status === 200) {
					setstatecodeq10d(true);
					const data = res.data;
					const dt = data.map((e) => ({
						q10d: e.question_10_d,
					}));
					// console.log(dt);
					setq10d(dt);
				}
			})
			.catch((err) => {
				setspinnerq10d(false);
				if (err.request) {
					seterrmsgq10d("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq10d("Please check your internet connexion");
				}
			});
	};
	const getqq13ba = async () => {
		setspinner13ba(true);
		await axiosInstance
			.get(`q13ba/`)
			.then((res) => {
				if (res.status === 200) {
					setstatecodeq13ba(true);
					const data = res.data;
					const dt = data.map((e) => ({
						q13ba: e.question_13_b_a,
					}));
					// console.log(dt);
					setq13ba(dt);
				}
			})
			.catch((err) => {
				setspinner13ba(false);
				if (err.request) {
					seterrmsgq13ba("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq13ba("Please check your internet connexion");
				}
			});
	};
	const getqq13bb = async () => {
		setspinnerq13bb(true);
		await axiosInstance
			.get(`q13bb/`)
			.then((res) => {
				if (res.status === 200) {
					setstatecodeq13bb(true);
					const data = res.data;
					const dt = data.map((e) => ({
						q13bb: e.question_13_b_b,
					}));
					// console.log(dt);
					setq13bb(dt);
				}
			})
			.catch((err) => {
				setspinnerq13bb(false);
				if (err.request) {
					seterrmsgq13bb("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq13bb("Please check your internet connexion");
				}
			});
	};

	const getqq13bc = async () => {
		setspinnerq13bc(true);
		await axiosInstance
			.get(`q13bc/`)
			.then((res) => {
				if (res.status === 200) {
					const data = res.data;
					const dt = data.map((e) => ({
						q13bc: e.question_13_b_c,
					}));
					setstatecodeq13bc(true);
					// console.log(dt);
					setq13bc(dt);
				}
			})
			.catch((err) => {
				setspinnerq13bc(false);
				if (err.request) {
					seterrmsgq13bc("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq13bc("Please check your internet connexion");
				}
			});
	};
	const getqq13bd = async () => {
		setspinnerq13bd(true);
		await axiosInstance
			.get(`q13bd/`)
			.then((res) => {
				if (res.status === 200) {
					const data = res.data;
					const dt = data.map((e) => ({
						q13bd: e.question_13_b_d,
					}));
					setstatecodeq13bd(true);
					// console.log(dt);
					setq13bd(dt);
				}
			})
			.catch((err) => {
				setspinnerq13bd(false);
				if (err.request) {
					seterrmsgq13bd("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq13bd("Please check your internet connexion");
				}
			});
	};

	const getq14a = async () => {
		setspinnerq14a(true);
		await axiosInstance
			.get(`q14aoui/`)
			.then((res) => {
				if (res.status === 200) {
					const data = res.data;
					const dt = data.map((e) => ({
						q14a: e.question_14_a_oui,
					}));
					setstatecodeq14a(true);
					// console.log(dt);
					setq14a(dt);
				}
			})
			.catch((err) => {
				setspinnerq14a(false);
				if (err.request) {
					seterrmsgq14a("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq14a("Please check your internet connexion");
				}
			});
	};
	const getq14b = async () => {
		setspinnerq14b(true);
		await axiosInstance
			.get(`q14boui/`)
			.then((res) => {
				if (res.status === 200) {
					const data = res.data;
					const dt = data.map((e) => ({
						q14b: e.question_14_b_oui,
					}));
					// console.log(dt);
					setq14b(dt);
					setstatecode14b(true);
				}
			})
			.catch((err) => {
				setspinnerq14b(false);
				if (err.request) {
					seterrmsgq14b("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq14b("Please check your internet connexion");
				}
			});
	};
	const getq14c = async () => {
		setspinnerq14c(true);
		await axiosInstance
			.get(`q14c/`)
			.then((res) => {
				if (res.status === 200) {
					const data = res.data;
					const dt = data.map((e) => ({
						q14c: e.question_14_c,
					}));
					setq14c(dt);
				}
				setstatecodeq14c(true);
			})
			.catch((err) => {
				setspinnerq14c(false);
				if (err.request) {
					seterrmsgq14c("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq14c("Please check your internet connexion");
				}
			});
	};

	useEffect(
		() => {
			getCase();
			getq10a();
			getq10b();
			getq10c();
			getq10d();
			getqq13ba();
			getqq13bb();
			getqq13bc();
			getqq13bd();
			getq14a();
			getq14b();
			getq14c();
		},
		[
			// cases.id,
			// q10a,q10b,q10c,q10d,q13ba,q13bb,q13bc,q13bd,q14a,q14b,q14c
		]
	); // axiosInstance
	const caseCount = cases.length;
	// console.log(cases);
	// console.log(statecode);
	// console.log(statecodeq10a);
	// console.log(statecodeq10b);
	// console.log(statecodeq10c);
	// console.log(statecodeq10d);

	//getting gender
	const autre = cases.filter((e) => e.gen === "Autre");
	const feminin = cases.filter((e) => e.gen === "Féminin");
	const masculin = cases.filter((e) => e.gen === "Masculin");
	// console.log(grpeDage);
	// console.log(convertgrp.values);

	// getting gpe d age

	const ans5 = cases.filter((item) => item.grp.indexOf("0-5 ans") > -1).length;
	const ans17 = cases.filter(
		(item) => item.grp.indexOf("6-17 ans") > -1
	).length;
	const ans29 = cases.filter(
		(item) => item.grp.indexOf("18-29 ans") > -1
	).length;
	const ans49 = cases.filter(
		(item) => item.grp.indexOf("30-49 ans") > -1
	).length;
	const ans64 = cases.filter(
		(item) => item.grp.indexOf("50-64 ans") > -1
	).length;
	const ans65 = cases.filter(
		(item) => item.grp.indexOf("65 ans et plus") > -1
	).length;
	const grpdAge = [
		{ value: ans5 },
		{ value: ans17 },
		{ value: ans29 },
		{ value: ans49 },
		{ value: ans64 },
		{ value: ans65 },
	];
	const COLORS = [
		"#FFDA83",
		"#A3A1FB",
		"#D12028",
		"#F48C47",
		"#1D384C",
		"#60C1CB",
	];

	const genderColor = ["#00A1AF", "#D12028", "#1D384C"];
	const genData = [
		{
			total: `${feminin.length}`,
		},
		{
			total: `${masculin.length}`,
		},
		{
			total: `${autre.length}`,
		},
	];

	//getting etat civil

	const celib = cases.filter((e) => e.etat === "Célibataire");
	const marie = cases.filter((e) => e.etat === "Marié(e)");
	//console.log(marie.length);
	const separe = cases.filter((e) => e.etat === "Séparé(e)");
	const divorce = cases.filter((e) => e.etat === "Divorcé(e)");
	const veuf = cases.filter((e) => e.etat === "Veuve / Veuf");
	const union = cases.filter(
		(e) => e.etat === "Union de fait (vivre ensemble sans être mariés)"
	);

	const etatcivile = [
		{
			total: `${celib.length}`,
		},
		{
			total: `${marie.length}`,
		},
		{
			total: `${separe.length}`,
		},
		{
			total: `${divorce.length}`,
		},
		{
			total: `${union.length}`,
		},
		{
			total: `${veuf.length}`,
		},
	];
	const etatColor = [
		"#00A1AF",
		"#FFDA83",
		"#A3A1FB",
		"#F48C47",
		"#55D8FE",
		"#1D384C",
	];

	//console.log(cases.map(e=>e.etat));

	const q8b = cases.filter((e) => e.q8b === "Oui");
	const q8c = cases.filter((e) => e.q8c === "Oui");
	const q8d = cases.filter((e) => e.q8d === "Oui");
	const q8dnon = cases.filter((e) => e.q8d === "Non");
	const q8e = cases.filter((e) => e.q8e === "Oui");
	const q8f = cases.filter((e) => e.q8f === "Oui");
	//q10 chart
	const viol = cases.filter((item) => item.q10a.indexOf("Viol") > -1);
	const violconj = cases.filter(
		(item) =>
			item.q10a.indexOf("Viol conjugal entre couples mariés et non mariés") > -1
	);
	const agr = cases.filter(
		(item) => item.q10a.indexOf("Agression sexuelle sur mineur") > -1
	);
	const coer = cases.filter(
		(item) => item.q10a.indexOf("Coercition sexuelle") > -1
	);
	const har = cases.filter(
		(item) => item.q10a.indexOf("Harcèlement sexuel") > -1
	);
	const attou = cases.filter(
		(item) => item.q10a.indexOf("Attouchements inappropriés") > -1
	);
	const etr = cases.filter(
		(item) =>
			item.q10a.indexOf(
				"Forcer la victime à avoir des relations sexuelles sans préservatif"
			) > -1
	);
	const empl = cases.filter(
		(item) =>
			item.q10a.indexOf(
				"Empêcher la victime de faire ses propres choix quant à la décision d'avoir ou non un bébé"
			) > -1
	);
	const autr = cases.filter((item) => item.q10a.indexOf("Autres") > -1);
	const meu = cases.filter(
		(item) => item.q10b.indexOf("Meurtre / féminicide") > -1
	);
	const cpoing = cases.filter(
		(item) => item.q10b.indexOf("Coups de poing et/ou coups de pieds") > -1
	);
	const cpdobj = cases.filter(
		(item) => item.q10b.indexOf("Coups portés avec des objets") > -1
	);
	const att = cases.filter(
		(item) => item.q10b.indexOf("Attaque à l’acide") > -1
	);
	const attAcouto = cases.filter(
		(item) => item.q10b.indexOf("Attaque au couteau ou à la machette") > -1
	);
	const attqAleau = cases.filter(
		(item) =>
			item.q10b.indexOf(
				"Attaque à l’eau chaude, à l’huile chaude ou autre objet chaud"
			) > -1
	);
	const priv = cases.filter(
		(item) =>
			item.q10b.indexOf("Privation de la liberté physique de la victime") > -1
	);
	const privDeNoutr = cases.filter(
		(item) => item.q10b.indexOf("Privation de nourriture de la victime") > -1
	);
	const neglDEnfa = cases.filter(
		(item) =>
			item.q10b.indexOf(
				"Négligence physique d’enfant (si la victime est un enfant)"
			) > -1
	);
	const neglDpers = cases.filter(
		(item) =>
			item.q10b.indexOf(
				"Négligence physique de personnes âgées (si la victime est une personne âgée)"
			) > -1
	);
	const destr = cases.filter(
		(item) =>
			item.q10b.indexOf("Destruction d’objets dans la maison de la victime") >
			-1
	);
	const rite = cases.filter(
		(item) =>
			item.q10b.indexOf(
				"Rites de veuvage affectant la santé physique et mentale de la victime"
			) > -1
	);
	const autr2 = cases.filter((item) => item.q10b.indexOf("Autres") > -1);

	const men = cases.filter((item) => item.q10c.indexOf("Menaces") > -1);
	const inj = cases.filter((item) => item.q10c.indexOf("Injures") > -1);
	const neglEm = cases.filter(
		(item) => item.q10c.indexOf("Négligence émotionnelle") > -1
	);
	const humil = cases.filter(
		(item) =>
			item.q10c.indexOf("Humiliation de la victime face aux autres") > -1
	);
	const attVisant = cases.filter(
		(item) =>
			item.q10c.indexOf(
				"Attaques visant à nuire à la réputation de la victime"
			) > -1
	);
	const isol = cases.filter(
		(item) =>
			item.q10c.indexOf(
				"Isolement de la victime de sa famille d’origine ou ses amis"
			) > -1
	);
	const contr = cases.filter(
		(item) => item.q10c.indexOf("Contrôle des mouvements de la victime") > -1
	);
	const cntrDecom = cases.filter(
		(item) =>
			item.q10c.indexOf("Contrôle de la communication de la victime") > -1
	);
	const refus = cases.filter(
		(item) =>
			item.q10c.indexOf(
				"Refus de divorce malgré le souhait de la victime de se divorcer"
			) > -1
	);
	const abus = cases.filter(
		(item) =>
			item.q10c.indexOf("Abus spirituel / abus religieux de la victime") > -1
	);
	const autr3 = cases.filter((item) => item.q10c.indexOf("Autres") > -1);

	const auteur = cases.filter(
		(item) => item.q10d.indexOf("L’auteur abandonne ou chasse la victime") > -1
	);
	const conf = cases.filter(
		(item) => item.q10d.indexOf("Confiscation des revenus de la victime") > -1
	);
	const inter = cases.filter(
		(item) =>
			item.q10d.indexOf(
				"Interdiction de participation à la gestion des revenus du ménage"
			) > -1
	);
	const restdeLAccessDeducatio = cases.filter(
		(item) => item.q10d.indexOf("Restriction de l’accès à l'éducation") > -1
	);
	const restdeLAccessDemploie = cases.filter(
		(item) => item.q10d.indexOf("Restriction de l’accès à l'emploi") > -1
	);
	const restdeLAccessDeconomique = cases.filter(
		(item) =>
			item.q10d.indexOf("Restriction de l’accès aux ressources économiques") >
			-1
	);
	const privDepriseEncharge = cases.filter(
		(item) =>
			item.q10d.indexOf(
				"Privation d’une prise en charge médicale, de médicaments ou d'appareils d’assistance"
			) > -1
	);
	const levirat = cases.filter(
		(item) => item.q10d.indexOf("Lévirat après la mort du conjoint") > -1
	);
	const pertepropriete = cases.filter(
		(item) =>
			item.q10d.indexOf(
				"Perte des propriétés et des biens / non accès à l’héritage après la mort du conjoint ou d’un parent"
			) > -1
	);
	const perteDenfa = cases.filter(
		(item) =>
			item.q10d.indexOf("Perte des enfants après la mort du conjoint") > -1
	);
	const autr4 = cases.filter((item) => item.q10d.indexOf("Autres") > -1);
	//q12a

	const Bandalungwa = cases.filter(
		(item) => item.q12a.indexOf("Bandalungwa") > -1
	);
	const barumbu = cases.filter((item) => item.q12a.indexOf("Barumbu") > -1);
	const bumbu = cases.filter((item) => item.q12a.indexOf("Bumbu") > -1);
	const gombe = cases.filter((item) => item.q12a.indexOf("Gombe") > -1);
	const kalamu = cases.filter((item) => item.q12a.indexOf("Kalamu") > -1);
	const kasa_Vubu = cases.filter((item) => item.q12a.indexOf("Kasa-Vubu") > -1);
	const kimbanseke = cases.filter(
		(item) => item.q12a.indexOf("Kimbanseke") > -1
	);
	const kinshasa = cases.filter((item) => item.q12a.indexOf("Kinshasa") > -1);
	const kintambo = cases.filter((item) => item.q12a.indexOf("Kintambo") > -1);
	const kisenso = cases.filter((item) => item.q12a.indexOf("Kisenso") > -1);
	const lemba = cases.filter((item) => item.q12a.indexOf("Lemba") > -1);
	const limete = cases.filter((item) => item.q12a.indexOf("Limete") > -1);
	const lingwala = cases.filter((item) => item.q12a.indexOf("Lingwala") > -1);
	const makala = cases.filter((item) => item.q12a.indexOf("Makala") > -1);
	const maluku = cases.filter((item) => item.q12a.indexOf("Maluku") > -1);
	const masina = cases.filter((item) => item.q12a.indexOf("Masina") > -1);
	const matete = cases.filter((item) => item.q12a.indexOf("Matete") > -1);
	const mont_Ngafula = cases.filter(
		(item) => item.q12a.indexOf("Mont Ngafula") > -1
	);
	const ndjili = cases.filter((item) => item.q12a.indexOf("Ndjili") > -1);
	const ngaba = cases.filter((item) => item.q12a.indexOf("Ngaba") > -1);
	const ngaliema = cases.filter((item) => item.q12a.indexOf("Ngaliema") > -1);
	const ngiri_ngiri = cases.filter(
		(item) => item.q12a.indexOf("Ngiri-Ngiri") > -1
	);
	const nsele = cases.filter((item) => item.q12a.indexOf("Nsele") > -1);
	const selembao = cases.filter((item) => item.q12a.indexOf("Selembao") > -1);
	const bunia = cases.filter((item) => item.q12a.indexOf("Bunia") > -1);
	const aru = cases.filter((item) => item.q12a.indexOf("Aru") > -1);
	const djugu = cases.filter((item) => item.q12a.indexOf("Djugu") > -1);
	const irumu = cases.filter((item) => item.q12a.indexOf("Irumu") > -1);
	const mahagi = cases.filter((item) => item.q12a.indexOf("Mahagi") > -1);
	const mambasa = cases.filter((item) => item.q12a.indexOf("Mambasa") > -1);
	const kananga = cases.filter((item) => item.q12a.indexOf("Kananga") > -1);
	const demba = cases.filter((item) => item.q12a.indexOf("Demba") > -1);
	const dibaya = cases.filter((item) => item.q12a.indexOf("Dibaya") > -1);
	const dimbelenge = cases.filter(
		(item) => item.q12a.indexOf("Dimbelenge") > -1
	);
	const kazumba = cases.filter((item) => item.q12a.indexOf("Kazumba") > -1);
	const luiza = cases.filter((item) => item.q12a.indexOf("Luiza") > -1);

	const mbuji_mayi = cases.filter(
		(item) => item.q12a.indexOf("Mbuji-mayi") > -1
	);
	const kabeya = cases.filter(
		(item) => item.q12a.indexOf("Kabeya-kamwanga") > -1
	);
	const katanda = cases.filter((item) => item.q12a.indexOf("Katanda") > -1);
	const lupatapata = cases.filter(
		(item) => item.q12a.indexOf("Lupatapata") > -1
	);
	const miabi = cases.filter((item) => item.q12a.indexOf("Miabi") > -1);
	const tshilenge = cases.filter((item) => item.q12a.indexOf("Tshilenge") > -1);
	const kindu = cases.filter((item) => item.q12a.indexOf("Kindu") > -1);
	const kabambare = cases.filter((item) => item.q12a.indexOf("Kabambare") > -1);
	const kailo = cases.filter((item) => item.q12a.indexOf("Kailo") > -1);
	const kasongo = cases.filter((item) => item.q12a.indexOf("Kasongo") > -1);
	const kibombo = cases.filter((item) => item.q12a.indexOf("Kibombo") > -1);
	const lubutu = cases.filter((item) => item.q12a.indexOf("Lubutu") > -1);
	const pangi = cases.filter((item) => item.q12a.indexOf("Pangi") > -1);
	const punia = cases.filter((item) => item.q12a.indexOf("Punia") > -1);

	const benivile = cases.filter(
		(item) => item.q12a.indexOf("Beni (Ville)") > -1
	);
	const butembo = cases.filter((item) => item.q12a.indexOf("Butembo") > -1);
	const goma = cases.filter((item) => item.q12a.indexOf("Goma") > -1);
	const beni = cases.filter(
		(item) => item.q12a.indexOf("Beni (Territoire)") > -1
	);
	const lubero = cases.filter((item) => item.q12a.indexOf("Lubero") > -1);
	const masisi = cases.filter((item) => item.q12a.indexOf("Masisi") > -1);
	const nyiragongo = cases.filter(
		(item) => item.q12a.indexOf("Nyiragongo") > -1
	);
	const rutshuru = cases.filter((item) => item.q12a.indexOf("Rutshuru") > -1);
	const walikale = cases.filter((item) => item.q12a.indexOf("Walikale") > -1);
	const bukavu = cases.filter((item) => item.q12a.indexOf("Bukavu") > -1);
	const fizi = cases.filter((item) => item.q12a.indexOf("Fizi") > -1);
	const idjwi = cases.filter((item) => item.q12a.indexOf("Idjwi") > -1);
	const kabare = cases.filter((item) => item.q12a.indexOf("Kabare") > -1);
	const kalehe = cases.filter((item) => item.q12a.indexOf("Kalehe") > -1);
	const mwenga = cases.filter((item) => item.q12a.indexOf("Mwenga") > -1);
	const shabunda = cases.filter((item) => item.q12a.indexOf("Shabunda") > -1);
	const uvira = cases.filter((item) => item.q12a.indexOf("Uvira") > -1);
	const walungu = cases.filter((item) => item.q12a.indexOf("Walungu") > -1);
	const kisangani = cases.filter((item) => item.q12a.indexOf("Kisangani") > -1);
	const bafwasende = cases.filter(
		(item) => item.q12a.indexOf("Bafwasende") > -1
	);
	const banalia = cases.filter((item) => item.q12a.indexOf("Banalia") > -1);
	const basoko = cases.filter((item) => item.q12a.indexOf("Basoko") > -1);
	const isangi = cases.filter((item) => item.q12a.indexOf("Isangi") > -1);
	const opala = cases.filter((item) => item.q12a.indexOf("Opala") > -1);
	const ubundu = cases.filter((item) => item.q12a.indexOf("Ubundu") > -1);
	const yahuma = cases.filter((item) => item.q12a.indexOf("Yahuma") > -1);

	//q12b
	const urbain = cases.filter(
		(item) => item.q12b.indexOf("Milieu urbain") > -1
	).length;
	const semi = cases.filter(
		(item) => item.q12b.indexOf("Milieu semi-urbain") > -1
	).length;
	const rural = cases.filter(
		(item) => item.q12b.indexOf("Milieu rural") > -1
	).length;

	const milieu = [{ value: urbain }, { value: semi }, { value: rural }];
	//console.log(q10a);
	//const total = cases.filter((item,cur) => item.q10a[cur].length!=="")

	const milieuColor = ["#FFDA83", "#A3A1FB", "#FF8373"];

	//q13
	const dec = cases.filter((item) => item.q13ba.indexOf("Décès") > -1);
	const mal = cases.filter(
		(item) => item.q13ba.indexOf("Maladies (par exemple hypertension)") > -1
	);
	const bles = cases.filter((item) => item.q13ba.indexOf("Blessures") > -1);
	const dou = cases.filter((item) => item.q13ba.indexOf("Douleurs") > -1);
	const troublgyn = cases.filter(
		(item) => item.q13ba.indexOf("Troubles gynécologiques et reproductifs") > -1
	);
	const handicap = cases.filter(
		(item) => item.q13ba.indexOf("Invalidité / Handicap") > -1
	);
	const gros = cases.filter(
		(item) => item.q13ba.indexOf("Grossesse non voulue") > -1
	);
	const ptDenfa = cases.filter(
		(item) => item.q13ba.indexOf("Perte de l'enfant à naître") > -1
	);
	const abusDalc = cases.filter(
		(item) => item.q13ba.indexOf("Abus d'alcool") > -1
	);
	const abusDedrog = cases.filter(
		(item) => item.q13ba.indexOf("Abus de drogues") > -1
	);
	const abusDemed = cases.filter(
		(item) => item.q13ba.indexOf("Abus de médicaments") > -1
	);
	const autr5 = cases.filter((item) => item.q13ba.indexOf("Autres") > -1);

	const risDeSui = cases.filter(
		(item) => item.q13bb.indexOf("Risque de suicide") > -1
	);
	const peur = cases.filter(
		(item) => item.q13bb.indexOf("Peur et anxiété") > -1
	);
	const depr = cases.filter((item) => item.q13bb.indexOf("Dépression") > -1);
	const detr = cases.filter(
		(item) => item.q13bb.indexOf("Détresse émotionnelle") > -1
	);
	const sent = cases.filter(
		(item) => item.q13bb.indexOf("Sentiment de honte") > -1
	);
	const sentDecol = cases.filter(
		(item) => item.q13bb.indexOf("Sentiment de colère") > -1
	);
	const troublDesom = cases.filter(
		(item) => item.q13bb.indexOf("Troubles de sommeil") > -1
	);
	const troublAlim = cases.filter(
		(item) => item.q13bb.indexOf("Troubles alimentaires") > -1
	);
	const peterDespoir = cases.filter(
		(item) => item.q13bb.indexOf("Perte d’espoir") > -1
	);
	const manqueDeconf = cases.filter(
		(item) => item.q13bb.indexOf("Manque de confiance en soi") > -1
	);
	const autr6 = cases.filter((item) => item.q13bb.indexOf("Autres") > -1);

	const perterDeSu = cases.filter(
		(item) =>
			item.q13bc.indexOf("Perte ou manque des moyens de subsistance") > -1
	);
	const perterDeRev = cases.filter(
		(item) => item.q13bc.indexOf("Perte ou manque de revenus") > -1
	);
	const sexDesurvie = cases.filter(
		(item) => item.q13bc.indexOf("Sexe de survie") > -1
	);
	const peterDemaison = cases.filter(
		(item) => item.q13bc.indexOf("Perte de maison") > -1
	);
	const demenagement = cases.filter(
		(item) => item.q13bc.indexOf("Déménagements fréquents") > -1
	);
	const situation = cases.filter(
		(item) => item.q13bc.indexOf("Situation de sans-abri") > -1
	);
	const pasoupeu = cases.filter(
		(item) => item.q13bc.indexOf("Pas ou peu d'éducation ou de formation") > -1
	);
	const autr7 = cases.filter((item) => item.q13bc.indexOf("Autres") > -1);

	const rupture = cases.filter(
		(item) =>
			item.q13bd.indexOf(
				"Rupture de la famille (famille créée par la victime et l’auteur)"
			) > -1
	);
	const conflits = cases.filter(
		(item) => item.q13bd.indexOf("Conflits domestiques réguliers") > -1
	);
	const abndon = cases.filter(
		(item) =>
			item.q13bd.indexOf(
				"Abandon de la victime par sa famille d’origine (parents)"
			) > -1
	);
	const stigm = cases.filter(
		(item) =>
			item.q13bd.indexOf("Stigmatisation de la victime par la communauté") > -1
	);
	const perterDeGarde = cases.filter(
		(item) =>
			item.q13bd.indexOf(
				"Perte de la garde ou de contact avec propres enfants"
			) > -1
	);
	const isolDelaVictime = cases.filter(
		(item) => item.q13bd.indexOf("Isolement social de la victime") > -1
	);
	const autr8 = cases.filter((item) => item.q13bd.indexOf("Autres") > -1);

	const mere = cases.filter((item) => item.q14aoui.indexOf("Mère") > -1);
	const pere = cases.filter((item) => item.q14aoui.indexOf("Père") > -1);
	const tuthom = cases.filter(
		(item) => item.q14aoui.indexOf("Tuteur / gardien (homme)") > -1
	);
	const tutfem = cases.filter(
		(item) => item.q14aoui.indexOf("Tuteur / gardienne (femme)") > -1
	);
	const proFrer = cases.filter(
		(item) => item.q14aoui.indexOf("Propres frères") > -1
	);
	const propSoeur = cases.filter(
		(item) => item.q14aoui.indexOf("Propres sœurs") > -1
	);
	const propDenfa = cases.filter(
		(item) => item.q14aoui.indexOf("Propres enfants adultes") > -1
	);
	const gdmere = cases.filter(
		(item) => item.q14aoui.indexOf("Grand-mère") > -1
	);
	const gdpere = cases.filter(
		(item) => item.q14aoui.indexOf("Grand-père") > -1
	);
	const belmere = cases.filter(
		(item) => item.q14aoui.indexOf("Belle-mère") > -1
	);
	const beaupere = cases.filter(
		(item) => item.q14aoui.indexOf("Beau-père") > -1
	);
	const beauFere = cases.filter(
		(item) => item.q14aoui.indexOf("Beaux-frères") > -1
	);
	const belSoeur = cases.filter(
		(item) => item.q14aoui.indexOf("Belles-sœurs") > -1
	);
	const oncle = cases.filter((item) => item.q14aoui.indexOf("Oncles") > -1);
	const tante = cases.filter((item) => item.q14aoui.indexOf("Tantes") > -1);
	const autrmembre = cases.filter(
		(item) =>
			item.q14aoui.indexOf("Autre(s) membre(s) de la famille de la victime") >
			-1
	);

	const mari = cases.filter((item) => item.q14boui.indexOf("Mari") > -1);
	const exmari = cases.filter((item) => item.q14boui.indexOf("Ex-mari") > -1);
	const epouse = cases.filter((item) => item.q14boui.indexOf("Epouse") > -1);
	const exepouse = cases.filter(
		(item) => item.q14boui.indexOf("Ex-épouse") > -1
	);
	const partHomme = cases.filter(
		(item) =>
			item.q14boui.indexOf("Partenaire intime actuel qui est homme") > -1
	);
	const partFemme = cases.filter(
		(item) =>
			item.q14boui.indexOf("Partenaire intime actuel qui est femme") > -1
	);
	const expartHomme = cases.filter(
		(item) => item.q14boui.indexOf("Ex-partenaire intime qui est homme") > -1
	);
	const expartFemme = cases.filter(
		(item) => item.q14boui.indexOf("Ex-partenaire intime qui est femme") > -1
	);

	const patronHomDom = cases.filter(
		(item) => item.q14c.indexOf("Patron (homme) de personnel domestique") > -1
	);
	const patronFemDom = cases.filter(
		(item) => item.q14c.indexOf("Patronne (femme) de personnel domestique") > -1
	);
	const personelHomDomestique = cases.filter(
		(item) => item.q14c.indexOf("Personnel domestique (homme)") > -1
	);
	const personelFemDomestique = cases.filter(
		(item) => item.q14c.indexOf("Personnel domestique (femme)") > -1
	);
	const bailDemezon = cases.filter(
		(item) =>
			item.q14c.indexOf("Bailleur / propriétaire de la maison (homme)") > -1
	);
	const bailleDemezon = cases.filter(
		(item) =>
			item.q14c.indexOf("Bailleresse / propriétaire de la maison (femme)") > -1
	);
	const autremember2 = cases.filter(
		(item) =>
			item.q14c.indexOf(
				"Autre(s) membre(s) du cercle restreint de la victime"
			) > -1
	);

	const displayData = () => {
		if (
			statecode === false ||
			statecodeq10a === false ||
			statecodeq10b === false ||
			statecodeq10c === false ||
			statecodeq10d === false ||
			statecodeq13ba === false ||
			statecodeq13bb === false ||
			statecodeq13bc === false ||
			statecodeq13bd === false ||
			statecodeq14a === false ||
			statecode14b === false ||
			statecodeq14c === false
		) {
			if (
				spinner === true &&
				spinnerq10a === true &&
				spinnerq10b === true &&
				spinnerq10c === true &&
				spinnerq10d === true &&
				spinner13ba === true &&
				spinnerq13bb === true &&
				spinnerq13bc === true &&
				spinnerq13bd === true &&
				spinnerq14a === true &&
				spinnerq14b === true &&
				spinnerq14c === true
			) {
				return (
					<Grid container spacing={4}>
						<Grid item xs={12} sm={12}>
							<Grid container direction="row" spacing={4}>
								<Grid item xs={12} sm={4}>
									<Skeleton variant="rect" height={100} />
								</Grid>
								<Grid item xs={12} sm={8}>
									<Skeleton variant="rect" height={100} />
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Grid container direction="row" spacing={4}>
								<Grid item xs={12} sm={8}>
									<Skeleton variant="rect" height={100} />
								</Grid>
								<Grid item xs={12} sm={4}>
									<Skeleton variant="rect" height={100} />
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Grid container direction="row" spacing={4}>
								<Grid item xs={12} sm={4}>
									<Skeleton variant="rect" height={300} />
								</Grid>
								<Grid item xs={12} sm={8}>
									<Grid container direction="row" spacing={4}>
										<Grid item xs={4} sm={4}>
											<Skeleton variant="rect" height={80} />
										</Grid>
										<Grid item xs={4} sm={4}>
											<Skeleton variant="rect" height={80} />
										</Grid>
										<Grid item xs={4} sm={4}>
											<Skeleton variant="rect" height={80} />
										</Grid>
										<Grid item xs={4} sm={4}>
											<Skeleton variant="rect" height={80} />
										</Grid>
										<Grid item xs={4} sm={4}>
											<Skeleton variant="rect" height={80} />
										</Grid>
										<Grid item xs={4} sm={4}>
											<Skeleton variant="rect" height={80} />
										</Grid>
										<Grid item xs={4} sm={4}>
											<Skeleton variant="rect" height={80} />
										</Grid>
										<Grid item xs={4} sm={4}>
											<Skeleton variant="rect" height={80} />
										</Grid>
										<Grid item xs={4} sm={4}>
											<Skeleton variant="rect" height={80} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Grid container direction="row" spacing={4}>
								<Grid item xs={12} sm={6}>
									<Skeleton variant="rect" height={400} />
								</Grid>
								<Grid item xs={12} sm={6}>
									<Skeleton variant="rect" height={400} />
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} sm={12}>
							<Grid container direction="row" spacing={4}>
								<Grid item xs={12} sm={6}>
									<Skeleton variant="rect" height={400} />
								</Grid>
								<Grid item xs={12} sm={6}>
									<Grid container spacing={4}>
										<Grid item xs={12} sm={12}>
											<Skeleton variant="rect" height={200} />
										</Grid>
										<Grid item xs={12} sm={12}>
											<Skeleton variant="rect" height={170} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				);
			} else {
				return (
					<Grid container spacing={4}>
						<Grid item xs={12} sm={12}>
							<Paper>
								<Grid
									container
									direction="row"
									justifyContent="center"
									style={{ height: "600px" }}
									alignItems="center"
								>
									<Grid item xs={12} sm={4}></Grid>
									<Grid item xs={12} sm={4}>
										<Grid container justifyContent="center">
											<Grid item>
												<CloudOffTwoToneIcon size={40} />
											</Grid>
											<Grid item>
												<Typography>{errmsg}</Typography>
											</Grid>
										</Grid>
										<Grid container justifyContent="center">
											<Grid item>
												<Button
													color="primary"
													variant="text"
													aria-label="Restart"
													component="span"
													onClick={() => {
														getCase();
														getq14c();
														getq14b();
														getq14a();
														getqq13bd();
														getqq13bc();
														getqq13bb();
														getqq13ba();
														getq10d();
														getq10c();
														getq10b();
														getq10a();
													}}
													startIcon={<RestoreIcon />}
												>
													reload
												</Button>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={4}></Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				);
			}
		} else if (
			statecode === true &&
			statecodeq10a === true &&
			statecodeq10b === true &&
			statecodeq10c === true &&
			statecodeq10d === true &&
			statecodeq13ba === true &&
			statecodeq13bb === true &&
			statecodeq13bc === true &&
			statecodeq13bd === true &&
			statecodeq14a === true &&
			statecode14b === true &&
			statecodeq14c === true
		) {
			return (
				<div>
					<Grid container direction="row" spacing={3}>
						<Grid item xs={12} sm={4}>
							<Paper>
								<Grid container className={classes.leftAndRightAndTopAndBottom}>
									<Grid item xs={12} sm={12}>
										<Typography className={classes.typochart}>
											Total des cas
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12}>
										<DoughnutCharts
											data={totalcases}
											value={caseCount}
											width={150}
											height={147}
											fill="#00A1AF"
											alignItems="center"
											justifyContent="center"
											innerRadius="60%"
											outerRadius="90%"
										/>
									</Grid>
								</Grid>
							</Paper>
						</Grid>

						<Grid item xs={12} sm={8}>
							<Paper>
								<LineChart />
							</Paper>
						</Grid>
					</Grid>
					<Grid
						container
						direction="row"
						spacing={3}
						className={classes.margin_Top_30}
					>
						<Grid item xs={12} sm={8}>
							<Paper>
								<Grid container className={classes.leftAndRightAndTopAndBottom}>
									<Grid item xs={12} sm={12}>
										<Typography className={classes.typochart}>
											Groupe d’âge de la victime
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12}>
										<Grid container direction="row" spacing={6}>
											<Grid item xs={12} sm={6}>
												<DoughnutCharts
													data={grpdAge}
													width={200}
													height={200}
													innerRadius="50%"
													outerRadius="100%"
													//typography="Groupe d’âge de la victime"
													filledColor={grpdAge.map((entry, index) => (
														<Cell
															key={`cell-${index}`}
															fill={COLORS[index % COLORS.length]}
														/>
													))}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Grid container direction="row">
													<Grid item xs={12} sm={12}>
														<Grid container direction="column" spacing={1}>
															<Grid item xs={12} sm={12}>
																<Grid container direction="row">
																	<Grid item xs={1} sm={1}>
																		<RadioButtonUncheckedIcon
																			style={{
																				fontSize: 12,
																				color: "#FFDA83",
																				marginRight: 10,
																			}}
																		/>
																	</Grid>
																	<Grid item xs={10} sm={10}>
																		<Typography
																			className={classes.typosubchart}
																		>
																			0 à 5 ans
																		</Typography>{" "}
																	</Grid>
																	<Grid item xs={1} sm={1}>
																		<Typography
																			className={classes.typosubchart}
																		>
																			{ans5}
																		</Typography>
																	</Grid>
																</Grid>
															</Grid>
															<Grid item xs={12} sm={12}>
																<Grid container direction="row">
																	<Grid item xs={1} sm={1}>
																		<RadioButtonUncheckedIcon
																			style={{ fontSize: 12, color: "#A3A1FB" }}
																		/>
																	</Grid>
																	<Grid item xs={10} sm={10}>
																		<Typography
																			className={classes.typosubchart}
																		>
																			6 à 17 ans
																		</Typography>{" "}
																	</Grid>
																	<Grid item xs={1} sm={1}>
																		<Typography
																			className={classes.typosubchart}
																		>
																			{ans17}
																		</Typography>
																	</Grid>
																</Grid>
															</Grid>
															<Grid item xs={12} sm={12}>
																<Grid container direction="row">
																	<Grid item xs={1} sm={1}>
																		<RadioButtonUncheckedIcon
																			style={{ fontSize: 12, color: "#D12028" }}
																		/>
																	</Grid>
																	<Grid item xs={10} sm={10}>
																		<Typography
																			className={classes.typosubchart}
																		>
																			18 à 29 ans
																		</Typography>{" "}
																	</Grid>
																	<Grid item xs={1} sm={1}>
																		<Typography
																			className={classes.typosubchart}
																		>
																			{ans29}
																		</Typography>
																	</Grid>
																</Grid>
															</Grid>
															<Grid item xs={12} sm={12}>
																<Grid container direction="row">
																	<Grid item xs={1} sm={1}>
																		<RadioButtonUncheckedIcon
																			style={{ fontSize: 12, color: "#F48C47" }}
																		/>
																	</Grid>
																	<Grid item xs={10} sm={10}>
																		<Typography
																			className={classes.typosubchart}
																		>
																			30 à 49 ans
																		</Typography>{" "}
																	</Grid>
																	<Grid item xs={1} sm={1}>
																		<Typography
																			className={classes.typosubchart}
																		>
																			{ans49}
																		</Typography>
																	</Grid>
																</Grid>
															</Grid>
															<Grid item xs={12} sm={12}>
																<Grid container direction="row">
																	<Grid item xs={1} sm={1}>
																		<RadioButtonUncheckedIcon
																			style={{ fontSize: 12, color: "#1D384C" }}
																		/>
																	</Grid>
																	<Grid item xs={10} sm={10}>
																		<Typography
																			className={classes.typosubchart}
																		>
																			50 à 64 ans
																		</Typography>{" "}
																	</Grid>
																	<Grid item xs={1} sm={1}>
																		<Typography
																			className={classes.typosubchart}
																		>
																			{ans64}
																		</Typography>
																	</Grid>
																</Grid>
															</Grid>
															<Grid item xs={12}>
																<Grid container direction="row">
																	<Grid item xs={1} sm={1}>
																		<RadioButtonUncheckedIcon
																			style={{ fontSize: 12, color: "#60C1CB" }}
																		/>
																	</Grid>
																	<Grid item xs={10} sm={10}>
																		<Typography
																			className={classes.typosubchart}
																		>
																			65 ans et plus
																		</Typography>{" "}
																	</Grid>
																	<Grid item xs={1} sm={1}>
																		<Typography
																			className={classes.typosubchart}
																		>
																			{ans65}
																		</Typography>
																	</Grid>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Paper>
								<Grid
									container
									direction="row"
									className={classes.leftAndRightAndTopAndBottom}
								>
									<Grid item xs={12} sm={12}>
										<BarCharts
											// data={genData}
											data={genData}
											filledcolor={genData.map((entry, index) => (
												<Cell
													key={`cell-${index}`}
													fill={genderColor[index % 20]}
												/>
											))}
											//  feminin={feminin.length}
											//   masculin={masculin.length}
											//    autre={autre.length}
											name="Genre"
										/>
									</Grid>
									<Grid item xs={12}>
										<Grid container>
											<Grid item xs={12}>
												<Grid container direction="row">
													<Grid item xs={6}>
														<Grid container direction="row">
															<Grid item>
																<StopIcon
																	style={{ fontSize: 12, color: "#00A1AF" }}
																/>
															</Grid>
															<Grid item>
																<Typography className={classes.typosubchart}>
																	Féminin
																</Typography>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={6}>
														<Grid
															container
															justifyContent="flex-end"
															alignItems="flex-end"
														>
															<Grid item>
																<Typography className={classes.typosubchart}>
																	{feminin.length}
																</Typography>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Grid container direction="row">
													<Grid item xs={6}>
														<Grid container direction="row">
															<Grid item>
																<StopIcon
																	style={{ fontSize: 12, color: "#D12028" }}
																/>
															</Grid>
															<Grid item>
																<Typography className={classes.typosubchart}>
																	Masculin
																</Typography>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={6}>
														<Grid
															container
															justifyContent="flex-end"
															alignItems="flex-end"
														>
															<Grid item>
																<Typography className={classes.typosubchart}>
																	{masculin.length}
																</Typography>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Grid container direction="row">
													<Grid item xs={6}>
														<Grid container direction="row">
															<Grid item>
																<StopIcon
																	style={{ fontSize: 12, color: "#1D384C" }}
																/>
															</Grid>
															<Grid item>
																<Typography className={classes.typosubchart}>
																	Autre
																</Typography>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={6}>
														<Grid
															container
															justifyContent="flex-end"
															alignItems="flex-end"
														>
															<Grid item>
																<Typography className={classes.typosubchart}>
																	{autre.length}
																</Typography>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
					<br />
					<Grid
						container
						direction="row"
						spacing={2}
						className={classes.margin_Top_20}
					>
						<Grid item xs={12} sm={4}>
							<Paper>
								<Grid
									container
									direction="row"
									className={classes.leftAndRightAndTopAndBottom}
								>
									<Grid item xs={12} sm={12}>
										<BarCharts
											data={etatcivile}
											name="Etat civil de la victime"
											filledcolor={etatcivile.map((entry, index) => (
												<Cell
													key={`cell-${index}`}
													fill={etatColor[index % 20]}
												/>
											))}
										/>
									</Grid>
									<Grid item xs={12} sm={12}>
										<Grid container>
											<Grid item xs={12} sm={12}>
												<Grid container direction="row">
													<Grid item xs={6}>
														<Grid container direction="row">
															<Grid item>
																<StopIcon
																	style={{ fontSize: 12, color: "#00A1AF" }}
																/>
															</Grid>
															<Grid item>
																<Typography className={classes.typosubchart}>
																	Célibataire
																</Typography>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={6}>
														<Grid
															container
															justifyContent="flex-end"
															alignItems="flex-end"
														>
															<Grid item>
																<Typography className={classes.typosubchart}>
																	{celib.length}
																</Typography>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Grid container direction="row">
													<Grid item xs={6}>
														<Grid container direction="row">
															<Grid item>
																<StopIcon
																	style={{ fontSize: 12, color: "#FFDA83" }}
																/>
															</Grid>
															<Grid item>
																<Typography className={classes.typosubchart}>
																	Marié(e)
																</Typography>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={6}>
														<Grid
															container
															justifyContent="flex-end"
															alignItems="flex-end"
														>
															<Grid item>
																<Typography className={classes.typosubchart}>
																	{marie.length}
																</Typography>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Grid container direction="row">
													<Grid item xs={6}>
														<Grid container direction="row">
															<Grid item>
																<StopIcon
																	style={{ fontSize: 12, color: "#A3A1FB" }}
																/>
															</Grid>
															<Grid item>
																<Typography className={classes.typosubchart}>
																	Séparé(e)
																</Typography>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={6}>
														<Grid
															container
															justifyContent="flex-end"
															alignItems="flex-end"
														>
															<Grid item>
																<Typography className={classes.typosubchart}>
																	{separe.length}
																</Typography>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Grid container direction="row">
													<Grid item xs={6}>
														<Grid container direction="row">
															<Grid item>
																<StopIcon
																	style={{ fontSize: 12, color: "#F48C47" }}
																/>
															</Grid>
															<Grid item>
																<Typography className={classes.typosubchart}>
																	Divorcé(e)
																</Typography>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={6}>
														<Grid
															container
															justifyContent="flex-end"
															alignItems="flex-end"
														>
															<Grid item>
																<Typography className={classes.typosubchart}>
																	{divorce.length}
																</Typography>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Grid container direction="row">
													<Grid item xs={6}>
														<Grid container direction="row">
															<Grid item>
																<StopIcon
																	style={{ fontSize: 12, color: "#55D8FE" }}
																/>
															</Grid>
															<Grid item>
																<Typography className={classes.typosubchart}>
																	Union de fait
																</Typography>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={6}>
														<Grid
															container
															justifyContent="flex-end"
															alignItems="flex-end"
														>
															<Grid item>
																<Typography className={classes.typosubchart}>
																	{union.length}
																</Typography>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Grid container direction="row">
													<Grid item xs={6}>
														<Grid container direction="row">
															<Grid item>
																<StopIcon
																	style={{ fontSize: 12, color: "#1D384C" }}
																/>
															</Grid>
															<Grid item>
																<Typography className={classes.typosubchart}>
																	Veuve/Veuf
																</Typography>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={6}>
														<Grid
															container
															justifyContent="flex-end"
															alignItems="flex-end"
														>
															<Grid item>
																<Typography className={classes.typosubchart}>
																	{veuf.length}
																</Typography>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
						<Grid item xs={12} sm={8}>
							<Grid container direction="row" spacing={3}>
								<Grid item xs={6}>
									<Paper>
										<Grid
											container
											direction="column"
											justifyContent="center"
											alignItems="center"
											className={classes.q8cardDashBoard}
										>
											<Grid item>
												<Typography align="center" className={classes.q8typo}>
													Victime s’identifiant en tant que LGBTI{" "}
												</Typography>
											</Grid>
											<Grid item>
												<Typography className={classes.q8num}>
													{q8b.length}
												</Typography>
											</Grid>
										</Grid>
									</Paper>
								</Grid>
								<Grid item xs={6}>
									<Paper>
										<Grid
											container
											direction="column"
											justifyContent="center"
											alignItems="center"
											className={classes.q8cardDashBoard}
										>
											<Grid item>
												<Typography align="center" className={classes.q8typo}>
													Victime en situation de handicap{" "}
												</Typography>
											</Grid>
											<Grid item>
												<Typography className={classes.q8num}>
													{q8c.length}
												</Typography>
											</Grid>
										</Grid>
									</Paper>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={3}>
								<Grid item xs={6}>
									<Paper>
										<Grid
											container
											direction="column"
											justifyContent="center"
											alignItems="center"
											className={classes.q8cardDashBoard}
										>
											<Grid item>
												<Typography align="center" className={classes.q8typo}>
													Victime avec occupation
												</Typography>
											</Grid>
											<Grid item>
												<Typography className={classes.q8num}>
													{q8d.length}
												</Typography>
											</Grid>
										</Grid>
									</Paper>
								</Grid>
								<Grid item xs={6}>
									<Paper>
										<Grid
											container
											direction="column"
											justifyContent="center"
											alignItems="center"
											className={classes.q8cardDashBoard}
										>
											<Grid item>
												<Typography align="center" className={classes.q8typo}>
													Victime sans occupation{" "}
												</Typography>
											</Grid>
											<Grid item>
												<Typography className={classes.q8num}>
													{q8dnon.length}
												</Typography>
											</Grid>
										</Grid>
									</Paper>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={3}>
								<Grid item xs={6}>
									<Paper>
										<Grid
											container
											direction="column"
											justifyContent="center"
											alignItems="center"
											className={classes.q8cardDashBoard}
										>
											<Grid item>
												<Typography align="center" className={classes.q8typo}>
													Victime mineure{" "}
												</Typography>
											</Grid>
											<Grid item>
												<Typography className={classes.q8num}>
													{q8e.length}
												</Typography>
											</Grid>
										</Grid>
									</Paper>
								</Grid>
								<Grid item xs={6}>
									<Paper>
										<Grid
											container
											direction="column"
											justifyContent="center"
											alignItems="center"
											className={classes.q8cardDashBoard}
										>
											<Grid item>
												<Typography align="center" className={classes.q8typo}>
													Victime de troisième âge
												</Typography>
											</Grid>
											<Grid item>
												<Typography className={classes.q8num}>
													{q8f.length}
												</Typography>
											</Grid>
										</Grid>
									</Paper>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						container
						direction="row"
						spacing={3}
						className={classes.margin_Top_30}
					>
						<Grid item xs={12} sm={6}>
							<Grid container direction="column">
								<Grid item xs={12}>
									<Tabls
										title1="Types de violence domestique"
										title2="Cas"
										incidentTotal={q10a.length}
										viol={viol.length}
										conj={violconj.length}
										agr={agr.length}
										coer={coer.length}
										har={har.length}
										attou={attou.length}
										etr={etr.length}
										empl={empl.length}
										autr={autr.length}
										physiqueTotal={q10b.length}
										meu={meu.length}
										coupDepoing={cpoing.length}
										coupDobj={cpdobj.length}
										att={att.length}
										attAcouto={attAcouto.length}
										attqAleau={attqAleau.length}
										priv={priv.length}
										privDeNoutr={privDeNoutr.length}
										neglDEnfa={neglDEnfa.length}
										neglDpers={neglDpers.length}
										destr={destr.length}
										rite={rite.length}
										autr2={autr2.length}
										psychoTotal={q10c.length}
										men={men.length}
										inj={inj.length}
										neglEm={neglEm.length}
										humil={humil.length}
										attVisant={attVisant.length}
										isol={isol.length}
										contr={contr.length}
										cntrDecom={cntrDecom.length}
										refus={refus.length}
										abus={abus.length}
										autr3={autr3.length}
										econTotal={q10d.length}
										auteur={auteur.length}
										conf={conf.length}
										inter={inter.length}
										restdeLAccessDeducatio={restdeLAccessDeducatio.length}
										restdeLAccessDemploie={restdeLAccessDemploie.length}
										restdeLAccessDeconomique={restdeLAccessDeconomique.length}
										privDepriseEncharge={privDepriseEncharge.length}
										levirat={levirat.length}
										pertepropriete={pertepropriete.length}
										perteDenfa={perteDenfa.length}
										autr4={autr4.length}
									/>
								</Grid>

								<br />
							</Grid>
						</Grid>
						<Grid item xs={12} sm={6}>
							<PrejudiceTbl
								title1="Catégories de préjudice causé"
								title2="Cas"
								prephysTotal={q13ba.length}
								dec={dec.length}
								mal={mal.length}
								bles={bles.length}
								dou={dou.length}
								troublgyn={troublgyn.length}
								handicap={handicap.length}
								gros={gros.length}
								ptDenfa={ptDenfa.length}
								abusDalc={abusDalc.length}
								abusDedrog={abusDedrog.length}
								abusDemed={abusDemed.length}
								autr5={autr5.length}
								preEmolTotal={q13bb.length}
								risDeSui={risDeSui.length}
								peur={peur.length}
								depr={depr.length}
								detr={detr.length}
								sent={sent.length}
								sentDecol={sentDecol.length}
								troublDesom={troublDesom.length}
								troublAlim={troublAlim.length}
								peterDespoir={peterDespoir.length}
								manqueDeconf={manqueDeconf.length}
								autr6={autr6.length}
								preEcomTotal={q13bc.length}
								perterDeSu={perterDeSu.length}
								perterDeRev={perterDeRev.length}
								sexDesurvie={sexDesurvie.length}
								peterDemaison={peterDemaison.length}
								demenagement={demenagement.length}
								situation={situation.length}
								pasoupeu={pasoupeu.length}
								autr7={autr7.length}
								impTotal={q13bd.length}
								rupture={rupture.length}
								conflits={conflits.length}
								abndon={abndon.length}
								stigm={stigm.length}
								perterDeGarde={perterDeGarde.length}
								isolDelaVictime={isolDelaVictime.length}
								autr8={autr8.length}
							/>
						</Grid>
					</Grid>
					<br />
					<br />
					<Grid container direction="row" spacing={3}>
						<Grid item xs={12} sm={6}>
							<ProvinceTbl
								title1="Lieu de l’acte de violence domestique"
								title2="cas"
								kinTotal={
									Bandalungwa.length +
									barumbu.length +
									bumbu.length +
									gombe.length +
									kalamu.length +
									kasa_Vubu.length +
									kimbanseke.length +
									kinshasa.length +
									kintambo.length +
									kisenso.length +
									lemba.length +
									limete.length +
									lingwala.length +
									makala.length +
									maluku.length +
									masina.length +
									matete.length +
									mont_Ngafula.length +
									ndjili.length +
									ngaba.length +
									ngaliema.length +
									ngiri_ngiri.length +
									nsele.length +
									selembao.length
								}
								Bandalungwa={Bandalungwa.length}
								barumbu={barumbu.length}
								bumbu={bumbu.length}
								gombe={gombe.length}
								kalamu={kalamu.length}
								kasa_Vubu={kasa_Vubu.length}
								kimbanseke={kimbanseke.length}
								kinshasa={kinshasa.length}
								kintambo={kintambo.length}
								kisenso={kisenso.length}
								lemba={lemba.length}
								limete={limete.length}
								lingwala={lingwala.length}
								makala={makala.length}
								maluku={maluku.length}
								masina={masina.length}
								matete={matete.length}
								mont_Ngafula={mont_Ngafula.length}
								ndjili={ndjili.length}
								ngaba={ngaba.length}
								ngaliema={ngaliema.length}
								ngiri_ngiri={ngiri_ngiri.length}
								nsele={nsele.length}
								selembao={selembao.length}
								ituriTotal={
									bunia.length +
									aru.length +
									djugu.length +
									irumu.length +
									mahagi.length +
									mambasa.length
								}
								bunia={bunia.length}
								aru={aru.length}
								djugu={djugu.length}
								irumu={irumu.length}
								mahagi={mahagi.length}
								mambasa={mambasa.length}
								kaisaiCentralTotal={
									kananga.length +
									demba.length +
									dibaya.length +
									dimbelenge.length +
									kazumba.length +
									luiza.length
								}
								kananga={kananga.length}
								demba={demba.length}
								dibaya={dibaya.length}
								dimbelenge={dimbelenge.length}
								kazumba={kazumba.length}
								luiza={luiza.length}
								kasaiOrientalTotal={
									mbuji_mayi.length +
									kabeya.length +
									katanda.length +
									lupatapata.length +
									miabi.length +
									tshilenge.length
								}
								mbuji_mayi={mbuji_mayi.length}
								kabeya={kabeya.length}
								katanda={katanda.length}
								lupatapata={lupatapata.length}
								miabi={miabi.length}
								tshilenge={tshilenge.length}
								maniemaTotal={
									kindu.length +
									kabambare.length +
									kailo.length +
									kasongo.length +
									kibombo.length +
									lubutu.length +
									pangi.length +
									punia.length
								}
								kindu={kindu.length}
								kabambare={kabambare.length}
								kailo={kailo.length}
								kasongo={kasongo.length}
								kibombo={kibombo.length}
								lubutu={lubutu.length}
								pangi={pangi.length}
								punia={punia.length}
								nordkivuTotal={
									benivile.length +
									butembo.length +
									goma.length +
									beni.length +
									lubero.length +
									masisi.length +
									nyiragongo.length +
									rutshuru.length +
									walikale.length
								}
								ben={benivile.length}
								butembo={butembo.length}
								goma={goma.length}
								beni={beni.length}
								lubero={lubero.length}
								masisi={masisi.length}
								nyiragongo={nyiragongo.length}
								rutshuru={rutshuru.length}
								walikale={walikale.length}
								sudkivuTotal={
									bukavu.length +
									fizi.length +
									idjwi.length +
									kabare.length +
									mwenga.length +
									shabunda.length +
									uvira.length +
									walungu.length
								}
								bukavu={bukavu.length}
								fizi={fizi.length}
								idjwi={idjwi.length}
								kabare={kabare.length}
								kalehe={kalehe.length}
								mwenga={mwenga.length}
								shabunda={shabunda.length}
								uvira={uvira.length}
								walungu={walungu.length}
								tshopoTotal={
									kisangani.length +
									bafwasende.length +
									banalia.length +
									basoko.length +
									isangi.length +
									opala.length +
									ubundu.length +
									yahuma.length
								}
								kisangani={kisangani.length}
								bafwasende={bafwasende.length}
								banalia={banalia.length}
								basoko={basoko.length}
								isangi={isangi.length}
								opala={opala.length}
								ubundu={ubundu.length}
								yahuma={yahuma.length}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Grid container spacing={4}>
								<Grid item xs={12} sm={12}>
									<Auteurtbl
										title1="Catégories d’auteur "
										title2="Cas"
										mememberTotal={q14a.length}
										mere={mere.length}
										pere={pere.length}
										tuthom={tuthom.length}
										tutfem={tutfem.length}
										proFrer={proFrer.length}
										propSoeur={propSoeur.length}
										propDenfa={propDenfa.length}
										gdmere={gdmere.length}
										gdpere={gdpere.length}
										belmere={belmere.length}
										beaupere={beaupere.length}
										beauFere={beauFere.length}
										belSoeur={belSoeur.length}
										oncle={oncle.length}
										tante={tante.length}
										autrmembre={autrmembre.length}
										conjointeTotal={q14b.length}
										mari={mari.length}
										exmari={exmari.length}
										epouse={epouse.length}
										exepouse={exepouse.length}
										partHomme={partHomme.length}
										partFemme={partFemme.length}
										expartHomme={expartHomme.length}
										expartFemme={expartFemme.length}
										autreRelationTotal={q14c.length}
										patronHomDom={patronHomDom.length}
										patronFemDom={patronFemDom.length}
										personelHomDomestique={personelHomDomestique.length}
										personelFemDomestique={personelFemDomestique.length}
										bailDemezon={bailDemezon.length}
										bailleDemezon={bailleDemezon.length}
										autremember2={autremember2.length}
									/>
								</Grid>
								<Grid item xs={12} sm={12}>
									<Paper>
										<Grid
											container
											className={classes.leftAndRightAndTopAndBottom}
										>
											<Grid item xs={12} sm={12}>
												<Typography className={classes.typochart}>
													Milieu où l’acte de violence domestique a eu lieu
												</Typography>
											</Grid>
											<Grid item xs={12} sm={12}>
												<Grid container direction="row" spacing={2}>
													<Grid item xs={12} sm={6}>
														<DoughnutCharts
															data={milieu}
															width={150}
															height={170}
															alignItems="center"
															justifyContent="center"
															innerRadius="50%"
															outerRadius="90%"
															//typography="Milieu où l’acte de violence domestique a eu lieu"
															filledColor={milieu.map((entery, index) => (
																<Cell
																	fill={milieuColor[index % milieuColor.length]}
																/>
															))}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														{/* <Grid container direction='row' className={classes.margin_Top_30} spacing={2} >                                                 */}
														<Grid item xs={12} sm={12}>
															<Grid
																container
																direction="column"
																spacing={1}
																className={classes.milieu}
															>
																<Grid item xs={12} sm={12}>
																	<Grid
																		container
																		direction="row"
																		className={classes.margin_20}
																	>
																		<Grid item xs={1} sm={2}>
																			<RadioButtonUncheckedIcon
																				style={{
																					fontSize: 12,
																					color: "#FFDA83",
																				}}
																			/>
																		</Grid>
																		<Grid item xs={10} sm={8}>
																			<Typography
																				className={classes.typosubchart}
																			>
																				Milieu urbain
																			</Typography>{" "}
																		</Grid>
																		<Grid item xs={1} sm={2}>
																			<Typography
																				className={classes.typosubchart}
																			>
																				{urbain}
																			</Typography>
																		</Grid>
																	</Grid>
																</Grid>
																<Grid item xs={12} sm={12}>
																	<Grid container direction="row">
																		<Grid item xs={1} sm={2}>
																			<RadioButtonUncheckedIcon
																				style={{
																					fontSize: 12,
																					color: "#A3A1FB",
																				}}
																			/>
																		</Grid>
																		<Grid item xs={10} sm={8}>
																			<Typography
																				className={classes.typosubchart}
																			>
																				Milieu semi-urbain
																			</Typography>{" "}
																		</Grid>
																		<Grid item xs={1} sm={2}>
																			<Typography
																				className={classes.typosubchart}
																			>
																				{semi}
																			</Typography>
																		</Grid>
																	</Grid>
																</Grid>
																<Grid item xs={12} sm={12}>
																	<Grid
																		container
																		direction="row"
																		className={classes.margin_Bottom_20s}
																	>
																		<Grid item xs={1} sm={2}>
																			<RadioButtonUncheckedIcon
																				style={{
																					fontSize: 12,
																					color: "#FF8373",
																				}}
																			/>
																		</Grid>
																		<Grid item xs={10} sm={8}>
																			<Typography
																				className={classes.typosubchart}
																			>
																				Milieu rural
																			</Typography>{" "}
																		</Grid>
																		<Grid item xs={1} sm={2}>
																			<Typography
																				className={classes.typosubchart}
																			>
																				{rural}
																			</Typography>
																		</Grid>
																	</Grid>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											{/* </Grid> */}
										</Grid>
									</Paper>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			);
		}
	};
	return (
		<BackPaper
			content={
				<div>
					{/* title */}
					<Grid container>
						<Grid item xs={12} sm={12}>
							<Typography variant="h4" className={classes.bigtitle}>
								Case Statistics
							</Typography>
						</Grid>
					</Grid>
					<Divider />
					{/* next paper */}
					<Grid container direction="row" className={classes.margin_Top_30}>
						<Grid item xs={1} sm={1}></Grid>
						<Grid item xs={10} sm={10}>
							{displayData()}
						</Grid>
						<Grid item xs={1} sm={1}></Grid>
					</Grid>
				</div>
			}
		/>
	);
};

export default Statistic;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import useStyles from "../../../styles";
import { Typography } from "@material-ui/core";

const useRowStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},
});

function createData3(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
		],
	};
}

function createData2(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
		],
	};
}

function createData(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8,
	tpn9,
	vl9,
	tpn10,
	vl10,
	tpn11,
	vl11,
	tpn12,
	vl12,
	tpn13,
	vl13,
	tpn14,
	vl14,
	tpn15,
	vl15,
	tpn16,
	vl16,
	tpn17,
	vl17,
	tpn18,
	vl18,
	tpn19,
	vl19,
	tpn20,
	vl20,
	tpn21,
	vl21,
	tpn22,
	vl22,
	tpn23,
	vl23,
	tpn24,
	vl24
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
			{ contentID: `${tpn9}`, amount: `${vl9}` },
			{ contentID: `${tpn10}`, amount: `${vl10}` },
			{ contentID: `${tpn11}`, amount: `${vl11}` },
			{ contentID: `${tpn12}`, amount: `${vl12}` },
			{ contentID: `${tpn13}`, amount: `${vl13}` },
			{ contentID: `${tpn14}`, amount: `${vl14}` },
			{ contentID: `${tpn15}`, amount: `${vl15}` },
			{ contentID: `${tpn16}`, amount: `${vl16}` },
			{ contentID: `${tpn17}`, amount: `${vl17}` },
			{ contentID: `${tpn18}`, amount: `${vl18}` },
			{ contentID: `${tpn19}`, amount: `${vl19}` },
			{ contentID: `${tpn20}`, amount: `${vl20}` },
			{ contentID: `${tpn21}`, amount: `${vl21}` },
			{ contentID: `${tpn22}`, amount: `${vl22}` },
			{ contentID: `${tpn23}`, amount: `${vl23}` },
			{ contentID: `${tpn24}`, amount: `${vl24}` },
		],
	};
}

function createData4(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
		],
	};
}
function createData5(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
		],
	};
}
function createData6(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8,
	tpn9,
	vl9
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
			{ contentID: `${tpn9}`, amount: `${vl9}` },
		],
	};
}
function createData8(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
		],
	};
}
function createData7(
	name,
	total,
	tpn,
	vl,
	tpn2,
	vl2,
	tpn3,
	vl3,
	tpn4,
	vl4,
	tpn5,
	vl5,
	tpn6,
	vl6,
	tpn7,
	vl7,
	tpn8,
	vl8,
	tpn9,
	vl9
) {
	return {
		name,
		total,
		history: [
			{ contentID: `${tpn}`, amount: `${vl}` },
			{ contentID: `${tpn2}`, amount: `${vl2}` },
			{ contentID: `${tpn3}`, amount: `${vl3}` },
			{ contentID: `${tpn4}`, amount: `${vl4}` },
			{ contentID: `${tpn5}`, amount: `${vl5}` },
			{ contentID: `${tpn6}`, amount: `${vl6}` },
			{ contentID: `${tpn7}`, amount: `${vl7}` },
			{ contentID: `${tpn8}`, amount: `${vl8}` },
			{ contentID: `${tpn9}`, amount: `${vl9}` },
		],
	};
}
function Row(props) {
	const { row } = props;
	const [open, setOpen] = React.useState(false);
	const classes = useRowStyles();

	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row">
					<Typography
						variant="button"
						style={{
							fontWeight: 330,
							textTransform: "unset",
							textAlign: "left",
							cursor: "pointer",
						}}
						onClick={() => setOpen(!open)}
					>
						{row.name}
					</Typography>
				</TableCell>
				<TableCell align="right" style={{ fontWeight: 330 }}>
					{row.total}
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							{/* <Typography variant="h6" gutterBottom component="div">
                Type
              </Typography> */}
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
                    { row.name === "Kinshasa" ?
										<TableCell style={{ fontWeight: 300, fontSize: 18 }}>
											Communes
										</TableCell>
                    :
                    <TableCell style={{ fontWeight: 300, fontSize: 18 }}>
											Ville / Territoire
										</TableCell>
                    }
										<TableCell
											align="right"
											style={{ fontWeight: 300, fontSize: 18 }}
										>
											Cas
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{row.history.map((historyRow) => (
										<TableRow key={historyRow.contentID}>
											<TableCell style={{ fontWeight: 300 }}>
												{historyRow.contentID}
											</TableCell>
											<TableCell align="right" style={{ fontWeight: 300 }}>
												{historyRow.amount} 
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

Row.propTypes = {
	row: PropTypes.shape({
		// calories: PropTypes.number.isRequired,
		// carbs: PropTypes.number.isRequired,
		// fat: PropTypes.number.isRequired,
		history: PropTypes.arrayOf(
			PropTypes.shape({
				amount: PropTypes.number.isRequired,
				contentID: PropTypes.any.isRequired,
				// contentID: PropTypes.string.isRequired,
			})
		).isRequired,
		name: PropTypes.string.isRequired,
		total: PropTypes.number.isRequired,
	}).isRequired,
};

const ProvinceTbl = ({
	title1,
	title2,
	kinTotal,
	Bandalungwa,
	barumbu,
	bumbu,
	gombe,
	kalamu,
	kasa_Vubu,
	kimbanseke,
	kinshasa,
	kintambo,
	kisenso,
	lemba,
	limete,
	lingwala,
	makala,
	maluku,
	masina,
	matete,
	mont_Ngafula,
	ndjili,
	ngaba,
	ngaliema,
	ngiri_ngiri,
	nsele,
	selembao,
	ituriTotal,
	bunia,
	aru,
	djugu,
	irumu,
	mahagi,
	mambasa,
	kaisaiCentralTotal,
	kananga,
	demba,
	dibaya,
	dimbelenge,
	kazumba,
	luiza,
	kasaiOrientalTotal,
	mbuji_mayi,
	kabeya,
	katanda,
	lupatapata,
	miabi,
	tshilenge,
	maniemaTotal,
	kindu,
	kabambare,
	kailo,
	kasongo,
	kibombo,
	lubutu,
	pangi,
	punia,
	nordkivuTotal,
	ben,
	butembo,
	goma,
	beni,
	lubero,
	masisi,
	nyiragongo,
	rutshuru,
	walikale,
	sudkivuTotal,
	bukavu,
	fizi,
	idjwi,
	kabare,
	kalehe,
	mwenga,
	shabunda,
	uvira,
	walungu,
	tshopoTotal,
	kisangani,
	bafwasende,
	banalia,
	basoko,
	isangi,
	opala,
	ubundu,
	yahuma,
}) => {
	const rowsx = [
		createData(
			"Kinshasa",
			`${kinTotal}`,
			"Bandalungwa",
			`${Bandalungwa}`,
			"Barumbu",
			`${barumbu}`,
			"Bumbu",
			`${bumbu}`,
			"Gombe",
			`${gombe}`,
			"Kalamu",
			`${kalamu}`,
			"Kasa-Vubu",
			`${kasa_Vubu}`,
			"Kimbanseke",
			`${kimbanseke}`,
			"Kinshasa",
			`${kinshasa}`,
			"Kintambo",
			`${kintambo}`,
			"Kisenso",
			`${kisenso}`,
			"Lemba",
			`${lemba}`,
			"Limete",
			`${limete}`,
			"Lingwala",
			`${lingwala}`,
			"Makala",
			`${makala}`,
			"Maluku",
			`${maluku}`,
			"Masina",
			`${masina}`,
			"Matete",
			`${matete}`,
			"Mont Ngafula",
			`${mont_Ngafula}`,
			"Ndjili",
			`${ndjili}`,
			"Ngaba",
			`${ngaba}`,
			"Ngaliema",
			`${ngaliema}`,
			"Ngiri-Ngiri",
			`${ngiri_ngiri}`,
			"Nsele",
			`${nsele}`,
			"Selembao",
			`${selembao}`
		),
		createData2(
			"Ituri",
			`${ituriTotal}`,
			"Bunia",
			`${bunia}`,
			"Aru",
			`${aru}`,
			"Djugu",
			`${djugu}`,
			"Irumu",
			`${irumu}`,
			"Mahagi",
			`${mahagi}`,
			"Mambasa",
			`${mambasa}`
		),
		createData3(
			"Kasaï-Central",
			`${kaisaiCentralTotal}`,
			"Kananga",
			`${kananga}`,
			"Demba",
			`${demba}`,
			"Dibaya",
			`${dibaya}`,
			"Dimbelenge",
			`${dimbelenge}`,
			"Kazumba",
			`${kazumba}`,
			"Luiza",
			`${luiza}`
		),
		createData4(
			"Kasaï Oriental",
			`${kasaiOrientalTotal}`,
			"Mbuji-Mayi",
			`${mbuji_mayi}`,
			"Kabeya-Kamwanga",
			`${kabeya}`,
			"Katanda",
			`${katanda}`,
			"Lupatapata",
			`${lupatapata}`,
			"Miabi",
			`${miabi}`,
			"Tshilenge",
			`${tshilenge}`
		),
		createData5(
			"Maniema",
			`${maniemaTotal}`,
			"Kindu",
			`${kindu}`,
			"Kabambare",
			`${kabambare}`,
			"Kailo",
			`${kailo}`,
			"Kasongo",
			`${kasongo}`,
			"Kibombo",
			`${kibombo}`,
			"Lubutu",
			`${lubutu}`,
			"Pangi",
			`${pangi}`,
			"Punia",
			`${punia}`
		),
		createData6(
			"Nord-Kivu",
			`${nordkivuTotal}`,
			"Beni (Ville)",
			`${ben}`,
			"Butembo",
			`${butembo}`,
			"Goma",
			`${goma}`,
			"Beni (Territoire)",
			`${beni}`,
			"Lubero",
			`${lubero}`,
			"Masisi",
			`${masisi}`,
			"Nyiragongo",
			`${nyiragongo}`,
			"Rutshuru",
			`${rutshuru}`,
			"Walikale",
			`${walikale}`
		),
		createData7(
			"Sud-Kivu",
			`${sudkivuTotal}`,
			"Bukavu",
			`${bukavu}`,
			"Fizi",
			`${fizi}`,
			"Idjwi",
			`${idjwi}`,
			"Kabare",
			`${kabare}`,
			"Kalehe",
			`${kalehe}`,
			"Mwenga",
			`${mwenga}`,
			"Shabunda",
			`${shabunda}`,
			"Uvira",
			`${uvira}`,
			"Walungu",
			`${walungu}`
		),
		createData8(
			"Tshopo",
			`${tshopoTotal}`,
			"Kisangani",
			`${kisangani}`,
			"Bafwasende",
			`${bafwasende}`,
			"Banalia",
			`${banalia}`,
			"Basoko",
			`${basoko}`,
			"Isangi",
			`${isangi}`,
			"Opala",
			`${opala}`,
			"Ubundu",
			`${ubundu}`,
			"Yahuma",
			`${yahuma}`
		),
	];
	const classes = useStyles();

	return (
		<TableContainer component={Paper}>
			<Table aria-label="collapsible table">
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell className={classes.typochart}>{title1}</TableCell>
						<TableCell align="right" className={classes.typochart}>
							{title2}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rowsx.map((row) => (
						<Row key={row.name} row={row} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
export { ProvinceTbl };

import {
	Grid,
	Typography,
	Button,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@material-ui/core";
import useStyles from "../../component/website/campain/style";
import React, { useEffect } from "react";
import Section1 from "../../component/website/AboutObs/section1";
import AboutPic from "../../component/website/widgets/AboutPic";
import { NavLink } from "react-router-dom";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useTranslation } from "react-i18next";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@material-ui/icons/FiberManualRecordOutlined";
import { Helmet } from "react-helmet";

export default function AboutObs() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<Helmet>
				<title>{t("about")}</title>
				<meta name="description" content={t("about-header1")} />
			</Helmet>
			<Grid container className={classes.section_1} alignItems="center">
				<Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
				<Grid item xs={10} sm={10} md={10} lg={10} xl={8}>
					<Section1 />
				</Grid>
				<Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
			</Grid>
			<Grid container direction="row">
				<Grid item lg={3} xl={2}>
					<AboutPic />
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
					<Grid
						container
						direction="row"
						spacing={2}
						className={classes.marginTop_13}
					>
						<Grid item xs={1} sm={1}></Grid>
						<Grid item xs={10} sm={10}>
							<Grid container>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<Typography className={classes.pg_header}>
												{t("about-header1")}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<Typography className={classes.dark_txt}>
												{t("about-header1-text")}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<Typography className={classes.dark_txt}>
												{t("about-header1-text-obj")}
											</Typography>
										</Grid>
										<List>
											<ListItem>
												<ListItemIcon>
													<FiberManualRecordIcon style={{ color: "#00A1AF" }} />
												</ListItemIcon>
												<ListItemText className={classes.dark_txt}>
													{t("about-header1-text-objsub1")}
												</ListItemText>
											</ListItem>
											<ListItem>
												<ListItemIcon>
													<FiberManualRecordIcon style={{ color: "#00A1AF" }} />
												</ListItemIcon>
												<ListItemText className={classes.dark_txt}>
													{t("about-header1-text-objsub2")}
												</ListItemText>
											</ListItem>
											<ListItem>
												<ListItemIcon>
													<FiberManualRecordIcon style={{ color: "#00A1AF" }} />
												</ListItemIcon>
												<ListItemText className={classes.dark_txt}>
													{t("about-header1-text-objsub3")}
												</ListItemText>
											</ListItem>
										</List>
										<List style={{ marginLeft: 30 }}>
											<ListItem>
												<ListItemIcon>
													<FiberManualRecordOutlinedIcon
														style={{ color: "#00A1AF" }}
													/>
												</ListItemIcon>
												<ListItemText className={classes.dark_txt}>
													{t("about-header1-text-objsub3-1")}
												</ListItemText>
											</ListItem>
											<ListItem>
												<ListItemIcon>
													<FiberManualRecordOutlinedIcon
														style={{ color: "#00A1AF" }}
													/>
												</ListItemIcon>
												<ListItemText className={classes.dark_txt}>
													{t("about-header1-text-objsub3-2")}
												</ListItemText>
											</ListItem>
										</List>
									</Grid>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Grid container spacing={2} className={classes.marginTop_13}>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<Typography className={classes.pg_header}>
												{t("about-header2")}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<Typography className={classes.dark_txt}>
												{t("about-header2-text")}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<Typography className={classes.dark_txt}>
												{t("about-header2-2-text")}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<Typography className={classes.dark_txt}>
												{t("about-header2-3-text")}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid container direction="row" justifyContent="center">
								<Grid item justifyContent="center">
									<Typography className={classes.about_link}>
										<NavLink
											activeClassName="is-active"
											style={{
												textDecoration: "none",
												color: "white",
												marginTop: "8px",
											}}
											to={"/" + i18n.language + t("link-partners")}
										>
											<Button
												endIcon={<KeyboardArrowRightIcon />}
												// size="Large"
												color="secondary"
												className={classes.btn_txt}
											>
												{t("about-partner-link")}
											</Button>
										</NavLink>
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={1} sm={1}></Grid>
				</Grid>
			</Grid>
		</div>
	);
}

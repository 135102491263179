import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import useStyles from "./style.js";
import { useTranslation } from "react-i18next";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { NavLink } from "react-router-dom";

const Section2 = () => {
  const classes = useStyles();
  const { t ,i18n} = useTranslation();
  return (
    <div>

      <Grid container direction = 'row' className={classes.margin_section}>

        <Grid item xs={12} sm={12}>
          <Grid container spacing={3} >
            <Grid item xs={12} sm={12}>
              <Typography variant="h3" className={classes.overview_ttl}>
                {t("overviewtitle")} {t("sub2")}
                </Typography>
               
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography className={classes.dark_txt}>
                  {t("overviewcontent")}
                  </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
            <Typography variant="h6" className={classes.overview_link}>
                <NavLink
                
                  activeClassName="is-active"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    marginTop: "8px",
                  }}
                  to={'/'+i18n.language+t('link-overview')} 
                >
                  <Button
                    endIcon={<KeyboardArrowRightIcon />}
                    //size="Large"
                    color="secondary"
                    className={classes.btn_txt}
                  >
                    {t("overviewLink")}
                  </Button>
                </NavLink>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        
      </Grid>
    </div>
  );
};

export default Section2;

import React, { useState, useEffect } from "react";
import {
	Grid,
	Card,
	Typography,
	CardContent,
	TextField,
} from "@material-ui/core";
import useStyles from "../dashboard/style";
import { Redirect, useHistory } from "react-router-dom";
import { BtnLogin } from "../../widget/util";
import oxide from "../../../assets/oxide.svg";
import { useForm } from "react-hook-form";
// import {useHistory} from 'react-router-dom'
import axiosInstance from "../../service/axiosApi";
import CircularProgress from "@material-ui/core/CircularProgress";

const Login = () => {
	const classes = useStyles();
	const history = useHistory();
	const [ison, setison] = useState(false);
	const [org, setorg] = useState([]);
	const [profile, setprofile] = useState([]);
	const [info, setinfo] = useState([]);
	const [perpetor, setperpetor] = useState([]);
	const [need, setneed] = useState([]);

	const [msg, setmsg] = useState();

	//  const url = 'http://127.0.0.1:8000/';
	const { register, handleSubmit } = useForm();
	const onSubmit = (data, e) => {
		e.preventDefault();
		//console.log(data);
		handLogin(data.user, data.pass);
	};

	const getOrgInfo = () => {
		const data = sessionStorage.getItem("organization");
		//  //console.log('data from local storage',JSON.parse(data));
		return setorg(JSON.parse(data));
	};
	const getPofile = () => {
		const data = sessionStorage.getItem("profile");
		// //console.log('data from local storage',JSON.parse(data));
		return setprofile(JSON.parse(data));
	};
	const getInfo = () => {
		const data = sessionStorage.getItem("info");
		////console.log('data from local storage',JSON.parse(data));
		return setinfo(JSON.parse(data));
	};
	const getPerpetor = () => {
		const data = sessionStorage.getItem("perpetor");
		//onsole.log('data from local storage',JSON.parse(data));
		return setperpetor(JSON.parse(data));
	};

	const getNeedAndCare = () => {
		const data = sessionStorage.getItem("need");
		////console.log('data from local storage',JSON.parse(data));
		return setneed(JSON.parse(data));
	};

	useEffect(() => {
		getOrgInfo();
		getPofile();
		getInfo();
		getPerpetor();
		getNeedAndCare();
	}, [org, profile, info, perpetor, need]);

	const handLogin = async (username, password) => {
		//axios
		setison(true);
		await axiosInstance
			.post("login/", { username: username, password: password })
			.then((res) => {
				const data = (axiosInstance.defaults.headers["Authorization"] =
					"Bearer " + res.data.access);
				const usrdata = res.data.authenticatedUser;

				console.log(usrdata);
				if (usrdata.role >= 1 && usrdata.activated === false) {
					setison(false);

					setmsg("This user is disabled, please contact the administrator!");
					return null;
				} else if (usrdata.role >= 1 && usrdata.activated === true) {
					sessionStorage.setItem("token", res.data.access);
					sessionStorage.setItem("refresh", res.data.refresh);

					sessionStorage.setItem("user", usrdata.username);
					sessionStorage.setItem("org", usrdata.organization);
					sessionStorage.setItem("id", usrdata.id);
					if (
						org !== null &&
						profile !== null &&
						info !== null &&
						perpetor !== null &&
						need !== null
					) {
						history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect/main`);
					} else {
						history.push(`/${process.env.REACT_APP_COLLECT_LINK}/collect`);
					}

					//console.log(usrdata);
					return data;
				}
			})

			.catch((err) => {
				if (err.response) {
					const msg = err.response.data.non_field_errors;
					setmsg(msg);
					setison(false);
					//console.log(err.response.data.non_field_errors);
				} else if (err.request) {
					setison(false);
					setmsg(
						"Please, your request could'nt reach the server ,check your internet connexion"
					);

					//console.log("something went wrong");
				} else {
					//console.log("please your internet");
				}
			});
	};

	if (sessionStorage.getItem("token") !== null) {
		return (
			<Redirect to={"/" + process.env.REACT_APP_COLLECT_LINK + "/collect"} />
		);
	}
	return (
		<div
			style={{ display: "flex", backgroundColor: "#F1F2F5", height: "100vh" }}
		>
			<Grid container justifyContent="center">
				<Grid item sm={3} md={4}></Grid>
				<Grid item sm={6} md={4}>
					<Grid container justifyContent="center">
						<Grid item xs={12} sm={12}>
							<Typography
								variant="h4"
								align="center"
								className={classes.typoBig}
							>
								<strong>DATA COLLECTION</strong>
							</Typography>
						</Grid>

						<Grid item xs={12} sm={12}>
							<Card>
								<CardContent>
									<form onSubmit={handleSubmit(onSubmit)}>
										<Grid
											container
											spacing={3}
											className={classes.leftandrightInput}
										>
											<Grid item xs={12} sm={12}>
												<Typography
													align="center"
													variant="h5"
													className={classes.titleLogin}
												>
													<b>S'IDENTIFIER</b>
												</Typography>
												<Typography
													align="center"
													className={classes.subtitleLogin}
												>
													Veuillez entrer vos données de connexion telles que
													fournies par l'administration du Dashboard
												</Typography>
											</Grid>

											<Grid item xs={12} sm={12}>
												<TextField
													required
													fullWidth
													type="text"
													variant="outlined"
													size="medium"
													label="Nom d'utilisateur"
													name="user"
													inputRef={register}
												/>
											</Grid>

											<Grid item xs={12} sm={12}>
												<TextField
													type="password"
													required
													fullWidth
													variant="outlined"
													size="medium"
													label="Mot de passe"
													name="pass"
													inputRef={register}
												/>
											</Grid>
											<Grid item xs={12} sm={12}>
												<Grid container justifyContent="flex-end">
													<Grid item>
														{ison === true ? (
															<CircularProgress color="secondary" />
														) : (
															<BtnLogin content="CONNEXION" type="submit" />
														)}
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</form>
									<Grid container justifyContent="center">
										<Grid item>
											<Typography style={{ color: "red" }}> {msg} </Typography>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
						<Grid container justifyContent="center">
							<Grid item>
								<Typography variant="subtitle2" className={classes.foot}>
									POWERED BY:
								</Typography>
							</Grid>
							{/* <Grid item>
                            <Typography>POWERED BY:</Typography>
                        </Grid> */}
						</Grid>
						<Grid container justifyContent="center">
							<Grid item>
								<img src={oxide} alt="Oxide Logo" width="50px" />
							</Grid>
							{/* <Grid item>
                            <Typography>POWERED BY:</Typography>
                        </Grid> */}
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={3} md={4}></Grid>
			</Grid>
		</div>
	);
};
export default Login;

// this.state = { username: "", password:"", authflag:1 };
// this.handleChange = this.handleChange.bind(this);
// this.handleSubmit = this.handleSubmit.bind(this);
// }
// handleChange(event) {
// this.setState({ username: event.state.username, password: event.state.password });
// }
// handleSubmit(event) {
// event.preventDefault();
// if (this.state.username === 'admin@littech.in' && this.state.password === 'secret') {
// this.props.history.push("/home");
// } else {
// alert('Incorrect Credntials!');
// }
// }

import { Grid, Typography } from "@material-ui/core";
import useStyles from "../../component/website/campain/style";
import React,{useEffect} from "react";
import Section1 from  '../../component/website/access_to/section1'
import { useTranslation } from 'react-i18next';
import {Helmet} from "react-helmet";




export default function Access_to() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
        <Helmet>
              <title>{t('sectionrigth')}</title>
              <meta name='description' content={t('sectionright-text')}/>
            </Helmet>
      <Grid container className={classes.section_1} alignItems="center">
        <Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
        <Grid item xs={10} sm={10} md={10} lg={8}>
          <Section1 />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
      </Grid>
      <Grid className={classes.marginTop_13}>
        <Grid container alignItems="center">
          <Grid item xs={1} sm={1} md={2} lg={2} xl={2}></Grid>
          <Grid item xs={10} sm={10} md={8} lg={8} xl={8} className={classes.marginBottom_5}>
          <Grid container direction='column' spacing={3}>
              <Grid item xs={12} sm={12}>
               <Grid container spacing={3}>
                   <Grid item><Typography className={classes.dark_txt}>
                   {t('sectionright-content-text-p1')}
                   {i18n.language==="fr"?
                   <>
                   <strong>{t('sectionright-content-bold-1')}</strong>
                   {t('sectionright-content-text-p1-1')}
                   </>:<strong>{t('sectionright-content-bold-1')}</strong>}
                   

                   </Typography>
                   </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
               <Grid container>
                   <Grid item><Typography className={classes.dark_txt}>
                   {t('sectionright-content-text-p2')}
                   <strong>{t('sectionright-content-bold-2')}</strong>
                   
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
               <Grid container spacing={3}>
                   <Grid item><Typography className={classes.dark_txt}>
                   {t('sectionright-content-text-3-1')}
                   <strong>{t('sectionright-content-bold-3')}</strong>
                   {t('sectionright-content-text-3-2')}
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
               <Grid container>
                   <Grid item><Typography className={classes.dark_txt}>
                   <strong>{t('sectionright-content-bold-4')}</strong>
                  
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>


              <Grid item xs={12} sm={12}>
               <Grid container>
                   <Grid item><Typography className={classes.dark_txt}>
                   <strong>{t('sectionright-content-bold-5')}</strong>
                   {t('sectionright-content-text-5')}
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
               <Grid container>
                   <Grid item><Typography className={classes.dark_txt}>
                   <strong>{t('sectionright-content-bold-6')}</strong>
                   {t('sectionright-content-text-6')}
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
               <Grid container>
                   <Grid item><Typography className={classes.dark_txt}>
                   <strong>{t('sectionright-content-bold-7')}</strong>
                   {t('sectionright-content-text-7')}
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
               <Grid container>
                   <Grid item><Typography className={classes.dark_txt}>
                   <strong>{t('sectionright-content-bold-8')}</strong>
                   {t('sectionright-content-text-8')}
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
               <Grid container>
                   <Grid item><Typography className={classes.dark_txt}>
                   <strong>{t('sectionright-content-bold-9')}</strong>
                   {t('sectionright-content-text-9')}
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>
               <Grid container>
                   <Grid item><Typography className={classes.dark_txt}>
                   <a target="_blank" rel="noopener noreferrer" style={{color:'#00A1AF'}} href={t('sectionright-content-link')}>{t('sectionright-link-name')}</a>
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>
              
              
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={2} lg={2} xl={2}></Grid>
        </Grid>
      </Grid>
    </div>
  );
}

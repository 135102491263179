import { Grid } from "@material-ui/core";
import useStyles from "../../component/website/Faq/style";
import React,{useEffect} from "react";
import Section1 from "../../component/website/Faq/section1";
import FaqTable from "../../component/website/Faq/FaqTable";
import { useTranslation } from 'react-i18next';
import {Helmet} from "react-helmet";

export default function Faq() {
  const classes = useStyles();
  const { t } = useTranslation();


  useEffect(() => {
    
    window.scrollTo(0, 0)

  }, [])
  return (
    <div>
      <Helmet>
              <title>{t('faq')}</title>
              {/* <meta name='description' content={t('site-description')}/> */}
            </Helmet>
      <Grid container className={classes.section_1} alignItems="center">
        <Grid md={1} lg={1} xl={2}></Grid>
        <Grid md={10} lg={10} xl={8}>
          <Section1 />
        </Grid>
        <Grid md={1} lg={1} xl={2}></Grid>
      </Grid>
      <Grid container className={classes.marginBottom_15} alignItems="center">
        <Grid md={1} lg={1} xl={2}></Grid>
        <Grid md={10} lg={10} xl={8} className={classes.marginTop_13}>
          <FaqTable />
        </Grid>
        <Grid md={1} lg={1} xl={2}></Grid>
      </Grid>
    </div>
  );
}

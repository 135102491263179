import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper, Hidden } from "@material-ui/core";
import useStyles from "../../dashboard/styles";
import LineChart from "./line";
import DoughnutCharts from "../../dashboard/case_statistic/sub/chart/doughtnut";
import BarCharts from "../../dashboard/case_statistic/sub/chart/bar";
import { Tabls } from "./tables/table";
import { Auteurtbl } from "./tables/auteur_tbl";
import { PrejudiceTbl } from "./tables/prejudice_tbl";
import { ProvinceTbl } from "./tables/province_tbl";
import axiosInstance from "../../../service/axiosPub";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { Cell } from "recharts";
import StopIcon from "@material-ui/icons/Stop";
import { useTranslation } from "react-i18next";
import Skeleton from "@material-ui/lab/Skeleton";
import AnimatedNumber from "animated-number-react";

const CaseStatistc = () => {
	const classes = useStyles();
	const totalcases = [{ name: "Total case", value: 100 }];

	const { t } = useTranslation();
	const [cases, setcases] = useState([]);
	const [q10a, setq10a] = useState([]);
	const [q10b, setq10b] = useState([]);
	const [q10c, setq10c] = useState([]);
	const [q10d, setq10d] = useState([]);
	const [q13ba, setq13ba] = useState([]);
	const [q13bb, setq13bb] = useState([]);
	const [q13bc, setq13bc] = useState([]);
	const [q13bd, setq13bd] = useState([]);
	const [q14a, setq14a] = useState([]);
	const [q14b, setq14b] = useState([]);
	const [q14c, setq14c] = useState([]);
	const [spinner, setspinner] = useState(true);
	const [spinnerq10a, setspinnerq10a] = useState(true);
	const [spinnerq10b, setspinnerq10b] = useState(true);
	const [spinnerq10c, setspinnerq10c] = useState(true);
	const [spinnerq10d, setspinnerq10d] = useState(true);
	const [spinner13ba, setspinner13ba] = useState(true);
	const [spinnerq13bb, setspinnerq13bb] = useState(true);
	const [spinnerq13bc, setspinnerq13bc] = useState(true);
	const [spinnerq13bd, setspinnerq13bd] = useState(true);
	const [spinnerq14a, setspinnerq14a] = useState(true);
	const [spinnerq14b, setspinnerq14b] = useState(true);
	const [spinnerq14c, setspinnerq14c] = useState(true);
	const [errmsg, seterrmsg] = useState("");
	const [statecode, setstatecode] = useState(false);
	const [errmsgq10a, seterrmsgq10a] = useState("");
	const [statecodeq10a, setstatecodeq10a] = useState(false);
	const [errmsgq10b, seterrmsgq10b] = useState("");
	const [statecodeq10b, setstatecodeq10b] = useState(false);
	const [errmsgq10c, seterrmsgq10c] = useState("");
	const [statecodeq10c, setstatecodeq10c] = useState(false);
	const [errmsgq10d, seterrmsgq10d] = useState("");
	const [statecodeq10d, setstatecodeq10d] = useState(false);
	const [errmsgq13ba, seterrmsgq13ba] = useState("");
	const [statecodeq13ba, setstatecodeq13ba] = useState(false);
	const [errmsgq13bb, seterrmsgq13bb] = useState("");
	const [statecodeq13bb, setstatecodeq13bb] = useState(false);
	const [statecodeq13bc, setstatecodeq13bc] = useState(false);
	const [errmsgq13bc, seterrmsgq13bc] = useState("");
	const [statecodeq13bd, setstatecodeq13bd] = useState(false);
	const [errmsgq13bd, seterrmsgq13bd] = useState("");
	const [errmsgq14a, seterrmsgq14a] = useState("");
	const [statecodeq14a, setstatecodeq14a] = useState(false);
	const [errmsgq14b, seterrmsgq14b] = useState("");
	const [statecode14b, setstatecode14b] = useState(false);
	const [errmsgq14c, seterrmsgq14c] = useState("");
	const [statecodeq14c, setstatecodeq14c] = useState(false);

	const getCase = async () => {
		setspinner(true);
		await axiosInstance
			.get(`pubcases/`)
			.then((res) => {
				if (res.status === 200) {
					//setspinner(false)
					const data = res.data.approved;
					const dt = data.map((e) => ({
						id: e.id,
						caseid: e.caseid,
						org: e.organization,
						staff: e.question_2,
						doc: e.question_3,
						dofdoc: e.question_4,
						grp: e.question_6_b,
						gen: e.question_7,
						etat: e.question_8_a,
						q8b: e.question_8_b,
						q8c: e.question_8_c,
						q8d: e.question_8_d,
						q8e: e.question_8_e,
						q8f: e.question_8_f,
						q10a: e.question_10_a,
						q10b: e.question_10_b,
						q10c: e.question_10_c,
						q10d: e.question_10_d,
						q12a: e.city,
						// q12a: e.question_12_a,
						q12b: e.question_12_b,
						q13ba: e.question_13_b_a,
						q13bb: e.question_13_b_b,
						q13bc: e.question_13_b_c,
						q13bd: e.question_13_b_d,
						q14aoui: e.question_14_a_oui,
						q14boui: e.question_14_b_oui,
						q14c: e.question_14_c, 
						
					}));
					setcases(dt);
					setstatecode(true);
				}
			})
			.catch((err) => {
				setspinner(true);

				if (err.request) {
					seterrmsg("Please check your internet connexion");
				} else if (err.response) {
					seterrmsg("Please check your internet connexion");
				}
			});
	};
	const getq10a = async () => {
		setspinnerq10a(true);
		await axiosInstance
			.get(`q10a/`)
			.then((res) => {
				if (res.status === 200) {
					setstatecodeq10a(true);
					const data = res.data;
					const dt = data.map((e) => ({
						q10a: e.question_10_a,
					}));
					// console.log(dt);
					setq10a(dt);
				}
			})
			.catch((err) => {
				setspinnerq10a(true);
				if (err.request) {
					seterrmsgq10a("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq10a("Please check your internet connexion");
				}
			});
	};
	const getq10b = async () => {
		setspinnerq10b(true);
		await axiosInstance
			.get(`q10b/`)
			.then((res) => {
				if (res.status === 200) {
					setstatecodeq10b(true);
					const data = res.data;
					const dt = data.map((e) => ({
						q10b: e.question_10_b,
					}));
					// console.log(dt);
					setq10b(dt);
				}
			})
			.catch((err) => {
				setspinnerq10b(true);
				if (err.request) {
					seterrmsgq10b("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq10b("Please check your internet connexion");
				}
			});
	};
	const getq10c = async () => {
		setspinnerq10c(true);
		await axiosInstance
			.get(`q10c/`)
			.then((res) => {
				if (res.status === 200) {
					setstatecodeq10c(true);
					const data = res.data;
					const dt = data.map((e) => ({
						q10c: e.question_10_c,
					}));
					// console.log(dt);
					setq10c(dt);
				}
			})
			.catch((err) => {
				setspinnerq10c(true);
				if (err.request) {
					seterrmsgq10c("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq10c("Please check your internet connexion");
				}
			});
	};
	const getq10d = async () => {
		setspinnerq10d(true);
		await axiosInstance
			.get(`q10d/`)
			.then((res) => {
				if (res.status === 200) {
					setstatecodeq10d(true);
					const data = res.data;
					const dt = data.map((e) => ({
						q10d: e.question_10_d,
					}));
					// console.log(dt);
					setq10d(dt);
				}
			})
			.catch((err) => {
				setspinnerq10d(true);
				if (err.request) {
					seterrmsgq10d("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq10d("Please check your internet connexion");
				}
			});
	};
	const getqq13ba = async () => {
		setspinner13ba(true);
		await axiosInstance
			.get(`q13ba/`)
			.then((res) => {
				if (res.status === 200) {
					setstatecodeq13ba(true);
					const data = res.data;
					const dt = data.map((e) => ({
						q13ba: e.question_13_b_a,
					}));
					// console.log(dt);
					setq13ba(dt);
				}
			})
			.catch((err) => {
				setspinner13ba(true);
				if (err.request) {
					seterrmsgq13ba("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq13ba("Please check your internet connexion");
				}
			});
	};
	const getqq13bb = async () => {
		setspinnerq13bb(true);
		await axiosInstance
			.get(`q13bb/`)
			.then((res) => {
				if (res.status === 200) {
					setstatecodeq13bb(true);
					const data = res.data;
					const dt = data.map((e) => ({
						q13bb: e.question_13_b_b,
					}));
					// console.log(dt);
					setq13bb(dt);
				}
			})
			.catch((err) => {
				setspinnerq13bb(true);
				if (err.request) {
					seterrmsgq13bb("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq13bb("Please check your internet connexion");
				}
			});
	};

	const getqq13bc = async () => {
		setspinnerq13bc(true);
		await axiosInstance
			.get(`q13bc/`)
			.then((res) => {
				if (res.status === 200) {
					setstatecodeq13bc(true);
					const data = res.data;
					const dt = data.map((e) => ({
						q13bc: e.question_13_b_c,
					}));
					// console.log(dt);
					setq13bc(dt);
				}
			})
			.catch((err) => {
				setspinnerq13bc(true);
				if (err.request) {
					seterrmsgq13bc("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq13bc("Please check your internet connexion");
				}
			});
	};
	const getqq13bd = async () => {
		setspinnerq13bd(true);
		await axiosInstance
			.get(`q13bd/`)
			.then((res) => {
				if (res.status === 200) {
					setstatecodeq13bd(true);
					const data = res.data;
					const dt = data.map((e) => ({
						q13bd: e.question_13_b_d,
					}));
					// console.log(dt);
					setq13bd(dt);
				}
			})
			.catch((err) => {
				setspinnerq13bd(true);
				if (err.request) {
					seterrmsgq13bd("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq13bd("Please check your internet connexion");
				}
			});
	};

	const getq14a = async () => {
		setspinnerq14a(true);
		await axiosInstance
			.get(`q14aoui/`)
			.then((res) => {
				if (res.status === 200) {
					setstatecodeq14a(true);
					const data = res.data;
					const dt = data.map((e) => ({
						q14a: e.question_14_a_oui,
					}));
					// console.log(dt);
					setq14a(dt);
				}
			})
			.catch((err) => {
				setspinnerq14a(true);
				if (err.request) {
					seterrmsgq14a("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq14a("Please check your internet connexion");
				}
			});
	};
	const getq14b = async () => {
		setspinnerq14b(true);
		await axiosInstance
			.get(`q14boui/`)
			.then((res) => {
				if (res.status === 200) {
					setstatecode14b(true);
					const data = res.data;
					const dt = data.map((e) => ({
						q14b: e.question_14_b_oui,
					}));
					// console.log(dt);
					setq14b(dt);
				}
			})
			.catch((err) => {
				setspinnerq14b(true);
				if (err.request) {
					seterrmsgq14b("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq14b("Please check your internet connexion");
				}
			});
	};
	const getq14c = async () => {
		setspinnerq14c(true);
		await axiosInstance
			.get(`q14c/`)
			.then((res) => {
				if (res.status === 200) {
					setstatecodeq14c(true);

					const data = res.data;
					const dt = data.map((e) => ({
						q14c: e.question_14_c,
					}));
					setq14c(dt);
				}
			})
			.catch((err) => {
				setspinnerq14c(true);
				if (err.request) {
					seterrmsgq14c("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq14c("Please check your internet connexion");
				}
			});
	};

	useEffect(
		() => {
			getCase();
			getq10a();
			getq10b();
			getq10c();
			getq10d();
			getqq13ba();
			getqq13bb();
			getqq13bc();
			getqq13bd();
			getq14a();
			getq14b();
			getq14c();
		},
		[
			// cases.id,
			// q10a,q10b,q10c,q10d,q13ba,q13bb,q13bc,q13bd,q14a,q14b,q14c
		]
	); //axiosInstance
	const caseCount = cases.length;
	// console.log(cases);
	// console.log(statecode);
	// console.log(statecodeq10a);
	// console.log(statecodeq10b);
	// console.log(statecodeq10c);
	// console.log(statecodeq10d);

	//getting gender
	const autre = cases.filter((e) => e.gen === "Autre");
	const feminin = cases.filter((e) => e.gen === "Féminin");
	const masculin = cases.filter((e) => e.gen === "Masculin");
	// console.log(grpeDage);
	// console.log(convertgrp.values);

	// getting gpe d age

	const ans5 = cases.filter((item) => item.grp.indexOf("0-5 ans") > -1);
	const ans17 = cases.filter((item) => item.grp.indexOf("6-17 ans") > -1);
	const ans29 = cases.filter((item) => item.grp.indexOf("18-29 ans") > -1);
	const ans49 = cases.filter((item) => item.grp.indexOf("30-49 ans") > -1);
	const ans64 = cases.filter((item) => item.grp.indexOf("50-64 ans") > -1);
	const ans65 = cases.filter((item) => item.grp.indexOf("65 ans et plus") > -1);
	const grpdAge = [
		{ value: ans5.length },
		{ value: ans17.length },
		{ value: ans29.length },
		{ value: ans49.length },
		{ value: ans64.length },
		{ value: ans65.length },
	];
	const COLORS = [
		"#FFDA83",
		"#A3A1FB",
		"#D12028",
		"#F48C47",
		"#1D384C",
		"#60C1CB",
	];

	const genderColor = ["#00A1AF", "#D12028", "#1D384C"];
	const genData = [
		{
			total: `${feminin.length}`,
		},
		{
			total: `${masculin.length}`,
		},
		{
			total: `${autre.length}`,
		},
	];
	//console.log(genData);
	//getting etat civil

	const celib = cases.filter((e) => e.etat === "Célibataire");
	const marie = cases.filter((e) => e.etat === "Marié(e)");
	//console.log(marie.length);
	const separe = cases.filter((e) => e.etat === "Séparé(e)");
	const divorce = cases.filter((e) => e.etat === "Divorcé(e)");
	const veuf = cases.filter((e) => e.etat === "Veuve / Veuf");
	const union = cases.filter(
		(e) => e.etat === "Union de fait (vivre ensemble sans être mariés)"
	);

	const etatcivile = [
		{
			total: `${celib.length}`,
		},
		{
			total: `${marie.length}`,
		},
		{
			total: `${separe.length}`,
		},
		{
			total: `${divorce.length}`,
		},
		{
			total: `${union.length}`,
		},
		{
			total: `${veuf.length}`,
		},
	];

	const etatColor = [
		"#00A1AF",
		"#FFDA83",
		"#A3A1FB",
		"#F48C47",
		"#55D8FE",
		"#1D384C",
	];

	//console.log(etatcivile );

	const q8b = cases.filter((e) => e.q8b === "Oui");
	const q8c = cases.filter((e) => e.q8c === "Oui");
	const q8d = cases.filter((e) => e.q8d === "Oui");
	const q8dnon = cases.filter((e) => e.q8d === "Non");
	const q8e = cases.filter((e) => e.q8e === "Oui");
	const q8f = cases.filter((e) => e.q8f === "Oui");
	//q10 chart
	const viol = cases.filter((item) => item.q10a.indexOf("Viol") > -1);
	const violconj = cases.filter(
		(item) =>
			item.q10a.indexOf("Viol conjugal entre couples mariés et non mariés") > -1
	);
	const agr = cases.filter(
		(item) => item.q10a.indexOf("Agression sexuelle sur mineur") > -1
	);
	const coer = cases.filter(
		(item) => item.q10a.indexOf("Coercition sexuelle") > -1
	);
	const har = cases.filter(
		(item) => item.q10a.indexOf("Harcèlement sexuel") > -1
	);
	const attou = cases.filter(
		(item) => item.q10a.indexOf("Attouchements inappropriés") > -1
	);
	const etr = cases.filter(
		(item) =>
			item.q10a.indexOf(
				"Forcer la victime à avoir des relations sexuelles sans préservatif"
			) > -1
	);
	const empl = cases.filter(
		(item) =>
			item.q10a.indexOf(
				"Empêcher la victime de faire ses propres choix quant à la décision d'avoir ou non un bébé"
			) > -1
	);
	const autr = cases.filter((item) => item.q10a.indexOf("Autres") > -1);
	const meu = cases.filter(
		(item) => item.q10b.indexOf("Meurtre / féminicide") > -1
	);
	const cpoing = cases.filter(
		(item) => item.q10b.indexOf("Coups de poing et/ou coups de pieds") > -1
	);
	const cpdobj = cases.filter(
		(item) => item.q10b.indexOf("Coups portés avec des objets") > -1
	);
	const att = cases.filter(
		(item) => item.q10b.indexOf("Attaque à l’acide") > -1
	);
	const attAcouto = cases.filter(
		(item) => item.q10b.indexOf("Attaque au couteau ou à la machette") > -1
	);
	const attqAleau = cases.filter(
		(item) =>
			item.q10b.indexOf(
				"Attaque à l’eau chaude, à l’huile chaude ou autre objet chaud"
			) > -1
	);
	const priv = cases.filter(
		(item) =>
			item.q10b.indexOf("Privation de la liberté physique de la victime") > -1
	);
	const privDeNoutr = cases.filter(
		(item) => item.q10b.indexOf("Privation de nourriture de la victime") > -1
	);
	const neglDEnfa = cases.filter(
		(item) =>
			item.q10b.indexOf(
				"Négligence physique d’enfant (si la victime est un enfant)"
			) > -1
	);
	const neglDpers = cases.filter(
		(item) =>
			item.q10b.indexOf(
				"Négligence physique de personnes âgées (si la victime est une personne âgée)"
			) > -1
	);
	const destr = cases.filter(
		(item) =>
			item.q10b.indexOf("Destruction d’objets dans la maison de la victime") >
			-1
	);
	const rite = cases.filter(
		(item) =>
			item.q10b.indexOf(
				"Rites de veuvage affectant la santé physique et mentale de la victime"
			) > -1
	);
	const autr2 = cases.filter((item) => item.q10b.indexOf("Autres") > -1);

	const men = cases.filter((item) => item.q10c.indexOf("Menaces") > -1);
	const inj = cases.filter((item) => item.q10c.indexOf("Injures") > -1);
	const neglEm = cases.filter(
		(item) => item.q10c.indexOf("Négligence émotionnelle") > -1
	);
	const humil = cases.filter(
		(item) =>
			item.q10c.indexOf("Humiliation de la victime face aux autres") > -1
	);
	const attVisant = cases.filter(
		(item) =>
			item.q10c.indexOf(
				"Attaques visant à nuire à la réputation de la victime"
			) > -1
	);
	const isol = cases.filter(
		(item) =>
			item.q10c.indexOf(
				"Isolement de la victime de sa famille d’origine ou ses amis"
			) > -1
	);
	const contr = cases.filter(
		(item) => item.q10c.indexOf("Contrôle des mouvements de la victime") > -1
	);
	const cntrDecom = cases.filter(
		(item) =>
			item.q10c.indexOf("Contrôle de la communication de la victime") > -1
	);
	const refus = cases.filter(
		(item) =>
			item.q10c.indexOf(
				"Refus de divorce malgré le souhait de la victime de se divorcer"
			) > -1
	);
	const abus = cases.filter(
		(item) =>
			item.q10c.indexOf("Abus spirituel / abus religieux de la victime") > -1
	);
	const autr3 = cases.filter((item) => item.q10c.indexOf("Autres") > -1);

	const auteur = cases.filter(
		(item) => item.q10d.indexOf("L’auteur abandonne ou chasse la victime") > -1
	);
	const conf = cases.filter(
		(item) => item.q10d.indexOf("Confiscation des revenus de la victime") > -1
	);
	const inter = cases.filter(
		(item) =>
			item.q10d.indexOf(
				"Interdiction de participation à la gestion des revenus du ménage"
			) > -1
	);
	const restdeLAccessDeducatio = cases.filter(
		(item) => item.q10d.indexOf("Restriction de l’accès à l'éducation") > -1
	);
	const restdeLAccessDemploie = cases.filter(
		(item) => item.q10d.indexOf("Restriction de l’accès à l'emploi") > -1
	);
	const restdeLAccessDeconomique = cases.filter(
		(item) =>
			item.q10d.indexOf("Restriction de l’accès aux ressources économiques") >
			-1
	);
	const privDepriseEncharge = cases.filter(
		(item) =>
			item.q10d.indexOf(
				"Privation d’une prise en charge médicale, de médicaments ou d'appareils d’assistance"
			) > -1
	);
	const levirat = cases.filter(
		(item) => item.q10d.indexOf("Lévirat après la mort du conjoint") > -1
	);
	const pertepropriete = cases.filter(
		(item) =>
			item.q10d.indexOf(
				"Perte des propriétés et des biens / non accès à l’héritage après la mort du conjoint ou d’un parent"
			) > -1
	);
	const perteDenfa = cases.filter(
		(item) =>
			item.q10d.indexOf("Perte des enfants après la mort du conjoint") > -1
	);

	const autr4 = cases.filter((item) => item.q10d.indexOf("Autres") > -1);
	//q12a

	const Bandalungwa = cases.filter(
		(item) => item.q12a.indexOf("Bandalungwa") > -1
	);
	const barumbu = cases.filter((item) => item.q12a.indexOf("Barumbu") > -1);
	const bumbu = cases.filter((item) => item.q12a.indexOf("Bumbu") > -1);
	const gombe = cases.filter((item) => item.q12a.indexOf("Gombe") > -1);
	const kalamu = cases.filter((item) => item.q12a.indexOf("Kalamu") > -1);
	const kasa_Vubu = cases.filter((item) => item.q12a.indexOf("Kasa-Vubu") > -1);
	const kimbanseke = cases.filter(
		(item) => item.q12a.indexOf("Kimbanseke") > -1
	);
	const kinshasa = cases.filter((item) => item.q12a.indexOf("Kinshasa") > -1);
	const kintambo = cases.filter((item) => item.q12a.indexOf("Kintambo") > -1);
	const kisenso = cases.filter((item) => item.q12a.indexOf("Kisenso") > -1);
	const lemba = cases.filter((item) => item.q12a.indexOf("Lemba") > -1);
	const limete = cases.filter((item) => item.q12a.indexOf("Limete") > -1);
	const lingwala = cases.filter((item) => item.q12a.indexOf("Lingwala") > -1);
	const makala = cases.filter((item) => item.q12a.indexOf("Makala") > -1);
	const maluku = cases.filter((item) => item.q12a.indexOf("Maluku") > -1);
	const masina = cases.filter((item) => item.q12a.indexOf("Masina") > -1);
	const matete = cases.filter((item) => item.q12a.indexOf("Matete") > -1);
	const mont_Ngafula = cases.filter(
		(item) => item.q12a.indexOf("Mont Ngafula") > -1
	);
	const ndjili = cases.filter((item) => item.q12a.indexOf("Ndjili") > -1);
	const ngaba = cases.filter((item) => item.q12a.indexOf("Ngaba") > -1);
	const ngaliema = cases.filter((item) => item.q12a.indexOf("Ngaliema") > -1);
	const ngiri_ngiri = cases.filter(
		(item) => item.q12a.indexOf("Ngiri-Ngiri") > -1
	);
	const nsele = cases.filter((item) => item.q12a.indexOf("Nsele") > -1);
	const selembao = cases.filter((item) => item.q12a.indexOf("Selembao") > -1);
	const bunia = cases.filter((item) => item.q12a.indexOf("Bunia") > -1);
	const aru = cases.filter((item) => item.q12a.indexOf("Aru") > -1);
	const djugu = cases.filter((item) => item.q12a.indexOf("Djugu") > -1);
	const irumu = cases.filter((item) => item.q12a.indexOf("Irumu") > -1);
	const mahagi = cases.filter((item) => item.q12a.indexOf("Mahagi") > -1);
	const mambasa = cases.filter((item) => item.q12a.indexOf("Mambasa") > -1);
	const kananga = cases.filter((item) => item.q12a.indexOf("Kananga") > -1);
	const demba = cases.filter((item) => item.q12a.indexOf("Demba") > -1);
	const dibaya = cases.filter((item) => item.q12a.indexOf("Dibaya") > -1);
	const dimbelenge = cases.filter(
		(item) => item.q12a.indexOf("Dimbelenge") > -1
	);
	const kazumba = cases.filter((item) => item.q12a.indexOf("Kazumba") > -1);
	const luiza = cases.filter((item) => item.q12a.indexOf("Luiza") > -1);

	const mbuji_mayi = cases.filter(
		(item) => item.q12a.indexOf("Mbuji-mayi") > -1
	);
	const kabeya = cases.filter(
		(item) => item.q12a.indexOf("Kabeya-kamwanga") > -1
	);
	const katanda = cases.filter((item) => item.q12a.indexOf("Katanda") > -1);
	const lupatapata = cases.filter(
		(item) => item.q12a.indexOf("Lupatapata") > -1
	);
	const miabi = cases.filter((item) => item.q12a.indexOf("Miabi") > -1);
	const tshilenge = cases.filter((item) => item.q12a.indexOf("Tshilenge") > -1);

	const kindu = cases.filter((item) => item.q12a.indexOf("Kindu") > -1);
	const kabambare = cases.filter((item) => item.q12a.indexOf("Kabambare") > -1);
	const kailo = cases.filter((item) => item.q12a.indexOf("Kailo") > -1);
	const kasongo = cases.filter((item) => item.q12a.indexOf("Kasongo") > -1);
	const kibombo = cases.filter((item) => item.q12a.indexOf("Kibombo") > -1);
	const lubutu = cases.filter((item) => item.q12a.indexOf("Lubutu") > -1);
	const pangi = cases.filter((item) => item.q12a.indexOf("Pangi") > -1);
	const punia = cases.filter((item) => item.q12a.indexOf("Punia") > -1);

	const benivile = cases.filter(
		(item) => item.q12a.indexOf("Beni (Ville)") > -1
	);
	const butembo = cases.filter((item) => item.q12a.indexOf("Butembo") > -1);
	const goma = cases.filter((item) => item.q12a.indexOf("Goma") > -1);
	const beni = cases.filter(
		(item) => item.q12a.indexOf("Beni (Territoire)") > -1
	);
	const lubero = cases.filter((item) => item.q12a.indexOf("Lubero") > -1);
	const masisi = cases.filter((item) => item.q12a.indexOf("Masisi") > -1);
	const nyiragongo = cases.filter(
		(item) => item.q12a.indexOf("Nyiragongo") > -1
	);
	const rutshuru = cases.filter((item) => item.q12a.indexOf("Rutshuru") > -1);
	const walikale = cases.filter((item) => item.q12a.indexOf("Walikale") > -1);
	const bukavu = cases.filter((item) => item.q12a.indexOf("Bukavu") > -1);
	const fizi = cases.filter((item) => item.q12a.indexOf("Fizi") > -1);
	const idjwi = cases.filter((item) => item.q12a.indexOf("Idjwi") > -1);
	const kabare = cases.filter((item) => item.q12a.indexOf("Kabare") > -1);
	const kalehe = cases.filter((item) => item.q12a.indexOf("Kalehe") > -1);
	const mwenga = cases.filter((item) => item.q12a.indexOf("Mwenga") > -1);
	const shabunda = cases.filter((item) => item.q12a.indexOf("Shabunda") > -1);
	const uvira = cases.filter((item) => item.q12a.indexOf("Uvira") > -1);
	const walungu = cases.filter((item) => item.q12a.indexOf("Walungu") > -1);
	const kisangani = cases.filter((item) => item.q12a.indexOf("Kisangani") > -1);
	const bafwasende = cases.filter(
		(item) => item.q12a.indexOf("Bafwasende") > -1
	);
	const banalia = cases.filter((item) => item.q12a.indexOf("Banalia") > -1);
	const basoko = cases.filter((item) => item.q12a.indexOf("Basoko") > -1);
	const isangi = cases.filter((item) => item.q12a.indexOf("Isangi") > -1);
	const opala = cases.filter((item) => item.q12a.indexOf("Opala") > -1);
	const ubundu = cases.filter((item) => item.q12a.indexOf("Ubundu") > -1);
	const yahuma = cases.filter((item) => item.q12a.indexOf("Yahuma") > -1);

	//q12b
	const urbain = cases.filter(
		(item) => item.q12b.indexOf("Milieu urbain") > -1
	);
	const semi = cases.filter(
		(item) => item.q12b.indexOf("Milieu semi-urbain") > -1
	);
	const rural = cases.filter((item) => item.q12b.indexOf("Milieu rural") > -1);

	const milieu = [
		{ value: urbain.length },
		{ value: semi.length },
		{ value: rural.length },
	];
	//console.log(q10a);
	//const total = cases.filter((item,cur) => item.q10a[cur].length!=="")

	const milieuColor = ["#FFDA83", "#A3A1FB", "#FF8373"];

	//q13
	const dec = cases.filter((item) => item.q13ba.indexOf("Décès") > -1);
	const mal = cases.filter(
		(item) => item.q13ba.indexOf("Maladies (par exemple hypertension)") > -1
	);
	const bles = cases.filter((item) => item.q13ba.indexOf("Blessures") > -1);
	const dou = cases.filter((item) => item.q13ba.indexOf("Douleurs") > -1);
	const troublgyn = cases.filter(
		(item) => item.q13ba.indexOf("Troubles gynécologiques et reproductifs") > -1
	);
	const handicap = cases.filter(
		(item) => item.q13ba.indexOf("Invalidité / Handicap") > -1
	);
	const gros = cases.filter(
		(item) => item.q13ba.indexOf("Grossesse non voulue") > -1
	);
	const ptDenfa = cases.filter(
		(item) => item.q13ba.indexOf("Perte de l'enfant à naître") > -1
	);
	const abusDalc = cases.filter(
		(item) => item.q13ba.indexOf("Abus d'alcool") > -1
	);
	const abusDedrog = cases.filter(
		(item) => item.q13ba.indexOf("Abus de drogues") > -1
	);
	const abusDemed = cases.filter(
		(item) => item.q13ba.indexOf("Abus de médicaments") > -1
	);
	const autr5 = cases.filter((item) => item.q13ba.indexOf("Autres") > -1);

	const risDeSui = cases.filter(
		(item) => item.q13bb.indexOf("Risque de suicide") > -1
	);
	const peur = cases.filter(
		(item) => item.q13bb.indexOf("Peur et anxiété") > -1
	);
	const depr = cases.filter((item) => item.q13bb.indexOf("Dépression") > -1);
	const detr = cases.filter(
		(item) => item.q13bb.indexOf("Détresse émotionnelle") > -1
	);
	const sent = cases.filter(
		(item) => item.q13bb.indexOf("Sentiment de honte") > -1
	);
	const sentDecol = cases.filter(
		(item) => item.q13bb.indexOf("Sentiment de colère") > -1
	);
	const troublDesom = cases.filter(
		(item) => item.q13bb.indexOf("Troubles de sommeil") > -1
	);
	const troublAlim = cases.filter(
		(item) => item.q13bb.indexOf("Troubles alimentaires") > -1
	);
	const peterDespoir = cases.filter(
		(item) => item.q13bb.indexOf("Perte d’espoir") > -1
	);
	const manqueDeconf = cases.filter(
		(item) => item.q13bb.indexOf("Manque de confiance en soi") > -1
	);
	const autr6 = cases.filter((item) => item.q13bb.indexOf("Autres") > -1);

	const perterDeSu = cases.filter(
		(item) =>
			item.q13bc.indexOf("Perte ou manque des moyens de subsistance") > -1
	);
	const perterDeRev = cases.filter(
		(item) => item.q13bc.indexOf("Perte ou manque de revenus") > -1
	);
	const sexDesurvie = cases.filter(
		(item) => item.q13bc.indexOf("Sexe de survie") > -1
	);
	const peterDemaison = cases.filter(
		(item) => item.q13bc.indexOf("Perte de maison") > -1
	);
	const demenagement = cases.filter(
		(item) => item.q13bc.indexOf("Déménagements fréquents") > -1
	);
	const situation = cases.filter(
		(item) => item.q13bc.indexOf("Situation de sans-abri") > -1
	);
	const pasoupeu = cases.filter(
		(item) => item.q13bc.indexOf("Pas ou peu d'éducation ou de formation") > -1
	);
	const autr7 = cases.filter((item) => item.q13bc.indexOf("Autres") > -1);

	const rupture = cases.filter(
		(item) =>
			item.q13bd.indexOf(
				"Rupture de la famille (famille créée par la victime et l’auteur)"
			) > -1
	);
	const conflits = cases.filter(
		(item) => item.q13bd.indexOf("Conflits domestiques réguliers") > -1
	);
	const abndon = cases.filter(
		(item) =>
			item.q13bd.indexOf(
				"Abandon de la victime par sa famille d’origine (parents)"
			) > -1
	);
	const stigm = cases.filter(
		(item) =>
			item.q13bd.indexOf("Stigmatisation de la victime par la communauté") > -1
	);
	const perterDeGarde = cases.filter(
		(item) =>
			item.q13bd.indexOf(
				"Perte de la garde ou de contact avec propres enfants"
			) > -1
	);
	const isolDelaVictime = cases.filter(
		(item) => item.q13bd.indexOf("Isolement social de la victime") > -1
	);
	const autr8 = cases.filter((item) => item.q13bd.indexOf("Autres") > -1);

	const mere = cases.filter((item) => item.q14aoui.indexOf("Mère") > -1);
	const pere = cases.filter((item) => item.q14aoui.indexOf("Père") > -1);
	const tuthom = cases.filter(
		(item) => item.q14aoui.indexOf("Tuteur / gardien (homme)") > -1
	);
	const tutfem = cases.filter(
		(item) => item.q14aoui.indexOf("Tuteur / gardienne (femme)") > -1
	);
	const proFrer = cases.filter(
		(item) => item.q14aoui.indexOf("Propres frères") > -1
	);
	const propSoeur = cases.filter(
		(item) => item.q14aoui.indexOf("Propres sœurs") > -1
	);
	const propDenfa = cases.filter(
		(item) => item.q14aoui.indexOf("Propres enfants adultes") > -1
	);
	const gdmere = cases.filter(
		(item) => item.q14aoui.indexOf("Grand-mère") > -1
	);
	const gdpere = cases.filter(
		(item) => item.q14aoui.indexOf("Grand-père") > -1
	);
	const belmere = cases.filter(
		(item) => item.q14aoui.indexOf("Belle-mère") > -1
	);
	const beaupere = cases.filter(
		(item) => item.q14aoui.indexOf("Beau-père") > -1
	);
	const beauFere = cases.filter(
		(item) => item.q14aoui.indexOf("Beaux-frères") > -1
	);
	const belSoeur = cases.filter(
		(item) => item.q14aoui.indexOf("Belles-sœurs") > -1
	);
	const oncle = cases.filter((item) => item.q14aoui.indexOf("Oncles") > -1);
	const tante = cases.filter((item) => item.q14aoui.indexOf("Tantes") > -1);
	const autrmembre = cases.filter(
		(item) =>
			item.q14aoui.indexOf("Autre(s) membre(s) de la famille de la victime") >
			-1
	);

	const mari = cases.filter((item) => item.q14boui.indexOf("Mari") > -1);
	const exmari = cases.filter((item) => item.q14boui.indexOf("Ex-mari") > -1);
	const epouse = cases.filter((item) => item.q14boui.indexOf("Epouse") > -1);
	const exepouse = cases.filter(
		(item) => item.q14boui.indexOf("Ex-épouse") > -1
	);
	const partHomme = cases.filter(
		(item) =>
			item.q14boui.indexOf("Partenaire intime actuel qui est homme") > -1
	);
	const partFemme = cases.filter(
		(item) =>
			item.q14boui.indexOf("Partenaire intime actuel qui est femme") > -1
	);
	const expartHomme = cases.filter(
		(item) => item.q14boui.indexOf("Ex-partenaire intime qui est homme") > -1
	);
	const expartFemme = cases.filter(
		(item) => item.q14boui.indexOf("Ex-partenaire intime qui est femme") > -1
	);

	const patronHomDom = cases.filter(
		(item) => item.q14c.indexOf("Patron (homme) de personnel domestique") > -1
	);
	const patronFemDom = cases.filter(
		(item) => item.q14c.indexOf("Patronne (femme) de personnel domestique") > -1
	);
	const personelHomDomestique = cases.filter(
		(item) => item.q14c.indexOf("Personnel domestique (homme)") > -1
	);
	const personelFemDomestique = cases.filter(
		(item) => item.q14c.indexOf("Personnel domestique (femme)") > -1
	);
	const bailDemezon = cases.filter(
		(item) =>
			item.q14c.indexOf("Bailleur / propriétaire de la maison (homme)") > -1
	);
	const bailleDemezon = cases.filter(
		(item) =>
			item.q14c.indexOf("Bailleresse / propriétaire de la maison (femme)") > -1
	);
	const autremember2 = cases.filter(
		(item) =>
			item.q14c.indexOf(
				"Autre(s) membre(s) du cercle restreint de la victime"
			) > -1
	);

	const skeletton = (state, value, spin, width, height) => {
		if (state === false) {
			if (spin === true) {
				return <Skeleton variant="circle" width={width} height={height} />;
			} else {
				setTimeout(() => {
					getCase();
					getq14c();
					getq14b();
					getq14a();
					getqq13bd();
					getqq13bc();
					getqq13bb();
					getqq13ba();
					getq10d();
					getq10c();
					getq10b();
					getq10a();
				}, 90000);
				return <Skeleton variant="circle" width={width} height={height} />;
			}
		} else if (state === true) {
			return (
				<AnimatedNumber
					value={value.length}
					formatValue={(v) => v.toFixed(0)}
					duration={4000}
					frameStyle={(perc) => ({
						opacity: perc / 100,
					})}
				/>
			);
		}
	};

	const displayData = () => {
		return (
			<div>
				<Grid container direction="row" spacing={3}>
					<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
						<Paper>
							<Grid container className={classes.leftAndRightAndTopAndBottom}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={classes.typochart}>
										{t("case-statistic-box1")}
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									sm={12}
									style={{ height: "20vh", width: "80vw" }}
								>
									<Hidden only="xl">
										{statecode === false && spinner === true ? (
											<center>
												<Skeleton variant="circle" width={150} height={150} />
											</center>
										) : (
											<DoughnutCharts
												data={totalcases}
												value={caseCount}
												width={150}
												height={165}
												fill="#00A1AF"
												alignItems="center"
												justifyContent="center"
												innerRadius="60%"
												outerRadius="90%"
											/>
										)}
									</Hidden>

									<Hidden only={["xs", "sm", "md", "lg"]}>
										{statecode === false && spinner === true ? (
											<center>
												{" "}
												<Skeleton variant="circle" width={120} height={120} />
											</center>
										) : (
											<DoughnutCharts
												data={totalcases}
												value={caseCount}
												width={250}
												height={280}
												fill="#00A1AF"
												alignItems="center"
												justifyContent="center"
												innerRadius="60%"
												outerRadius="90%"
											/>
										)}
									</Hidden>
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
						<Grid container>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Paper>
									<LineChart />
								</Paper>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					container
					direction="row"
					spacing={3}
					className={classes.margin_Top_30}
				>
					<Grid item xs={12} sm={8}>
						<Paper>
							<Grid container className={classes.leftAndRightAndTopAndBottom}>
								<Grid item xs={12} sm={12}>
									<Typography className={classes.typochart}>
										{t("case-statistic-box3")}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12}>
									<Grid container direction="row" spacing={4} style={{paddingBottom: 10}}>
										<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
											<Hidden xlUp>
												{statecode === false && spinner === true ? (
													<center>
														{" "}
														<Skeleton
															variant="circle"
															width={180}
															height={180}
														/>
													</center>
												) : (
													<center>
														<DoughnutCharts
															data={grpdAge}
															width={180}
															height={180}
															innerRadius="50%"
															outerRadius="100%"
															//typography="Groupe d’âge de la victime"
															filledColor={grpdAge.map((entry, index) => (
																<Cell
																	key={`cell-${index}`}
																	fill={COLORS[index % COLORS.length]}
																/>
															))}
														/>
													</center>
												)}
											</Hidden>
											<Hidden only={["xs", "sm", "md", "lg"]}>
												{statecode === false && spinner === true ? (
													<center>
														{" "}
														<Skeleton
															variant="circle"
															width={180}
															height={180}
														/>
													</center>
												) : (
													<center>
														<DoughnutCharts
															data={grpdAge}
															width={180}
															height={180}
															innerRadius="50%"
															outerRadius="100%"
															//typography="Groupe d’âge de la victime"
															filledColor={grpdAge.map((entry, index) => (
																<Cell
																	key={`cell-${index}`}
																	fill={COLORS[index % COLORS.length]}
																/>
															))}
														/>
													</center>
												)}
											</Hidden>
										</Grid>
										<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
											<Grid container direction="row" spacing={3}>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<Grid container direction="column" spacing={1}>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<Grid container direction="row">
																<Grid item xs={1} sm={1}>
																	<RadioButtonUncheckedIcon
																		style={{
																			fontSize: 12,
																			color: "#FFDA83",
																			marginRight: 2,
																		}}
																	/>
																</Grid>
																<Grid item xs={10} sm={10}>
																	<Typography className={classes.typosubchart}>
																		{/* 0-5 {t("case-statistic-year")} */}
																		{t("filter-q6-b-5")}
																	</Typography>{" "}
																</Grid>
																<Grid item xs={1} sm={1}>
																	<Typography className={classes.typosubchart}>
																		{skeletton(
																			statecode,
																			ans5,
																			spinner,
																			10,
																			10
																		)}

																		{/* {ans5} */}
																	</Typography>
																</Grid>
															</Grid>
														</Grid>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<Grid container direction="row">
																<Grid item xs={1} sm={1}>
																	<RadioButtonUncheckedIcon
																		style={{
																			fontSize: 12,
																			color: "#A3A1FB",
																			marginRight: 2,
																		}}
																	/>
																</Grid>
																<Grid item xs={10} sm={10}>
																	<Typography className={classes.typosubchart}>
																		{/* 6-17 {t("case-statistic-year")} */}
																		{t("filter-q6-b-17")}
																	</Typography>{" "}
																</Grid>
																<Grid item xs={1} sm={1}>
																	<Typography className={classes.typosubchart}>
																		{skeletton(
																			statecode,
																			ans17,
																			spinner,
																			10,
																			10
																		)}

																		{/* {ans17} */}
																	</Typography>
																</Grid>
															</Grid>
														</Grid>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<Grid container direction="row">
																<Grid item xs={1} sm={1}>
																	<RadioButtonUncheckedIcon
																		style={{
																			fontSize: 12,
																			color: "#D12028",
																			marginRight: 2,
																		}}
																	/>
																</Grid>
																<Grid item xs={10} sm={10}>
																	<Typography className={classes.typosubchart}>
																		{/* 18-29 {t("case-statistic-year")} */}
																		{t("filter-q6-b-29")}
																	</Typography>{" "}
																</Grid>
																<Grid item xs={1} sm={1}>
																	<Typography className={classes.typosubchart}>
																		{skeletton(
																			statecode,
																			ans29,
																			spinner,
																			10,
																			10
																		)}
																	</Typography>
																</Grid>
															</Grid>
														</Grid>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<Grid container direction="row">
																<Grid item xs={1} sm={1}>
																	<RadioButtonUncheckedIcon
																		style={{
																			fontSize: 12,
																			color: "#F48C47",
																			marginRight: 2,
																		}}
																	/>
																</Grid>
																<Grid item xs={10} sm={10}>
																	<Typography className={classes.typosubchart}>
																		{/* 30-49 {t("case-statistic-year")} */}
																		{t("filter-q6-b-49")}
																	</Typography>{" "}
																</Grid>
																<Grid item xs={1} sm={1}>
																	<Typography className={classes.typosubchart}>
																		{skeletton(
																			statecode,
																			ans49,
																			spinner,
																			10,
																			10
																		)}

																		{/* {ans49} */}
																	</Typography>
																</Grid>
															</Grid>
														</Grid>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<Grid container direction="row">
																<Grid item xs={1} sm={1}>
																	<RadioButtonUncheckedIcon
																		style={{
																			fontSize: 12,
																			color: "#1D384C",
																			marginRight: 2,
																		}}
																	/>
																</Grid>
																<Grid item xs={10} sm={10}>
																	<Typography className={classes.typosubchart}>
																		{/* 50-64 {t("case-statistic-year")} */}
																		{t("filter-q6-b-64")}
																	</Typography>{" "}
																</Grid>
																<Grid item xs={1} sm={1}>
																	<Typography className={classes.typosubchart}>
																		{skeletton(
																			statecode,
																			ans64,
																			spinner,
																			10,
																			10
																		)}
																		{/* {ans64} */}
																	</Typography>
																</Grid>
															</Grid>
														</Grid>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<Grid container direction="row">
																<Grid item xs={1} sm={1}>
																	<RadioButtonUncheckedIcon
																		style={{ fontSize: 12, color: "#60C1CB" }}
																	/>
																</Grid>
																<Grid item xs={10} sm={10}>
																	<Typography className={classes.typosubchart}>
																		{/* 65 {t("case-statistic-year")}{" "} */}
																		{/* {t("case-statistic-more")} */}
																		{t("filter-q6-b-65")}
																	</Typography>{" "}
																</Grid>
																<Grid item xs={1} sm={1}>
																	<Typography className={classes.typosubchart}>
																		{skeletton(
																			statecode,
																			ans65,
																			spinner,
																			10,
																			10
																		)}
																		{/* {ans65} */}
																	</Typography>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Paper>
							<Grid
								container
								direction="row"
								className={classes.leftAndRightAndTopAndBottom}
							>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<BarCharts
										data={genData}
										filledcolor={genData.map((entry, index) => (
											<Cell
												key={`cell-${index}`}
												fill={genderColor[index % 20]}
											/>
										))}
										//  feminin={feminin.length}
										//   masculin={masculin.length}
										//    autre={autre.length}
										name={t("case-statistic-box4")}
									/>
								</Grid>
								<Grid item xs={12}>
									<Grid container>
										<Grid item xs={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#00A1AF" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{t("case-statistic-box4-fem")}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{skeletton(statecode, feminin, spinner, 10, 10)}
															</Typography>

															{/* {feminin.length} */}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#D12028" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{t("case-statistic-box4-masc")}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{skeletton(
																	statecode,
																	masculin,
																	spinner,
																	10,
																	10
																)}
															</Typography>

															{/* {masculin.length} */}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#1D384C" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{t("case-statistic-box4-other")}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{skeletton(statecode, autre, spinner, 10, 10)}
															</Typography>{" "}
															<Typography
																className={classes.typosubchart}
															></Typography>
															{/* {autre.length} */}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
				<br />
				<Grid container direction="row" spacing={2}>
					<Grid item xs={12} sm={4}>
						<Paper>
							<Grid
								container
								direction="row"
								className={classes.leftAndRightAndTopAndBottom}
							>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<BarCharts
										data={etatcivile}
										filledcolor={etatcivile.map((entry, index) => (
											<Cell
												key={`cell-${index}`}
												fill={etatColor[index % 20]}
											/>
										))}
										name={t("case-statistic-box5")}
									/>
								</Grid>
								<Grid item xs={12} sm={12}>
									<Grid container>
										{/* SINGLE */}
										<Grid item xs={12} sm={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#00A1AF" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{t("case-statistic-box5-bach")}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{skeletton(statecode, celib, spinner, 10, 10)}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>

										{/* MARRIED */}
										<Grid item xs={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#FFDA83" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{t("case-statistic-box5-spouse")}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{skeletton(statecode, marie, spinner, 10, 10)}
															</Typography>
															{/* {marie.length} */}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>

										{/* SEPARATED */}
										<Grid item xs={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#A3A1FB" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{t("case-statistic-box5-separ")}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{skeletton(statecode, separe, spinner, 10, 10)}
															</Typography>
															{/* {separe.length} */}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>

										{/* DIVORCED */}
										<Grid item xs={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#F48C47" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{t("case-statistic-box5-div")}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{skeletton(statecode, divorce, spinner, 10, 10)}
															</Typography>
															{/* {divorce.length} */}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>

										{/* COMMON LAW */}
										<Grid item xs={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#55D8FE" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{t("case-statistic-box5-union").slice(0, 15)}...
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{skeletton(statecode, union, spinner, 10, 10)}
															</Typography>{" "}
															{/* {union.length} */}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>

										{/* WIDOW / WIDOWER */}
										<Grid item xs={12}>
											<Grid container direction="row">
												<Grid item xs={6}>
													<Grid container direction="row">
														<Grid item>
															<StopIcon
																style={{ fontSize: 12, color: "#1D384C" }}
															/>
														</Grid>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{t("case-statistic-box5-widow")}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid
														container
														justifyContent="flex-end"
														alignItems="flex-end"
													>
														<Grid item>
															<Typography className={classes.typosubchart}>
																{skeletton(statecode, veuf, spinner, 10, 10)}
															</Typography>
															{/* {veuf.length} */}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={8}>
						<Grid container direction="row" spacing={3}>
							<Grid item xs={6}>
								<Paper>
									<Grid
										container
										direction="column"
										justifyContent="center"
										alignItems="center"
										className={classes.q8card}
									>
										<Grid item>
											<Typography align="center" className={classes.q8typo}>
												{t("case-statistic-box6")}{" "}
											</Typography>
										</Grid>
										<Grid item>
											<Typography className={classes.q8num}>
												{skeletton(statecode, q8b, spinner, 30, 30)}
											</Typography>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper>
									<Grid
										container
										direction="column"
										justifyContent="center"
										alignItems="center"
										className={classes.q8card}
									>
										<Grid item>
											<Typography align="center" className={classes.q8typo}>
												{t("case-statistic-box7")}{" "}
											</Typography>
										</Grid>
										<Grid item>
											<Typography className={classes.q8num}>
												{skeletton(statecode, q8c, spinner, 30, 30)}
											</Typography>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
						<Grid container direction="row" spacing={2}>
							<Grid item xs={6}>
								<Paper>
									<Grid
										container
										direction="column"
										justifyContent="center"
										alignItems="center"
										className={classes.q8card}
									>
										<Grid item>
											<Typography align="center" className={classes.q8typo}>
												{t("case-statistic-box8")}{" "}
											</Typography>
										</Grid>
										<Grid item>
											<Typography className={classes.q8num}>
												{skeletton(statecode, q8d, spinner, 30, 30)}
											</Typography>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper>
									<Grid
										container
										direction="column"
										justifyContent="center"
										alignItems="center"
										className={classes.q8card}
									>
										<Grid item>
											<Typography align="center" className={classes.q8typo}>
												{t("case-statistic-box9")}{" "}
											</Typography>
										</Grid>
										<Grid item>
											<Typography className={classes.q8num}>
												{skeletton(statecode, q8dnon, spinner, 30, 30)}
											</Typography>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper>
									<Grid
										container
										direction="column"
										justifyContent="center"
										alignItems="center"
										className={classes.q8card}
									>
										<Grid item>
											<Typography align="center" className={classes.q8typo}>
												{t("case-statistic-box11")}{" "}
											</Typography>
										</Grid>
										<Grid item>
											<Typography className={classes.q8num}>
												{skeletton(statecode, q8e, spinner, 30, 30)}
											</Typography>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper>
									<Grid
										container
										direction="column"
										justifyContent="center"
										alignItems="center"
										className={classes.q8card}
									>
										<Grid item>
											<Typography align="center" className={classes.q8typo}>
												{t("case-statistic-box12")}
											</Typography>
										</Grid>
										<Grid item>
											<Typography className={classes.q8num}>
												{skeletton(statecode, ans65, spinner, 30, 30)}
												{/* {skeletton(statecode, q8f, spinner, 30, 30)} */}
											</Typography>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					container
					direction="row"
					spacing={3}
					className={classes.margin_Top_30}
					
				>
					<Grid item xs={12} sm={6}>
						<Grid container direction="column">
							<Grid item xs={12} >
								<Tabls
									title1={t("case-statistic-type-violence")}
									title2={t("cases")}
									incidentTotal={
										statecodeq10a === false && spinnerq10a === true
											? "- - -"
											: q10a.length
									}
									viol={
										statecodeq10a === false && spinnerq10a === true
											? "- - -"
											: viol.length
									}
									conj={
										statecodeq10a === false && spinnerq10a === true
											? "- - -"
											: violconj.length
									}
									agr={
										statecodeq10a === false && spinnerq10a === true
											? "- - -"
											: agr.length
									}
									coer={
										statecodeq10a === false && spinnerq10a === true
											? "- - -"
											: coer.length
									}
									har={
										statecodeq10a === false && spinnerq10a === true
											? "- - -"
											: har.length
									}
									attou={
										statecodeq10a === false && spinnerq10a === true
											? "- - -"
											: attou.length
									}
									etr={
										statecodeq10a === false && spinnerq10a === true
											? "- - -"
											: etr.length
									}
									empl={
										statecodeq10a === false && spinnerq10a === true
											? "- - -"
											: empl.length
									}
									autr={
										statecodeq10a === false && spinnerq10a === true
											? "- - -"
											: autr.length
									}
									physiqueTotal={
										statecodeq10b === false && spinnerq10b === true
											? "- - -"
											: q10b.length
									}
									meu={
										statecodeq10b === false && spinnerq10b === true
											? "- - -"
											: meu.length
									}
									coupDepoing={
										statecodeq10b === false && spinnerq10b === true
											? "- - -"
											: cpoing.length
									}
									coupDobj={
										statecodeq10b === false && spinnerq10b === true
											? "- - -"
											: cpdobj.length
									}
									att={
										statecodeq10b === false && spinnerq10b === true
											? "- - -"
											: att.length
									}
									attAcouto={
										statecodeq10b === false && spinnerq10b === true
											? "- - -"
											: attAcouto.length
									}
									attqAleau={
										statecodeq10b === false && spinnerq10b === true
											? "- - -"
											: attqAleau.length
									}
									priv={
										statecodeq10b === false && spinnerq10b === true
											? "- - -"
											: priv.length
									}
									privDeNoutr={
										statecodeq10b === false && spinnerq10b === true
											? "- - -"
											: privDeNoutr.length
									}
									neglDEnfa={
										statecodeq10b === false && spinnerq10b === true
											? "- - -"
											: neglDEnfa.length
									}
									neglDpers={
										statecodeq10b === false && spinnerq10b === true
											? "- - -"
											: neglDpers.length
									}
									destr={
										statecodeq10b === false && spinnerq10b === true
											? "- - -"
											: destr.length
									}
									rite={
										statecodeq10b === false && spinnerq10b === true
											? "- - -"
											: rite.length
									}
									autr2={
										statecodeq10b === false && spinnerq10b === true
											? "- - -"
											: autr2.length
									}
									psychoTotal={
										statecodeq10c === false && spinnerq10c === true
											? "- - -"
											: q10c.length
									}
									men={
										statecodeq10c === false && spinnerq10c === true
											? "- - -"
											: men.length
									}
									inj={
										statecodeq10c === false && spinnerq10c === true
											? "- - -"
											: inj.length
									}
									neglEm={
										statecodeq10c === false && spinnerq10c === true
											? "- - -"
											: neglEm.length
									}
									humil={
										statecodeq10c === false && spinnerq10c === true
											? "- - -"
											: humil.length
									}
									attVisant={
										statecodeq10c === false && spinnerq10c === true
											? "- - -"
											: attVisant.length
									}
									isol={
										statecodeq10c === false && spinnerq10c === true
											? "- - -"
											: isol.length
									}
									contr={
										statecodeq10c === false && spinnerq10c === true
											? "- - -"
											: contr.length
									}
									cntrDecom={
										statecodeq10c === false && spinnerq10c === true
											? "- - -"
											: cntrDecom.length
									}
									refus={
										statecodeq10c === false && spinnerq10c === true
											? "- - -"
											: refus.length
									}
									abus={
										statecodeq10c === false && spinnerq10c === true
											? "- - -"
											: abus.length
									}
									autr3={
										statecodeq10c === false && spinnerq10c === true
											? "- - -"
											: autr3.length
									}
									econTotal={
										statecodeq10d === false && spinnerq10d === true
											? "- - -"
											: q10d.length
									}
									auteur={
										statecodeq10d === false && spinnerq10d === true
											? "- - -"
											: auteur.length
									}
									conf={
										statecodeq10d === false && spinnerq10d === true
											? "- - -"
											: conf.length
									}
									inter={
										statecodeq10d === false && spinnerq10d === true
											? "- - -"
											: inter.length
									}
									restdeLAccessDeducatio={
										statecodeq10d === false && spinnerq10d === true
											? "- - -"
											: restdeLAccessDeducatio.length
									}
									restdeLAccessDemploie={
										statecodeq10d === false && spinnerq10d === true
											? "- - -"
											: restdeLAccessDemploie.length
									}
									restdeLAccessDeconomique={
										statecodeq10d === false && spinnerq10d === true
											? "- - -"
											: restdeLAccessDeconomique.length
									}
									privDepriseEncharge={
										statecodeq10d === false && spinnerq10d === true
											? "- - -"
											: privDepriseEncharge.length
									}
									levirat={
										statecodeq10d === false && spinnerq10d === true
											? "- - -"
											: levirat.length
									}
									pertepropriete={
										statecodeq10d === false && spinnerq10d === true
											? "- - -"
											: pertepropriete.length
									}
									perteDenfa={
										statecodeq10d === false && spinnerq10d === true
											? "- - -"
											: perteDenfa.length
									}
									autr4={
										statecodeq10d === false && spinnerq10d === true
											? "- - -"
											: autr4.length
									}
								/>
							</Grid>

							<br />
						</Grid>
					</Grid>
					<Grid item xs={12} sm={6}>
						<PrejudiceTbl
							title1={t("case-statistic-categ-of-harm-caused")}
							title2={t("cases")}
							prephysTotal={
								statecodeq13ba === false && spinner13ba === true
									? "- - -"
									: q13ba.length
							}
							dec={
								statecodeq13ba === false && spinner13ba === true
									? "- - -"
									: dec.length
							}
							mal={
								statecodeq13ba === false && spinner13ba === true
									? "- - -"
									: mal.length
							}
							bles={
								statecodeq13ba === false && spinner13ba === true
									? "- - -"
									: bles.length
							}
							dou={
								statecodeq13ba === false && spinner13ba === true
									? "- - -"
									: dou.length
							}
							troublgyn={
								statecodeq13ba === false && spinner13ba === true
									? "- - -"
									: troublgyn.length
							}
							handicap={
								statecodeq13ba === false && spinner13ba === true
									? "- - -"
									: handicap.length
							}
							gros={
								statecodeq13ba === false && spinner13ba === true
									? "- - -"
									: gros.length
							}
							ptDenfa={
								statecodeq13ba === false && spinner13ba === true
									? "- - -"
									: ptDenfa.length
							}
							abusDalc={
								statecodeq13ba === false && spinner13ba === true
									? "- - -"
									: abusDalc.length
							}
							abusDedrog={
								statecodeq13ba === false && spinner13ba === true
									? "- - -"
									: abusDedrog.length
							}
							abusDemed={
								statecodeq13ba === false && spinner13ba === true
									? "- - -"
									: abusDemed.length
							}
							autr5={
								statecodeq13ba === false && spinner13ba === true
									? "- - -"
									: autr5.length
							}
							preEmolTotal={
								statecodeq13bb === false && spinnerq13bb === true
									? "- - -"
									: q13bb.length
							}
							risDeSui={
								statecodeq13bb === false && spinnerq13bb === true
									? "- - -"
									: risDeSui.length
							}
							peur={
								statecodeq13bb === false && spinnerq13bb === true
									? "- - -"
									: peur.length
							}
							depr={
								statecodeq13bb === false && spinnerq13bb === true
									? "- - -"
									: depr.length
							}
							detr={
								statecodeq13bb === false && spinnerq13bb === true
									? "- - -"
									: detr.length
							}
							sent={
								statecodeq13bb === false && spinnerq13bb === true
									? "- - -"
									: sent.length
							}
							sentDecol={
								statecodeq13bb === false && spinnerq13bb === true
									? "- - -"
									: sentDecol.length
							}
							troublDesom={
								statecodeq13bb === false && spinnerq13bb === true
									? "- - -"
									: troublDesom.length
							}
							troublAlim={
								statecodeq13bb === false && spinnerq13bb === true
									? "- - -"
									: troublAlim.length
							}
							peterDespoir={
								statecodeq13bb === false && spinnerq13bb === true
									? "- - -"
									: peterDespoir.length
							}
							manqueDeconf={
								statecodeq13bb === false && spinnerq13bb === true
									? "- - -"
									: manqueDeconf.length
							}
							autr6={
								statecodeq13bb === false && spinnerq13bb === true
									? "- - -"
									: autr6.length
							}
							preEcomTotal={
								statecodeq13bc === false && spinnerq13bc === true
									? "- - -"
									: q13bc.length
							}
							perterDeSu={
								statecodeq13bc === false && spinnerq13bc === true
									? "- - -"
									: perterDeSu.length
							}
							perterDeRev={
								statecodeq13bc === false && spinnerq13bc === true
									? "- - -"
									: perterDeRev.length
							}
							sexDesurvie={
								statecodeq13bc === false && spinnerq13bc === true
									? "- - -"
									: sexDesurvie.length
							}
							peterDemaison={
								statecodeq13bc === false && spinnerq13bc === true
									? "- - -"
									: peterDemaison.length
							}
							demenagement={
								statecodeq13bc === false && spinnerq13bc === true
									? "- - -"
									: demenagement.length
							}
							situation={
								statecodeq13bc === false && spinnerq13bc === true
									? "- - -"
									: situation.length
							}
							pasoupeu={
								statecodeq13bc === false && spinnerq13bc === true
									? "- - -"
									: pasoupeu.length
							}
							autr7={
								statecodeq13bc === false && spinnerq13bc === true
									? "- - -"
									: autr7.length
							}
							impTotal={
								statecodeq13bd === false && spinnerq13bd === true
									? "- - -"
									: q13bd.length
							}
							rupture={
								statecodeq13bd === false && spinnerq13bd === true
									? "- - -"
									: rupture.length
							}
							conflits={
								statecodeq13bd === false && spinnerq13bd === true
									? "- - -"
									: conflits.length
							}
							abndon={
								statecodeq13bd === false && spinnerq13bd === true
									? "- - -"
									: abndon.length
							}
							stigm={
								statecodeq13bd === false && spinnerq13bd === true
									? "- - -"
									: stigm.length
							}
							perterDeGarde={
								statecodeq13bd === false && spinnerq13bd === true
									? "- - -"
									: perterDeGarde.length
							}
							isolDelaVictime={
								statecodeq13bd === false && spinnerq13bd === true
									? "- - -"
									: isolDelaVictime.length
							}
							autr8={
								statecodeq13bd === false && spinnerq13bd === true
									? "- - -"
									: autr8.length
							}
						/>
					</Grid>
				</Grid>
				<br />
				<br />
				<Grid container direction="row" spacing={3}>
					<Grid item xs={12} sm={6}>
						<ProvinceTbl
							title1={t("case-statistic-place-of-the-act-viol")}
							title2={t("cases")}
							kinTotal={
								statecode === false && spinner === true
									? "- - -"
									: Bandalungwa.length +
									  barumbu.length +
									  bumbu.length +
									  gombe.length +
									  kalamu.length +
									  kasa_Vubu.length +
									  kimbanseke.length +
									  kinshasa.length +
									  kintambo.length +
									  kisenso.length +
									  lemba.length +
									  limete.length +
									  lingwala.length +
									  makala.length +
									  maluku.length +
									  masina.length +
									  matete.length +
									  mont_Ngafula.length +
									  ndjili.length +
									  ngaba.length +
									  ngaliema.length +
									  ngiri_ngiri.length +
									  nsele.length +
									  selembao.length
							}
							Bandalungwa={
								statecode === false && spinner === true
									? "- - -"
									: Bandalungwa.length
							}
							barumbu={
								statecode === false && spinner === true
									? "- - -"
									: barumbu.length
							}
							bumbu={
								statecode === false && spinner === true ? "- - -" : bumbu.length
							}
							gombe={
								statecode === false && spinner === true ? "- - -" : gombe.length
							}
							kalamu={
								statecode === false && spinner === true
									? "- - -"
									: kalamu.length
							}
							kasa_Vubu={
								statecode === false && spinner === true
									? "- - -"
									: kasa_Vubu.length
							}
							kimbanseke={
								statecode === false && spinner === true
									? "- - -"
									: kimbanseke.length
							}
							kinshasa={
								statecode === false && spinner === true
									? "- - -"
									: kinshasa.length
							}
							kintambo={
								statecode === false && spinner === true
									? "- - -"
									: kintambo.length
							}
							kisenso={
								statecode === false && spinner === true
									? "- - -"
									: kisenso.length
							}
							lemba={
								statecode === false && spinner === true ? "- - -" : lemba.length
							}
							limete={
								statecode === false && spinner === true
									? "- - -"
									: limete.length
							}
							lingwala={
								statecode === false && spinner === true
									? "- - -"
									: lingwala.length
							}
							makala={
								statecode === false && spinner === true
									? "- - -"
									: makala.length
							}
							maluku={
								statecode === false && spinner === true
									? "- - -"
									: maluku.length
							}
							masina={
								statecode === false && spinner === true
									? "- - -"
									: masina.length
							}
							matete={
								statecode === false && spinner === true
									? "- - -"
									: matete.length
							}
							mont_Ngafula={
								statecode === false && spinner === true
									? "- - -"
									: mont_Ngafula.length
							}
							ndjili={
								statecode === false && spinner === true
									? "- - -"
									: ndjili.length
							}
							ngaba={
								statecode === false && spinner === true ? "- - -" : ngaba.length
							}
							ngaliema={
								statecode === false && spinner === true
									? "- - -"
									: ngaliema.length
							}
							ngiri_ngiri={
								statecode === false && spinner === true
									? "- - -"
									: ngiri_ngiri.length
							}
							nsele={
								statecode === false && spinner === true ? "- - -" : nsele.length
							}
							selembao={
								statecode === false && spinner === true
									? "- - -"
									: selembao.length
							}
							ituriTotal={
								statecode === false && spinner === true
									? "- - -"
									: bunia.length +
									  aru.length +
									  djugu.length +
									  irumu.length +
									  mahagi.length +
									  mambasa.length
							}
							bunia={
								statecode === false && spinner === true ? "- - -" : bunia.length
							}
							aru={
								statecode === false && spinner === true ? "- - -" : aru.length
							}
							djugu={
								statecode === false && spinner === true ? "- - -" : djugu.length
							}
							irumu={
								statecode === false && spinner === true ? "- - -" : irumu.length
							}
							mahagi={
								statecode === false && spinner === true
									? "- - -"
									: mahagi.length
							}
							mambasa={
								statecode === false && spinner === true
									? "- - -"
									: mambasa.length
							}
							kaisaiCentralTotal={
								statecode === false && spinner === true
									? "- - -"
									: kananga.length +
									  demba.length +
									  dibaya.length +
									  dimbelenge.length +
									  kazumba.length +
									  luiza.length
							}
							kananga={
								statecode === false && spinner === true
									? "- - -"
									: kananga.length
							}
							demba={
								statecode === false && spinner === true ? "- - -" : demba.length
							}
							dibaya={
								statecode === false && spinner === true
									? "- - -"
									: dibaya.length
							}
							dimbelenge={
								statecode === false && spinner === true
									? "- - -"
									: dimbelenge.length
							}
							kazumba={
								statecode === false && spinner === true
									? "- - -"
									: kazumba.length
							}
							luiza={
								statecode === false && spinner === true ? "- - -" : luiza.length
							}
							kasaiOrientalTotal={
								statecode === false && spinner === true
									? "- - -"
									: mbuji_mayi.length +
									  kabeya.length +
									  katanda.length +
									  lupatapata.length +
									  miabi.length +
									  tshilenge.length
							}
							mbuji_mayi={
								statecode === false && spinner === true
									? "- - -"
									: mbuji_mayi.length
							}
							kabeya={
								statecode === false && spinner === true
									? "- - -"
									: kabeya.length
							}
							katanda={
								statecode === false && spinner === true
									? "- - -"
									: katanda.length
							}
							lupatapata={
								statecode === false && spinner === true
									? "- - -"
									: lupatapata.length
							}
							miabi={
								statecode === false && spinner === true ? "- - -" : miabi.length
							}
							tshilenge={
								statecode === false && spinner === true
									? "- - -"
									: tshilenge.length
							}
							maniemaTotal={
								statecode === false && spinner === true
									? "- - -"
									: kindu.length +
									  kabambare.length +
									  kailo.length +
									  kasongo.length +
									  kibombo.length +
									  lubutu.length +
									  pangi.length +
									  punia.length
							}
							kindu={
								statecode === false && spinner === true ? "- - -" : kindu.length
							}
							kabambare={
								statecode === false && spinner === true
									? "- - -"
									: kabambare.length
							}
							kailo={
								statecode === false && spinner === true ? "- - -" : kailo.length
							}
							kasongo={
								statecode === false && spinner === true
									? "- - -"
									: kasongo.length
							}
							kibombo={
								statecode === false && spinner === true
									? "- - -"
									: kibombo.length
							}
							lubutu={
								statecode === false && spinner === true
									? "- - -"
									: lubutu.length
							}
							pangi={
								statecode === false && spinner === true ? "- - -" : pangi.length
							}
							punia={
								statecode === false && spinner === true ? "- - -" : punia.length
							}
							nordkivuTotal={
								statecode === false && spinner === true
									? "- - -"
									: benivile.length +
									  butembo.length +
									  goma.length +
									  beni.length +
									  lubero.length +
									  masisi.length +
									  nyiragongo.length +
									  rutshuru.length +
									  walikale.length
							}
							ben={
								statecode === false && spinner === true
									? "- - -"
									: benivile.length
							}
							butembo={
								statecode === false && spinner === true
									? "- - -"
									: butembo.length
							}
							goma={
								statecode === false && spinner === true ? "- - -" : goma.length
							}
							beni={
								statecode === false && spinner === true ? "- - -" : beni.length
							}
							lubero={
								statecode === false && spinner === true
									? "- - -"
									: lubero.length
							}
							masisi={
								statecode === false && spinner === true
									? "- - -"
									: masisi.length
							}
							nyiragongo={
								statecode === false && spinner === true
									? "- - -"
									: nyiragongo.length
							}
							rutshuru={
								statecode === false && spinner === true
									? "- - -"
									: rutshuru.length
							}
							walikale={
								statecode === false && spinner === true
									? "- - -"
									: walikale.length
							}
							sudkivuTotal={
								statecode === false && spinner === true
									? "- - -"
									: bukavu.length +
									  fizi.length +
									  idjwi.length +
									  kabare.length +
									  mwenga.length +
									  shabunda.length +
									  uvira.length +
									  walungu.length
							}
							bukavu={
								statecode === false && spinner === true
									? "- - -"
									: bukavu.length
							}
							fizi={
								statecode === false && spinner === true ? "- - -" : fizi.length
							}
							idjwi={
								statecode === false && spinner === true ? "- - -" : idjwi.length
							}
							kabare={
								statecode === false && spinner === true
									? "- - -"
									: kabare.length
							}
							kalehe={
								statecode === false && spinner === true
									? "- - -"
									: kalehe.length
							}
							mwenga={
								statecode === false && spinner === true
									? "- - -"
									: mwenga.length
							}
							shabunda={
								statecode === false && spinner === true
									? "- - -"
									: shabunda.length
							}
							uvira={
								statecode === false && spinner === true ? "- - -" : uvira.length
							}
							walungu={
								statecode === false && spinner === true
									? "- - -"
									: walungu.length
							}
							tshopoTotal={
								statecode === false && spinner === true
									? "- - -"
									: kisangani.length +
									  bafwasende.length +
									  banalia.length +
									  basoko.length +
									  isangi.length +
									  opala.length +
									  ubundu.length +
									  yahuma.length
							}
							kisangani={
								statecode === false && spinner === true
									? "- - -"
									: kisangani.length
							}
							bafwasende={
								statecode === false && spinner === true
									? "- - -"
									: bafwasende.length
							}
							banalia={
								statecode === false && spinner === true
									? "- - -"
									: banalia.length
							}
							basoko={
								statecode === false && spinner === true
									? "- - -"
									: basoko.length
							}
							isangi={
								statecode === false && spinner === true
									? "- - -"
									: isangi.length
							}
							opala={
								statecode === false && spinner === true ? "- - -" : opala.length
							}
							ubundu={
								statecode === false && spinner === true
									? "- - -"
									: ubundu.length
							}
							yahuma={
								statecode === false && spinner === true
									? "- - -"
									: yahuma.length
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Grid container spacing={4}>
							<Grid item xs={12} sm={12}>
								<Auteurtbl
									title1={t("case-statistic-perpetrator-categ")}
									title2={t("cases")}
									mememberTotal={
										statecodeq14a === false && spinnerq14a === true
											? "- - -"
											: q14a.length
									}
									mere={
										statecodeq14a === false && spinnerq14a === true
											? "- - -"
											: mere.length
									}
									pere={
										statecodeq14a === false && spinnerq14a === true
											? "- - -"
											: pere.length
									}
									tuthom={
										statecodeq14a === false && spinnerq14a === true
											? "- - -"
											: tuthom.length
									}
									tutfem={
										statecodeq14a === false && spinnerq14a === true
											? "- - -"
											: tutfem.length
									}
									proFrer={
										statecodeq14a === false && spinnerq14a === true
											? "- - -"
											: proFrer.length
									}
									propSoeur={
										statecodeq14a === false && spinnerq14a === true
											? "- - -"
											: propSoeur.length
									}
									propDenfa={
										statecodeq14a === false && spinnerq14a === true
											? "- - -"
											: propDenfa.length
									}
									gdmere={
										statecodeq14a === false && spinnerq14a === true
											? "- - -"
											: gdmere.length
									}
									gdpere={
										statecodeq14a === false && spinnerq14a === true
											? "- - -"
											: gdpere.length
									}
									belmere={
										statecodeq14a === false && spinnerq14a === true
											? "- - -"
											: belmere.length
									}
									beaupere={
										statecodeq14a === false && spinnerq14a === true
											? "- - -"
											: beaupere.length
									}
									beauFere={
										statecodeq14a === false && spinnerq14a === true
											? "- - -"
											: beauFere.length
									}
									belSoeur={
										statecodeq14a === false && spinnerq14a === true
											? "- - -"
											: belSoeur.length
									}
									oncle={
										statecodeq14a === false && spinnerq14a === true
											? "- - -"
											: oncle.length
									}
									tante={
										statecodeq14a === false && spinnerq14a === true
											? "- - -"
											: tante.length
									}
									autrmembre={
										statecodeq14a === false && spinnerq14a === true
											? "- - -"
											: autrmembre.length
									}
									conjointeTotal={
										statecode14b === false && spinnerq14b === true
											? "- - -"
											: q14b.length
									}
									mari={
										statecode14b === false && spinnerq14b === true
											? "- - -"
											: mari.length
									}
									exmari={
										statecode14b === false && spinnerq14b === true
											? "- - -"
											: exmari.length
									}
									epouse={
										statecode14b === false && spinnerq14b === true
											? "- - -"
											: epouse.length
									}
									exepouse={
										statecode14b === false && spinnerq14b === true
											? "- - -"
											: exepouse.length
									}
									partHomme={
										statecode14b === false && spinnerq14b === true
											? "- - -"
											: partHomme.length
									}
									partFemme={
										statecode14b === false && spinnerq14b === true
											? "- - -"
											: partFemme.length
									}
									expartHomme={
										statecode14b === false && spinnerq14b === true
											? "- - -"
											: expartHomme.length
									}
									expartFemme={
										statecode14b === false && spinnerq14b === true
											? "- - -"
											: expartFemme.length
									}
									autreRelationTotal={
										statecode14b === false && spinnerq14b === true
											? "- - -"
											: q14c.length
									}
									patronHomDom={
										statecodeq14c === false && spinnerq14c === true
											? "- - -"
											: patronHomDom.length
									}
									patronFemDom={
										statecodeq14c === false && spinnerq14c === true
											? "- - -"
											: patronFemDom.length
									}
									personelHomDomestique={
										statecodeq14c === false && spinnerq14c === true
											? "- - -"
											: personelHomDomestique.length
									}
									personelFemDomestique={
										statecodeq14c === false && spinnerq14c === true
											? "- - -"
											: personelFemDomestique.length
									}
									bailDemezon={
										statecodeq14c === false && spinnerq14c === true
											? "- - -"
											: bailDemezon.length
									}
									bailleDemezon={
										statecodeq14c === false && spinnerq14c === true
											? "- - -"
											: bailleDemezon.length
									}
									autremember2={
										statecodeq14c === false && spinnerq14c === true
											? "- - -"
											: autremember2.length
									}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Paper>
									<Grid
										container
										className={classes.leftAndRightAndTopAndBottom}
									>
										<Grid item xs={12} sm={12}>
											<Typography className={classes.typochart}>
												{t("case-statistic-place-where-dv-took")}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={12}>
											<Grid container direction="row" spacing={2}>
												<Grid item xs={12} sm={6}>
													{statecode === false && spinner === true ? (
														<center>
															<Skeleton
																variant="circle"
																width={150}
																height={165}
															/>
														</center>
													) : (
														<DoughnutCharts
															data={milieu}
															width={150}
															height={165}
															alignItems="center"
															justifyContent="center"
															innerRadius="50%"
															outerRadius="90%"
															//typography="Milieu où l’acte de violence domestique a eu lieu"
															filledColor={milieu.map((entery, index) => (
																<Cell
																	key={`cell-${index}`}
																	fill={milieuColor[index % milieuColor.length]}
																/>
															))}
														/>
													)}
												</Grid>
												<Grid item xs={12} sm={6}>
													{/* <Grid container direction='row' className={classes.margin_Top_30} spacing={2} >                                                 */}
													<Grid item xs={12} sm={12}>
														<Grid
															container
															direction="column"
															spacing={1}
															className={classes.milieu}
														>
															<Grid item xs={12} sm={12}>
																<Grid
																	container
																	direction="row"
																	className={classes.margin_20}
																>
																	<Grid item xs={1} sm={2}>
																		<RadioButtonUncheckedIcon
																			style={{ fontSize: 12, color: "#FFDA83" }}
																		/>
																	</Grid>
																	<Grid item xs={10} sm={8}>
																		<Typography
																			className={classes.typosubchart}
																		>
																			{t("case-statistic-place-urb")}
																		</Typography>{" "}
																	</Grid>
																	<Grid item xs={1} sm={2}>
																		<Typography
																			className={classes.typosubchart}
																		>
																			{skeletton(
																				statecode,
																				urbain,
																				spinner,
																				10,
																				10
																			)}
																			{/* {urbain} */}
																		</Typography>
																	</Grid>
																</Grid>
															</Grid>
															<Grid item xs={12} sm={12}>
																<Grid container direction="row">
																	<Grid item xs={1} sm={2}>
																		<RadioButtonUncheckedIcon
																			style={{ fontSize: 12, color: "#A3A1FB" }}
																		/>
																	</Grid>
																	<Grid item xs={10} sm={8}>
																		<Typography
																			className={classes.typosubchart}
																		>
																			{t("case-statistic-place-semi-urb")}
																		</Typography>{" "}
																	</Grid>
																	<Grid item xs={1} sm={2}>
																		<Typography
																			className={classes.typosubchart}
																		>
																			{skeletton(
																				statecode,
																				semi,
																				spinner,
																				10,
																				10
																			)}
																			{/* {semi} */}
																		</Typography>
																	</Grid>
																</Grid>
															</Grid>
															<Grid item xs={12} sm={12}>
																<Grid
																	container
																	direction="row"
																	className={classes.margin_Bottom_20s}
																>
																	<Grid item xs={1} sm={2}>
																		<RadioButtonUncheckedIcon
																			style={{ fontSize: 12, color: "#FF8373" }}
																		/>
																	</Grid>
																	<Grid item xs={10} sm={8}>
																		<Typography
																			className={classes.typosubchart}
																		>
																			{t("case-statistic-place-rural")}
																		</Typography>{" "}
																	</Grid>
																	<Grid item xs={1} sm={2}>
																		<Typography
																			className={classes.typosubchart}
																		>
																			{skeletton(
																				statecode,
																				rural,
																				spinner,
																				10,
																				10
																			)}

																			{/* {rural} */}
																		</Typography>
																	</Grid>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										{/* </Grid> */}
									</Grid>
								</Paper>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		);

		// }
	};
	return (
		<Grid container style={{ marginBottom: 60 }}>
			<Grid item xs={12} sm={12}>
				{displayData()}
			</Grid>
		</Grid>
	);
};

export default CaseStatistc;

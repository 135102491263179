import { Grid, Typography } from "@material-ui/core";
import useStyles from "../../component/website/campain/style";
import React,{useEffect} from "react";
import Section1 from  '../../component/website/where_to/section1'
import { useTranslation } from 'react-i18next';
import {NavLink} from 'react-router-dom'
import {Helmet} from "react-helmet";



export default function Where_to_find() {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])

  return (
    <div>
      <Helmet>
              <title>{t('serctioncenter')}</title>
              <meta name='description' content={t('sectioncenter-text')}/>
            </Helmet>
      <Grid container className={classes.section_1} alignItems="center">
        <Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
        <Grid item xs={10} sm={10} md={10} lg={8}>
          <Section1 />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
      </Grid>
      <Grid className={classes.marginTop_13} style={{ padding: "2vh" }}>
        <Grid container alignItems="center">
          <Grid item xs={1} sm={1} md={2} lg={2} xl={2}></Grid>
          <Grid item xs={10} sm={10} md={8} lg={8} xl={8} className={classes.marginBottom_5}>
          <Grid container direction='column' spacing={5}>
               
              <Grid item xs={12} sm={12}>
               <Grid container>
                   <Grid item><Typography className={classes.dark_txt}>
                   {t('section-center-content-text-pg1')}
                   
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
               <Grid container>
                   <Grid item><Typography className={classes.dark_txt}>
                   {t('section-center-content-text-pg2')}
                   <NavLink style={{color:'#00A1AF'}} to='/Partners'>{t('section-center-content-text-link')}</NavLink>
                   </Typography>
                   </Grid>
               </Grid>
              </Grid>
              
              
              
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={2} lg={2} xl={2}></Grid>
        </Grid>
      </Grid>
    </div>
  );
}

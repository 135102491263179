import React, { useState, useEffect } from "react";
import useStyles from "../styles";
import BackPaper from "../../../widget/backpaper";
import {
	Grid,
	Card,
	CardContent,
	Typography,
	Tab,
	Tabs,
	Box,
	Divider,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Quantitative from "./subcomponent/quantitative";
import Qualitative from "./subcomponent/qualitative";
import axiosInstance from "../../../service/dashboardAxiosApi";

// controle for the tabPanel

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-prevent-tabpanel-${index}`}
			aria-labelledby={`scrollable-prevent-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography component={"span"}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

const Analysis = () => {
	const classes = useStyles();
	const tablabel = ["QUANTITATIVE", "QUALITATIVE"];
	const [value, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [data, setdata] = useState([]);
	const [q10AData, setq10AData] = useState([]);
	const [spinner, setspinner] = useState(true);
	const [errmsg, seterrmsg] = useState("");
	const [statecode, setstatecode] = useState(false);

	const getApprove = async () => {
		setspinner(true);
		await axiosInstance
			.get(`approvedcases/`)
			.then((res) => {
				if (res.status === 200) {
					const dt = res.data.approved;
					const dtQ10 = dt.filter((e) => e.question_10_a.length > 0);
					setdata(dt);
					setq10AData(dtQ10);
					setstatecode(true);
					seterrmsg("Approve cases successfull fetched");
				}
			})
			.catch((err) => {
				setspinner(false);
				if (err.response) {
					seterrmsg("Please check your internet connexion");
				} else if (err.request) {
					//  console.log(err.request.timeout);
					seterrmsg("Please check your internet connexion");
				}
			});
		//console.log("Q10: ", q10AData);
	};

	useEffect(() => {
		getApprove();
	}, [data.id]);
	// console.log(data)
	return (
		<BackPaper
			content={
				<div>
					<Grid container direction="row">
						<Grid item xs={12} sm={12}>
							<Typography variant="h4" className={classes.bigtitle}>
								Analysis
							</Typography>
						</Grid>
						{/* quantitative and qualitative  section */}
						<Grid container direction="row">
							<Grid item sm={12} xs={12}>
								<Card className={classes.grid} elevation={3}>
									<CardContent>
										<Grid container direction="row">
											<Grid item sm={12} xs={12}>
												<Tabs
													disableRipple
													value={value}
													indicatorColor="primary"
													variant="fullWidth"
													textColor="primary"
													scrollButtons="on"
													onChange={handleChange}
												>
													{tablabel.map((tab) => (
														<Tab
															key={tab}
															label={
																<span className={classes.tab}> {tab}</span>
															}
														/>
													))}
												</Tabs>
												<Divider component="hr" />
											</Grid>
										</Grid>
										<Grid container>
											{/* subcompent in the folder */}
											<Grid item sm={12} xs={12}>
												<TabPanel value={value} index={0}>
													<Quantitative
														data={data}
														getApprove={getApprove}
														statecode={statecode}
														errmsg={errmsg}
														spinner={spinner}
													/>
												</TabPanel>
												<TabPanel value={value} index={1}>
													<Qualitative
														data={data}
														getApprove={getApprove}
														statecode={statecode}
														errmsg={errmsg}
														spinner={spinner}
													/>
												</TabPanel>
											</Grid>
										</Grid>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</Grid>
				</div>
			}
		/>
	);
};

export default Analysis;

import React, { useState, useEffect } from "react";
import { Grid, Card, Typography, CardContent } from "@material-ui/core";
import MapIcon from "@material-ui/icons/Map";
import useStyles from "./style.js";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import CloudOffTwoToneIcon from '@material-ui/icons/CloudOffTwoTone';
// import RestoreIcon from '@material-ui/icons/Restore';
import Skeleton from "@material-ui/lab/Skeleton";
import axiosInstance from "../../../service/axiosPub";
import AnimatedNumber from "animated-number-react";

const Section3 = () => {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const [q10a, setq10a] = useState([]);
	const [q10b, setq10b] = useState([]);
	const [q10c, setq10c] = useState([]);
	const [q10d, setq10d] = useState([]);
	const [spinnerq10a, setspinnerq10a] = useState(true);
	const [spinnerq10b, setspinnerq10b] = useState(true);
	const [spinnerq10c, setspinnerq10c] = useState(true);
	const [spinnerq10d, setspinnerq10d] = useState(true);
	const [errmsgq10a, seterrmsgq10a] = useState("");
	const [statecodeq10a, setstatecodeq10a] = useState(false);
	const [errmsgq10b, seterrmsgq10b] = useState("");
	const [statecodeq10b, setstatecodeq10b] = useState(false);
	const [errmsgq10c, seterrmsgq10c] = useState("");
	const [statecodeq10c, setstatecodeq10c] = useState(false);
	const [errmsgq10d, seterrmsgq10d] = useState("");
	const [statecodeq10d, setstatecodeq10d] = useState(false);

	const getq10a = async () => {
		setspinnerq10a(true);
		await axiosInstance
			.get(`q10a/`)
			.then((res) => {
				if (res.status === 200) {
					const data = res.data;
					const dt = data.map((e) => ({
						q10a: e.question_10_a,
					}));
					setq10a(dt);
					setstatecodeq10a(true);
					// console.log(dt);
				}
			})
			.catch((err) => {
				setspinnerq10a(false);
				if (err.request) {
					seterrmsgq10a("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq10a("Please check your internet connexion");
				}
			});
	};
	const getq10b = async () => {
		setspinnerq10b(true);
		await axiosInstance
			.get(`q10b/`)
			.then((res) => {
				if (res.status === 200) {
					const data = res.data;
					const dt = data.map((e) => ({
						q10b: e.question_10_b,
					}));
					// console.log(dt);
					setq10b(dt);
					setstatecodeq10b(true);
				}
			})
			.catch((err) => {
				setspinnerq10b(false);
				if (err.request) {
					seterrmsgq10b("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq10b("Please check your internet connexion");
				}
			});
	};
	const getq10c = async () => {
		setspinnerq10c(true);
		await axiosInstance
			.get(`q10c/`)
			.then((res) => {
				if (res.status === 200) {
					const data = res.data;
					const dt = data.map((e) => ({
						q10c: e.question_10_c,
					}));
					// console.log(dt);
					setq10c(dt);
					setstatecodeq10c(true);
				}
			})
			.catch((err) => {
				setspinnerq10c(false);
				if (err.request) {
					seterrmsgq10c("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq10c("Please check your internet connexion");
				}
			});
	};
	const getq10d = async () => {
		setspinnerq10d(true);
		await axiosInstance
			.get(`q10d/`)
			.then((res) => {
				if (res.status === 200) {
					const data = res.data;
					const dt = data.map((e) => ({
						q10d: e.question_10_d,
					}));
					// console.log(dt);
					setq10d(dt);
					setstatecodeq10d(true);
				}
			})
			.catch((err) => {
				setspinnerq10d(false);
				if (err.request) {
					seterrmsgq10d("Please check your internet connexion");
				} else if (err.response) {
					seterrmsgq10d("Please check your internet connexion");
				}
			});
	};

	useEffect(() => {
		getq10a();
		getq10b();
		getq10c();
		getq10d();
	}, []);
	//console.log(q10a.length);

	const skeletton = (state, value, spin) => {
		if (state === false) {
			if (spin === true) {
				return (
					<Skeleton
						variant="circle"
						width={30}
						height={30}
						style={{ marginBottom: 20, marginTop: 10 }}
					/>
				);
			} else {
				setTimeout(() => {
					getq10a();
					getq10b();
					getq10c();
					getq10d();
				}, 90000);
				return (
					<Skeleton
						variant="circle"
						width={30}
						height={30}
						style={{ marginBottom: 20, marginTop: 10 }}
					/>
				);
			}
		} else if (state === true) {
			return (
				<AnimatedNumber
					value={value.length}
					formatValue={(v) => v.toFixed(0)}
					duration={4000}
					frameStyle={(perc) => ({
						opacity: perc / 100,
					})}
				/>
			);
		}
	};

	const listData = () => {
		//   if(statecodeq10a===false && statecodeq10b===false && statecodeq10c===false && statecodeq10d===false){
		//     if(spinnerq10a ===true &&  spinnerq10b ===true &&  spinnerq10c ===true &&  spinnerq10d ===true){
		//       return (
		//         <Grid container direction='row' spacing={1}>
		//         <Grid item xs={12} sm={4}><Skeleton variant="rect" height={200}  /></Grid>
		//         <Grid item xs={12} sm={4}><Skeleton variant="rect" height={200}  /></Grid>
		//         <Grid item xs={12} sm={4}><Skeleton variant="rect" height={200}  /></Grid>
		//         </Grid>
		//       )
		//     }else{
		//       return  (<Grid container  justifyContent="center" alignItems='center'>
		//       <Grid item>
		//         <Grid container  justifyContent="center" spacing={2}>
		//           <Grid item ><CloudOffTwoToneIcon size={40}/></Grid>
		//            <Grid item ><Typography>{errmsgq10a}</Typography></Grid>
		//         </Grid>
		//         <Grid container justifyContent="center" alignItems='center'>
		//         <Grid item >
		//               <Button startIcon={<RestoreIcon />} onClick={()=>{
		//                 getq10a()
		//                 getq10b()
		//                 getq10c()
		//                 getq10d()
		//               }}>Reload</Button>
		//              </Grid>
		//         </Grid>
		//       </Grid>
		//   </Grid>)
		//     }

		// }
		// else
		// if(statecodeq10a===true && statecodeq10b===true && statecodeq10c===true && statecodeq10d===true ){
		return (
			<Grid
				container
				direction="row"
				spacing={3}
				justifyContent="center"
				alignItems="center"
			>
				<Grid item xs={10} sm={3} md={3}>
					<Card className={classes.card_stl} elevation={0}>
						<Grid container>
							<Grid item>
								<CardContent>
									<Grid container justifyContent="space-around">
										<Typography className={classes.card_txt1}>
											{skeletton(statecodeq10a, q10a, spinnerq10a)}
										</Typography>
									</Grid>
									<Grid container justifyContent="space-around">
										<Typography
											variant="body2"
											align="center"
											className={classes.white_color}
										>
											{t("box")}
										</Typography>
									</Grid>
								</CardContent>
							</Grid>
						</Grid>
					</Card>
				</Grid>
				<Grid item xs={10} sm={3} md={3}>
					<Card className={classes.card_stl} elevation={0}>
						<Grid container justifyContent="space-around">
							<Grid item>
								<CardContent>
									<Grid container justifyContent="space-around">
										<Typography className={classes.card_txt1}>
											{skeletton(statecodeq10b, q10b, spinnerq10b)}

											{/* {q10b.length===0?(
                <Skeleton variant='circle' width={30} height={30} style={{marginBottom:20,marginTop:10}}/>
              ):(
                <AnimatedNumber
                value={q10b.length}
                formatValue={v=>v.toFixed(0)}
                duration={4000}
                frameStyle={perc =>({
                  opacity:perc/100
                })}
              />
              )}    */}
											{/* <AnimatedNumber
                    value={q10b.length}
                    formatValue={v=>v.toFixed(0)}
                    duration={4000}
                    frameStyle={perc =>({
                      opacity:perc/100
                    })}
                  /> */}
										</Typography>
									</Grid>
									<Grid container justifyContent="space-around">
										<Typography
											variant="body2"
											align="center"
											className={classes.white_color}
										>
											{t("box2")}
										</Typography>
									</Grid>
								</CardContent>
							</Grid>
						</Grid>
					</Card>
				</Grid>

				<Grid item xs={10} sm={3} md={3}>
					<Card className={classes.card_stl} elevation={0}>
						<Grid container justifyContent="space-around">
							<Grid item>
								<CardContent>
									<Grid container justifyContent="space-around">
										<Typography className={classes.card_txt1}>
											{skeletton(statecodeq10c, q10c, spinnerq10c)}

											{/* {q10c.length===0?(
                <Skeleton variant='circle' width={30} height={30} style={{marginBottom:20,marginTop:10}}/>
              ):(
                <AnimatedNumber
                value={q10c.length}
                formatValue={v=>v.toFixed(0)}
                duration={4000}
                frameStyle={perc =>({
                  opacity:perc/100
                })}
              />
              )}  */}
											{/* <AnimatedNumber
                    value={q10c.length}
                    formatValue={v=>v.toFixed(0)}
                    duration={4000}
                    frameStyle={perc =>({
                      opacity:perc/100
                    })}
                  /> */}
										</Typography>
									</Grid>
									<Grid container justifyContent="space-around">
										<Typography
											variant="body2"
											align="center"
											className={classes.white_color}
										>
											{t("box3")}
										</Typography>
									</Grid>
								</CardContent>
							</Grid>
						</Grid>
					</Card>
				</Grid>
				<Grid item xs={10} sm={3} md={3}>
					<Card className={classes.card_stl} elevation={0}>
						<Grid container justifyContent="space-around">
							<Grid item>
								<CardContent>
									<Grid container justifyContent="space-around">
										<Typography className={classes.card_txt1}>
											{skeletton(statecodeq10d, q10d, spinnerq10d)}

											{/* {q10d.length===0?(
                <Skeleton variant='circle' width={30} height={30} style={{marginBottom:20,marginTop:10}}/>
              ):(
                <AnimatedNumber
                value={q10d.length}
                formatValue={v=>v.toFixed(0)}
                duration={4000}
                frameStyle={perc =>({
                  opacity:perc/100
                })}
              />
              )}  */}
											{/* <AnimatedNumber
                    value= {q10d.length}
                    formatValue={v=>v.toFixed(0)}
                    duration={4000}
                    frameStyle={perc =>({
                      opacity:perc/100
                    })}
                  /> */}
										</Typography>
									</Grid>
									<Grid container justifyContent="space-around">
										<Typography
											variant="body2"
											align="center"
											className={classes.white_color}
										>
											{t("box4")}
										</Typography>
									</Grid>
								</CardContent>
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>
		);
		// }
	};

	return (
		<Grid container direction="row" spacing={2}>
			<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
				<Grid container justifyContent="center" alignItems="center">
					<Grid item className={classes.card_circle}>
						<NavLink
							style={{ textDecoration: "none" }}
							to={"/" + i18n.language + t("link-case-database")}
						>
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								spacing={3}
							>
								<Grid item>
									<MapIcon style={{ fontSize: 50 }} color="secondary" />
								</Grid>
								<Grid item>
									<Grid container>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<Typography
												variant="body2"
												className={classes.green_color}
												align="center"
												//style={{justifyContent:'center'}}
											>
												{t("roundsymbolLeft1")}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</NavLink>
					</Grid>
				</Grid>
				{/* <Grid container  justifyContent='center' alignItems='center'  className={classes.card_circle}>
          <NavLink  style={{ textDecoration: "none",}} to={'/'+i18n.language+t('link-case-database')}>
          <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
           <Grid container  justifyContent='center' alignItems='center'>
             <Grid item>
             <MapIcon style={{ fontSize: 50 }} color="secondary" />
             </Grid>
           </Grid>
          </Grid>
          <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            variant="body2"
            className={classes.green_color}
            align="center"
            //style={{justifyContent:'center'}}
          >
            {t("roundsymbolLeft1")}
          </Typography>
          </Grid>
          </NavLink>
         
        </Grid> */}
			</Grid>

			<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
				{listData()}
			</Grid>
		</Grid>
	);
};

export default Section3;

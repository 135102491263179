import React from 'react'
import useStyles from './styles'
import {Grid,Paper} from '@material-ui/core'


const Backpaper =({content})=>{
    const classes = useStyles()
    return (
        <div className={classes.root}>

           <Grid container>
            <Grid item xs={12} sm={12}>
            <Paper style={{height:'vh',paddingBottom:50}}>
                {content}
            </Paper>
            </Grid>
            </Grid>

</div>

    )
}

export default Backpaper;
import { Grid } from "@material-ui/core";
import useStyles from "../../component/website/campain/style";
import React,{useEffect} from "react";
import Section1 from "../../component/website/CaseBank/section1";
import CaseMap from "../../component/website/CaseBank/case_map";
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';



export default function CaseBank() {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])
  return (
    <div>
      <Helmet>
              <title>{t('case-databank')}</title>
              <meta name='description' content={t('case-databank-text')}/>
            </Helmet>
      <Grid container className={classes.section_1} alignItems="center">
        <Grid xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
        <Grid xs={10} sm={10} md={10} lg={10}>
          <Section1 />
        </Grid>
        <Grid xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid  xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
        <Grid xs={10} sm={10} md={10} lg={10} xl={8} className={classes.marginTopbot_10}>
          <CaseMap />
        </Grid>
        <Grid xs={1} sm={1} md={1} lg={1} xl={2}></Grid>
      </Grid>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Grid, Typography } from "@material-ui/core"; //, Card, CardContent, Paper
import useStyles from "../../../styles";
import axiosInstance from "../../../../../service/dashboardAxiosApi";

const LineChart = () => {
	const classes = useStyles();
	const [cases, setcases] = useState([]);

	useEffect(() => {
		const getCase = async () => {
			await axiosInstance.get(`approvedcases/`).then((res) => {
				const data = res.data.approved;
				const dt = data.map((e) => ({
					id: e.id,
					caseid: e.caseid,
					org: e.organization,
					staff: e.question_2,
					doc: e.question_3,
					// dofdoc: e.question_4.slice(6), //Uncomment this linein 2023 When the data collection reaches 3 years in a row
					dateofdoc: e.question_4.slice(2),
					code: e.question_5.toUpperCase(),
					age: e.question_6_a,
					gen: e.question_7,
				}));
				setcases(dt);
			});
		};
		getCase();
	}, [cases.id]); //axiosInstance
	//const a = cases.map(e=>e.dofdoc.slice(6));
	//console.log(cases);
	//console.log(cases.map(e=>e.dofdoc));
	//console.log(new Set(cases.map(e=>e.dofdoc)));

	//TODO: Uncomment in 2023,
	// var c = cases.map((e) => e.dofdoc);
	// var map = c.reduce(function (prev, cur) {
	// 	prev[cur] = (prev[cur] || 0) + 1;
	// 	return prev;
	// }, {});

	// const data = {
	// 	labels: [...new Set(cases.map((e) => e.dofdoc).sort((a, b) => a - b))],
	// 	datasets: [
	// 		{
	// 			data: Object.values(map),
	// 			fill: false,
	// 			backgroundColor: "rgba(75,192,192,0.2)",
	// 			borderColor: "rgba(75,192,192,1)",
	// 		},
	// 	],
	// };

	// Case document line chart data 2022

	// Only fetch cases of 2021
	var caseQ42021 = cases
		.map((e) => e.dateofdoc)
		.filter((e) => e.includes("/2021"));

	// Slice the month only and sort the data
	var monthvar2021 = caseQ42021
		.map((e) => e.slice(1, -5))
		.sort((a, b) => {
			if (a < b) {
				return -1;
			}
			if (a > b) {
				return 1;
			}
			return 0;
		});

	// Count the occurence of each month
	var mapQ4M2021 = monthvar2021.reduce(
		(acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
		new Map()
	);

	// Create an array of the number of occurences
	var docData21 = Array.from(mapQ4M2021.values());
	// var mapQ4M2021 = monthvar2021.reduce(function (prev, cur) {
	// 	prev[cur] = (prev[cur] || 0) + 1;
	// 	return prev;
	// }, {});

	// Case document line chart data 2022

	// Only fetch cases of 2022
	var caseQ42022 = cases
		.map((e) => e.dateofdoc)
		.filter((e) => e.includes("/2022"));

	// Slice the month only and sort the data
	var monthvar2022 = caseQ42022
		.map((e) => e.slice(1, -5))
		.sort((a, b) => {
			if (a < b) {
				return -1;
			}
			if (a > b) {
				return 1;
			}
			return 0;
		});
	// var mapQ4M2022 = monthvar2022.reduce(function (prev, cur) {
	// 	prev[cur] = (prev[cur] || 0) + 1;
	// 	return prev;
	// }, {});

	// Count the occurence of each month
	var mapQ4M2022 = monthvar2022.reduce(
		(acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
		new Map()
	);

	// Create an array of the number of occurences
	var docData22 = Array.from(mapQ4M2022.values());

	const data = {
		labels: [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		],
		datasets: [
			{
				data: [0, 0, 0, ...Object.values(docData21)],
				label: "2021",
				fill: false,
				backgroundColor: "rgba(75,192,192,0.2)",
				borderColor: "rgba(75,192,192,1)",
			},
			{
				data: Object.values(docData22),
				label: "2022",
				fill: false,
				backgroundColor: "#4F2970",
				borderColor: "#4F2970",
			},
		],
	};

	return (
		<Grid
			container
			direction="column"
			className={classes.leftAndRightAndTopAndBottom}
			style={{ paddingTop: 0 }}
		>
			<Grid item sm={12} xs={12}>
				<Typography className={classes.typochart}>Évolution des cas</Typography>
			</Grid>

			<Grid item sm={12} xs={12}>
				<Line
					data={data}
					// Height of graph
					height={82}
				/>
				{/* <Line
					data={data}
					height={85}
					options={{
						legend: { display: false },
						scales: {
							yAxes: [
								{
									ticks: {
										precision: 0,
									},
								},
							],
						},
					}}
				/> */}
			</Grid>
		</Grid>
	);
};

export default LineChart;

import React, { useState, useEffect } from "react";
import BackPaper from "../../../widget/backpaper";
import {
	Grid,
	Typography,
	Box,
	FormGroup,
	Checkbox,
	Divider,
	TextField,
	RadioGroup,
	Radio,
	FormControlLabel,
	FormControl,
	TextareaAutosize,
	Button,
	Card,
	CardContent,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	// Paper,
} from "@material-ui/core";
import useStyles from "../styles";
import {
	q1,
	q2,
	q3,
	q4,
	q5,
	q6,
	q6a,
	q6b,
	q7,
	q8,
	q8a,
	q8b,
	q8c,
	q8d,
	q8e,
	q8f,
	q9,
	q10,
	q11,
	q11a,
	q11b,
	q12,
	q12a,
	q12b,
	q13,
	q13a,
	q13b,
	q14,
	q14a,
	q14b,
	q14c,
	q15,
	q16,
	q17,
	q18,
	q18a,
	q18b,
	q18c,
	q19,
	q20,
	q21,
	q22,
	q23,
	q24,
	q24a,
	q25,
	q26,
	q27,
} from "../../../widget/i1e";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import {useHistory } from "react-router-dom";
import axiosInstance from "../../../service/dashboardAxiosApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BtnOutline, BtnContained, BtnText } from "../../../widget/util";
import { useParams } from 'react-router-dom';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const CaseDetail = () => {

	// GLOBAL STATE VARIABLES
	const history = useHistory();
	const role = sessionStorage.getItem("role");
	const classes = useStyles();
	const { id } = useParams();
	const [caseDetail, setCaseDetail] = useState([]);
	const [internetError, setInternetError] = useState(false);
	const [responseMessage, setResponseMessage] = useState("");
	const [successResponse, setSuccessResponse] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [openDialogForApproveCase, setOpenDialogForApproveCase] = useState(false);
	const [openDialogForDelete, setOpenDialogForDelete] = useState(false);
	const [isLoading, setIsLoading] = useState(false);



	// GETTING THE CASE DETAIL FROM THE API
	const fetchCase = async () => {

		// Return Internet Error
		if (!navigator.onLine) {
			//   console.log("No internet connection.");
			  setResponseMessage("No internet connection.")
			  setInternetError(true);
			  return;
			}
		
		setIsLoading(true);

		try {
			//   console.log(`Fetching user with id: ${id}`);
				const response = await axiosInstance.get(`cases/${id}`, {timeout: 20000});
				const data = response.data.caseItem;
			//   console.log(`Fetched user:`, response.data);
				setCaseDetail(data);
				setInternetError(false);
				setSuccessResponse(true)
				setIsLoading(false);
			} catch (err) {
			//   console.log('Error fetching user: ', err);
				if (err.code === 'ECONNABORTED') {
				// console.log("The request took too long - please try again.");
				setResponseMessage("The request took too long - please try again.")
				setInternetError(true);
				} 
	
				setSuccessResponse(false)
				setIsLoading(false);
			}

	};
 
	useEffect(() => {
		fetchCase();
	  }, [id]);

	//console.log(caseDetail);

	// Display load icon if data is not fetched
	if (isLoading) {
		return <CircularProgress />;
		
	}

	// If no internet, reload 
	if (internetError) {
		return (
		  <div>
			<p>An error occurred. Please check your internet connection and try again.</p>
			<Button variant="contained" color="primary"  onClick={fetchCase}>Retry</Button>
		  </div>
		)
	  }
	
	// Redirect to edit page
	const handleEdit = () => {
		history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard/case/edit/${id}`);
	  };

	// HANDLE APPROVE THE CASE
	const handleApprove = async () => {
		setIsUpdating(true);
		// send request to backend to deactivate the user
		try {
			await axiosInstance.patch(`case/${id}/`, {
				approve: caseDetail.approve === true ? false : caseDetail.approve === false ? true : caseDetail.approve,
				// role: userData.users.role === "Super Admin" ? 1 : userData.users.role === "Admin" ? 2 : userData.users.role === "Author" ? 3 : userData.users.role === "Data Collector" ? 4 : userData.users.role,
			});
			setResponseMessage(caseDetail.approve === false ? `Case ${caseDetail.caseid} has been approved!` : `${caseDetail.caseid} has been disapproved!`)
			// Fetch the case to update the display
			fetchCase();
		} catch (err) {
			// console.error('Error deactivating user:', err);
			setResponseMessage("Error deactivating user. Check your internet or contact admin")
		}
		setIsUpdating(false);
		handleCloseDialogForAproveCase();
		handleClickSnack(); // CALLING THE SNACKBAR
	};


	// HANDLE DELETE CASE
	const handleDeleteCase = async () => {
		try {
		  await axiosInstance.delete(`case/${id}/`);
		  history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard`);
		} catch (error) {
		  console.error('Failed to delete the case:', error);
		}
		setIsUpdating(false);
		handleCloseDialogForDelete()
		handleClickSnack();
	  };


	
	// HANDLING SNACKBAR OPENING AND CLOSING
	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	  });
	
	const handleCloseScnackBar = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
	
		setOpenSnackBar(false);
	  };
	
	const handleClickSnack = () => {
		setOpenSnackBar(true);
	  };

	
	// Approve / Disapprove case dialogue
	const handleOpenDialogForApproveCase = () => {
		setOpenDialogForApproveCase(true);
	  };
	  
	  const handleCloseDialogForAproveCase = () => {
		setOpenDialogForApproveCase(false);
	  };
	
	// Delete case dialogue
	const handleOpenDialogForDelete = () => {
		setOpenDialogForDelete(true);
	  };
	  
	  const handleCloseDialogForDelete = () => {
		setOpenDialogForDelete(false);
	  };


	// TODO
	// const backbtn = () => {
	// 	sessionStorage.removeItem("rowId");
	// 	history.push(`/${process.env.REACT_APP_DASHBOAD_LINK}/dashboard`);
	// };
	
	
	return (

		<Grid container direction="row" spacing={3}>
			<Card className={classes.fullWidthCard}>
				<CardContent className={classes.leftAndRightMiddle}>
					<Grid item xs={12}>
						<Typography variant="h5" className={classes.bigtitle}>
						{caseDetail.caseid} {caseDetail.approve ? <CheckIcon style={{ color: 'green', fontSize: 50 }} /> : <CloseIcon style={{ color: 'red', fontSize: 50 }} />}
						</Typography>
					</Grid>

					{/* RESPONSE MESSAGE SNACKBAR */}
					<Grid item xs={12} sm={4}>
						<Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseScnackBar}>
							{ successResponse ? <Alert onClose={handleCloseScnackBar} severity="success" sx={{ width: '100%' }}>
								{responseMessage}
							</Alert>
							:
							<Alert onClose={handleCloseScnackBar} severity="error" sx={{ width: '100%' }}>
							{responseMessage}
							</Alert> }
						</Snackbar>
					</Grid>

					<Divider />

					<Grid item sm={8} xs={11} className={classes.margin_Top_30}>
						{/* from question 1 up to 25 */}.
						<Grid container spacing={3}>
							<Grid item sm={11} xs={12}>
								{/* QUESTION 1 */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>1. {q1}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.answer}>
											{caseDetail.organization}
										</Typography>
									</Grid>
									{/* {console.log("Question ORG: ", caseDetail.organization)} */}
								</Grid>

								{/* QUESTION 2 */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q2}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.answer}>
											{caseDetail.question_2}
										</Typography>
									</Grid>
								</Grid>

								{/* QUESTION 3 */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q3}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.answer}>
											{caseDetail.question_3}
										</Typography>
									</Grid>
								</Grid>

								{/* QUESTION 4 */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q4}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.answer}>
											{caseDetail.question_4}
										</Typography>
									</Grid>
								</Grid>

								{/* QUESTION 5 */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q5}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.answer}>
											{caseDetail.question_5}
										</Typography>
									</Grid>
								</Grid>

								{/* QUESTION 6 */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q6}</Typography>
									</Grid>
									{/* <Grid item sm= {12} xs={12}><Typography className={classes.answer}>Lorem</Typography></Grid> */}
								</Grid>

								{/* QUESTION 6A */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.sublabel}>{q6a}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.answer}>
											{caseDetail.question_6_a}
										</Typography>
									</Grid>
								</Grid>

								{/* QUESTION 6B */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.sublabel}>{q6b}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.answer}>
											{caseDetail.question_6_b}
										</Typography>
									</Grid>
								</Grid>

								{/* QUESTION 7 */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q7}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.answer}>
											{caseDetail.question_7}
										</Typography>
									</Grid>
								</Grid>

								{/* QUESTION 8 */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q8}</Typography>
									</Grid>
									{/* <Grid item sm= {12} xs={12}><Typography className={classes.answer}>Lorem</Typography></Grid> */}
								</Grid>

								{/* QUESTION 8A */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.sublabel}>{q8a}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.answer}>
											{caseDetail.question_8_a}
										</Typography>
									</Grid>
								</Grid>

								{/* QUESTION 8B */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.sublabel}>{q8b}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<FormControl component="fieldset">
											<RadioGroup
												name="question_8_b"
												className={classes.answer}
											>
												{caseDetail.question_8_b === "Oui" ? (
													<FormControlLabel
														value="Oui"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
												) : (
													<FormControlLabel
														value="Non"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
												)}
												{/* <FormControlLabel value="Oui" control={<Radio size='small'/>} label={<Box  component="div" fontSize={13}>Oui</Box>} /> */}
												{/* <FormControlLabel value="Non" control={<Radio size='small'/>} label={<Box  component="div" fontSize={13}>Non</Box>} /> */}
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>

								{/* QUESTION 8C */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.sublabel}>{q8c}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<FormControl component="fieldset">
											<RadioGroup
												name="question_8_c"
												className={classes.answer}
											>
												{caseDetail.question_8_c === "Oui" ? (
													<FormControlLabel
														value="Oui"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
												) : (
													<FormControlLabel
														value="Non"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
												)}
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>

								{/* QUESTION 8D */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.sublabel}>{q8d}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<FormControl component="fieldset">
											<RadioGroup
												name="question_8_d"
												className={classes.answer}
											>
												{caseDetail.question_8_d === "Oui" ? (
													<FormControlLabel
														value="Oui"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
												) : (
													<FormControlLabel
														value="Non"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
												)}
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>

								{/* QUESTION 8E */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.sublabel}>{q8e}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<FormControl component="fieldset">
											<RadioGroup
												name="question_8_e"
												className={classes.answer}
											>
												{caseDetail.question_8_e === "Oui" ? (
													<FormControlLabel
														value="Oui"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
												) : (
													<FormControlLabel
														value="Non"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
												)}
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>

								{/* QUESTION 8F */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.sublabel}>{q8f}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<FormControl component="fieldset">
											<RadioGroup
												name="question_8_f"
												className={classes.answer}
											>
												{caseDetail.question_8_f === "Oui" ? (
													<FormControlLabel
														value="Oui"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
												) : (
													<FormControlLabel
														value="Non"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
												)}
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>

								{/* QUESTION 9 */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q9}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<TextareaAutosize
											minRows={14}
											disabled
											className={classes.textarea}
											defaultValue={caseDetail.question_9}
											aria-label="maximum height"
											//placeholder="Il s’agit d’une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé."
										/>
										{/* {console.log("Case ID Ref Q9: ", caseDetail.id)} */}
									</Grid>
								</Grid>

								{/* <Grid container  spacing={1} className={classes.margin_Bottom_30}>
											<Grid item sm= {12} xs={12}><Typography className={classes.label}>{q10}</Typography></Grid>
											<Grid item sm= {12} xs={12}>
											<FormGroup className={classes.answer}>

												
												<FormControlLabel
													control={<Checkbox  name="" />}
													label="Incidents de violence sexuelle"
												/>
												<FormControlLabel
													control={<Checkbox   name="" />}
													label="Incidents de violence psychologique"
												/>
												<FormControlLabel
													control={<Checkbox   name="" />}
													label="incidents de violence physique(autre que violence sexuelle)"
												/>
												<FormControlLabel
													control={<Checkbox   name="" />}
													label="incidents de violence economique et de déni d`opportunités"
												/>
											</FormGroup>
											</Grid>
									</Grid> */}

								{/* QUESTION 10A */}
								<Grid
									container
									spacing={1}
									direction="row"
									className={classes.marginBottom_30}
								>
									<Grid item xs={12} sm={12}>
										<Typography className={classes.label}>
											{q10}
											<small style={{ fontWeight: 200 }}>
												(choix multiple est possible)
											</small>
										</Typography>

										<Grid container>
											<Grid item xs={12} sm={12}>
												<Button
													variant="text"
													color="default"
													className={classes.button}
													startIcon={
														(caseDetail.question_10_a || []).filter(Boolean).length ===
															0 ? (
																<ArrowRightIcon style={{ fontSize: 30 }} />
															) : (
																<ArrowDropDownIcon style={{ fontSize: 30 }} />
		)
													}
												>
													Incidents de violence sexuelle
												</Button>
											</Grid>
											<Grid item xs={12} sm={12}>
												<FormGroup className={classes.answer}>
													{caseDetail.question_10_a === null
														? null
														: (caseDetail.question_10_a || [])
																.filter((e) => e)
																.map((e) => (
																	<FormControlLabel
																		control={<Checkbox name="" checked />}
																		key={e}
																		label={
																			<Typography
																				className={classes.detailTypo}
																			>
																				{e}
																				{/* {console.log("printing the e: ", e)} */}
																			</Typography>
																		}
																	/>
																))}
												</FormGroup>
											</Grid>
											<Grid item xs={12} sm={12}>
												<Button
													variant="text"
													color="default"
													className={classes.button}
													startIcon={
														(caseDetail.question_10_b || []).filter(Boolean).length ===
														0 ? (
															<ArrowRightIcon style={{ fontSize: 30 }} />
														) : (
															<ArrowDropDownIcon style={{ fontSize: 30 }} />
														)
													}
												>
													Incidents de violence physique (autre que violence
													sexuelle)
												</Button>
											</Grid>

											{/* QUESTION 10B */}
											<Grid item xs={12} sm={12}>
												<FormGroup className={classes.answer}>
													{caseDetail.question_10_b === null
														? null
														: (caseDetail.question_10_b || [])
																.filter((e) => e)
																.map((e) => (
																	<FormControlLabel
																		control={<Checkbox name="" checked />}
																		key={e}
																		label={
																			<Typography
																				className={classes.detailTypo}
																			>
																				{e}
																			</Typography>
																		}
																	/>
																))}
												</FormGroup>
											</Grid>

											{/* QUESTION 10C */}
											<Grid item xs={12} sm={12}>
												<Button
													variant="text"
													color="default"
													className={classes.button}
													startIcon={
														(caseDetail.question_10_c || []).filter(Boolean).length ===
														0 ? (
															<ArrowRightIcon style={{ fontSize: 30 }} />
														) : (
															<ArrowDropDownIcon style={{ fontSize: 30 }} />
														)
													}
												>
													Incidents de violence psychologique
												</Button>
											</Grid>
											<Grid item xs={12} sm={12}>
												<FormGroup className={classes.answer}>
													{caseDetail.question_10_c === null
														? null
														: (caseDetail.question_10_c || [])
																.filter((e) => e)
																.map((e) => (
																	<FormControlLabel
																		control={<Checkbox name="" checked />}
																		key={e}
																		label={
																			<Typography
																				className={classes.detailTypo}
																			>
																				{e}
																			</Typography>
																		}
																	/>
																))}
												</FormGroup>
											</Grid>

											{/* QUESTION 10D */}
											<Grid item xs={12} sm={12}>
												<Button
													variant="text"
													color="default"
													className={classes.button}
													startIcon={
														(caseDetail.question_10_d || []).filter(Boolean).length ===
														0 ? (
															<ArrowRightIcon style={{ fontSize: 30 }} />
														) : (
															<ArrowDropDownIcon style={{ fontSize: 30 }} />
														)
													}
												>
													Incidents de violence économique et de déni
													d’opportunités
												</Button>
											</Grid>
											<Grid item xs={12} sm={12}>
												<FormGroup className={classes.answer}>
													{caseDetail.question_10_d === null
														? null
														: (caseDetail.question_10_d|| [])
																.filter((e) => e)
																.map((e) => (
																	<FormControlLabel
																		control={<Checkbox name="" checked />}
																		key={e}
																		label={
																			<Typography
																				className={classes.detailTypo}
																			>
																				{e}
																				{/* {console.log(e)} */}
																			</Typography>
																		}
																	/>
																))}
												</FormGroup>
											</Grid>
										</Grid>
									</Grid>
								</Grid>

								{/* QUESTION 11 */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid
										item
										sm={12}
										xs={12}
										className={classes.margin_Bottom_10}
									>
										<Typography className={classes.label}>{q11}</Typography>
									</Grid>

									{/* QUESTION 11A */}
									<Grid item sm={12} xs={12}>
										<Grid container spacing={1}>
											<Grid item sm={12} xs={12}>
												<Typography className={classes.sublabel}>
													{q11a}
												</Typography>
											</Grid>
											<Grid item sm={12} xs={12}>
												<FormControl component="fieldset">
													<RadioGroup
														name="question_11_a"
														className={classes.answer}
													>
														{caseDetail.question_11_a === "Oui" ? (
															<div>
																<FormControlLabel
																	value="Oui"
																	checked
																	control={<Radio size="small" />}
																	label={
																		<Box component="div" fontSize={13}>
																			Oui
																		</Box>
																	}
																/>
																<Typography className={classes.subradio}>
																	Si oui : Quand cette situation a-t-elle
																	commencé ?
																</Typography>
																{caseDetail.question_11_a_oui_month ===
																"" ? null : (
																	<Typography>
																		Month: {caseDetail.question_11_a_oui_month}
																	</Typography>
																)}

																<Typography>
																	Year: {caseDetail.question_11_a_oui}
																</Typography>
															</div>
														) : (
															<FormControlLabel
																value="Non"
																checked
																control={<Radio size="small" />}
																label={
																	<Box component="div" fontSize={13}>
																		Non
																	</Box>
																}
															/>
														)}

														{/* <FormControlLabel value="Oui" control={<Radio size='small'/>} label={<Box  component="div" fontSize={13}>Oui</Box>} />
																<Typography className={classes.subradio}>Si oui : Quand cette situation a-t-elle commencé ?</Typography>
																<Typography>21/12/2020</Typography>
																<FormControlLabel value="Non" control={<Radio size='small'/>} label={<Box  component="div" fontSize={13}>Non</Box>} /> */}
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
								</Grid>

								{/* QUESTION 11B */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.sublabel}>{q11b}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<FormControl component="fieldset">
											<RadioGroup
												name="question_11_b"
												className={classes.answer}
											>
												{caseDetail.question_11_b === "Oui" ? (
													<div>
														<FormControlLabel
															checked
															value="Oui"
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Oui
																</Box>
															}
														/>
														<Typography className={classes.subradio}>
															Si oui : quand l'incident a-t-il eu lieu ?
														</Typography>
														{caseDetail.question_11_b_oui_month ===
														"" ? null : (
															<Typography>
																Month: {caseDetail.question_11_b_oui_month}
															</Typography>
														)}
														<Typography>
															Year: {caseDetail.question_11_b_oui}
														</Typography>
													</div>
												) : (
													<FormControlLabel
														checked
														value="Non"
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
												)}
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>

								{/* QUESTION 12 */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_20}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q12}</Typography>
									</Grid>
								</Grid>

								{/* QUESTION 12A */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.sublabel}>{q12a}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.answer}>
											{caseDetail.question_12_a}
										</Typography>
									</Grid>
								</Grid>
								{/* QUESTION 12B */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.sublabel}>{q12b}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.answer}>
											{caseDetail.question_12_b}
										</Typography>
									</Grid>
								</Grid>

								{/* QUESTION 13 */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q13}</Typography>
									</Grid>
								</Grid>

								{/* QUESTION 13A */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.sublabel}>{q13a}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<TextareaAutosize
											disabled
											defaultValue={caseDetail.question_13_a}
											minRows={14}
											className={classes.textarea}
											aria-label="maximum height"
											placeholder="Il s’agit d’une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé."
										/>
									</Grid>
								</Grid>

								<Grid
									container
									spacing={1}
									direction="row"
									className={classes.marginBottom_30}
								>
									<Grid item xs={12} sm={12}>
										<Typography className={classes.sublabel}>
											{q13b}
											<small style={{ fontWeight: 200 }}>
												(choix multiple est possible)
											</small>
										</Typography>

										<Grid container>
											<Grid item xs={12} sm={12}>
												<Button
													variant="text"
													color="default"
													className={classes.button}
													startIcon={
														(caseDetail.question_13_b_a || []).filter(Boolean)
															.length === 0 ? (
															<ArrowRightIcon style={{ fontSize: 30 }} />
														) : (
															<ArrowDropDownIcon style={{ fontSize: 30 }} />
														)
													}
												>
													Préjudice physique
												</Button>
											</Grid>

											{/* QUESTION 13B_A */}
											<Grid item xs={12} sm={12}>
												<FormGroup className={classes.answer}>
													{caseDetail.question_13_b_a === null
														? null
														: (caseDetail.question_13_b_a|| [])
																.filter((e) => e)
																.map((e) => (
																	<FormControlLabel
																		control={<Checkbox name="" checked />}
																		key={e}
																		label={
																			<Typography
																				className={classes.detailTypo}
																			>
																				{e}
																			</Typography>
																		}
																	/>
																))}
												</FormGroup>
											</Grid>
											<Grid item xs={12} sm={12}>
												<Button
													variant="text"
													color="default"
													className={classes.button}
													startIcon={
														(caseDetail.question_13_b_b || []).filter(Boolean)
															.length === 0 ? (
															<ArrowRightIcon style={{ fontSize: 30 }} />
														) : (
															<ArrowDropDownIcon style={{ fontSize: 30 }} />
														)
													}
												>
													Préjudice émotionnel
												</Button>
											</Grid>

											{/* QUESTION 13B_B */}
											<Grid item xs={12} sm={12}>
												<FormGroup className={classes.answer}>
													{caseDetail.question_13_b_b === null
														? null
														: (caseDetail.question_13_b_b|| [])
																.filter((e) => e)
																.map((e) => (
																	<FormControlLabel
																		control={<Checkbox name="" checked />}
																		key={e}
																		label={
																			<Typography
																				className={classes.detailTypo}
																			>
																				{e}
																			</Typography>
																		}
																	/>
																))}
												</FormGroup>
											</Grid>
											{/* QUESTION 13B_C */}
											<Grid item xs={12} sm={12}>
												<Button
													variant="text"
													color="default"
													className={classes.button}
													startIcon={
														(caseDetail.question_13_b_c|| []).filter(Boolean)
															.length === 0 ? (
															<ArrowRightIcon style={{ fontSize: 30 }} />
														) : (
															<ArrowDropDownIcon style={{ fontSize: 30 }} />
														)
													}
												>
													Préjudice économique
												</Button>
											</Grid>
											<Grid item xs={12} sm={12}>
												<FormGroup className={classes.answer}>
													{caseDetail.question_13_b_c === null
														? null
														: (caseDetail.question_13_b_c|| [])
																.filter((e) => e)
																.map((e) => (
																	<FormControlLabel
																		control={<Checkbox name="" checked />}
																		key={e}
																		label={
																			<Typography
																				className={classes.detailTypo}
																			>
																				{e}
																			</Typography>
																		}
																	/>
																))}
												</FormGroup>
											</Grid>
											{/* QUESTION 13B_D */}
											<Grid item xs={12} sm={12}>
												<Button
													variant="text"
													color="default"
													className={classes.button}
													startIcon={
														(caseDetail.question_13_b_d || []).filter(Boolean)
															.length === 0 ? (
															<ArrowRightIcon style={{ fontSize: 30 }} />
														) : (
															<ArrowDropDownIcon style={{ fontSize: 30 }} />
														)
													}
												>
													Impact sur les relations
												</Button>
											</Grid>
											<Grid item xs={12} sm={12}>
												<FormGroup className={classes.answer}>
													{caseDetail.question_13_b_d === null
														? null
														: (caseDetail.question_13_b_d|| [])
																.filter((e) => e)
																.map((e) => (
																	<FormControlLabel
																		control={<Checkbox name="" checked />}
																		key={e}
																		label={
																			<Typography
																				className={classes.detailTypo}
																			>
																				{e}
																			</Typography>
																		}
																	/>
																))}
												</FormGroup>
											</Grid>
										</Grid>
									</Grid>
								</Grid>

								{/* QUESTION 14 */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_10}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q14}</Typography>
									</Grid>
								</Grid>

								{/* QUESTION 14A */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.sublabel}>{q14a}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<FormControl component="fieldset">
											<RadioGroup
												name="question_14_a"
												className={classes.answer}
											>
												{caseDetail.question_14_a === "Oui" ? (
													<FormControlLabel
														checked
														value="Oui"
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
												) : (
													<FormControlLabel
														checked
														value="Non"
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
												)}
											</RadioGroup>
										</FormControl>
										{caseDetail.question_14_a === "Oui" ? (
											<FormGroup className={classes.answer}>
												{caseDetail.question_14_a_oui === null
													? null
													: (caseDetail.question_14_a_oui|| [])
															.filter((e) => e)
															.map((e) => (
																<FormControlLabel
																	control={<Checkbox name="" checked />}
																	key={e}
																	label={
																		<Typography className={classes.detailTypo}>
																			{e}
																		</Typography>
																	}
																/>
															))}
											</FormGroup>
										) : null}
									</Grid>
								</Grid>

								{/* QUESTION 14B */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.sublabel}>{q14b}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<FormControl component="fieldset">
											<Typography className={classes.sublabel}>
												Si oui, l’auteur de la violence domestique est …
											</Typography>
											<RadioGroup
												name="question_14_b"
												className={classes.answer}
											>
												{caseDetail.question_14_b === "Oui" ? (
													<FormControlLabel
														value="Oui"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
												) : (
													<FormControlLabel
														value="Non"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
												)}
											</RadioGroup>
										</FormControl>
										{caseDetail.question_14_b === "Oui" ? (
											<FormGroup className={classes.answer}>
												{(caseDetail.question_14_b_oui|| [])
													.filter((e) => e)
													.map((e) => (
														<FormControlLabel
															control={<Checkbox name="" checked />}
															key={e}
															label={
																<Typography className={classes.detailTypo}>
																	{e}
																</Typography>
															}
														/>
													))}
											</FormGroup>
										) : null}
									</Grid>
								</Grid>

								{/* QUESTION 14C */}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.sublabel}>{q14c}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<FormGroup className={classes.answer}>
											{caseDetail.question_14_c === null
												? null
												: (caseDetail.question_14_c|| [])
														.filter((e) => e)
														.map((e) => (
															<FormControlLabel
																control={<Checkbox name="" checked />}
																key={e}
																label={
																	<Typography className={classes.detailTypo}>
																		{e}
																	</Typography>
																}
															/>
														))}
										</FormGroup>
									</Grid>
								</Grid>

								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q15}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<FormControl component="fieldset">
											<RadioGroup name="question_15" className={classes.answer}>
												{caseDetail.question_15 === "Oui" ? (
													<div>
														<Typography className={classes.subradio}>
															Si oui,
														</Typography>
														<FormControlLabel
															checked
															value="Oui"
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	Oui
																</Box>
															}
														/>
													</div>
												) : (
													<FormControlLabel
														checked
														value="Non"
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
												)}
											</RadioGroup>
										</FormControl>
										{caseDetail.question_15 === "Oui" ? (
											<FormGroup className={classes.answer}>
												{(caseDetail.question_15_oui || [])
													.filter((e) => e)
													.map((e) => (
														<FormControlLabel
															control={<Checkbox name="" checked />}
															key={e}
															label={
																<Typography className={classes.detailTypo}>
																	{e}
																</Typography>
															}
														/>
													))}
												{caseDetail.question_15_oui_autre === "" ? null : (
													<FormControlLabel
														control={<Checkbox checked />}
														label={
															<div className={classes.disabledTextField}>{caseDetail.question_15_oui_autre}</div>
														}
													/>
												)}
											</FormGroup>
										) : null}
									</Grid>
								</Grid>

								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q16}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<FormControl component="fieldset">
											<RadioGroup name="question_16" className={classes.answer}>
												{caseDetail.question_16 === "Oui" ? (
													<FormControlLabel
														value="Oui"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
												) : (
													<FormControlLabel
														value="Non"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
												)}
											</RadioGroup>
										</FormControl>
									</Grid>
									{caseDetail.question_16 === "Oui" ? (
										<Grid item sm={12} xs={12}>
											<Typography>
												Si oui, les autorités ont-elles donné suite à la
												plainte:{" "}
											</Typography>
											{caseDetail.question_16_oui === "Oui" ? (
												<FormControlLabel
													value="Oui"
													checked
													control={<Radio size="small" />}
													label={
														<Box component="div" fontSize={13}>
															Oui
														</Box>
													}
												/>
											) : (
												<FormControlLabel
													value="Non"
													checked
													control={<Radio size="small" />}
													label={
														<Box component="div" fontSize={13}>
															Non
														</Box>
													}
												/>
											)}
										</Grid>
									) : null}
								</Grid>

								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q17}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<FormControl component="fieldset">
											<RadioGroup name="question_17" className={classes.answer}>
												{caseDetail.question_17 === "Oui" ? (
													<FormControlLabel
														value="Oui"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
												) : (
													<FormControlLabel
														value="Non"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
												)}
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>

								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q18}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<FormControl component="fieldset">
											<RadioGroup name="question_18" className={classes.answer}>
												{caseDetail.question_18 === "Oui" ? (
													<FormControlLabel
														value="Oui"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
												) : (
													<FormControlLabel
														value="Non"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
												)}
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>
								{caseDetail.question_18 === "Oui" ? (
									<div>
										<Grid
											container
											spacing={1}
											className={classes.margin_Bottom_30}
										>
											<Grid item sm={12} xs={12}>
												<Typography className={classes.sublabel}>
													{q18a}
												</Typography>
											</Grid>
											<Grid item sm={12} xs={12}>
												<FormGroup className={classes.answer}>
													{caseDetail.question_18_a === null
														? null
														: (caseDetail.question_18_a|| [])
																.filter((e) => e)
																.map((e) => (
																	<FormControlLabel
																		control={<Checkbox name="" checked />}
																		key={e}
																		label={
																			<Typography
																				className={classes.detailTypo}
																			>
																				{e}
																			</Typography>
																		}
																	/>
																))}

													{caseDetail.question_18_a_autre === "" ? null : (
														<FormControlLabel
															control={<Checkbox name=" " checked />}
															label={
																<div className={classes.disabledTextField}>{caseDetail.question_18_a_autre}</div>
															}
														/>
													)}
												</FormGroup>
											</Grid>
										</Grid>

										<Grid
											container
											spacing={1}
											className={classes.margin_Bottom_30}
										>
											<Grid item sm={12} xs={12}>
												<Typography className={classes.sublabel}>
													{q18b}
												</Typography>
											</Grid>
											<Grid item sm={12} xs={12}>
												<TextareaAutosize
													disabled
													minRows={14}
													defaultValue={caseDetail.question_18_b}
													className={classes.textarea}
													aria-label="maximum height"
													placeholder="Il s’agit d’une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé."
												/>
											</Grid>
										</Grid>

										<Grid
											container
											spacing={1}
											className={classes.margin_Bottom_30}
										>
											<Grid item sm={12} xs={12}>
												<Typography className={classes.sublabel}>
													{q18c}
												</Typography>
											</Grid>
											<Grid item sm={12} xs={12}>
												<TextareaAutosize
													disabled
													minRows={14}
													defaultValue={caseDetail.question_18_c}
													className={classes.textarea}
													aria-label="maximum height"
													placeholder="Il s’agit d’une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé."
												/>
											</Grid>
										</Grid>
									</div>
								) : null}

								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q19}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<FormControl component="fieldset">
											<RadioGroup name="question_19" className={classes.answer}>
												{caseDetail.question_19 === "Oui" ? (
													<FormControlLabel
														value="Oui"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
												) : (
													<FormControlLabel
														value="Non"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
												)}
											</RadioGroup>
										</FormControl>
										{caseDetail.question_19 === "Oui" ? (
											<FormGroup className={classes.answer}>
												{caseDetail.question_19_oui === null
													? null
													: (caseDetail.question_19_oui || [])
															.filter((e) => e)
															.map((e) => (
																<FormControlLabel
																	control={<Checkbox name="" checked />}
																	key={e}
																	label={
																		<Typography className={classes.detailTypo}>
																			{e}
																		</Typography>
																	}
																/>
															))}
												{caseDetail.question_19_autre === "" ? null : (
													<FormControlLabel
														control={<Checkbox name="" checked />}
														label={
															<div className={classes.disabledTextField}>{caseDetail.question_19_autre}</div>
														}
													/>
												)}
											</FormGroup>
										) : null}
									</Grid>
								</Grid>

								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>
											{q20}
											(Veuillez cocher les volets qui sont couverts par les
											domaines d’intervention de votre organisation et dans
											lesquels vous pouvez ainsi directement assurer la prise en
											charge de la victime.)
										</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<FormGroup className={classes.answer}>
											{caseDetail.question_20 === null
												? null
												: (caseDetail.question_20 || [])
														.filter((e) => e)
														.map((e) => (
															<FormControlLabel
																control={<Checkbox name="" checked />}
																key={e}
																label={
																	<Typography className={classes.detailTypo}>
																		{e}
																		{/* {console.log("printing the i one: ", [...e][0])} */}
																	</Typography>
																}
															/>
														))}
											{caseDetail.question_20_autre === "" ? null : (
												<FormControlLabel
													control={<Checkbox name="" checked />}
													label={
														<div className={classes.disabledTextField}>{caseDetail.question_20_autre}</div>
													}
												/>
											)}
										</FormGroup>
									</Grid>
								</Grid>

								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>
											{q21}
											<small>
												(Veuillez cocher les volets qui ne sont pas couverts par
												les domaines d’intervention de votre organisation (cf.
												question 18) mais pour lesquels vous pouvez référer
												facilement la victime auprès des structures de référence
												fiables.)
											</small>
										</Typography>
										<FormGroup className={classes.answer}>
											{caseDetail.question_21 === null
												? null
												: (caseDetail.question_21 || [])
														.filter((e) => e)
														.map((e) => (
															<FormControlLabel
																control={<Checkbox name="" checked />}
																key={e}
																label={
																	<Typography className={classes.detailTypo}>
																		{e}
																	</Typography>
																}
															/>
														))}
											{caseDetail.question_21_autre === "" ? null : (
												<FormControlLabel
													control={<Checkbox name="" checked />}
													label={
														<div className={classes.disabledTextField}>{caseDetail.question_21_autre}</div>
													}
												/>
											)}
										</FormGroup>
									</Grid> 
								</Grid>

								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>
											{q22}
											<small>
												(Veuillez cocher les volets qui ne sont pas couverts par
												les domaines d’intervention de votre organisation (cf.
												question 18) et pour lesquels il n’existe pas des
												structures de référence (cf. question 18) pour offrir
												ces services à la victime.)
											</small>
										</Typography>
									</Grid>

									<FormGroup className={classes.answer}>
										{caseDetail.question_22 === null
											? null
											: (caseDetail.question_22 || [])
													.filter((e) => e)
													.map((e) => (
														<FormControlLabel
															control={<Checkbox name="" checked />}
															key={e}
															label={
																<Typography className={classes.detailTypo}>
																	{e}
																</Typography>
															}
														/>
													))}
										{caseDetail.question_22_autre === "" ? null : (
											<FormControlLabel
												control={<Checkbox name="" checked />}
												label={
													<div className={classes.disabledTextField}>{caseDetail.question_22_autre}</div>
												}
											/>
										)}
									</FormGroup>
								</Grid>

								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q23}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<FormControl component="fieldset">
											<RadioGroup name="question_23" className={classes.answer}>
												{caseDetail.question_23 === "Oui" ? (
													<FormControlLabel
														value="Oui"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
												) : (
													<FormControlLabel
														value="Non"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
												)}
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>

								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q24}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<FormControl
											component="fieldset"
											className={classes.marginBottom_30}
										>
											<RadioGroup name="question_24" className={classes.answer}>
												<FormControlLabel
													checked
													control={<Radio size="small" />}
													label={
														<Box component="div" fontSize={13}>
															{caseDetail.question_24}
														</Box>
													}
												/>
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>

								{caseDetail.question_24_a === "" ? null : (
									<Grid
										container
										spacing={1}
										className={classes.margin_Bottom_30}
									>
										<Grid item sm={12} xs={12}>
											<Typography className={classes.sublabel}>
												{q24a}
											</Typography>
										</Grid>
										<Grid item sm={12} xs={12}>
											<FormControl component="fieldset">
												<RadioGroup
													name="question_24_a"
													className={classes.answer}
												>
													<FormControlLabel
														value={caseDetail.question_24_a}
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																{caseDetail.question_24_a}
															</Box>
														}
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>
								)}
								<Grid
									container
									spacing={1}
									className={classes.margin_Bottom_30}
								>
									<Grid item sm={12} xs={12}>
										<Typography className={classes.label}>{q25}</Typography>
									</Grid>
									<Grid item sm={12} xs={12}>
										<FormControl component="fieldset">
											<RadioGroup name="question_25" className={classes.answer}>
												{caseDetail.question_25 === "Oui" ? (
													<FormControlLabel
														value="Oui"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Oui
															</Box>
														}
													/>
												) : (
													<FormControlLabel
														value="Non"
														checked
														control={<Radio size="small" />}
														label={
															<Box component="div" fontSize={13}>
																Non
															</Box>
														}
													/>
												)}
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>

								{caseDetail.question_25 === "Oui" ? (
									<div>
										<Grid
											container
											spacing={1}
											className={classes.margin_Bottom_30}
										>
											<Grid item sm={12} xs={12}>
												<Typography className={classes.label}>{q26}</Typography>
											</Grid>
											<Grid item sm={12} xs={12}>
												<TextareaAutosize
													minRows={14}
													disabled
													defaultValue={caseDetail.question_26}
													className={classes.textarea}
													aria-label="maximum height"
													//placeholder="Il s’agit d’une question ouverte qui permet à la victime de raconter dans ses propres mots ce qui s'est passé."
												/>
											</Grid>
										</Grid>

										<Grid
											container
											spacing={1}
											className={classes.margin_Bottom_30}
										>
											<Grid item sm={12} xs={12}>
												<Typography className={classes.label}>{q27}</Typography>
											</Grid>
											<Grid item sm={12} xs={12}>
												<FormControl component="fieldset">
													<RadioGroup
														name="question_27"
														className={classes.answer}
													>
														<FormControlLabel
															value="Non"
															checked
															control={<Radio size="small" />}
															label={
																<Box component="div" fontSize={13}>
																	{caseDetail.question_27}
																</Box>
															}
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
									</div>
								) : null}
							</Grid>
						</Grid>
						<Grid
							container
							className={classes.margin_Bottom_30}
							direction="row"
						>
							<Grid item xs={12} sm={6}>
							{role <= 1 ? (
								<Grid container direction="row" spacing={2}>
									<Grid item>
										{/* {role <= 1 ? ( */}
											<BtnText content="DELETE" onClick={handleOpenDialogForDelete} />
										{/* // ) : null} */}
										<Dialog open={openDialogForDelete} onClose={handleCloseDialogForDelete}>
											<DialogTitle>Confirmation</DialogTitle>
											<DialogContent>
											<DialogContentText>
												Are you sure you want to delete this case: <strong> {caseDetail.caseid}? </strong> 
											</DialogContentText>
											</DialogContent>
											<DialogActions>
											<Button onClick={handleCloseDialogForDelete} color="primary">
												Cancel
											</Button>
											<Button onClick={handleDeleteCase} color="secondary">
											{isUpdating ? <CircularProgress size={24} /> : `Yes, delete this case`}
											</Button>
											</DialogActions>
										</Dialog>
									</Grid>
									<Grid item>
										<BtnOutline
											content={caseDetail.approve? "Disapprove" : "Approve"}
											onClick={handleOpenDialogForApproveCase}
										/>
										<Dialog open={openDialogForApproveCase} onClose={handleCloseDialogForAproveCase}>
											<DialogTitle>Confirmation</DialogTitle>
											<DialogContent>
											<DialogContentText>
												Are you sure you want to {caseDetail.approve? "disapprove" : "approve"} this case: <strong> {caseDetail.caseid}? </strong> 
											</DialogContentText>
											</DialogContent>
											<DialogActions>
											<Button onClick={handleCloseDialogForAproveCase} color="primary">
												Cancel
											</Button>
											<Button onClick={handleApprove} color="secondary">
											{isUpdating ? <CircularProgress size={24} /> : `Yes, ${caseDetail.approve ? "Disapprove" : "Approve"}`}
											</Button>
											</DialogActions>
										</Dialog>
									</Grid>
								</Grid>
								) : null}
							</Grid>
							{role <= 2 ? (
							<Grid item xs={12} sm={6}>
								<Grid
									container
									className={classes.margin_Bottom_30}
									justifyContent="flex-end"
								>
									<Grid item>
										{/* <BtnOutline content="BACK" onClick={backbtn} /> */}
										{/* {role <= 2 ? ( */}
											<BtnContained content="EDIT" onClick={handleEdit} />
										{/* // ) : null} */}
									</Grid>
								</Grid>
							</Grid>
							) : null}
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
			
	); 
};

export default CaseDetail;


import React from 'react'
import {AppBar,Toolbar} from '@material-ui/core';
import Revoir from '../../component/datacollector/revoir'
import Submit from '../../component/datacollector/submit'
import ModifierCase from '../../component/datacollector/modifiercase'
import useStyles from './navStyle'
import {Route,Switch} from 'react-router-dom';

export const Routerevoir = () => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
        <Navbar/>
        <Switch>
        <Route exact path={"/"+process.env.REACT_APP_COLLECT_LINK+'/collect/main/changes'} component={ModifierCase} />
        <Route exact path={"/"+process.env.REACT_APP_COLLECT_LINK+'/collect/main'} component={Revoir}/>
        <Route exact path={"/"+process.env.REACT_APP_COLLECT_LINK+'/collect/main/submit'} component={Submit}/>

        
        </Switch>

            
        </div>
    )
}









const Navbar = () => {
    const classes = useStyles()
    return (
    <div>
      <AppBar position="absolute" className={classes.appbar}>
        <Toolbar>
        
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            component={Link} 
            to='/cases/home'
          >
          <ArrowBack/>
          </IconButton> */}
        </Toolbar>
      </AppBar>
      
      <br/>
    
      <br/>
      <br/>

     

      
      <br/>
      <div>
     
    </div>

    </div>

    )
}



export default Routerevoir;
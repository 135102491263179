import React, { useState, useEffect } from "react";
import PostMedia from "../../component/website/widgets/postMedia";
import {
	Card,
	Button,
	CardMedia,
	CardActionArea,
	Grid,
	Typography,
	Link,
} from "@material-ui/core";
import useStyles from "../../component/website/campain/style";
import axiosInstance from "../../service/axiosPub";
import InsertDriveFileSharpIcon from "@material-ui/icons/InsertDriveFileSharp";
import ReactHtmlParser from "react-html-parser";
import CloudOffTwoToneIcon from "@material-ui/icons/CloudOffTwoTone";
import RestoreIcon from "@material-ui/icons/Restore";
import StorageIcon from "@material-ui/icons/Storage";
import ArticleSkeleton from "../../component/website/Article/articleSkeleton";
import CompainSkeleton from "../../component/website/Article/compainSkeleton";
import { useTranslation } from "react-i18next";

export default function Article() {
	const classes = useStyles();
	const [postdata, setpostdata] = useState([]);
	const [posts, setposts] = useState([]);
	const [spinner, setspinner] = useState(true);
	const [errmsg, seterrmsg] = useState("");
	const [statecode, setstatecode] = useState(false);
	const [spinner2, setspinner2] = useState(true);
	const [errmsg2, seterrmsg2] = useState("");
	const [statecode2, setstatecode2] = useState(false);
	const { t } = useTranslation();

	// const handleReadmore = () => {
	//   history.push("/article");
	//   window.location.reload();
	// };

	const getPost = async () => {
		setspinner(true);
		axiosInstance
			.get(`pubposts/${localStorage.getItem("pstId")}/`)
			.then((res) => {
				if (res.status === 200) {
					const data = res.data.post;
					setspinner(false);
					setpostdata(data);
					setstatecode(true);
					seterrmsg("Posts successfull fetched");
				}
			})
			.catch((err) => {
				setspinner(false);
				if (err.response) {
					seterrmsg("Please check your internet connexion");
				} else if (err.request) {
					seterrmsg("Please check your internet connexion");
				}
			});
	};

	const getPostpub = async () => {
		setspinner2(true);

		axiosInstance
			.get(`pubposts/`)
			.then((res) => {
				if (res.status === 200) {
					const data = res.data.posts;
					setposts(data);
					setspinner2(false);
					setstatecode2(true);
					seterrmsg2("Posts successfull fetched");
				}
			})
			.catch((err) => {
				setspinner2(false);
				if (err.response) {
					seterrmsg2("Please check your internet connexion");
				} else if (err.request) {
					seterrmsg2("Please check your internet connexion");
				}
			});
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		getPost();

		getPostpub();
	}, [ postdata.id]); //axiosInstance,

	// console.log(posts);

	const displayData = () => {
		if (statecode === false) {
			return (
				<Grid container style={{ marginTop: 100, marginBottom: 100 }}>
					<Grid item xs={12} sm={12}>
						{spinner === true ? (
							<ArticleSkeleton />
						) : (
							<Grid container justifyContent="center" alignItems="center">
								<Grid item>
									<Grid container justifyContent="center" spacing={2}>
										<Grid item>
											<CloudOffTwoToneIcon size={40} />
										</Grid>
										<Grid item>
											<Typography>{errmsg}</Typography>
										</Grid>
									</Grid>
									<Grid container justifyContent="center" alignItems="center">
										<Grid item>
											<Button startIcon={<RestoreIcon />} onClick={getPost}>
												Reload
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			);
		} else if (statecode === true && postdata === "") {
			return (
				<Grid container direction="row" justifyContent="center">
					<Grid xs={12} sm={4}></Grid>
					<Grid xs={12} sm={4}>
						<Grid container direction="row">
							<Grid item xs={12} sm={4}>
								<StorageIcon size={60} />
							</Grid>
							<Grid item xs={12} sm={8}>
								<Typography>No Campaign news found</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid xs={12} sm={4}></Grid>
				</Grid>
			);
		} else if (statecode === true && postdata !== []) {
			return (
				<Grid
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					alignItems="flex-start"
					justifyContent={"space-around"}
					className={classes.marginBottom_5}
				>
					{postdata.image === null ? null : (
						<Grid container className={classes.img}>
							<Grid item xs={12} sm={12}>
								<Card elevation={0}>
									<CardActionArea>
										<CardMedia
											className={classes.posts_media}
											image={postdata.image}
										/>
									</CardActionArea>
								</Card>
							</Grid>
						</Grid>
					)}
					<Grid
						container
						direction="row"
						alignItems="flex-start"
						spacing={4}
						className={classes.margin_Bottom_30}
					>
						<Grid item>
							<Typography className={classes.labelTitle}>
								{postdata.title}
							</Typography>
						</Grid>
					</Grid>

					<Grid container className={classes.margin_Bottom_30}>
						<Grid item xs={12} sm={12}>
							<Typography className={classes.dark_txt}>
								{ReactHtmlParser(postdata.content)}
							</Typography>
						</Grid>
					</Grid>
					{postdata.link === null ? null : (
						<Grid container className={classes.margin_Bottom_10}>
							<Grid item>
								<Link href={postdata.link} target="_blank" rel="noopener">
									<Typography style={{ fontWeight: 300 }}>
										{" "}
										{postdata.link}
									</Typography>
								</Link>
							</Grid>
						</Grid>
					)}
					{postdata.document === null ? null : (
						<Grid container className={classes.margin_Bottom_10}>
							<Grid item>
								<Link href={postdata.document} target="_blank" rel="noopener">
									<InsertDriveFileSharpIcon style={{ fontSize: 12 }} />{" "}
									<Typography style={{ fontWeight: 300 }}> Document</Typography>
								</Link>
							</Grid>
						</Grid>
					)}
					<Grid
						container
						alignItems="flex-start"
						className={classes.marginTop_and_marginBottom}
					>
						<Grid item>
							<Typography
								style={{
									color: `${
										postdata.category === "Campaign news"
											? "#208BD1"
											: "#D12028"
									}`,
									fontWeight: 300,
								}}
							>
								{postdata.category}
							</Typography>
							<Typography className={classes.poste_date}>
								Posted: {postdata.created}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			);
		}
	};

	const listPostCompain = () => {
		if (statecode2 === false) {
			return (
				<Grid container style={{ marginTop: 100, marginBottom: 100 }}>
					<Grid item xs={12} sm={12}>
						{spinner2 === true ? (
							<CompainSkeleton />
						) : (
							<Grid container justifyContent="center" alignItems="center">
								<Grid item>
									<Grid container justifyContent="center" spacing={2}>
										<Grid item>
											<CloudOffTwoToneIcon size={40} />
										</Grid>
										<Grid item>
											<Typography>{errmsg2}</Typography>
										</Grid>
									</Grid>
									<Grid container justifyContent="center" alignItems="center">
										<Grid item>
											<Button startIcon={<RestoreIcon />} onClick={getPostpub}>
												Reload
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			);
		} else if (statecode2 === true && posts === "") {
			return (
				<Grid container direction="row" justifyContent="center" spacing={4}>
					<Grid xs={12} sm={4}></Grid>
					<Grid xs={12} sm={4}>
						<Grid container direction="row">
							<Grid item xs={12} sm={4}>
								<StorageIcon size={60} />
							</Grid>
							<Grid item xs={12} sm={8}>
								<Typography>No Campaign news found</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid xs={12} sm={4}></Grid>
				</Grid>
			);
		} else if (statecode2 === true && posts !== []) {
			return (
				<Grid container spacing={4}>
					{posts
						.map((post) => (
							<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
								<PostMedia
									category={
										post.category === "Campaign news"
											? t("campaigntab")
											: t("reporttab")
									}
									title={post.title}
									image={post.image}
									color={
										post.category === "Campaign news" ? "#208BD1" : "#D12028"
									}
									description={ReactHtmlParser(
										post.content.length > 180
											? post.content.substr(0, 180) + " ...."
											: post.content
									)}
									posteddate={post.created.substr(3, 10)}
									readmoreClicked={() => {
										//handleReadmore();

										if (postdata !== "") {
											setpostdata([]);
											//postdata.splice(postdata.id)
										}

										const newpost = posts.filter((e) => e.id === post.id);
										// console.log(newpost);
										setpostdata(newpost);
										localStorage.setItem("pstId", post.id);
										//console.log(postdata.id);
									}}
								/>
							</Grid>
						))
						.slice(0, 3)}
				</Grid>
			);
		}
	};

	return (
		<div>
			<Grid container className={classes.section_article}></Grid>

			<Grid>
				<Grid container alignItems="center">
					<Grid item xs={1} sm={1} md={1} lg={2} xl={3}></Grid>
					<Grid item xs={10} sm={10} md={10} lg={8} xl={6}>
						{displayData()}
					</Grid>

					<Grid xs={1} sm={1} md={1} lg={2} xl={3}></Grid>
				</Grid>
				<Grid container alignItems="center">
					<Grid xs={1} sm={1} md={1} lg={2} xl={3}></Grid>
					<Grid
						xs={10}
						sm={10}
						md={10}
						lg={10}
						xl={8}
						container
						alignItems="center"
						className={classes.marginBottom_5}
					>
						<Grid container alignItems="flex-start">
							<Grid container className={classes.marginBottom_5}>
								<Grid className={classes.marginLeft_2}>
									<Typography className={classes.label1}>
										{t("recent-news")}
									</Typography>
								</Grid>
							</Grid>
							<Grid
								container
								direction="row"
								// alignItems="flex-start"
								// justifyContent={"space-around"}
							>
								{listPostCompain()}
							</Grid>
						</Grid>
					</Grid>
					<Grid xs={1} sm={1} md={1} lg={2} xl={3}></Grid>
				</Grid>
			</Grid>
		</div>
	);
}
